import React from 'react'
import PropTypes from 'prop-types'
import { Button } from "@material-ui/core"

const CustomButton = (props) => {
  const classNames = props.type === 'text'
    ? [props.styles.text]
    : props.type === 'outlined'
    ? [props.styles.text, props.styles.outlined]
    : [props.styles.contained]
  const mergedClassNames = props.className ? [...classNames, props.className] : classNames

  return <Button
    {...props}
    className={mergedClassNames}
  />
}

CustomButton.propTypes = {
  type: PropTypes.oneOf(['text', 'outlined', 'contained'])
}

export default CustomButton
