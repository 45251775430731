import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import messages from '../messages';
import Icon from '@material-ui/core/Icon';

import {injectIntl,} from 'react-intl';

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.primary,
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  hiddenTitle:{
    display: 'none'
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, numSelectedApproved, numSelectedRejected, classes } = props;
  const {formatMessage} =props.intl;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
        [classes.hiddenTitle]: numSelected == 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} {formatMessage(messages.table.toolbar.selected).toLowerCase()}
          </Typography>
        ) : (
          <Typography  id="tableTitle" className={classes.hiddenTitle}>
            {props.title||''}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>

        {numSelected > 0 ? (
          <div style={{display: 'flex'}}>
            <div style={{marginRight: '20px'}}>
              {numSelectedApproved > 0?(
                <div style={numSelectedRejected > 0? null:{padding: '15% 0'}}>{numSelectedApproved} APROBADOS</div>
              ):null}
              {numSelectedRejected > 0?(
                <div style={numSelectedApproved > 0? null:{padding: '15% 0'}}>{numSelectedRejected} RECHAZADOS</div>
              ):null}
            </div>
            {numSelectedApproved || numSelectedRejected?(
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{flex: '1'}}
                onClick={()=>{
                  if (props.changeState){
                    props.changeState()
                  }
                }}
                >
                Confirmar
                <Icon className={classes.rightIcon}>check</Icon>
              </Button>
            ):null}
          </div>

        ) : (null)}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  numSelectedApproved: PropTypes.number,
  numSelectedRejected: PropTypes.number,
  changeState: PropTypes.func,
  
};

export default injectIntl(withStyles(toolbarStyles)(EnhancedTableToolbar));
