/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';
import {LISTING_STATES} from "../../config/constants"

export default defineMessages({
  "platformListing.synced": {
    id: "boilerplate.components.Emi.platformListing.synced",
    defaultMessage: "Synchronized Listings",
  },
  "platformListing.sync": {
    id: "boilerplate.components.Emi.platformListing.sync",
    defaultMessage: "Add new",
  },
  listing:{
    id: "boilerplate.components.Emi.listing",
    defaultMessage: "Listing",
  },
  status:{
    action:{
      activate:{
        id: "boilerplate.components.Emi.status.action.activate",
        defaultMessage: "Activate",
      },
      pause:{
        id: "boilerplate.components.Emi.status.action.pause",
        defaultMessage: "Pause"
      },
      end:{
        id: "boilerplate.components.Emi.status.action.end",
        defaultMessage: "Finalize"
      }
    },
    listing:{
      [LISTING_STATES.ACTIVE]:{
        id: 'boilerplate.components.Emi.status.listing.active',
        defaultMessage: 'Active',
      },
      [LISTING_STATES.PAUSED]:{
        id: 'boilerplate.components.Emi.status.listing.paused',
        defaultMessage: 'Paused',
      },
      [LISTING_STATES.ENDED]:{
        id: 'boilerplate.components.Emi.status.listing.ended',
        defaultMessage: 'Ended',
      },
      [LISTING_STATES.DELETED]:{
        id: 'boilerplate.components.Emi.status.listing.deleted',
        defaultMessage: 'Deleted',
      },
      [LISTING_STATES.HIDDEN]:{
        id: 'boilerplate.components.Emi.status.listing.hidden',
        defaultMessage: 'Hidden',
      },
    },
  },
  calendar:{
    months:{
      january:{
        id: 'boilerplate.components.Emi.calendar.months.january',
        defaultMessage: 'January',
      },
      february:{
        id: 'boilerplate.components.Emi.calendar.months.february',
        defaultMessage: 'February',
      },
      march:{
        id: 'boilerplate.components.Emi.calendar.months.march',
        defaultMessage: 'March',
      },
      april:{
        id: 'boilerplate.components.Emi.calendar.months.april',
        defaultMessage: 'April',
      },
      may:{
        id: 'boilerplate.components.Emi.calendar.months.may',
        defaultMessage: 'May',
      },
      june:{
        id: 'boilerplate.components.Emi.calendar.months.june',
        defaultMessage: 'June',
      },
      july:{
        id: 'boilerplate.components.Emi.calendar.months.july',
        defaultMessage: 'July',
      },
      august:{
        id: 'boilerplate.components.Emi.calendar.months.august',
        defaultMessage: 'August',
      },
      september:{
        id: 'boilerplate.components.Emi.calendar.months.september',
        defaultMessage: 'September',
      },
      october:{
        id: 'boilerplate.components.Emi.calendar.months.october',
        defaultMessage: 'October',
      },
      november:{
        id: 'boilerplate.components.Emi.calendar.months.november',
        defaultMessage: 'November',
      },
      december:{
        id: 'boilerplate.components.Emi.calendar.months.december',
        defaultMessage: 'December',
      },
    },
    weekDaysLong:{
      sunday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysLong.sunday',
        defaultMessage: 'Sunday',
      },
      monday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysLong.monday',
        defaultMessage: 'Monday',
      },
      tuesday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysLong.tuesday',
        defaultMessage: 'Tuesday',
      },
      wednesday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysLong.wednesday',
        defaultMessage: 'Wednesday',
      },
      thursday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysLong.thursday',
        defaultMessage: 'Thursday',
      },
      friday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysLong.friday',
        defaultMessage: 'Friday',
      },
      saturday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysLong.saturday',
        defaultMessage: 'Saturday',
      },
    },
    weekDaysShort:{
      sunday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysShort.sunday',
        defaultMessage: 'Su',
      },
      monday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysShort.monday',
        defaultMessage: 'Mo',
      },
      tuesday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysShort.tuesday',
        defaultMessage: 'Tu',
      },
      wednesday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysShort.wednesday',
        defaultMessage: 'We',
      },
      thursday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysShort.thursday',
        defaultMessage: 'Th',
      },
      friday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysShort.friday',
        defaultMessage: 'Fr',
      },
      saturday:{
        id: 'boilerplate.components.Emi.calendar.weekDaysShort.saturday',
        defaultMessage: 'Sa',
      },
    },
  },

  button:{
    details: {
      id: 'boilerplate.components.Emi.button.details',
      defaultMessage: 'Details',
    },
    createAnother: {
      id: 'boilerplate.components.Emi.button.createAnother',
      defaultMessage: 'Create another',
    },
    add: {
      id: 'boilerplate.components.Emi.button.add',
      defaultMessage: 'Add',
    },
    view: {
      id: 'boilerplate.components.Emi.button.view',
      defaultMessage: 'View',
    },
    edit: {
      id: 'boilerplate.components.Emi.button.edit',
      defaultMessage: 'Edit',
    },
    create: {
      id: 'boilerplate.components.Emi.button.create',
      defaultMessage: 'Create',
    },
    back: {
      id: 'boilerplate.components.Emi.button.back',
      defaultMessage: 'Go Back',
    },
    remove: {
      id: 'boilerplate.components.Emi.button.remove',
      defaultMessage: 'Remove',
    },
    cancel: {
      id: 'boilerplate.components.Emi.button.cancel',
      defaultMessage: 'Cancel',
    },
    confirm: {
      id: 'boilerplate.components.Emi.button.confirm',
      defaultMessage: 'Confirm',
    },
    approve: {
      id: 'boilerplate.components.Emi.button.approve',
      defaultMessage: 'Approve',
    },
    next: {
      id: 'boilerplate.components.Emi.button.next',
      defaultMessage: 'Next',
    },
    save: {
      id: 'boilerplate.components.Emi.button.save',
      defaultMessage: 'Save',
    },
    search: {
      id: 'boilerplate.components.Emi.button.search',
      defaultMessage: 'Search',
    },
    submit: {
      id: 'boilerplate.components.Emi.button.submit',
      defaultMessage: 'Submit',
    },
    close: {
      id: 'boilerplate.components.Emi.button.close',
      defaultMessage: 'Close',
    },
  },
  date: {
    format: {
      id: 'boilerplate.components.Emi.date.format',
      defaultMessage: 'M/D/YYYY',
    },
  },
  format: {
    date: {
      id: 'boilerplate.components.Emi.format.date',
      defaultMessage: 'MM/DD/YYYY',
    },
    datetime: {
      id: 'boilerplate.components.Emi.format.datetime',
      defaultMessage: 'MM/DD/YYYY h:mm A',
    },
    time: {
      id: 'boilerplate.components.Emi.format.time',
      defaultMessage: 'h:mm A',
    }
  },
  table: {
    noData: {
      id: 'boilerplate.components.Emi.table.noData',
      defaultMessage: 'No rows found'
    },
    nextPage: {
      id: 'boilerplate.components.Emi.table.nextPage',
      defaultMessage: 'Next Page'
    },
    previousPage: {
      id: 'boilerplate.components.Emi.table.previousPage',
      defaultMessage: 'Previous Page'
    },
    numberOfResults: {
      id: 'boilerplate.components.Emi.table.numberOfResults',
      defaultMessage: 'of {total} results'
    },
  }
});
