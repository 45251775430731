// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import Hotjar from '@hotjar/browser';
import { withErrorPage } from './components/Error/ErrorPageBoundary';
import track from './utils/track'

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

const updateTracking = async (page) => {
  await track({
    page: page,
    type: 'pageView',
  })
  Hotjar.stateChange(page)
}

const NOT_FOUND_ROUTE = {
  path: '*',
  name: 'notfound',
  getComponent(nextState, cb) {
    import('containers/NotFoundPage')
      .then(loadModule(cb))
      .catch(errorLoading);
  },
}

export default function createRoutes(store) {
  return [
    {
      path: '/',
      name: 'dashboardPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Home/index'),
        ]);
        updateTracking('home'); //todo ?
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/home',
      name: 'Incio',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Home/index'),
        ]);
        updateTracking('home'); //todo ?
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute({ ...component, default: withErrorPage(component.default) });
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/tutorials',
      name: 'tutorialPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Documentation/index'),
        ]);
        updateTracking('tutorials');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/interview',
      name: 'interviewPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Interview/index'),
        ]);
        updateTracking('interview');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/platforms',
      name: 'platformPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Platform/Page/index'),
        ]);
        updateTracking('platforms');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/attraction',
      name: 'attractionPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Attraction/index'),
        ]);
        updateTracking('attraction');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/candidate_load',
      name: 'candidateLoadPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/CandidateCreator/index'),
        ]);
        updateTracking('candidate_load');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/candidates/add',
      name: 'addCandidatePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Candidates/PrivateOxxoApplicationPage/PrivateApplicationPage.js'),
        ]);
        updateTracking('addCandidatePage');
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
        importModules.catch(errorLoading);
      },
    },
    {
      path: '/vacancies',
      name: 'vacanviesPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Vacancies/index'),
        ]);
        updateTracking('vacancies');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/vacancies/approve',
      name: 'vacancyApprovalPage',
      type: 'public',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Vacancies/approve'),
        ]);

        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
      },
    },
    {
      path: '/vacancies/reject',
      name: 'vacancyRejectPage',
      type: 'public',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Vacancies/reject'),
        ]);

        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
      },
    },
    {
      path: '/vacancies/:id',
      name: 'vacancieDetailPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Vacancies/VacancyPage'),
        ]);
        updateTracking('vacancies');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/vacancies/:id/edit',
      name: 'vacancieEditPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Vacancies/EditVacancyPage'),
        ]);
        updateTracking('vacancies');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/listings',
      name: 'listingsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Listings/List/index'),
        ]);
        updateTracking('/listings');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/interviews',
      name: 'interviewSlotsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('pages/interviews/index'),
        ]);
        updateTracking('/interviews');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/candidates',
      name: 'candidatesPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('pages/candidates/index'),
        ]);
        updateTracking('/candidates');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/requisitions',
      name: 'requisitionsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/RequisitionsPage/index'),
        ]);
        updateTracking('/requisitions');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/requisitions/create',
      name: 'newRequisitionsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/NewRequisition/index'),
        ]);
        updateTracking('/requisitions/create');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/requisitions/:id/edit',
      name: 'editRequisitionsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/NewRequisition/index'),
        ]);
        updateTracking('/requisitions/edit');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/listings/create',
      name: 'listingsCreatePage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Listings/CreateEdit/index'),
        ]);
        updateTracking('listings/create');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: "/listing/dashboard/:listingId",
      name: 'dashboardPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Listings/Dashboard/index'),
        ]);
        updateTracking('/listing/dashboard')
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: "/pendingApproval",
      name: 'stepViewsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/StepViews/Step/pendingApproval'),
        ]);
        updateTracking('pendingApproval');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/suggestions',
      name: 'suggestionsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Suggestions/List/index'),
        ]);
        updateTracking('suggestions');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/mysuggestions',
      name: 'mysuggestionsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Suggestions/CreateEdit/index'),
        ]);
        updateTracking('mySuggestions');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: '/candidate_search',
      name: 'candidateSearchPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Candidate/Search/index'),
        ]);
        updateTracking('candidateSearch');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/flows',
      name: 'flowsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Flow/List/index'),
        ]);
        updateTracking('flows');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/companies',
      name: 'companiesPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Company/List/index'),
        ]);
        updateTracking('companies');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/analytics',
      name: 'emiAnalytics',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/EmiAnalytics/Companies/index'),
        ]);
        updateTracking('analytics');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/subsidiaries',
      name: 'subsidiariesPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Subsidiary/List/index'),

        ]);
        updateTracking('subsidiaries');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/users',
      name: 'usersPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/User/List/index'),
        ]);
        updateTracking('users');
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
        importModules.catch(errorLoading);
      },
    },
    {
      path: '/integrations',
      name: 'integrationsPage',
      getComponent (nextState, cb) {
        const importModules = Promise.all([
          import('containers/Integrations/index'),
        ]);
        // updateTracking('integrations');

        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });

      }
    },
    {
      path: '/candidateBag',
      name: 'candidateBagPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/CandidateBag/index'),
        ]);
        // updateTracking('integrations');

        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });

      }
    },
    {
      path: '/status',
      name: 'statusPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Status/index'),
        ]);
        // updateTracking('integrations');

        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });


      },
    },
    {
      path: '/oxxo/candidates/apply/recruiter',
      name: 'oxxoApplicationPageRecruiter',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Candidates/OxxoApplicationPage'),
        ]);
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
      },
    },
    {
      path: '/oxxo/candidates/apply',
      name: 'oxxoApplicationPage',
      type: 'standalone',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Candidates/OxxoApplicationPage'),
        ]);
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
      },
    },
    {
      path: '/oxxo/candidates/agenda',
      name: 'oxxoApplicationPageAgenda',
      type: 'private-standalone',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Candidates/OxxoApplicationPage'),
        ]);
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
      },
    },
    {
      path: '/oxxo',
      name: 'oxxoNotFound',
      type: 'standalone',
      getComponent(nextState, cb) {
        import('containers/NotFoundPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/oxxo/*',
      name: 'oxxoNotFound',
      type: 'standalone',
      onEnter: (_, replace) => replace('/oxxo'),
    },
    {
      path: '/public/candidates/apply/:id',
      name: 'publicApplicationPage',
      type: 'standalone',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/Candidates/PublicApplicationPage'),
        ]);
        const renderRoute = loadModule(cb);
        importModules.then(([component]) => {
          renderRoute(component);
        });
      },
    },
    {
      path: '/recurrent-slots',
      name: 'RecurrentInterviewSlotsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('pages/recurrentInterviewSlots/index'),
        ]);
        updateTracking('/recurrent-interview-slots');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/candidate-re-engagement',
      name: 'CampaignsPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('pages/campaigns/index'),
        ]);
        updateTracking('/candidate-re-engagement');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/candidate-re-engagement/create-campaign',
      name: 'CreateCandidateReEngagementCampaign',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('pages/createCandidateReEngagementCampaign/index'),
        ]);
        updateTracking('/create-candidate-re-engagement-campaign');
        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: '/public',
      name: 'publicNotFound',
      type: 'standalone',
      getComponent(nextState, cb) {
        import('containers/NotFoundPage')
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/public/*',
      name: 'publicNotFound',
      type: 'standalone',
      onEnter: (_, replace) => replace('/public'),
    },
    NOT_FOUND_ROUTE
  ];
}

export function filterRoutes(routes, { user, extra }) {
  const isPublicRoute = (route) => route.isPublic === true || route.type === 'public' || route.type === 'standalone' || route.path === '/public' || route.path.startsWith('/public/')
  const isOxxoRoute = (route) => route.path === '/oxxo' || route.path.startsWith('/oxxo/')
  if (user === 'guest') {
    const publicRoutes = routes.filter(isPublicRoute)
    return [...publicRoutes, NOT_FOUND_ROUTE]
  }
  if (user === 'authenticated') {
    return extra && extra.subsidiaryId === 51 ? routes.filter(r => !isOxxoRoute(r)) : routes
  }
  return routes
}