import React from 'react';
import { browserHistory } from 'react-router';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IconButton, Icon } from '@material-ui/core';

import EmiLogo from '../assets/images/emi-logo-nt.svg';
import * as appActions from '../../containers/App/actions';
import { showHelpAndSupport } from '../../store/candidateActions/actions'
import { makeSelectGlobal, isFakeUser } from '../../containers/App/selectors';
import Theme from '../../config/theme';
import Styles from './styles';
import AppBar from '../../v2/components/others/AppBar'

import messages from './messages';
import { scrollToMenuItemAndOpenViews } from '../LeftDrawer/menuUtils';
import { useUser } from '../../utils/hooks';
import { usePrivacyPolicyConfig } from '../../containers/ConfigProvider'
import mockMenuApi from '../../api/mockMenuApi';

const theme = new Theme();

const HeaderWithConfig = (props) => {
  const user = useUser()
  const { getPrivacyPolicyConfig } = usePrivacyPolicyConfig()
  const { menus } = mockMenuApi.getMenu(user)
  return <Header 
    privacyPolicyConfig={getPrivacyPolicyConfig(user.subsidiaryId)}
    menus={menus}
    {...props}
  />
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTheme: theme.get(props.appStore.currentTheme),
    };

    this.signOut = this.signOut.bind(this);
    this.support = this.support.bind(this);
    this.navigateUrl = this.navigateUrl.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.appStore.currentTheme !== this.props.appStore.currentTheme) {
      this.setState({
        currentTheme: theme.get(newProps.appStore.currentTheme),
      });
    }
  }

  support() {
    this.props.actions.showHelpAndSupport()
  }

  signOut() {
    this.props.actions.signOut();
  }

  navigateUrl(menuItem) {
    browserHistory.push(menuItem.url);
    scrollToMenuItemAndOpenViews(
      menuItem,
      this.props.menus,
      this.props.appStore.openViews,
    );
  }

  render() {
    const {
      styles,
      handleChangeRequestNavDrawer,
      appStore,
      isMobileBrowser,
      isFakeUser,
      menus
    } = this.props;
    const { formatMessage } = this.props.intl;
    const style = Styles(appStore.isBoxedLayout, this.state.currentTheme);

    if (isMobileBrowser) {
      return (
        <AppBar
          position="static"
          style={{ ...styles, ...style.appBar }}
          title={<img src={EmiLogo} style={style.emiLogoMobile} />}
          iconElementLeft={
            <IconButton
              iconStyle={style.iconButton}
              style={style.menuButton}
              onClick={handleChangeRequestNavDrawer}
            >
              {this.props.navDrawerOpen ? (
                <Icon
                  color={this.state.currentTheme.appBarMenuButtonColor}
                  className="material-icons"
                >
                  chevron_left
                </Icon>
              ) : (
                <Icon
                  color={this.state.currentTheme.appBarMenuButtonColor}
                  className="material-icons"
                >
                  menu
                </Icon>
              )}
            </IconButton>
          }
        />
      );
    }

    const location = browserHistory.getCurrentLocation()
    let currentItem = menus.find(menu => location.pathname.startsWith(menu.url))
    if (!currentItem) {
      currentItem = appStore.selectedOpenedMenuItem || { id: -1 }
    }

    const buildTabs = (menuItems) => (
      menuItems.map(item => ({
        selected: item.id === currentItem.id,
        label: formatMessage(messages.tabnavs.header[item.textId]),
        onClick: () => this.navigateUrl(item),
        icon: item.icon
      }))
    )

    const buildProfileMenuItems = () => {
      const profileMenuItems = []
      if (this.props.privacyPolicyConfig && this.props.privacyPolicyConfig.show) {
        const { link, label } = this.props.privacyPolicyConfig
        profileMenuItems.push({
          label: label,
          onClick: () => window.open(link, "_blank")
        })
      }
      profileMenuItems.push({
        label: formatMessage(messages.tabnavs.header.helpAndSupport),
        onClick: this.support
      })
      profileMenuItems.push({
        label: formatMessage(messages.tabnavs.header.signOut),
        onClick: this.signOut
      })
      return profileMenuItems
    }

    const mainTab = buildTabs(menus.filter(item => item.type === 'main'))
    const secondaryTab = buildTabs(menus.filter(item => item.type === 'secondary'))

    const profileMenu = {
      label: formatMessage(messages.tabnavs.header.profile),
      items: buildProfileMenuItems()
    }

    const moreOptionsMenu = {
      label: formatMessage(messages.tabnavs.header.moreOptions),
      items: buildTabs(menus.filter(item => item.type === 'subMenu'))
    };

    return <AppBar
      onLogoClick={() => browserHistory.push('/')}
      mainTab={mainTab}
      secondaryTab={secondaryTab}
      moreOptionsMenu={moreOptionsMenu}
      onSignOut={this.signOut}
      profileMenu={profileMenu}
      isFakeUser={isFakeUser}
    />
  }
}

Header.propTypes = {
  navDrawerOpen: PropTypes.bool,
  styles: PropTypes.object,
  handleChangeRequestNavDrawer: PropTypes.func,
  actions: PropTypes.any,
  appStore: PropTypes.any,
  isMobileBrowser: PropTypes.bool,
  location: PropTypes.any,
  privacyPolicyConfig: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
  appStore: makeSelectGlobal(),
  isFakeUser: isFakeUser()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...appActions, showHelpAndSupport }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeaderWithConfig));
