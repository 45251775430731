const NAMESPACE = 'CANDIDATE_ACTIONS'

export const SHOW_LOG = `${NAMESPACE}/SHOW_LOG`
export const REQUEST_LOG = `${NAMESPACE}/REQUEST_LOG`
export const REQUEST_LOG_SUCCEED = `${NAMESPACE}/REQUEST_LOG_SUCCEED`
export const REQUEST_LOG_FAIL = `${NAMESPACE}/REQUEST_LOG_FAIL`
export const HIDE_LOG = `${NAMESPACE}/HIDE_LOG`

export const HIDE_RESUME = `${NAMESPACE}/HIDE_RESUME`
export const REQUEST_RESUME = `${NAMESPACE}/REQUEST_RESUME`

export const HIDE_INTERVIEW_CANCEL = `${NAMESPACE}/HIDE_INTERVIEW_CANCEL`
export const REQUEST_INTERVIEW_CANCEL = `${NAMESPACE}/REQUEST_INTERVIEW_CANCEL`

export const HIDE_GO_TO_CANDIDATE = `${NAMESPACE}/HIDE_GO_TO_CANDIDATE`
export const REQUEST_GO_TO_CANDIDATE = `${NAMESPACE}/REQUEST_GO_TO_CANDIDATE`

export const HIDE_INTERVIEW_INVITE = `${NAMESPACE}/HIDE_INTERVIEW_INVITE`
export const REQUEST_INTERVIEW_INVITE = `${NAMESPACE}/REQUEST_INTERVIEW_INVITE`

export const HIDE_INTERVIEW_RESCHEDULE = `${NAMESPACE}/HIDE_INTERVIEW_RESCHEDULE`
export const REQUEST_INTERVIEW_RESCHEDULE = `${NAMESPACE}/REQUEST_INTERVIEW_RESCHEDULE`

export const HIDE_HIRE = `${NAMESPACE}/HIDE_HIRE`
export const REQUEST_HIRE = `${NAMESPACE}/REQUEST_HIRE`

export const SHOW_ADVANCE = `${NAMESPACE}/SHOW_ADVANCE`
export const HIDE_ADVANCE = `${NAMESPACE}/HIDE_ADVANCE`
export const REQUEST_ADVANCE = `${NAMESPACE}/REQUEST_ADVANCE`
export const REQUEST_ADVANCE_SUCCEED = `${NAMESPACE}/REQUEST_ADVANCE_SUCCEED`
export const REQUEST_ADVANCE_FAIL = `${NAMESPACE}/REQUEST_ADVANCE_FAIL`

export const SHOW_REJECT = `${NAMESPACE}/SHOW_REJECT`
export const HIDE_REJECT = `${NAMESPACE}/HIDE_REJECT`
export const REQUEST_REJECT = `${NAMESPACE}/REQUEST_REJECT`
export const REQUEST_REJECT_SUCCEED = `${NAMESPACE}/REQUEST_REJECT_SUCCEED`
export const REQUEST_REJECT_FAIL = `${NAMESPACE}/REQUEST_REJECT_FAIL`

export const SHOW_STAND_BY = `${NAMESPACE}/SHOW_STAND_BY`
export const HIDE_STAND_BY = `${NAMESPACE}/HIDE_STAND_BY`
export const REQUEST_STAND_BY = `${NAMESPACE}/REQUEST_STAND_BY`
export const REQUEST_STAND_BY_SUCCEED = `${NAMESPACE}/REQUEST_STAND_BY_SUCCEED`
export const REQUEST_STAND_BY_FAIL = `${NAMESPACE}/REQUEST_STAND_BY_FAIL`

export const CANDIDATE_CHANGED = `${NAMESPACE}/CANDIDATE_CHANGED`

export const SHOW_SPECIAL_NEEDS = `${NAMESPACE}/SHOW_SPECIAL_NEEDS`
export const HIDE_SPECIAL_NEEDS = `${NAMESPACE}/HIDE_SPECIAL_NEEDS`
export const REQUEST_SPECIAL_NEEDS = `${NAMESPACE}/REQUEST_SPECIAL_NEEDS`
export const REQUEST_SPECIAL_NEEDS_SUCCEED = `${NAMESPACE}/REQUEST_SPECIAL_NEEDS_SUCCEED`
export const REQUEST_SPECIAL_NEEDS_FAIL = `${NAMESPACE}/REQUEST_SPECIAL_NEEDS_FAIL`

export const SHOW_CHANGE_VACANCY = `${NAMESPACE}/SHOW_CHANGE_VACANCY`
export const HIDE_CHANGE_VACANCY = `${NAMESPACE}/HIDE_CHANGE_VACANCY`

export const SHOW_EDIT_CANDIDATE_DATA = `${NAMESPACE}/SHOW_EDIT_CANDIDATE_DATA`
export const REQUEST_EDIT_CANDIDATE_DATA = `${NAMESPACE}/REQUEST_EDIT_CANDIDATE_DATA`
export const REQUEST_EDIT_CANDIDATE_DATA_SUCCEED = `${NAMESPACE}/REQUEST_EDIT_CANDIDATE_DATA_SUCCEED`
export const REQUEST_EDIT_CANDIDATE_DATA_FAIL = `${NAMESPACE}/REQUEST_EDIT_CANDIDATE_DATA_FAIL`
export const HIDE_EDIT_CANDIDATE_DATA = `${NAMESPACE}/HIDE_EDIT_CANDIDATE_DATA`

export const SHOW_HELP_AND_SUPPORT = `${NAMESPACE}/SHOW_HELP_AND_SUPPORT`
export const HIDE_HELP_AND_SUPPORT = `${NAMESPACE}/HIDE_HELP_AND_SUPPORT`