/*
 *
 * PlatformPage actions
 *
 */

import {
  GET_DOCUMENTATION,
  GET_DOCUMENTATION_SUCCESS,
  GET_DOCUMENTATION_ERROR,
  CLEAR_MESSAGE
} from './constants'

export function getDocumentation () {
  return {
    type: GET_DOCUMENTATION
  }
}

export function getDocumentationSuccess (documentation) {
  return {
    type: GET_DOCUMENTATION_SUCCESS,
    payload: documentation
  }
}

export function getDocumentationError (type, error) {
  return {
    type: GET_DOCUMENTATION_ERROR,
    payload: {
      type,
      error
    }
  }
}

export function clearMessage () {
  return {
    type: CLEAR_MESSAGE
  }
}
