
const ping = async ([url, method]) => {
  //TODO: this should not be here
  try {
    const result = await fetch(url, {
      method
    })
    if (!!result.ok) {
      return {
        url,
        status: 'ok',
      }
    }
    const text = await result.text()
    return {
      url,
      status: 'error',
      message: `statusCode=${result.status} - type=${result.type}`,
      text
    };

  } catch (err) {
    return {
      url,
      status: 'exception',
      err: err.message,
      asString: err.toString()
    }
  }
}

const pingTawkTo = async (urls) => {
  const result = await Promise.all(urls.map(x => ping(x)))
  return result.reduce((acc, current, index) => ({ ...acc, [`ping_${index}`]: current }), {})
}

export const getInformation = async () => {
  //TODO: update tawkto-react to v3 which provides a clean API to get this information
  const status = !window.Tawk_API
    ? '#feature off#'
    : window.Tawk_API.getStatus && typeof window.Tawk_API.getStatus === 'function'
      ? window.Tawk_API.getStatus()
      : '#unknown#';
  const pingResult = await pingTawkTo([
    ['https://embed.tawk.to/5ed55f858ee2956d73a693ed/default', 'GET'],
    ['https://va.tawk.to/v1/session/start', 'POST'],
    ['https://va.tawk.to/log-performance/v3', 'POST'],
    ['https://embed.tawk.to/_s/v4/app/647857c834f/js/twk-chunk-common.js', 'GET'],
    ['https://va.tawk.to/v1/widget-settings?propertyId=5ed55f858ee2956d73a693ed&widgetId=default&sv=undefined', 'GET']
  ]);

  return {
    status,
    ...pingResult
  }
}