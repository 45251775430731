import { createSelector } from 'reselect';
import { get } from 'lodash'
/**
 * The global state selectors
 */

const selectGlobal = () => (state) => state.get('global');

const makeSelectGlobal = () => createSelector(
  selectGlobal(),
  (substate) => substate.toJS()
);

const isEmployee = () => createSelector(
  selectGlobal(),
  (global) => get(global.toJS(), 'user.roles', []).includes('sys_admin')
);

const isUserAuthorized = (sections) => createSelector(
  selectGlobal(),
  (global) => {
    const { user } = global.toJS()
    const result = user && user.authorizedSections && user.authorizedSections.some(section => sections.includes(section))
    return result
  }
)

const isFakeUser = () => createSelector(
  selectGlobal(),
  (global) => {
    const { user } = global.toJS()
    return user && user.isFake
  }
)

const makeSelectIsAuthenticated = () => createSelector(
  selectGlobal(),
  (global) => get(global.toJS(), 'session') !== null
);


export const makeSelectSession = () => createSelector(
  selectGlobal(),
  (global) => get(global.toJS(), 'session')
);

export const makeSelectCognitoUser = () => createSelector(
  selectGlobal(),
  (global) => get(global.toJS(), 'cognitoUser')
);


const makeSelectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state) => {
    const routingState = state.get('route'); // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

export {
  selectGlobal,
  makeSelectGlobal,
  isUserAuthorized,
  isEmployee,
  isFakeUser,
  makeSelectIsAuthenticated,
  makeSelectLocationState,
};
