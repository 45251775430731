
/*
 *
 * PlatformPage actions
 *
 */

import {
  CLEAN_LOCAL_DATA,
  SET_VIEW_CONFIG,
  GET_STATE_DATA,
  GET_PENDING_APPROVAL_LISTINGS,
  GET_PENDING_APPROVAL_LISTINGS_SUCCESS,
  GET_PENDING_APPROVAL_LISTINGS_ERROR,
  GET_REASONS_REJECTION,
  GET_REASONS_REJECTION_SUCCESS,
  GET_REASONS_REJECTION_ERROR,
  GET_VIEW_DATA,
  GET_VIEW_DATA_SUCCESS,
  GET_VIEW_DATA_ERROR,
  GET_STEP_VIEWS,
  GET_STEP_VIEWS_SUCCESS,
  GET_STEP_VIEWS_ERROR,
  GET_VIEW_CONFIG,
  GET_VIEW_CONFIG_SUCCESS,
  GET_VIEW_CONFIG_ERROR,
  UPDATE_ANSWER,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_ERROR,
  RESET_SLOT_AVAILABILITY,
  ADD_REASONS_REJECTION,
  ADD_REASONS_REJECTION_WAITING,
  ADD_REASONS_REJECTION_SUCCESS,
  ADD_REASONS_REJECTION_ERROR,
  REMOVE_REASONS_REJECTION,
  REMOVE_REASONS_REJECTION_WAITING,
  REMOVE_REASONS_REJECTION_SUCCESS,
  REMOVE_REASONS_REJECTION_ERROR,
  APPROVE_CANDIDATES,
  APPROVE_CANDIDATES_SUCCESS,
  APPROVE_CANDIDATES_ERROR,
  CANDIDATES_TO_NEXT_STEP,
  CANDIDATES_TO_NEXT_STEP_SUCCESS,
  CANDIDATES_TO_NEXT_STEP_ERROR,
  CLEAR_MESSAGE,
  GET_INTERVIEW_CONFIG,
  GET_INTERVIEW_CONFIG_SUCCESS,
  GET_INTERVIEW_CONFIG_ERROR,
  SAVE_INTERVIEW_SLOT,
  SAVE_INTERVIEW_SLOT_SUCCESS,
  SAVE_INTERVIEW_SLOT_ERROR,
  GET_SLOT_AVAILABILITY,
  GET_SLOT_AVAILABILITY_SUCCESS,
  GET_SLOT_AVAILABILITY_ERROR,
  GET_INTERVIEW_SLOTS,
  GET_INTERVIEW_SLOTS_SUCCESS,
  GET_INTERVIEW_SLOTS_ERROR,
  GET_CANDIDATES_IN_INTERVIEW,
  GET_CANDIDATES_IN_INTERVIEW_SUCCESS,
  GET_CANDIDATES_IN_INTERVIEW_ERROR,
  SET_CANDIDATE_ASSISTANCE_STEP,
  SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS,
  SET_CANDIDATE_ASSISTANCE_STEP_ERROR,
  CANCEL_INTERVIEW,
  CANCEL_INTERVIEW_SUCCESS,
  CANCEL_INTERVIEW_ERROR,
  CLOSE_INTERVIEW,
  CLOSE_INTERVIEW_SUCCESS,
  CLOSE_INTERVIEW_ERROR,
  GET_HIRING_MANAGERS,
  GET_HIRING_MANAGERS_SUCCESS,
  GET_HIRING_MANAGERS_ERROR,
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
  GET_POSITIONS,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_ERROR,
  GET_VACANCIES,
  GET_VACANCIES_SUCCESS,
  GET_VACANCIES_ERROR,
  NEW_REQUISITION_CREATED,
  LOADING,
} from './constants';
import { GET_CANDIDATES, GET_CANDIDATES_ERROR, GET_CANDIDATES_SUCCESS } from '../Interview/constants';


export function setLoading(key, loading) {
  return {
    type: LOADING,
    key,
    loading,
  };
}

export function getPendingApprovalData(
  tableClass,
  listingId,
  stepId,
  offset,
  limit,
  filters,
  sort,
  rejected,
) {
  console.log('GET_STATE_DATA');
  return {
    type: GET_STATE_DATA,
    endpoint: rejected ? 'pendingRejection' : 'pendingApproval',
    tableClass,
    listingId,
    stepId,
    offset,
    limit,
    filters,
    sort,
    rejected,
  };
}

export function getStateData(
  tableClass,
  listingId,
  stepId,
  offset,
  limit,
  filters,
  sort,
  rejected,
  stepType,
  showCandidatesAt,
  showRejectedCandidatesAt,
  filtersMetadata
) {
  console.log('GET_STATE_DATA');
  const endpoint = rejected ? 'rejected' : 'approved';
  const showCandidatesAtState = rejected ? showRejectedCandidatesAt : showCandidatesAt
  return {
    type: GET_STATE_DATA,
    endpoint,
    tableClass,
    listingId,
    stepId,
    offset,
    limit,
    filters,
    sort,
    rejected,
    stepType,
    showCandidatesAt: showCandidatesAtState,
    filtersMetadata
  };
}

export function getViewData(
  tableClass,
  listingId,
  stepId,
  offset,
  limit,
  filters,
  sort,
  rejected,
) {
  console.log('GET_VIEW_DATA');
  return {
    type: GET_VIEW_DATA,
    tableClass,
    listingId,
    stepId,
    offset,
    limit,
    filters,
    sort,
    rejected,
  };
}

export function getViewDataSuccess(viewData) {
  return {
    type: GET_VIEW_DATA_SUCCESS,
    viewData,
  };
}

export function getViewDataError(error) {
  return {
    type: GET_VIEW_DATA_ERROR,
    error,
  };
}

export function getCandidates() {
  console.log('GET_CANDIDATES');
  return {
    type: GET_CANDIDATES,
  };
}

export function getCandidatesSuccess(candidates) {
  return {
    type: GET_CANDIDATES_SUCCESS,
    candidates,
  };
}

export function getCandidatesError(error) {
  return {
    type: GET_CANDIDATES_ERROR,
    error,
  };
}

export function getCandidatesDescartess() {
  console.log('GET_CANDIDATES_DESCARTES');
  return {
    type: GET_CANDIDATES_DESCARTES,
  };
}

export function getCandidatesDescartesSuccess(candidates) {
  return {
    type: GET_CANDIDATES_DESCARTES_SUCCESS,
    candidates,
  };
}

export function getCandidatesDescartesError(error) {
  return {
    type: GET_CANDIDATES_DESCARTES_ERROR,
    error,
  };
}

export function getStepViews() {
  console.log('GET_STEP_VIEWS');
  return {
    type: GET_STEP_VIEWS,
  };
}

export function getStepViewsSuccess(stepViews) {
  return {
    type: GET_STEP_VIEWS_SUCCESS,
    stepViews,
  };
}

export function getStepViewsError(error) {
  return {
    type: GET_STEP_VIEWS_ERROR,
    error,
  };
}

export function getViewConfig(listingId, stepId, viewType) {
  console.log('GET_VIEW_CONFIG');
  return {
    type: GET_VIEW_CONFIG,
    listingId,
    stepId,
    viewType,
  };
}

export function getViewConfigSuccess(viewConfig) {
  return {
    type: GET_VIEW_CONFIG_SUCCESS,
    viewConfig,
  };
}

export function getViewConfigError(error) {
  return {
    type: GET_VIEW_CONFIG_ERROR,
    error,
  };
}

export function getReasonsRejection(listingId, stepId) {
  console.log('GET_REASONS_REJECTION');
  return {
    type: GET_REASONS_REJECTION,
    listingId,
    stepId,
  };
}

export function getReasonsRejectionSuccess(reasonsData) {
  return {
    type: GET_REASONS_REJECTION_SUCCESS,
    reasonsData,
  };
}

export function getReasonsRejectionError(error) {
  return {
    type: GET_REASONS_REJECTION_ERROR,
    error,
  };
}

export function cleanLocalData(listingId, stepId, tableClass, rejected) {
  console.log('CLEAN_LOCAL_DATA', listingId, stepId, tableClass, rejected);
  return {
    type: CLEAN_LOCAL_DATA,
    listingId,
    stepId,
    tableClass,
    rejected,
  };
}

export function updateAnswer({
  candidateId,
  listingId,
  stepId,
  tableClass,
  rejected,
  tableIndex,
  questionIndex,
  value
}) {
  console.log("UPDATE_ANSWER")
  return {
    type: UPDATE_ANSWER,
    candidateId,
    listingId,
    stepId,
    tableClass,
    rejected,
    tableIndex,
    questionIndex,
    value,
  };
}

export function updateAnswerSuccess(updateData) {
  return {
    type: UPDATE_ANSWER_SUCCESS,
    updateData,
  };
}

export function updateAnswerError(error) {
  return {
    type: UPDATE_ANSWER_ERROR,
    error,
  };
}

export function getPendingApprovalListings() {
  console.log('GET_PENDING_APPROVAL_LISTINGS');
  return {
    type: GET_PENDING_APPROVAL_LISTINGS,
  };
}

export function getPendingApprovalListingsSuccess(listings) {
  return {
    type: GET_PENDING_APPROVAL_LISTINGS_SUCCESS,
    listings,
  };
}

export function getPendingApprovalListingsError(error) {
  return {
    type: GET_PENDING_APPROVAL_LISTINGS_ERROR,
    error,
  };
}

export function addReasonsRejection(
  reasons,
  candidateId,
  listingId,
  stepId,
  tableClass,
  rejected,
  tableIndex,
) {
  console.log('ADD_REASONS_REJECTION');
  return {
    type: ADD_REASONS_REJECTION,
    reasons,
    candidateId,
    listingId,
    stepId,
    tableClass,
    rejected,
    tableIndex,
  };
}

export function addReasonsRejectionWaiting(
  reasons,
  listingId,
  stepId,
  tableClass,
  rejected,
  tableIndex,
) {
  return {
    type: ADD_REASONS_REJECTION_WAITING,
    reasons,
    listingId,
    stepId,
    tableClass,
    rejected,
    tableIndex,
  };
}

export function addReasonsRejectionSuccess(
  reasons,
  listingId,
  stepId,
  tableClass,
  rejected,
  tableIndex,
) {
  return {
    type: ADD_REASONS_REJECTION_SUCCESS,
    reasons,
    listingId,
    stepId,
    tableClass,
    rejected,
    tableIndex,
  };
}

export function addReasonsRejectionError(error) {
  return {
    type: ADD_REASONS_REJECTION_ERROR,
    error,
  };
}

export function resetSlotAvailability() {
  return {
    type: RESET_SLOT_AVAILABILITY,
  };
}

export function removeReasonsRejection(
  reasons,
  listingId,
  stepId,
  tableClass,
  rejected,
  tableIndex,
) {
  console.log('REMOVE_REASONS_REJECTION');
  return {
    type: REMOVE_REASONS_REJECTION,
    reasons,
    listingId,
    stepId,
    tableClass,
    rejected,
    tableIndex,
  };
}

export function removeReasonsRejectionSuccess(
  reasons,
  listingId,
  stepId,
  tableClass,
  rejected,
  tableIndex,
) {
  return {
    type: REMOVE_REASONS_REJECTION_SUCCESS,
    reasons,
    listingId,
    stepId,
    tableClass,
    rejected,
    tableIndex,
  };
}

export function removeReasonsRejectionWaiting(
  reasons,
  listingId,
  stepId,
  tableClass,
  rejected,
  tableIndex,
) {
  return {
    type: REMOVE_REASONS_REJECTION_WAITING,
    reasons,
    listingId,
    stepId,
    tableClass,
    rejected,
    tableIndex,
  };
}

export function removeReasonsRejectionError(error) {
  return {
    type: REMOVE_REASONS_REJECTION_ERROR,
    error,
  };
}

export function approveCandidates(
  listingId,
  stepId,
  approved,
  rejected,
  tableClass,
) {
  console.log('APPROVE_CANDIDATES');
  return {
    type: APPROVE_CANDIDATES,
    listingId,
    stepId,
    approved,
    rejected,
    tableClass,
  };
}

export function approveCandidatesSuccess(
  listingId,
  stepId,
  approved,
  rejected,
  tableClass,
) {
  return {
    type: APPROVE_CANDIDATES_SUCCESS,
    listingId,
    stepId,
    approved,
    rejected,
    tableClass,
  };
}

export function approveCandidatesError(error) {
  return {
    type: APPROVE_CANDIDATES_ERROR,
    error,
  };
}

// candidates: an array with the candidate_id
// rejected: boolean
export function candidatesToNextStep(
  listingId,
  stepId,
  rejected,
  tableClass,
  approvedIds,
  rejectedIds,
) {
  console.log('CANDIDATES_TO_NEXT_STEP');
  return {
    type: CANDIDATES_TO_NEXT_STEP,
    listingId,
    stepId,
    rejected,
    tableClass,
    approvedIds,
    rejectedIds,
  };
}

export function candidatesToNextStepSuccess(
  listingId,
  stepId,
  rejected,
  tableClass,
  approvedIds,
  rejectedIds,
) {
  return {
    type: CANDIDATES_TO_NEXT_STEP_SUCCESS,
    listingId,
    stepId,
    rejected,
    tableClass,
    approvedIds,
    rejectedIds,
  };
}

export function candidatesToNextStepError(error) {
  return {
    type: CANDIDATES_TO_NEXT_STEP_ERROR,
    error,
  };
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function setViewConfig(listingId, stepId, config) {
  console.log('SET_VIEW_CONFIG');
  return {
    type: SET_VIEW_CONFIG,
    listingId,
    stepId,
    config,
  };
}

// get the config for a Interview step
export function getInterviewConfig(listingId, stepId) {
  console.log('GET_INTERVIEW_CONFIG');
  return {
    type: GET_INTERVIEW_CONFIG,
    listingId,
    stepId,
  };
}

export function getInterviewConfigSuccess(listingId, stepId, interviewConfig) {
  return {
    type: GET_INTERVIEW_CONFIG_SUCCESS,
    interviewConfig,
    listingId,
    stepId,
  };
}

export function getInterviewConfigError(error) {
  return {
    type: GET_INTERVIEW_CONFIG_ERROR,
    error,
  };
}

export function saveInterviewSlot(config, listingId, candidateId, assignmentId,subsidiaryId, cb) {
  console.log('SAVE_INTERVIEW_SLOT');
  return {
    type: SAVE_INTERVIEW_SLOT,
    config,
    listingId,
    candidateId,
    assignmentId,
    subsidiaryId,
    cb
  };
}

export function saveInterviewSlotSuccess() {
  return {
    type: SAVE_INTERVIEW_SLOT_SUCCESS,
  };
}

export function saveInterviewSlotError(error) {
  return {
    type: SAVE_INTERVIEW_SLOT_ERROR,
    error,
  };
}

export function getInterviewSlots(
  listingId,
  stepId,
  slotType = 'PAST',
  status,
  offset = 0,
  limit = 20,
  userId = null,
  sort
) {
  console.log('GET_INTERVIEW_SLOTS');
  return {
    type: GET_INTERVIEW_SLOTS,
    listingId,
    stepId,
    slotType,
    status,
    offset,
    limit,
    userId,
    sort
  };
}

export function getInterviewSlotsSuccess(slots) {
  return {
    type: GET_INTERVIEW_SLOTS_SUCCESS,
    slots,
  };
}

export function getInterviewSlotsError(error) {
  return {
    type: GET_INTERVIEW_SLOTS_ERROR,
    error,
  };
}

export function getSlotAvailability(conditions, listingId, stepId) {
  console.log('GET_SLOT_AVAILABILITY');
  return {
    type: GET_SLOT_AVAILABILITY,
    conditions,
    listingId,
    stepId,
  };
}

export function getSlotAvailabilitySuccess(availability) {
  return {
    type: GET_SLOT_AVAILABILITY_SUCCESS,
    availability,
  };
}

export function getSlotAvailabilityError(error) {
  return {
    type: GET_SLOT_AVAILABILITY_ERROR,
    error,
  };
}

export function getHiringManagers(subsidiaryId) {
  console.log('GET_HIRING_MANAGERS');
  return {
    type: GET_HIRING_MANAGERS,
    subsidiaryId
  };
}

export function getHiringManagersSuccess(subsidiaryId, hiringManagers) {
  return {
    type: GET_HIRING_MANAGERS_SUCCESS,
    subsidiaryId,
    hiringManagers,
  };
}


export function getHiringManagersError(err) {
  return {
    type: GET_HIRING_MANAGERS_ERROR,
    err,
  };
}

export function getCandidatesInInterview(
  listingId,
  stepId,
  slotId,
  filters,
  offset,
  limit,
) {
  console.log('GET_CANDIDATES_IN_INTERVIEW');
  return {
    type: GET_CANDIDATES_IN_INTERVIEW,
    listingId,
    stepId,
    slotId,
    filters,
    offset,
    limit,
  };
}

export function getCandidatesInInterviewSuccess(interviewData) {
  return {
    type: GET_CANDIDATES_IN_INTERVIEW_SUCCESS,
    interviewData,
  };
}

export function getCandidatesInInterviewError(error) {
  return {
    type: GET_CANDIDATES_IN_INTERVIEW_ERROR,
    error,
  };
}

export function setCandidateAssistance({
  answers,
  listingId,
  stepId,
  slotId,
  assisted,
  selected,
  reason,
  forcedFeedback,
  editedFeedback,
  paging,
  initialPaging
}) {
  console.log('SET_CANDIDATE_ASSISTANCE_STEP');
  return {
    type: SET_CANDIDATE_ASSISTANCE_STEP,
    answers,
    listingId,
    stepId,
    slotId,
    assisted,
    selected,
    reason,
    forcedFeedback,
    editedFeedback,
    paging,
    initialPaging
  };
}

export function setCandidateAssistanceSuccess(candidateState) {
  return {
    type: SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS,
    candidateState,
  };
}

export function setCandidateAssistanceError(error) {
  return {
    type: SET_CANDIDATE_ASSISTANCE_STEP_ERROR,
    error,
  };
}

export function cancelInterview(listingId, stepId, slotId) {
  console.log('CANCEL_INTERVIEW');
  return {
    type: CANCEL_INTERVIEW,
    listingId,
    stepId,
    slotId,
  };
}

export function cancelInterviewSuccess(listingId, stepId, slotId) {
  return {
    type: CANCEL_INTERVIEW_SUCCESS,
    listingId,
    stepId,
    slotId,
  };
}

export function cancelInterviewError(error) {
  return {
    type: CANCEL_INTERVIEW_ERROR,
    error,
  };
}

export function closeInterview(listingId, stepId, slotId, slotType, status, offset, limit, userId, sort) {
  console.log('CLOSE_INTERVIEW');
  return {
    type: CLOSE_INTERVIEW,
    listingId,
    stepId,
    slotId,
    slotType,
    status,
    offset,
    limit,
    userId,
    sort
  };
}

export function closeInterviewSuccess(listingId, stepId, slotId) {
  return {
    type: CLOSE_INTERVIEW_SUCCESS,
    listingId,
    stepId,
    slotId,
  };
}

export function closeInterviewError(error) {
  return {
    type: CLOSE_INTERVIEW_ERROR,
    error,
  };
}

export function getLocations(id, listingId, options) {
  return {
    type: GET_LOCATIONS,
    id,
    listingId,
    options
  };
}

export function getLocationsSuccess(id, locations) {
  return {
    type: GET_LOCATIONS_SUCCESS,
    id,
    locations,
  };
}

export function getLocationsError(error) {
  return {
    type: GET_LOCATIONS_ERROR,
    error,
  };
}

export function getPositions(id, listingId) {
  return {
    type: GET_POSITIONS,
    id,
    listingId,
  };
}

export function getPositionsBySubsidiary(id, subsidiaryId) {
  return {
    type: GET_POSITIONS,
    id,
    subsidiaryId,
  };
}

export function getPositionsSuccess(id, positions) {
  return {
    type: GET_POSITIONS_SUCCESS,
    id,
    positions,
  };
}

export function getPositionsError(error) {
  return {
    type: GET_POSITIONS_ERROR,
    error,
  };
}

export function getVacancies(subsidiaryId, listingId) {
  return {
    type: GET_VACANCIES,
    subsidiaryId,
    listingId
  };
}

export function getVacanciesSuccess(subsidiaryId, listingId, vacancies) {
  return {
    type: GET_VACANCIES_SUCCESS,
    subsidiaryId,
    listingId,
    vacancies,
  };
}

export function getVacanciesError(error) {
  return {
    type: GET_VACANCIES_ERROR,
    error,
  };
}

export const newRequisitionCreated = (listingId, stepId) => ({
  type: NEW_REQUISITION_CREATED,
  listingId,
  stepId,
})
