/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  CLEAR_MESSAGE,

  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,

  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,

  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}


export function getUsers() {
  console.log('action GET_USERS')
  return {
    type: GET_USERS
  };
}

export function getUsersSuccess(users) {
  return {
    type: GET_USERS_SUCCESS,
    users
  };
}

export function getUsersError(error) {
  return {
    type: GET_USERS_ERROR,
    error
  };
}

export function createUser(user){
  return {
    type: CREATE_USER,
    user
  }
}


export function createUserSuccess(user){
  return {
    type: CREATE_USER_SUCCESS,
    user
  }
}


export function createUserError(error){
  return {
    type: CREATE_USER_ERROR,
    error
  }
}

export function editUser(user){
  console.log("EDIT_USER")
  return {
    type: EDIT_USER,
    user
  }
}


export function editUserSuccess(user){
  return {
    type: EDIT_USER_SUCCESS,
    user
  }
}


export function editUserError(error){
  return {
    type: EDIT_USER_ERROR,
    error
  }
}
