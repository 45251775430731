import React from 'react';
import FontIcon from '@material-ui/core/Icon';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

import HomeIcon from './v2/components/icons/HomeIcon';
import SearchIcon from './v2/components/icons/SearchIcon';
import ListingIcon from './v2/components/icons/ListingIcon';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const data = {
  menus: [
    {
      id: 'home',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'home',
      icon: <HomeIcon />,
      url: '/home',
      index: 0,
      type: 'main'
    },
    {
      id: 'listings',
      roles: ['sys_admin', 'company_user'],
      textId: 'listings',
      icon: <ListingIcon />,
      url: '/listings',
      index: 2,
      type: 'main'
    },
    {
      id: 'emiAnalytics',
      roles: ['sys_admin', 'company_admin'],
      textId: 'analytics',
      icon: <PieChartOutlinedIcon />,
      url: '/analytics',
      index: 3,
      type: 'main'
    },
    {
      id: 'candidateSearch',
      roles: ['sys_admin', 'company_admin'],
      textId: 'searchCandidate',
      icon: <SearchIcon />,
      url: '/candidate_search',
      index: 2,
      type: 'main'
    },
    {
      id: 'candidateView',
      roles: ['sys_admin', 'company_admin'],
      textId: 'candidateView',
      icon: <PeopleOutlineOutlinedIcon />,
      url: '/candidates',
      index: 2,
      type: 'main'
    },
    {
      id: 'vacancies',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'vacancies',
      icon: <PageviewOutlinedIcon />,
      url: '/vacancies',
      index: 114,
      type: 'subMenu'
    },
    {
      id: 'interviewView',
      roles: ['sys_admin', 'company_admin'],
      textId: 'interviewView',
      icon: <SearchIcon />,
      url: '/interviews',
      index: 2,
      type: 'main'
    },
    {
      id: 'platforms',
      roles: ['sys_admin', 'company_admin'],
      textId: 'jobBoards',
      icon: <WorkOutlineOutlinedIcon />,
      url: '/platforms',
      index: 111,
      type: 'subMenu'
    },
    {
      id: 'attraction',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'attraction',
      icon: <LinkOutlinedIcon />,
      url: '/attraction',
      index: 112,
      type: 'subMenu'
    },
    {
      id: 'candidate_load',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'candidateLoad',
      icon: <PersonOutlineOutlinedIcon />,
      url: '/candidate_load',
      index: 113,
      type: 'subMenu'
    },
    {
      id: 'candidate_add',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'candidateAdd',
      icon: <PersonOutlineOutlinedIcon />,
      url: '/candidates/add',
      index: 114,
      type: 'subMenu'
    },
    {
      id: 'docs',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'docs',
      icon: <LibraryBooksOutlinedIcon />,
      url: '/tutorials',
      index: 2,
      type: 'subMenu'
    },
    {
      id: 'mysuggestions',
      roles: ['sys_admin', 'company_user'],
      textId: 'wish',
      icon: <ChatBubbleOutlineOutlinedIcon />,
      url: '/mysuggestions',
      index: 2,
      type: 'secondary'
    },
    {
      id: 'campaigns',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'campaigns',
      icon: <DoneAllIcon />,
      url: '/candidate-re-engagement',
      type: 'subMenu',
      index: 115,
    },
    {
      id: 'requisitions',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'requisitions',
      icon: <StickyNote2OutlinedIcon />,
      url: '/requisitions',
      type: 'subMenu',
      index: 116,
    },
    {
      id: 'applicationPage',
      roles: ['sys_admin', 'company_admin', 'company_user'],
      textId: 'oxxo_interview_form_shortcut',
      icon: <NoteAddOutlinedIcon />,
      url: '/oxxo/candidates/apply/recruiter',
      type: 'subMenu',
      index: 115,
    },
    {
      id: 'suggestions',
      roles: ['sys_admin'],
      textId: 'suggestions',
      icon: <FontIcon className="material-icons">lightbulb_outline</FontIcon>,
      url: '/suggestions',
      index: 2,
    },
    {
      id: 'integrations',
      roles: ['sys_admin', 'company_user', 'company_admin'],
      textId: 'integrations',
      icon: <FontIcon className="material-icons">face</FontIcon>,
      url: '/integrations',
      index: 6,
    },
  ],
};

export default data;
