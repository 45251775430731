/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import {
  GET_STATE_DATA,
  GET_PENDING_APPROVAL_LISTINGS,
  GET_VIEW_DATA,
  GET_STEP_VIEWS,
  GET_VIEW_CONFIG,
  GET_REASONS_REJECTION,
  UPDATE_ANSWER,
  ADD_REASONS_REJECTION,
  REMOVE_REASONS_REJECTION,
  APPROVE_CANDIDATES,
  CANDIDATES_TO_NEXT_STEP,
  GET_INTERVIEW_SLOTS,
  GET_INTERVIEW_CONFIG,
  SAVE_INTERVIEW_SLOT,
  GET_SLOT_AVAILABILITY,
  GET_CANDIDATES_IN_INTERVIEW,
  SET_CANDIDATE_ASSISTANCE_STEP,
  CANCEL_INTERVIEW,
  CLOSE_INTERVIEW,
  GET_HIRING_MANAGERS,
  GET_LOCATIONS,
  GET_POSITIONS,
  GET_VACANCIES
} from './constants';

import {
  getReasonsRejection,
  getViewDataSuccess,
  getViewDataError,
  getStepViewsSuccess,
  getStepViewsError,
  getViewConfigSuccess,
  getViewConfigError,
  getReasonsRejectionSuccess,
  getReasonsRejectionError,
  addReasonsRejectionWaiting,
  addReasonsRejectionSuccess,
  addReasonsRejectionError,
  removeReasonsRejectionWaiting,
  removeReasonsRejectionSuccess,
  removeReasonsRejectionError,
  getPendingApprovalListingsSuccess,
  getPendingApprovalListingsError,
  updateAnswerSuccess,
  updateAnswerError,
  approveCandidatesSuccess,
  approveCandidatesError,
  candidatesToNextStepSuccess,
  candidatesToNextStepError,
  getInterviewConfigSuccess,
  getInterviewConfigError,
  saveInterviewSlotSuccess,
  saveInterviewSlotError,
  getInterviewSlots,
  getInterviewSlotsSuccess,
  getInterviewSlotsError,
  getSlotAvailabilitySuccess,
  getSlotAvailabilityError,
  getCandidatesInInterviewSuccess,
  getCandidatesInInterviewError,
  setCandidateAssistanceSuccess,
  setCandidateAssistanceError,
  cancelInterviewSuccess,
  cancelInterviewError,
  getHiringManagersSuccess,
  getHiringManagersError,
  getHiringManagers,
  getLocationsSuccess,
  getLocationsError,
  getPositionsSuccess,
  getPositionsError,
  getVacanciesSuccess,
  getVacanciesError,
  getCandidatesInInterview,
  closeInterviewSuccess,
  closeInterviewError, setLoading,
} from './actions';

import { requestManuallyAddCandidateConfig } from '../../store/features/actions';

import {
  getLocationsById,
  getPositionsById,
  getVacanciesBySubsidiaryIdAndListingId
} from './selectors'

import request from '../../utils/request';
import { makeSelectGlobal } from '../App/selectors';
import { delay } from 'redux-saga';
const config = require('../../config');
const root = config.params.API_SERVER;

export function* sViewData(action) {
  const requestURL = new URL(`${root}/views/data`);
  let filter = [];
  for (let key in action.filters) {
    if (action.filters[key]) {
      filter.push(key + `${key === 'candidate_id' ? '_in=' : '_contains='}` + action.filters[key]);
    }
  }
  filter = filter.join(',');

  let params = {
    listingId: action.listingId || '',
    stepId: action.stepId || '',
    viewId: action.viewId || '',
    amount: action.amount || '',
    page: action.page || 0,
    filter: filter || '',
    order: action.order || '',
    orderBy: action.orderBy || '',
    rejected: action.rejected || '',
  };
  Object.keys(params).forEach((key) =>
    requestURL.searchParams.append(key, params[key]),
  );

  try {
    // Call our request helper (see 'utils/request')
    console.log(requestURL.href);
    const result = yield call(request, requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('in sagas GET_VIEW_DATA', result);
    yield put(
      getViewDataSuccess({
        data: result,
        listingId: action.listingId,
        stepId: action.stepId,
        viewId: action.viewId,
        amount: action.amount,
        page: action.page,
        rejected: action.rejected,
        tableClass: action.tableClass,
      }),
    );
  } catch (err) {
    console.error('in view data error');
    yield put(getViewDataError(err));
  }
}

export function* getViewData() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_VIEW_DATA, withLoading(sViewData));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* setPendingApproval(action) {
  const requestURL = `${root}/listings/pendingApproval`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(getPendingApprovalListingsSuccess(result));

  } catch (err) {
    console.error('in view data error');
    yield put(getPendingApprovalListingsError(err));
  }
}

export function* getPendingApprovalListings() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_PENDING_APPROVAL_LISTINGS, withLoading(setPendingApproval));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sStepViews(stepId) {
  const requestURL = `${root}/listings`;
  try {
    // Call our request helper (see 'utils/request')
    // const result = yield call(request, requestURL, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    // });
    const result = [
      {
        name: 'Postulados',
        '1': {
          type: 'application',
          field: 'name',
          name: 'Nombre',
        },
        '2': {
          type: 'application',
          field: 'phone',
          name: 'Teléfono',
        },
        '3': {
          type: 'application',
          field: 'mobile',
          name: 'Celular',
        },
        '4': {
          type: 'application',
          field: 'document',
          name: 'Documento',
        },
        '5': {
          type: 'application',
          field: 'email',
          name: 'Email',
        },
        '6': {
          type: 'application',
          field: 'cv',
          name: 'CV',
        },
      },
    ];
    yield put(getStepViewsSuccess(result));
  } catch (err) {
    yield put(getStepViewsError(err));
  }
}

export function* getStepViews() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_STEP_VIEWS, withLoading(sStepViews));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sViewConfig(action) {
  console.log('getViewConfig sagas begin');
  const requestURL = `${root}/views/config?listingId=${action.listingId ||
    ''}&stepId=${action.stepId || ''}&viewId=${action.viewId || ''}`;
  try {
    yield put(requestManuallyAddCandidateConfig(action.stepId));
    // Call our request helper (see 'utils/request')
    console.log('GET_VIEW_CONFIG', requestURL);
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('GET_VIEW_CONFIG results', result);
    yield put(
      getViewConfigSuccess({
        config: result,
        listingId: action.listingId,
        stepId: action.stepId,
        viewId: action.viewId,
        type: action.viewType,
      }),
    );
    yield put(
      getReasonsRejection(action.listingId, action.stepId, action.viewId),
    );
  } catch (err) {
    yield put(getViewConfigError(err));
  }
}

export function* getViewConfig() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_VIEW_CONFIG, withLoading(sViewConfig));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sReasonsRejection(action) {
  const requestURL = `${root}/listings/reasons?listingId=${action.listingId ||
    ''}&stepId=${action.stepId || ''}&viewId=${action.viewId || ''}`;
  try {
    // Call our request helper (see 'utils/request')

    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    yield put(
      getReasonsRejectionSuccess({
        reasons: result,
        listingId: action.listingId,
        stepId: action.stepId,
        viewId: action.viewId,
      }),
    );
  } catch (err) {
    yield put(getReasonsRejectionError(err));
  }
}

export function* apiGetReasonsRejection() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_REASONS_REJECTION, withLoading(sReasonsRejection));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sUpdateAnswer(action) {
  const requestURL = `${root}/users/answer`;
  console.log(requestURL);

  try {
    // Call our request helper (see 'utils/request')
    console.log(requestURL.href, action);

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action),
    });

    yield put(
      updateAnswerSuccess({
        candidateId: action.candidateId,
        listingId: action.listingId,
        stepId: action.stepId,
        tableClass: action.tableClass,
        rejected: action.rejected,
        tableIndex: action.tableIndex,
        questionIndex: action.questionIndex,
        value: action.value,
        data: result.data
      }),
    );
  } catch (err) {
    console.error('in view data error');
    yield put(updateAnswerError(err));
  }
}

export function* updateAnswer() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(UPDATE_ANSWER, withLoading(sUpdateAnswer));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sStateData(action) {
  const requestURL = new URL(`${root}/views/` + action.endpoint);
  let filter = [];
  for (let key in action.filters) {
    if (action.filters[key]) {
      filter.push(key + `${key === 'candidate_id' ? '_in=' : '_contains='}` + action.filters[key]);
    }
  }
  filter = filter.join(',');

  let filterMetadata = [];
  for (let key in action.filtersMetadata) {
    if (action.filtersMetadata[key] && action.filtersMetadata[key].fieldType) {
      filterMetadata.push(`${key}_type=${action.filtersMetadata[key].fieldType}`);
    }
  }
  filterMetadata = filterMetadata.join(',');

  let params = {
    listingId: action.listingId || '',
    stepId: action.stepId || '',
    viewId: action.viewId || '',
    offset: action.offset,
    limit: action.limit,
    filter: filter || '',
    filterMetadata: filterMetadata || '',
    sort: action.sort,
    rejected: action.rejected || '',
    type: action.stepType || ''
  };
  if (action.showCandidatesAt) {
    params.state = action.showCandidatesAt
  }
  Object.keys(params).forEach((key) =>
    requestURL.searchParams.append(key, params[key]),
  );
  console.log('in sagas GET_STATE_DATA', action);
  try {
    // Call our request helper (see 'utils/request')
    console.log(requestURL.href);
    const result = yield call(request, requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });

    yield put(
      getViewDataSuccess({
        data: result,
        listingId: action.listingId,
        stepId: action.stepId,
        viewId: action.viewId,
        offset: action.offset,
        limit: action.limit,
        rejected: action.rejected,
        tableClass: action.tableClass,
      }),
    );
  } catch (err) {
    console.error('in view data error');
    yield put(getViewDataError(err));
  }
}

export function* apiGetStateData() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_STATE_DATA, withLoading(sStateData));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sAddReasonsRejection(action) {
  const requestURL = `${root}/listings/reasons`;
  console.log('will add this reasons:', action);
  try {
    // Call our request helper (see 'utils/request')
    yield put(
      addReasonsRejectionWaiting(
        action.reasons,
        action.listingId,
        action.stepId,
        action.tableClass,
        action.rejected,
        action.tableIndex,
      ),
    );
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        listingId: action.listingId,
        stepId: action.stepId,
        reasons: action.reasons,
        candidateId: action.candidateId,
        criteriaId: action.criteriaId,
      }),
    });

    yield put(
      addReasonsRejectionSuccess(
        result,
        action.listingId,
        action.stepId,
        action.tableClass,
        action.rejected,
        action.tableIndex,
      ),
    );
  } catch (err) {
    yield put(addReasonsRejectionError(err));
  }
}

export function* apiAddReasonsRejection() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(ADD_REASONS_REJECTION, withLoading(sAddReasonsRejection));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sRemoveReasonsRejection(action) {
  const requestURL = `${root}/listings/reasons`;
  try {
    // Call our request helper (see 'utils/request')
    // removeReasonsRejectionWaiting
    yield put(
      removeReasonsRejectionSuccess(
        action.reasons,
        action.listingId,
        action.stepId,
        action.tableClass,
        action.rejected,
        action.tableIndex,
      ),
    );
    // yield put(removeReasonsRejectionSuccess(result, action.listingId, action.stepId, action.tableClass, action.rejected, action.tableIndex));
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        listingId: action.listingId,
        rejectionIds: action.reasons,
      }),
    });


    // yield put(removeReasonsRejectionSuccess(result, action.listingId, action.stepId, action.tableClass, action.rejected, action.tableIndex));
  } catch (err) {
    yield put(removeReasonsRejectionError(err));
  }
}

export function* apiRemoveReasonsRejection() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(REMOVE_REASONS_REJECTION, withLoading(sRemoveReasonsRejection));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sApproveCandidates(action) {
  const requestURL = `${root}/stateMachine/candidate/approve`;
  try {
    // Call our request helper (see 'utils/request')

    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        approved: action.approved,
        rejected: action.rejected,
        stepId: action.stepId,
      }),
    });

    yield put(
      approveCandidatesSuccess(
        action.listingId,
        action.stepId,
        result.approved,
        result.rejected,
        action.tableClass,
      ),
    );
  } catch (err) {
    yield put(approveCandidatesError(err));
  }
}

export function* apiApproveCandidates() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(APPROVE_CANDIDATES, withLoading(sApproveCandidates));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCandidatesToNextStep(action) {
  const requestURL = `${root}/stateMachine/candidate/nextStep`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        approvedIds: action.approvedIds,
        rejectedIds: action.rejectedIds,
        listingId: action.listingId,
        stepId: action.stepId,
      }),
    });

    yield put(
      candidatesToNextStepSuccess(
        action.listingId,
        action.stepId,
        action.rejected,
        action.tableClass,
        result.approvedIds,
        result.rejectedIds,
      ),
    );
  } catch (err) {
    yield put(candidatesToNextStepError(err));
  }
}

export function* apiCandidatesNextStep() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(CANDIDATES_TO_NEXT_STEP, withLoading(sCandidatesToNextStep));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sGetInterviewConfig(action) {
  console.log('sGetInterviewConfig', action);
  const requestURL = new URL(`${root}/interviews/config`);
  let params = {
    stepId: action.stepId || '',
  };
  Object.keys(params).forEach((key) =>
    requestURL.searchParams.append(key, params[key]),
  );

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('in sagas GET_INTERVIEW_CONFIG', result);

    // TODO: avoid redundancy in both 'conditions' and 'interviewConfig' keys
    const configFromResponse = {
      conditions: result.config,
      locations: result.locations,
      interviewConfig: result.config.interview
    }

    yield put(
      getInterviewConfigSuccess(action.listingId, action.stepId, configFromResponse),
    );

  } catch (err) {
    yield put(getInterviewConfigError(err));
  }
}

export function* apiGetInterviewConfig() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_INTERVIEW_CONFIG, withLoading(sGetInterviewConfig));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sSaveInterviewSlot(action) {
  console.log('sSaveInterviewSlot', action);
  const requestURL = `${root}/interviews/slots`;

  try {
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        config: action.config,
        listingId: action.listingId,
        candidateId: action.candidateId,
        reschedulingAssignmentId: action.assignmentId
      })
    });

    yield put(saveInterviewSlotSuccess());
    action.cb()
    yield put(
      getInterviewSlots(action.listingId, action.config.slot.stepId, 'FUTURE', null),
    );

    yield put(
      getHiringManagers(action.subsidiaryId),
    );

  } catch (error) {
    action.cb(error);
    yield put(saveInterviewSlotError(error));
    yield delay(5000)
    yield put(saveInterviewSlotError(null))
  }
}

export function* apiSaveInterviewSlot() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(SAVE_INTERVIEW_SLOT,withLoading(sSaveInterviewSlot));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sGetInterviewSlots(action) {
  console.log('sGetInterviewSlots', action);
  const requestURL = new URL(`${root}/interviews/slots`);
  let params = {
    listingId: action.listingId,
    stepId: action.stepId,
    offset: action.offset,
    limit: action.limit,
  };
  if (action.slotType) {
    params.type = action.slotType
  }
  if (action.status) {
    params.status = action.status
  }
  if (action.userId) {
    params.userId = action.userId;
  }
  if (action.sort) {
    params.sort = action.sort
  }
  Object.keys(params).forEach((key) =>
    requestURL.searchParams.append(key, params[key]),
  );
  console.log(requestURL);
  try {
    // Call our request helper (see 'utils/request')

    const result = yield call(request, requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('in sagas GET_INTERVIEW_SLOTS', result);
    const slots = params;
    slots.data = result;
    yield put(getInterviewSlotsSuccess(slots));
  } catch (err) {
    yield put(getInterviewSlotsError(err));
  }
}

export function* apiGetInterviewSlots() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_INTERVIEW_SLOTS, withLoading(sGetInterviewSlots));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sGetCandidatesInInterview(action) {
  console.log('sGetCandidatesInInterview', action);
  const requestURL = new URL(
    `${root}/interviews/slots/${action.slotId}/candidates`,
  );
  let filter = [];
  for (let key in action.filters) {
    if (action.filters[key]) {
      filter.push(key + `${key === 'candidate_id' ? '_in=' : '_contains='}` + action.filters[key]);
    }
  }
  filter = filter.join(',');

  let params = {
    listingId: action.listingId,
    stepId: action.stepId,
    offset: action.offset,
    filter: filter || '',
    limit: action.limit,
  };
  Object.keys(params).forEach((key) =>
    requestURL.searchParams.append(key, params[key]),
  );

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('in sagas GET_CANDIDATES_IN_INTERVIEW', result);
    params.results = result;
    params.slotId = action.slotId;
    yield put(getCandidatesInInterviewSuccess(params));
  } catch (err) {
    yield put(getCandidatesInInterviewError(err));
  }
}

export function* apiGetCandidatesInInterview() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_CANDIDATES_IN_INTERVIEW, withLoading(sGetCandidatesInInterview));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sSetCandidateAssistance(action) {
  console.log('sSaveInterviewSlot', action);
  const requestURL = `${root}/interviews/slots/${action.slotId}/state`;
  const params = {
    ...(action.answers && { answers: action.answers }),
    listingId: action.listingId,
    stepId: action.stepId,
    assisted: action.assisted,
    selected: action.selected,
    reason: action.reason,
    forcedFeedback: action.forcedFeedback,
    editedFeedback: action.editedFeedback
  };
  console.log('sSaveInterviewSlot with params', params);
  console.log('sSaveInterviewSlot with string params', JSON.stringify(params));
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    // Expected result is:
    // const result = {"446061": {"assisted":false,"selected":false}}

    console.log('in sagas SET_CANDIDATE_ASSISTANCE_STEP', result);
    params.candidates = result;
    params.slotId = action.slotId;
    yield put(setCandidateAssistanceSuccess(params));
    const paging = action.paging || action.initialPaging
    yield put(getCandidatesInInterview(action.listingId, action.stepId, action.slotId, action.filters, paging.offset, paging.limit));
  } catch (err) {
    yield put(setCandidateAssistanceError(err));
  }
}

export function* apiSetCandidateAssistance() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(SET_CANDIDATE_ASSISTANCE_STEP, withLoading(sSetCandidateAssistance));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCancelInterview(action) {
  console.log('sCancelInterview', action);
  const requestURL = `${root}/interviews/slots/${action.slotId}/cancel`;
  const params = {
    listingId: action.listingId,
    stepId: action.stepId,
  };
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    yield put(
      cancelInterviewSuccess(action.listingId, action.stepId, action.slotId),
    );
    console.log('in sagas CANCEL_INTERVIEW', result);
  } catch (err) {
    yield put(cancelInterviewError(err));
  }
}

export function* apiCancelInterview() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(CANCEL_INTERVIEW, withLoading(sCancelInterview));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCloseInterview(action) {
  console.log('sCloseInterview', action);
  const requestURL = `${root}/interviews/slots/${action.slotId}/close`;
  const params = {
    listingId: action.listingId,
    stepId: action.stepId,
  };

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    yield put(
      closeInterviewSuccess(action.listingId, action.stepId, action.slotId),
    );
    yield put(
      getInterviewSlots(action.listingId,action.stepId, action.slotType, action.status, action.offset, action.limit, action.userId, action.sort)
    )
    console.log('in sagas CLOSE_INTERVIEW', result);
  } catch (err) {
    yield put(closeInterviewError(err));
  }
}

export function* apiCloseInterview() {
  yield takeEvery(CLOSE_INTERVIEW, withLoading(sCloseInterview));
}

export function* sGetHiringManagers(action) {
  console.log('sGetHiringManagers', action);
  const requestURL = `${root}/subsidiaries/${action.subsidiaryId}/hiring-managers`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    yield put(getHiringManagersSuccess(action.subsidiaryId, result));
  } catch (err) {
    yield put(getHiringManagersError(err));
  }
}

function withLoading(fn){
  return function*(action){
    try{
      yield put(setLoading(action.type, true));
      yield fn(action)
    } finally {
      yield put(setLoading(action.type, false));
    }
  }
}

export function* apiGetHiringManagers() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_HIRING_MANAGERS, withLoading(sGetHiringManagers));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sGetSlotAvailability(action) {
  console.log('sGetSlotAvailability', action);
  const requestURL = `${root}/interviews/availability`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        conditions: action.conditions,
        listingId: action.listingId,
        stepId: action.stepId,
      }),
    });

    yield put(getSlotAvailabilitySuccess(result));
  } catch (err) {
    yield put(getSlotAvailabilityError(err));
  }
}

export function* apiGetSlotAvailability() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_SLOT_AVAILABILITY, withLoading(sGetSlotAvailability));

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sGetLocations({ type, id, listingId, options = {} }) {
  const locations = yield select(getLocationsById(id))
  if (locations) {
    console.debug('LOCATIONS ALREADY FETCHED', id, locations)
  } else {
    yield put(setLoading(type, true));
    const global = yield select(makeSelectGlobal())
    const subsidiaryId = global.user.subsidiaryId
    console.debug('FETCH LOCATIONS ', id, listingId, subsidiaryId)
    let requestURL = `${root}/subsidiaries/${subsidiaryId}/locations/short?listing_id=${listingId}`
    const { nameFormat } = options;
    if (nameFormat) {
      requestURL = `${requestURL}&name_format=${encodeURIComponent(nameFormat)}`
    }
    
    try {
      // Call our request helper (see 'utils/request')
      const result = yield call(request, requestURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      yield put(getLocationsSuccess(id, result))
    } catch (err) {
      yield put(getLocationsError(err));
    }
    yield put(setLoading(type, false));
  }
}

export function* sGetPositions({ id, listingId, subsidiaryId }) {
  const positions = yield select(getPositionsById(id))
  if (positions) {
    console.debug('POSITIONS ALREADY FETCHED', id, positions)
  } else {
    let requestURL = ''
    if (listingId) {
      console.debug('FETCH POSITIONS BY LISTING', id, listingId)
      requestURL = `${root}/listings/${listingId}/positions`;
    } else if (subsidiaryId) {
      console.debug('FETCH POSITIONS BY SUBSIDIARY', id, subsidiaryId)
      requestURL = `${root}/subsidiaries/${subsidiaryId}/positions`;
    }
    try {
      // Call our request helper (see 'utils/request')
      const result = yield call(request, requestURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      yield put(getPositionsSuccess(id, result))
    } catch (err) {
      yield put(getPositionsError(err));
    }

  }
}

export function* sGetVacancies({ subsidiaryId, listingId }) {
  const vacancies = yield select(getVacanciesBySubsidiaryIdAndListingId(subsidiaryId, listingId))
  if (vacancies) {
    console.debug('VACANCIES ALREADY FETCHED', subsidiaryId, listingId, vacancies)
  } else {
    const requestURL = `${root}/vacancies?subsidiaryId=${subsidiaryId}&listingId=${listingId}`
    try {
      // Call our request helper (see 'utils/request')
      const result = yield call(request, requestURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      yield put(getVacanciesSuccess(subsidiaryId, listingId, result.results))
    } catch (err) {
      yield put(getVacanciesError(err));
    }

  }
}

export function* apiGetLocations() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_LOCATIONS, sGetLocations);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* apiGetPositions() {
  yield takeEvery(GET_POSITIONS, withLoading(sGetPositions));
}

export function* apiGetVacancies() {
  yield takeEvery(GET_VACANCIES, withLoading(sGetVacancies));
}
// All sagas to be loaded
export default [
  getViewData,
  getStepViews,
  getViewConfig,
  apiGetReasonsRejection,
  apiAddReasonsRejection,
  apiRemoveReasonsRejection,
  updateAnswer,
  getPendingApprovalListings,
  apiGetStateData,
  apiApproveCandidates,
  apiCandidatesNextStep,
  apiGetInterviewConfig,
  apiGetInterviewSlots,
  apiSaveInterviewSlot,
  apiGetCandidatesInInterview,
  apiSetCandidateAssistance,
  apiCancelInterview,
  apiCloseInterview,
  apiGetSlotAvailability,
  apiGetHiringManagers,
  apiGetLocations,
  apiGetPositions,
  apiGetVacancies
];
