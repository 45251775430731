import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import messages from '../messages';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReasonInput from './Rejection/newReasonInput';
import messages from '../../../messages';
import CheckboxIcon from '../../../../../v2/components/icons/CheckboxIcon'
import SecondaryButton from '../../../../../v2/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../v2/components/buttons/PrimaryButton';
import { isANotAssignedWorkingPlaceReason } from '../index';

const styles = () => ({
  dialog: {
    minWidth: '800px',
    minHeight: '500px',
  },
  notFoundContainer: {
    marginTop: '20px',
  },
});

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
`;

class DialogResonRejection extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
    filter: '',
    checked: [],
    reasons: [],
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      open: newProps.show,
      reasons: newProps.reasons || [],
      checked: newProps.checked || [],
    });
  }

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = event => {
    this.setState({ open: false });
    if (this.props.onCancel) {
      this.props.onCancel(event);
    }
  };

  handleAccept = event => {
    this.setState({ open: false });
    if (this.props.onAccept) {
      this.props.onAccept(this.state.checked);
    }
    console.log('HANDLE ACCEPT', this.state.checked)
  };

  handleCheck = phrase => {
    if (this.props.onReasonSelected) {
      this.props.onReasonSelected(phrase);
    }
    this.setState({
      checked: phrase,
    });
  };

  handleTyping = phrase => {
    this.setState({
      filter: phrase,
    });
  };

  handleAdd = phrase => {
    if (this.props.onReasonAdded) {
      this.props.onReasonAdded(phrase);
    } else {
      const checked = this.state.checked;
      const reasons = this.state.reasons;
      checked.push(phrase);
      reasons.push(phrase);

      this.setState({
        checked,
        reasons,
      });
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { classes } = this.props;
    const { checked, filter } = this.state;

    const reasons = filter
      ? this.state.reasons.filter(r =>
          r ? r.toLowerCase().indexOf(filter.toLowerCase()) > -1 : -1,
        )
      : this.state.reasons;


    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
          className={classes.dialog}
          maxWidth={'md'}
          fullWidth
        >
          <DialogTitle id="scroll-dialog-title">
            {this.props.title ||
              formatMessage(messages.descartes.reasonSelect.why)}
          </DialogTitle>
          <DialogContent>
            <div>
              <ReasonInput
                onChange={this.handleTyping}
                showAdd={reasons.length && reasons[0] ? false : true}
                onAdd={this.handleAdd}
              />
            </div>
            <div>
              {reasons && reasons.length ? (
                <CheckboxGrid>
                  {reasons.map(reason => (
                    <FormControlLabel
                      key={reason}
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckboxIcon checked />}
                          checked={checked.indexOf(reason) > -1}
                          disabled={isANotAssignedWorkingPlaceReason(reason)}
                          onChange={() => {
                            this.handleCheck(
                              checked.indexOf(reason) === -1
                                ? [...checked, reason]
                                : checked.filter(r => r !== reason),
                            );
                          }}
                        />
                      }
                      label={reason}
                    />
                  ))}
                </CheckboxGrid>
              ) : (
                <div className={classes.notFoundContainer}>
                  {formatMessage(messages.descartes.reasonSelect.notFound)} '
                  <b>{this.state.filter}</b>'.
                  <br />
                  <br />
                  {formatMessage(messages.descartes.reasonSelect.onFinish)}
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              onClick={this.handleClose}
              type='outlined'
            >
              {this.props.onAccept
                ? formatMessage(messages.descartes.reasonDialog.button.cancel)
                : formatMessage(messages.descartes.reasonDialog.button.close)}
            </SecondaryButton>
            {this.props.onAccept && (
              <PrimaryButton
                onClick={this.handleAccept}
              >
                {formatMessage(messages.descartes.reasonDialog.button.confirm)}
              </PrimaryButton>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogResonRejection.propTypes = {
  title: PropTypes.string, // Optional, the title of the dialog

  onCancel: PropTypes.func, // Dialog closes with cancel
  onAccept: PropTypes.func, // Dialog closes with accept

  onReasonAdded: PropTypes.func, // Optional, the reason manually and added
  onReasonSelected: PropTypes.func, // A reason is un/checked

  show: PropTypes.bool,
  reasons: PropTypes.array,
  checked: PropTypes.array,

  dialogButton: PropTypes.string, // Optional. If present override 'messages.descartes.reasonSelect.reject'

  
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(DialogResonRejection));
