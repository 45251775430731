import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_LOG,
  REQUEST_ADVANCE,
  REQUEST_REJECT,
  REQUEST_STAND_BY,
  REQUEST_SPECIAL_NEEDS,
  REQUEST_EDIT_CANDIDATE_DATA
} from './constants';

import {
  showLog,
  requestLogSucceed,
  requestLogFail,
  showAdvance,
  requestAdvanceSucceed,
  requestAdvanceFail,
  requestRejectSucceed,
  requestRejectFail,
  showReject,
  showStandBy,
  requestStandBySucceed,
  requestStandByFail,
  showSpecialNeeds,
  requestSpecialNeedsSucceed,
  showEditCandidateData,
  requestEditCandidateDataSucceed,
  requestEditCandidateDataFail
} from './actions';

import request from '../../utils/request';
import { getCandidate, getViewConfig } from '../../utils/connector';

const config = require('../../config');
const root = config.params.API_SERVER

// TODO: this is just a hack for testing log v2 in one listing. Once we are okay to move this change for all clients, we should remove it
function* sRequestLogData(action) {
  yield put(showLog());
  const requestURL = new URL(`${root}/candidate/log${action.listingId == 1593 ? '/v2' : ''}?candidate_id=${action.candidateId}&listing_id=${action.listingId}`)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    yield put(requestLogSucceed(result))
  } catch (err) {
    console.error('in view data error')
    yield put(requestLogFail(err))
  }
}

function* requestLogData() {
  const watcher = yield takeLatest(REQUEST_LOG, sRequestLogData);
}

function* sRequestAdvanceData(action) {
  const { candidateId, listingId, subsidiaryId, stepId } = action
  yield put(showAdvance());
  const requestURL = `${root}/subsidiaries/${subsidiaryId}/locations/short?listing_id=${listingId}`;
  try {
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const viewResponse = yield call(getViewConfig, listingId, stepId)
    if (!viewResponse || !Array.isArray(viewResponse) || viewResponse.length === 0 || !viewResponse[0].results || !Array.isArray(viewResponse[0].results)) {
      throw new Error(`View not found for listing=${listingId} and step=${stepId}`)
    }
    const candidateResponse = yield call(getCandidate, candidateId, listingId)
    const candidate = candidateResponse && candidateResponse.results && candidateResponse.results.length === 1 ? candidateResponse.results[0] : {}
    yield put(requestAdvanceSucceed({
      candidate,
      candidateId,
      listingId,
      subsidiaryId,
      stepId,
      locations: result,
      view: viewResponse[0].results 
    }))
  } catch (err) {
    yield put(requestAdvanceFail(err))
  }
}

function* requestAdvanceData() {
  const watcher = yield takeLatest(REQUEST_ADVANCE, sRequestAdvanceData);
}

function* sRequestRejectCandidateData(action) {
  const { candidateId, listingId, stepId, stepType, name, state } = action
  const requestURL = `${root}/listings/reasons?listing_id=${listingId}&step_id=${stepId}`;
  yield put(showReject({name}));

  try {
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const reasons = result && Array.isArray(result) ? result : [];
    console.log('### REASONS candidateActions/sagas.js', reasons )

    yield put(requestRejectSucceed({
      candidateId,
      listingId,
      stepId,
      stepType,
      name,
      reasons,
      state
    }))
  } catch (err) {
    yield put(requestRejectFail(err))
  }
}

function* requestRejectCandidateData() {
  const watcher = yield takeLatest(REQUEST_REJECT, sRequestRejectCandidateData);
}

function* sRequestStandByCandidateData(action) {
  const { candidateId, listingId, stepId, subsidiaryId, stepType, name, state } = action
  const requestURL = `${root}/subsidiaries/${subsidiaryId}/standby_reasons`;
  yield put(showStandBy({name}));

  try {
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    yield put(requestStandBySucceed({
      candidateId,
      listingId,
      stepId,
      stepType,
      name,
      reasons: result,
      state
    }))
  } catch (err) {
    yield put(requestStandByFail(err))
  }
}

function* requestStandByCandidateData() {
  const watcher = yield takeLatest(REQUEST_STAND_BY, sRequestStandByCandidateData);
}

function* sRequestSpecialNeedsData(action) {
  const { listingId, locations, viewConfig } = action

  const locationIds = locations.trim().split(',').map(locationId => locationId.trim()).join(',')

  const requestURL = `${root}/special-needs?listing_id=${listingId}&location_id=${locationIds}`;

  yield put(showSpecialNeeds())

  const specialNeeds = yield call(request, requestURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });


  yield put(requestSpecialNeedsSucceed(specialNeeds, viewConfig))
}

function* requestSpecialNeedsData() {
  const watcher = yield takeLatest(REQUEST_SPECIAL_NEEDS, sRequestSpecialNeedsData);
}

function* sRequestEditCandidateData(action) {
  const { candidateId, subsidiaryId, listingId, stepId, config } = action
  yield put(showEditCandidateData());
  
  try {
    const candidateResponse = yield call(getCandidate, candidateId, listingId)
    const candidate = candidateResponse && candidateResponse.results && candidateResponse.results.length === 1 ? candidateResponse.results[0] : {}
    yield put(requestEditCandidateDataSucceed({
      candidate,
      candidateId,
      subsidiaryId,
      listingId,
      stepId,
      config
    }))
  } catch (err) {
    yield put(requestEditCandidateDataFail(err))
  }
}

function* requestEditCandidateData() {
  const watcher = yield takeLatest(REQUEST_EDIT_CANDIDATE_DATA, sRequestEditCandidateData);
}

// All sagas to be loaded
export default [
  requestLogData,
  requestAdvanceData,
  requestRejectCandidateData,
  requestStandByCandidateData,
  requestSpecialNeedsData,
  requestEditCandidateData
];
