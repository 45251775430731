/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
  REMOVE_COMPANY,
  REMOVE_COMPANY_SUCCESS,
  REMOVE_COMPANY_ERROR,
  EDIT_COMPANY,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_ERROR,
  CLEAR_MESSAGE,
} from './constants';


export function getCompanies() {
  console.log('action GET_COMPANIES')
  return {
    type: GET_COMPANIES
  };
}

export function getCompaniesSuccess(companies) {
  return {
    type: GET_COMPANIES_SUCCESS,
    companies
  };
}

export function getCompaniesError(error) {
  return {
    type: GET_COMPANIES_ERROR,
    error
  };
}

export function createCompany(company){
  return {
    type: CREATE_COMPANY,
    company
  }
}


export function createCompanySuccess(company){
  return {
    type: CREATE_COMPANY_SUCCESS,
    company
  }
}


export function createCompanyError(error){
  return {
    type: CREATE_COMPANY_ERROR,
    error
  }
}

export function removeCompany(company){
  return {
    type: REMOVE_COMPANY,
    company
  }
}


export function removeCompanySuccess(company){
  return {
    type: REMOVE_COMPANY_SUCCESS,
    company
  }
}


export function removeCompanyError(error){
  return {
    type: REMOVE_COMPANY_ERROR,
    error
  }
}
export function editCompany(company){
  return {
    type: EDIT_COMPANY,
    company
  }
}


export function editCompanySuccess(company){
  return {
    type: EDIT_COMPANY_SUCCESS,
    company
  }
}


export function editCompanyError(error){
  return {
    type: EDIT_COMPANY_ERROR,
    error
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}
