import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { candidateChanged, hideEditCandidateData } from '../../../store/candidateActions/actions'
import {
  updateCandidateData,
  changeCandidateState,
  updateAssignedVacancy,
  getOpenVacancies,
  getVacancy,
  postCandidateFiles,
  getCandidateFile,
  applyCandidateTransformations,
  manuallyAssignCandidateToRequisition
} from '../../../utils/connector'
import EditCandidateDataDialog from '../EditCandidateDataDialog'
import { ApiResourcesContextProvider } from '../EditCandidateDataDialog/ApiResources'
import track from '../../../utils/track'

const getVacancyDetail = async vacancyId => {
  try {
    return await getVacancy(vacancyId);
  } catch (e) {
    console.error(`vacancy not found ${vacancyId}`, e);
    return null;
  }
};

/**
 * Function name is not entirely accurate, not all modification will be
 * detected (for example, deleting a value will not return true)
 * @param {Object} candidateData
 * @param {string[]} fields
 * @param {string: "or" | "and"} operator
 * @returns {boolean}
 */
const wereFieldsModified = (candidateData, { fields, operator = 'or' }) => {
  const wasFieldModified = f => !!candidateData[f]
  let modified = false

  if (operator === 'and') {
    modified = fields.map(wasFieldModified).every(x => !!x)
  }

  if (operator === 'or') {
    modified = fields.map(wasFieldModified).some(x => !!x)
  }

  return modified
}

const EditCandidateDataAction = ({ store: { show, loading, data }, close, notify }) => {
  const { candidate, candidateId, listingId, stepId, config, subsidiaryId } = data || {}
  const [processing, setProcessing] = useState(false);
  const [loadingVacancies, setLoadingVacancies] = useState(false);
  const [loadingContext, setLoadingContext] = useState(false);
  const [vacancies, setVacancies] = useState([]);
  const [functions, setFunctions] = useState({
    getVacancy: getVacancyDetail
  })
  const [context, setContext] = useState({});

  useEffect(() => {
    const getVacancies = async (subsidiaryId, listingId) => {
      setLoadingVacancies(true)
      const vacancies = await getOpenVacancies(subsidiaryId, listingId)
      setVacancies(vacancies)
      setLoadingVacancies(false)
    }
    const getVacanciesDetails = async (fields) => {
      setLoadingContext(true);
      const vacancies = {}
      for (let index = 0; index < fields.length; index++) {
        const { name, vacancyId } = fields[index];
        const vacancy = await getVacancyDetail(vacancyId);
        if (vacancy) {
          vacancies[name] = vacancy
        }
      }
      setContext(vacancies)
      setLoadingContext(false);
    }
    if (data && data.config && data.config.fields && data.config.fields.some(x => x.type === 'vacancies')) {
      getVacancies(subsidiaryId, listingId)
      const fetchVacancyFields = data.config.fields.filter(x => x.type === 'vacancies' && x.resource && !!x.resource.fetch);
      if (candidate && fetchVacancyFields && fetchVacancyFields.length > 0) {
        getVacanciesDetails(
          fetchVacancyFields
            .map(field => ({
              name: field.name,
              vacancyId: candidate[field.name],
            }))
            .filter(x => !!x.vacancyId),
        );
      }
    }
  }, [data])

  useEffect(() => {
    const getVacanciesDetails = async (fields) => {
      setLoadingContext(true);
      const vacancies = {}
      for (let index = 0; index < fields.length; index++) {
        const { name, vacancyId } = fields[index];
        const vacancy = await getVacancyDetail(vacancyId);
        if (vacancy) {
          vacancies[name] = vacancy
        }
      }
      setContext(vacancies)
      setLoadingContext(false);
    }
    if (candidate && data && data.config && data.config.fields) {
      const fetchVacancyFields = data.config.fields.filter(x => x.type === 'vacancies' && x.resource && !!x.resource.fetch);
      if (fetchVacancyFields && fetchVacancyFields.length > 0) {
        getVacanciesDetails(
          fetchVacancyFields
            .map(field => ({
              name: field.name,
              vacancyId: candidate[field.name],
            }))
            .filter(x => !!x.vacancyId),
        );
      }
    }
  }, [data, candidate])

  useEffect(() => {
    if (data && data.config && data.config.functions) {
      // TODO: create a model/enum with available resources?
      if (data.config.functions.some(fn => fn === 'downloadCandidateFile')){
        setFunctions(functions => ({ ...functions, downloadCandidateFile: (key) => getCandidateFile(candidateId, listingId, key) }))
      }
    }
  }, [data])

  const onConfirm = async (candidateData, actions) => {
    setProcessing(true)
    try {
      const excludedFields = config && config.excludedFields
        ? config.excludedFields || []
        : [];
      const filteredCandidateData = Object.keys(candidateData).reduce(
        (result, key) => {
          if (excludedFields.includes(key)) {
            return result;
          }
          return { ...result, [key]: candidateData[key] };
        },
        {},
      );
      await updateCandidateData(candidateId, listingId, stepId, filteredCandidateData)

      track({ type: 'EDIT_CANDIDATE_DATA', candidateId, listingId, stepId, candidateActionName: config.title || config.label || "Editar datos", state: candidate.step_state });

      if (actions) {
        // we execute actions in the order they are configured. Consider this when configuring your actions
        for (let index = 0; index < actions.length; index++) {
          const { type, params } = actions[index];
          switch (type) {
            case 'CHANGE_STATE':
              await changeCandidateState({ candidateId, listingId, stepId, state: params.state })
              break;
            case 'UPDATE_VACANCY':
              const vacancyId = candidateData[params.candidateKey] || null
              if (vacancyId) {
                // other endpoint will take care of this field, let's just remove it
                delete candidateData[params.candidateKey]
                await updateAssignedVacancy(candidateId, listingId, vacancyId, params.augmentData)
              }
              break;
            case 'UPDATE_CANDIDATE_FILES':
              const candidateKeys = Object.keys(candidateData)
              const files = params.fields.reduce((acc, name) => {
                if(candidateKeys.includes(name)) {
                  acc.push({
                    name,
                    file: candidateData[name]
                  })
                }

                return acc
              }, [])
              await postCandidateFiles(candidateId, listingId, files);
              break;
            case 'CONDITIONAL_CHANGE_STATE':
              if (wereFieldsModified(candidateData, params.onModify)) {
                await changeCandidateState({ candidateId, listingId, stepId, state: params.state })
              }
              break;
            case 'UPDATE_DOCUMENTATION_VERIFICATION_DATA':
              const isConditional = !!params.onModify && Array.isArray(params.onModify.fields)

              if (!isConditional || wereFieldsModified(candidateData, params.onModify)) {
                await updateCandidateData(candidateId, listingId, stepId, params.data)
              }
              break;
            case 'APPLY_TRANSFORMATIONS':
              const { transformations } = params
              if (Array.isArray(transformations) && !!transformations.length && transformations.every(x => !!Number(x.id))) {
                await applyCandidateTransformations({ candidateId, listingId, transformations })
              }
              break;
            case 'MANUALLY_ASSIGN_CANDIDATE_TO_REQUISITION':
              await manuallyAssignCandidateToRequisition(candidateData, data)
            default:
              break;
          }
        }
      }
      notify()
    }
    catch(err){
      console.log('Something failed while saving candidate data', err)
    }
    setProcessing(false)
    close()
  }

  return <ApiResourcesContextProvider
    commonArgs = {{ subsidiaryId, listingId, stepId, candidateId }}
    resourceFields = { config && config.fields.filter(f => f.type === 'api-resource' || f.type === 'api-resource-2' || f.type === 'api-resource-large') }
    initFormData = { candidate }
  >
    <EditCandidateDataDialog
      open={show}
      onClose={close}
      onConfirm={onConfirm}
      loading={loading || loadingVacancies || loadingContext || processing}
      candidate={candidate}
      config={config}
      vacancies={vacancies}
      functions={functions}
      context={context}
    />
  </ApiResourcesContextProvider>
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').others.editCandidateData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideEditCandidateData()),
    notify: () => dispatch(candidateChanged()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCandidateDataAction)
