import { browserHistory } from 'react-router';
import HomeIcon from "@mui/icons-material/HomeOutlined";
import MuiStackedBarChartIcon from "@mui/icons-material/StackedBarChartOutlined";
import CampaignIcon from "@mui/icons-material/CampaignOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTreeOutlined";
import LiveHelpIcon from "@mui/icons-material/LiveHelpOutlined";
import BugReportIcon from "@mui/icons-material/BugReportOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { useUser } from '../../../utils/hooks';
import { usePrivacyPolicyConfig, useHomeConfig } from '../../../containers/ConfigProvider'
import mockMenuApi from '../../../api/mockMenuApi';

import { useIntl } from 'react-intl';
import legacyMessages from '../../../components/Header/messages';
import messages from '../messages';
import { styled } from "@mui/material/styles";

const StackedBarChartIcon = styled(MuiStackedBarChartIcon)`
  transform: scaleX(-1);
`;

const DEFAULT_SECTION = {
  Icon: BugReportIcon,
  id: "DEFAULT_SECTION",
  type: "section",
  match: []
}

const sections = [{
  Icon: HomeIcon,
  id: "HOME",
  type: 'item',
  match: ['/home']
}, {
  Icon: AccountTreeIcon,
  id: "HIRING",
  type: 'section',
  match: ['/candidates', '/candidates/add', '/candidate_load', '/candidate_search', '/interviews', '/requisitions', '/listings', '/vacancies']
}, {
  Icon: StackedBarChartIcon,
  id: "ANALYTICS",
  type: 'item',
  match: ['/analytics']
}, {
  Icon: CampaignIcon,
  id: "ATTRACTION",
  type: 'item',
  match: ['/attraction']
}, {
  Icon: DoneAllIcon,
  id: "CAMPAIGNS",
  type: 'item',
  match: ['/candidate-re-engagement']
}, {
  Icon: CampaignIcon,
  id: "JOB_BOARDS",
  type: 'item',
  match: ['/platforms']
}, {
  Icon: LiveHelpIcon,
  id: "HELP",
  type: 'section',
  match: ['/tutorials', '/mysuggestions']
}, {
  Icon: NoteAddOutlinedIcon,
  id: "APPLICATION_PAGE",
  type: 'item',
  match: ['/oxxo/candidates/apply/recruiter']
}, DEFAULT_SECTION]

const buildId = (values) => values.join('.')

const buildMenu = (userRoutes, translate, navigate, rootRoute) => {

  const enhancedUserRoutes = userRoutes.map((userRoute) => {
    const { url, textId } = userRoute
    const sectionId = (sections.find(x => x.match.includes(url)) || DEFAULT_SECTION).id
    const label = translate(textId)
    return { ...userRoute, sectionId, label }
  })

  const menu = sections.reduce((menu, section) => {
    const { id, type, Icon } = section
    const routes = enhancedUserRoutes.filter(x => x.sectionId === id)
    if (routes.length > 0) {
      if (type === 'item' && routes.length === 1) {
        menu.push({
          id: buildId([routes[0].url]),
          type,
          Icon,
          label: translate(id),
          onClick: () => navigate(routes[0].url),
          isRoot: routes[0].url === rootRoute
        })
      } else if (type === 'section') {
        const sectionLabel = translate(id)
        const subItems = routes.map(({ label, url }) => ({ 
          id: buildId([sectionLabel, url]),
          label, 
          onClick: () => navigate(url),
          isRoot: url === rootRoute
        }))
        menu.push({
          id: buildId([sectionLabel]),
          type,
          Icon,
          label: sectionLabel,
          subItems,
          isRoot: false
        })
      }
    }
    return menu
  }, [])

  return menu
}

const buildProfileMenu = (privacyPolicyConfig, translate, showHelpAndSupport, signOut) => {
  const menu = []

  menu.push({
    label: translate('helpAndSupport'),
    onClick: showHelpAndSupport,
    Icon: HealthAndSafetyOutlinedIcon,
  })

  if (privacyPolicyConfig && privacyPolicyConfig.show) {
    const { link, label } = privacyPolicyConfig
    menu.push({
      label,
      onClick: () => window.open(link, "_blank"),
      Icon: LockPersonOutlinedIcon,
    })
  }

  menu.push({
    label: translate('signOut'),
    onClick: signOut,
    Icon: LogoutOutlinedIcon
  })

  return menu
}

function getClientName(user) {
  try {
    return { name: user.subsidiary.Company.name }
  } catch (e) {
    return { name: '' }
  }
}

export function useNavigation({ showHelpAndSupport, signOut }) {
  const { formatMessage } = useIntl()
  const user = useUser()
  const { getPrivacyPolicyConfig } = usePrivacyPolicyConfig()
  const { getHomeConfig } = useHomeConfig()

  const { menus } = mockMenuApi.getMenu(user)
  const menu = buildMenu(menus,
    (id) => {
      const message = messages.section[id] || legacyMessages.tabnavs.header[id]
      return formatMessage(message)
    },
    (url) => browserHistory.push(url),
    getHomeConfig(user.id, user.subsidiaryId).show ? '/home' : '/listings'
  )

  const profileMenu = buildProfileMenu(getPrivacyPolicyConfig(user.subsidiaryId),
    (id) => formatMessage(legacyMessages.tabnavs.header[id]),
    showHelpAndSupport, signOut
  )

  return { 
    menu,
    profileMenu,
    workspace: formatMessage(messages.workspace, getClientName(user))
  }
}