/*
 *
 * ListingPage constants
 *
 */

export const LOADING = 'app/ListingPage/LOADING'
export const GET_LISTING = 'app/ListingPage/GET_LISTING'
export const GET_LISTING_SUCCESS = 'app/ListingPage/GET_LISTING_SUCCESS'
export const GET_LISTING_ERROR = 'app/ListingPage/GET_LISTING_ERROR'

export const GET_LISTINGS = 'app/ListingPage/GET_LISTINGS'
export const GET_LISTINGS_SUCCESS = 'app/ListingPage/GET_LISTINGS_SUCCESS'
export const GET_LISTINGS_ERROR = 'app/ListingPage/GET_LISTINGS_ERROR'

export const CLEAR_LISTING_STEPS = 'app/ListingPage/CLEAR_LISTING_STEPS'

export const GET_LISTING_STEPS = 'app/ListingPage/GET_LISTING_STEPS'
export const GET_LISTING_STEPS_SUCCESS = 'app/ListingPage/GET_LISTING_STEPS_SUCCESS'
export const GET_LISTING_STEPS_ERROR = 'app/ListingPage/GET_LISTING_STEPS_ERROR'

export const CREATE_LISTING = 'app/ListingPage/CREATE_LISTING'
export const CREATE_LISTING_SUCCESS = 'app/ListingPage/CREATE_LISTING_SUCCESS'
export const CREATE_LISTING_ERROR = 'app/ListingPage/CREATE_LISTING_ERROR'

export const REMOVE_LISTING = 'app/ListingPage/REMOVE_LISTING'
export const REMOVE_LISTING_SUCCESS = 'app/ListingPage/REMOVE_LISTING_SUCCESS'
export const REMOVE_LISTING_ERROR = 'app/ListingPage/REMOVE_LISTING_ERROR'

export const EDIT_LISTING = 'app/ListingPage/EDIT_LISTING'
export const EDIT_LISTING_SUCCESS = 'app/ListingPage/EDIT_LISTING_SUCCESS'
export const EDIT_LISTING_ERROR = 'app/ListingPage/EDIT_LISTING_ERROR'

export const CLEAR_MESSAGE = 'app/ListingPage/CLEAR_MESSAGE'

export const UPDATE_LISTING_PLATFORM_LISTINGS = 'app/ListingPage/UPDATE_LISTING_PLATFORM_LISTINGS'
export const REMOVE_LISTING_PLATFORM_LISTINGS = 'app/ListingPage/REMOVE_LISTING_PLATFORM_LISTINGS'


export const RESET_LISTING = 'app/ListingPage/RESET_LISTING'
export const RESET_LISTING_SUCCESS = 'app/ListingPage/RESET_LISTING_SUCCESS'
export const RESET_LISTING_ERROR = 'app/ListingPage/RESET_LISTING_ERROR'

export const INVITE_INTERVIEW = 'app/ListingPage/INVITE_INTERVIEW'
export const INVITE_INTERVIEW_SUCCESS = 'app/ListingPage/INVITE_INTERVIEW_SUCCESS'
export const INVITE_INTERVIEW_ERROR = 'app/ListingPage/INVITE_INTERVIEW_ERROR'

export const DOWNLOAD_LISTING_DATA = 'app/ListingPage/DOWNLOAD_LISTING_DATA'
export const DOWNLOAD_LISTING_DATA_SUCCESS = 'app/ListingPage/DOWNLOAD_LISTING_DATA_SUCCESS'
export const DOWNLOAD_LISTING_DATA_ERROR = 'app/ListingPage/DOWNLOAD_LISTING_DATA_ERROR'

export const DOWNLOAD_STEP_DATA = 'app/ListingPage/DOWNLOAD_STEP_DATA'
export const DOWNLOAD_STEP_DATA_SUCCESS = 'app/ListingPage/DOWNLOAD_STEP_DATA_SUCCESS'
export const DOWNLOAD_STEP_DATA_ERROR = 'app/ListingPage/DOWNLOAD_STEP_DATA_ERROR'

export const GET_INTERVIEW_CONFIG = 'app/ListingPage/GET_INTERVIEW_CONFIG'
export const GET_INTERVIEW_CONFIG_SUCCESS = 'app/ListingPage/GET_INTERVIEW_CONFIG_SUCCESS'
export const GET_INTERVIEW_CONFIG_ERROR = 'app/ListingPage/GET_INTERVIEW_CONFIG_ERROR'

export const UPDATE_LISTING_STEP = 'app/ListingPage/UPDATE_LISTING_STEP'
export const DELETE_LISTING_STEP = 'app/ListingPage/DELETE_LISTING_STEP'
export const RECOVER_LISTING_STEP = 'app/ListingPage/RECOVER_LISTING_STEP'

export const DEMO_SEND_REMINDER = 'app/ListingPage/DEMO_SEND_REMINDER'
export const DEMO_SEND_REMINDER_SUCCESS = 'app/ListingPage/DEMO_SEND_REMINDER_SUCCESS'
export const DEMO_SEND_REMINDER_ERROR = 'app/ListingPage/DEMO_SEND_REMINDER_ERROR'

export const DEMO_SEND_FEEDBACK = 'app/ListingPage/DEMO_SEND_FEEDBACK'
export const DEMO_SEND_FEEDBACK_SUCCESS = 'app/ListingPage/DEMO_SEND_FEEDBACK_SUCCESS'
export const DEMO_SEND_FEEDBACK_ERROR = 'app/ListingPage/DEMO_SEND_FEEDBACK_ERROR'
