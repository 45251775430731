import React from 'react';
import { FormattedMessage } from 'react-intl';
import stepViewMessages from '../../StepView/messages';
import messages from '../messages';

const experimientalLayout = {
  showButton: false,
  buttonText: null,
  titleText: <FormattedMessage {...messages.actions.addToApplication} />,
  showDate: false,
  showTime: false,
  showLocations: false,
  showSize: false,
  showConditions: true,
  includeConditions: null,
  showSlotAvailability: false,
  showSummaryMessage: false
};

const regularLayout = {
  showButton: false,
  titleText: <FormattedMessage {...stepViewMessages.interview.dialog.title} />,
  showDate: true,
  showTime: true,
  showLocations: true,
  showSize: false,
  showConditions: true,
  includeConditions: ['working_place_id', 'working_place_cell', 'job_position_id', 'modality'],
  showSlotAvailability: false,
  showSummaryMessage: false
};

const decentralizedLayout = {
  showButton: false,
  titleText: <FormattedMessage {...stepViewMessages.interview.dialog.title} />,
  showDate: false,
  showTime: false,
  showLocations: true,
  showSize: false,
  showConditions: true,
  includeConditions: ['working_place_id', 'working_place_cell', 'job_position_id', 'modality'],
  showSlotAvailability: false,
  showSummaryMessage: false
};

export const buildLayoutConfig = (isExperimental = false, isDecentralizedStep = false) => (
  isDecentralizedStep ? decentralizedLayout : isExperimental ? experimientalLayout : regularLayout
)

export const ERROR_CODES = {
  SERVER_ERROR: messages.inviteToInterview.error.serverError,
  DATA_REQUIRED: messages.inviteToInterview.error.dataRequired,
  INVALID_INTERVIEWEE: messages.inviteToInterview.error.invalidInterviewee,
  CANDIDATE_ASSIGNED: messages.inviteToInterview.error.candidateAssigned,
  AUTOMATIC_SLOT: messages.inviteToInterview.error.automaticSlot,
  INCOMPLETE_LOCATION: messages.inviteToInterview.error.incompleteLocation,
  ONSITE_REQUISITION: messages.inviteToInterview.error.onSiteRequisition,
  MISSING_TIMEZONE: messages.inviteToInterview.error.missingTimezone,
  MISSING_SLOT_LOCATION_AND_DATE: messages.inviteToInterview.error.missingSlotLocationAndDate,
  CLOSED_REQUISITION: messages.inviteToInterview.error.closedRequisition,
  ACTIVE_INTERVIEW: messages.inviteToInterview.error.alreadyInvited,
}

const INVITATION_ERRORS = {
  'server_error': {
    code: 'server_error',
    message: ERROR_CODES.SERVER_ERROR
  },
  'user_data_required': {
    code: 'server_error',
    message: ERROR_CODES.DATA_REQUIRED
  },
  'invalid_interviewee': {
    code: 'invalid_interviewee',
    message: ERROR_CODES.INVALID_INTERVIEWEE
  },
  'assignment_found': {
    code: 'assignment_found',
    message: ERROR_CODES.CANDIDATE_ASSIGNED
  },
  'automatic_slot': {
    code: 'automatic_slot',
    message: ERROR_CODES.AUTOMATIC_SLOT
  },
  'missing_working_location': {
    code: 'missing_working_location',
    message: ERROR_CODES.INCOMPLETE_LOCATION
  },
  'onsite_requisition': {
    code: 'onsite_requisition',
    message: ERROR_CODES.ONSITE_REQUISITION
  },
  'timezone_unspecified': {
    code: 'timezone_unspecified',
    message: ERROR_CODES.MISSING_TIMEZONE
  },
  'missing_local_date_time': {
    code: 'missing_local_date_time',
    message: ERROR_CODES.MISSING_SLOT_LOCATION_AND_DATE
  },
  'closed_invitation': {
    code: 'closed_invitation',
    message: ERROR_CODES.CLOSED_REQUISITION
  },
  'active_interview': {
    code: 'active_interview',
    message: ERROR_CODES.ACTIVE_INTERVIEW
  }
}

export const getErrorMessage = (error) => {
  try {
    if (!error || !error.errorCode) return INVITATION_ERRORS['server_error']
    return INVITATION_ERRORS[error.errorCode] || INVITATION_ERRORS['server_error']
  } catch (error) {
    return INVITATION_ERRORS['server_error']
  }
}