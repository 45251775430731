import moment from 'moment'
import initialState, { defaultActionState } from './initialState';
import {
  CANDIDATE_CHANGED,
  HIDE_ADVANCE,
  HIDE_CHANGE_VACANCY,
  HIDE_HIRE,
  HIDE_INTERVIEW_CANCEL,
  HIDE_GO_TO_CANDIDATE,
  HIDE_INTERVIEW_INVITE,
  HIDE_INTERVIEW_RESCHEDULE,
  HIDE_LOG,
  HIDE_REJECT,
  HIDE_RESUME,
  HIDE_SPECIAL_NEEDS,
  HIDE_STAND_BY,
  REQUEST_ADVANCE_FAIL,
  REQUEST_ADVANCE_SUCCEED,
  REQUEST_HIRE,
  REQUEST_INTERVIEW_CANCEL,
  REQUEST_INTERVIEW_INVITE,
  REQUEST_GO_TO_CANDIDATE,
  REQUEST_INTERVIEW_RESCHEDULE,
  REQUEST_LOG,
  REQUEST_LOG_FAIL,
  REQUEST_LOG_SUCCEED,
  REQUEST_REJECT_FAIL,
  REQUEST_REJECT_SUCCEED,
  REQUEST_SPECIAL_NEEDS_FAIL,
  REQUEST_SPECIAL_NEEDS_SUCCEED,
  REQUEST_STAND_BY_FAIL,
  REQUEST_STAND_BY_SUCCEED,
  REQUEST_RESUME,
  SHOW_ADVANCE,
  SHOW_CHANGE_VACANCY,
  SHOW_LOG,
  SHOW_REJECT,
  SHOW_SPECIAL_NEEDS,
  SHOW_STAND_BY,
  SHOW_EDIT_CANDIDATE_DATA,
  REQUEST_EDIT_CANDIDATE_DATA_SUCCEED,
  REQUEST_EDIT_CANDIDATE_DATA_FAIL,
  HIDE_EDIT_CANDIDATE_DATA,
  SHOW_HELP_AND_SUPPORT,
  HIDE_HELP_AND_SUPPORT
} from './constants';

function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOG: {
      return {
        ...state,
        log: {
          ...state.log,
          show: true,
          loading: true
        }
      }
    }
    case HIDE_LOG: {
      return {
        ...state,
        log: defaultActionState
      }
    }
    case REQUEST_LOG: {
      return {
        ...state,
        log: {
          ...defaultActionState,
          data: {
            name: action.name,
            channelUserId: action.channelUserId,
            listingId: action.listingId
          }
        }
      }
    }
    case REQUEST_LOG_SUCCEED: {
      return {
        ...state,
        log: {
          ...state.log,
          loading: false,
          data: {
            ...state.log.data,
            logs: action.data
          }
        }
      }
    }
    case REQUEST_LOG_FAIL: {
      return {
        ...state,
        log: {
          ...state.log,
          loading: false,
          error: action.error
        }
      }
    }
    case HIDE_RESUME: {
      return {
        ...state,
        resume: defaultActionState
      }
    }
    case REQUEST_RESUME: {
      return {
        ...state,
        resume: {
          ...state.resume,
          show: true,
          loading: false,
          data: action.data
        }
      }
    }
    case HIDE_INTERVIEW_CANCEL: {
      return {
        ...state,
        interview: {
          ...state.interview,
          cancel: defaultActionState
        }
      }
    }
    case REQUEST_INTERVIEW_CANCEL: {
      return {
        ...state,
        interview: {
          ...state.interview,
          cancel: {
            ...state.interview.cancel,
            show: true,
            loading: false,
            data: action.data
          }
        }
      }
    }
    case REQUEST_GO_TO_CANDIDATE: {
      return {
        ...state,
        goToCandidate: {
          ...state.goToCandidate,
          show: true,
          loading: false,
          data: action.data
        }
      }
    }
    case HIDE_GO_TO_CANDIDATE: {
      return {
        ...state,
        goToCandidate: defaultActionState
      }
    }
    case HIDE_INTERVIEW_INVITE: {
      return {
        ...state,
        interview: {
          ...state.interview,
          invite: defaultActionState
        }
      }
    }
    case REQUEST_INTERVIEW_INVITE: {
      return {
        ...state,
        interview: {
          ...state.interview,
          invite: {
            ...state.interview.invite,
            show: true,
            loading: false,
            data: action.data
          }
        }
      }
    }
    case HIDE_INTERVIEW_RESCHEDULE: {
      return {
        ...state,
        interview: {
          ...state.interview,
          reschedule: defaultActionState
        }
      }
    }
    case REQUEST_INTERVIEW_RESCHEDULE: {
      return {
        ...state,
        interview: {
          ...state.interview,
          reschedule: {
            ...state.interview.reschedule,
            show: true,
            loading: false,
            data: action.data
          }
        }
      }
    }
    case HIDE_HIRE: {
      return {
        ...state,
        hire: defaultActionState
      }
    }
    case REQUEST_HIRE: {
      return {
        ...state,
        hire: {
          ...state.hire,
          show: true,
          loading: false,
          data: action.data
        }
      }
    }
    case HIDE_ADVANCE: {
      return {
        ...state,
        advance: defaultActionState
      }
    }
    case SHOW_ADVANCE: {
      return {
        ...state,
        advance: {
          ...defaultActionState,
          show: true,
          loading: true
        }
      }
    }
    case REQUEST_ADVANCE_SUCCEED: {
      return {
        ...state,
        advance: {
          ...state.advance,
          loading: false,
          data: action.data
        }
      }
    }
    case REQUEST_ADVANCE_FAIL: {
      return {
        ...state,
        advance: {
          ...state.advance,
          loading: false,
          error: action.error
        }
      }
    }
    case HIDE_REJECT: {
      return {
        ...state,
        reject: defaultActionState
      }
    }
    case SHOW_REJECT: {
      return {
        ...state,
        reject: {
          ...defaultActionState,
          show: true,
          loading: true,
          data: action.data
        }
      }
    }
    case REQUEST_REJECT_SUCCEED: {
      return {
        ...state,
        reject: {
          ...state.reject,
          loading: false,
          data: {...state.reject.data, ...action.data}
        }
      }
    }
    case REQUEST_REJECT_FAIL: {
      return {
        ...state,
        reject: {
          ...state.reject,
          loading: false,
          error: action.error
        }
      }
    }
    case HIDE_STAND_BY: {
      return {
        ...state,
        standBy: defaultActionState
      }
    }
    case SHOW_STAND_BY: {
      return {
        ...state,
        standBy: {
          ...defaultActionState,
          show: true,
          loading: true,
          data: action.data
        }
      }
    }
    case REQUEST_STAND_BY_SUCCEED: {
      return {
        ...state,
        standBy: {
          ...state.standBy,
          loading: false,
          data: {...state.standBy.data, ...action.data}
        }
      }
    }
    case REQUEST_STAND_BY_FAIL: {
      return {
        ...state,
        standBy: {
          ...state.standBy,
          loading: false,
          error: action.error
        }
      }
    }
    case CANDIDATE_CHANGED: {
      return {
        ...state,
        lastCandidateChange: moment().toISOString()
      }
    }
    case SHOW_SPECIAL_NEEDS: {
      return {
        ...state,
        others: {
          ...state.others,
          specialNeeds: {
            ...state.others.specialNeeds,
            show: true,
            loading: true
          }
        }
      }
    }
    case REQUEST_SPECIAL_NEEDS_SUCCEED: {
      return {
        ...state,
        others: {
          ...state.others,
          specialNeeds: {
            ...state.others.specialNeeds,
            loading: false,
            data: action.data
          }
        }
      }
    }
    case REQUEST_SPECIAL_NEEDS_FAIL: {
      return {
        ...state,
        others: {
          ...state.others,
          specialNeeds: {
            ...state.others.specialNeeds,
            loading: false,
            error: action.error
          }
        }
      }
    }
    case HIDE_SPECIAL_NEEDS: {
      return {
        ...state,
        others: {
          ...state.others,
          specialNeeds: defaultActionState
        }
      }
    }
    case HIDE_CHANGE_VACANCY: {
      return {
        ...state,
        changeVacancy: defaultActionState
      }
    }
    case SHOW_CHANGE_VACANCY: {
      return {
        ...state,
        changeVacancy: {
          ...defaultActionState,
          show: true
        }
      }
    }
    case SHOW_EDIT_CANDIDATE_DATA: {
      return {
        ...state,
        others: {
          ...state.others,
          editCandidateData: {
            ...state.others.editCandidateData,
            show: true,
            loading: true
          }
        }
      }
    }
    case REQUEST_EDIT_CANDIDATE_DATA_SUCCEED: {
      return {
        ...state,
        others: {
          ...state.others,
          editCandidateData: {
            ...state.others.editCandidateData,
            loading: false,
            data: action.data
          }
        }
      }
    }
    case REQUEST_EDIT_CANDIDATE_DATA_FAIL: {
      return {
        ...state,
        others: {
          ...state.others,
          editCandidateData: {
            ...state.others.editCandidateData,
            loading: false,
            error: action.error
          }
        }
      }
    }
    case HIDE_EDIT_CANDIDATE_DATA: {
      return {
        ...state,
        others: {
          ...state.others,
          editCandidateData: defaultActionState
        }
      }
    }
    case SHOW_HELP_AND_SUPPORT: {
      return {
        ...state,
        others: {
          ...state.others,
          helpAndSupport: {
            ...state.others.editCandidateData,
            show: true
          }
        }
      }
    }
    case HIDE_HELP_AND_SUPPORT: {
      return {
        ...state,
        others: {
          ...state.others,
          helpAndSupport: defaultActionState
        }
      }
    }
    default:
      return state;
  }
}

export default reducer;
