import React from 'react';
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  icon: theme.styles.tabs.appBar.item.icon,

}))

const ListingIcon = (props) => {
  const classes = useStyles()
  return <div {...props}>
    <img src={require('../../assets/thunder.svg')} className={classes.icon}/>
  </div>
}

export default ListingIcon
