/*
 *
 * FlowPage constants
 *
 */

export const LOADING = 'app/FlowPage/LOADING'

export const GET_SUBSIDIARY_FLOWS = 'app/FlowPage/GET_SUBSIDIARY_FLOWS'
export const GET_SUBSIDIARY_FLOWS_SUCCESS = 'app/FlowPage/GET_SUBSIDIARY_FLOWS_SUCCESS'
export const GET_SUBSIDIARY_FLOWS_ERROR = 'app/FlowPage/GET_SUBSIDIARY_FLOWS_ERROR'

export const GET_SUBSIDIARY_REJECTION_FLOWS = 'app/FlowPage/GET_SUBSIDIARY_REJECTION_FLOWS'
export const GET_SUBSIDIARY_REJECTION_FLOWS_SUCCESS = 'app/FlowPage/GET_SUBSIDIARY_REJECTION_FLOWS_SUCCESS'
export const GET_SUBSIDIARY_REJECTION_FLOWS_ERROR = 'app/FlowPage/GET_SUBSIDIARY_REJECTION_FLOWS_ERROR'

export const GET_FLOWS = 'app/FlowPage/GET_FLOWS'
export const GET_FLOWS_SUCCESS = 'app/FlowPage/GET_FLOWS_SUCCESS'
export const GET_FLOWS_ERROR = 'app/FlowPage/GET_FLOWS_ERROR'
export const CREATE_FLOW = 'app/FlowPage/CREATE_FLOW'
export const CREATE_FLOW_SUCCESS = 'app/FlowPage/CREATE_FLOW_SUCCESS'
export const CREATE_FLOW_ERROR = 'app/FlowPage/CREATE_FLOW_ERROR'
export const RESET_CREATE_FLOW = 'app/FlowPage/RESET_CREATE_FLOW'

export const REMOVE_FLOW = 'app/FlowPage/REMOVE_FLOW'
export const REMOVE_FLOW_SUCCESS = 'app/FlowPage/REMOVE_FLOW_SUCCESS'
export const REMOVE_FLOW_ERROR = 'app/FlowPage/REMOVE_FLOW_ERROR'

export const CLEAR_MESSAGE = 'app/FlowPage/CLEAR_MESSAGE'

// export const GET_LISTINGS = 'app/FlowPage/GET_LISTINGS'
// export const GET_LISTINGS_SUCCESS = 'app/FlowPage/GET_LISTINGS_SUCCESS'
// export const GET_LISTINGS_ERROR = 'app/FlowPage/GET_LISTINGS_ERROR'
