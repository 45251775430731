/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  section: {
    HOME: {
      id: 'layout.menu.sections.home',
      defaultMessage: 'Home',
    },
    HIRING: {
      id: 'layout.menu.sections.hiring',
      defaultMessage: 'Hiring',
    },
    ANALYTICS: {
      id: 'layout.menu.sections.analytics',
      defaultMessage: 'Analytics',
    },
    ATTRACTION: {
      id: 'layout.menu.sections.attraction',
      defaultMessage: 'Attraction',
    },
    JOB_BOARDS: {
      id: 'layout.menu.sections.jobBoards',
      defaultMessage: 'Job boards',
    },
    HELP: {
      id: 'layout.menu.sections.help',
      defaultMessage: 'Help',
    },
    DEFAULT_SECTION: {
      id: 'layout.menu.sections.defaultSection',
      defaultMessage: 'Others',
    },
    APPLICATION_PAGE: {
      id: 'layout.menu.sections.applicationPage',
      defaultMessage: 'Interview Form',
    },
    CAMPAIGNS: {
      id: 'layout.menu.sections.campaigns',
      defaultMessage: 'Candidate Re-engagement',
    },
    REQUISITIONS: {
      id: 'layout.menu.sections.requisitions',
      defaultMessage: 'Requisitions',
    },
  },
  workspace: {
    id: 'layout.appBar.workspace',
    defaultMessage: "{name}'s Workspace",
  },
  impersonated: {
    id: 'layout.appBar.impersonated',
    defaultMessage: "Viewing as",
  },
  testEnvironment: {
    id: 'layout.appBar.testEnvironment',
    defaultMessage: "Testing Environment",
  }
});
