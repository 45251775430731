/*
 *
 * PlatformPage actions
 *
 */

import {
  GET_BOARD_LISTINGS,
  GET_BOARD_LISTINGS_SUCCESS,
  GET_BOARD_LISTINGS_ERROR,

  LOADING,
  GET_PLATFORMS,
  LOAD_PLATFORMS_SUCCESS,
  LOAD_PLATFORMS_ERROR,
  GET_USER_PLATFORMS,
  LOAD_USER_PLATFORMS_SUCCESS,
  LOAD_USER_PLATFORMS_ERROR,
  RESET_PLATFORM_ADD,
  PLATFORM_ADD,
  PLATFORM_ADD_SUCCESS,
  PLATFORM_ADD_ERROR,
  PLATFORM_REMOVE,
  PLATFORM_REMOVE_SUCCESS,
  PLATFORM_REMOVE_ERROR,
  JOB_LINK,
  JOB_LINK_SUCCESS,
  JOB_LINK_ERROR,
  JOB_READ,
  JOB_READ_SUCCESS,
  JOB_READ_ERROR,
  CLEAR_CREDENTIALS_MESSAGE,
  SET_MESSAGE,
  CLEAR_MESSAGE,
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}


export function getBoardListings(subsidiaryPlatform, listingId) {
  console.log("GET_BOARD_LISTINGS")
  return {
    type: GET_BOARD_LISTINGS,
    subsidiaryPlatform, listingId
  };
}

export function getBoardListingsSuccess(boardListings, positions) {
  return {
    type: GET_BOARD_LISTINGS_SUCCESS,
    boardListings,
    positions
  };
}

export function getBoardListingsError(error) {
  return {
    type: GET_BOARD_LISTINGS_ERROR,
    error
  };
}


export function getPlatforms() {
  return {
    type: GET_PLATFORMS
  };
}

export function getPlatformsSuccess(platforms) {
  return {
    type: LOAD_PLATFORMS_SUCCESS,
    platforms
  };
}

export function getPlatformsError(error) {
  return {
    type: LOAD_PLATFORMS_ERROR,
    error
  };
}

export function getUserPlatforms() {
  console.log('getUserPlatforms')
  return {
    type: GET_USER_PLATFORMS
  };
}


export function getUserPlatformsSuccess(platforms) {
  return {
    type: LOAD_USER_PLATFORMS_SUCCESS,
    platforms
  };
}

export function getUserPlatformsError(error) {
  return {
    type: LOAD_USER_PLATFORMS_ERROR,
    error
  };
}
export function addPlatform(platform) {
  return {
    type: PLATFORM_ADD,
    platform,
  };
}

export function resetPlatformAdd(){
  return {
      type: RESET_PLATFORM_ADD
  }
}
export function addPlatformSuccess(platform) {
  return {
    type: PLATFORM_ADD_SUCCESS,
    platform,
  };
}

export function addPlatformError(error) {
  return {
    type: PLATFORM_ADD_ERROR,
    error,
  };
}

export function removePlatform(platform) {
  return {
    type: PLATFORM_REMOVE,
    platform,
  };
}

export function removePlatformSuccess(platform) {
  return {
    type: PLATFORM_REMOVE_SUCCESS,
    platform,
  };
}

export function removePlatformError(error) {
  return {
    type: PLATFORM_REMOVE_ERROR,
    error,
  };
}

export function clearCredentialsMessage(){
  return{
    type: CLEAR_CREDENTIALS_MESSAGE
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    message
  };
}
