/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  GET_SUGGESTIONS,
  GET_SUGGESTIONS_SUCCESS,
  GET_SUGGESTIONS_ERROR,
  CREATE_SUGGESTION,
  CREATE_SUGGESTION_SUCCESS,
  CREATE_SUGGESTION_ERROR,
  CLEAR_MESSAGE,
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}

export function getSuggestions() {
  console.log("GET_SUGGESTIONS")
  return {
    type: GET_SUGGESTIONS
  };
}

export function getSuggestionsSuccess(suggestions) {
  return {
    type: GET_SUGGESTIONS_SUCCESS,
    suggestions
  };
}

export function getSuggestionsError(error) {
  return {
    type: GET_SUGGESTIONS_ERROR,
    error
  };
}

export function createSuggestion(suggestion){
  console.log("CREATE_SUGGESTION")
  return {
    type: CREATE_SUGGESTION,
    suggestion
  }
}


export function createSuggestionSuccess(suggestion){
  return {
    type: CREATE_SUGGESTION_SUCCESS,
    suggestion
  }
}


export function createSuggestionError(error){
  return {
    type: CREATE_SUGGESTION_ERROR,
    error
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}
