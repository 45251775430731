import React from "react";
import { styled } from "@mui/material/styles";

export default styled("div")(({ theme }) => ({
  fontFamily: "Lato",
  fontSize: 14,
  fontWeight: 400,
  color: theme.custom.navigation.appBar.workspaceLabel.color,
  flexGrow: 1,
  marginLeft: theme.spacing(1),
}));