import { fromJS } from 'immutable';
import * as ActionTypes from './constants';
import { getData, saveData} from './localStorageManager';
import Cookie from 'js-cookie';
import { params } from 'config';
import { signOut } from 'utils/signOut'

const getCognitoUser = () => {
  const value = Cookie.get('user')
  console.log(value)
  return value !== undefined ? JSON.parse(value) : null
}
const getCognitoSession = () => {
  const idToken = Cookie.get('idToken')
  const accessToken = Cookie.get('accessToken')
  const refreshToken = Cookie.get('refreshToken')

  if (accessToken && refreshToken) {
    return {
      idToken, accessToken, refreshToken
    }
  }

  return null
}
const initialState = fromJS({
  subsidiary: {},
  user: getData('user') || {},
  authenticationErrorMessage: '',
  showMaintenanceScreen: false,
  registrationErrorMessage: '',
  menus: [],
  openViews: [],
  selectedMenuIndex: 0,
  selectedMenuItem: null,
  selectedOpenedMenuIndex: 0,
  selectedOpenedMenuItem: null,
  userIsAuthenticated:  getData('userIsAuthenticated') || false,
  currentTheme: 'darkTheme', // darkTheme, lightTheme, blueTheme, grayTheme, darkBlueTheme
  openSettingDrawer: false,
  showTabs: false,
  showOpenViews: false,
  isBoxedLayout: false,
  cognitoUser: getCognitoUser(),
  session: getCognitoSession()
});

function setBodyBackground(currentTheme) {
  const body = document.querySelector('body');

  switch (currentTheme) {
    case 'darkTheme':
    body.style.backgroundColor = '#37474f';
    break;
    case 'lightTheme':
    body.style.backgroundColor = '#eee';
    break;
    case 'blueTheme':
    body.style.backgroundColor = '#3e6e99';
    break;
    case 'grayTheme':
    body.style.backgroundColor = '#575f6a';
    break;
    case 'darkBlueTheme':
    body.style.backgroundColor = '#303a47';
    break;
    default:
    body.style.backgroundColor = '#f5f5f5';
    break;
  }
}

function appReducer(state = initialState, action) {
  switch (action.type) {

    case ActionTypes.CHANGE_LAYOUT:
    {
      const currentTheme = state.get('currentTheme');
      setBodyBackground(currentTheme);

      return state.set('isBoxedLayout', action.isBoxedLayout);
    }
    case ActionTypes.CHANGE_THEME:
    {
      setBodyBackground(action.theme);

      return state.set('currentTheme', action.theme);
    }
    case ActionTypes.CHANGE_SHOWS_TABS:
    return state.set('showTabs', action.value);
    case ActionTypes.CHANGE_SHOW_OPEN_VIEWS:
    return state.set('showOpenViews', action.value);

    case ActionTypes.SERVICE_UNAVAILABLE: {
      return state.set('showMaintenanceScreen', true);
    }
    // Authentication process
    case ActionTypes.AUTHENTICATED:
    {
      console.log("AUTHENTICATED", action.data)
      const menus = state.get('menus');
      const openViews = state.get('openViews');
      const user = Object.assign({}, action.data.data.user);
      const menuItem = menus[0];
      const openedMenuItem = openViews[0];

      user.roles = action.data.data.roles
      user.token = action.data.token

      if (user.roles.includes('company_user')){
        user.isEmployee = true
      }

      user.permissions = {}

      if (user.roles.includes('company_admin')){
        user.permissions.isExternalAdmin = true
        user.permissions.canViewDescartes = true
        user.permissions.canViewManual = true
        user.permissions.canViewDismissed = false
        user.permissions.canViewSelected = true
        user.permissions.canViewHired = true
      }
      user.permissions.canViewInterview = true

      saveData('userIsAuthenticated', true)
      saveData('user', user)
      console.log('AUTHENTICATED', user)
      console.log(localStorage)
      return state.set('userIsAuthenticated', true)
      .set('user', user)
      .set('authenticationErrorMessage', '')
      .set('selectedMenuIndex', 0)
      .set('selectedMenuItem', menuItem)
      .set('selectedOpenedMenuIndex', 0)
      .set('selectedOpenedMenuItem', openedMenuItem);
    }
    case ActionTypes.AUTHENTICATION_FAILED:
    {
      console.log(action.error)
      return state.set('authenticationErrorMessage', 'error.login');
    }
    case ActionTypes.LOAD_USER_SUCCESS:
    {
      //merge data
      const user = Object.assign(state.get('user'), action.user);

      if (user && user.roles && user.roles.includes('sys_admin')) {
        user.isEmployee = true
      }

      user.permissions = {}

      if (user && user.roles && user.roles.includes('company_admin')){
        user.permissions.isExternalAdmin = true
        user.permissions.canViewDescartes = true
        user.permissions.canViewManual = true
        user.permissions.canViewDismissed = false
        user.permissions.canViewSelected = true
        user.permissions.canViewHired = true
      }
      user.permissions.canViewInterview = true

      saveData('user', user)

      console.log("LOAD_USER_SUCCESS: user", user)
      return state.set('user', user)

    }
    case ActionTypes.CLEAR_AUTHENTICATION_MESSAGE:
    {
      return state.set('authenticationErrorMessage', '');
    }
    case ActionTypes.REGISTRATION_FAILED:
    {
      return state.set('registrationErrorMessage', action.message);
    }
    case ActionTypes.SIGN_OUT:
      signOut()
      return state;
    // End of Authentication process
    case ActionTypes.OPEN_VIEW:
    {
      const openViews = state.get('openViews');

      if (openViews.indexOf(action.menuItem) === -1) {
        openViews.push(action.menuItem);
        return state.set('openViews', openViews);
      }
      return state;
    }
    case ActionTypes.CLOSE_VIEW:
    {
      const menus = state.get('menus');
      const openViews = Object.assign([], state.get('openViews'));

      let itemFound = openViews.find((item) =>
      item.id === action.id);

      const indexToBeRemoved = openViews.indexOf(itemFound);
      let openedIndex = 0;

      if (indexToBeRemoved > 0) {
        openedIndex = indexToBeRemoved - 1;
      }

      const itemOpenedFound = openViews[openedIndex];
      let menuIndex;

      menus.forEach((menu) => {
        if (itemOpenedFound.id === menu.id) {
          itemFound = menu;
          menuIndex = menu.index;
        }
        if (menu.children) {
          menu.children.forEach((child) => {
            if (itemOpenedFound.id === child.id) {
              itemFound = child;
              menuIndex = child.index;
            }
          });
        }
      });

      openViews.splice(indexToBeRemoved, 1);

      return state.set('openViews', openViews)
      .set('selectedMenuIndex', menuIndex)
      .set('selectedMenuItem', itemFound)
      .set('selectedOpenedMenuIndex', openedIndex)
      .set('selectedOpenedMenuItem', itemOpenedFound);
    }
    case ActionTypes.SELECT_MENU_ITEM:
    {
      const menus = state.get('menus');
      const openViews = state.get('openViews');

      let itemFound;
      let index;

      menus.forEach((menu) => {
        if (action.id === menu.id) {
          itemFound = menu;
          index = menu.index;
        }
        if (menu.children) {
          menu.children.forEach((child) => {
            if (action.id === child.id) {
              itemFound = child;
              itemFound.icon = menu.icon;
              index = child.index;
            }
          });
        }
      });

      let itemOpenedFound = openViews.find((item) =>
      item.id === itemFound.id);

      let openedIndex = 0;

      if (!itemOpenedFound) {
        itemOpenedFound = Object.assign({}, itemFound);
        openedIndex = openViews.length;
      } else {
        openedIndex = openViews.indexOf(itemOpenedFound);
      }

      return state
      .set('selectedMenuIndex', index)
      .set('selectedMenuItem', itemFound)
      .set('selectedOpenedMenuIndex', openedIndex)
      .set('selectedOpenedMenuItem', itemOpenedFound);
    }
    case ActionTypes.OPEN_SETTING_DRAWER:
    return state.set('openSettingDrawer', true);
    case ActionTypes.CLOSE_SETTING_DRAWER:
    return state.set('openSettingDrawer', false);
    case ActionTypes.ANIMATE_MENUS:
    {
      let menus = state.get('menus');

      menus = menus.map((item) => {
        let newItem = item;

        if (item.children && item.children.length > 0) {
          if (item.id === action.menuId) {
            newItem = { ...item, animating: true, willCloseMenu: action.willCloseMenu };
          } else {
            newItem = { ...item, animating: false };
          }

          newItem.children = newItem.children.map((child) => {
            let newChild = child;
            newChild = { ...child, animating: newItem.animating, willCloseMenu: newItem.willCloseMenu };
            return newChild;
          });
        }

        return newItem;
      });

      return state.set('menus', menus);
    }
    case ActionTypes.TOGGLE_MENUS:
    {
      let menus = state.get('menus');

      menus = menus.map((item) => {
        let newItem = item;

        if (item.children && item.children.length > 0) {
          if (item.id === action.menuId) {
            newItem = { ...item, open: !item.open, animating: false };
          } else {
            newItem = { ...item, open: false, animating: false };
          }
        }

        return newItem;
      });

      return state.set('menus', menus);
    }
    case ActionTypes.ANIMATE_ROOT_MENUS:
    {
      let menus = state.get(action.rootMenuName);

      menus = menus.map((item) => {
        let newItem = item;

        newItem = { ...item, animatingRootMenu: true, willCloseRootMenu: action.willCloseMenu };

        return newItem;
      });

      return state.set(action.rootMenuName, menus);
    }
    case ActionTypes.TOGGLE_ROOT_MENUS:
    {
      let menus = state.get(action.rootMenuName);

      menus = menus.map((item) => {
        let newItem = item;

        newItem = { ...item, animatingRootMenu: false };

        return newItem;
      });

      return state.set(action.rootMenuName, menus);
    }
    case ActionTypes.GET_COUNTRIES_SUCCESS: {
      console.log('GET_COUNTRIES_SUCCESS', action.countries)
      const countries = Object.assign([], action.countries);

      return state.set('countries', countries);
    }
    case ActionTypes.GET_COUNTRIES_ERROR: {
      console.log("GET_COUNTRIES_ERROR", action.error)

      return state;
    }
    default:
    return state;
  }

}

export default appReducer;
