/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { push } from 'react-router-redux'
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_USERS,
  CREATE_USER,
  EDIT_USER,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  createUserSuccess,
  createUserError,
  editUserSuccess,
  editUserError,
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sUsers() {
  const requestURL = `${root}/users`;
  console.log("sagas");
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getUsersSuccess(result));

  } catch (err) {
    yield put(getUsersError(err));
  }
}

export function* getUsersApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_USERS, sUsers);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCreateUser(action) {
  const requestURL = `${root}/users`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.user),
    });
    yield put(createUserSuccess(result));
    yield put(push('/users'));
  } catch (err) {
    yield put(createUserError(err));
  }
}

export function* createUserApi() {
  console.log('createUserApi')
  const watcher = yield takeLatest(CREATE_USER, sCreateUser);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


export function* sEditUser(action) {
  try {
    console.log('sEditUser', action)
    const requestURL = `${root}/users/`+action.user.id;
    console.log(requestURL)

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.user),
    });
    yield put(editUserSuccess(result));
    // yield put(push('/users'));
  } catch (err) {
    yield put(editUserError(err));
  }
}

export function* editUserApi() {
  const watcher = yield takeLatest(EDIT_USER, sEditUser);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


// All sagas to be loaded
export default [
  getUsersApi,
  createUserApi,
  editUserApi
];
