import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { List } from "react-virtualized";

import PrimaryButton from '../buttons/PrimaryButton';

/**
 * This constant is set to 20000 to prevent heavy search
 * in autocomplete for clients with large options list
 * like Oxxo. 
 */
const AUTOCOMPLETE_LIST_LARGE_AMOUNT = 20000;
const MIN_INPUT_LENGTH_TO_ENABLE_SEARCH = 3;

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 72;
  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={1}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={props => {
            return React.cloneElement(children[props.index], {
              style: props.style
            });
          }}
          role={role}
          containerStyle={{ width: "100%", maxWidth: "100%" }}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
});

export const AutoCompleteLargeCondition = (props) => {
  return <AutoCompleteCondition
    {...props}
    disableListWrap
    ListboxComponent={ListboxComponent}
  />
}

const AutoCompleteCondition = ({ label, value, onChange, metadata, required, isLoading = false, errorMessage, ...override }) => {
  const [inputLength, setInputLength] = React.useState(0);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = React.useState(false);
  const isAutocompleteExtremelyLarge = metadata.options.length > AUTOCOMPLETE_LIST_LARGE_AMOUNT;
  const allowSearch = (isAutocompleteExtremelyLarge && inputLength >= MIN_INPUT_LENGTH_TO_ENABLE_SEARCH) || !isAutocompleteExtremelyLarge;

  const component = (
    <Autocomplete
      options={allowSearch ? metadata.options : []}
      open={isAutocompleteOpen}
      onOpen={() => {
        if (allowSearch) setIsAutocompleteOpen(true)
      }}
      onInputChange={(_, value) => {
        if ((value.length >= 3 && isAutocompleteExtremelyLarge) || (!value.length && !isAutocompleteExtremelyLarge)) {
          if (!isAutocompleteOpen) setIsAutocompleteOpen(true);
        } else {
          setIsAutocompleteOpen(false);
        }
      }}
      onClose={() => setIsAutocompleteOpen(false)}
      autoComplete={false}
      getOptionLabel={(option) => metadata.getOptionLabel ? metadata.getOptionLabel(option) : option.name}
      renderInput={(params) => {
        setInputLength(params.inputProps.value.length);

        return (
          <TextField
            {...params}
            label={label}
            size='small'
            error={metadata && metadata.error}
            variant="outlined"
            required={required}
            helperText={errorMessage}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
      renderOption={(option) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {option.icon}
            <p style={{ marginLeft: '5px' }}>{metadata.getOptionLabel ? metadata.getOptionLabel(option) : option.name}</p>
          </div>
        )
      }}
      value={metadata && metadata.getValueItem ? metadata.getValueItem(value) : value}
      onChange={(event, newValue) => onChange(newValue)}
      fullWidth
      {...override}
    />
  );

  return metadata.onAdd ? <AutoCompleteWithAdd onAdd={metadata.onAdd}>{component}</AutoCompleteWithAdd> : component;
}

const AutoCompleteWithAdd = ({ children, onAdd }) => {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={10}>
        {children}
      </Grid>
      <Grid item xs={2}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
        >
          <PrimaryButton type='outlined' size='small' onClick={onAdd} style={{ minWidth: '0px', width: '100%', height: '40px' }}>+</PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AutoCompleteCondition
