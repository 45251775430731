/**
 NSS validation from: https://es.stackoverflow.com/questions/32023/c%C3%B3mo-validar-un-n%C3%BAmero-de-seguridad-social-nss-de-m%C3%A9xico
 */
 const nssValido = (nss) => {
  console.log('### nssValido 1', { nss })
  const re = /^(\d{2})(\d{2})(\d{2})\d{5}$/
  const validado = nss.match(re);
  console.log('### nssValido 2', { nss, re, validado });
  if (!validado) // 11 dígitos y subdelegación válida?
    return false;

  const subDeleg = parseInt(validado[1], 10);
  const anno = new Date().getFullYear() % 100;
  let annoAlta = parseInt(validado[2], 10);
  let annoNac = parseInt(validado[3], 10);

  //Comparar años (excepto que no tenga año de nacimiento)
  if (subDeleg !== 97) {
      if (annoAlta <= anno) annoAlta += 100;
      if (annoNac  <= anno) annoNac  += 100;
      if (annoNac  >  annoAlta)
        return false; // Err: se dio de alta antes de nacer!
  }
  
  return luhn(nss);
}

const luhn = (nss) => {
  let suma = 0;
  let par = false;
  
  for (let i = nss.length - 1; i >= 0; i--) {
    let digito = parseInt(nss.charAt(i), 10);
    if (par && (digito *= 2) > 9) {
      digito -= 9;
    }
    par = !par;
    suma += digito;
  }
  return (suma % 10) == 0;
}

class Nss {
  constructor(nss) {
    this.nss = nss;
    this.valid = nssValido(nss);
  }

  isValid() {
    return this.valid;
  }

  isValidYearOfBirthdate(date) {
    if (!this.valid) {
      return false;
    }
    if (!date) {
      return false;
    }
    try {
      const nssYearOfBirth = this.nss.substring(4, 6);
      const dateYear = date.replaceAll('-', '').substring(2, 4);
      return nssYearOfBirth === dateYear;
    } catch (_) {
      return false;
    }
  }

  toString() {
    return this.nss;
  }
}

export default Nss;
