/*
 *
 * UserPage constants
 *
 */

export const CONTACT_CANDIDATE = 'app/CandidateBag/CONTACT_CANDIDATE'
export const CONTACT_CANDIDATE_SUCCESS = 'app/CandidateBag/CONTACT_CANDIDATE_SUCCESS'
export const CONTACT_CANDIDATE_ERROR = 'app/CandidateBag/CONTACT_CANDIDATE_ERROR'

export const LOADING = 'app/CandidateBag/LOADING'
export const CLEAR_MESSAGE = 'app/CandidateBag/CLEAR_MESSAGE'
