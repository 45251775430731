import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTable, useFilters, useFlexLayout, useResizeColumns, useExpanded, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { createStyles, Box, CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from "@material-ui/core"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OffTablePaginationButton from '../buttons/OffTablePaginationButton';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ChevronUpIcon from '../icons/ChevronUpIcon';

const Styles = styled.div`
  .table {
    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .tr-empty {
      height: 30px;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      background-color: #FFFFFF;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 1px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .footer {
        bottom: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
      }

      [data-sticky-first-right-td] {
      }
    }
  }
`;

const Overlay = ({ show, opacity, children }) => {
  if (!show) {
    return null
  }
  return (
    <Box position='absolute' top={0} left={0} height='100%' width='100%' zIndex={5} style={{ backgroundColor: '#E5E5E5', opacity: opacity }} >
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        style={{ height: '100%', marginTop: '50px' }}
      >
        {children}
      </Grid>
    </Box>
  )
}

const CustomTableCell = withStyles((theme) =>
  createStyles({
    root: {
      borderRightWidth: 1,
      borderRightColor: theme.palette.grey[300],
      borderRightStyle: "solid",
      fontWeight: 500,
      color: '#000000',
      whiteSpace: 'nowrap'
    },
  })
)(TableCell);

const CustomTableHeaderCell = withStyles((theme) =>
  createStyles({
    root: {
      fontWeight: 'bold',
      color: '#A2A2A2',
      whiteSpace: 'nowrap'
    },
  })
)(CustomTableCell);

const CustomOffTablePaginationButton = withStyles((theme) => 
  createStyles({
    root: {
      padding: "6px 10px 6px 10px",
      backgroundColor: "#1049BC",
      color: "#ffffff",
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "10px",
      borderRadius: "5px",
      minWidth: "auto"
    },
    'disabled': {
      backgroundColor: "#E7E7E7",
      color: "#999999"
    },
    "hover": {
      backgroundColor: "red",
    }
  })
)(OffTablePaginationButton);

const ReactTableAdapter = (props) => {
  const {
    className = "",
    data,
    columns,
    loading = false,
    getCellProps = () => { },
    trClassName = "",
    SubComponent,
    height,
    showPagination,
    page,
    pages,
    onPageChange,
    defaultPageSize,
    nextText,
    previousText,
    onSortChange,
    defaultSorted = [],
    sortable,
    filterable,
    noDataText,
    numberOfResultsText,
    minResultCurrentPage,
    maxResultCurrentPage,
    showPaginationUIWithIcons
  } = props;

  /* Defining default column width for every column */
  const defaultColumn = {
  };

  console.debug('ReactTableAdapter', props)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      SubComponent,
      manualSortBy: true,
      disableFilters: !!!filterable,
      disableSortBy: !!!sortable,
      initialState: {
        sortBy: defaultSorted
      }
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    useExpanded,
    useResizeColumns,
    useSticky
  );

  useEffect(() => {
    if (onSortChange) {
      onSortChange(sortBy)
    }
  }, [sortBy])

  const EmptyRow = () => {
    return (
      headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()} className='tr-empty' >
          {headerGroup.headers.map((column, index) => (
            <CustomTableCell key={index} {...column.getHeaderProps()} className='td'>

            </CustomTableCell>
          ))}
        </TableRow>
      ))
    )
  };

  const Pagination = () => (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      spacing={1}
    >
      {showPaginationUIWithIcons && 
        <React.Fragment>
          <Grid item>
            <Grid container direction='row' alignItems="center">
              <Box sx={{backgroundColor: "#EEF3FF", color: "#1049BC", padding: "6px 10px 6px 10px", borderRadius: "5px", marginRight: "4px"}}><Typography variant="body2">{minResultCurrentPage} - {maxResultCurrentPage}</Typography></Box>
              <Typography variant="body2">{numberOfResultsText}</Typography>
            </Grid>
          </Grid>
          <Grid item spacing={2}>
            <CustomOffTablePaginationButton disabled={!(page > 0)} onClick={() => onPageChange(page - 1)}><ArrowBackIosNewIcon sx={{fontSize: 10}}/></CustomOffTablePaginationButton>
          </Grid>
          <Grid item spacing={2}>
            <CustomOffTablePaginationButton disabled={!(page + 1 !== pages)} onClick={() => onPageChange(page + 1)}><ArrowForwardIosIcon sx={{fontSize: 10}}/></CustomOffTablePaginationButton>
          </Grid>
        </React.Fragment>
      }
      {
        !showPaginationUIWithIcons && 
        <React.Fragment>
          <Grid item>
            {page > 0 && <OffTablePaginationButton onClick={() => onPageChange(page - 1)}>{'<'} {previousText}</OffTablePaginationButton>}
          </Grid>
          <Grid item>
            <Typography variant="body2">{page + 1} - {pages}</Typography>
          </Grid>
          <Grid item>
            {page + 1 !== pages && <OffTablePaginationButton onClick={() => onPageChange(page + 1)}>{nextText} {'>'}</OffTablePaginationButton>}
          </Grid>
        </React.Fragment>
      }
    </Grid>
  )

  const emptyRows = defaultPageSize > rows.length
    ? new Array(defaultPageSize - rows.length).fill(0).map((_, index) => {
      return <EmptyRow key={index} />
    })
    : null

  return (<Styles >
    <Box position='relative'>
      <Overlay
        show={loading}
        opacity={0.5}
      >
        <CircularProgress
          style={{ zIndex: 10 }}
        />
      </Overlay>
      <Overlay 
        show={!loading && rows.length === 0 && !!noDataText}
        opacity={0.5}
      >
        <Box border={1} padding="19px 77px" sx={{backgroundColor:"#ffffff"}}>{noDataText}</Box>
      </Overlay>

      <TableContainer style={{ height: `${height}px` }}>

        <Table size='medium' className='table sticky'>
          <TableHead className='header'>
            {
              headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()} className='tr' >
                  {headerGroup.headers.map((column, index) => (
                    <CustomTableHeaderCell
                      key={index}
                      {...column.getHeaderProps()}
                      className='th'
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        {...column.getSortByToggleProps()}
                      >
                        <Grid item>
                          {column.render('Header')}
                        </Grid>
                        {/* Add a sort direction indicator */}
                        <Grid item>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <ChevronDownIcon />
                              : <ChevronUpIcon />
                            : null}
                        </Grid>
                      </Grid>
                      {/* Render the columns filter UI */}
                      <div>{!!filterable && column.canFilter && !!column.Filter ? column.render('Filter') : null}</div>
                      {/* Use column.getResizerProps to hook up the events correctly */}
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                      />
                    </CustomTableHeaderCell>
                  ))}
                </TableRow>
              ))
            }

          </TableHead>
          <TableBody {...getTableBodyProps()} className='body'>
            {
              rows.map((row, index) => {
                prepareRow(row);
                return <TableRow key={index} {...row.getRowProps()} className='tr' >
                  {row.cells.map((cell, index) => <CustomTableCell key={index} {...cell.getCellProps(getCellProps(row))} className='td'>{cell.render('Cell')}</CustomTableCell>)}
                </TableRow>
              })
            }
            {emptyRows}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && pages > 1 && <Pagination />}
    </Box>
  </Styles>)
};

export default ReactTableAdapter;