import React from 'react'
import { connect } from 'react-redux'
import { candidateChanged, hideRescheduleInterview } from '../../../store/candidateActions/actions'
import RescheduleInterviewDialog from '../RescheduleInterviewDialog'

const RescheduleInterviewAction = ({ store: { show, data }, close, notify }) => {
  const { candidateId, listingId, stepId } = data || {}
  const onSucceed = () => {
    notify()
    close()
  }
  return <RescheduleInterviewDialog
    show={show}
    candidateId={candidateId}
    listingId={listingId}
    stepId={stepId}
    onClose={close}
    onSucceed={onSucceed}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').interview.reschedule
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideRescheduleInterview()),
    notify: () => dispatch(candidateChanged())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleInterviewAction)
