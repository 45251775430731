const { name, version } = require('../package.json');
const { parseSemVer } = require('./semver')

function getEnv() {
  return process.env.ENVIRONMENT || process.env.NODE_ENV
}

function getEnvOrDefault() {
  return getEnv() || 'UNDEFINED'
}

function isProdEnv() {
  return getEnv() === 'production'
}

function isStagingEnv() {
  return getEnv() === 'staging'
}

function isDevEnv() {
  return !isProdEnv() && !isStagingEnv()
}

function getReleaseName(getEnv) {
  try {
    const nameVersion = `${name}@${version}`
    if (isProdEnv()) {
      return nameVersion
    }
    return `${nameVersion}@${getEnv()}`
  } catch (error) {
    return isProdEnv() ? version : null
  }
}

function getEnvExtended(envResolver) {
  try {
    return envResolver() || getEnvOrDefault()
  } catch (error) {
    return getEnvOrDefault()
  }
}

function parseSubdomain(subdomain) {
  if (subdomain && (subdomain.startsWith('PR-') || subdomain.startsWith('pr-'))) {
    return subdomain.toUpperCase()
  }
  return null
}

function getClientEnvExtended() {
  return getEnvExtended(() => {
    return parseSubdomain(window.location.host.split('.')[0])
  })
}

function getServerEnvExtended(req) {
  return getEnvExtended(() => {
    return parseSubdomain(req.subdomains.pop())
  })
}

function getClientReleaseName() {
  return getReleaseName(getClientEnvExtended)
}

function getServerReleaseName(req) {
  return getReleaseName(() => getServerEnvExtended(req))
}

function getVersionDetail() {
  const { major, minor, patch } = parseSemVer(version, false)
  return {
    version: `${major}.${minor}.${patch}`,
    "version-major": `${major}.x`,
    "version-minor": `${major}.${minor}.x`,
  }
}

module.exports = { getEnv, getClientEnvExtended, getClientReleaseName, getServerEnvExtended, getServerReleaseName, getVersionDetail, isProdEnv, isStagingEnv, isDevEnv }