/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
 import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  GET_PLATFORM_LISTINGS,
  CREATE_PLATFORM_LISTING,
  REMOVE_PLATFORM_LISTING,
  EDIT_PLATFORM_LISTING
} from './constants';

import {
  getPlatformListingsSuccess,
  getPlatformListingsError,
  removePlatformListingSuccess,
  removePlatformListingError,
  createPlatformListingSuccess,
  createPlatformListingError,
  editPlatformListingSuccess,
  editPlatformListingError,
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sPlatformListings(action) {
  console.log("action", action)
  let requestURL = `${root}/platformListings`;
  if(action.listing && action.listing.id){
    requestURL = requestURL + "/"+action.listing.id
  }
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getPlatformListingsSuccess(result));

  } catch (err) {
    yield put(getPlatformListingsError(err));
  }
}

export function* getPlatformListingsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_PLATFORM_LISTINGS, sPlatformListings);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sRemovePlatformListing(action) {
  const requestURL = `${root}/platformListings/`+action.platformListing.id;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log(result)
    yield put(removePlatformListingSuccess(action.platformListing));

  } catch (err) {
    yield put(removePlatformListingError(err));
  }
}

export function* removePlatformListingApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(REMOVE_PLATFORM_LISTING, sRemovePlatformListing);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCreatePlatformListing(action) {
  const requestURL = `${root}/platformListings`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.platformListing),
    });
    yield put(createPlatformListingSuccess(result));

  } catch (err) {
    yield put(createPlatformListingError(err));
  }
}

export function* createPlatformListingApi() {
  const watcher = yield takeLatest(CREATE_PLATFORM_LISTING, sCreatePlatformListing);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sEditPlatformListing(action) {
  try {
    console.log('sEditPlatformListing', action)
    const requestURL = `${root}/platformListings/`+action.platformListing.id;
    console.log(requestURL)

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.platformListing),
    });
    yield put(editPlatformListingSuccess(result));
    yield put(push('/platformListings'));
  } catch (err) {
    yield put(editPlatformListingError(err));
  }
}

export function* editPlatformListingApi() {
  const watcher = yield takeLatest(EDIT_PLATFORM_LISTING, sEditPlatformListing);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}



// All sagas to be loaded
export default [
  getPlatformListingsApi,
  removePlatformListingApi,
  createPlatformListingApi,
  editPlatformListingApi
];
