import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../actions';
import * as localeActions from '../../LanguageProvider/actions';
import { makeSelectGlobal } from '../selectors';
import { selectLocale, selectIsDefault } from '../../LanguageProvider/selectors'
import { updateContentDimensions } from '../appUtils';
import { findMenuItem } from '../../../components/LeftDrawer/menuUtils';
import { ThemeProvider } from '@material-ui/core/styles';
import LockerAuth from '../../LockerAuth'
import emiTheme from '../../../v2/utils/EmiTheme'

import CacheBuster from '../../../cacheBuster';
import Box from '@material-ui/core/Box'

const { SERVICE_UNAVAILABLE_EVENT, SERVICE_OK_EVENT } = require('../../../utils/request');

const StandalonePage = ({ children }) => (
  <Box height="100vh" display="flex" flexDirection="column">
    <Box
      style={{ background: "white" }}
      display="flex" flexDirection="column"
      flex={1}
      overflow="auto"
      id="standalone-page"
    >{children}</Box>
  </Box>
)

class App extends React.Component {
  constructor(props) {
    super(props);

    this.renderPages = this.renderPages.bind(this);
  }

  componentWillMount() {

    if (this.props.appStore.user) {
      this.props.localeActions.changeLocaleByUser(this.props.appStore.user)
    }

    document.addEventListener(SERVICE_UNAVAILABLE_EVENT, () => {
      this.props.actions.serviceUnavailable();

      const handleServiceOkEvent = () => {
        this.props.actions.serviceAvailable();
        document.removeEventListener(SERVICE_OK_EVENT, handleServiceOkEvent, false);
      }
      document.addEventListener(SERVICE_OK_EVENT, handleServiceOkEvent, false);
    }, false);
  }

  componentDidMount() {
    window.addEventListener('resize', updateContentDimensions);
  }

  componentWillReceiveProps(nextProps) {
    const nextRoute = nextProps.routes[nextProps.routes.length - 1];
    const currentRoute = this.props.routes[this.props.routes.length - 1];

    // Any change on routes are catch here
    if (nextRoute !== currentRoute) {
      const url = nextRoute.path;
      const userMenuData = findMenuItem(this.props.appStore.menus, 'url', url);
      if (userMenuData.foundMenuItem) {
        // Select menu item
        this.props.actions.selectMenuItem(userMenuData.foundMenuItem.id);
      }
    }

  }

  componentWillUpdate() {
    updateContentDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', updateContentDimensions);
  }

  renderPages() {
  
    const path = this.props.location.pathname;
    const currentRoute = this.props.routes[this.props.routes.length - 1];

    if (window.location.hostname === 'emi.emilabs.ai') {
      window.location = `https://app.emilabs.ai${window.location.pathname}`
      return null;
    }

    console.log("renderPages:", this.props.appStore.signedOut, this.props.appStore.userIsAuthenticated, this.props.location)
    if (currentRoute.type === 'public' || currentRoute.isPublic === true) {
      return (<div>
        {React.cloneElement(this.props.children, {
          key: path,
        })}
      </div>);
    } else if (currentRoute.type === 'standalone') {
      return (<StandalonePage>
        {React.cloneElement(this.props.children, {
          key: path,
        })}
      </StandalonePage>);
    }
    return (<LockerAuth />);
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <ThemeProvider theme={emiTheme}>
              {this.renderPages()}
            </ThemeProvider>
          );
        }}
      </CacheBuster>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
  routes: PropTypes.any,
  appStore: PropTypes.any,
  actions: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  appStore: makeSelectGlobal(),
  locale: selectLocale(),
  isDefault: selectIsDefault(),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appActions, dispatch),
    localeActions: bindActionCreators(localeActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
