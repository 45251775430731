import React from 'react'
import TextField from '@material-ui/core/TextField'

const NumberCondition = ({ label, value, onChange, metadata: { error, errorMessage } }) => (
  <TextField
    id="standard-number"
    variant="outlined"
    label={label}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    type="number"
    fullWidth
    size='small'
    error={ error }
    helperText={ error ? errorMessage : '' }
  />
)

export default NumberCondition