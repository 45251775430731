import { createSelector } from 'reselect';

/**
 * The global state selectors
 */

const selectListingPage = () => (state) => state.get('listings');

const makeSelectListingPage = () => createSelector(
  selectListingPage(),
  (substate) => substate.toJS()
);

const makeSelectListingsByType = (types) => createSelector(
  makeSelectListingPage(),
  (substate) => substate.listings ? substate.listings.filter(listing => types.includes(listing.listingType)) : []
)

const makeSelectListingsByNotType = (types) => createSelector(
  makeSelectListingPage(),
  (substate) => substate.listings ? substate.listings.filter(listing => !types.includes(listing.listingType)) : []
)

const makeSelectListingsByTypeAndState = (types, states) => createSelector(
  makeSelectListingPage(),
  (substate) => substate.listings ? substate.listings.filter(listing => types.includes(listing.listingType) && states.includes(listing.state)) : []
)

export {
  selectListingPage,
  makeSelectListingPage,
  makeSelectListingsByType,
  makeSelectListingsByNotType,
  makeSelectListingsByTypeAndState
};
