import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import PrimaryButton from '../../../../v2/components/buttons/PrimaryButton';

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Filter from './filter';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import DialogReasonRejection from './dialog/reasonRejection';
import StepToolbar from '../toolbar';
import ToolbarApproved from '../../../../v2/components/toolbars/toolbarApproved';
import ToolbarRejected from '../../../../v2/components/toolbars/toolbarRejected';
import messages from '../../messages';
const config = require('../../../../config');
const api_server = config.params.API_SERVER;
const localStorageManager = require('../../../../containers/App/localStorageManager');
import '../Style/emi-react-table.css';
import LocationReassignDialog from './dialog/LocationReassignDialog/LocationReassignDialog';
import CustomButtonConfirmationDialog from './dialog/CustomButtonConfirmationDialog/CustomButtonConfirmationDialog';
import ReactTableAdapter from '../../../../v2/components/others/ReactTableAdapter';
import CopyToClipboard from '../../../../v2/components/copyToClipboard/CopyToClipboard';
import moment from 'moment';
import Actions from '../../../Candidate/Actions'
import SuccessButton from '../../../../v2/components/buttons/SuccessButton';
import SecondaryButton from '../../../../v2/components/buttons/SecondaryButton';
import ErrorButton from '../../../../v2/components/buttons/ErrorButton';
import Tooltip from '@material-ui/core/Tooltip';
import ApproveCandidateDialog from './dialog/ApproveCandidateDialog';
import RejectCandidateDialog from './dialog/RejectCandidateDialog';
import RejectCandidateForReasonDialog from './dialog/RejectCandidateForReasonDialog';
import { CircularProgress } from '@material-ui/core';
import { customButtonRequest, changeCandidateState } from '../../../../utils/connector';
import { resolveInterviewState } from '../../../../utils/interviewState';
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import IconButton from '@material-ui/core/IconButton'
import dateUtils from '../../../../utils/date'
import track from '../../../../utils/track';

export const notAssignedWorkingPlaceReasons = ['No assigned working place', 'No tiene sucursal asignada']

const styles = (theme) => ({
  showFullText: {
    whiteSpace: 'pre-wrap',
  },
  changedMenuItem: {
    backgroundColor: 'inherit',
    // color: 'rgb(228, 240, 251)',
    paddingLeft: '30px',
    fontSize: '13px',

    width: '100%',
    height: '100%',
    position: 'relative',
    bottom: '0',
    top: '0',
    left: '0',
    right: '0',
    '&:before': {
      border: '0px',
    },
  },
  changeMenuItem: {
    backgroundColor: 'lightgrey',
    color: 'black',
    fontSize: '13px',
    fontFamily: 'Helvetica Neue',
    width: '100%',
    height: '100%',
    position: 'relative',
    bottom: '0',
    top: '0',
    left: '0',
    right: '0',
    '&:before': {
      border: '0px',
    },
  },
});

export function isANotAssignedWorkingPlaceReason(reason) {
  return reason ? notAssignedWorkingPlaceReasons.some(x => reason.startsWith(x)) : false
}

function getData(tableData) {
  const data = tableData.map((item) => {
    // using chancejs to generate guid
    // shortid is probably better but seems to have performance issues
    // on codesandbox.io
    if (!item) return {};
    const _id = item.candidate_id;
    return {
      _id,
      ...item,
    };
  });

  return data;
}

function addOrRemoveFromArray(arr, key) {
  const keyIndex = arr.indexOf(key);
  // check to see if the key exists
  if (keyIndex >= 0) {
    // it does exist so we will remove it using destructing
    arr = [...arr.slice(0, keyIndex), ...arr.slice(keyIndex + 1)];
  } else {
    // it does not exist so add it
    arr.push(key);
  }
  return arr;
}

function addToArray(arr, key) {
  const keyIndex = arr.indexOf(key);
  // check to see if the key exists
  if (keyIndex == -1) {
    // it does not exist so add it
    arr.push(key);
  }
  return arr;
}
function removeFromArray(arr, key) {
  const keyIndex = arr.indexOf(key);
  // check to see if the key exists
  if (keyIndex >= 0) {
    // it does exist so we will remove it using destructing
    arr = [...arr.slice(0, keyIndex), ...arr.slice(keyIndex + 1)];
  }
  return arr;
}

function emptyAsNull(value) {
  return value || null;
}

const parseToInt = (value) => {
  try{
    return parseInt(value)
  } catch(err) {
    return null
  }
}

const formatTimestamp = (stringDate, format, defaultFn) => { 
  try {
    const timezoneOffset = new Date().getTimezoneOffset()
    const momentValue = moment(new Date(stringDate)).add(timezoneOffset * -1, 'minutes')
    return momentValue.format(format)
  } catch (e) {
    return defaultFn(format)
  }  
};

const formatDate = (stringDate, format) => {
  if (stringDate && stringDate.length === 10) {
    return moment(stringDate).format(format);
  }
  return formatTimestamp(stringDate, format, (format) => moment.utc(stringDate).format(format));
};

const getValue = (value, header) => {
  const hasTransformation = val => !!(header && header.transformation && header.transformation[val])
  const getRawOrTransform = val => hasTransformation(val) ? header.transformation[val] : val

  if (Array.isArray(value)) {
    return value
      .map(getRawOrTransform)
      // Remove the last period from the original string, ONLY if there is one AND it's not the last element
      .map((s, index) => s.endsWith('.') && (index !== value.length-1)  ? s.slice(0, -1) : s)
      .join('. ')
  }

  return getRawOrTransform(value)
}

const WM_TALENT_POOL_HARD_REASONS = ["Aceptó otra oferta laboral", "Candidato \“No recontratable\”", "No acepta rolar turnos", "No tiene fit cultural", "No tiene interés en la empresa", "Candidato interno - No cumple con validación de RRHH"]
const WM_LISTING_IDS = [1530, 1541, 1556, 1605]

const getWMReasonLabel = (reason) => {
  if (WM_TALENT_POOL_HARD_REASONS.includes(reason)) {
    return `${reason} *`;
  }
  return reason;
};

const isCandidateInWMListing = ({ listingId }) => {
  return WM_LISTING_IDS.includes(listingId)
};

const CandidateFeedback = injectIntl(({ candidate, errors, reasons, onApproved, onRejected, isRejected, extraButtons, intl: { formatMessage }, stepViewConfiguration }) => {
  const { reason } = candidate
  const isNotEmptyArray = (obj) => obj && Array.isArray(obj) && obj.length > 0
  const hasRejectionReasons = isNotEmptyArray(reason)
  const hasErrors = isNotEmptyArray(errors)
  const DisabledApproveButton = ({ reason }) => (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={reason}
      placement={'right-end'}
    >
      {/* Is div tag necessary? https://material-ui.com/components/tooltips/#disabled-elements */}
      <div>
        <SecondaryButton
          size='small'
          disabled
        >{formatMessage(messages.descartes.button.approve)}</SecondaryButton>
      </div>
    </Tooltip>
  )
  const ApproveCandidate = () => {
    const [open, setOpen] = useState(false)
    const doOpen = () => setOpen(true)
    const doClose = () => setOpen(false)
    const doConfirm = () => {
      setOpen(false)
      onApproved(candidate)
    }
    const candidateData = {
      name: candidate.name_logic,
      nickname: candidate.nickname,
      phone: candidate.telephone,
      email: candidate.email,
      id: candidate._id,
      listingId: candidate.listing_id,
      stepId: candidate.step_id
    }
    if (hasErrors) {
      const content = []
      const requiredErrors = errors.filter(x => x.error_reason === undefined || x.error_reason === undefined || x.error_reason === 'required' )
      if (requiredErrors.length > 0) {
        content.push(<React.Fragment>
          <p>{formatMessage(messages.descartes.approveTooltip.requiredFields)}</p>
          <ul>{requiredErrors.map(field => <li>{field.label}</li>)}</ul>
        </React.Fragment>)
      }
      const otherErrors = errors.filter(x => x.error_reason && x.error_reason !== 'required')
      if (otherErrors.length > 0) {
        content.push(<React.Fragment>
          <p>{formatMessage(messages.descartes.approveTooltip.requiredFields)}</p>
          <ul>{otherErrors.map(field => {
            const error = messages.descartes.approveTooltip[field.error_reason]
            const errorMessage = error ? `: ${formatMessage(error)}` : 'SASA'
            return <li key={field.id}>{field.label}{errorMessage}</li>
          })}</ul>
        </React.Fragment>)
      }
      
      return <DisabledApproveButton reason={content} />
    }

    if (hasRejectionReasons && reason.some(x => isANotAssignedWorkingPlaceReason(x.reason))) {
      return <DisabledApproveButton reason={formatMessage(messages.descartes.workingPlaceNotAssigned)} />
    }

    return <React.Fragment>
      <ApproveCandidateDialog
        candidate={candidateData}
        open={open}
        onClose={doClose}
        onConfirm={doConfirm}
        stepViewConfiguration={stepViewConfiguration}
      />
      <SuccessButton
        size='small'
        onClick={doOpen}
      >{formatMessage(messages.descartes.button.approve)}</SuccessButton>
    </React.Fragment>
  }
  const RejectCandidate = () => {
    const [open, setOpen] = useState(false)
    const doOpen = () => setOpen(true)
    const doClose = () => setOpen(false)
    const doConfirm = (reasons) => {
      setOpen(false)
      onRejected(candidate, reasons)
    }
    if (isRejected) {
      return <SecondaryButton
        size='small'
        disabled
      >{formatMessage(messages.descartes.button.reject)}</SecondaryButton>
    }
    const isInWMListing = isCandidateInWMListing({ listingId: candidate.listing_id });
    return <React.Fragment>
      <RejectCandidateDialog
        name={candidate.name_logic}
        reasons={reasons}
        currentReasons={hasRejectionReasons ? reason.map(({ reason, reason_id }) => ({ reason, id: reason_id })) : []}
        open={open}
        onClose={doClose}
        onConfirm={doConfirm}
        labelResolver={isInWMListing ? getWMReasonLabel : null}
        references={isInWMListing ? ['* Razón de rechazo dura'] : null }
      />
      <ErrorButton
        size='small'
        onClick={doOpen}
      >{formatMessage(messages.descartes.button.reject)}</ErrorButton>
    </React.Fragment>
  }
  const RejectionReasonButton = ({ type, label, value }) => {
    const [open, setOpen] = useState(false)
    const doOpen = () => setOpen(true)
    const doClose = () => setOpen(false)
    const doConfirm = (reason) => {
      setOpen(false)
      onRejected(candidate, [reason])
    }
    if (isRejected) {
      return <SecondaryButton
        size='small'
        disabled
      >{label}</SecondaryButton>
    }
    return <React.Fragment>
      <RejectCandidateForReasonDialog
        name={candidate.name_logic}
        reason={value}
        open={open}
        onClose={doClose}
        onConfirm={doConfirm}
      />
      <SecondaryButton
        size='small'
        onClick={doOpen}
      >{label}</SecondaryButton>
    </React.Fragment>
  }

  return <Grid container spacing={1}>
    <Grid item>
      <ApproveCandidate />
    </Grid>
    <Grid item>
      <RejectCandidate />
    </Grid>
    {
      extraButtons && extraButtons.filter(({ type }) => type === 'rejectionReason').map((button, index) =>
        <Grid item key={index}>
          <RejectionReasonButton {...button} />
        </Grid>
      )
    }
  </Grid>
})

const EditableTextarea = ({ value, fieldType, onBlur, hasError }) => {
  const [currentValue, setCurrentValue] = useState('')
  useEffect(() => {
    setCurrentValue(value)
  }, [value])
  return (
    <textarea
      style={{
        border: hasError ? '3px solid #ff1b1b' : '1px solid lightgrey',
        borderRadius: '5px',
        backgroundColor: hasError ? '#f5aeae' : 'white',
        width: '95%',
        height: '90%',
        fontFamily: 'Helvetica Neue',
      }}
      value={currentValue}
      onChange={(e) => {
        const newValue = fieldType === 'number' ? e.target.value.replace(/[^\d]/g, '') : e.target.value
        setCurrentValue(newValue)
      }}
      onBlur={() => onBlur(currentValue)}
    ></textarea>
  );
}

class DescartesTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    const data = getData(this.props.data.tableData);
    const headers = Object.assign([], this.props.data.header || []);

    const cvKeys = [];
    if (data.length > 0) {
      for (let h in data[0]) {
        if (h.startsWith('cv') || h.startsWith('hv')) {
          if (cvKeys.indexOf(h) == -1) {
            cvKeys.push(h);
          }
        }
      }
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      data,
      storeAssignmentModal: {
        candidateId: null,
        reasons: [],
        userReasons: [],
        rowIndex: null,
        show: false,
      },
      headers,
      cvKeys,
      selected: [],
      selectAll: false,
      loading: false,
      filterable: false,
      columns: [],
      dialog: {
        reasonRejection: {
          show: false,
          reasons: [],
          userReasons: [],
        },
      },
      errors: {
        requiredFields: [],
      },
      filters: {},
      height: 0,
      loadingCustomButton: {},
      customButtomConfirmationModal: {
        show: false,
        candidate: {},
        button: {}
      }
    };
  }

  componentDidMount() {
    const headers = this.state.headers;
    console.log('newProps.data.header', headers);
    if (headers) {
      let filterable = false;
      headers.map((header) => {
        filterable = filterable || header.filterable;
      });
      const { columns } = this.buildColumns(headers);
      this.setState({ columns, filterable });
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ height: window.innerHeight - 260 });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data.header != this.state.headers) {
      const { columns } = this.buildColumns(newProps.data.header);
      let filterable = false;
      if (newProps.data.header) {
        newProps.data.header.map((header) => {
          filterable = filterable || header.filterable;
        });
      }
      this.setState({
        headers: Object.assign([], newProps.data.header || []),
        columns,
        filterable,
      });
    }
    if (newProps.data.tableData != this.props.data.tableData) {
      const data = getData(newProps.data.tableData);
      const cvKeys = [];
      if (data.length > 0) {
        for (let h in data[0]) {
          if (h.startsWith('cv') || h.startsWith('hv')) {
            if (cvKeys.indexOf(h) == -1) cvKeys.push(h);
          }
        }
      }
      this.setState({ data, cvKeys, loading: false });
    }
  }
  handleOpenDialogRejection = (reasons, checked, row, candidate) => {
    const dialog = this.state.dialog;
    dialog.reasonRejection.show = true;
    dialog.reasonRejection.reasons = reasons;
    dialog.reasonRejection.userReasons = checked;
    dialog.reasonRejection.checked = checked.map((r) => r.reason);
    dialog.reasonRejection.row = row;
    dialog.reasonRejection.candidate = candidate;

    this.setState({ dialog });
  };

  handleCloseDialogRejection = () => {
    const dialog = this.state.dialog;
    dialog.reasonRejection.show = false;
    this.setState({ dialog });
  };

  handleViewCV = (candidate) => {
    if (this.props.onViewCV) {
      this.props.onViewCV(candidate);
    }
  };

  handleRequestSpecialNeeds = (listingId, locations, headers) => {
    if (this.props.onRequestSpecialNeeds) {
      this.props.onRequestSpecialNeeds(listingId, locations, headers);
    }
  };

  handleViewLog = (candidate) => {
    if (this.props.onViewLog) {
      this.props.onViewLog(candidate);
    }
  };

  handleStoreReassign = (values) => {
    const { onReasonsChange, onStoreReassign } = this.props;
    const {
      candidateId,
      rowIndex,
      userReasons,
    } = this.state.storeAssignmentModal;

    const newReasons = userReasons
      .filter(({ reason }) => !isANotAssignedWorkingPlaceReason(reason))
      .map(({ reason, reason_id }) => ({ reason, id: reason_id }));

    // This block removes "store unnassigned" via /reasons
    onReasonsChange &&
      onReasonsChange({
        oldReasons: userReasons,
        newReasons,
        tableIndex: rowIndex,
      });

    const value = values && values.join(',');

    // This block updates the new stores assigned to candidate via /answer
    onStoreReassign({
      candidateId,
      rowIndex,
      value,
    });
  };

  handleAcceptDialogRejection = (newReasons) => {
    const dialog = this.state.dialog;

    if (this.props.onReasonsChange) {
      this.props.onReasonsChange({
        oldReasons: dialog.reasonRejection.userReasons,
        newReasons,
        tableIndex: dialog.reasonRejection.row,
      });
    }

    if (
      this.props.selectOnUpdateReason &&
      !this.isSelected(dialog.reasonRejection.candidate._id)
    ) {
      this.toggleSelection(
        dialog.reasonRejection.candidate._id,
        dialog.reasonRejection.candidate,
      );
    }

    dialog.reasonRejection.show = false;
    this.setState({ dialog });
  };

  cellHasError = (candidateId, key) => {
    const { errors } = this.state;
    return (
      errors.requiredFields[candidateId] &&
      errors.requiredFields[candidateId].includes(key)
    );
  };

  renderEditable = (cellInfo, column) => {
    const data = [...this.state.data];
    const candidateId = cellInfo.original.candidate_id;
    const questionIndex = column.id;
    const value = this.state.data[cellInfo.index][column.id];
    const hasError = this.cellHasError(candidateId, questionIndex);
    const header = this.state.headers.find(
      (header) => header.id === column.id,
    );

    if (['datetime', 'date', 'time'].includes(header.field_type)) {
      const fieldType = header.field_type === 'datetime' ? "datetime-local" : header.field_type
      const extraProps = {};
      if (header.metadata) {
        const { minValue, maxValue } = header.metadata;
        if (minValue || maxValue) {
          extraProps.inputProps = {};
          if(fieldType === 'datetime-local') {
            if (minValue) {
              extraProps.inputProps.min = dateUtils.extractDateTimeOrNull(minValue);
            }
            if (maxValue) {
              extraProps.inputProps.max = dateUtils.extractDateTimeOrNull(maxValue);
            }
          } else {
            if (minValue) {
              extraProps.inputProps.min = dateUtils.extractDateOrNull(minValue);
            }
            if (maxValue) {
              extraProps.inputProps.max = dateUtils.extractDateOrNull(maxValue);
            }
          }
        }
      }  
      return (
        <TextField
          id={fieldType}
          //label="Next appointment"
          type={fieldType}
          defaultValue={value}
          style={{ width: '100%', fontFamily: 'Helvetica Neue' }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            const newValue = e.target.value;
            data[cellInfo.index][column.id] = newValue;
            this.setState({ data }, (e) => {
              this.checkRowErrors(cellInfo.original);
            });
          }}
          onBlur={(e) => {
            const newValue = e.target.value;
            data[cellInfo.index][column.id] = newValue;
            if (this.props.onInputChange) {
              this.props.onInputChange(
                newValue,
                candidateId,
                cellInfo.index,
                questionIndex,
              );
            }
            this.setState({ data }, (e) => {
              this.checkRowErrors(cellInfo.original);
            });
          }}
          {...extraProps}
        />
      );
    } else {
      return (
        <EditableTextarea
          value={this.state.data[cellInfo.index][column.id]}
          onBlur={(newValue) => {
            data[cellInfo.index][column.id] = newValue;
            if (this.props.onInputChange) {
              this.props.onInputChange(
                newValue,
                candidateId,
                cellInfo.index,
                questionIndex,
              );
            }
            this.setState({ data }, (e) => {
              this.checkRowErrors(cellInfo.original);
            });
          }}
        />
      );
    }
  };

  renderCv = (cellInfo) => {
    const cvKeys = this.state.cvKeys;
    const candidateData = cellInfo.original;
    let cvs = cvKeys.map((cv) => {
      if (candidateData[cv]) {
        return (
          <a href={candidateData[cv]} key={cv} target="_blank">
            {cv.split('_').join(' ').toUpperCase()}
          </a>
        );
      }
    });
    return <div>{cvs}</div>;
  };

  onFilterChanged = (key, value, metadata) => {
    const filters = Object.assign({}, this.props.filters);
    filters[key] = value;
    this.setState({ filters });
    console.log(filters);
    if (this.props.onFilterChange) {
      console.log('firing onFilterChange');
      track({ type: 'LISTING_VIEW_FILTER', filterType: key, search: value });
      this.props.onFilterChange(key, value, metadata);
      this.setState({ loading: true });
    }
    this.setState({
      selected: [],
      selectAll: false,
    });
  };

  onDropdownChange = (props, key, value) => {

    const candidateId = props.original.candidate_id;
    const index = props.index;
    if (value == -1) {
      value = null;
    }
    this.props.onInputChange(value, candidateId, index, key);

    //set state and check row errors afterwards
    const data = this.state.data;
    data[props.index][key] = value;
    this.setState({ data }, (e) => {
      this.checkRowErrors(props.original);
    });

    const header = this.state.headers.filter((h) => h.id === key)[0];
    const previousSelection = props.values[key]
    if (header.discard_array && header.reason_exclusion) {
      console.log('header with discard array');
      if (header.discard_array.includes(value)) {
        console.log('add reason');
        if (value !== previousSelection) {
          if (previousSelection) {
            this.props.onReasonDeleted((header.reason_exclusion === 'ref/self' ? previousSelection : header.reason_exclusion), props.index);
          }
          this.props.onReasonAdded((header.reason_exclusion === 'ref/self' ? value : header.reason_exclusion), props.index);
        }
      } else {
        console.log('delete reason');
        this.props.onReasonDeleted((header.reason_exclusion === 'ref/self' ? previousSelection : header.reason_exclusion), props.index);
      }
    } else {
      console.log('header with no discard array');
    }
  };

  executeCustomButton = async (button, candidate) => {
    const { candidate_id, channel_user_id, listing_id, step_id } = candidate
    const actionType = button.action ? button.action.type : ''

    this.setState({ ...this.state, loadingCustomButton: { ...this.state.loadingCustomButton, [candidate_id]: true } })

    switch (actionType) {
      case 'CHANGE_STATE':
        await changeCandidateState({ candidateId: candidate_id, listingId: listing_id, stepId: step_id, state: button.action.params.state })
        break;
      default:
        await customButtonRequest(button.path, candidate_id, listing_id, channel_user_id)
        break;
    }

    this.setState({ ...this.state, loadingCustomButton: { ...this.state.loadingCustomButton, [candidate_id]: false } })
    this.props.onPageChange(0)
  }

  handleCustomButtonClick = async (button, candidate) => {
    if (!button.requireConfirmation) {
      await this.executeCustomButton(button, candidate)
    } else {
      this.setState({
        customButtomConfirmationModal: {
          show: true,
          button,
          candidate
        }
      })
    }
  }

  customButtonEnabled = (button, candidate) => {
    const hasErrors = () => {
      if (!button.disableOnErrors) {
        return false
      }

      const errors = this.getFieldsWithErrorsByCandidate(candidate)
      if (!errors || !Array.isArray(errors) || errors.length === 0) {
        return false
      }

      const requiredErrors = errors.filter(x => x.error_reason === 'required')

      return requiredErrors.length > 0
    }
    const enabledForStepState = () => {
      const enabledStates = button.states || []
      if (enabledStates.length === 0) {
        // enabled by default
        return true
      }

      return enabledStates.includes(candidate.step_state)
    }

    return enabledForStepState() && !hasErrors()
  }

  buildColumns = (h) => {
    var columns = [];
    const headers = h || this.state.headers;

    const classes = this.props.classes;
    const rejected = this.props.data.rejected;
    const intl = this.props.intl
    const { formatMessage } = intl;
    const { showManualActions, onShouldShowActionForCandidate } = this.props

    const onFilterChanged = this.onFilterChanged;
    if (rejected || true) {
      let h = headers.filter((h) => h.id == 'reason');
      if (h && !h.length)
        headers.splice(2, 0, {
          id: 'reason',
          label: formatMessage(messages.table.header.reason),
          show: true,
        });
    }

    if (!!this.props.showActions && !headers.filter((h) => h.id == 'candidate_actions').length) {
      headers.splice(0, 0, {
        id: 'candidate_actions',
        show: true,
      });
    }

    if (!!!this.props.showActions && !headers.filter((h) => h.id == 'action_resume').length)
      headers.splice(2, 0, {
        id: 'action_resume',
        label: formatMessage(messages.table.header.action),
        show: true,
      });

    // TODO: remove this harcoded listings.
    if (!!!this.props.showActions && !headers.filter((h) => h.id == 'action_log').length) {
      const { step, listing } = this.props
      // Show logs column only for step type HIRED of some listings - CS-920
      if (step && listing && step.type === 'HIRED' && [1286,1287,1614].includes(listing.id)) {
        console.log('ACTION LOG', step, listing)
        headers.splice(2, 0, {
          id: 'action_log',
          label: formatMessage(messages.table.header.action),
          show: true,
        });
      }
    }

    if (!headers.filter((h) => h.id == 'action_assignLocation').length)
      headers.splice(2, 0, {
        id: 'action_assignLocation',
        label: formatMessage(messages.table.header.action),
        show: true,
      });

    for (let i in headers) {
      if (headers[i].show && !(headers[i].show_only_to_emi_employees && this.props.user && ![1,56].includes(this.props.user.subsidiaryId))) {
        if (headers[i].id.startsWith('cv') || headers[i].id.startsWith('hv')) {
          columns.push({
            Header: headers[i].label,
            disableSortBy: true,
            style: { textAlign: 'center' },
            accessor: headers[i].id, // String-based value accessors!
            Cell: ({ row }) => this.renderCv(row),
            Filter: ({ filter, onChange }) => {
              return <span />;
            },
            filterMethod: (filter, row) => {
              const rowValue = row[filter.id] ? row[filter.id] : '';
              const filterValue = filter.value ? filter.value : '';
              return (
                rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
              );
            },
          });
        } else if (headers[i].id == 'reason') {
          columns.push({
            Header: headers[i].label,
            accessor: headers[i].id, // String-based value accessors!
            width: 260,
            disableSortBy: true,
            Filter: ({ filter, onChange }) => {
              if (headers[i].filterable) {
                return (
                  <Filter
                    value={
                      (this.props.filters &&
                        this.props.filters[headers[i].id]) ||
                      ''
                    }
                    onChange={(value) => {
                      onFilterChanged(headers[i].id, value);
                    }}
                  />
                );
              } else {
                return <span />;
              }
            },
            filterMethod: (filter, row) => {
              const rowValue = row[filter.id] ? row[filter.id] : '';
              const filterValue = filter.value ? filter.value : '';
              return (
                rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
              );
            },
            Cell: ({ value, column }) => {
              return (
                <span className={column.width > 250 ? classes.showFullText : ''}>
                  {value ? value.map((r) => r.reason.reason || r.reason).join(', ') : ''}
                </span>
              )
            },
          });
        } else if (headers[i].id == 'name_logic') {
          columns.push({
            Header: headers[i].label,
            accessor: headers[i].id, // String-based value accessors!
            minWidth: 250,
            Filter: ({ filter, onChange }) => {
              if (headers[i].filterable) {
                return (
                  <Filter
                    value={
                      (this.props.filters &&
                        this.props.filters[headers[i].id]) ||
                      ''
                    }
                    onChange={(value) => {
                      onFilterChanged(headers[i].id, value);
                    }}
                  />
                );
              } else {
                return <span />;
              }
            },
            filterMethod: (filter, row) => {
              const rowValue = row[filter.id] ? row[filter.id] : '';
              const filterValue = filter.value ? filter.value : '';
              return (
                rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
              );
            },
            Cell: ({ row, value }) => {
              const { original } = row
              return <Grid container>
                <Grid item xs={12}>{value}</Grid>
                {
                  showManualActions && onShouldShowActionForCandidate(original) && (
                    <Grid item xs={12}>
                      <CandidateFeedback
                        candidate={original}
                        errors={this.getFieldsWithErrorsByCandidate(original)}
                        reasons={this.props.reasons}
                        onApproved={(candidate) => this.approveCandidate(candidate, row)}
                        onRejected={(candidate, reasons) => this.rejectCandidate(candidate, reasons, row)}
                        isRejected={rejected}
                        extraButtons={this.props.extraActions}
                        stepViewConfiguration={this.state.headers}
                      />
                    </Grid>
                  )
                }
              </Grid>
            },
          });
        } else if (headers[i].id == 'candidate_actions') {
          columns.push({
            Header: '',
            accessor: headers[i].id, // String-based value accessors!
            disableSortBy: true,
            width: 50,
            Filter: ({ filter, onChange }) => {
              return <span />;
            },
            Cell: ({ row }) => {
              const { original: { candidate_id, listing_id, step_id, step_state, name_logic, channel_user_id, assigned_vacancy_id } } = row
              return <Actions
                candidateId={candidate_id}
                listingId={listing_id}
                stepId={step_id}
                step={this.props.step}
                state={step_state}
                name={name_logic}
                channelUserId={channel_user_id}
                subsidiaryId={this.props.listing.subsidiaryId}
                assignedVacancyId={assigned_vacancy_id}
              />
            },
          });
        } else if (headers[i].id == 'action_resume') {
          if (this.props.showActionButton) {
            columns.push({
              Header: '',
              accessor: headers[i].id, // String-based value accessors!
              disableSortBy: true,
              width: 100,
              Filter: ({ filter, onChange }) => {
                return <span />;
              },
              Cell: ({ row }) => {
                if (!this.props.onViewCV || [1021].includes(this.props.listing.id)) {
                  return null
                }
                return <div style={{ textAlign: 'center' }}>
                  <PrimaryButton
                    type="outlined"
                    onClick={() => this.handleViewCV(row.original)}
                    style={{ fontSize: '8px' }}
                  >
                    {formatMessage(messages.descartes.button.data)}
                  </PrimaryButton>
                </div>
              },
            });
          }
        } else if (headers[i].id == 'action_log') {
          if (this.props.showActionButton) {
            columns.push({
              Header: '',
              accessor: headers[i].id, // String-based value accessors!
              disableSortBy: true,
              width: 100,
              Filter: ({ filter, onChange }) => {
                return <span />;
              },
              Cell: ({ row }) => {
                return <div style={{ textAlign: 'center' }}>
                  <PrimaryButton
                    type="outlined"
                    onClick={() => this.handleViewLog(row.original)}
                    style={{ fontSize: '8px' }}
                  >
                    {formatMessage(messages.descartes.button.log)}
                  </PrimaryButton>
                </div>
              },
            });
          }
        } else if (headers[i].id == 'custom_button') {
          columns.push({
            accessor: headers[i].id, // String-based value accessors!
            disableSortBy: true,
            width: 100,
            Filter: ({ filter, onChange }) => {
              return <span />;
            },
            Cell: ({ row }) => {
              return <div style={{ textAlign: 'center' }}>
                {this.state.loadingCustomButton[row.original.candidate_id] ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : <PrimaryButton
                  type='outlined'
                  onClick={() => this.handleCustomButtonClick(headers[i].metadata, row.original)}
                  style={{ fontSize: '8px' }}
                  disabled={!this.customButtonEnabled(headers[i].metadata, row.original)}
                >
                  {headers[i].label}
                </PrimaryButton>
                }
              </div>
            },
          });
        } else if (headers[i].field_type === 'interview_status') {
          columns.push({
            Header: headers[i].label,
            accessor: headers[i].accessor, // String-based value accessors!
            Cell: ({ row }) => {
              const { original } = row
              return (
              <span className={row.width > 250 ? classes.showFullText : ''}>
                {resolveInterviewState(original["_state_interview"], intl, { subsidiaryId: this.props.listing.subsidiaryId, listingId: this.props.listing.id })}
                {' '}
              </span>
            )},
          });
        } else if (headers[i].id == 'action_assignLocation') {
          if (this.props.showActionButton && this.props.showRejectedButton) {
            columns.push({
              Header: '',
              accessor: headers[i].id, // String-based value accessors!
              disableSortBy: true,
              width: 100,
              Filter: ({ filter, onChange }) => {
                return <span />;
              },
              Cell: ({ row }) => {
                const candidate = row.original;
                const { reason } = candidate;
                const shouldReassignStore =
                  Array.isArray(reason) &&
                  reason.find(({ reason: rejectReason }) => {
                    return !rejectReason
                      ? false
                      : typeof rejectReason === 'string'
                        ? isANotAssignedWorkingPlaceReason(rejectReason)
                        : typeof rejectReason === 'object' && !Array.isArray(rejectReason) 
                          ? isANotAssignedWorkingPlaceReason(rejectReason.reason)
                          : false
                  })
                // const shouldReassignStore = true;
                if (!shouldReassignStore) {
                  return null
                }
                return (
                  <div style={{ textAlign: 'center' }} >
                    <PrimaryButton
                      onClick={() =>
                        this.setState({
                          storeAssignmentModal: {
                            candidateId: candidate.candidate_id,
                            reasons: this.props.reasons,
                            userReasons: candidate.reason || [],
                            rowIndex: row.index,
                            show: true,
                          },
                        })
                      }
                      style={{ fontSize: '8px' }}
                      type='outlined'
                    >
                      {formatMessage(messages.descartes.button.assignLocation)}
                    </PrimaryButton>
                  </div>
                );
              },
            });
          }
        } else if (headers[i].field_type === 'locations') {
          // Metadata structure
          // {
          //   ...,
          //   field_type: 'locations',
          //   metadata: {
          //     default: {
          //       value: -1,
          //       label: 'Default text'
          //     },
          //     name_format: '{name} [{tag.state}]'
          //   },
          //   ...
          // }
          // - default: OPTIONAL. If exists, an extra row is added at the beginning of the dropdown
          // - default.value: OPTIONAL. If is not defined, `-1` will be used
          // - default.label: MANDATORY.
          const nameFormat = headers[i].metadata && headers[i].metadata.name_format;
          const locations = this.props.getLocations ? this.props.getLocations(this.props.listing.id, { nameFormat }) : []
          if (headers[i].editable) {
            columns.push({
              Header: headers[i].label,
              style: { textAlign: 'center', position: 'relative' },
              minWidth: 120,
              accessor: headers[i].id, // String-based value accessors!
              Filter: ({ filter, onChange }) => {
                if (headers[i].filterable) {
                  return (
                    <Filter
                      value={
                        (this.props.filters &&
                          this.props.filters[headers[i].id]) ||
                        ''
                      }
                      onChange={(value) => {
                        onFilterChanged(headers[i].id, value);
                      }}
                    />
                  );
                } else {
                  return <span />;
                }
              },
              Cell: ({ row, value }) => {
                const props = row
                let selectValue = value || -1;
                const hasError = this.cellHasError(
                  props.original.candidate_id,
                  headers[i].id,
                );
                let dropdown = locations
                const disabled = !dropdown.length;
                return (
                  <Select
                    style={{
                      border: hasError ? '3px solid #ff1b1b' : '0',
                      borderRadius: hasError ? '5px' : '0',
                      backgroundColor: hasError
                        ? '#f5aeae'
                        : disabled
                          ? 'ghostwhite'
                          : 'lightgrey',
                      width: '100%',
                      height: '100%',
                      fontFamily: 'Helvetica Neue',
                    }}
                    value={selectValue}
                    disabled={disabled}
                    onChange={(e, v) => {
                      this.onDropdownChange(
                        props,
                        headers[i].id,
                        e.target.value,
                      );
                    }}
                    className={
                      selectValue != -1
                        ? classes.changedMenuItem
                        : classes.changeMenuItem
                    }
                  >
                    {
                      headers[i].metadata && headers[i].metadata.default && (
                        <MenuItem value={headers[i].metadata.default.value || -1}>
                          {headers[i].metadata.default.label}
                        </MenuItem>
                      )
                    }
                    {
                      dropdown.map((option, index) => {
                        return (
                          <MenuItem value={option.id} key={index}>
                            {option.name}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                );
              },
            });
          } else {
            columns.push({
              Header: headers[i].label,
              style: { textAlign: 'center', position: 'relative' },
              minWidth: 120,
              accessor: headers[i].id, // String-based value accessors!
              Filter: () => {
                if (headers[i].filterable) {
                  return (
                    <Filter
                      value={
                        (this.props.filters &&
                          this.props.filters[headers[i].id]) ||
                        ''
                      }
                      onChange={(value) => {
                        onFilterChanged(headers[i].id, value, value ? { fieldType: 'locations' } : null);
                      }}
                    />
                  );
                } else {
                  return <span />;
                }
              },
              Cell: ({ row, value }) => {
                const props = row
                const location = locations.find(l => l.id === parseToInt(value))
                const name = location ? location.name : ''
                return (
                  <span
                    className={props.width > 250 ? classes.showFullText : ''}
                  >
                    {name}{' '}
                  </span>
                );
              },
            });
          }

        } else if (headers[i].field_type === 'positions') {

          const positions = this.props.getPositions ? this.props.getPositions(this.props.listing.id) : []
          if (headers[i].editable) {
            columns.push({
              Header: headers[i].label,
              style: { textAlign: 'center', position: 'relative' },
              minWidth: 120,
              accessor: headers[i].id, // String-based value accessors!
              Cell: ({ row, value }) => {
                const props = row
                let selectValue = value || -1;
                const hasError = this.cellHasError(
                  props.original.candidate_id,
                  headers[i].id,
                );
                let dropdown = positions
                const disabled = !dropdown.length;
                return (
                  <Select
                    style={{
                      border: hasError ? '3px solid #ff1b1b' : '0',
                      borderRadius: hasError ? '5px' : '0',
                      backgroundColor: hasError
                        ? '#f5aeae'
                        : disabled
                          ? 'ghostwhite'
                          : 'lightgrey',
                      width: '100%',
                      height: '100%',
                      fontFamily: 'Helvetica Neue',
                    }}
                    value={selectValue}
                    disabled={disabled}
                    onChange={(e, v) => {
                      this.onDropdownChange(
                        props,
                        headers[i].id,
                        e.target.value,
                      );
                    }}
                    className={
                      selectValue != -1
                        ? classes.changedMenuItem
                        : classes.changeMenuItem
                    }
                  >
                    {
                      dropdown.map((option, index) => {
                        return (
                          <MenuItem value={option.id} key={index}>
                            {option.name}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                );
              },
            });
          } else {
            columns.push({
              Header: headers[i].label,
              style: { textAlign: 'center', position: 'relative' },
              minWidth: 120,
              accessor: headers[i].id, // String-based value accessors!        
              Filter: () => {
                if (headers[i].filterable) {
                  return (
                    <Filter
                      value={
                        (this.props.filters &&
                          this.props.filters[headers[i].id]) ||
                        ''
                      }
                      onChange={(value) => {
                        onFilterChanged(headers[i].id, value, value ? { fieldType: 'positions' } : null);
                      }}
                    />
                  );
                } else {
                  return <span />;
                }
              },
              Cell: ({ row, value }) => {
                const props = row
                const position = positions.find(l => l.id == value)
                console.log('POSITIONS 1', value, positions, position)
                const name = position ? position.name : ''
                return (
                  <span
                    className={props.width > 250 ? classes.showFullText : ''}
                  >
                    {name}{' '}
                  </span>
                );
              },
            });
          }

        } else if (headers[i].field_type === 'vacancies') {
          const vacancies = this.props.getVacancies ? this.props.getVacancies(this.props.listing.subsidiaryId, this.props.listing.id) : []
          if (headers[i].editable) {
            columns.push({
              Header: headers[i].label,
              style: { textAlign: 'center', position: 'relative' },
              minWidth: 120,
              accessor: headers[i].id, // String-based value accessors!
              Cell: ({ row, value }) => {
                const props = row
                let selectValue = value || -1;
                const hasError = this.cellHasError(
                  props.original.candidate_id,
                  headers[i].id,
                );
                let dropdown = vacancies
                const disabled = !dropdown.length;
                return (
                  <Select
                    style={{
                      border: hasError ? '3px solid #ff1b1b' : '0',
                      borderRadius: hasError ? '5px' : '0',
                      backgroundColor: hasError
                        ? '#f5aeae'
                        : disabled
                          ? 'ghostwhite'
                          : 'lightgrey',
                      width: '100%',
                      height: '100%',
                      fontFamily: 'Helvetica Neue',
                    }}
                    value={selectValue}
                    disabled={disabled}
                    onChange={(e, v) => {
                      this.onDropdownChange(
                        props,
                        headers[i].id,
                        e.target.value,
                      );
                    }}
                    className={
                      selectValue != -1
                        ? classes.changedMenuItem
                        : classes.changeMenuItem
                    }
                  >
                    {
                      dropdown.map((option, index) => {
                        return (
                          <MenuItem value={option.id} key={index}>
                            {`${option.id}-${option.positionName}-${option.refDeterminant}`}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                );
              },
            });
          } else {
            columns.push({
              Header: headers[i].label,
              style: { textAlign: 'center', position: 'relative' },
              minWidth: 120,
              accessor: headers[i].id, // String-based value accessors!
              Filter: () => <span />,
              Cell: ({ row, value }) => {
                const props = row
                const vacancy = vacancies.find(l => l.id == value)
                const name = vacancy ? `${vacancy.id}-${vacancy.positionName}-${vacancy.refDeterminant}` : ''
                return (
                  <span
                    className={props.width > 250 ? classes.showFullText : ''}
                  >
                    {name}{' '}
                  </span>
                );
              },
            });
          }

        } else if (headers[i].field_type === 'time_interval') {
          // Metadata structure
          // {
          //   ...,
          //   field_type: 'time_interval',
          //   metadata: {
          //     unit: 'days',
          //     from: null, [NOT IMPLEMENTED]
          //   },
          //   ...
          // }
          // - unit: OPTIONAL. Default: days. Options: years, months, weeks, days, hours, minutes and seconds
          // - from: OPTIONAL. Default: now. Options: fixed value in iso8601 | ref to another value (eg ref/application_date)
          const defaultMetadata = {
            unit: 'days'
          }
          columns.push({
            Header: headers[i].label,
            style: { textAlign: 'center', position: 'relative' },
            minWidth: 120,
            accessor: headers[i].id, // String-based value accessors!
            Filter: () => <span />,
            Cell: ({ row, value }) => {
              const metadata = headers[i].metadata || defaultMetadata
              const valueMoment = moment(value)
              let diff = null
              if (valueMoment.isValid()){
                diff = Math.abs(valueMoment.diff(moment(), metadata.unit))
              }
              return (
                <span
                  className={row.width > 250 ? classes.showFullText : ''}
                >
                  {diff}{' '}
                </span>
              );
            },
          });
        } else if (headers[i].field_type === 'special_needs') {
          columns.push({
            id: `${new Date().getTime()}`,
            Header: headers[i].label,
            style: { textAlign: 'center', position: 'relative' },
            minWidth: 120,
            accessor: headers[i].id, // String-based value accessors!
            Filter: () => <span />,
            Cell: ({ row, value }) => {
              return (
                <Grid container>
                  <Grid item xs>
                    <IconButton size='small' onClick={() => this.handleRequestSpecialNeeds(this.props.listing.id, value, headers)}>
                      <ZoomInIcon />
                    </IconButton>
                  </Grid>
                </Grid>

              );
            },
          });
        } else if (headers[i].field_type === 'copy_clipboard') {
          columns.push({
            Header: headers[i].label,
            style: { textAlign: 'center', position: 'relative' },
            minWidth: 10,
            accessor: headers[i].id, // String-based value accessors!
            Filter: () => {
              if (headers[i].filterable) {
                return (
                  <Filter
                    value={
                      (this.props.filters &&
                        this.props.filters[headers[i].id]) ||
                      ''
                    }
                    onChange={(value) => {
                      onFilterChanged(headers[i].id, value);
                    }}
                  />
                );
              } else {
                return <span />;
              }
            },
            Cell: ({ row, value }) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{value}</Box>
                <CopyToClipboard content={value} />
              </Box>
            ),
          });
        } else if (headers[i].field_type === 'long_text') {
          columns.push({
            Header: headers[i].label,
            style: { textAlign: 'center', position: 'relative' },
            minWidth: 120,
            width: 360,
            accessor: headers[i].id, // String-based value accessors!
            Filter: () => <span />,
            Cell: ({ column, value }) => {
              return (
                <span
                  className={column.width > 250 ? classes.showFullText : ''}
                >
                  {getValue(value, headers[i])}{' '}
                </span>
              );
            },
          });
        } else if (
          headers[i].editable !== false &&
          (headers[i].id.startsWith('_') ||
            this.props.canEdit ||
            headers[i].editable)
        ) {
          if (headers[i].dropdown) {
            columns.push({
              Header: headers[i].label,
              style: { textAlign: 'center', position: 'relative' },
              minWidth: 120,
              accessor: headers[i].id, // String-based value accessors!
              Filter: ({ filter, onChange }) => {
                if (headers[i].filterable) {
                  return (
                    <Filter
                      value={
                        (this.props.filters &&
                          this.props.filters[headers[i].id]) ||
                        ''
                      }
                      onChange={(value) => {
                        onFilterChanged(headers[i].id, value);
                      }}
                    />
                  );
                } else {
                  return <span />;
                }
              },
              Cell: ({ row, value }) => {
                const props = row
                let selectValue = value || -1;
                const hasError = this.cellHasError(
                  props.original.candidate_id,
                  headers[i].id,
                );
                let dropdown = headers[i].dropdown;
                if (
                  typeof dropdown === typeof '' &&
                  dropdown.startsWith('ref/')
                ) {
                  const indexData = dropdown.replace('ref/', '').split('.');
                  if (indexData[0] === 'listing_candidate') {
                    dropdown = JSON.parse(props.original[indexData[1]] || '[]');
                  }
                }
                const haveEmptyItem = dropdown.filter(
                  (o) => (!o.value || o.value === -1) && typeof o !== typeof '',
                );
                const disabled = !dropdown.length;
                return (
                  <Select
                    style={{
                      border: hasError ? '3px solid #ff1b1b' : '0',
                      borderRadius: hasError ? '5px' : '0',
                      backgroundColor: hasError
                        ? '#f5aeae'
                        : disabled
                          ? 'ghostwhite'
                          : 'lightgrey',
                      width: '100%',
                      height: '100%',
                      fontFamily: 'Helvetica Neue',
                    }}
                    value={selectValue}
                    disabled={disabled}
                    onChange={(e, v) => {
                      this.onDropdownChange(
                        props,
                        headers[i].id,
                        e.target.value,
                      );
                    }}
                    className={
                      selectValue != -1
                        ? classes.changedMenuItem
                        : classes.changeMenuItem
                    }
                  >
                    {!haveEmptyItem.length ? (
                      <MenuItem value={-1}>
                        <em />
                      </MenuItem>
                    ) : null}

                    {dropdown.map((option, index) => {
                      if (typeof option === typeof '') {
                        return (
                          <MenuItem value={option} key={index}>
                            {option}
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem value={option.value || -1} key={index}>
                            {option.label}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                );
              },
            });
          } else {
            columns.push({
              Header: headers[i].label,
              accessor: headers[i].id,
              style: { backgroundColor: 'lightgray', padding: '0' },
              Cell: ({ row, column }) => this.renderEditable(row, column),
              minWidth: 200,
              Filter: ({ filter, onChange }) => {
                if (headers[i].filterable) {
                  return (
                    <Filter
                      value={
                        (this.props.filters &&
                          this.props.filters[headers[i].id]) ||
                        ''
                      }
                      onChange={(value) => {
                        onFilterChanged(headers[i].id, value);
                      }}
                    />
                  );
                } else {
                  return <span />;
                }
              },
              filterMethod: (filter, row) => {
                const rowValue = row[filter.id] ? row[filter.id] : '';
                const filterValue = filter.value ? filter.value : '';
                return (
                  rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
                );
              },
            });
          }
        } else {
          columns.push({
            Header: headers[i].label,
            accessor: headers[i].id, // String-based value accessors!
            minWidth: 150,
            fieldType: headers[i].field_type,
            Cell: ({ row, column, value }) => {
              if (column.fieldType) {
                switch (column.fieldType) {
                  case 'datetime':
                    return (
                      <span
                        className={column.width > 250 ? classes.showFullText : ''}
                      >
                        {formatTimestamp(value, formatMessage(messages.format.datetime), (format) => new Date(value).getTime() ? moment(new Date(value)).format(format) : ' ')}
                      </span>
                    )
                  case 'date':
                    return (
                      <span
                        className={column.width > 250 ? classes.showFullText : ''}
                      >
                        {formatDate(value, formatMessage(messages.format.date))}
                      </span>
                    )
                  case 'time':
                    return (
                      <span
                        className={column.width > 250 ? classes.showFullText : ''}
                      >
                        {formatTimestamp(value, formatMessage(messages.format.time), (format) => moment.utc(value).format(format))}
                      </span>
                    )
                }
              }
              const props = row
              if (
                value &&
                typeof value === 'string' &&
                (value.toLowerCase().endsWith('.pdf') ||
                  value.toLowerCase().endsWith('.jpeg') ||
                  value.toLowerCase().endsWith('.jpg') ||
                  value.toLowerCase().endsWith('.png'))
              ) {
                // return <img src={value} style={{maxWidth: '100px'}} />
                return (
                  <div style={{ textAlign: 'center' }}>
                    <a
                      href={`${api_server}/candidate-files?question_index=${headers[i].id
                        }&candidate_id=${props.original.candidate_id
                        }&listing_id=${this.props.listing.id}&token=${localStorageManager.getData('user').token
                        }`}
                      target="_blank"
                    >
                      {headers[i].label.toUpperCase()}
                    </a>
                  </div>
                );
              } else if (
                value &&
                typeof value === 'string' &&
                value.toLowerCase().endsWith('.mp4') &&
                value.indexOf('video') == -1
              ) {
                // treat audio as video tags. the porblem is that is impossible to know if it's a video or audio from link
                return (
                  <video
                    controls
                    style={{ maxWidth: '100%' }}
                    onClick={(e) => e.stopPropagation()}
                    preload="none"
                  >
                    <source
                      src={`${api_server}/candidate-files?question_index=${headers[i].id
                        }&candidate_id=${props.original.candidate_id
                        }&listing_id=${this.props.listing.id}&token=${localStorageManager.getData('user').token
                        }`}
                      type="video/mp4"
                    />
                    Your browser does not support the video element.
                    <br />
                  </video>
                );
              } else if (
                value &&
                typeof value === 'string' &&
                value.toLowerCase().endsWith('.mp4')
              ) {
                return (
                  <video
                    controls
                    style={{ maxWidth: '100%' }}
                    onClick={(e) => e.stopPropagation()}
                    preload="none"
                  >
                    <source
                      src={`${api_server}/candidate-files?question_index=${headers[i].id
                        }&candidate_id=${props.original.candidate_id
                        }&listing_id=${this.props.listing.id}&token=${localStorageManager.getData('user').token
                        }`}
                      type="video/mp4"
                    />
                    Your browser does not support the video element.
                    <br />
                  </video>
                );
              } else if (headers[i].id == 'applied_at') {
                return (
                  <span
                    className={props.width > 250 ? classes.showFullText : ''}
                  >
                    {formatTimestamp(value, formatMessage(messages.format.datetime), (format) => new Date(Date.parse(value + ' utc')).toString())}{' '}
                  </span>
                );
              } else {
                return (
                  <span
                    className={props.width > 250 ? classes.showFullText : ''}
                  >
                    {getValue(value, headers[i])}{' '}
                  </span>
                );
              }
            },
            Filter: ({ filter, onChange }) => {
              if (headers[i].filterable) {
                return (
                  <Filter
                    value={
                      (this.props.filters &&
                        this.props.filters[headers[i].id]) ||
                      ''
                    }
                    onChange={(value) => {
                      onFilterChanged(headers[i].id, value);
                    }}
                  />
                );
              } else {
                return <span />;
              }
            },
            filterMethod: (filter, row) => {
              const rowValue = row[filter.id] ? row[filter.id] : '';
              const filterValue = filter.value ? filter.value : '';
              return (
                rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
              );
            },
          });
        }
      }
    }

    let stickyLeft = ['candidate_actions', 'name_logic', 'action_resume', 'action_log', 'action_assignLocation'];
    let stickyRight = [];

    for (var c of columns) {
      if (stickyLeft.includes(c['accessor'])) {
        c['sticky'] = 'left';
      }
      if (stickyRight.includes(c['accessor'])) {
        //c['sticky'] = 'right';
      }
    }

    columns.sort((a, b) => {

      if (a.accessor === 'candidate_actions') {
        return -1
      }

      if (b.accessor === 'candidate_actions') {
        return 1
      }

      if (a.accessor === 'name_logic') {
        return -1
      }

      if (b.accessor === 'name_logic') {
        return 1
      }

      if (a['sticky'] == 'right') {
        return 1;
      }

      if (b['sticky'] == 'right') {
        return -1;
      }

      if (a['sticky'] == 'left') {
        return -1;
      }

      if (b['sticky'] == 'left') {
        return 1;
      }

      return 0;
    });

    return { columns };
  };

  getSelectedLists = () => {
    let { selected, data } = this.state;
    let newRejectedList = [];
    let newApprovedList = [];

    for (var candidate of data) {
      if (selected.includes(candidate._id)) {
        if (candidate.reason && candidate.reason.length) {
          newRejectedList.push(candidate._id);
        } else {
          newApprovedList.push(candidate._id);
        }
      }
    }

    return {
      selected: selected,
      approved: newApprovedList,
      rejected: newRejectedList,
    };
  };

  approveCandidate = ({ reason, _id }, { index }) => {
    this.props.onReasonsChange({
      oldReasons: reason || [],
      newReasons: [],
      tableIndex: index
    });
    this.props.onAdvanceToNextStep([_id], null);
  }

  rejectCandidate = ({ reason, _id }, reasons, { index }) => {
    this.props.onReasonsChange({
      oldReasons: reason || [],
      newReasons: reasons,
      tableIndex: index
    });
    this.props.onAdvanceToNextStep(null, [_id]);
  }

  getFieldsWithErrorsByCandidate = (candidate) => {
    let requiredFields = this.state.headers
      .filter((h) => h.required)
      .map(x => ({ ...x, error_reason: 'required' }))
    const requiredFieldsByRef = this.state.headers
      .filter(h => h.required_ref)
      .map(h => {
        const refParts = h.required_ref.split('=')
        if (refParts[0].startsWith('listing_candidates.')) {
          const key = refParts[0].split('listing_candidates.')[1]
          const value = refParts[1]
          return { ...h, key, value }
        }
        else {
          throw new Error('Only listing_candidates conditions are supported in required_ref')
        }
      })
      .filter(requiredCondition => candidate[requiredCondition.key] === requiredCondition.value)
      .map(x => ({ ...x, error_reason: 'required' }))
    requiredFields = requiredFields.concat(requiredFieldsByRef)

    const requiredFieldsNotFullfiled = requiredFields
      .filter((h) => !candidate[h.id]);
    
    const fieldsWithValidators = this.state.headers.filter(x => x.validators && Array.isArray(x.validators) && x.validators.length > 0)
    const fieldsWithValidatorsWithError = fieldsWithValidators
    .filter(x => !requiredFieldsNotFullfiled.some(y => y.name === x.name))
    .map(x => {
      if (x.field_type !== 'date' && x.field_type !== 'datetime') {
        return { field: x, hasError: false, validator: null }
      }
      for (let index = 0; index < x.validators.length; index++) {
        const validator = x.validators[index];
        if (typeof validator !== 'string') {
          continue
        }
        const value = candidate[x.id] 
        if (validator === 'range') {
          if (!dateUtils.isValidDate(value)) {
            return { field: x, hasError: true, validator }
          }
          if (!x.metadata || (!x.metadata.minValue && !x.metadata.maxValue)) {
            return { field: x, hasError: true, validator }
          }
          
          const { minValue, maxValue } = x.metadata

          if(x.field_type === 'datetime') {
            if (minValue) {
              const extractedMinValue = dateUtils.extractDateTimeOrNull(minValue)
              if (!!extractedMinValue && dateUtils.parseDate(value).isBefore(dateUtils.parseDate(extractedMinValue))) {
                return { field: x, hasError: true, validator }
              }
            }
            if (maxValue) {
              const extractedMaxValue = dateUtils.extractDateTimeOrNull(maxValue)
              if (!!extractedMaxValue && dateUtils.parseDate(value).isAfter(dateUtils.parseDate(extractedMaxValue))) {
                return { field: x, hasError: true, validator }
              }
            }
          } else {
            if (minValue) {
              const extractedMinValue = dateUtils.extractDateOrNull(minValue)
              if (!!extractedMinValue && dateUtils.parseDate(value).isBefore(dateUtils.parseDate(extractedMinValue))) {
                return { field: x, hasError: true, validator }
              }
            }
            if (maxValue) {
              const extractedMaxValue = dateUtils.extractDateOrNull(maxValue)
              if (!!extractedMaxValue && dateUtils.parseDate(value).isAfter(dateUtils.parseDate(extractedMaxValue))) {
                return { field: x, hasError: true, validator }
              }
            }
          }
        } else if (validator === 'avoidWeekend') {
          if (!dateUtils.isValidDate(value)) {
            return { field: x, hasError: true, validator }
          }
          const momentDate = dateUtils.parseDate(value)
          const dayOfWeek = momentDate.day();
          if (dayOfWeek === 6 || dayOfWeek === 0) {
            return { field: x, hasError: true, validator }
          }
        } else if (validator === 'avoidHoliday') {
          if (!dateUtils.isValidDate(value)) {
            return { field: x, hasError: true, validator }
          }
          const momentDate = dateUtils.parseDate(value)
          if (dateUtils.isHoliday(momentDate)) {
            return { field: x, hasError: true, validator }
          }
        } else if (validator === 'avoidSpecificDay') {
          if (!dateUtils.isValidDate(value)) {
            return { field: x, hasError: true, validator }
          }
          const momentDate = dateUtils.parseDate(value)
          if (dateUtils.isSpecificDay(momentDate)) {
            return { field: x, hasError: true, validator }
          }
        }
      }
      return { field: x, hasError: false, validator: null }
    }).filter(x => x.hasError)
    .map(x => ({ ...x.field, error_reason: x.validator}))

    return requiredFieldsNotFullfiled.concat(fieldsWithValidatorsWithError)
  }

  checkRowErrors = (candidate) => {
    //If row is not selected, remove errors for this candidate row

    const { errors } = this.state;
    if (!this.state.selected.includes(candidate.candidate_id)) {
      delete errors.requiredFields[candidate.candidate_id];
    } else {
      let requiredFields = this.state.headers
        .filter((h) => h.required)
      const requiredFieldsByRef = this.state.headers
        .filter(h => h.required_ref)
        .map(h => {
          const refParts = h.required_ref.split('=')
          if (refParts[0].startsWith('listing_candidates.')) {
            const key = refParts[0].split('listing_candidates.')[1]
            const value = refParts[1]
            return { ...h, key, value }
          }
          else {
            throw new Error('Only listing_candidates conditions are supported in required_ref')
          }
        })
        .filter(requiredCondition => candidate[requiredCondition.key] === requiredCondition.value)
      requiredFields = requiredFields.concat(requiredFieldsByRef)

      const requiredFieldsWithError = requiredFields
        .filter((h) => !candidate[h.id]);
      if (requiredFieldsWithError.length) {
        errors.requiredFields[candidate.candidate_id] = requiredFieldsWithError.map(
          (f) => f.id,
        );
      } else {
        delete errors.requiredFields[candidate.candidate_id];
      }
    }
    this.setState({ errors });
  };

  toggleSelection = (key, candidate) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state

    let selected = [...this.state.selected];
    selected = addOrRemoveFromArray(selected, key);
    this.setState({ selected }, () => {
      //use callback so that we check errors after state is set
      this.checkRowErrors(candidate);
    });
  };

  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAll = this.state.selectAll ? false : true;
    const selected = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      // const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const first = 0; //wrappedInstance.getResolvedState().page;
      // const last =  wrappedInstance.getResolvedState().defaultPageSize;
      const last = this.props.data.limit || 30;

      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      // const currentRecords = wrappedInstance.getResolvedState().sortedData;
      const currentRecords = this.props.data.tableData;
      // we just push all the IDs onto the selected array
      currentRecords.forEach((item, index) => {
        if (index >= first && index < last) {
          selected.push(item.candidate_id);
        }
      });
    }
    this.setState({ selectAll, selected }, () => {
      this.state.data.forEach((row) => this.checkRowErrors(row));
    });
  };

  isSelected = (key) => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selected.includes(key);
  };

  logSelection = () => {
    console.log('selection:', this.state.selected);
  };

  handleFetchData = (state, instance) => {
    const tableLoaded = this.state.tableLoaded;
    const currentPage = Math.ceil(
      this.props.data.offset / this.props.data.limit,
    );
    let sort;
    if (state.sorted && state.sorted.length) {
      sort = state.sorted[0].id + '_';
      if (state.sorted[0].desc) {
        sort = sort + 'desc';
      } else {
        sort = sort + 'asc';
      }
    }

    console.log('handleFetchData', state, currentPage);
    if (
      this.props.onPageChange &&
      tableLoaded &&
      state.page != currentPage &&
      state.page
    ) {
      console.log('page change', state, instance);
      this.props.onPageChange(state.page);
    } else if (
      this.props.onSortChange &&
      tableLoaded &&
      sort &&
      sort != this.props.data.sort
    ) {
      console.log('sort', state, instance);
      this.props.onSortChange(sort);
    } else {
      this.setState({ tableLoaded: true });
    }
    this.setState({
      selected: [],
      selectAll: false,
    });
  };

  handleChangePage = (pageIndex) => {
    console.log('handleChangePage = (pageIndex)', pageIndex);
    if (this.props.onPageChange) {
      this.props.onPageChange(pageIndex);
    }
    this.setState({
      selected: [],
      selectAll: false,
    });
  };

  handleChangeSort = (sortBy) => {
    console.log('handleChangeSort = (sortBy)', sortBy);

    let sort = ''
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0]
      sort = `${id}_${desc ? 'desc' : 'asc'}`
    }

    if (this.props.onSortChange
      && emptyAsNull(sort) !== emptyAsNull(this.props.data.sort)) {
      this.props.onSortChange(sort);
    }

  };

  changeState = (selected, approved, rejected) => {
    if (this.props.onChangeState) {
      this.props.onChangeState(selected, approved, rejected);
    }

    this.setState({
      selected: [],
      selectAll: false,
    });
  };

  advanceToNextStep = (selected, rejected) => {
    if (this.props.onAdvanceToNextStep) {
      // this.props.onChangeState(approved, rejected)
      this.props.onAdvanceToNextStep(selected, rejected);
    }

    this.setState({
      selected: [],
    });
  };

  getCellProps = (row) => {
    const dismissed = row && row.original.step_state === 'DISMISSED' ? true : false // because r might not exist
    return {
      style: {
        color: dismissed ? "orangered" : "inherit"
      }
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { data, height, errors } = this.state;
    const columns = this.state.columns;
    const rejected = this.props.data.rejected;
    const changeState = this.changeState;
    const advanceToNextStep = this.advanceToNextStep;

    let selectedLists = this.getSelectedLists();
    let tableToolbar = (
      <StepToolbar
        // title={rejected?formatMessage(messages.table.toolbar.rejected):formatMessage(messages.table.toolbar.preSelected)}
        numSelected={selectedLists.selected.length}
        numSelectedApproved={selectedLists.approved.length}
        numSelectedRejected={selectedLists.rejected.length}
        isRequiredFieldsError={Object.keys(errors.requiredFields).length > 0}
        changeState={() => {
          changeState(
            selectedLists.selected,
            selectedLists.approved,
            selectedLists.rejected,
          );
        }}
      />
    );

    if (!rejected && this.props.onAdvanceToNextStep) {
      tableToolbar = (
        <ToolbarApproved
          numSelected={selectedLists.selected.length}
          numSelectedApproved={selectedLists.approved.length}
          numSelectedRejected={selectedLists.rejected.length}
          isRequiredFieldsError={Object.keys(errors.requiredFields).length > 0}
          changeState={() => {
            advanceToNextStep(selectedLists.approved, selectedLists.rejected);
          }}
          messages={messages}
        />
      );
    } else if (rejected && this.props.onAdvanceToNextStep) {
      tableToolbar = (
        <ToolbarRejected
          numSelected={selectedLists.selected.length}
          numSelectedApproved={selectedLists.approved.length}
          numSelectedRejected={selectedLists.rejected.length}
          isRequiredFieldsError={Object.keys(errors.requiredFields).length > 0}
          changeState={() => {
            advanceToNextStep(selectedLists.approved, selectedLists.rejected);
          }}
          messages={messages}
        />
      );
    }
    let sortObj = [];
    if (this.props.data && this.props.data.sort) {
      let sortData = this.props.data.sort;
      sortData = sortData.split('_');
      let sortDirection = sortData[sortData.length - 1];
      let sortKey = sortData.join('_').replace('_' + sortDirection, '');
      sortDirection = sortDirection == 'desc';
      sortObj.push({ id: sortKey, desc: sortDirection });
    }

    return (
      <div>
        <DialogReasonRejection
          onCancel={this.handleCloseDialogRejection}
          show={this.state.dialog.reasonRejection.show}
          reasons={this.state.dialog.reasonRejection.reasons}
          checked={this.state.dialog.reasonRejection.checked}
          onAccept={(newReasons) => {
            this.handleAcceptDialogRejection(newReasons);
          }}
        />
        {tableToolbar}

        <ReactTableAdapter
          data={[...this.state.data]}
          columns={columns}
          page={
            this.props.data && this.props.data.limit
              ? Math.ceil(this.props.data.offset / this.props.data.limit)
              : 0
          }
          pages={
            this.props.data && this.props.data.limit
              ? Math.ceil(this.props.data.total / this.props.data.limit)
              : 0
          }
          defaultPageSize={this.props.data.limit || 30}
          getCellProps={this.getCellProps}
          showPagination={true}
          showPageSizeOptions={false}
          filterable={this.props.filterable || this.state.filterable} //false?true:false
          showPageJump={false}
          sortable={true}
          defaultSorted={sortObj}
          onFetchData={this.handleFetchData}
          onPageChange={this.handleChangePage}
          onSortChange={this.handleChangeSort}
          manual={true} // Forces table not to paginate or sort automatically, so we can handle it server-side
          nextText={formatMessage(messages.descartes.button.next)}
          previousText={formatMessage(messages.descartes.button.back)}
          // noDataText={formatMessage(messages.descartes.noData)}
          loading={this.state.loading || this.props.loading}
          height={height}
        />

        <LocationReassignDialog
          onClose={() =>
            this.setState((prevState) => ({
              storeAssignmentModal: {
                ...prevState.storeAssignmentModal,
                show: false,
              },
            }))
          }
          open={this.state.storeAssignmentModal.show}
          options={this.props.subsidiaryLocations}
          onConfirm={(values) => this.handleStoreReassign(values)}
        />

        <CustomButtonConfirmationDialog
          onClose={() => this.setState({
            customButtomConfirmationModal: {
              show: false,
              candidate: {},
              button: {}
            }
          })}
          onConfirm={(button, candidate) => this.executeCustomButton(button, candidate)}
          {...this.state.customButtomConfirmationModal}
        />

      </div>
    );
  }
}

DescartesTable.propTypes = {
  onSortChange: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  onAmountChange: PropTypes.func.isRequired,
  onReasonsChange: PropTypes.func,
  onReasonAdded: PropTypes.func,
  onReasonDeleted: PropTypes.func,
  onChangeState: PropTypes.func,
  onAdvanceToNextStep: PropTypes.func,
  onViewCV: PropTypes.func,
  onViewLog: PropTypes.func,
  canEdit: PropTypes.bool,
  loading: PropTypes.bool,
  filterable: PropTypes.bool,
  data: PropTypes.object.isRequired,
  filters: PropTypes.object,
  listing: PropTypes.object,
  reasons: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  
  selectOnUpdateReason: PropTypes.bool.isRequired,
  showManualActions: PropTypes.bool,
  showActionButton: PropTypes.bool.isRequired,
  showRejectedButton: PropTypes.bool,
  onCandidateShowActions: PropTypes.bool,
  extraActions: PropTypes.array,
  getLocations: PropTypes.func.isRequired,
  getPositions: PropTypes.func.isRequired,
  getVacancies: PropTypes.func.isRequired,
  onRequestSpecialNeeds: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

DescartesTable.defaultProps = {
  showActions: true,
  step: {},
  showManualActions: true,
  extraActions: []
}

export default injectIntl(withStyles(styles)(DescartesTable));
