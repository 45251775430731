import React, { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ToastContext } from './ToastContext';
import { Toast } from './Toast';

export const ToastProvider = (props) => {
	const [toasts, setToasts] = useState([]);

	/**
	 * 
	 * @param {string} message 
	 * @param {JSX} icon 
	 * @param {string} type 
	 */
	const open = (message, type, icon) => {
		setToasts((currentToasts) => [
			...currentToasts,
			{
				id: uuidv4(),
				message,
				type,
				icon,
			},
		]);
	}

	const close = (id) => {
		setToasts((currentToasts) =>
			currentToasts.filter((toast) => toast.id !== id)
		);
	}

	const contextValue = useMemo(() => ({ open }), []);

	return (
		<ToastContext.Provider value={contextValue}>
			{props.children}

			{toasts.map((toast) => (
				<Toast
					key={toast.id}
					icon={toast.icon}
					type={toast.type}
					message={toast.message}
					close={() => close(toast.id)}
				/>
			))}
		</ToastContext.Provider>
	);
};