import React from 'react';
import styled from 'styled-components';
import Icon from '../icons/Icon';
import PrimaryButton from '../buttons/PrimaryButton';

const StyledContainer = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  background: #ECECEC;
`;

const StyledTitle = styled.div`
  line-height: 22px;
  font-weight: 700;
  font-size: 18px;
  font-family: Lato;
`;

const StyledSubTitle = styled.div`
  line-height: 17px;
  font-weight: 400;
  font-size: 14px;
  font-family: Lato;
  padding: 10px 0px 0px 0px;
`;

const StyledTextContainer = styled.div`
  width: 380px;
  text-align: center;

  padding: 10px 0px;
  gap: 10px;
`;

const StyledCard = styled.div`
  background: white;
  border-radius: 10px;
`;

const StyledCardContent = styled.div`
  width: 400px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 40px 10px;
  gap: 30px;
`;

const StyledCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ErrorPage = ({ title, subtitle, buttonText }) => (
  <StyledContainer>
    <StyledCardContainer>
      <StyledCard>
        <StyledCardContent>
          <Icon name='errorFace' height='91px' width='98px'/>
          <StyledTextContainer>
            <StyledTitle>{title}</StyledTitle>
            <StyledSubTitle>{subtitle}</StyledSubTitle>
          </StyledTextContainer>
          <PrimaryButton onClick={() => window.location.reload(false)} size="large">{buttonText}</PrimaryButton>
        </StyledCardContent>
      </StyledCard>
    </StyledCardContainer>
  </StyledContainer>
);
