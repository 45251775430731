import { createSelector } from 'reselect';

/**
 * The global state selectors
 */

const selectCandidates = () => (state) => state.get('candidates');

const makeSelectCandidates = () => createSelector(
  selectCandidates(),
  (substate) => substate.toJS()
);


export {
  selectCandidates,
  makeSelectCandidates,
};
