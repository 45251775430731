/*
 *
 * PlatformPage constants
 *
 */

export const LOADING = 'app/PlatformPage/LOADING'
export const GET_PLATFORMS = 'app/PlatformPage/GET_PLATFORMS'
export const LOAD_PLATFORMS_SUCCESS = 'app/PlatformPage/LOAD_PLATFORMS_SUCCESS'
export const LOAD_PLATFORMS_ERROR = 'app/PlatformPage/LOAD_PLATFORMS_ERROR'
export const GET_USER_PLATFORMS = 'app/PlatformPage/GET_USER_PLATFORMS'
export const LOAD_USER_PLATFORMS_SUCCESS = 'app/PlatformPage/LOAD_USER_PLATFORMS_SUCCESS'
export const LOAD_USER_PLATFORMS_ERROR = 'app/PlatformPage/LOAD_USER_PLATFORMS_ERROR'
export const RESET_PLATFORM_ADD = 'app/PlatformPage/RESET_PLATFORM_ADD'
export const PLATFORM_ADD = 'app/PlatformPage/PLATFORM_ADD'
export const PLATFORM_ADD_SUCCESS = 'app/PlatformPage/PLATFORM_ADD_SUCCESS'
export const PLATFORM_ADD_ERROR = 'app/PlatformPage/PLATFORM_ADD_ERROR'
export const PLATFORM_REMOVE = 'app/PlatformPage/PLATFORM_REMOVE'
export const PLATFORM_REMOVE_SUCCESS = 'app/PlatformPage/PLATFORM_REMOVE_SUCCESS'
export const PLATFORM_REMOVE_ERROR = 'app/PlatformPage/PLATFORM_REMOVE_ERROR'
export const JOB_LINK = 'app/PlatformPage/JOB_LINK'
export const JOB_LINK_SUCCESS = 'app/PlatformPage/JOB_LINK_SUCCESS'
export const JOB_LINK_ERROR = 'app/PlatformPage/JOB_LINK_ERROR'
export const JOB_READ = 'app/PlatformPage/JOB_READ'
export const JOB_READ_SUCCESS = 'app/PlatformPage/JOB_READ_SUCCESS'
export const JOB_READ_ERROR = 'app/PlatformPage/JOB_READ_ERROR'
export const CLEAR_CREDENTIALS_MESSAGE = 'app/PlatformPage/CLEAR_CREDENTIALS_MESSAGE';
export const CLEAR_MESSAGE = 'app/PlatformPage/CLEAR_MESSAGE';
export const SET_MESSAGE = 'app/PlatformPage/SET_MESSAGE';

export const GET_BOARD_LISTINGS = 'app/PlatformListingPage/GET_BOARD_LISTINGS'
export const GET_BOARD_LISTINGS_SUCCESS = 'app/PlatformListingPage/GET_BOARD_LISTINGS_SUCCESS'
export const GET_BOARD_LISTINGS_ERROR = 'app/PlatformListingPage/GET_BOARD_LISTINGS_ERROR'

export const GET_POSTS = 'app/ApidemoPage/GET_POSTS';
export const LOAD_POSTS_SUCCESS = 'app/ApidemoPage/LOAD_POSTS_SUCCESS';
export const LOAD_POSTS_ERROR = 'app/ApidemoPage/LOAD_POSTS_ERROR';
export const CREATE_POST = 'app/ApidemoPage/CREATE_POST';
export const CREATED_POST = 'app/ApidemoPage/CREATED_POST';
export const UPDATE_POST = 'app/ApidemoPage/UPDATE_POST';
export const UPDATED_POST = 'app/ApidemoPage/UPDATED_POST';
export const DELETE_POST = 'app/ApidemoPage/DELETE_POST';
export const DELETED_POST = 'app/ApidemoPage/DELETED_POST';
