import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { browserHistory, withRouter } from 'react-router';
import moment from 'moment';
import * as Sentry from "@sentry/react";
import { injectIntl } from 'react-intl';

import * as stepViewActions from '../../actions';
import * as appActions from '../../../App/actions';
import * as listingActions from '../../../Listings/actions';
import { makeSelectStepViews } from '../../selectors';
import { makeSelectGlobal } from '../../../../containers/App/selectors';
import { makeSelectListingPage } from '../../../../containers/Listings/selectors';
import NewInterviewDialog from '../../../../components/StepView/Interview/newInterviewDialog';
import messages from '../../../../components/StepView/messages.js';
import { getCurrentUser } from '../../../../utils/connector';
import {  isNil } from 'lodash';
import track from '../../../../utils/track.js';

const noCommunicationHiringManagers = require('../../../../../noCommunicationHiringManagers.json');

const getHiringManagers = (subsidiaryId, hiringManagers) => {
  if (!hiringManagers) {
    return null
  }
  return hiringManagers[subsidiaryId]
}

const getListing = (listingId, listings) => {
  return listings.find(listing => listing.id === parseInt(listingId))
}

class InterviewStepView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listingId: this.props.listingId,
      stepId: null,
      interviewLocation: null,
      interviewLocationOptions: [],
      interviewConditions: null,
      interviewConfig: {},
      interviewTimeError: false,
      interviewConditionsOptions: [],
      interviewHiringManager: [],
      interviewSizeError: false,
      conditionToCheck: { date:{}, conditions: [], size: undefined},
      slotData: {},

      rejected: this.props.rejected,
      listing: this.props.listing,
      tableClass: 'descartes',
      slotAvailability: "-",

      data: {},
      config: [],
      page: 0,
      amount: 50,
      orderBy: 'createdAt',
      order: 'DESC',
      filters: {},
      headers: [],
      visibleData: [],
      hiringManager: null,
      showHiringManagerOptions: false
    };

  }

  componentWillMount() {
    if (!this.props.listingStore.listings || !this.props.listingStore.listings.length || this.props.listingStore.listings.length < 2) {
      this.props.actions.getListings();
    }
    // You may think this second request is redundant, but it's not.
    // The reason is that the steps object inside listings does not include certain data from the api that is present
    // in the steps object from this other request 'getListing'.
    // We need to do this in order to reuse this legacy component in interview view.
    // Reference: https://emilabs.atlassian.net/browse/CU-227
    this.props.actions.getListing(this.props.listingId);
  }

  componentDidMount() {
    const listingId = this.props.listingId
    if (listingId) {
      const listing = getListing(listingId, this.props.listingStore.listings)
      if (listing) {
        this.subsidiaryId = listing.subsidiaryId
        this.props.actions.getHiringManagers(this.subsidiaryId)
        const stepId = `${this.props.stepId}`
        const step = Object.values(listing.steps).find(s => +s.id === +stepId)
        if (step){
          const config = step.config
          if (!!config.requiredHiringManager ||
            (!config.manualInterviewFeedback && config.manualSlotCreation)
          ) {
            this.setState({
              showHiringManagerOptions: true,
              subsidiaryId: this.subsidiaryId
            });
          }
          if (!config.manualSlotCreation){
            this.setState({showDateOptions: false, showTimeOptions: false})
          }
          this.setState({requiredHiringManager: !!config.requiredHiringManager})
        }
      }
    }

    if(!this.state.listingId){
      const listingId = this.props.listingId || this.props.params.listingId;
      const stepId = this.props.stepId || this.props.location.query.stepId;
      const stepViews = newProps.stepStore.stepViews;
      let config = {}
      if (stepViews && stepViews[listingId] && stepViews[listingId][stepId]){
        config = stepViews[listingId][stepId].interviewConfig;
        this.processConfig(config)
        this.setState({
          config: config || {},
        });
      } else {
        this.props.actions.getInterviewConfig(listingId, stepId);
      }

      this.setState({
        listingId,
        stepId
      })
    }

    if (this.props.candidateId){
      const  { formatMessage } = this.props.intl
      this.onSizeChange(formatMessage)(1)
    }
  }

  formatOption = (option)=>{
    return option.charAt(0).toUpperCase() + option.substr(1).toLowerCase();
  }

  processConfig = (config) => {
    if (!config || !config.locations || !config.conditions){
      return
    }
    console.log('????? processConfig config', config)
    const isAdmin = this.props.appStore.user && ((this.props.appStore.user.permissions && this.props.appStore.user.permissions.isExternalAdmin) || this.props.appStore.user.isEmployee);

    const locations = config.locations
    const interviewConfig = config.conditions
    const conditions = interviewConfig.conditions
    const slot = interviewConfig.slot
    let interviewConditionsOptionsIndex = 0;

    // determine the interview location or if the user has to select one
    let interviewLocation = null
    let interviewLocationOptions = null
    if (locations && locations.length > 0) {
      interviewLocationOptions = locations
    } else if (locations && locations.length){
      interviewLocation = locations[0]
    }
    // if (this.props.locationId && interviewLocationOptions && interviewLocationOptions.length){
    //   const location = interviewLocationOptions.find(location => location.id === this.props.locationId)
    //   this.onLocationChange(location)
    // }
    this.setState({interviewLocation,interviewLocationOptions})

    // determine the interview conditions
    let interviewConditions = []
    let interviewConditionsOptions = []
    let formatOption = this.formatOption
    if (conditions && conditions.length){
      for (let i in conditions){
        conditions[i].id = i // add a fake id to find it quickly then

        let condition = conditions[i];
        condition.type = 'conditions'

        // look for the key/values
        if (typeof(condition.question_key)=='string'){
          if(!condition.value){
            console.log('continue dude')
            continue
          }
          // the values come in the 'value' field
          if (condition.value.length == 1 && !condition.wildcard){
            // only one possible value for the condition
            // it's not an option for the user
            condition.value = (condition.value[0] && condition.value[0].id) ? [condition.value[0].id] : [condition.value[0]]
            condition.id = condition.question_key
            interviewConditions.push(condition)
          } else {
            if(typeof(condition.value)=='string'){
              condition.value = [condition.value]
            }
            let options = condition.value.map(c => {
              return {
                id: condition.question_key,
                value: typeof c === 'string' ? c : c.id,
                name: typeof c === 'string' ? c : c.value,
                title: condition.title,
                index: interviewConditionsOptionsIndex,
                type: condition.type
              }
            })
            console.log('lo hice bien')
            if(condition.wildcard){
              options.unshift({id: '', name: formatOption(condition.wildcard), title: condition.title, index: interviewConditionsOptionsIndex, type: condition.type})
            }
            condition.options = options
            interviewConditionsOptions.push(condition)
            interviewConditionsOptionsIndex++;
          }
        } else {
          // the values com in the question_index array)
          let questionKeys = Object.keys(condition.question_key)
          if (questionKeys.length == 1 && !condition.wildcard){
            // only one possible value for the condition
            // it's not an option for the user
            condition.value = [condition.question_key[questionKeys[0]]]
            condition.id = questionKeys[0]
            interviewConditions.push(condition)
          } else {
            let options = []
            if(condition.wildcard){
              options.push({id: '', name: formatOption(condition.wildcard), title: condition.title, index: interviewConditionsOptionsIndex, type: condition.type})
            }

            Object.keys(condition.question_key).map(function(k, index) {
              options.push({id: k, name: formatOption(condition.question_key[k]), value: condition.value, title: condition.title, index: interviewConditionsOptionsIndex, type: condition.type})
            })

            condition.options = options
            interviewConditionsOptions.push(condition)
            interviewConditionsOptionsIndex++;
          }
        }
      }
    }

    if (slot && slot.length){
      for (let i in slot){
        slot[i].id = i // add a fake id to find it quickly then
        let condition = slot[i];
        condition.type = 'slot'
        // look for the key/values
        if(!condition.values){
          continue
        }
        // the values come in the 'value' field
        if (Object.keys(condition.values).length == 1 && !condition.wildcard ){
          // only one possible values for the condition
          // it's not an option for the user
          condition.value = Object.keys(condition.values)[0]
          condition.id = condition.key;
          interviewConditions.push(condition)
        } else {
          let options = []
          if(condition.wildcard){
            options.push({id: '', name: formatOption(condition.wildcard), title: condition.title, index: interviewConditionsOptionsIndex, type: 'slot'})
          }

          Object.keys(condition.values).map(function(k, index) {

            const isRestricted = condition.restricted && condition.restricted.length
            if (isRestricted && (isAdmin || condition.restricted.indexOf(k) == -1)){
              options.push({id: condition.key, name: formatOption(condition.values[k]), value: k, title: condition.title, index: interviewConditionsOptionsIndex, type: 'slot'})
            } else if (!isRestricted){
              options.push({id: condition.key, name: formatOption(condition.values[k]), value: k, title: condition.title, index: interviewConditionsOptionsIndex, type: 'slot'})
            }
          })
          if (options.length == 1){
            // only one possible values for the condition
            // it's not an option for the user
            interviewConditions.push(options[0])
          } else {
            condition.options = options
            interviewConditionsOptions.push(condition)
            interviewConditionsOptionsIndex++;
          }
        }

      }
    }
    this.setState({interviewConditions, interviewConditionsOptions, interviewConfig: config.interviewConfig})
  }

  updateQueryParams = (query) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    Object.assign(location.query, query);
    browserHistory.push(location);
  }

  componentWillReceiveProps(newProps) {
    let listingId, stepId;
    if (newProps.stepId && newProps.stepId !== this.state.stepId) {
      const query = { stepId: newProps.stepId };
      this.updateQueryParams(query);
      this.setState(query);
      stepId = newProps.stepId;
    }


    if (getListing(newProps.listingId, newProps.listingStore.listings) && !getListing(this.props.listingId, this.props.listingStore.listings) ) {
      const listing = getListing(newProps.listingId, newProps.listingStore.listings)
      this.props.actions.getHiringManagers(listing.subsidiaryId)
    }

    if (newProps.listingId && newProps.listingId !== this.state.listingId) {
      const query = { listingId: newProps.listingId };
      this.updateQueryParams(query);
      this.setState(query);
      listingId = newProps.listingId;
    }

    if(newProps.stepStore.stepViews.interviewAvailability !== this.props.stepStore.stepViews.interviewAvailability){
      this.setState({
        slotAvailability: newProps.stepStore.stepViews.interviewAvailability
      })
    }

    if (newProps.stepStore.stepViews !== this.props.stepStore.stepViews) {
      const  { formatMessage } = this.props.intl
      const listingId = this.state.listingId;
      const stepId = this.state.stepId;
      const stepViews = newProps.stepStore.stepViews;
      if (stepViews && stepViews[listingId] && stepViews[listingId][stepId]){
        const config = stepViews[listingId][stepId].interviewConfig;
        this.processConfig(config)
        const locationCondition =  config && config.conditions && config.conditions.conditions.find(({question_key}) => question_key === 'working_place_id')
        if(this.props.locationId && locationCondition){ //fill working place
          const preSelected = locationCondition.options.find(({value})=> value === this.props.locationId)
          if (preSelected) {
            const condition = {
              id: locationCondition.question_key,
              index: locationCondition.id,
              name: preSelected.name,
              title: preSelected.title,
              type: preSelected.type,
              value: preSelected.value
            }
            this.onConditionChange(formatMessage)(condition)
          }
        }
        this.setState({
          config: config || {},
        });

      }
    }

    if (listingId || stepId){
      listingId = listingId || this.state.listingId;
      stepId = stepId || this.state.stepId;
      this.props.actions.getInterviewConfig(listingId, stepId);
    }

    const listing = getListing(this.props.listingId, this.props.listingStore.listings)
    if (listing) {
      this.subsidiaryId = listing.subsidiaryId
      const stepId = `${this.props.stepId}`
      const step = Object.values(listing.steps).find(s => +s.id === +stepId)
      if (step){
        const config = step.config
        if (!!config.requiredHiringManager || (!config.manualInterviewFeedback && config.manualSlotCreation)){
          this.setState({showHiringManagerOptions: true, subsidiaryId: listing.subsidiaryId})
        }
        if (!config.manualSlotCreation){
          this.setState({showDateOptions: false, showTimeOptions: false})
        }
      }
    }

  }
  resetState = () => {
    const interviewConditionsOptions = this.state.interviewConditionsOptions || [];
    for (let i in interviewConditionsOptions){
      interviewConditionsOptions[i].searchText=''
    }
    this.props.actions.resetSlotAvailability();
    this.setState({
      conditionToCheck: { date:{}, conditions: [], size: undefined},
      location: null,
      interviewConditionsOptions,
      slotAvailability: '-',
      interviewTimeError: false,
      interviewSizeError: false
    })
    if (this.props.onClose){
      this.props.onClose()
    }
    this.updateQueryParams({ vacancyId: undefined })
  }

  onSizeChange = (formatMessage) => (size) =>{
    let conditionToCheck = this.state.conditionToCheck;
    conditionToCheck.size = size;

    const { maxAllowedCandidates } = this.state.interviewConfig
    if (!isNil(maxAllowedCandidates) && size > maxAllowedCandidates) {
      this.setState({conditionToCheck, interviewSizeError: true})
      return
    }

    this.setState({interviewSizeError: false})

    conditionToCheck = this.calculateInterviewInterval(conditionToCheck, formatMessage)

    this.setState({conditionToCheck})
  }

  onDateChange = (date, originalDate) =>{
    const conditionToCheck = this.state.conditionToCheck;
    if (!conditionToCheck.date){
      conditionToCheck.date = {}
    }
    conditionToCheck.date.date = date;
    conditionToCheck.date.originalDate = originalDate;

    const interviewTime = conditionToCheck && conditionToCheck.date && conditionToCheck.date.time
    const dateTime = new Date(date + 'T' + interviewTime)
    if (isNaN(dateTime.getTime())){
      this.setState({conditionToCheck, interviewTimeError: true})

    }
    const minutesToInterview = (dateTime.getTime() - new Date().getTime()) / 1000 / 60
    if (minutesToInterview < this.state.interviewConfig.minutesToCloseInterview){
      this.setState({conditionToCheck, interviewTimeError: true})
    }
  }

  // Convert a time in hh:mm format to minutes
  timeToMins = (time) => {
    const b = time.split(':');
    return b[0]*60 + +b[1];
  }

  // Convert minutes to a time in format hh:mm
  // Returned value is in range 00  to 24 hrs
  timeFromMins = (mins) => {
    const z = (n) => {return (n<10? '0':'') + n;}
    const h = (mins/60 |0) % 24;
    const m = mins % 60;
    return z(h) + ':' + z(m);
  }

  addTimes = (t0, t1) => {
    return timeFromMins(timeToMins(t0) + timeToMins(t1));
  }

  calculateInterviewInterval = (conditionToCheck, formatMessage) =>{
    const time = conditionToCheck.date? conditionToCheck.date.time: null

    if (conditionToCheck.size && time){
      let slotType = this.state.interviewConditions.find(c => c.id == 'slot_type')

      if (!slotType){
        slotType = conditionToCheck.conditions.find(c => c && c.id == 'slot_type')
      }
      if (slotType && slotType.value == 'INDIVIDUAL'){
        let slotDuration = this.state.interviewConditions.find(c => c.id == 'duration')
        if (!slotDuration){
          slotDuration = conditionToCheck.conditions.find(c => c && c.id == 'duration')
        }
        if(slotDuration && time){
          let datetime = new Date('1970-01-01T' + time);
          let newDateTime = new Date (datetime);
          newDateTime.setMinutes ( datetime.getMinutes() + slotDuration.value*conditionToCheck.size );
          newDateTime = newDateTime.toLocaleTimeString('es',{ hour12: false })
          newDateTime = newDateTime.substring(0,newDateTime.lastIndexOf(':'))
          if (newDateTime.length < 5){
            newDateTime = '0'+newDateTime
          }
          conditionToCheck.date.finishTime = newDateTime
          conditionToCheck.date.duration = slotDuration.value
        }
      } else {
        conditionToCheck.date.finishTime = null
        conditionToCheck.date.duration = null
      }
    } else {
      console.log('something misisng')
    }

    this.validateTime(conditionToCheck, formatMessage)
    return conditionToCheck
  }

  validateTime = (conditionToCheck, formatMessage) => {
    const { date, size } = conditionToCheck ? conditionToCheck : {}

    const interviewStart = date && moment(`${date.date} ${date.time}`)
    
    let interviewEnd = interviewStart.clone()
    if (size && date.duration) {
      interviewEnd = interviewEnd.add(date.duration * size, 'minutes')
    }

    let errorMessage
    
    // Error: interview starts before interviewFromHour
    const { interviewFromHour } = this.state.interviewConfig
    if (
      interviewStart &&
      interviewFromHour &&
      interviewStart.get('hours') < interviewFromHour
    ) {
      errorMessage = formatMessage(messages.interview.interviewTooEarly, {
        hours: interviewFromHour > 12
          ? `${interviewFromHour - 12} PM` 
          : `${interviewFromHour} AM`
        })
      this.setState({ interviewTimeError: errorMessage })
      console.debug(errorMessage)
    }

    // Error: we don't have enough time before the interview starts
    const { minutesToCloseInterview } = this.state.interviewConfig
    if (
      interviewStart &&
      minutesToCloseInterview &&
      interviewStart.diff(moment(), 'minutes') <  minutesToCloseInterview // minutes until interview begins < minutesToCloseInterview
    ) {
      errorMessage = formatMessage(messages.interview.interviewTooCloseError, { hours: minutesToCloseInterview / 60 })
      this.setState({ interviewTimeError: errorMessage })
      console.debug(errorMessage)
    }

    // Error: interview ends later than interviewToHour
    const { interviewToHour } = this.state.interviewConfig

    const invalidEndDate = endDate => endDate.get('hours') > interviewToHour || // exceeds interviewToHour
      (endDate.get('hours') === interviewToHour && endDate.get('minutes') > 0) || // exceeds interviewToHour due to minutes
      endDate.get('dayOfYears') !== interviewStart.get('dayOfYears') // ends in a different day than starts
    
    if (
      interviewEnd &&
      interviewToHour &&
      invalidEndDate(interviewEnd)
    ) {
      errorMessage = formatMessage(messages.interview.interviewTooLate, {
        hours: interviewToHour > 12
          ? `${interviewToHour - 12} PM` 
          : `${interviewToHour} AM`
        })
      this.setState({ interviewTimeError: errorMessage })
      console.debug(errorMessage)
    }

    // No errors: remove error messages from UI
    if (!errorMessage) {
      this.setState({ interviewTimeError: false })
    }

  }

  onTimeChange = (formatMessage) => (time, originalTime) =>{
    let conditionToCheck = this.state.conditionToCheck;
    if (!conditionToCheck.date){
      conditionToCheck.date = {}
    }
    conditionToCheck.date.time = time;
    conditionToCheck.date.originalTime = originalTime;

    conditionToCheck = this.calculateInterviewInterval(conditionToCheck, formatMessage)

    this.setState({conditionToCheck})
  }
  onLocationChange = (location) =>{
    const conditionToCheck = this.state.conditionToCheck;

    conditionToCheck.location = location;
    this.setState({conditionToCheck});
  }

  onHiringManagerChange = (hiringManager) =>{
        if (hiringManager) {
          this.setState({
            hiringManager:
              {
                subsidiaryId: this.state.subsidiaryId,
                ref: hiringManager.phoneNumber,
                platformId: this.state.subsidiaryId,
                ...hiringManager,
              }
          });
        }else{
          this.setState({hiringManager: null})
        }
  }
  onConditionChange = (formatMessage) => (condition) =>{
      let conditionToCheck = this.state.conditionToCheck;
    const interviewConditionsOptions = this.state.interviewConditionsOptions;

    conditionToCheck.conditions[condition.index] = condition
    if(interviewConditionsOptions[condition.index]){
      interviewConditionsOptions[condition.index].searchText= condition.name
    }
    conditionToCheck = this.calculateInterviewInterval(conditionToCheck, formatMessage)
    // conditionToCheck.conditions.push(condition);
    this.setState({conditionToCheck});
    // this.checkSlotAvailability();
  }

  buildConditionsObject(slotObject){
    const conditionToCheck = this.state.conditionToCheck
    const interviewConditions = this.state.interviewConditions
    const interviewLocation = this.state.interviewLocation || conditionToCheck.location
    const interviewConditionsOptions = this.state.interviewConditionsOptions
    const conditions = []
    for(let i in conditionToCheck.conditions){
      let o = conditionToCheck.conditions[i]
      if(o.type == 'slot'){
        slotObject[o.id]=o.value
      } else {
        // build a condition object
        if (o.id){
          let conditionObject = {
            matchType: interviewConditionsOptions[o.index].match_type,
            stepId: interviewConditionsOptions[o.index].step_id,
            questionKey: o.id,
            value: o.value,
            alias: o.title
          }
          conditions.push(conditionObject)
        }
      }
    }

    for(let i in interviewConditions){
      let o = interviewConditions[i]
      if(o.type == 'slot'){
        slotObject[o.id]=o.value
      } else {
        if (o.id){
          let conditionObject
          if (o.index){
            conditionObject = {
              matchType: interviewConditions[o.index].match_type,
              stepId: interviewConditions[o.index].step_id,
              questionKey: o.id,
              value: o.value,
              alias: o.title
            }
          } else {
            conditionObject = {
              matchType: o.match_type,
              stepId: o.step_id,
              questionKey: o.id,
              value: o.value[0],
              alias: o.title
            }
          }
          conditions.push(conditionObject)
        }

      }
    }

    return conditions
  }

  checkSlotAvailability = () =>{
    const conditionToCheck = this.state.conditionToCheck
    const interviewLocation = this.state.interviewLocation || conditionToCheck.location

    const slotObject = {
      stepId: this.state.stepId,
      listingId: this.state.listingId,
      localDate: conditionToCheck.date.date,
      localTime: conditionToCheck.date.time,
      size: conditionToCheck.size,
      officeId: interviewLocation && interviewLocation.id,
    }

    const conditions = this.buildConditionsObject(slotObject)

    const slotConfig = {
      slot: slotObject,
      conditions:{
        single: conditions
      }
    }
    this.props.actions.resetSlotAvailability();
    this.props.actions.getSlotAvailability(slotConfig, this.state.listingId, this.state.stepId)
  }

  createSlot = () =>{
    const conditionToCheck = this.state.conditionToCheck
    const interviewLocation = this.state.interviewLocation || conditionToCheck.location

    let stepConfig;
    if (this.props.experimental_config) {
      stepConfig = this.props.experimental_config
    } else {
      const step = this.props.listingStore.listings.flatMap(l =>  Object.values(l.steps || {})).find(({id}) => id == this.state.stepId)
      stepConfig = step && step.config
    }
    
    const slotObject = {
      stepId: this.state.stepId,
      officeId: interviewLocation && interviewLocation.id,
      listingId: this.state.listingId,
      localDate: conditionToCheck.date.date,
      localTime: conditionToCheck.date.time,
      timezone: interviewLocation && interviewLocation.timezone,
      size: conditionToCheck.size,
      hiringManagerCommunicationEnabled: !!this.state.hiringManager && !noCommunicationHiringManagers.includes(this.state.hiringManager.id) && stepConfig.hasInterviewScheduling,
      hiringManager: this.state.hiringManager,
    }

    const conditions = this.buildConditionsObject(slotObject)

    const slotConfig = {
      slot: slotObject,
      conditions:{
        single: conditions
      }
    }

    const listing = getListing(this.props.listingId, this.props.listingStore.listings)

    if (listing.subsidiaryId === 72) {
      const hour = moment(conditionToCheck.date.date + ' ' + conditionToCheck.date.time).hour()
      if (hour < 8 || hour > 19){
        throw Error('Horario invalido para farmacity')
      }
    }

    this.props.actions.saveInterviewSlot(
      slotConfig,
      this.state.listingId,
      this.props.candidateId,
      this.props.assignmentId,
      listing.subsidiaryId,
      (error) => {
        if (this.props.onError && error) {
          return this.props.onError(error);
        }
        if (this.props.onCreate) {
          this.props.onCreate();
        }
      }
    );

    const positionName = conditionToCheck.conditions.find(cond => cond.id.includes('position')) ? conditionToCheck.conditions.find(cond => cond.id.includes('position')).name : '';
    
    track({ 
      type: this.props.candidateId ? 'INVITE_CANDIDATE_TO_INTERVIEW_MODAL_CONFIRM' : 'CREATE_NEW_INTERVIEW_MODAL_CONFIRM', 
      candidateCount: conditionToCheck.size,
      interviewSlotLength: conditionToCheck.date.duration,
      positionName: positionName,
      interviewType: slotObject.slot_type,
      interviewAt: conditionToCheck.date.date + ' ' + conditionToCheck.date.time,
      candidateId: this.props.candidateId || undefined
    });

    this.setState({
      hiringManager: null,
      hiringManagerCommunicationEnabled: false
    });

    this.updateQueryParams({ vacancyId: undefined })
  }

  render() {
    const  { formatMessage } = this.props.intl
    const listing = getListing(this.props.listingId, this.props.listingStore.listings)
    const hiringManagers = listing ? getHiringManagers(listing.subsidiaryId, this.props.stepStore.hiringManagers) : []
    const { layout } = this.props
    if (this.state.showDateOptions === false){
      layout.showDate = false
    }
    if (this.state.showTimeOptions === false){
      layout.showTime = false
    } 
    return (
      <Sentry.ErrorBoundary 
        beforeCapture={(scope) => {
          scope.setUser(this.props.appStore.user)
          scope.setTag('feature','NewInterviewDialog')
        }}
        fallback={<div></div>}
      >
        <NewInterviewDialog
          location={this.state.conditionToCheck.location?this.state.conditionToCheck.location.name:null}
          hiringManager={this.state.hiringManager}
          onHiringManagerChange={this.onHiringManagerChange}
          hiringManagers={hiringManagers || []}
          locations={this.state.interviewLocationOptions}
          conditions={this.state.interviewConditionsOptions}
          interviewConfig={this.state.interviewConfig}
          interviewTimeError={this.state.interviewTimeError}
          onConditionChange={this.onConditionChange(formatMessage)}
          onDateChange={this.onDateChange}
          onTimeChange={this.onTimeChange(formatMessage)}
          onLocationChange={this.onLocationChange}
          interviewSizeError={this.state.interviewSizeError}
          onSizeChange={this.onSizeChange(formatMessage)}
          onClose={this.resetState}
          onCloseOk={this.createSlot}
          onVerify={this.checkSlotAvailability}
          checkConditions={this.state.conditionToCheck}
          slotAvailability={this.state.slotAvailability.toString()}
          showHiringManagerOptions={this.state.showHiringManagerOptions}
          requiredHiringManager={this.state.requiredHiringManager}
          showAddHiringManager={this.state.subsidiaryId !== 67} //todo remove when implemented complete form for imperfect foods
          country={this.props.country}
          locationId={this.props.locationId}
          candidateName={this.props.candidateName}
          candidateId={this.props.candidateId}
          loading={this.props.listingStore.loading || this.props.stepStore.loading}
          vacancyId={this.props.location.query.vacancyId}
          {...layout}
        />
      </Sentry.ErrorBoundary>
    );
  }
}

InterviewStepView.propTypes = {
  stepId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  candidateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  candidateName: PropTypes.string,
  candidateId: PropTypes.number,
  showFilters: PropTypes.bool,
  showColumnsCheckbox: PropTypes.bool,
  listing: PropTypes.object,
  rejected: PropTypes.bool,
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  stepStore: PropTypes.object.isRequired,
  listingStore: PropTypes.object.isRequired,
  assignmentId: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
  stepStore: makeSelectStepViews(),
  appStore: makeSelectGlobal(),
  listingStore: makeSelectListingPage()
});

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators({ ...appActions, ...listingActions, ...stepViewActions }, dispatch);
  return {
    actions,
  };
}

const withCountry = (WrappedComponent) => (props) => {
  const [country, setCountry] = useState()
  useEffect(() => {
    if (props.appStore && props.appStore.user && props.appStore.user.subsidiary && props.appStore.user.subsidiary.country) {
      setCountry(props.appStore.user.subsidiary.country)
    } else {
      async function getCountry() {
        const currentUser = await getCurrentUser()
        if (currentUser && currentUser.subsidiary && currentUser.subsidiary.country) {
          setCountry(currentUser.subsidiary.country)
        }
      }
      getCountry()
    }
  }, [])
  
  if (!country) {
    return null
  }
  
  return <WrappedComponent {...props} country={country} />
} 

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withCountry(withRouter(InterviewStepView))));
