import { call, put, take, takeLatest } from 'redux-saga/effects';
import { GET_CANDIDATES } from './constants';
const camelCaseKeys = require('camelcase-keys')

import request from '../../utils/request';
import { getCandidatesError, getCandidatesSuccess } from './actions';
import { getListingError, getListingSuccess } from '../Listings/actions';

const config = require('../../config');
const root = config.params.API_SERVER;

export function* sCandidates() {
  try {
    const requestURL = `${root}/available-candidates`

    const results = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(getCandidatesSuccess(results));
  } catch (error) {
    yield put(getCandidatesError(error));
  }
}

export function* getInterviewApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_CANDIDATES, sCandidates);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

// All sagas to be loaded
export default [
  getInterviewApi,
];
