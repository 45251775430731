import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "./components/AppBar";
import AppBarDivider from "./components/AppBarDivider";
import AppBarIcon from "./components/AppBarIcon";
import AppBarImpersonated from "./components/AppBarImpersonated";
import AppBarProfile from "./components/AppBarProfile";
import AppBarTestEnvironment from "./components/AppBarTestEnvironment";
import AppBarToggleMenuButton from "./components/AppBarToggleMenuButton";
import AppBarWorkspace from "./components/AppBarWorkspace";
import Main from "./components/Main";
import Menu from "./components/Menu";
import MenuItem from "./components/MenuItem";
import { useMenu } from "./hooks/useMenu";

export default function Layout({
  menuItems,
  userName,
  profileMenuItems,
  workspace,
  isImpersonated,
  isTestEnvironment,
  children,
}) {
  const {
    expanded,
    menu,
    collapse,
    toggle,
    toggleSection,
    selectItem
  } = useMenu({ baseMenu: menuItems })

  return (
    <Box display="flex">
      <CssBaseline />
      <AppBar>
        <AppBarToggleMenuButton onClick={toggle} />
        <AppBarIcon name="emiColor" />
        <AppBarDivider />
        { isTestEnvironment && <AppBarTestEnvironment />}
        <AppBarWorkspace>{workspace}</AppBarWorkspace>
        { isImpersonated && <AppBarImpersonated />}
        <AppBarProfile menu={profileMenuItems}>{userName}</AppBarProfile>
      </AppBar>
      <Menu expanded={expanded} onClose={collapse}>
        {menu.map((item) => (
          <MenuItem
            key={item.id}
            expanded={expanded}
            toggleSection={toggleSection}
            selectItem={selectItem}
            item={item}
          />
        ))}
      </Menu>
      <Main>{children}</Main>
    </Box>
  );
}