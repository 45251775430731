const styles = (isBoxedLayout, currentTheme) => ({
  appBar: {
    position: 'fixed',
    top: 0,
    overflow: 'hidden',
    maxHeight: 57,
    maxWidth: isBoxedLayout ? 1200 : null,
  },
  emiLogoMobile: {
    verticalAlign: 'middle',
    height: '60%',
    paddingBottom: 8,
  },
  menuButton: {
    marginLeft: 10,
  },
  iconButton: {
    fill: currentTheme.appBarMenuButtonColor,
  }
});

export default styles;
