import React from 'react';
import { Typography, Alert } from '@mui/material';
import styled from 'styled-components'
import { useTimeout } from '../../v2/hooks';

const ToastType = Object.freeze({
	ERROR: 'error',
	SUCCESS: 'success',
	INFO: 'info',
});

const ToastBackgroundColor = {
	error: '#db6363!important',
	success: '#5B88FF!important',
	info: '#39109C!important',
};

const ToastAlert = styled(Alert)`
		position: absolute;
		background-color: ${({ type }) => ToastBackgroundColor[type]};
		color: #fff!important;
		bottom: 20px;
		left: 20px;
		height: 50px;
		z-index: 110;
		width: ${({ fullWidth }) => fullWidth ? '90%!important' : 'fit-content'};
		border-radius: 8px!important;

		& .MuiAlert-icon {
			color: #fff!important
		};
`;

const Toast = ({ message, type, icon, close, fullWidth }) => {
	useTimeout(close, 3000);

	return (
		<ToastAlert icon={icon} onClose={close} type={type} fullWidth={fullWidth}>
			<Typography variant="paragraph">{message}</Typography>
		</ToastAlert>
	);
};

export {
	Toast,
	ToastType,
}