import moment from 'moment';
import Curp from './documents/Curp';
import Rfc from './documents/Rfc';
import Nss from './documents/Nss';
import dateUtils from './date';
import { isValidPhoneNumber } from 'libphonenumber-js';

const executeValidator = (validator, value, ref = null, options = {}) => {
  switch (validator) {
    case 'email':
      return validateEmail(value);
    case 'nss':
      return validateNss(value);
    case 'nssYearOfBirth':
      return validateNssYearOfBirth(value, ref);
    case 'curp':
      return validateCurp(value);
    case 'curpBirthdate':
      return validateCurpBirthdate(value, ref);
    case 'rfc':
      return validateRfc(value);
    case 'rfcCustomWM':
      return validateRfcCustomWM(value);
    case 'rfcBirthdate':
      return validateRfcBirthdate(value, ref);
    case 'avoidWeekend':
      return !isWeekend(value);
    case 'avoidSpecificDay':
      return !isSpecificDay(value);
    case 'avoidHoliday':
      return !isHoliday(value);
    case 'onlyNumbers':
      return hasOnlyNumbers(value);
    case 'avoidNumbers':
      return !hasNumbers(value);
    case 'zipCode':
      return validateZipCode(value);
    case 'onlyNumbersAndLetters':
      return hasOnlyNumbersAndLetters(value);
    case 'paymentMethodCustomWM':
      return validatePaymentMethodCustomWM(value, ref);
    case 'avoidUnderAge':
      return !isUnderAge(value);
    case 'phoneNumber':
      return validatePhoneNumber(value, options.countryCode);
    case 'floatingPointNumberString': // accepts only positive numbers
      return isFloatingPointNumberString(value);
    case 'age':
      return validateAge(value, ref);
    case 'locationOperation':
      return validateLocationOperation(ref);
    default:
      return false;
  }
};

const hasNumbers = (value) => {
  return !!String(value).match(/\d/);
}

const validateZipCode = (zipCode) => {
  if(!!!String(zipCode).match(/\b\d{5}\b/g)) {
    return false
  }
  const numZipCode = +zipCode
  return numZipCode >= 1000 && numZipCode <= 99999
}

const hasOnlyNumbers = (value) => {
  return !!String(value).match(/^\d+$/)
}

const hasOnlyNumbersAndLetters = (value) => {
  return !!String(value).match(/^[0-9a-zA-Z ]+$/)
}

const isWeekend = (date) => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return false;
  }
  const dayOfWeek = momentDate.day();
  return dayOfWeek === 6 || dayOfWeek === 0;
}

const isHoliday = (date) => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return false;
  }
  return dateUtils.isHoliday(momentDate);
};

const isSpecificDay = (date) => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return false;
  }
  return dateUtils.isSpecificDay(momentDate);
};

const validateEmail = (email) => {
  console.log('### validate email', email)
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const validateRfc = rfc => new Rfc(rfc).isValid();

const validateRfcCustomWM = rfc =>
  rfc && rfc.length > 0 && rfc.endsWith('000') ? true : validateRfc(rfc);

const validateRfcBirthdate = (rfc, date) => new Rfc(rfc).isValidBirthdate(date);

const validateNss = nss => new Nss(nss).isValid();

const validateNssYearOfBirth = (nss, date) =>
  new Nss(nss).isValidYearOfBirthdate(date);

const validateCurp = curp => new Curp(curp).isValid();

const validateCurpBirthdate = (curp, date) =>
  new Curp(curp).isValidBirthdate(date);

const validatePaymentMethodCustomWM = (paymentMethod, rfc) => {
  if (!paymentMethod || !rfc) {
    return true;
  }
  if (paymentMethod !== 'PO') {
    return true;
  }
  return validateRfc(rfc) && !rfc.endsWith('000');
};

const isUnderAge = date => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return false;
  }
  const years = moment.duration(moment().diff(momentDate)).asYears();
  return years < 18;
};

const validatePhoneNumber = (phoneNumber, country) => {
  if (!phoneNumber) {
    return false;
  }
  return isValidPhoneNumber(phoneNumber, country);
};

const isFloatingPointNumberString = value => {
  return !!String(value).match(/^([0-9]+([.][0-9]*)?)$/);
}

const validateAge = (age, birthdate) => {
  return moment().diff(birthdate, 'years') === Number(age)
}

const validateLocationOperation = (operation) => {
  return operation !== 'COMISIONISTA'
}

export { executeValidator };
