import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedRelative} from 'react-intl';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';

import messages from '../../../../messages';

const styles = theme => ({
  container: {
    // minWidth: '600px',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    width: '100%'
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    '&$cssFocused': {
      color: purple[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: purple[500],
    },
  },
  bootstrapRoot: {
    width: '70%',
    marginRight: "20px",
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapRootFullWidth: {
    width: '100%',
    marginRight: "20px",
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    // width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

class CustomizedInputs extends React.Component {
  state = {
    value: ''
  }
  onChange = (event) => {
    const value = event.target.value;
    if (this.props.onChange){
      this.props.onChange(value)
    };
    this.setState({value})
  }

  onClick = (e) => {
    if (this.props.onAdd){
      this.props.onAdd(this.state.value);
      this.setState({value: ''})
      if (this.props.onChange){
        this.props.onChange('')
      };
    }
  }

  render () {
    const { classes } = this.props;
    const {formatMessage} = this.props.intl;

    return (
      <div className={classes.container}>
        <TextField
          className={this.props.showAdd?classes.bootstrapRoot:classes.bootstrapRootFullWidth}
          onChange={this.onChange}
          value={this.state.value}
          placeholder={formatMessage(messages.descartes.reasonDialog.input.placeholder)}
          id="bootstrap-input"
          InputProps={{
            disableUnderline: true,
            classes: {
              // root: classes.bootstrapRoot,
              input: classes.bootstrapInput,
            },
          }}
          InputLabelProps={{
            shrink: true,
            className: classes.bootstrapFormLabel,
          }}
        />

        {this.props.showAdd?(
          <Button color="primary" variant="outlined" onClick={(e) => this.onClick(e)}>
            {formatMessage(messages.descartes.reasonDialog.button.add)}
          </Button>
        ):null}

      </div>
    );
  }

}

CustomizedInputs.propTypes = {
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  showAdd: PropTypes.bool,
  
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(CustomizedInputs));
