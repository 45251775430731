import Hotjar from '@hotjar/browser';
import React, { useEffect } from 'react';
import { identifyUser } from './utils';

export const HotjarWrapper = ({ user }) => {

  useEffect(() => {
    if (!Hotjar.isReady()) {
      const isReady = Hotjar.init(3211484, 6, { debug: true })
      if (isReady) {
        identifyUser(user)
      } 
    } else {
      identifyUser(user)
    }
  });

  return null;
};
