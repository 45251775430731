import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedRelative} from 'react-intl';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import SearchInput from '../../Emi/Input'

import messages from '../messages';

const getValue = (value, header) => {
  try {
    if (header && header.transformation && header.transformation[value]) {
      return header.transformation[value]
    }
    return value
  } catch (e) {
    return value
  }
}

const styles = theme => ({
  root: {
    padding: "10px",
    // marginTop: "20px"
  },
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

class CandidateSectionView extends React.Component {
  state = {}

  render () {

    const { classes, data } = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <section className={classes.root} style={{backgroundColor: 'white'}}>
        {this.props.fields.length? <h2>{this.props.title}</h2>:null}
        <Grid container className={classes.root}>
          {this.props.fields.map(f => {       
            if((f.show || f.in_emicv) && data[f.id] && f.id != 'reason' && (
              (typeof data[f.id] === 'string' && !data[f.id].endsWith('.jpg')&& data[f.id].indexOf('http') == -1) ||
              (typeof data[f.id] === 'number')
            )) {
              return <Grid item xs={12} md={12} key={f.id}>
                <b>{f.label}:</b> <span>{getValue(data[f.id], f)}</span>
              </Grid>
            }
          })}

        </Grid>
      </section>
    );
  }

}

CandidateSectionView.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  data: PropTypes.object,

  
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(CandidateSectionView));
