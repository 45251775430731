import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextField from './CustomTextField';

const useStyles = value =>{
  const color = acquireValidationColor(value);
  return makeStyles(() => ({
    root: {
      '& .MuiFormHelperText-root': { color },
      '& .MuiFormLabel-root.Mui-error': { color },
      '& .MuiFormLabel-asterisk.Mui-error': { color },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': { borderColor: color },
      '& .MuiInputBase-root.Mui-disabled': { background: '#eeeeee', color: '#636363' },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {borderColor:'#ebebeb4d'},
      '& .MuiInputBase-multiline.Mui-disabled': {color:'#000', background:'#ebebeb4d'} ,
      
    },
  }));
}

const acquireValidationColor = status => {
  switch (status) {
    case 'ERROR':
      return '#f44336';
    case 'WARN':
      return '#f9a825';
    default:
      return '#A1A1A1';
    
  }
};

const ValidationField = ({ validation: { status, message }, ...rest}) => {
  const classes = useStyles(status)();
  return (
    <CustomTextField
      {...rest}
      error={status !== 'OK'}
      helperText={message || rest.helperText}
      className={classes.root}
    />
  );
};

export default ValidationField;
