import React from 'react';
import { Button, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  offTablePagination: theme.styles.buttons.offTablePagination
}))

const OffTablePaginationButton = (props) => {
  const styles = useStyles()
  return <Button
    {...props}
    className={styles.offTablePagination}
  />
}

export default OffTablePaginationButton