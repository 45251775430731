import { useReducer } from "react";

function match(a, b) {
  try {
    return a.startsWith(b)
  } catch (e) {
    console.error(`Cannot validate if urls match [${a}/${b}]`, e)
    return false
  }
}

function matchEndsWith(a, b) {
  try {
    return a.endsWith(`.${b}`)
  } catch (e) {
    console.error(`Cannot validate if urls match [${a}/${b}]`, e)
    return false
  }
}

function wrap(menu) {
  return {
    raw: menu,
    unwrap: function () { return this.raw },
    collapseAllSections: function() {
      this.raw = this.raw.map(x => ({ ...x, open: false }))
      return this
    },
    collapseAllSectionsExcept: function(exception) {
      this.raw = this.raw.map(x => ({ ...x, open: exception === x.label ? x.open : false }))
      return this
    },
    toggleSection: function(section) {
      this.raw = this.raw.map(x => ({ ...x, open: x.label === section ? !x.open : x.open }))
      return this
    },
    updateActiveSection: function (id) {
      this.raw = this.raw.map((x) => {
        if (x.type === 'item') {
          return ({ ...x, selected: match(id, x.id) })
        } else {
          const subItems = x.subItems.map(y => ({ ...y, selected: match(id, y.id) }))
          return ({ ...x, selected: subItems.some(x => !!x.selected), subItems })
        }
      })
      return this
    },
    activateRootSection: function () {
      this.raw = this.raw.map((x) => {
        if (x.type === 'item') {
          return ({ ...x, selected: x.isRoot })
        } else {
          const subItems = x.subItems.map(y => ({ ...y, selected: y.isRoot }))
          return ({ ...x, selected: subItems.some(x => !!x.selected), subItems })
        }
      })
      return this
    },
    activateSectionByUrl: function (url) {
      this.raw = this.raw.map((x) => {
        if (x.type === 'item') {
          return ({ ...x, selected: x.id === url })
        } else {
          const subItems = x.subItems.map(y => ({ ...y, selected: matchEndsWith(y.id, url) }))
          return ({ ...x, selected: subItems.some(x => !!x.selected), subItems })
        }
      })
      return this
    },
    expandActiveSection: function () {
      this.raw = this.raw.map((x) => ({ ...x, open: x.selected ? true : x.selected}))
      return this
    }
  }
}

const TYPE = {
  EXPAND_MENU: "EXPAND_MENU",
  COLLAPSE_MENU: "COLLAPSE_MENU",
  TOGGLE_MENU: "TOGGLE_MENU",
  TOGGLE_SECTION: "TOGGLE_SECTION",
  SELECT: "SELECT",
}

function reducer(state, action) {
  switch (action.type) {
    case TYPE.EXPAND_MENU:
      return { ...state, expanded: true, menu: wrap(state.menu).collapseAllSections().expandActiveSection().unwrap() }
    case TYPE.COLLAPSE_MENU:
      return { ...state, expanded: false }
    case TYPE.TOGGLE_SECTION:
      return { ...state, expanded: true, menu: wrap(state.menu).collapseAllSectionsExcept(action.payload.section).toggleSection(action.payload.section).unwrap() }
    case TYPE.SELECT:
      return { ...state, expanded: false, menu: wrap(state.menu).updateActiveSection(action.payload.id).unwrap() }
    default:
      return state;
  }
}

function initialState(baseMenu) {
  return {
    expanded: false,
    menu: window.location.pathname === '/' 
      ? wrap(baseMenu).collapseAllSections().activateRootSection().expandActiveSection().unwrap()
      : wrap(baseMenu).collapseAllSections().activateSectionByUrl(window.location.pathname).expandActiveSection().unwrap()
  }
}

export function useMenu({ baseMenu }) {
  const [state, dispatch] = useReducer(reducer, initialState(baseMenu))

  const expand = () => dispatch({ type: TYPE.EXPAND_MENU })
  const collapse = () => dispatch({ type: TYPE.COLLAPSE_MENU })
  const toggle = () => state.expanded ? collapse() : expand()
  const toggleSection = (section) => dispatch({ type: TYPE.TOGGLE_SECTION, payload: { section } })
  const selectItem = (id) => dispatch({ type: TYPE.SELECT, payload: { id }})

  return {
    expanded: state.expanded,
    menu: state.menu,
    collapse,
    toggle,
    toggleSection,
    selectItem
  }
}