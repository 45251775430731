import React from 'react';
import { injectIntl } from 'react-intl';
import { Box } from '@mui/material';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { useToast, ToastType } from '../../../components/Toasts';
import messages from './messages';

const CopyToClipboard = ({ content, intl }) => {
    const { formatMessage } = intl;
    const toast = useToast();

    const copyContent = async () => {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(content);
            toast.open(
                formatMessage(messages.clipboard.success),
                ToastType.SUCCESS,
                <FileCopyOutlined />
            );
        } else {
            toast.open(
                formatMessage(messages.clipboard.error),
                ToastType.ERROR,
                <FileCopyOutlined />
            );
        }
    };

    return (
        <Box sx={{
            width: '35px',
            height: 'fit-content',
            color: '#063EA8',
            backgroundColor: '#E9E9E9',
            padding: '5px 10px',
            borderRadius: '5px',
            cursor: 'pointer',
            boxSizeing: 'border-box',
        }} onClick={copyContent}>
            <FileCopyOutlined sx={{ width: '0.9rem' }} />
        </Box>
    );
};

export default injectIntl(CopyToClipboard);

