import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PrimaryButton from '../../../v2/components/buttons/PrimaryButton'
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton'
import CheckboxIcon from '../../../v2/components/icons/CheckboxIcon'
import messages from '../messages';
import emiMessages from '../../Emi/messages'
import { Grid } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
`;

const StandByDialog = ({ open, onClose, name, reasons, onConfirm, intl: { formatMessage }, loading }) => {
  const [selectedReason, setSelectedReason] = useState(null)
  const doConfirm = () => onConfirm(selectedReason)

  const ready = !loading && reasons;
  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth='md'
    fullWidth
  >
    <DialogTitle>{formatMessage(messages.standBy.title)}: {name}</DialogTitle>
    <DialogContent>
      {!ready ? <Grid item container justify="center"><CircularProgress /></Grid>
      :
      <CheckboxGrid>
        {reasons.map(reason => (
          <FormControlLabel
            key={reason.value}
            control={
              <Checkbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIcon checked />}
                checked={selectedReason && selectedReason.value === reason.value}
                disabled={selectedReason && selectedReason.value !== reason.value}
                onChange={() => {
                  selectedReason && selectedReason.value === reason.value
                  ? setSelectedReason(null)
                  : setSelectedReason(reason)
                }}
              />
            }
            label={reason.label}
          />
        ))}
      </CheckboxGrid>
    }
    </DialogContent>
    <DialogActions>
      <PrimaryButton
        onClick={doConfirm}
        disabled={!selectedReason}
      >
        {formatMessage(emiMessages.button.confirm)}
      </PrimaryButton>
      <SecondaryButton
        type="text"
        onClick={onClose}
      >
        {formatMessage(emiMessages.button.cancel)}
      </SecondaryButton>
    </DialogActions>
  </Dialog>
}

export default injectIntl(StandByDialog)