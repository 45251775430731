import React from "react";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const MuiListItemButtonExtended = styled(MuiListItemButton)(({ theme }) => ({
  "&:hover": { borderRadius: theme.spacing() },
}));

const ListItemButton = ({
  expanded,
  children,
  title,
  ...props
}) =>
  expanded ? (
    <MuiListItemButtonExtended {...props}>{children}</MuiListItemButtonExtended>
  ) : (
    <Tooltip title={title} placement="right" arrow>
      <MuiListItemButtonExtended {...props}>{children}</MuiListItemButtonExtended>
    </Tooltip>
  );

const MenuLabel = styled("div")(({ theme, selected }) => ({
  fontFamily: "Lato",
  fontSize: 14,
  fontWeight: selected ? 700 : 400,
  color: theme.custom.navigation.menu.label.color,
}));

export default ({ expanded, selectItem, toggleSection, item: { id, Icon, label, selected, type, open, subItems, onClick } }) => {
  const hasSubItems =
    expanded && type === "section" && open && subItems;
  const handleSelectItem = (itemId, callback) => {
    selectItem(itemId)
    callback()
  }
  return (
    <React.Fragment key={label}>
      <ListItem
        sx={{
          display: "block",
          padding: 0,
          marginBottom: theme => hasSubItems ? 0 : theme.spacing(1),
        }}
      >
        <ListItemButton
          onClick={() => {
            if (type === "section") {
              toggleSection(label)
            } else {
              handleSelectItem(id, onClick)
            }
          }}
          expanded={expanded}
          title={label}
          sx={ theme =>
            expanded
              ? { padding: theme.spacing(1, 1, 1, 0.5) }
              : {
                padding: theme.spacing(1, 0, 1, 1.5),
                ...(selected && {
                  backgroundColor:
                    theme.custom.navigation.menu.label.selected,
                  borderRadius: theme.spacing(1),
                }),
              }
          }
        >
          <Icon
            sx={theme => ({
              color: selected
                ? theme.custom.navigation.menu.icon.selectedColor
                : theme.custom.navigation.menu.icon.defaultColor,
              fontSize: theme.spacing(3),
              margin: "auto",
            })}
          />
          <MenuLabel
            selected={selected}
            sx={{
              marginLeft: theme => theme.spacing(1),
              flexGrow: 1,
              opacity: expanded ? 1 : 0,
            }}
          >
            {label}
          </MenuLabel>
          {type === "section" && open && (
            <ExpandLessIcon
              sx={theme => ({
                color:
                  theme.custom.navigation.menu.sectionIcon
                    .expandLess.color,
                backgroundColor:
                  theme.custom.navigation.menu.sectionIcon
                    .expandLess.backgroundColor,
                borderRadius: theme.spacing(0.5),
                fontSize: 20,
                opacity: expanded ? 1 : 0,
              })}
            />
          )}
          {type === "section" && !open && (
            <ExpandMoreIcon
              sx={theme => ({
                color:
                  theme.custom.navigation.menu.sectionIcon
                    .expandMore.color,
                backgroundColor:
                  theme.custom.navigation.menu.sectionIcon
                    .expandMore.backgroundColor,
                borderRadius: theme.spacing(0.5),
                fontSize: 20,
                opacity: expanded ? 1 : 0,
              })}
            />
          )}
        </ListItemButton>
      </ListItem>
      {expanded &&
        type === "section" &&
        open &&
        subItems &&
        subItems.map(({ label, selected, id, onClick }) => (
          <ListItemButton
            key={id}
            onClick={() => handleSelectItem(id, onClick)}
            sx={theme => ({
              margin: theme.spacing(0, 5),
              padding: theme.spacing(1),
              ...(selected && {
                backgroundColor:
                  theme.custom.navigation.menu.label.selected,
                borderRadius: theme.spacing(1),
              }),
            })}
          >
            <MenuLabel sx={{ flexGrow: 1 }} selected={selected}>
              {label}
            </MenuLabel>
          </ListItemButton>
        ))}
    </React.Fragment>
  );
}
