import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { useUser } from './hooks';
import { version } from '../../package.json';

const enhanceScope = (scope, tags, extras) => {
  const user = useUser();
  scope.setUser(user);
  scope.setTag('appVersion', version);
  if (tags) {
    scope.setTags(tags);
  }
  if (extras) {
    scope.setExtras(extras);
  }
};

const captureException = (e, tags, extras) => {
  const trackId = uuidv4();
  Sentry.captureException(e, scope => {
    enhanceScope(scope, { ...tags, trackId }, extras);
    scope.setExtra('exception', e);
  });
  return { trackId, e };
};

const captureMessage = (message, tags, extras) => {
  const trackId = uuidv4();
  Sentry.withScope(scope => {
    enhanceScope(scope, { ...tags, trackId }, extras);
    Sentry.captureMessage(message);
  });
  return { trackId };
};

export { captureException, captureMessage };
