import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PrimaryButton from '../../../../../v2/components/buttons/PrimaryButton'
import SecondaryButton from '../../../../../v2/components/buttons/SecondaryButton'
import CheckboxIcon from '../../../../../v2/components/icons/CheckboxIcon'
import { isANotAssignedWorkingPlaceReason } from '../index'
import messages from '../../../messages'
import { Grid, CircularProgress } from '@material-ui/core'

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
`;

const buildReasons = (options) => {
  return options && options.every(item => typeof item === 'string')
  ? options.map(item => ({
    reason: item,
    id: null
  }))
  : options
  ? options.map(item => ({
    reason: item.reason,
    id: item.id
  }))
  : null
}

const RejectCandidateDialog = ({ open, onClose, name, currentReasons, reasons, onConfirm, intl: { formatMessage }, loading = false, oneSelectionEnabled = false, labelResolver = null, references }) => {
  
  const [newReasons, setNewReasons] = useState([])
  const [addedReasons, setAddedReasons] = useState([])
  const [filter, setFilter] = useState('')
  const doConfirm = () => onConfirm(newReasons)
  const formattedReasons = buildReasons(reasons)
  const visibleReasons = [...(formattedReasons ? formattedReasons.filter(r => r ? r.reason.toLowerCase().indexOf(filter.toLowerCase()) > -1 : -1) : []), ...addedReasons]
  const showAddReason = filter.length > 0 && visibleReasons.length === 0
  useEffect(() => {
    setNewReasons(buildReasons(currentReasons))
  }, [currentReasons])
  
  const ready = !loading && reasons;
  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth='md'
    fullWidth
  >
    <DialogTitle>{formatMessage(messages.descartes.rejectDialog.title)}: {name}</DialogTitle>
    <DialogContent>
      {!ready ? <Grid item container justify="center"><CircularProgress /></Grid>
      :
      <React.Fragment>     
        <CheckboxGrid>
          {visibleReasons.map((reason, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxIcon checked />}
                  checked={newReasons.some(r => r.reason === reason.reason)}
                  disabled={(oneSelectionEnabled && newReasons.length && !newReasons.some(r => r.reason === reason.reason)) || isANotAssignedWorkingPlaceReason(reason.reason)}
                  onChange={() => {
                    !newReasons.some(r => r.reason === reason.reason)
                      ? setNewReasons([...newReasons, reason])
                      : setNewReasons(newReasons.filter(r => r.reason !== reason.reason))
                  }}
                />
              }
              label={labelResolver ? labelResolver(reason.reason) : reason.reason}
            />
          ))}
        </CheckboxGrid>
        {
          showAddReason && (
            <div>
              {formatMessage(messages.descartes.reasonSelect.notFound)} '
              <b>{filter}</b>'.
              <br />
              <br />
              {formatMessage(messages.descartes.reasonSelect.onFinish)}
            </div>
          )
        }
        {
          references && (
            <div style={{marginTop: '10px'}}>
              {references.map(reference => (<div key={reference}>{reference}</div>))}
            </div>
          )
        }
      </React.Fragment>
    }
    </DialogContent>
    <DialogActions>
      <PrimaryButton
        onClick={doConfirm}
        disabled={newReasons.length === 0}
      >
        {formatMessage(messages.button.confirm)}
      </PrimaryButton>
      <SecondaryButton
        type="text"
        onClick={onClose}
      >
        {formatMessage(messages.button.cancel)}
      </SecondaryButton>
    </DialogActions>
  </Dialog>
}

export default injectIntl(RejectCandidateDialog)