import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { trackEvent, actionsType } from '../../../pages/candidates/utils/tracks'
import messages from '../messages';
import NewInterview from '../../../v2/components/NewInterview';
import NewInterviewSummary from '../../../v2/components/NewInterviewSummary';
import PrimaryButton from '../../../v2/components/buttons/PrimaryButton';
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton';
import { isEmpty } from 'lodash';
import { notifyEvent } from '../../Hotjar';
import track from '../../../utils/track';

const styles = theme => ({
  dialog: {
    minHeight: '50%',
    overflowY: 'hidden'
  }
})

const Observer = ({ value, onChange }) => {
  useEffect(() => {
    if (value) {
      onChange(value)
    }
  }, [value])
  return null // component does not render anything
}

const dateFormatsMapper = {
  "es-MX": "dd/MM/yyyy",
  "es-AR": "dd/MM/yyyy",
  "en-US": "MM/dd/yyyy",
}

class InterviewDialog extends React.Component {
  state = {
    open: false,
    verificationMode: false,
    conditionsError: false,
    creatingHiringManager: false,
    hiringManager: {
      name: null,
      phoneNumber: null
    },
    hiringManagerError: false
  };

  styles = {
    hiringManager: {
      display: 'flex',
      width: '100%'
    },
    hiringManagerInput: {
      //marginLeft: '5px'
    },
    addHiringManager: {
      // paddingTop: '16px',
      display: 'flex',
      alignItems: 'center'
    }
  }

  componentDidMount() {
    if(this.props.candidateId) {
      trackEvent({ type: actionsType.openInvitation, data: { candidateId: this.props.candidateId } })
    }
  }

  handleClickOpen = () => {
    track({ type: 'CREATE_NEW_INTERVIEW_MODAL_OPEN' })
    this.setState({
      open: true,
      verificationMode: false,
      conditionsError: false,
      hiringManagerError: false
    });
  };

  handleClickNext = () => {
    const {
      checkConditions,
      conditions,
      showDate,
      showTime,
      showSize,
      showLocations,
      showConditions,
      includeConditions,
      hiringManager,
      requiredHiringManager
    } = this.props;
    if (!checkConditions) {
      this.setState({
        verificationMode: true,
        conditionsError: false,
        hiringManagerError: false
      });
      return
    }

    if (showSize && 
      (
        isEmpty(checkConditions.size) || 
        +checkConditions.size === 0 ||
        this.props.interviewSizeError
      )
    ) {
      console.log('size missing or oversized')
      this.setState({ conditionsError: true });
      return
    }

    if (showTime && this.props.interviewTimeError) {
      console.log('invalid date')
      return
    }

    if (showConditions) {
      const c = includeConditions && Array.isArray(includeConditions)
        ? conditions.filter(condition => includeConditions.includes(condition.question_key) || includeConditions.includes(condition.key))
        : conditions
      console.log('CCC', includeConditions, conditions, c)

      if (c && c.length !== checkConditions.conditions.filter(x => x).length ){
        console.log('length missmatch', c, c.length)
        this.setState({ conditionsError: true });
        return
      }

      if (c && c.length === checkConditions.conditions.length) {
        let elementLength = checkConditions.conditions.length;
        for (let i in checkConditions.conditions) {
          // if the array is [{}, empty, {}] it only iterates 2 times
          elementLength--
        }
        if (elementLength) {
          console.log('missingConditions', elementLength)
          this.setState({ conditionsError: true });
          return
        }
      }
    }
    if (showDate && (!checkConditions.date || !checkConditions.date.date || !checkConditions.date.time)) {
      console.log('date or time missing', showDate)
      this.setState({ conditionsError: true });
      return
    }

    if (showLocations && this.props.locations && this.props.locations.length > 1 && !checkConditions.location) {
      console.log("location missing")
      this.setState({ conditionsError: true });
      return
    }

    if(requiredHiringManager && (!hiringManager || !hiringManager.name )){
      console.log('hiring manager missing')
      this.setState(({hiringManagerError: true}))
      return
    }

    if (this.props.showSize && this.props.onVerify) {
      this.props.onVerify();
    }

    this.setState({
      verificationMode: true,
      conditionsError: false,
      hiringManagerError: false
    });
  };

  handleClickBack = () => {
    this.setState({ verificationMode: false });
  };

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      open: false,
      verificationMode: false,
      conditionsError: false,
      hiringManagerError: false,
      creatingHiringManager: false,
      hiringManager: {
        name: null,
        phoneNumber: null
      }
    });
  };

  handleCloseOk = () => {
    if (this.props.onCloseOk) {
      this.props.onCloseOk();
    }
    this.setState({
      open: false,
      verificationMode: false,
      conditionsError: false,
      hiringManagerError: false,
      creatingHiringManager: false,
      hiringManager: {
        name: null,
        phoneNumber: null
      }
    });
  };

  handleDateChange = (date) => {
    console.debug('handleDateChange', date)
    let mm = date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate();

    let newDate = [date.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');

    if (this.props.onDateChange) {
      this.props.onDateChange(newDate, date)
    }
  };

  handleTimeChange = (e, time) => {
    console.debug('handleTimeChange', time)
    if (this.props.onTimeChange) {
      this.props.onTimeChange(time, time)
    }
  }

  handleLocationChange = (location) => {
    console.debug('handleLocationChange', location)
    if (this.props.onLocationChange) {
      this.props.onLocationChange(location)
    }
  }

  handleHiringManagerChange = (hiringManager) => {
    console.debug('onHiringManagerChange', hiringManager)
    if (this.props.onHiringManagerChange) {
      this.props.onHiringManagerChange(hiringManager)
    }
  }

  handleConditionChange = (condition) => {
    console.debug('ConditionChange', condition)
    if (this.props.onConditionChange) {
      this.props.onConditionChange(condition)
    }
  }

  handleSizeChange = (size) => {
    if (this.props.onSizeChange) {
      this.props.onSizeChange(size)
    }
  }

  toggleNewHM = () => {
    this.setState({
      creatingHiringManager: !this.state.creatingHiringManager,
      hiringManager: this.state.creatingHiringManager ? this.state.hiringManager : {
        name: null,
        phoneNumber: null
      }
    })

  }

  handleHMPNameChange = (e) => {
    this.setState({
      hiringManager: {
        ...this.state.hiringManager,
        name: e.target.value
      }
    })
  }

  handleHMPhoneChange = (e) => {
    this.setState({
      hiringManager: {
        ...this.state.hiringManager,
        phoneNumber: e.target.value
      }
    })
  }

  handleSaveNewHM = (e) => {
    this.handleHiringManagerChange(this.state.hiringManager)
    this.setState({ creatingHiringManager: false })
  }

  isPhoneInvalid = (p, countryCode, hiringManagers, formatMessage) => {
    const normalize = phone => (phone || '').trim().replace('+', '').replace('-', '')
    try {
      if (!isValidPhoneNumber(normalize(p), countryCode)) {
        return formatMessage(messages.interview.invalidPhoneNumber)
      }
      if (hiringManagers.map(hm => normalize(hm.phoneNumber)).includes(normalize(p))){
        return formatMessage(messages.interview.repeatedPhoneNumber)
      }
      return null
    } catch (e) {
      return formatMessage(messages.interview.invalidPhoneNumber)
    }
  }

  newHiringManagerForm = (formatMessage, handleHMPNameChange, handleHMPhoneChange, toggleNewHM, handleSaveNewHM, isPhoneInvalid, hiringManagers) => {
    return <Grid item xs={12}>
      <Grid
        container
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography>{formatMessage(messages.interview.newHiringManager)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={this.styles.hiringManagerInput}
            id='hm-name'
            label={formatMessage(messages.interview.placeholder.hiringManagerName)}
            hint={formatMessage(messages.interview.placeholder.hiringManagerName)}
            floatingLabelText={formatMessage(messages.interview.placeholder.hiringManagerName)}
            value={this.state.hiringManager.name || ''}
            onChange={handleHMPNameChange}
            type='text'
            fullWidth
            variant='outlined'
            size='small'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={this.styles.hiringManagerInput}
            id='hm-phone'
            label={formatMessage(messages.interview.placeholder.hiringManagerPhone)}
            hint={formatMessage(messages.interview.placeholder.hiringManagerPhone)}
            floatingLabelText={formatMessage(messages.interview.placeholder.hiringManagerPhone)}
            value={this.state.hiringManager.phoneNumber || ''}
            onChange={handleHMPhoneChange}
            type='text'
            fullWidth
            error={this.state.hiringManager.phoneNumber && isPhoneInvalid(this.state.hiringManager.phoneNumber, this.props.country.code, hiringManagers, formatMessage)}
            helperText={this.state.hiringManager.phoneNumber ? isPhoneInvalid(this.state.hiringManager.phoneNumber, this.props.country.code, hiringManagers, formatMessage) : ''}
            variant='outlined'
            size='small'
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
          >
            <Button onClick={toggleNewHM}>
              <Icon className='material-icons'>close</Icon>
            </Button>
            <Button disabled={isPhoneInvalid(this.state.hiringManager.phoneNumber, this.props.country.code, hiringManagers, formatMessage) || !this.state.hiringManager.name || !this.state.hiringManager.phoneNumber} onClick={handleSaveNewHM}>
              <Icon className='material-icons'>done</Icon>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }


  render() {
    const {
      classes,
      interviewConfig: { minutesToCloseInterview },
      intl: { formatMessage },
      interviewTimeError,
      interviewSizeError,
      showButton,
      buttonText,
      titleText,
      showDate,
      showTime,
      showSize,
      showLocations,
      showConditions,
      includeConditions,
      showSlotAvailability,
      showSummaryMessage
    } = this.props;

    let content = null
    if (this.state.verificationMode) {
      console.debug('summary checkCOnditions', this.props.checkConditions.date)
      const conditions = []
      if (showDate) {
        conditions.push({
          label: formatMessage(messages.interview.placeholder.date),
          value: this.props.checkConditions.date.date
        })
      }
      if (showTime) {
        conditions.push({
          label: formatMessage(messages.interview.placeholder.time),
          value: this.props.checkConditions.date.finishTime ? (
            formatMessage(messages.interview.placeholder.individual.interval,
              {
                time: this.props.checkConditions.date.time,
                end: this.props.checkConditions.date.finishTime,
                duration: this.props.checkConditions.date.duration,
                candidates: this.props.checkConditions.size
              })
          ) : (
            this.props.checkConditions.date.time.startsWith('0') || this.props.checkConditions.date.time.startsWith('10') || this.props.checkConditions.date.time.startsWith('11') ? (
              formatMessage(messages.interview.time.morning, { time: this.props.checkConditions.date.time })
            ) : this.props.checkConditions.date.time
          )
        })
      }

      if (showSlotAvailability){
        conditions.unshift({
          label: formatMessage(messages.interview.placeholder.slotAvailability),
          value: this.props.slotAvailability == '-' ? <span>{formatMessage(messages.interview.placeholder.slotAvailabilityProcessing)} <CircularProgress style={{ width: '20px', height: '20px', marginLeft: '20px' }} /></span> : this.props.slotAvailability
        })
      }

      if (this.props.candidateName){
        conditions.unshift({
          label: formatMessage(messages.interview.placeholder.candidate),
          value: this.props.candidateName
        })
      }

      if (showLocations && this.props.checkConditions.location && this.props.checkConditions.location.name) {
        conditions.push({
          label: formatMessage(messages.interview.placeholder.interviewLocation),
          value: this.props.checkConditions.location.name
        })
      }

      if (showSize && this.props.checkConditions.size) {
        conditions.push({
          label: formatMessage(messages.interview.placeholder.size),
          value: this.props.checkConditions.size
        })
      }
      if (showConditions && this.props.checkConditions.conditions) {
        const c = includeConditions && Array.isArray(includeConditions)
          ? this.props.checkConditions.conditions.filter(condition => includeConditions.includes(condition.id))
          : this.props.checkConditions.conditions
        c.sort((a, b) => a.order < b.order ? -1 : 1)
        c.forEach(condition => conditions.push({
          label: condition.title,
          value: condition.name
        }))
      }

      if (this.props.hiringManager && (this.props.hiringManager.name || this.props.hiringManager.email)) {
        const { name, phoneNumber, email } = this.props.hiringManager
        const value =  `${name} (${phoneNumber || email})`
        conditions.push({
          label: formatMessage(messages.interview.placeholder.hiringManager),
          value
        })
      }

      content = <NewInterviewSummary
        title={titleText}
        conditions={conditions}
        message={showSummaryMessage && formatMessage(messages.interview.message.check)}
        submitButton={<PrimaryButton onClick={() => { this.handleCloseOk(); notifyEvent('hotjar_new_interview_save_button_clicked')}}>
          {formatMessage(messages.interview.button.save)}
        </PrimaryButton>}
        cancelButton={<SecondaryButton type='text' onClick={this.handleClickBack}>
          {formatMessage(messages.interview.button.modify)}
        </SecondaryButton>}
      />
    } else {
      const conditions = []
      if (showDate) {
        conditions.push({
          type: 'datetime',
          label: formatMessage(messages.interview.placeholder.date),
          value: this.props.checkConditions.date.originalDate || null,
          onChange: (newValue) => this.handleDateChange(newValue),
          metadata: {
            minValue: new Date(new Date().getTime() + minutesToCloseInterview * 60 * 1000),
            format: dateFormatsMapper[this.props.country.locale] || "dd/MM/yyyy"
          }
        })
      }
      if (showTime) {
        conditions.push({
          type: 'time',
          label: formatMessage(messages.interview.placeholder.time),
          value: this.props.checkConditions.date.originalTime || null,
          onChange: (newValue) => this.handleTimeChange(null, newValue),
          metadata: {
            error: interviewTimeError,
            errorMessage: interviewTimeError
          }
        })
      }

      if (showLocations && this.props.locations && this.props.locations.length > 0) {
        const value = this.props.checkConditions && this.props.checkConditions.location
          ? this.props.checkConditions.location
          : null
        conditions.push({
          type: 'autocomplete',
          label: formatMessage(messages.interview.placeholder.interviewLocation),
          value: value,
          onChange: (newValue) => this.handleLocationChange(newValue),
          metadata: {
            options: this.props.locations || [],
            errorMessage: this.props.locationError ? '*' : ''
          }
        })
      }

      if (showSize) {
        conditions.push({
          type: 'number',
          label: formatMessage(messages.interview.placeholder.size),
          value: this.props.checkConditions.size || '',
          metadata: { error: interviewSizeError, errorMessage: formatMessage(messages.interview.interviewSizeError) },
          onChange: (newValue) => this.handleSizeChange(newValue)
        })
      }

      if (showConditions && this.props.conditions && this.props.conditions.length > 0) {
        const c = includeConditions && Array.isArray(includeConditions)
          ? this.props.conditions.filter(condition => includeConditions.includes(condition.question_key) || includeConditions.includes(condition.key))
          : this.props.conditions
        c.sort((a, b) => a.order < b.order ? -1 : 1)
        c.forEach((condition) => {
          const value = this.props.checkConditions && this.props.checkConditions.conditions
            ? this.props.checkConditions.conditions.find(c => c && c.title === condition.title)
            : null
          if (condition.title === 'Vacante') { //TODO: improve: this does not scale
            if (!value && this.props.vacancyId) {
              const valueFromVacancyId = condition.options.find(x => x.value === this.props.vacancyId)
              if (valueFromVacancyId) {
                this.handleConditionChange(valueFromVacancyId)
              }
            }
            conditions.push({
              type: 'autocomplete',
              label: condition.title,
              value: value,
              onChange: (newValue) => this.handleConditionChange(newValue),
              metadata: {
                options: condition.options || [],
                errorMessage: condition.error ? '*' : ''
              },
              width: condition.width
            })
          } else {
            conditions.push({
              type: 'autocomplete',
              label: condition.title,
              value: value,
              onChange: (newValue) => this.handleConditionChange(newValue),
              metadata: {
                options: condition.options || [],
                errorMessage: condition.error ? '*' : ''
              },
              width: condition.width
            })
          }
        })
      }

      if (this.props.showHiringManagerOptions) {
        const shouldShowIcon = this.props.hiringManagers.find(({communicationChannel}) => communicationChannel === 'DESKTOP') && this.props.hiringManagers.find(({communicationChannel}) => communicationChannel === 'MESSAGE')
        if (shouldShowIcon){
         this.props.hiringManagers.forEach(hm => hm.icon = hm.communicationChannel === 'DESKTOP' ? <Icon>desktop_windows</Icon> : <Icon>sms_outlined</Icon>)
        }
        conditions.push({
          type: 'autocomplete',
          label: formatMessage(messages.interview.placeholder.hiringManager),
          value: this.props.hiringManager,
          onChange: (newValue) => this.handleHiringManagerChange(newValue),
          width: 12,
          metadata: {
            options: this.props.hiringManagers.sort((a, b) => (a.name > b.name) ? 1 : -1),
            onAdd: (this.props.showAddHiringManager ? () => this.toggleNewHM() : null),
            getOptionLabel: (option) => {
              if(option.email){
                return `${option.name} (${option.email})`
              }
              else if (option.phoneNumber){
                return `${option.name} (${option.phoneNumber})`
              }else{
                return option.name
              }
            },
            error: this.state.hiringManagerError
          }
        })
      }

      content = <NewInterview
        title={titleText}
        conditions={conditions}
        error={this.state.conditionsError
          ? `* ${formatMessage(messages.interview.dialog.error)}`
          : null
        }
        submitButton={<PrimaryButton onClick={this.handleClickNext}>
          {formatMessage(messages.interview.button.ok)}
        </PrimaryButton>}
        cancelButton={<SecondaryButton type='text' onClick={this.handleClose}>
          {formatMessage(messages.interview.button.cancel)}
        </SecondaryButton>}
        showHiringManagerComponent={this.state.creatingHiringManager}
        hiringManagerComponent={this.newHiringManagerForm(formatMessage, this.handleHMPNameChange, this.handleHMPhoneChange, this.toggleNewHM, this.handleSaveNewHM, this.isPhoneInvalid, this.props.hiringManagers ? this.props.hiringManagers : [])}
      />
    }
    return (
      <div style={{ paddingRight: '5px' }}>
        <Observer value={this.props.vacancyId} onChange={this.handleClickOpen} />
        {showButton && <PrimaryButton
          onClick={this.handleClickOpen}
          type="contained"
          style={{ height: '42px', padding: '8px 16px', borderRadius: '8px' }}
        >
          {buttonText}
        </PrimaryButton>
        }
        <Dialog
          open={!showButton || this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth='md'
          fullWidth
        >
          <DialogContent
            className={classes.dialog}
          >
            {this.props.loading ? (
              <Grid container justify='center'>
                <Grid item>
                  <Box padding='20px' >
                    <CircularProgress />
                  </Box>
                </Grid>
              </Grid>
            ) : content}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

InterviewDialog.propTypes = {
  checkConditions: PropTypes.object,
  locations: PropTypes.array,
  location: PropTypes.string,
  conditions: PropTypes.array,
  onClose: PropTypes.func,
  onCloseOk: PropTypes.func,
  onVerify: PropTypes.func,
  onDateChange: PropTypes.func,
  onTimeChange: PropTypes.func,
  onLocationChange: PropTypes.func,
  onSizeChange: PropTypes.func,
  onConditionChange: PropTypes.func,
  slotAvailability: PropTypes.string,
  classes: PropTypes.object.isRequired,
  hiringManagers: PropTypes.array,
  hiringManager: PropTypes.string,
  onHiringManagerChange: PropTypes.func,

  showHiringManagerOptions: PropTypes.bool,
  showAddHiringManager: PropTypes.bool,
  country: PropTypes.object,
  interviewConfig: PropTypes.object,
  locationId: PropTypes.object,
  showButton: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  titleText: PropTypes.string,
  showDate: PropTypes.bool.isRequired,
  showTime: PropTypes.bool.isRequired,
  showSize: PropTypes.bool.isRequired,
  showHiringManagerForm: PropTypes.bool.isRequired,
  showLocations: PropTypes.bool.isRequired,
  showConditions: PropTypes.bool.isRequired,
  includeConditions: PropTypes.array,
  showSlotAvailability: PropTypes.bool.isRequired,
  showSummaryMessage: PropTypes.bool.isRequired
};

export default injectIntl(withStyles(styles)(InterviewDialog));
