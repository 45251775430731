import {
  FEATURE_LINK_BUILDER,
  FEATURE_MANUALLY_ADD_CANDIDATE,
  REQUEST_FEATURE_CONFIG_SUCCEED,
} from './constants';

function reducer(state = {}, action) {
  switch (action.type) {
    case REQUEST_FEATURE_CONFIG_SUCCEED: {
      const {
        request: { featureName, params },
        data,
      } = action;
      // TODO: this strategy does not scale. This requires to add a switch case for any new feature
      switch (featureName) {
        case FEATURE_LINK_BUILDER: {
          const { subsidiaryId } = params;
          return {
            ...state,
            [featureName]: {
              ...state[featureName],
              [subsidiaryId]: data,
            },
          };
        }
        case FEATURE_MANUALLY_ADD_CANDIDATE: {
          const { stepId } = params;
          return {
            ...state,
            [featureName]: {
              ...state[featureName],
              [stepId]: data,
            },
          };
        }
        default: {
          return state;
        }
      }
    }
    default:
      return state;
  }
}

export default reducer;
