
import { defineMessages } from 'react-intl'
import moment from 'moment-timezone'
import emiMessages from '../components/Emi/messages'

const camelCaseKeys = require('camelcase-keys')

const DEFAULT_MESSAGES = defineMessages({
  WAITING_FOR_INTERVIEW: {
    id: 'app.subsidiary.listing.step.interview.state.WAITING_FOR_INTERVIEW',
    defaultMessage: 'Waiting interview in {locationName}'
  },
  WAITING_FOR_FEEDBACK: {
    id: 'app.subsidiary.listing.step.interview.state.WAITING_FOR_FEEDBACK',
    defaultMessage: 'Waiting HM feedback of {locationName}'
  },
  APPROVED: {
    id: 'app.subsidiary.listing.step.interview.state.APPROVED',
    defaultMessage: 'Approved in interview in {locationName}'
  },
  REJECTED: {
    id: 'app.subsidiary.listing.step.interview.state.REJECTED',
    defaultMessage: 'Rejected'
  },
  VALIDATE_INTEREST: {
    id: 'app.subsidiary.listing.step.interview.state.VALIDATE_INTEREST',
    defaultMessage: 'Validating interest'
  },
  NO_INTEREST: {
    id: 'app.subsidiary.listing.step.interview.state.NO_INTEREST',
    defaultMessage: 'Not interested'
  },
  NO_INTEREST_ANSWERED: {
    id: 'app.subsidiary.listing.step.interview.state.NO_INTEREST_ANSWERED',
    defaultMessage: 'Not interested (Refused to continue)'
  },
  NO_INTEREST_NO_ANSWER: {
    id: 'app.subsidiary.listing.step.interview.state.NO_INTEREST_NO_ANSWER',
    defaultMessage: 'Not interested (Did not answer)'
  },
  NO_INTEREST_UNREACHABLE: {
    id: 'app.subsidiary.listing.step.interview.state.NO_INTEREST_UNREACHABLE',
    defaultMessage: 'Not interested (Unreachable)'
  },
  WAITING_FOR_HM_CONFIRMATION: {
    id: 'app.subsidiary.listing.step.interview.state.WAITING_FOR_HM_CONFIRMATION',
    defaultMessage: 'Waiting HM confirmation in {locationName}'
  },
  WAITING_FOR_HM_AVAILABILITY: {
    id: 'app.subsidiary.listing.step.interview.state.WAITING_FOR_HM_AVAILABILITY',
    defaultMessage: 'Waiting HM availability in {locationName}'
  },
  WAITING_FOR_VACANCY: {
    id: 'app.subsidiary.listing.step.interview.state.WAITING_FOR_VACANCY',
    defaultMessage: 'Waiting vacancy'
  },
  WAITING_FOR_CANDIDATE_INTERVIEW_RESPONSE: {
    id: 'app.subsidiary.listing.step.interview.state.WAITING_FOR_CANDIDATE_INTERVIEW_RESPONSE',
    defaultMessage: 'Waiting candidate response'
  },
})

const MESSAGES_BY_SUBSIDIARY = {
  51: {
    WAITING_FOR_FEEDBACK: {
      id: 'app.subsidiary.51.listing.step.interview.state.WAITING_FOR_FEEDBACK',
      defaultMessage: 'Waiting HM feedback of {locationName} ({interviewDateTime})'
    }
  }
}

const MESSAGES_BY_LISTING = {
  // 1530: {
  //   WAITING_FOR_CANDIDATE_INTERVIEW_RESPONSE: {
  //     id: 'app.subsidiary.listing.1530.step.interview.state.WAITING_FOR_CANDIDATE_INTERVIEW_RESPONSE',
  //     defaultMessage: 'Waiting candidate response -> LISTING 1530'
  //   }
  // }
}

/**
 * Setup custom messages for subsidiary
 * eg: subsidiary 71
 * 
 * const messages = defineMessages({
 *   READY: {
 *     id: 'app.subsidiary.71.listing.step.interview.state.READY',
 *     defaultMessage: 'READY message for subsidiary 71'
 *   }
 * })
 * 
 * Then create a message with id "app.subsidiary.71.listing.step.interview.state.READY" at es.json file
 */

/**
 * Setup custom messages for listing
 * eg: listing 1530
 * 
 * const messages = defineMessages({
 *   READY: {
 *     id: 'app.subsidiary.listing.1530.step.interview.state.READY',
 *     defaultMessage: 'READY message for listing 1530'
 *   }
 * })
 * 
 * Then create a message with id "app.subsidiary.listing.1530.step.interview.state.READ" at es.json file
 */

const mergeMessages = (...args) => args.reduce((data, item) =>  ({ ...data, ...item }), {})

const getMessages = (subsidiaryId, listingId) => mergeMessages(DEFAULT_MESSAGES, MESSAGES_BY_SUBSIDIARY[subsidiaryId], MESSAGES_BY_LISTING[listingId])

export const resolveInterviewState = (state, { formatMessage }, { subsidiaryId, listingId }) => {
  if (!state || Array.isArray(state) || !(state instanceof Object) || !state.type) {
    return null
  }

  const messages = getMessages(subsidiaryId, listingId)
  const message = messages[state.type]
  if (!message) {
    return null
  }

  const formattedMessage = formatMessage(message, camelCaseKeys(state, { deep: true }))

  if (state.type === 'WAITING_FOR_INTERVIEW') {
    const { interviewTime, timezone } = state

    if (!!interviewTime && !!timezone) {
      const formattedInterviewTime = moment.utc(interviewTime).tz(timezone).format(formatMessage(emiMessages.format.datetime))
      return `${formattedMessage} (${formattedInterviewTime})`
    }
  }

  console.log("## INTERVIEW STATUS", formattedMessage)
  return formattedMessage
}
