import React, { useCallback, useMemo } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const DateCondition = ({ label, value, onChange, metadata, onlyDate }) => {
  const currentValue = useMemo(() => {
    if (onlyDate && value) {
      let newValue = value
      if (newValue.length > 10) {
        newValue = newValue.substring(0, 10)
      }
      return `${newValue}T12:00:00.000Z`
    } else {
      return value
    }
  }, [value, onlyDate]);
  const handleChange = useCallback((value) => {
    let newValue = value;
    if (onlyDate && value) {
      const day = value.getDate();
      const month = value.getMonth() + 1;
      const year = value.getFullYear();
      newValue = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }
    return onChange(newValue)
  }, [onChange, onlyDate])
  const handleBlur = useCallback(() => {
    if (value) {
      const dateValue = new Date(value)

      if(isNaN(dateValue)) {
        onChange(null)
      }
    }
  }, [onChange, onlyDate, value])
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        variant="inline"
        inputVariant="outlined"
        disableToolbar
        format={(metadata && metadata.format) || 'MM/dd/yyyy'}
        id="date-picker-inline"
        data-testid="datePicker"
        label={label}
        value={currentValue}
        onChange={handleChange}
        onBlur={handleBlur}
        minDate={metadata && metadata.minValue}
        maxDate={metadata && metadata.maxValue}
        size="small"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        error={metadata && metadata.error}
        disabled={metadata && metadata.disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateCondition;
