import React, { useState, useContext, useCallback } from 'react';
import { connect } from 'react-redux';

import { candidateChanged, hideChangeVacancy } from '../../../../store/candidateActions/actions';
import { updateAssignedVacancy } from '../../../../utils/connector';

import { ActionsContext } from '../ActionsContext';
import { useFetchCandidate, useFetchVacancies } from '../../../../v2/hooks';
import { Dialog } from './Dialog';

export const ChangeVacancy = ({ close, notify }) => {
  const actionsContext = useContext(ActionsContext);
  const { subsidiaryId, candidateId, listingId } = actionsContext.state;
  const candidate = useFetchCandidate(candidateId, listingId);
  const [partialSearch, setPartialSearch] = useState(null);
  const [vacancies, isLoadingVacancies] = useFetchVacancies(subsidiaryId, listingId, partialSearch);
  const [processing, setProcessing] = useState(null);

  const onSucceed = useCallback(vacancyId => {
    setProcessing(true);
    const update = async () => {
      try {
        if (!candidate) {
          throw Error('Candidate not found');
        }
        const { candidate_id: candidateId, listing_id: listingId} = candidate;
        await updateAssignedVacancy(candidateId, listingId, vacancyId);
        notify();
      } catch (e) {
        console.error(`Error updating assigned vacancy ${candidateId}`);
      }
      close();
      setProcessing(null);
    }
    update();
  }, [candidate, listingId])

  return (
    candidate &&
    <Dialog
      show
      loading={processing}
      onClose={close}
      onInputChange={setPartialSearch}
      onSucceed={onSucceed}
      candidate={candidate}
      vacancies={vacancies}
      isLoadingVacancies={isLoadingVacancies}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(hideChangeVacancy()),
  notify: () => dispatch(candidateChanged())
});

export default connect(null, mapDispatchToProps)(ChangeVacancy);
