export const API_SERVER =
  process.env.API_SERVER || 'https://cbo.emilabs.ai/api/v1';
export const DESCARTES_SERVER =
  process.env.DESCARTES_SERVER || 'https://descartes.holaemi.com';
export const WEBHOOK_SERVER =
  process.env.WEBHOOK_SERVER ||
  'http://emi-motion-staging.us-east-1.elasticbeanstalk.com';
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE';
export const METRICS_METABASE_SITE_URL =
  process.env.METRICS_METABASE_SITE_URL || 'https://metabase.emilabs.ai';
export const GOOGLE_MAPS_API_KEY =
  process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDmoYGllIsMUFo9EtJP11aIy-dM4-yt3fQ';
export const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  'http://fa3c2c403fc942d69cd5f7a0ab9c05d7@sentry-20.emilabs.io/14';
export const COGNITO_LOGIN_URL = 'https://login.emilabs.ai';
export const TRACKING_ENABLED = true;
