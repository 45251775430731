import React from 'react'
import { connect } from 'react-redux'
import { hideHelpAndSupport } from '../../../store/candidateActions/actions'
import HelpAndSupportActionDialog from '../HelpAndSupportActionDialog'

const HelpAndSupportAction = ({ store: { show }, close }) => {
  return <HelpAndSupportActionDialog
    open={show}
    onClose={close}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').others.helpAndSupport
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideHelpAndSupport())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpAndSupportAction)
