

export const GET_ANALYTICS = 'app/EmiAnalytics/GET_ANALYTICS'
export const GET_ANALYTICS_SUCCESS = 'app/EmiAnalytics/GET_ANALYTICS_SUCCESS'
export const GET_ANALYTICS_ERROR = 'app/EmiAnalytics/GET_ANALYTICS_ERROR'

export const GET_ANALYTICS_DASHBOARDS = 'app/EmiAnalytics/GET_ANALYTICS_DASHBOARDS'
export const GET_ANALYTICS_DASHBOARDS_SUCCESS = 'app/EmiAnalytics/GET_ANALYTICS_DASHBOARDS_SUCCESS'
export const GET_ANALYTICS_DASHBOARDS_ERROR = 'app/EmiAnalytics/GET_ANALYTICS_DASHBOARDS_ERROR'

export const GET_ANALYTICS_MAP_CANDIDATES = 'app/EmiAnalytics/GET_ANALYTICS_MAP_CANDIDATES'
export const GET_ANALYTICS_MAP_CANDIDATES_SUCCESS = 'app/EmiAnalytics/GET_ANALYTICS_MAP_CANDIDATES_SUCCESS'
export const GET_ANALYTICS_MAP_CANDIDATES_ERROR = 'app/EmiAnalytics/GET_ANALYTICS_MAP_CANDIDATES_ERROR'

export const GET_CANDIDATES_LISTING_DATA_AGGREGATION = 'app/EmiAnalytics/GET_CANDIDATES_LISTING_DATA_AGGREGATION'
export const GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS = 'app/EmiAnalytics/GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS'
export const GET_CANDIDATES_LISTING_DATA_AGGREGATION_ERROR = 'app/EmiAnalytics/GET_CANDIDATES_LISTING_DATA_AGGREGATION_ERROR'
