import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    emiGrey1: "#F7F9FF",
    emiGrey2: "#383838",
    emiRed1: "#BE342E",
    emiRed2: "#FF5947",
    emiBlue1: "#173898",
    emiBlue2: "#BECFFF",
    emiBlue3: "#0B1C4C",
    emiBlue4: "#1E4BCB",
    emiBlue5: "#265EFE",
    emiBlue6: "#2786D5",
    emiBlue7: "#D2DBFF",
    emiBlue8: "#677ACB",
    emiBlue9: "#F7F9FF",
    emiViolet1: "#5E33C5",
  },
});
export default createTheme(theme, {
  custom: {
    navigation: {
      appBar: {
        height: theme.spacing(7),
        toggleIcon: {
          color: theme.palette.emiBlue6,
        },
        workspaceLabel: {
          color: theme.palette.emiBlue3,
        },
        workspaceDivider: {
          color: theme.palette.emiBlue2,
        },
        userName: {
          color: theme.palette.emiBlue5,
        },
        profileIcon: {
          color: theme.palette.emiRed2,
        },
        profileMenu: {
          backgroundColor: theme.palette.emiBlue9,
          labelColor: theme.palette.emiGrey2,
          iconColor: theme.palette.emiViolet1,
        },
      },
      menu: {
        width: theme.spacing(34),
        backgroundColor: theme.palette.emiGrey1,
        label: {
          color: theme.palette.emiBlue4,
          selected: {
            backgroundColor: "#FFFFFF",
          },
        },
        icon: {
          selectedColor: theme.palette.emiRed1,
          defaultColor: theme.palette.emiBlue1,
        },
        sectionIcon: {
          expandLess: {
            color: theme.palette.emiBlue4,
            backgroundColor: theme.palette.emiBlue7,
          },
          expandMore: {
            color: "#FFFFFF",
            backgroundColor: theme.palette.emiBlue8,
          },
        },
      },
    },
  },
});