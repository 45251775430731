import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import notFoundImg from '../../../v2/assets/not-found.svg';
import { injectIntl, defineMessages } from 'react-intl';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  flex-direction: column;
`

const StyledImg = styled.img`
  height: 155px;
  margin-bottom: 16px;
`;

const StyledTitle = styled.div`
  width: 500px;
  text-align: center;
  margin-bottom: 16px;
  font-family: Lato;
  font-weight: 700;
  font-size: 20px;
  color: #1049BC;
`;

const StyledSubTitle = styled.div`
  width: 370px;  
  text-align: center;
  margin-bottom: 16px;
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  color: #000000;
`;

const StyledButton = styled(Button)`
  && {
    color: #FFFFFF;
    font-size: 14px;
    font-family: Lato;
    font-weight: 600;

    background-color: #3179FF;
    padding: 16px 24px;
    border-radius: 8px;
    text-transform: none;
    &:hover {
      background-color: #FFFFFF;
      color: #3179FF;
      border: 1px solid #3179FF;
    }
  }
`

const messages = defineMessages({
  title: {
    id: "pageFallback.title",
    defaultMessage: "Oops! This link is not available"
  },
  subtitle: {
    id: "pageFallback.subtitle",
    defaultMessage: "We're working to bring you the best tools to manage your process. As soon as access is available, we'll let you know."
  },
  button: {
    id: "pageFallback.button",
    defaultMessage: "Return Home"
  },
})

export default injectIntl(({ intl: { formatMessage }}) => (
  <StyledContainer>
    <StyledImg src={notFoundImg} />
    <StyledTitle>{formatMessage(messages.title)}</StyledTitle>
    <StyledSubTitle>{formatMessage(messages.subtitle)}</StyledSubTitle>
    <StyledButton href="/">{formatMessage(messages.button)}</StyledButton>
  </StyledContainer>
))