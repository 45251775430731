import React from 'react';
import { withErrorBoundary } from '@sentry/react';
import { ErrorPage } from '../../v2/components/pages/ErrorPage';
import { FormattedMessage } from 'react-intl';

const messages = {
  title: {
    id: 'app.page.Error.title',
    defaultMessage: 'Something went wrong',
  },
  subtitle: {
    id: 'app.page.Error.subtitle',
    defaultMessage: 'Try to reload the site to be able to continue using the platform',
  },
  button: {
    id: 'app.page.Error.button',
    defaultMessage: 'Reload site',
  },
};

const Fallback = () => (
  <ErrorPage
    title={<FormattedMessage {...messages.title} />}
    subtitle={<FormattedMessage {...messages.subtitle} />}
    buttonText={<FormattedMessage {...messages.button} />}
  />
);

export const withErrorPage = component =>
  withErrorBoundary(component, { fallback: Fallback });
