/*
 *
 * LanguageProvider actions
 *
 */
import {
  CHANGE_LOCALE
} from './constants'

const COUNTRIES_TO_LANGUAGE = {
  mx: 'es',
  ar: 'es',
  us: 'en'
}

const parseTag = (tag) => {
  if (typeof tag !== 'string' || tag.length < 2) {
    return null
  }
  const [language, country] = tag.split('-')
  return { language, country }
}

export function changeLocaleByUser (user) {
  const { locale, isEmployee, subsidiary } = user
  console.log('CHANGING LOCALE BY USER', user)
  if (isEmployee && locale) {
    const parsed = parseTag(locale)
    if (parsed) {
      const { language, country } = parsed
      const lang = country && Object.keys(COUNTRIES_TO_LANGUAGE).includes(country.toLocaleLowerCase())
        ? COUNTRIES_TO_LANGUAGE[country.toLocaleLowerCase()]
        : language && Object.values(COUNTRIES_TO_LANGUAGE).includes(language.toLocaleLowerCase())
        ? language.toLocaleLowerCase()
        : null
      console.log('CHANGING LOCALE BY USER', locale, lang)
      if (lang){
        return {
          type: CHANGE_LOCALE,
          locale: lang
        }
      }
    }
  }
  if (subsidiary) {
    const { country } = subsidiary
    const countryCode = country && country.code ? country.code : 'AR'
    return {
      type: CHANGE_LOCALE,
      locale: COUNTRIES_TO_LANGUAGE[countryCode.toLowerCase()]
    }
  }
  console.log('YOU SHOULD NOT BE HERE', user)
  // This avoids changing selected locale and keeps default one
  return {
    type: 'INVALID'
  }
}
