/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
 import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  GET_SUBSIDIARIES,
  CREATE_SUBSIDIARY,
  REMOVE_SUBSIDIARY,
  EDIT_SUBSIDIARY,
  GET_SUBSIDIARY_LOCATIONS
} from './constants';

import {
  getSubsidiariesSuccess,
  getSubsidiariesError,
  removeSubsidiarySuccess,
  removeSubsidiaryError,
  createSubsidiarySuccess,
  createSubsidiaryError,
  editSubsidiarySuccess,
  editSubsidiaryError,
  getSubsidiaryLocationsSuccess,
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sSubsidiaries() {
  const requestURL = `${root}/subsidiaries`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getSubsidiariesSuccess(result));

  } catch (err) {
    yield put(getSubsidiariesError(err));
  }
}

export function* getSubsidiariesApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_SUBSIDIARIES, sSubsidiaries);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sRemoveSubsidiary(action) {
  const requestURL = `${root}/subsidiaries/`+action.subsidiary.id;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log(result)
    yield put(removeSubsidiarySuccess(action.subsidiary));

  } catch (err) {
    yield put(removeSubsidiaryError(err));
  }
}

export function* removeSubsidiaryApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(REMOVE_SUBSIDIARY, sRemoveSubsidiary);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCreateSubsidiary(action) {
  const requestURL = `${root}/subsidiaries`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.subsidiary),
    });
    yield put(createSubsidiarySuccess(result));
    yield put(push('/subsidiaries'));
  } catch (err) {
    yield put(createSubsidiaryError(err));
  }
}

export function* createSubsidiaryApi() {
  const watcher = yield takeLatest(CREATE_SUBSIDIARY, sCreateSubsidiary);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sEditSubsidiary(action) {
  try {
    console.log('sEditSubsidiary', action)
    const requestURL = `${root}/subsidiaries/`+action.subsidiary.id;
    console.log(requestURL)

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.subsidiary),
    });
    yield put(editSubsidiarySuccess(result));
    yield put(push('/subsidiaries'));
  } catch (err) {
    yield put(editSubsidiaryError(err));
  }
}

export function* editSubsidiaryApi() {
  const watcher = yield takeLatest(EDIT_SUBSIDIARY, sEditSubsidiary);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sGetSubsidiaryLocations({ listingId, subsidiaryId }) {
  const reqUrl = `${root}/subsidiaries/${subsidiaryId}/locations/short?listing_id=${listingId}`
  try {
    const result = yield call(request, reqUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log('in sagas GET_SUBSIDIARY_LOCATIONS', result)
    yield put(getSubsidiaryLocationsSuccess({ locations: result }));

  } catch (err) {
    yield put(getInterviewSlotsError(err));
  }
}

export function* getSubsidiaryLocationsApi() {
  yield takeLatest(GET_SUBSIDIARY_LOCATIONS, sGetSubsidiaryLocations)
}



// All sagas to be loaded
export default [
  getSubsidiaryLocationsApi,
  getSubsidiariesApi,
  removeSubsidiaryApi,
  createSubsidiaryApi,
  editSubsidiaryApi
];
