/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  GET_PENDING_ACTIONS,
  GET_PENDING_ACTIONS_SUCCESS,
  GET_PENDING_ACTIONS_ERROR,
  GET_CATEGORY_INFO_START,
  GET_CATEGORY_INFO_ERROR,
  GET_CATEGORY_INFO_SUCCESS,
} from './constants'

export function setLoading (loading) {
  return {
    type: LOADING,
    loading
  }
}

export function getPendingActions (config) {
  return {
    type: GET_PENDING_ACTIONS,
    config
  }
}

export function getPendingActionsSuccess (type, actions) {
  return {
    type: GET_PENDING_ACTIONS_SUCCESS,
    payload: {
      type,
      actions
    }
  }
}

export function getPendingActionsError (type, error) {
  return {
    type: GET_PENDING_ACTIONS_ERROR,
    payload: {
      type,
      error
    }
  }
}

export function startCategory (category, pending) {
  return {
    type: GET_CATEGORY_INFO_START,
    category,
    pending
  }
}

export function categoryTypeSucceed (category, type, actions) {
  return {
    type: GET_CATEGORY_INFO_SUCCESS,
    category,
    payload: {
      type,
      actions
    }
  }
}

export function categoryTypeFailed (category, type, error) {
  return {
    type: GET_CATEGORY_INFO_ERROR,
    category,
    payload: {
      type,
      error
    }
  }
}