import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '16px 24px 8px',
    borderRadius: '16px'
  }
}));

const ErrorIcon = styled(CancelIcon)`
  color: #FF5947;
  margin: auto;
  width: 32px!important;
  height: 32px!important;
`

const StyledTitle = styled.span`
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600!important;
  padding: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const CloseButton = styled.button`
  border-radius: 5px;
  background: #E5E7EC;
  cursor: pointer;
  padding: 4px 8px;
  box-sizing: border-box;
`

const ErrorTitle = styled.p`
  color: #FF5947;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`
  
const ErrorMessage = styled.p`
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`

const ErrorCode = styled.div`
  color: #6F6F6F;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 24px;
`

const IconClose = styled(CloseIcon)`
  color: #1049BC;
  width: 20px;
  height: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
`

export const ErrorDialog = ({ onClose = () => {}, title, errorTitle, errorMessage, errorCode }) => {
  const classes = useStyles();
  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.paper }}>
      <Header>
        <StyledTitle>{title}</StyledTitle>
        <CloseButton onClick={onClose}>
          <IconClose />
        </CloseButton>
      </Header>
      <ErrorIcon />
      <Box>
        <ErrorTitle>{errorTitle}</ErrorTitle>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <ErrorCode>ID: {errorCode}</ErrorCode>
      </Box>
    </Dialog>
  );
}
