import Cookie from 'js-cookie';

const getCognitoUser = () => {
  const value = Cookie.get('user')
  return value !== undefined ? JSON.parse(value) : null
}
const getCognitoSession = () => {
  const idToken = Cookie.get('idToken')
  const accessToken = Cookie.get('accessToken')
  const refreshToken = Cookie.get('refreshToken')

  if (accessToken && refreshToken) {
    return {
      idToken, accessToken, refreshToken
    }
  }
  console.log('null')
  return null
}

const initialState = {
  cognitoUser: getCognitoUser(),
  session: getCognitoSession()
};

export default initialState;


