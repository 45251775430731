/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_ERROR,
  GET_ANALYTICS_MAP_CANDIDATES_SUCCESS,
  GET_ANALYTICS_MAP_CANDIDATES_ERROR,
  GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS,
  GET_CANDIDATES_LISTING_DATA_AGGREGATION_ERROR,
  GET_ANALYTICS_DASHBOARDS_SUCCESS,
  GET_ANALYTICS_DASHBOARDS_ERROR,
} from './constants';

import { decodeEscapeSequence } from '../../utils/emilib'

const initialState = fromJS({
  analytics: {},
  loading: false,
});



function emiAnalyticsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ANALYTICS_SUCCESS:{
      console.log('GET_ANALYTICS_SUCCESS', action.data)
      const stateAnalytics = Object.assign({}, state.get('analytics'));
      const data = action.data
      const { listingId, stepId, dashboard, token } = data
      if(listingId && stepId && dashboard){
        if(!stateAnalytics[listingId]){
          stateAnalytics[listingId] = {}
        }
        if(!stateAnalytics[listingId][stepId]){
          stateAnalytics[listingId][stepId] = {}
        }
        if(!stateAnalytics[listingId][stepId][dashboard]){
          stateAnalytics[listingId][stepId][dashboard] = {}
        }

        stateAnalytics[listingId][stepId][dashboard].token = token
      }
        console.log('stateAnalytics', stateAnalytics)
      return state.set('analytics', stateAnalytics);
    }
    case GET_ANALYTICS_ERROR: {
      console.log("GET_ANALYTICS_ERROR", action.error)
      return state;
    }

    case GET_ANALYTICS_DASHBOARDS_SUCCESS:{
      console.log('GET_ANALYTICS_DASHBOARDS_SUCCESS', action.data)
      const analyticsDashboardsState = Object.assign({}, state.get('analyticsDashboards'));
      const data = action.data
      const { listingId, analyticDashboards } = data
      const stepId = -1 // applied to all steps, dashboard, token
      analyticDashboards.map(dashboard => {
        console.log('looking for dashboard', dashboard)
        if(listingId && stepId && dashboard.id){
          if(!analyticsDashboardsState[listingId]){
            analyticsDashboardsState[listingId] = {}
          }
          if(!analyticsDashboardsState[listingId][stepId]){
            analyticsDashboardsState[listingId][stepId] = {}
          }
          if(!analyticsDashboardsState[listingId][stepId][dashboard.id]){
            analyticsDashboardsState[listingId][stepId][dashboard.id] = {}
          }

          analyticsDashboardsState[listingId][stepId][dashboard.id].token = dashboard.token
          analyticsDashboardsState[listingId][stepId][dashboard.id].name = dashboard.name
          analyticsDashboardsState[listingId][stepId][dashboard.id].id = dashboard.id
          analyticsDashboardsState[listingId][stepId][dashboard.id].order = dashboard.order
        }

      })
      console.log('analyticsDashboards', analyticsDashboardsState)
      return state.set('analyticsDashboards', analyticsDashboardsState);
    }
    case GET_ANALYTICS_DASHBOARDS_ERROR: {
      console.log("GET_ANALYTICS_DASHBOARDS_ERROR", action.error)
      return state;
    }

    case GET_ANALYTICS_MAP_CANDIDATES_SUCCESS:{
      console.log('GET_ANALYTICS_MAP_CANDIDATES_SUCCESS', action.data)
      const stateAnalytics = Object.assign({}, state.get('analytics'));
      if(action.data.listingId && action.data.stepId){
        if(!stateAnalytics.mapData){
          stateAnalytics.mapData = {[action.data.listingId]: {[action.data.stepId]: {}}}
        }
        if(stateAnalytics.mapData && ! stateAnalytics.mapData[action.data.listingId]){
          stateAnalytics.mapData[action.data.listingId] = {[action.data.stepId]: {}}
        }
        if(stateAnalytics.mapData && ! stateAnalytics.mapData[action.data.listingId][action.data.stepId]){
          stateAnalytics.mapData[action.data.listingId][action.data.stepId] = {}
        }

        action.data.candidates.map((candidate, idx) => {
          if (candidate.geo_coords){
            let geoCoords = candidate.geo_coords
            try{
              geoCoords = decodeEscapeSequence(geoCoords)
              geoCoords = geoCoords.replace(/"/g, "'")
              geoCoords = geoCoords.replace(/: u'/g, ': "')
              geoCoords = geoCoords.replace(/':/g, '":')
              geoCoords = geoCoords.replace(/, '/g, ', "')
              geoCoords = geoCoords.replace(/{'/g, '{"')
              geoCoords = geoCoords.replace(/'}/g, '"}')
              geoCoords = geoCoords.replace(/": u"/g, '": "')
              geoCoords = geoCoords.split(', "address"')[0]+"}"
              candidate.geo_coords = JSON.parse(geoCoords)
            } catch(error) {
              geoCoords = candidate.geo_coords;
              geoCoords = decodeEscapeSequence(geoCoords);
              geoCoords = geoCoords.split(', "address"').length > 1 ? geoCoords.split(', "address"')[0]+"}" : geoCoords
              candidate.geo_coords = JSON.parse(geoCoords)
              // expected output: ReferenceError: nonExistentFunction is not defined
              // Note - error messages will vary depending on browser
            }
            candidate.working_place_id = candidate.working_place_id.replace(',(?!.*?,)|,,', '').split(',')
            candidate.working_place_id = candidate.working_place_id.filter(wp => wp && wp!='-').map( wp => parseInt(wp))


          }
        })
        stateAnalytics.mapData[action.data.listingId][action.data.stepId].candidates = action.data.candidates
        stateAnalytics.mapData[action.data.listingId][action.data.stepId].subsidiaryLocations = action.data.subsidiaryLocations
      }
      console.log('stateAnalytics GET_ANALYTICS_MAP_CANDIDATES_SUCCESS', stateAnalytics)
      return state.set('analytics', stateAnalytics);
    }
    case GET_ANALYTICS_MAP_CANDIDATES_ERROR: {
      console.log("GET_ANALYTICS_MAP_CANDIDATES_ERROR", action.error)
      return state;
    }

    case GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS:{
      console.log('GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS', action.data.listingId, action.data.stepId, action.data)
      const stateAnalytics = Object.assign({}, state.get('analytics'));
      if(action.data.listingId && action.data.stepId){
        if(!stateAnalytics.aggregate){
          stateAnalytics.aggregate = {[action.data.listingId]: {[action.data.stepId]: {}}}
        }
        if(stateAnalytics.aggregate && ! stateAnalytics.aggregate[action.data.listingId]){
          stateAnalytics.aggregate[action.data.listingId] = {[action.data.stepId]: {}}
        }
        if(stateAnalytics.aggregate && ! stateAnalytics.aggregate[action.data.listingId][action.data.stepId]){
          stateAnalytics.aggregate[action.data.listingId][action.data.stepId] = {}
        }
        if(stateAnalytics.aggregate && ! stateAnalytics.aggregate[action.data.listingId][action.data.stepId][action.data.aggregate]){
          stateAnalytics.aggregate[action.data.listingId][action.data.stepId][action.data.aggregate] = {}
        }

        const groups = action.data.results.reduce((groups, item) => ({
          ...groups,
          [item.working_place_type_title]: [...(groups[item.working_place_type_title] || []), item]
        }), {});

        for (const [key, value] of Object.entries(groups)) {
          stateAnalytics.aggregate[action.data.listingId][action.data.stepId][action.data.aggregate][key] = value
        }
      }
      console.log('stateAnalytics GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS', stateAnalytics)
      return state.set('analytics', stateAnalytics);
    }
    case GET_CANDIDATES_LISTING_DATA_AGGREGATION_ERROR: {
      console.log("GET_CANDIDATES_LISTING_DATA_AGGREGATION_ERROR", action.error)
      return state;
    }

    default:{
      return state;
    }
  }
}

export default emiAnalyticsReducer;
