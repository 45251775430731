import React, { useCallback, useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { captureException } from '../../../utils/sentry';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrimaryButton from '../../../v2/components/buttons/PrimaryButton';
import styled from 'styled-components';
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton';
import messages from '../messages';
import emiMessages from '../../Emi/messages';
import { Grid, TextField, Typography, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { executeValidator } from '../../../utils/validators';
import moment from 'moment';
import AutoCompleteCondition from '../../../v2/components/others/AutoCompleteCondition';
import Publish from '@material-ui/icons/Publish';
import GetApp from '@material-ui/icons/GetApp';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { ApiResourcesContext } from './ApiResources';
import { isNil, toString, isEmpty } from 'lodash';
import { List } from "react-virtualized";
import { AddressField } from '../../../v2/components/addressField/AddressField';
import Box from '@mui/material/Box';

const onlyAllowNumbers = (value) => value.replace(/[^0-9]/g, '')
const ignoreNumbers = (value) => value.replace(/[0-9]/g, '')

const parseDate = (value) => moment(value, 'YYYY-MM-DD')
const isValidDate = (value) => !!value && !!value.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) && parseDate(value).isValid()
const getCurrentDate = () => moment().startOf('day')

const VALIDATION_STATUS = {
  OK: 'OK',
  ERROR: 'ERROR',
  WARN: 'WARN',
};

const extractDateOrNull = (value) => {
  if (['yesterday', 'today', 'tomorrow'].includes(value)) {
    if (value === 'yesterday') {
      return getCurrentDate().add(-1, 'day').format('YYYY-MM-DD');
    }
    if (value === 'today') {
      return getCurrentDate().format('YYYY-MM-DD');
    }
    if (value === 'tomorrow') {
      return getCurrentDate().add(1, 'day').format('YYYY-MM-DD');
    }
    return null;
  }
  if (value && !!value.match(/[+|-][0-9]+[y|M|w|d]/g)) {
    const unit = value.slice(-1)
    const amount = +value.substring(0, value.length - 1)
    return getCurrentDate().add(amount, unit).format('YYYY-MM-DD')
  }
  if (isValidDate(value)) {
    return value;
  }
  return null;
}

const useStyles = value => {
  const color = acquireValidationColor(value);
  return makeStyles(() => ({
    root: {
      '& .MuiFormHelperText-root': { color },
      '& .MuiFormLabel-root.Mui-error': { color },
      '& .MuiFormLabel-asterisk.Mui-error': { color },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': { borderColor: color },
    },
  }));
}

const acquireValidationColor = status => {
  switch (status) {
    case VALIDATION_STATUS.ERROR:
      return '#f44336';
    case VALIDATION_STATUS.WARN:
      return '#f9a825';
    default:
      return 'black';
  }
};

const ValidationField = (props) => {
  const { validation: { status, message } } = props;
  const classes = useStyles(status)();
  return <TextField
    {...props}
    error={status !== VALIDATION_STATUS.OK}
    helperText={props.helperText || message}
    className={classes.root}
  />
}

const StringField = ({ value, defaultValue, required, label, maxLength, minLength, onChange, validation, disabled, ignore, helperText }) => {
  const [customHelperText, setHelperText] = useState(helperText)
  useEffect(() => setHelperText([validation.message, helperText].filter(x => !!x).join('. ')), [validation, customHelperText])
  const handleChange = useCallback((e) => {
    let executeChange = onChange
    if (ignore && ignore.length > 0) {
      for (let index = 0; index < ignore.length; index++) {
        const ignoreRule = ignore[index];
        switch (ignoreRule) {
          case 'numbers':
            executeChange = (value) => onChange(ignoreNumbers(value))
            break;
          default:
            break;
        }
      }
    }
    executeChange(e.target.value)
  })
  useEffect(() => {
    if (disabled) {
      onChange('')
    }
  }, [disabled])
  return <ValidationField
    value={value}
    label={label}
    defaultValue={defaultValue}
    variant="outlined"
    size="small"
    fullWidth
    inputProps={{ maxLength, minLength }}
    onChange={handleChange}
    required={required}
    disabled={disabled}
    validation={validation}
    helperText={customHelperText}
  />
}

const FileSelector = ({ id, onFileSelected, children, acceptedTypes }) => {
  const selectFile = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      onFileSelected(files[0]);
      e.target.value = null
    }
  };

  return (
    <label htmlFor={id}>
      <input
        style={{ display: 'none' }}
        id={id}
        name={id}
        type="file"
        accept={acceptedTypes.join(',')}
        onChange={selectFile}
      />
      {children}
    </label>
  );
}

const FileActions = ({ id, onFileSelected, onClearSelection, onDownload, allowClear, allowDownload, allowUpload, acceptedFileTypes }) => {
  return <React.Fragment>
    {
      allowClear && (
        <IconButton size='small' onClick={onClearSelection} color='secondary'>
          <Delete />
        </IconButton>
      )
    }
    {
      allowDownload && (
        <IconButton size='small' onClick={onDownload} color='primary'>
          <GetApp />
        </IconButton>
      )
    }
    {
      allowUpload && (
        <FileSelector id={id} onFileSelected={onFileSelected} styles={{ padding: 0 }} acceptedTypes={acceptedFileTypes}>
          <IconButton component='span' size='small' color='primary'>
            <Publish />
          </IconButton>
        </FileSelector>
      )
    }
  </React.Fragment>
}

const FileField = ({
  value,
  required,
  label,
  onChange,
  validation,
  disabled,
  name,
  acceptedTypes,
  functions,
  showAcceptedTypesMessage = true
}) => {
  const handleChange = useCallback((file) => {
    if (file && file.name) {
      onChange(file)
    } else {
      onChange(null)
    }
  }, [])
  const handleClearSelection = useCallback(() => onChange(null), [])
  useEffect(() => {
    if (disabled) {
      onChange(null)
    }
  }, [disabled])

  // We should update this to use downloadBlobFile util to standarize how we download files
  const downloadFile = useCallback((blob, filename) => {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }, [])

  const handleDownload = useCallback(async () => {
    if (value instanceof File) {
      downloadFile(value, value.name)
    } else {
      try {
        const blob = await functions.downloadCandidateFile(name)
        downloadFile(blob, value.split('/').pop())
      } catch (e) {
        console.error(`Error downloading file ${value}`, e)
      }
    }
  }, [value, functions])
  const [acceptedTypesMessage, setAcceptedTypesMessage] = useState('')
  const [helperText, setHelperText] = useState('')
  useEffect(() => {
    const acceptedTypesSet = acceptedTypes && Array.isArray(acceptedTypes) && acceptedTypes.length > 0
    const message = acceptedTypesSet && showAcceptedTypesMessage ? `Archivos permitidos: ${acceptedTypes.join(', ')}` : ''

    setAcceptedTypesMessage(message)
  }, [showAcceptedTypesMessage])
  useEffect(() => setHelperText(disabled ? null : [validation.message, acceptedTypesMessage].filter(x => !!x).join('. ')), [validation, acceptedTypesMessage, disabled])

  return <ValidationField
    label={label}
    value={disabled ? '' : value ? 'Archivo cargado' : ''}
    variant="outlined"
    size="small"
    fullWidth
    required={required}
    disabled={disabled}
    helperText={helperText}
    InputProps={{
      endAdornment: !disabled &&
        <FileActions
          id={name}
          onFileSelected={handleChange}
          onClearSelection={handleClearSelection}
          onDownload={handleDownload}
          allowClear={!!value}
          allowDownload={!!value}
          allowUpload={!!!value}
          acceptedFileTypes={acceptedTypes}
        />
      ,
      sx: { padding: 0 }
    }}
    inputProps={{ readOnly: true }}
    validation={validation}
  />
}

const NumberField = (props) => {
  const { onChange } = props
  const handleChange = useCallback(value => onChange(onlyAllowNumbers(value)))
  return <StringField
    {...props}
    onChange={handleChange}
  />
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 56;
  return (
    <Box sx={{height: 'auto'}} ref={ref} {...other}>
      <List
        height={250}
        width={445}
        rowHeight={itemSize}
        overscanCount={5}
        rowCount={itemCount}
        rowRenderer={props => {
          return React.cloneElement(children[props.index], {
            style: props.style
          });
        }}
        role={role}
      />
    </Box>
  );
});

const ListField = ({ defaultValue, required, label, onChange, options, disabled, dependsOn, refValue, validation }) => {
  /**
   * This type comparison is needed to support both numbers and
   * strings for manually_add_job_position_id and manually_add_working_place_id.
   * The need for this is required in this ticket: https://emilabs.atlassian.net/browse/TIM-2196
   */
  const [value, setValue] = useState(() => options.find(x => {
    if (typeof x.value === 'string') {
      if (!!defaultValue) return x.value === defaultValue.toString()
      return false
    }
    if (typeof x.value === 'number') {
      if (!!defaultValue) return x.value === JSON.parse(defaultValue)
      return false
    }
  }))
  const handleChange = useCallback((event, newValue) => {
    setValue(newValue)
    onChange(newValue ? newValue.value : null, newValue)
  })
  useEffect(() => {
    if (disabled) {
      handleChange(null, null)
    }
  }, [disabled])
  const [filteredOptions, setFilteredOptions] = useState([])
  useEffect(() => {
    if (!dependsOn) {
      setFilteredOptions(options)
    } else {
      setFilteredOptions(options.filter(x => x.ref === refValue))
    }
  }, [options, dependsOn, refValue])

  return <Autocomplete
    options={filteredOptions}
    getOptionLabel={(option) => option.label}
    disableListWrap
    freeSolo
    ListboxComponent={ListboxComponent}
    renderInput={(props) => <ValidationField {...props} size='small' variant="outlined" required={required} label={label} validation={validation} disabled={disabled} />}
    renderOption={(option) => {
      return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <p style={{ marginLeft: '5px' }}>{option.label}</p>
      </div>
    }}
    onChange={handleChange}
    value={!disabled ? value : null}
    noOptionsText="Entrada no válida"
    disabled={disabled}
  />
}

const BooleanField = (props) => {
  const { defaultValue } = props
  const booleanDefaultValue = typeof defaultValue == "boolean" ? defaultValue.toString() : (defaultValue || '')
  return <ListField {...props} defaultValue={booleanDefaultValue} options={[{ label: "Si", value: "true" }, { label: "No", value: "false" }]} />
}

const DateField = ({ value, defaultValue, required, label, onChange, validation, minValue, maxValue, disabled }) => {
  const handleChange = useCallback((e) => onChange(e.target.value))
  useEffect(() => {
    if (disabled) {
      onChange('')
    }
  }, [disabled])
  const extraProps = {}
  if (minValue || maxValue) {
    extraProps.inputProps = {}
    if (minValue) {
      extraProps.inputProps.min = extractDateOrNull(minValue)
    }
    if (maxValue) {
      extraProps.inputProps.max = extractDateOrNull(maxValue)
    }
  }
  return <ValidationField
    label={label}
    value={value}
    defaultValue={defaultValue}
    variant="outlined"
    size="small"
    fullWidth
    type='date'
    onChange={handleChange}
    required={required}
    InputLabelProps={{ shrink: true, required }}
    validation={validation}
    disabled={disabled}
    {...extraProps}
  />
}

const TimeField = ({ value, defaultValue, required, label, onChange, validation, disabled }) => {
  const handleChange = useCallback((e) => onChange(e.target.value))
  useEffect(() => {
    if (disabled) {
      onChange('')
    }
  }, [disabled])
  return <ValidationField
    label={label}
    value={value}
    defaultValue={defaultValue}
    variant="outlined"
    size="small"
    fullWidth
    type='time'
    onChange={handleChange}
    required={required}
    InputLabelProps={{ shrink: true, required }}
    validation={validation}
    disabled={disabled}
  />
}

const Switch = (props) => {
  const { condition, children } = props
  const selectedChild = children.find(element => {
    if (Array.isArray(element.props.condition)) {
      return element.props.condition.includes(condition)
    } else {
      return element.props.condition === condition
    }
  })
  if (selectedChild) {
    return selectedChild
  }
  return children.find(element => {
    return !!element.props.default
  })
}

const Case = ({ children }) => children

const resolveCondition = (formData, ref, condition, operator) => {
  const isEmpty = () => {
    return formData[ref] === undefined ||
      formData[ref] === null ||
      formData[ref] === [] ||
      formData[ref] === ''
  };

  switch (operator) {
    case 'not_equal':
      return formData[ref] !== condition;
    case 'is_empty':
      return isEmpty() === condition;
    case 'is_not_empty':
      return !isEmpty() === condition;
    case 'i_equal': // case insensitive equal
      return String(formData[ref]).toLowerCase() === String(condition).toLowerCase()
    case 'equal':
    default:
      return formData[ref] === condition;
  }
};

const evaluateConditions = (formData, param) => {
  if (!param) {
    return false;
  }
  if (typeof param === 'boolean') {
    return param;
  }

  if (
    param.conditions &&
    Array.isArray(param.conditions) &&
    param.conditions.length > 0
  ) {
    const conditionResults = param.conditions.map(({
      ref,
      condition,
      operator,
    }) => resolveCondition(formData, ref, condition, operator));
    switch (param.operator) {
      case 'and':
        return conditionResults.every(x => !!x);
      case 'or':
      default:
        return conditionResults.some(x => !!x);
    }
  }
  return false
};

const isDisabled = (formData, { disabled }) => evaluateConditions(formData, disabled)

const isRequired = (formData, { required }) => evaluateConditions(formData, required)

const isShowable = (formData, { show = true }) => evaluateConditions(formData, show)

const evaluateDependency = (formData, { dependsOn }) => {
  if (!dependsOn) {
    return {}
  }
  const { ref } = dependsOn
  if (isNullOrEmpty(ref)) {
    return { disabled: true, refValue: null }
  }
  return { disabled: isNullOrEmpty(formData[ref]), refValue: formData[ref] }
}

const isNullOrEmpty = value => value === undefined || value === null || value === ''

const validateField = (value, required, field, formData, formatMessage) => {
  if (isNullOrEmpty(value)) {
    if (required) {
      return {
        status: VALIDATION_STATUS.ERROR,
        message: formatMessage(messages.validation.requiredField),
      }
    }
    return {
      status: VALIDATION_STATUS.OK,
      message: null
    }
  }

  if (field) {
    const { type, validators, dependsOn } = field
    if (type === 'list') {
      if (!dependsOn && !field.options.some(x => x.value === value)) {
        return {
          status: VALIDATION_STATUS.ERROR,
          message: `Opción no disponible: ${value}`
        }
      }
      if (dependsOn && dependsOn.ref && !field.options.filter(x => x.ref === formData[dependsOn.ref]).some(x => x.value === value)) {
        return {
          status: VALIDATION_STATUS.ERROR,
          message: `Opción no disponible: ${value}`
        }
      }
    }
    if (type === 'string' || type === 'number') {
      const { minLength, maxLength } = field
      if (minLength && maxLength && minLength === maxLength && value.length !== maxLength) {
        return {
          status: VALIDATION_STATUS.ERROR,
          message: formatMessage(messages.validation.numberOfCharacters, { maxLength })
        }
      }
      if (minLength && value.length < minLength) {
        return {
          status: VALIDATION_STATUS.ERROR,
          message: formatMessage(messages.validation.minimumNumberOfCharacters, { minLength })
        }
      }
      if (maxLength && value.length > maxLength) {
        return {
          status: VALIDATION_STATUS.ERROR,
          message: formatMessage(messages.validation.maximumNumberOfCharacters, { maxLength })
        }
      }
    }
    if (type === 'date') {
      if (!isValidDate(value)) {
        return {
          status: VALIDATION_STATUS.ERROR,
          message: `Formato inválido: ${value}`
        }
      }
      if (field.minValue) {
        const extractedMinValue = extractDateOrNull(field.minValue)
        if (!!extractedMinValue && parseDate(value).isBefore(parseDate(extractedMinValue))) {
          return {
            status: VALIDATION_STATUS.ERROR,
            message: `Fuera de rango. Fecha mínima: ${extractedMinValue}`
          }
        }
      }
      if (field.maxValue) {
        const extractedMaxValue = extractDateOrNull(field.maxValue)
        if (!!extractedMaxValue && parseDate(value).isAfter(parseDate(extractedMaxValue))) {
          return {
            status: VALIDATION_STATUS.ERROR,
            message: `Fuera de rango. Fecha máxima: ${extractedMaxValue}`
          }
        }
      }
    }
    if (validators && Array.isArray(validators) && validators.length > 0) {
      for (const { name, errorMessage, ref, strict } of validators) {
        const refValue = ref ? formData[ref] : null
        if (!executeValidator(name, value, refValue)) {
          return {
            status: strict === false
              ? VALIDATION_STATUS.WARN
              : VALIDATION_STATUS.ERROR,
            message: errorMessage
          }
        }
      }
    }
  }

  return {
    status: VALIDATION_STATUS.OK,
    message: null
  }
}

const isValueDefined = (key, object) => {
  return key && object && !isEmpty(object) && !isNil(object[key])
}

const Form = ({ fields, onChange, defaultValues, vacancies, functions, context, formatMessage }) => {
  const getBaseValue = (name, secondaryName, defaultValue = null) => {
    if (isValueDefined(name, defaultValues)) {
      return defaultValues[name]
    }
    if (isValueDefined(secondaryName, defaultValues)) {
      return defaultValues[secondaryName]
    }
    
    return defaultValue
  }

  const getDefaultValue = (name, secondaryName) => getBaseValue(name, secondaryName, "")

  const [formData, setFormData] = useState(() => {
    const data = fields.reduce(
      (result, field) => ({
        ...result,
        [field.name]: getDefaultValue(field.name, field.secondaryName),
      }),
      {},
    )

    if (fields.some(x => x.type === 'vacancies')) {
      const vacanciesFields = fields.filter(x => x.type === 'vacancies');
      for (const field of vacanciesFields) {
        if (field.resource) {
          const { name, resource: { fetch, extract } } = field;
          if (!!fetch && extract && extract.length > 0) {
            const vacancyId = data[name];
            const vacancy = context[name];
            if (vacancyId && vacancy && vacancyId === vacancy.id && vacancy.status !== 'FULFILLED') {
              extract.forEach(x => {
                data[`${name}.${x}`] = vacancy[x] || null;
              });
            } else {
              data[name] = null;
              extract.forEach(x => {
                data[`${name}.${x}`] = null;
              });
            }
          }
        }
      }
    }
    return data;
  });

  const resourcesContext = useContext(ApiResourcesContext)

  useEffect(() => {
    onChange(formData)

    console.log('### formData change', formData)
  }, [formData]);

  const triggerLargeFieldValidation = async (field, defaultValue) => {
    const options = await resourcesContext.listResourcesForField(field).map(({ id, name }) => ({ label: name, value: id }));
    if (options) {
      const selectedOption = options.find((option) => option.value === defaultValue);
      const transform = (option) => option ? { id: option.value, name: option.label } : null
      const updatedFields = await resourcesContext.extractResourceFields(field, transform(selectedOption))
      setFormData(prevData => {
        const areSameFields = Object.keys(updatedFields).every(key => (
          updatedFields[key] && prevData[key] && prevData[key] === updatedFields[key]
        ))

        if (areSameFields) {
          return prevData
        }

        const updatedData = {
          ...prevData,
          ...updatedFields
        }
        resourcesContext.handleResourceFieldUpdated(field, updatedData)
        return updatedData
      });
    }
  }

  useEffect(() => {
    // This hack is intended to revalidate oxxo comisionista on form load
    // https://emilabs.atlassian.net/browse/IM-3081
    const oxxoComisionistaField = fields.find((field) => {
      const { name } = field;
      return (
        name === "manually_assigned_working_place_id" &&
        formData["manually_assigned_working_place_id"] &&
        typeof formData["manually_assigned_working_place_id"] === 'number' &&
        !formData["location_operation"]
      )
    })
    if (!!oxxoComisionistaField) {
      const defaultValue = getDefaultValue(oxxoComisionistaField.name, oxxoComisionistaField.secondaryName)
      triggerLargeFieldValidation(oxxoComisionistaField, defaultValue);
    }
  }, [])

  const getApiResourceLargeOptions = (field) => {
    const options = resourcesContext.listResourcesForField(field)
    const filteredOptions = options.filter((item) => item.id && item.name).map(({ id, name }) => ({ label: name, value: id }))
    
    if (filteredOptions.length !== options.length) {
      const regularOptions = options.map(({ id, name }) => ({ label: name, value: id }))
      const error = new Error('Edit data candidate action options contains items with undefined or null values')
      captureException(error, { feature: 'CANDIDATE_ACTIONS' }, { filteredOptions, regularOptions })
    }

    return filteredOptions
  }

  return (
    <Grid container spacing={2}>
      {fields.map((field, index) => {
        const showable = isShowable(formData, field)
        if (!showable) {
          return null;
        }
        const { name, type, secondaryName } = field;
        const defaultValue = getDefaultValue(name, secondaryName)

        const required = isRequired(formData, field)
        const disabled = isDisabled(formData, field)
        const validation = validateField(formData[name], required, field, formData, formatMessage)
        const isValid = validation.status === VALIDATION_STATUS.OK;
        const dependencyResult = evaluateDependency(formData, field);
        const errorMessage = !isValid ? validation.message : null;

        return (
          <Grid key={index} item sm={6}>
            <Switch condition={type}>
              <Case condition='string' default>
                <StringField
                  value={formData[name]}
                  defaultValue={defaultValue}
                  onChange={(newValue) =>
                    setFormData((formData) => ({ ...formData, [name]: newValue }))
                  }
                  {...field}
                  required={required}
                  disabled={disabled}
                  {...dependencyResult}
                  validation={validation}
                />
              </Case>
              <Case condition='number'>
                <NumberField
                  value={formData[name]}
                  defaultValue={defaultValue}
                  onChange={(newValue) =>
                    setFormData((formData) => ({ ...formData, [name]: newValue }))
                  }
                  {...field}
                  required={required}
                  disabled={disabled}
                  {...dependencyResult}
                  validation={validation}
                />
              </Case>
              <Case condition='list'>
                <ListField
                  defaultValue={defaultValue}
                  onChange={(newValue) =>
                    setFormData((formData) => ({ ...formData, [name]: newValue }))
                  }
                  {...field}
                  required={required}
                  disabled={disabled}
                  {...dependencyResult}
                  validation={validation}
                />
              </Case>
              <Case condition='date'>
                <DateField
                  value={formData[name]}
                  defaultValue={defaultValue}
                  onChange={(newValue) =>
                    setFormData((formData) => ({ ...formData, [name]: newValue }))
                  }
                  {...field}
                  required={required}
                  disabled={disabled}
                  {...dependencyResult}
                  validation={validation}
                />
              </Case>
              <Case condition='time'>
                <TimeField
                  value={formData[name]}
                  defaultValue={defaultValue}
                  onChange={(newValue) =>
                    setFormData((formData) => ({ ...formData, [name]: newValue }))
                  }
                  {...field}
                  required={required}
                  disabled={disabled}
                  {...dependencyResult}
                  validation={validation}
                />
              </Case>
              <Case condition='boolean'>
                <BooleanField
                  defaultValue={defaultValue}
                  onChange={(newValue) =>
                    setFormData((formData) => ({ ...formData, [name]: newValue }))
                  }
                  {...field}
                  required={required}
                  errorMessage={errorMessage}
                  disabled={disabled}
                  {...dependencyResult}
                  validation={validation}
                />
              </Case>
              <Case condition={['googlePlace', 'address']}>
                <AddressField
                  state={{
                    value: formData[name],
                    required,
                    disabled,
                    validation,
                  }}
                  definition={{
                    placeholder: field.label,
                    fontSize: '12px',
                  }}
                  setFormData={setFormData}
                  {...field}
                />
              </Case>
              <Case condition='vacancies'>
                <AutoCompleteCondition
                  {...field}
                  value={formData[name]}
                  onChange={async (newValue) => {
                    const vacancyId = newValue && newValue.id ? newValue.id : ''
                    let updateData = { [name]: vacancyId }
                    if (field.resource) {
                      const { fetch, extract } = field.resource;
                      if (!!fetch && extract && extract.length > 0) {
                        if (vacancyId) {
                          const vacancy = await functions.getVacancy(vacancyId);
                          extract.forEach(x => {
                            updateData = { ...updateData, [`${name}.${x}`]: vacancy[x] };
                          });
                        } else {
                          extract.forEach(x => {
                            updateData = { ...updateData, [`${name}.${x}`]: null };
                          });
                        }
                      }
                    }
                    setFormData((formData) => ({ ...formData, ...updateData }))
                  }
                  }
                  metadata={{
                    options: vacancies || [],
                    getOptionLabel: (option) => `${option.id}-${option.positionName}-${option.locationName}`,
                    getValueItem: (value) => {
                      if (!vacancies || !value) {
                        return null
                      }
                      return vacancies.find(l => l.id == value)
                    },
                    error: !isValid
                  }}
                  required={required}
                  errorMessage={errorMessage}
                  disabled={disabled}
                />
              </Case>
              <Case condition="file">
                <FileField
                  value={formData[name]}
                  onChange={(newValue) =>
                    setFormData((formData) => ({ ...formData, [name]: newValue }))
                  }
                  {...field}
                  required={required}
                  disabled={disabled}
                  {...dependencyResult}
                  functions={functions}
                  validation={validation}
                />
              </Case>
              <Case condition='api-resource'>
                <AutoCompleteCondition
                  {...field}
                  value={formData[name]}
                  onChange={async selectedOption => {
                    const updatedFields = await resourcesContext.extractResourceFields(field, selectedOption)

                    setFormData(prevData => {
                      const updatedData = {
                        ...prevData,
                        ...updatedFields
                      }

                      resourcesContext.handleResourceFieldUpdated(field, updatedData)
                      return updatedData
                    })

                  }}
                  metadata={{
                    options: resourcesContext.listResourcesForField(field),
                    getValueItem: (value) => {
                      if (!value) {
                        return null
                      }

                      return resourcesContext
                        .listResourcesForField(field)
                        .find(op => toString(op.id) === toString(value))
                    },
                    error: !isValid
                  }}
                  required={required}
                  errorMessage={errorMessage}
                  disabled={disabled || !resourcesContext.resourcesForFieldAreReady(field)}
                />
              </Case>
              <Case condition='api-resource-large'>
                <ListField
                  defaultValue={defaultValue}
                  onChange={async (_, selectedOption) => {
                    if (!selectedOption) {
                      setFormData(prevData => {
                        const updatedData = {
                          ...prevData,
                          [field.name]: null,
                        }

                        return updatedData
                      });

                      return null;
                    }

                    const transform = (option) => option ? { id: option.value, name: option.label } : null
                    const updatedFields = await resourcesContext.extractResourceFields(field, transform(selectedOption))

                    setFormData(prevData => {
                      const updatedData = {
                        ...prevData,
                        ...updatedFields
                      }

                      resourcesContext.handleResourceFieldUpdated(field, updatedData)
                      return updatedData
                    })
                  }}
                  {...field}
                  required={required}
                  disabled={disabled || !resourcesContext.resourcesForFieldAreReady(field)}
                  {...dependencyResult}
                  validation={validation}
                  options={getApiResourceLargeOptions(field)}
                />
              </Case>
              <Case condition='api-resource-2'>
                <ListField
                  defaultValue={defaultValue}
                  onChange={async (_, selectedOption) => {
                    if (!selectedOption) {
                      setFormData(prevData => {
                        const updatedData = {
                          ...prevData,
                          [field.name]: null,
                        }

                        return updatedData
                      });

                      return null;
                    }

                    const transform = (option) => option ? { id: option.value, name: option.label } : null
                    const updatedFields = await resourcesContext.extractResourceFields(field, transform(selectedOption))

                    setFormData(prevData => {
                      const updatedData = {
                        ...prevData,
                        ...updatedFields
                      }

                      resourcesContext.handleResourceFieldUpdated(field, updatedData)
                      return updatedData
                    })
                  }}
                  {...field}
                  required={required}
                  disabled={disabled || !resourcesContext.resourcesForFieldAreReady(field)}
                  {...dependencyResult}
                  validation={validation}
                  options={resourcesContext.listResourcesForField(field).map(({ id, name }) => ({ label: name, value: id }))}
                />
              </Case>
            </Switch>
          </Grid>
        );
      })}
    </Grid>
  );
};

const StyledGridAlert = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.625rem; 
  border: 1px solid #DCAF0E; 
  background: rgba(255, 199, 0, 0.1);
  color: #000;
  font-size: 0.875rem;
  line-height: 1.3;
  font-family: Lato, sans-serif;
`

const StyledWarningRoundedIcon = styled(WarningRoundedIcon)`
  color: #DCAF0E;
  margin-right: 12px;
`

const EditCandidateDataDialog = ({
  open,
  onConfirm,
  onClose,
  intl: { formatMessage },
  loading,
  candidate,
  config,
  vacancies,
  functions,
  context
}) => {
  const [candidateData, setCandidateData] = useState({});

  const [confirmationButtonDisabled, setConfirmationButtonDisabled] = useState(true)

  const [showAlertMessage, setShowAlertMessage] = useState(false)

  const doConfirm = () => {
    const staticData = {}
    if (config && config.staticFields) {
      config.staticFields.forEach(({ name, value }) => {
        staticData[name] = value
      })
    }
    const mergedData = { ...staticData, ...candidateData }
    const filteredMergedData = Object.keys(mergedData).reduce(
      (result, key) => {
        if (mergedData[key] !== candidate[key]) {
          return { ...result, [key]: mergedData[key] }
        }
        return result
      }, {}
    )
    onConfirm(filteredMergedData, config.actions)
  };

  useEffect(() => {
    const requiredKeys = ((config && config.fields) || []).filter(field => isRequired(candidateData, field)).map(field => field.name)
    const isValid = ((config && config.fields) || []).map(field => {
      if (!isShowable(candidateData, field)) {
        return true;
      }
      const { status } = validateField(candidateData[field.name], requiredKeys.includes(field.name), field, candidateData, formatMessage);
      return status === VALIDATION_STATUS.OK || status === VALIDATION_STATUS.WARN;
    }).every(x => x)
    setConfirmationButtonDisabled(!isValid)
    if (config && config.alert && config.alert.show) {
      const conditions = config.alert.show.conditions.map((c) => ({ ...c, condition: c.type === "dynamic" ? candidate[c.ref] : c.condition }))
      const show = evaluateConditions(candidateData, { conditions, operator: config.alert.show.operator })
      setShowAlertMessage(show)
    }
  }, [candidateData])

  const { isInitializing: apiResourcesLoading } = useContext(ApiResourcesContext)

  const isFormLoading = loading || apiResourcesLoading

  const content = isFormLoading ? (
    <Grid item container justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Form
      fields={(config && config.fields) || []}
      onChange={setCandidateData}
      defaultValues={candidate}
      vacancies={vacancies}
      functions={functions}
      context={context}
      formatMessage={formatMessage}
    />
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll='body' disableBackdropClick>
      <DialogTitle>
        {config && (config.title || formatMessage(messages.editCandidateData.title))}
      </DialogTitle>
      {config && config.description && <DialogContent><Typography variant='subtitle1'>{config.description}</Typography></DialogContent>}
      {config && config.subDescription && <DialogContent><Typography variant='subtitle1'>{config.subDescription}</Typography></DialogContent>}
      {
        config && config.alert && showAlertMessage && <DialogContent>
          <StyledGridAlert item xs={12}>
            <StyledWarningRoundedIcon />
            {config.alert.label}
          </StyledGridAlert>
        </DialogContent>
      }
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <PrimaryButton onClick={doConfirm} disabled={confirmationButtonDisabled || isFormLoading}>
          {formatMessage(emiMessages.button.confirm)}
        </PrimaryButton>
        <SecondaryButton type="text" onClick={onClose}>
          {formatMessage(emiMessages.button.close)}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(EditCandidateDataDialog);
