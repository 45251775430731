import Data from '../data';

class mockMenuApi {
  static getMenu(user) {
    let menus = [];
    const allMenus = Data.menus
    let openViews = [];
    let selectedMenuItem = null;
    let selectedOpenedMenuItem = null;
    let index = 0;

    for (let m in allMenus) {
      const roles = allMenus[m].roles;
      let skipMenu = false;

      for (let a in user.roles) {
        for (let t in roles) {
          if (user.roles[a] === roles[t]) {
            allMenus[m].index = index;
            menus.push(allMenus[m]);
            skipMenu = true;
            break;
          }
        }
        if (skipMenu) break;
      }
      index++;
    }

    // Menu children authorities. REVIEW code!
    for (let m in menus) {
      if (menus[m].children && menus[m].children.length) {
        let children = [];
        for (let c in menus[m].children) {
          let roles = menus[m].children[c].roles;
          let skipMenu = false;
          for (let t in roles) {
            for (let a in user.roles) {
              if (user.roles[a] === roles[t]) {
                children.push(menus[m].children[c]);
                skipMenu = true;
                break;
              }
            }
          }
          if (skipMenu) break;
        }
        menus[m].children = children;
      }
    }

    if (
      Object.prototype.toString.call(menus) === '[object Array]' &&
      menus.length > 0
    ) {
      selectedMenuItem = menus[0];
      openViews = menus.slice(0, 1);
      selectedOpenedMenuItem = openViews[0];
    } else {
      menus = [];
    }

    const restrictMenu = menus.filter(({ textId }) => (
      user.authorizedSections.includes(textId)
    ));

    return {
      menus: restrictMenu,
      openViews,
      selectedMenuItem,
      selectedOpenedMenuItem,
    };
  }
}

export default mockMenuApi;
