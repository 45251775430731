import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedRelative} from 'react-intl';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import SearchInput from '../../Emi/Input'

import messages from '../messages';

const styles = theme => ({
  root: {
    padding: "10px",
    marginTop: "20px"
    // marginTop: theme.spacing.unit * 3,
  },
  list: {
    width: 500,
  }
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

class CandidateView extends React.Component {
  state = {
  }

  componentWillReceiveProps(newProps){

  }
  onHideCV = (caca) => {
    if (this.props.onHideCV){
      this.props.onHideCV()
    };
  }

  render () {
    const { classes } = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <Drawer anchor="right" open={this.props.show} onClose={() => this.onHideCV()}>
        <div
          onKeyDown={() => this.onHideCV()}
          className={classes.list}
          role="presentation"
        >
        {this.props.children}
      </div>
      </Drawer>
    );
  }

}

CandidateView.propTypes = {
  onHideCV: PropTypes.func,
  show: PropTypes.bool,
  
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(CandidateView));
