import React from 'react'
import { connect } from 'react-redux'
import { candidateChanged, hideInviteToInterview } from '../../../store/candidateActions/actions'
import InviteToInterviewDialog from '../InviteToInterviewDialog'

const InviteToInterviewAction = ({ store: { show, data }, close, notify }) => {
  const { candidateId, candidateName, listingId, stepId, withExperimentalLayout, withDecentralizedLayout } = data || {}
  if (!show) {
    return null
  }
  const onSucceed = () => {
    notify()
    close()
  }
  // TODO: add to this experiment conditions:
  // - check if the current url corresponds with candidate view
  const experimental_config = listingId === 1884 && stepId === 4168 && data.config || listingId === 1833 && stepId === 4072 && data.config ? data.config : null
  return <InviteToInterviewDialog
    candidateId={candidateId}
    candidateName={candidateName}
    listingId={listingId}
    stepId={stepId}
    onClose={close}
    onSucceed={onSucceed}
    withExperimentalLayout={withExperimentalLayout}
    withDecentralizedLayout={withDecentralizedLayout}
    experimental_config={experimental_config}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').interview.invite
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideInviteToInterview()),
    notify: () => dispatch(candidateChanged())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteToInterviewAction)
