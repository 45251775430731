import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import Divider from '@material-ui/core/Divider';
import * as candidateActions from '../actions';
import * as appActions from '../../App/actions';
import * as listingActions from '../../Listings/actions';
import {makeSelectCandidates} from '../selectors';
import { makeSelectGlobal } from '../../../containers/App/selectors';
import {makeSelectListingPage} from '../../../containers/Listings/selectors';
import { injectIntl } from 'react-intl'
import messages from '../messages'
import CandidateSection from '../../../components/Candidate/View/section'
import PagePdf from '../../../components/Candidate/View/pagePdf'
import { PDFDownloadLink, Document, StyleSheet } from '@react-pdf/renderer'

class CandidateShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      candidate: {},
      loading: true,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.candidate != this.props.candidate) {
      this.setState({
        candidate: newProps.candidate
      })
    }
  }

  render() {

    if (!this.props.candidate) {
      return null
    }

    const {formatMessage} = this.props.intl;
    const basicData = ['birthdate','document','age','nationality','rfc', 'candidate_age', 'email', 'gender', 'nickname', 'residence', 'telephone', 'name_logic']
    const exclude = ['ua_id','channel_user_id', 'score', 'reason']
    const oxxoSection = [
      'candidate_code',
      'assigned_hiring_manager_name',
      'assigned_hiring_manager_role',
      'working_place',
      'manually_assigned_working_place',
      'manually_assigned_job_position_id',
      'can_work_legally',
      'marital_status',
      'has_dependants',
      'has_housekeeper',
      'first_job',
      'working_place_commuting_time',
      'working_place_transport_count',
      'motivations',
      'motivations_other',
      'company_tc',
      '_empty',
      'address',
      'apartment',
      'neighborhood',
      'municipality',
      'state',
      'zip_code'
    ];

    const isOxxoUser = () => {
      const user = this.props.appStore.user;
      return user && user.subsidiaryId === 82;
    };
    const c = <div>
      {this.props.fields.filter(f => basicData.includes(f.id)).length?(
        <div>
          <CandidateSection
            title={formatMessage(messages.section.personalData)}
            data={this.props.candidate}
            fields={this.props.fields.filter(f => basicData.includes(f.id))}
          />
          <Divider />
        </div>
      ):null}
      {this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('education')).length?(
        <div>
          <CandidateSection
            title={formatMessage(messages.section.education)}
            data={this.props.candidate}
            fields={this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('education'))}
          />
          <Divider />
        </div>
      ):null}
      {this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('experience')).length?(
        <div>
          <CandidateSection
            title={formatMessage(messages.section.experience)}
            data={this.props.candidate}
            fields={this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('experience'))}
          />
          <Divider />
        </div>
      ):null}
      {this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('working')).length?(
        <div>
          <CandidateSection
            title={formatMessage(messages.section.timeAvailability)}
            data={this.props.candidate}
            fields={this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('working'))}
          />
          <Divider />
        </div>
      ):null}
      {this.props.fields.filter(f => (f.show || f.in_emicv)
          && !basicData.includes(f.id)
          && !exclude.includes(f.id)
          && !f.id.startsWith('education')
          && !f.id.startsWith('experience')
          && !f.id.startsWith('working')
          && f.id !== 'manually_assigned_working_place_id'
        ).length?(
          <div>
            <CandidateSection
              title={formatMessage(messages.section.generalData)}
              data={this.props.candidate}
              fields={this.props.fields.filter(f => (f.show || f.in_emicv)
                  && !basicData.includes(f.id)
                  && !exclude.includes(f.id)
                  && !f.id.startsWith('education')
                  && !f.id.startsWith('experience')
                  && !f.id.startsWith('working')
                  && f.id !== 'manually_assigned_working_place_id'
                )}
            />
            <Divider/>
            <Divider/>
          </div>

      ):null}
      {/* Just for Oxxo */}
      {isOxxoUser() && this.props.fields.filter(f =>  (f.show || f.in_emicv) && oxxoSection.includes(f.id)).length?(
        <div>
          <CandidateSection
            title={formatMessage(messages.section.interviewData)}
            data={this.props.candidate}
            fields={this.props.fields.filter(f => (f.show || f.in_emicv) && oxxoSection.includes(f.id))}
          />
          <Divider />
        </div>
      ):null}
      {this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('test_question')).length?(
        <div>
          <CandidateSection
            title={formatMessage(messages.section.testAnswers)}
            data={this.props.candidate}
            fields={this.props.fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('test_question'))}
          />
          <Divider />
        </div>
      ):null}

    </div>
    const styles = StyleSheet.create({
      header: {
        position: 'relative',
        width: '100%',
        marginBottom: '50px',
      },
      headerImage: {
        position: 'absolute',
        right: '10px',
        top: '15px',
        maxWidth: '15%',
        height: '30px'
      },
      headerBrandImage: {
        position: 'absolute',
        left: '10px',
        top: '15px',
        maxWidth: '15%',
        height: '30px'
      },
      footerImage: {
        position: 'absolute',
        left: '10px',
        bottom: '10px',
        width: '8%',
      },
      footer: {
        position: 'absolute',
        bottom: '20px',
        left: '0',
        transform: 'translateX(-50%)',
        fontSize: 9,
        color: 'grey',
        textAlign: 'center'

      },
      footerText: {
        // position: 'absolute',
        transform: 'translateX(-55%)',
        fontFamily: 'Helvetica-Oblique'
      }
    });

    console.log('this.props.appStore.user.subsidiary.logo || this.props.appStore.user.subsidiary.Company.logo', this.props.appStore.user.subsidiary.logo , this.props.appStore.user.subsidiary.Company.logo)

    const sections = []
    const { fields, candidate } = this.props

    if (fields.filter(f => basicData.includes(f.id)).length) {
      sections.push({
        title: formatMessage(messages.section.personalData),
        data: candidate,
        fields: fields.filter(f => basicData.includes(f.id))})
    }
    if (fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('education')).length) {
      sections.push({
        title: formatMessage(messages.section.education),
        data: candidate,
        fields: fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('education'))})
    }
    if (fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('experience')).length) {
      sections.push({
        title: formatMessage(messages.section.experience),
        data: candidate,
        fields: fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('experience'))})
    }

    if (fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('working')).length) {
      sections.push({
        title: formatMessage(messages.section.timeAvailability),
        data: candidate,
        fields: fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('working'))})
    }

    if (fields.filter(f => (f.show || f.in_emicv)
      && !basicData.includes(f.id)
      && !exclude.includes(f.id)
      && !f.id.startsWith('education')
      && !f.id.startsWith('experience')
      && !f.id.startsWith('working')
      && f.id !== 'manually_assigned_working_place_id'
    ).length) {
      sections.push({
        title: formatMessage(messages.section.generalData),
        data: candidate,
        fields: fields.filter(f => (f.show || f.in_emicv)
          && !basicData.includes(f.id)
          && !exclude.includes(f.id)
          && !f.id.startsWith('education')
          && !f.id.startsWith('experience')
          && !f.id.startsWith('working')
          && f.id !== 'manually_assigned_working_place_id'
        )})
    }
    // Just for Oxxo
    if (isOxxoUser() && fields.filter(f => (f.show || f.in_emicv) && oxxoSection.includes(f.id)).length) {
      sections.push({
        title: formatMessage(messages.section.interviewData),
        data: candidate,
        fields: fields.filter(f => (f.show || f.in_emicv) && oxxoSection.includes(f.id))})
    }

    const MyDocument = ({candidate, fields}) => (
      <Document>
        <PagePdf
          sections={sections}
          user={this.props.appStore.user}
        >
        </PagePdf>

        {fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('test_question')).length?(
          <PagePdf sections={[{
              title: formatMessage(messages.section.testAnswers),
              data: candidate,
              fields: fields.filter(f => (f.show || f.in_emicv) && f.id.startsWith('test_question'))
            }]}
          user={this.props.appStore.user}>
        </PagePdf>):null}

      </Document>
    );

    const pdf = <MyDocument
        candidate={this.props.candidate}
        fields={this.props.fields}/>

    return (
        <div>
          <PDFDownloadLink
            style={{float: 'right', 'margin': '10px', 'marginRight': '20px'}}
            document={pdf}
            fileName={(this.props.candidate['name_logic'] || "candidato").replace(/ /g, '_')+'.pdf'}>
            {({ blob, url, loading, error }) => (loading ? formatMessage(messages.action.buildingPDF) : formatMessage(messages.action.downloadData))}
          </PDFDownloadLink>
          {c}


        </div>
      );
  }
}

CandidateShow.propTypes = {
  candidate: PropTypes.object,
  fields: PropTypes.array,
  actions: PropTypes.object.isRequired,
  candidateStore: PropTypes.object.isRequired,
  listingStore: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
  candidateStore: makeSelectCandidates(),
  appStore: makeSelectGlobal(),
  listingStore: makeSelectListingPage()
});

function mapDispatchToProps(dispatch) {
  // You can use actions from another container, for example appActions, like this:
  // const actions = bindActionCreators({ ...appActions, ...apidemoActions }, dispatch);

  const actions = bindActionCreators({ ...appActions, ...listingActions, ...candidateActions }, dispatch);
  return {
    actions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CandidateShow));
