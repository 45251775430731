/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { all, call, put, race, takeLatest } from 'redux-saga/effects';
import { ACTIONS, GET_PENDING_ACTIONS, INTERVIEW, STANDBY } from './constants';
const camelCaseKeys = require('camelcase-keys')

import request from '../../utils/request';
import { categoryTypeFailed, categoryTypeSucceed, getPendingActionsError, getPendingActionsSuccess, setLoading, startCategory } from './actions';
import { captureException, captureMessage } from '../../utils/sentry';

const config = require('../../config');
const root = config.params.API_SERVER;
const TIMEOUT_THRESHOLD = 5 * 1000;

function delay (ms) {
  return new Promise (function (resolve) {
    return setTimeout(function () {
      return resolve(`TIMEOUT (${ms}ms)`);
    }, ms);  
  });
}

async function trackRequest (url, options) {
  const startTime = new Date();
  const data = await request(url, options)
  const endTime = new Date();
  return {
    data,
    timespan: endTime.getTime() - startTime.getTime()
  }
}

function captureExceptionSafe (error, tags, extras) {
  try {
    captureException(error, tags, extras)
  } catch (error) { }
}

function captureMessageSafe (message, tags, extras) {
  try {
    captureMessage(message, tags, extras)
  } catch (error) { }
}

function* getPendingAction (type) {
  try {
    const requestURL = `${root}/pendingActions/${type}`;
    const results = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(getPendingActionsSuccess(type, camelCaseKeys(results, { deep: true })));
  } catch (error) {
    yield put(getPendingActionsError(type, error));
  }
}

function* getPendingActionByCategory (category, type) {
  try {
    const { results, timeout } = yield race({
      results: call(trackRequest, `${root}/pendingActions/${type}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      timeout: call(delay, TIMEOUT_THRESHOLD)
    });
    if (timeout) {
      yield put(categoryTypeFailed(category, type, new Error(timeout)));
      captureExceptionSafe(
        new Error(`TIMEOUT GET PENDING ACTION BY TYPE: ${type}`), {
          type
        }, {
          type,
          category,
          threshold: TIMEOUT_THRESHOLD,
        }
      )
    } else {
      const { data, timespan } = results
      yield put(categoryTypeSucceed(category, type, camelCaseKeys(data, { deep: true })));
      if (timespan > 1000) {
        const order = Math.ceil(timespan / 1000)
        captureMessageSafe(`GET PENDING ACTION BY TYPE: ${type} - Order: ${order}`, {
          type,
          order,
        }, {
          type,
          category,
          order,
          timespan,
        })
      }
    }
  } catch (error) {
    yield put(categoryTypeFailed(category, type, error));
  }
}

export function* sPendingActions({ config }) {
  if (config.version === 2) {
    const effects = []
    if (config.actions.show && Array.isArray(config.actions.actions) && config.actions.actions.length > 0) {
      yield put(startCategory(ACTIONS, config.actions.actions.length))
      effects.push(config.actions.actions.map(type => call(getPendingActionByCategory, ACTIONS, type)))
    }
    if (config.interview.show && Array.isArray(config.interview.actions) && config.interview.actions.length > 0) {
      yield put(startCategory(INTERVIEW, config.interview.actions.length))
      effects.push(config.interview.actions.map(type => call(getPendingActionByCategory, INTERVIEW, type)))
    }
    if (config.standBy.show && Array.isArray(config.standBy.actions) && config.standBy.actions.length > 0) {
      yield put(startCategory(STANDBY, config.standBy.actions.length))
      effects.push(config.standBy.actions.map(type => call(getPendingActionByCategory, STANDBY, type)))
    }
    if (effects.length > 0) {
      yield all(effects)
    }
  } else {
    let pendingActionTypes = [ 'PENDING_FEEDBACK', 'CREATE_INTERVIEW', 'ADVANCE_CANDIDATE_PRE_INTERVIEW', 'ADVANCE_CANDIDATE_POST_INTERVIEW' ]
    if (config.showTodayInterviews) {
      if (config.todayInterviewsAllUsers) {
        pendingActionTypes.push('ALL_USERS_TODAY_INTERVIEWS')
      } else if (config.allInterviews) {
        pendingActionTypes.push('ALL_INTERVIEWS')
      } else {
        pendingActionTypes.push('MY_TODAY_INTERVIEWS')
      }
    }

    if (pendingActionTypes.length === 0) {
      return
    }
  
    yield put(setLoading(pendingActionTypes.length))
    yield all(pendingActionTypes.map(type => call(getPendingAction, type)))
  }
}

export function* getPendingActionsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_PENDING_ACTIONS, sPendingActions);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

// All sagas to be loaded
export default [
  getPendingActionsApi,
];
