import { useState, useEffect } from 'react';

import { getVacanciesfilteredByText } from '../../utils/connector';

export const useFetchVacancies = (subsidiaryId, listingId, partial) => {
  const [vacancies, setVacancies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getVacanciesfilteredByText(subsidiaryId, listingId, partial);
        setVacancies(response);
      } catch(error) {
        console.error('An error has ocurred getting vacancies', error, subsidiaryId, partial);
      }
      setIsLoading(false);
    };
    if (subsidiaryId && partial) {
      fetchData();
    }
  }, [subsidiaryId, partial]);
  return [vacancies, isLoading];
};
