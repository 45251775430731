import Hotjar from '@hotjar/browser';

const notifyEvent = eventName => {
  try {
    Hotjar.event(eventName)
  } catch (e) {
    console.error(`Hotjar event notification failed: ${eventName}`, e);
  }
};

const identifyUser = user => {
  try {
    const simplifiedUser = {
      id: user.id.toString(),
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      subsidiaryId: user.subsidiaryId,
    }
    Hotjar.identify(simplifiedUser.id, simplifiedUser)
    console.log('Hotjar: user identified')
  } catch (e) {
    console.error(`Hotjar: user identification failed`, { user, e });
  }
}

export { identifyUser, notifyEvent };
