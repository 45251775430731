import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { AutoCompleteLargeCondition } from './others/AutoCompleteCondition';
import DateCondition from './others/DateCondition';
import TimeCondition from './others/TimeCondition';
import NumberCondition from './others/NumberCondition';

const Condition = (props) => {
  switch (props.type) {
    case 'datetime':
      return <DateCondition {...props} />
    case 'time':
      return <TimeCondition {...props} />
    case 'autocomplete':
      return <AutoCompleteLargeCondition {...props} />
    case 'number':
      return <NumberCondition {...props} />
    default:
      return null
  }
}

const NewInterview = ({ title, conditions, error, submitButton, cancelButton, hiringManagerComponent, showHiringManagerComponent }) => {
  return (
    <React.Fragment>
      <Box marginBottom='15px'>
        <Grid container>
          <Grid item xs={12}>
            <Box
              fontFamily='Lato'
              fontStyle='normal'
              fontWeight='bold'
              fontSize='24px'
              lineHeight='31px'
            >{title}</Box>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Box color='red' marginBottom='20px'>
                {error}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {
          showHiringManagerComponent ? hiringManagerComponent : conditions.map((condition, index) => (
            <Grid key={index} item sm={condition.width || 6}>
              <Condition {...condition} />
            </Grid>
          ))
        }
      </Grid>
      <Box marginTop='15px'>
        <Grid container justify='flex-end'>
          <Grid item>
            {cancelButton}
          </Grid>
          <Grid item>
            {submitButton}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}

NewInterview.propTypes = {
  title: PropTypes.string.isRequired,
  conditions: PropTypes.array.isRequired,
  submitButton: PropTypes.element.isRequired,
  cancelButton: PropTypes.element.isRequired,
  error: PropTypes.string,
  showHiringManagerComponent: PropTypes.bool.isRequired,
  hiringManagerComponent: PropTypes.element.isRequired
}

export default NewInterview
