import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import MuiMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'

const Menu = ({
  identifier,
  actions
}) => {
  const [open, setOpen] = useState(false)
  const openMenu = () => setOpen(true)
  const closeMenu = () => setOpen(false)
  const clickAndClose = (onClick) => () => {
    closeMenu()
    onClick()
  }
  return <React.Fragment>
    <IconButton size='small' id={identifier} onClick={openMenu}>
      <MenuIcon />
    </IconButton>
    <MuiMenu
      id='simple-menu'
      keepMounted
      anchorEl={document.getElementById(identifier)}
      open={open}
      onClose={closeMenu}
    >
      {
        actions.map((group, index) => {
          const items = []
          if (index !== 0 && group.length > 0) {
            items.push(<Divider />)
          }
          items.push(group.map(({ label, onClick }, index) => (
            <MenuItem onClick={clickAndClose(onClick)} key={index}>
              {label}
            </MenuItem>
          )))
          return items
        })
      }
    </MuiMenu>
  </React.Fragment>
};

export default injectIntl(Menu);