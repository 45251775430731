import React from 'react';
import { connect } from 'react-redux'
import { hideGoToCandidate } from '../../../store/candidateActions/actions'
import GoToCandidateDialog from '../GoToCandidateDialog';

const GoToCandidateAction = ({ store: { show, data }, close }) => {
  const { candidateId, listingId, stepId } = data || {}

  return <GoToCandidateDialog 
    open={show}
    onClose={close}
    candidateId={candidateId}
    listingId={listingId}
    stepId={stepId}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').goToCandidate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideGoToCandidate())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoToCandidateAction)
