import moment from 'moment';

/**
 * CURP validation from: https://es.stackoverflow.com/questions/31039/c%C3%B3mo-validar-una-curp-de-m%C3%A9xico
 */

const curpValida = (curp) => {
  const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  const validado = curp.match(re);

  if (!validado) //Coincide con el formato general?
    return false;
  
  //Validar que coincida el dígito verificador
  const digitoVerificador = (curp17) => {
    //Fuente https://consultas.curp.gob.mx/CurpSP/
    const diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
    let lngSuma = 0.0;
    let lngDigito = 0.0;
    for (let i = 0; i < 17; i++) {
      lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    }    
    lngDigito = 10 - lngSuma % 10;
    if (lngDigito == 10) {
      return 0;
    }
    return lngDigito;
  }

  if (validado[2] != digitoVerificador(validado[1])) {
    return false;
  }
  return true; //Validado
}

class Curp {
  constructor(curp) {
    this.curp = curp;
    this.valid = curpValida(curp);
  }

  isValid() {
    return this.valid;
  }

  isValidBirthdate(date) {
    if (!this.valid) {
      return false;
    }
    if (!date) {
      return false;
    }
    try {
      const curpBirthdate = this.curp.substring(4, 10);
      const dateNormalized = date.replaceAll('-', '').substring(2, 8);
      return curpBirthdate === dateNormalized;
    } catch (_) {
      return false;
    }
  }

  toString() {
    return this.curp;
  }
}

export default Curp;
