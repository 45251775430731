/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  GET_ANALYTICS,
  GET_ANALYTICS_DASHBOARDS,
  GET_ANALYTICS_MAP_CANDIDATES,
  GET_CANDIDATES_LISTING_DATA_AGGREGATION,
} from './constants';

import {
  getAnalyticsSuccess,
  getAnalyticsError,
  getAnalyticsDashboardsSuccess,
  getAnalyticsDashboardsError,
  getAnalyticsMapCandidatesSuccess,
  getAnalyticsMapCandidatesError,
  getCandidatesListingDataAggregationSuccess,
  getCandidatesListingDataAggregationError,
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sGetAnalyticsApi(action) {
  const requestURL = new URL(`${root}/analytics`);
  let params = {
    listingId: action.listingId || '',
    stepId: action.stepId || '',
    dashboard: action.dashboard || '',
    dashboardFields: action.dashboardFields || '',
  }
  Object.keys(params).forEach(key => requestURL.searchParams.append(key, params[key]))
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      headers: {
        'Content-Disposition': 'attachment;'
      },
    });
    Object.assign(result, action)
    yield put(getAnalyticsSuccess(result));
  } catch (err) {
    yield put(getAnalyticsError(err));
  }
}

export function* getAnalyticsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_ANALYTICS, sGetAnalyticsApi);

}

export function* sGetAnalyticsDashboardsApi(action) {
  const requestURL = new URL(`${root}/analytics_dashboards`);
  let params = {
    listingId: action.listingId || ''
  }
  Object.keys(params).forEach(key => requestURL.searchParams.append(key, params[key]))
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      headers: {
        'Content-Disposition': 'attachment;'
      },
    });
    Object.assign(result, action)
    yield put(getAnalyticsDashboardsSuccess(result));
  } catch (err) {
    yield put(getAnalyticsDashboardsError(err));
  }
}

export function* getAnalyticsDashboardsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_ANALYTICS_DASHBOARDS, sGetAnalyticsDashboardsApi);

}


export function* sGetAnalyticsMapCandidatesApi(action) {
  const requestURL = new URL(`${root}/analytics/candidates`);
  let params = {
    listingId: action.listingId || '',
    stepId: action.stepId || ''
  }
  Object.keys(params).forEach(key => requestURL.searchParams.append(key, params[key]))
  console.log('getAnalyticsMapCandidatesApi')
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      headers: {
        'Content-Disposition': 'attachment;'
      },
    });
    Object.assign(result, action)
    yield put(getAnalyticsMapCandidatesSuccess(result));
  } catch (err) {
    yield put(getAnalyticsMapCandidatesError(err));
  }
}

export function* getAnalyticsMapCandidatesApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_ANALYTICS_MAP_CANDIDATES, sGetAnalyticsMapCandidatesApi);

}



export function* sGetCandidatesListingDataAggregationApi(action) {
  const requestURL = new URL(`${root}/interviews/analytics`);
  let params = {
    listingId: action.listingId || '',
    stepId: action.stepId || '',
    state: action.state || '',
    aggregate: action.aggregate || '',
  }
  Object.keys(params).forEach(key => requestURL.searchParams.append(key, params[key]))
  console.log('getCandidatesListingDataAggregationApi', action.listingId, action.stepId)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      headers: {
        'Content-Disposition': 'attachment;'
      },
    });
    console.log('getCandidatesListingDataAggregationApi result', action.listingId, action.stepId)
    Object.assign(result, action)
    yield put(getCandidatesListingDataAggregationSuccess(result));
  } catch (err) {
    yield put(getCandidatesListingDataAggregationError(err));
  }
}

export function* getCandidatesListingDataAggregationApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeEvery(GET_CANDIDATES_LISTING_DATA_AGGREGATION, sGetCandidatesListingDataAggregationApi);

}


// All sagas to be loaded
export default [
  getAnalyticsApi,
  getAnalyticsDashboardsApi,
  getAnalyticsMapCandidatesApi,
  getCandidatesListingDataAggregationApi
];
