import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiList from "@mui/material/List";

const sharedMixin = (theme) => ({
  backgroundColor: theme.custom.navigation.menu.backgroundColor,
  borderRadius: theme.spacing(0, 3, 3, 0),
});

const openedMixin = (theme) => ({
  ...sharedMixin(theme),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  width: theme.custom.navigation.menu.width,
});

const closedMixin = (theme) => ({
  ...sharedMixin(theme),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "expanded",
})(({ theme, expanded }) => ({
  position: "absolute",
  top: theme.custom.navigation.appBar.height,
  left: 0,
  height: `calc(100% - ${theme.custom.navigation.appBar.height})`,
  width: theme.custom.navigation.menu.width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiModal-backdrop": {
    backgroundColor: "#0B1C4C",
    opacity: "0.1 !important",
  },
  ...(expanded && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      position: "relative",
      padding: theme.spacing(1),
      ...openedMixin(theme),
    },
  }),
  ...(!expanded && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      position: "relative",
      padding: theme.spacing(1),
      ...closedMixin(theme),
    },
  }),
}));

const List = styled(MuiList, {
  shouldForwardProp: (prop) => prop !== "expanded",
})(({ theme, expanded }) => ({
  ...(expanded && {
    padding: theme.spacing(1),
  }),
  ...(!expanded && {
    padding: theme.spacing(1, 0, 0, 0),
  }),
}));

export default ({ expanded, onClose, children }) => (
  <Drawer
    open
    disableEnforceFocus
    hideBackdrop={!expanded}
    expanded={expanded}
    onClose={onClose}
  >
    <List expanded={expanded}>
      {children}
    </List>
  </Drawer>
)
