import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedRelative} from 'react-intl';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';

import { Image, Text, View, Document, Page, StyleSheet } from '@react-pdf/renderer'

import Paper from '@material-ui/core/Paper';
import SearchInput from '../../Emi/Input'

import messages from '../messages';

const styles = theme => ({
  root: {
    padding: "10px",
    // marginTop: "20px"
  },
});


const getValue = (value, header) => {
  try {
    if (header && header.transformation && header.transformation[value]) {
      return header.transformation[value]
    }
    return value
  } catch (e) {
    return value
  }
}

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

class CandidateSectionView extends React.Component {
  state = {}

  render () {
    const { title, fields, data } = this.props;
    const styles = StyleSheet.create({
      title: {
        padding: '10px',
        fontSize: 12,
        color: 'blue',
      },
      key: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bolder'
        // fontFamily: 'Times-Bold'
      },
      value: {
        fontSize: 9,
        color: 'black',
      },
      gridContainer: {
        margin: '20px',
        marginTop: '5',
        marginBottom: '5',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
              justifyContent: 'space-between',
              borderStyle: "solid",
              borderBottomColor: 'gainsboro',
              borderBottomWidth: 1,
      },
      gridItem: {
        marginBottom: '10px',
        textAlign: 'left',
        width: '47%',

      },
    });
    return (
      <View>
        {fields.length? (
          <View>
            <View style={styles.title}>
              <Text>{title}</Text>
            </View>
            <View  style={styles.gridContainer}>
              {fields.map((f, index) => {
                if(f && (f.show || f.in_emicv) && data[f.id] && ((typeof data[f.id] === 'string' && !data[f.id].endsWith('.jpg')&& data[f.id].indexOf('http') == -1) || (typeof data[f.id] === 'number'))){
                  return <Text style={styles.gridItem} key={index}>

                        <Text style={styles.key}>{f.label} </Text>  <Text style={styles.value}>{getValue(data[f.id], f)}</Text>

                    </Text>

                }
              })}
            </View>
          </View>
        ):null}

      </View>
    );
  }

}

CandidateSectionView.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  data: PropTypes.object,

};

export default (CandidateSectionView);
