import React, { useState, useRef } from 'react'
import { injectIntl } from 'react-intl'
import BoltIcon from '@mui/icons-material/Bolt';
import MuiMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Button from "@mui/material/Button"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { trackEvent, actionsType } from '../../../pages/candidates/utils/tracks';

const StyledMenu = styled(MuiMenu)`
  && { 
    margin-top: 50px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    font-family: Lato;
  }

  & .MuiMenu-paper {
    border-radius: 8px;
  }

  & .MuiTypography-root {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  & .MuiSvgIcon-root {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`

const Menu = ({
  actions,
  disabled,
  candidateId
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef(null);

  React.useEffect(() => {
    if (openMenu) {
      trackEvent({ type: actionsType.actionButtonClick, data: { candidateId } })
    }
  }, [openMenu])

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef && menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return <Box sx={{display: "inline-block", position: "relative"}}>
    <Button
      id="demo-customized-button"
      variant="outlined"
      onClick={() => setOpenMenu(!openMenu)}
      startIcon={<BoltIcon sx={{ color: disabled ? "#A4A4A4" : "#5E33C5" }} />}
      sx={{
        borderRadius: "8px",
        border: disabled ? "1px solid #F3F3F3 !important" : "1px solid #5E33C5",
        backgroundColor: disabled ? "#F3F3F3" : "inherit",
        color: "#5E33C5",
        textTransform: "none",
        padding: "8px 10px",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "24px",
        fontFamily: "Lato",
        minWidth: "auto",
        "& .MuiButton-startIcon": { margin: "0px" },
      }}
      ref={menuRef}
      disabled={disabled}
    />
    <StyledMenu
      id='simple-menu'
      keepMounted
      anchorEl={menuRef.current}
      open={openMenu}
      onClose={() => setOpenMenu(false)}
    >
      {
        actions.map((group, index) => {
          const items = []
          if (index !== 0 && group.length > 0) {
            items.push(<Divider />)
          }
          items.push(group.map(({ label, onClick, icon, color }, index) => {
            const Icon = icon
            return (
              <MenuItem 
                onClick={(props) => { 
                  setOpenMenu(false)
                  onClick(props)
                }} 
                key={index}>
                {icon && <Icon sx={{ color: color || "#000" }} />}
                <Typography sx={{ color: color || "#000", fontFamily: "Lato" }}>{label}</Typography>
              </MenuItem>
            )
          }))
          return items
        })
      }
    </StyledMenu>
    </Box>
};

export default injectIntl(Menu);