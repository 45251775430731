import { jwtDecode } from 'jwt-decode';
import Cookie from 'js-cookie';

const getUserContext = () => {
  try {
    const idToken = Cookie.get('idToken')
    if (!idToken) {
      return {
        user: 'guest'
      }
    }
    const payload = jwtDecode(idToken);
    const subsidiaryId = JSON.parse(payload.subsidiaries)[0].subsidiaryId
    return {
      user: 'authenticated',
      extra: {
        subsidiaryId
      }
    }
  } catch (e) {
    return {
      user: 'unknown'
    }
  }
}

const isUserOfSubsidiary = ({ user, extra }, subsidiaryIds) => {
  return user === 'authenticated' && extra && extra.subsidiaryId && subsidiaryIds.includes(extra.subsidiaryId)
}

export { getUserContext, isUserOfSubsidiary }