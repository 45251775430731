import React from "react";
import { useIntl } from 'react-intl';
import Box from "@mui/material/Box";
import messages from '../messages';
import Icon from "../../../v2/components/icons/Icon";

const MagicWandIcon = () => (
  <Box mr={0.5} display="inline-block">
    <Icon name="magicWand" height={16} width={16} />
  </Box>
)

export default () => {
  const { formatMessage } = useIntl()
  return (
    <Box
      color="#5E33C5"
      backgroundColor="rgba(186,157,255,0.2)"
      p={1}
      borderRadius={(theme) => theme.spacing(1)}
      fontSize={12}
      fontWeight={600}
      fontFamily="Lato"
    ><MagicWandIcon />{formatMessage(messages.impersonated)}</Box>
  )
}