import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import messages from '../messages';
import { Grid } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { browserHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { getInterviewActiveSlots } from '../../../utils/connector';
import moment from 'moment';

const PAST = 'past'
const FUTURE = 'future'

const filterByType = Object.freeze({
  [PAST]: "&tab=0",
  [FUTURE]: "&tab=1"
})

const getTrackParams = () => `source=ca&clickId=${uuidv4()}`
const getInterviewLink = (listingId, stepId, slotId, type) => `/listing/dashboard/${listingId}?stepId=${stepId}&slotId=${slotId}${filterByType[type]}&${getTrackParams()}`;

const GoToCandidateDialog = ({ open, onClose, candidateId, listingId, stepId, intl: { formatMessage } }) => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(null);

  useEffect(() => {
    if (link) {
      onClose();
      browserHistory.push(link);
    }
  }, [link]);

  useEffect(() => {
    async function process() {
      setLoading(true)
      try {
        const result = await getInterviewActiveSlots(candidateId, listingId)
        if (result && result.length > 0) {
          const { slot_id: slotId, date_time: dateTime } = result[0]
          //TODO: we should avoid as much as possible business logic here. Each slot should have an attribute that indicates its "type"
          const type = !dateTime || moment().utc().isAfter(moment(dateTime)) ? PAST : FUTURE
          const link = getInterviewLink(listingId, stepId, slotId, type);
          setLink(link); 
        } else {
          setLink(null)
        }
      } catch (e) {
        setLink(null)
      }
      setLoading(false)
    }
    if (open) {
      process()
    }
  }, [open, candidateId, listingId, stepId]);

  return (<Dialog
    open={open}
    maxWidth="sm"
    fullWidth
    onClose={onClose}
    onBackdropClick={onClose}
  >
    <DialogContent>
      {loading && <Grid item container justify="center"><CircularProgress /></Grid>}
      {!loading && !link && <span>{formatMessage(messages.goToCandidate.error)}</span>}
    </DialogContent>
  </Dialog>)
};

export default injectIntl(GoToCandidateDialog);
