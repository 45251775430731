/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';
import emiMessages from '../Emi/messages'
export default defineMessages({
  interview: {
    assisted: {
      defaultMessage: 'Attended',
      id: "boilerplate.components.StepView.interview.assisted"
    },
    interviewTooCloseError: {
      defaultMessage: "Invalid time: interviews should take place at least {hours} hours from now.",
      id: "boilerplate.components.StepView.interview.interviewTimeError"
    },
    interviewTooEarly: {
      defaultMessage: "Invalid time: interviews should take place after {hours}",
      id: "boilerplate.components.StepView.interview.interviewTooEarly"
    },
    interviewTooLate: {
      defaultMessage: "Invalid time: interviews should end before {hours}",
      id: "boilerplate.components.StepView.interview.interviewTooLate"
    },
    invalidPhoneNumber: {
      defaultMessage: "invalid phone number",
      id: "boilerplate.components.StepView.interview.invalidPhoneNumber"
    },
    interviewSizeError: {
      defaultMessage: 'Invalid number of candidates',
      id: 'boilerplate.components.StepView.interview.interviewSizeError'
    },
    repeatedPhoneNumber: {
      defaultMessage: "phone number already saved",
      id: "boilerplate.components.StepView.interview.repeatedPhoneNumber"
    },
    newHiringManager: {
      defaultMessage: "New Hiring Manager",
      id: "boilerplate.components.StepView.interview.newHiringManager"
    },
    modality: {
      virtual: {
        id: 'boilerplate.components.StepView.interview.modality.virtual',
        defaultMessage: "Virtual",

      },
    },
    noCandidates: {
      id: 'boilerplate.components.StepView.interview.noCandidates',
      defaultMessage: "Currently there aren't any candidate to show. Please come back later and check again.",

    },
    canceled: {
      id: 'boilerplate.components.StepView.interview.canceled',
      defaultMessage: 'Canceled',
    },
    time: {
      morning: {
        id: 'boilerplate.components.StepView.interview.dialog.morning',
        defaultMessage: '{time} (in the morning)',
      },

    },
    dialog: {
      title: {
        id: 'boilerplate.components.StepView.interview.dialog.title',
        defaultMessage: 'New interview',
      },
      rescheduleTitle: {
        id: 'boilerplate.components.StepView.interview.dialog.rescheduleTitle',
        defaultMessage: 'Reschedule interview',
      },
      error: {
        id: 'boilerplate.components.StepView.interview.dialog.error',
        defaultMessage: 'You must complete all the fields correctly in order to continue',
      },
      cancel: {
        title: {
          id: 'boilerplate.components.StepView.interview.dialog.cancel.title',
          defaultMessage: 'Cancel the interview?',
        },
        body: {
          id: 'boilerplate.components.StepView.interview.dialog.cancel.body',
          defaultMessage: 'Once you cancel the interview, Emi will contact all the candidates that have been already invited and informed that this interview is not longer taking place. Also, no more candidates will ve invited to this interview. Is this ok?',
        },
      },
      close: {
        title: {
          id: 'boilerplate.components.StepView.interview.dialog.close.title',
          defaultMessage: 'Close the interview?',
        },
        body: {
          id: 'boilerplate.components.StepView.interview.dialog.close.body',
          defaultMessage: 'When you close the application, Emi will not assign any more candidates to it, and candidates who do not have the interview result loaded will return to the Talent Pool.',
        },
      },
    },
    dropdown: {
      yes: {
        id: 'boilerplate.components.StepView.interview.dropdown.yes',
        defaultMessage: 'Yes',
      },
      no: {
        id: 'boilerplate.components.StepView.interview.dropdown.no',
        defaultMessage: 'No',
      },
    },
    button: {
      newInterview: {
        id: 'boilerplate.components.StepView.interview.button.newInterview',
        defaultMessage: 'Create New interview',
      },
      ok: {
        id: 'boilerplate.components.StepView.interview.button.ok',
        defaultMessage: 'Confirm',
      },
      view: {
        id: 'boilerplate.components.StepView.interview.button.view',
        defaultMessage: 'View',
      },
      cancel: {
        id: 'boilerplate.components.StepView.interview.button.cancel',
        defaultMessage: 'Cancel',
      },
      close: {
        id: 'boilerplate.components.StepView.interview.button.close',
        defaultMessage: 'Close',
      },
      back: {
        id: 'boilerplate.components.StepView.interview.button.back',
        defaultMessage: 'Back',
      },
      modify: {
        id: 'boilerplate.components.StepView.interview.button.modify',
        defaultMessage: 'Modify',
      },
      save: {
        id: 'boilerplate.components.StepView.interview.button.save',
        defaultMessage: 'Save',
      },
    },
    placeholder: {
      candidate: {
        id: 'boilerplate.components.StepView.interview.placeholder.candidate',
        defaultMessage: 'Candidate',
      },
      hiringManagerName: {
        id: 'boilerplate.components.StepView.interview.placeholder.hiringManagerName',
        defaultMessage: 'Full Name',
      },
      hiringManagerPhone: {
        id: 'boilerplate.components.StepView.interview.placeholder.hiringManagerPhone',
        defaultMessage: 'Phone',
      },
      individual: {
        interval: {
          id: 'boilerplate.components.StepView.interview.placeholder.individual.interval',
          defaultMessage: 'From {time} to {end}, each {duration} minutes, in total {candidates} candidate(s)',
        },
      },
      slotAvailability: {
        id: 'boilerplate.components.StepView.interview.placeholder.slotAvailability',
        defaultMessage: 'Candidates available',
      },
      slotAvailabilityProcessing: {
        id: 'boilerplate.components.StepView.interview.placeholder.slotAvailabilityProcessing',
        defaultMessage: 'processing',
      },
      interviewLocation: {
        id: 'boilerplate.components.StepView.interview.placeholder.interviewLocation',
        defaultMessage: 'Where the candidate should go',
      },
      date: {
        id: 'boilerplate.components.StepView.interview.placeholder.date',
        defaultMessage: 'Date',
      },
      time: {
        id: 'boilerplate.components.StepView.interview.placeholder.time',
        defaultMessage: 'Time',
      },
      size: {
        id: 'boilerplate.components.StepView.interview.placeholder.size',
        defaultMessage: '# Candidates',
      },
      hiringManager: {
        id: 'boilerplate.components.StepView.interview.placeholder.hiringManager',
        defaultMessage: 'Hiring Manager',
      },
      hiringManagerCommunication: {
        id: 'boilerplate.components.StepView.interview.placeholder.hiringManagerCommunication',
        defaultMessage: 'Communicate with hiring manager?',
        description: {
          id: 'boilerplate.components.StepView.interview.placeholder.hiringManagerCommunication.description',
          defaultMessage: 'If checked, Emi will contact the selected Hiring Manager to notify them about upcoming interviews and ask for post-interview feedback.',
        }
      },
      grupal: {
        size: {
          id: 'boilerplate.components.StepView.interview.placeholder.grupal.size',
          defaultMessage: '# Candidates',
        },
      },
    },
    message: {
      check: {
        id: 'boilerplate.components.StepView.interview.message.check',
        defaultMessage: 'Please check if everything is ok. Once you click on the "save" button, Emi will automatically start talking with candidates. Please be sure everything is ok.',
      },
    },
    actions: {
      approved: {
        label: {
          id: 'boilerplate.components.StepView.interview.actions.approved.label',
          defaultMessage: 'Approved'
        },
        title: {
          id: 'boilerplate.components.StepView.interview.actions.approved.title',
          defaultMessage: 'Do you confirm that the candidate will be approved?'
        },
        body: {
          id: 'boilerplate.components.StepView.interview.actions.approved.body',
          defaultMessage: 'After confirmation, the candidate will advance to the next stage.'
        }
      },
      rejected: {
        label: {
          id: 'boilerplate.components.StepView.interview.actions.rejected.label',
          defaultMessage: 'Rejected'
        },
        title: {
          id: 'boilerplate.components.StepView.interview.actions.rejected.title',
          defaultMessage: 'Do you confirm that the candidate will be rejected?'
        },
        body: {
          id: 'boilerplate.components.StepView.interview.actions.rejected.body',
          defaultMessage: 'After confirmation, the candidate will be left out of the process.'
        }
      },
      notAttended: {
        label: {
          id: 'boilerplate.components.StepView.interview.actions.notAttended.label',
          defaultMessage: 'Not attended'
        },
        title: {
          id: 'boilerplate.components.StepView.interview.actions.notAttended.title',
          defaultMessage: 'Do you confirm that the candidate has not attended?'
        }
      },
      unreachable: {
        label: {
          id: 'boilerplate.components.StepView.interview.actions.unreachable.label',
          defaultMessage: 'Unreachable'
        },
        title: {
          id: 'boilerplate.components.StepView.interview.actions.unreachable.title',
          defaultMessage: 'Do you confirm that the candidate is unreachable?'
        }
      }
    },
    editedFeedbackDisabled: {
      id: 'boilerplate.components.StepView.interview.editedFeedbackDisabled',
      defaultMessage: 'You cannot edit approved candidate feedbacks. However, you can reject candidates in the next steps.',
    },
    cancel: {
      id: 'boilerplate.components.StepView.interview.cancel',
      defaultMessage: 'Cancel',
    },
    scoring: {
      interpretation: {
        high: {
          id: 'boilerplate.components.StepView.interview.scoring.interpretation.high',
          defaultMessage: 'High',
        },
        medium: {
          id: 'boilerplate.components.StepView.interview.scoring.interpretation.medium',
          defaultMessage: 'Medium',
        },
        low: {
          id: 'boilerplate.components.StepView.interview.scoring.interpretation.low',
          defaultMessage: 'Low',
        }
      }
    }
  },
  descartes: {
    noData: {
      id: 'boilerplate.components.StepView.descartes.noData',
      defaultMessage: 'No rows found',
    },
    button: {
      next: {
        id: 'boilerplate.components.StepView.descartes.button.next',
        defaultMessage: 'Next page',
      },
      back: {
        id: 'boilerplate.components.StepView.descartes.button.back',
        defaultMessage: 'Previous page',
      },
      data: {
        id: 'boilerplate.components.StepView.descartes.button.data',
        defaultMessage: 'See resume',
      },
      log: {
        id: 'boilerplate.components.StepView.descartes.button.log',
        defaultMessage: 'Show history',
      },
      assignLocation: {
        id: 'boilerplate.components.StepView.descartes.button.assignLocation',
        defaultMessage: 'Assign location'
      },
      approve: {
        id: 'boilerplate.components.StepView.descartes.button.approve',
        defaultMessage: 'Approve'
      },
      reject: {
        id: 'boilerplate.components.StepView.descartes.button.reject',
        defaultMessage: 'Reject'
      }
    },
    approveTooltip: {
      requiredFields: {
        id: 'boilerplate.components.StepView.descartes.approveTooltip.requiredFields',
        defaultMessage: 'You must complete the required fields to approve the candidate:',
      },
      validationErrors: {
        id: 'boilerplate.components.StepView.descartes.approveTooltip.validationErrors',
        defaultMessage: 'You must correct the following fields to approve the candidate:"',
      },
      range: {
        id: 'boilerplate.components.StepView.descartes.approveTooltip.range',
        defaultMessage: 'Invalid range.',
      },
      avoidWeekend: {
        id: 'boilerplate.components.StepView.descartes.approveTooltip.avoidWeekend',
        defaultMessage: 'Saturday and Sunday are not allowed.',
      },
      avoidHoliday: {
        id: 'boilerplate.components.StepView.descartes.approveTooltip.avoidHoliday',
        defaultMessage: 'Holidays are not allowed.',
      }
    },
    reasonDialog: {
      input: {
        placeholder: {
          id: 'boilerplate.components.StepView.descartes.reasonDialog.input.placeholder',
          defaultMessage: "If you don't see the option, type it",
        },
      },
      button: {
        add: {
          id: 'boilerplate.components.StepView.descartes.reasonDialog.button.add',
          defaultMessage: 'Add new reason',
        },
        confirm: {
          id: 'boilerplate.components.StepView.descartes.reasonDialog.button.confirm',
          defaultMessage: 'Confirm'
        },
        reject: {
          id: 'boilerplate.components.StepView.descartes.reasonDialog.button.reject',
          defaultMessage: 'Reject',
        },
        close: {
          id: 'boilerplate.components.StepView.descartes.reasonDialog.button.close',
          defaultMessage: 'Close',
        },
        cancel: {
          id: 'boilerplate.components.StepView.descartes.reasonDialog.button.cancel',
          defaultMessage: 'Cancel',
        },
      },
    },
    reasonSelect: {
      notFound: {
        id: 'boilerplate.components.StepView.descartes.reasonSelect.notFound',
        defaultMessage: 'Nothing was found that includes',
      },
      onFinish: {
        id: 'boilerplate.components.StepView.descartes.reasonSelect.onFinish',
        defaultMessage: 'When you finish typing, add the new reason with the button at the top-right',
      },
      why: {
        id: 'boilerplate.components.StepView.descartes.reasonSelect.why',
        defaultMessage: 'Tell us why',
      },
      reject: {
        id: 'boilerplate.components.StepView.descartes.reasonSelect.reject',
        defaultMessage: 'Assign reasons',
      },
      changeReasons: {
        id: 'boilerplate.components.StepView.descartes.reasonSelect.changeReasons',
        defaultMessage: 'Change reasons',
      }
    },
    approveDialog: {
      title: {
        id: 'boilerplate.components.StepView.descartes.approveDialog.title',
        defaultMessage: "Approve candidate",
      },
      confirmationMessage: {
        id: 'boilerplate.components.StepView.descartes.approveDialog.confirmationMessage',
        defaultMessage: "Do you confirm that you want to approve the candidate?",
      },
      confirmationWarning: {
        id: 'boilerplate.components.StepView.descartes.approveDialog.confirmationWarning',
        defaultMessage: "When you approve a candidate, it moves to the next step of the recruitment process. You selected approve candidate:",
      },
      nickname: {
        id: 'boilerplate.components.StepView.descartes.approveDialog.nickname',
        defaultMessage: "Nickname",
      },
      errors: {
        title: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.title',
          defaultMessage: "Candidate could not be approved, due to the following restrictions:",
        },
        footer: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.footer',
          defaultMessage: "Please, review the candidate and try again",
        },
        MISSING_VACANCY: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.MISSING_VACANCY',
          defaultMessage: "Candidate should have a vacancy assigned",
        },
        MISSING_CASHI: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.MISSING_CASHI',
          defaultMessage: "Candidate has a regular vacancy without setting cashi data",
        },
        MISSING_CASHI_TELEPHONE: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.MISSING_CASHI_TELEPHONE',
          defaultMessage: "Candidate has cashi without a cashi phone number",
        },
        VACANCY_ALREADY_ASSIGNED: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.VACANCY_ALREADY_ASSIGNED',
          defaultMessage: "Candidates's vacancy is already assigned to a candidate in final steps",
        },
        REQUEST_UNKNOWN_ERROR: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.REQUEST_UNKNOWN_ERROR',
          defaultMessage: "Something failed while validating the candidate. Please try again",
        },
        VACANCY_NOT_OPEN: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.VACANCY_NOT_OPEN',
          defaultMessage: "Candidate's assigned vacancy is not open",
        },
        DISABILITY_MISMATCH_ERROR: {
          id: 'boilerplate.components.StepView.descartes.approveDialog.errors.DISABILITY_MISMATCH_ERROR',
          defaultMessage: "Candidate's assigned vacancy does not allow candidates with disabilities",
        },
      }
    },
    rejectDialog: {
      title: {
        id: 'boilerplate.components.StepView.descartes.rejectDialog.title',
        defaultMessage: "Reject candidate",
      },
      reason: {
        id: 'boilerplate.components.StepView.descartes.rejectDialog.reason',
        defaultMessage: 'The candidate will be rejected for the reason'
      }
    },
    locationReassignDialog: {
      title: {
        id: 'boilerplate.components.StepView.descartes.locationReassignDialog.title',
        defaultMessage: "Assign location to the candidate",
      },
      location: {
        id: 'boilerplate.components.StepView.descartes.locationReassignDialog.location',
        defaultMessage: 'Location'
      }
    },
    workingPlaceNotAssigned: {
      id: 'boilerplate.components.StepView.descartes.workingPlaceNotAssigned',
      defaultMessage: 'No assigned working place',
    }
  },
  error: {
    subsidiary: {
      id: 'boilerplate.components.StepView.error.subsidiary',
      defaultMessage: 'You must select a Company form the list.',
    },
    create: {
      id: 'boilerplate.components.StepView.error.create',
      defaultMessage: 'All fields must be filled',
    }
  },
  button: emiMessages.button,
  status: emiMessages.status,
  format: emiMessages.format,
  input: {
    steps: {
      name: {
        id: 'boilerplate.components.StepView.input.steps.name',
        defaultMessage: 'Step name',
      },
      step: {
        id: 'boilerplate.components.StepView.input.steps.step',
        defaultMessage: 'Step order',
      },
      flow: {
        id: 'boilerplate.components.StepView.input.steps.flow',
        defaultMessage: 'Flow',
      },
    },
    name: {
      id: 'boilerplate.components.StepView.input.name',
      defaultMessage: 'Name of the listing',
    },
    gs: {
      id: 'boilerplate.components.StepView.input.gs',
      defaultMessage: 'Google Sheet URL',
    },
    state: {
      id: 'boilerplate.components.StepView.input.state',
      defaultMessage: 'State',
    },
    description: {
      id: 'boilerplate.components.StepView.input.description',
      defaultMessage: 'Description of the listing, what the job offers.',
    },
    info: {
      id: 'boilerplate.components.StepView.input.info',
      defaultMessage: 'Information of the position or the company. This is displayed when the candidate requests for more info of the listing.',
    },
    subsidiary: {
      id: 'boilerplate.components.StepView.input.subsidiary',
      defaultMessage: 'Company',
    },
    listingCompany: {
      id: 'boilerplate.components.StepView.input.listingCompany',
      defaultMessage: 'Listing Company (optional)',
    },
    listingLogo: {
      id: 'boilerplate.components.StepView.input.listingLogo',
      defaultMessage: 'Listing Company Logo URL (optional)',
    },
  },
  dialog: {
    remove: {
      title: {
        id: 'boilerplate.components.StepView.dialog.remove.title',
        defaultMessage: 'Delete the profile "{profile}" from your account?',
      },
      text: {
        id: 'boilerplate.components.StepView.dialog.remove.text',
        defaultMessage: `{listings,
          plural,
          =0 {You will be not able to sync listings anymore with this profile.}
          one {We still have an active listing sync to this profile. If you remove this profile, Emi won't contact candidates anymore of that listing.}
          other {We still have some active listings synced to this profile. If you remove this profile, Emi won't contact candidates anymore from that listings.}}. Are you sure?`,

      }
    }
  },
  header: {
    button: {
      criteria: {
        id: 'boilerplate.components.StepView.header.button.criteria',
        defaultMessage: 'Criteria',
      },
    },
    approved: {
      id: 'boilerplate.components.StepView.header.action.approved',
      defaultMessage: 'APPROVED'
    },
    rejected: {
      id: 'boilerplate.components.StepView.header.action.rejected',
      defaultMessage: 'REJECTED'
    }
  },
  table: {
    footer: {
      rowsPerPage: {
        id: 'boilerplate.components.StepView.table.footer.rowsPerPage',
        defaultMessage: 'Rows per page',
      },
    },
    toolbar: {
      error: {
        required: {
          id: 'boilerplate.components.StepView.table.toolbar.error.required',
          defaultMessage: "Please complete all the required fields",
        }
      },
      interview: {
        reconfirm: {
          id: 'boilerplate.components.StepView.table.toolbar.interview.reconfirm',
          defaultMessage: "When you confirm, you won't be able to edit again. Please be sure that the changes are correct.",
        },
      },
      explanation: {
        rejected: {
          id: 'boilerplate.components.StepView.table.toolbar.explanation.rejected',
          defaultMessage: 'By confirming this action, the selected candidate(s) will move forward to the next step',
        },
      },
      listing: {
        id: 'boilerplate.components.StepView.table.toolbar.listing',
        defaultMessage: 'Listing',
      },
      selected: {
        id: 'boilerplate.components.StepView.table.toolbar.selected',
        defaultMessage: "You've selected {count} candidates",
      },
      selectedOne: {
        id: 'boilerplate.components.StepView.table.toolbar.selectedOne',
        defaultMessage: "You've selected 1 candidate",
      },
      selectedMany: {
        id: 'boilerplate.components.StepView.table.toolbar.selectedMany',
        defaultMessage: "You've selected {count} candidates",
      },
      notSelected: {
        id: 'boilerplate.components.StepView.table.toolbar.notSelected',
        defaultMessage: 'Not Selected',
      },
      assisted: {
        id: 'boilerplate.components.StepView.table.toolbar.assisted',
        defaultMessage: 'Attended',
      },
      notAssisted: {
        id: 'boilerplate.components.StepView.table.toolbar.notAssisted',
        defaultMessage: 'Not Attended',
      },
      criteria: {
        id: 'boilerplate.components.StepView.table.toolbar.criteria',
        defaultMessage: 'Selection criteria',
      },
      applications: {
        id: 'boilerplate.components.StepView.table.toolbar.applications',
        defaultMessage: 'Applications',
      },
      preSelected: {
        id: 'boilerplate.components.StepView.table.toolbar.preSelected',
        defaultMessage: 'Pre-Selected',
      },
      rejected: {
        id: 'boilerplate.components.StepView.table.toolbar.rejected',
        defaultMessage: 'Rejected',
      },
      button: {
        confirm: {
          id: 'boilerplate.components.StepView.table.toolbar.button.confirm',
          defaultMessage: 'Confirm',
        },
      }
    },
    header: {
      button: {
        criteria: {
          id: 'boilerplate.components.StepView.table.header.button.criteria',
          defaultMessage: 'Criteria',
        },
      },
      id: {
        id: 'boilerplate.components.StepView.table.header.id',
        defaultMessage: 'ID',
      },
      action: {
        id: 'boilerplate.components.StepView.table.header.action',
        defaultMessage: 'Action',
      },
      selected: {
        id: 'boilerplate.components.StepView.table.header.selected',
        defaultMessage: 'Selected',
      },
      assisted: {
        id: 'boilerplate.components.StepView.table.header.assisted',
        defaultMessage: 'Attended',
      },
      reason: {
        id: 'boilerplate.components.StepView.table.header.reason',
        defaultMessage: 'Reason',
      },
      candidateId: {
        id: 'boilerplate.components.StepView.table.header.candidateId',
        defaultMessage: 'Candidate ID',
      },
      name: {
        id: 'boilerplate.components.StepView.table.header.name',
        defaultMessage: 'Name',
      },
      email: {
        id: 'boilerplate.components.StepView.table.header.email',
        defaultMessage: 'Email',
      },
      document: {
        id: 'boilerplate.components.StepView.table.header.document',
        defaultMessage: 'Document',
      },
      phone: {
        id: 'boilerplate.components.StepView.table.header.phone',
        defaultMessage: 'Phone',
      },
      mobile: {
        id: 'boilerplate.components.StepView.table.header.mobile',
        defaultMessage: 'Mobile',
      },
      cv: {
        id: 'boilerplate.components.StepView.table.header.cv',
        defaultMessage: 'CV',
      },
      platform: {
        id: 'boilerplate.components.StepView.table.header.platform',
        defaultMessage: 'Job Board',
      },
      reference: {
        id: 'boilerplate.components.StepView.table.header.reference',
        defaultMessage: 'Source',
      },
      createdAt: {
        id: 'boilerplate.components.StepView.table.header.createdAt',
        defaultMessage: 'Application Date',
      },
      updatedAt: {
        id: 'boilerplate.components.StepView.table.header.updatedAt',
        defaultMessage: 'Last Updated',
      },
      listing: {
        id: 'boilerplate.components.StepView.table.header.listing',
        defaultMessage: 'Listing Name',
      },
      step: {
        id: 'boilerplate.components.StepView.table.header.step',
        defaultMessage: 'Current State',
      },
      _empty_: {
        id: 'boilerplate.components.StepView.table.header.empty',
        defaultMessage: 'Comments',
      },
      _comments_: {
        id: 'boilerplate.components.StepView.table.header.empty',
        defaultMessage: 'Comments',
      },
      state: {
        id: 'boilerplate.components.StepView.table.header.state',
        defaultMessage: 'State',
      },
      interview: {
        state: {
          accepted: {
            id: 'boilerplate.components.StepView.table.header.interview.state.accepted',
            defaultMessage: 'Accepted',
          },
          slot_rejected_by_interviewee_slot_date: {
            id: 'boilerplate.components.StepView.table.header.interview.state.badDate',
            defaultMessage: 'Canceled (availability for date)'
          },
          slot_rejected_by_interviewee_slot_datetime: {
            id: 'boilerplate.components.StepView.table.header.interview.state.badTime',
            defaultMessage: 'Canceled (availability for time)'
          },
          no_confirmation_from_interviewee: {
            id: 'boilerplate.components.StepView.table.header.interview.state.noResponse',
            defaultMessage: 'Canceled (no response to invitation)'
          },
          slot_rejected_by_interviewee_location: {
            id: 'boilerplate.components.StepView.table.header.interview.state.distance',
            defaultMessage: 'Canceled (distance)'
          },
          slot_rejected_by_interviewee_position: {
            id: 'boilerplate.components.StepView.table.header.interview.state.position',
            defaultMessage: 'Canceled (position)'
          },
          canceled: {
            id: 'boilerplate.components.StepView.table.header.interview.state.canceled',
            defaultMessage: 'Canceled'
          },
          slot_rejected_by_interviewee_listing: {
            id: 'boilerplate.components.StepView.table.header.interview.state.noInterest',
            defaultMessage: 'Canceled (interest)'
          },
          pending: {
            id: 'boilerplate.components.StepView.table.header.interview.state.pending',
            defaultMessage: 'Waiting response'
          },
          late_reschedule: {
            id: 'boilerplate.components.StepView.table.header.interview.state.late_reschedule',
            defaultMessage: 'Cancelled',
          },
          absent: {
            id: 'boilerplate.components.StepView.table.header.interview.state.absent',
            defaultMessage: 'Absent',
          },
          rejected: {
            id: 'boilerplate.components.StepView.table.header.interview.state.rejected',
            defaultMessage: 'Rejected',
          },
          approved: {
            id: 'boilerplate.components.StepView.table.header.interview.state.approved',
            defaultMessage: 'Approved',
          },

        },
        link: {
          id: 'boilerplate.components.StepView.table.header.interview.link',
          defaultMessage: 'Link',
        },
        occupation: {
          id: 'boilerplate.components.StepView.table.header.interview.occupation',
          defaultMessage: 'Room occupation',
        },
        date: {
          id: 'boilerplate.components.StepView.table.header.interview.date',
          defaultMessage: 'Date',
        },
        time: {
          id: 'boilerplate.components.StepView.table.header.interview.time',
          defaultMessage: 'Time',
        },
        size: {
          id: 'boilerplate.components.StepView.table.header.interview.size',
          defaultMessage: '# Candidates',
        },
        expectedCandidates: {
          id: 'boilerplate.components.StepView.table.header.interview.expectedCandidates',
          defaultMessage: 'Requested Candidates',
        },
        recruiter: {
          id: 'boilerplate.components.StepView.table.header.interview.recruiter',
          defaultMessage: 'Recruiter',
        },
        vacancy: {
          id: 'boilerplate.components.StepView.table.header.interview.vacancy',
          defaultMessage: 'Assigned Vacancy',
        },
        grupal: {
          size: {
            id: 'boilerplate.components.StepView.table.header.interview.grupal.size',
            defaultMessage: '# Candidates',
          },
        },
        cancel: {
          id: 'boilerplate.components.StepView.table.header.interview.cancel',
          defaultMessage: 'Action',
        },
        location: {
          id: 'boilerplate.components.StepView.table.header.interview.location',
          defaultMessage: "Location",
        },
        hiringManagerName: {
          id: 'boilerplate.components.StepView.table.header.interview.hiringManagerName',
          defaultMessage: "Hiring Manager",
        },
        position: {
          id: 'boilerplate.components.StepView.table.header.interview.position',
          defaultMessage: "Position",
        },
        candidates: {
          id: 'boilerplate.components.StepView.table.header.interview.candidates',
          defaultMessage: 'Candidates',
        },
        status: {
          pendingResponse: {
            id: 'boilerplate.components.StepView.table.header.interview.status.pendingresponse',
            defaultMessage: 'Pending response',
          },
          notInterested: {
            id: 'boilerplate.components.StepView.table.header.interview.status.notinterested',
            defaultMessage: 'Not interested',
          },
          rescheduled: {
            id: 'boilerplate.components.StepView.table.header.interview.status.rescheduled',
            defaultMessage: 'Rescheduled',
          },
          accepted: {
            id: 'boilerplate.components.StepView.table.header.interview.status.accepted',
            defaultMessage: 'Confirmed',
          },
          canceled: {
            id: 'boilerplate.components.StepView.table.header.interview.status.canceled',
            defaultMessage: 'Canceled',
          },
          expected: {
            id: 'boilerplate.components.StepView.table.header.interview.status.expected',
            defaultMessage: 'Confirmed Candidates',
          },
          pendingFeedback: {
            id: 'boilerplate.components.StepView.table.header.interview.status.pendingFeedback',
            defaultMessage: 'Pending feedback Candidates',
          },
          assisted: {
            id: 'boilerplate.components.StepView.table.header.interview.status.assisted',
            defaultMessage: 'Attended',
          },
        }
      },

    },
  }
});
