import { createMuiTheme } from '@material-ui/core/styles';

const themeLight = createMuiTheme({
  palette: {
  },
  appBar: {
    height: 57,
    color: '#ececec',
    // color: '#ececec',
    // color: '#3399ff',

  },
  drawer: {
    width: 230,
    color: '#f3f3f3',
  },
  raisedButton: {
    primaryColor: '#1E88E5',
  },
  tabs: {
    backgroundColor: '#ececec',
    textColor: '#717171',
    selectedTextColor: '#717171',
  },
  inkBar: {
    backgroundColor: '#3399ff',
  },
});


export default themeLight;
