/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable'
import {
  LOADING,
  GET_PENDING_ACTIONS_SUCCESS,
  GET_PENDING_ACTIONS_ERROR,
  GET_CATEGORY_INFO_START,
  GET_CATEGORY_INFO_SUCCESS,
  GET_CATEGORY_INFO_ERROR,
  ACTIONS,
  INTERVIEW,
  STANDBY,
} from './constants'

const actionInitialState = {
  actions: {},
  pending: 0,  
}

const initialState = fromJS({
  actions: {},
  loading: 0,
  [ACTIONS]: actionInitialState,
  [INTERVIEW]: actionInitialState,
  [STANDBY]: actionInitialState,
})

function pendingActionsPageReducer (state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_ACTIONS_SUCCESS: {
      return state
        .setIn(['actions', action.payload.type], action.payload.actions)
        .set('loading', state.get('loading') - 1)
    }
    case GET_PENDING_ACTIONS_ERROR: {
      console.log('GET_PENDING_ACTIONS_ERROR', action.payload.type, action.payload.error)
      return state.set('loading', state.get('loading') - 1)
    }
    case LOADING: {
      return state.set('loading', action.loading)
    }

    case GET_CATEGORY_INFO_START: {
      return resolveStart(action.category)(state, action)
    }
    case GET_CATEGORY_INFO_SUCCESS: {
      return resolveSuccess(action.category)(state, action)
    }
    case GET_CATEGORY_INFO_ERROR: {
      return resolveError(action.category)(state, action)
    }

    default:
      return state
  }
}

const resolveStart = (identifier) => (state, action) => state.setIn([identifier, 'pending'], action.pending)

const resolveSuccess = (identifier) => (state, action) => state
  .setIn([identifier, 'actions', action.payload.type], action.payload.actions)
  .setIn([identifier, 'pending'], state.getIn([identifier, 'pending']) - 1)

const resolveError = (identifier) => (state, action) => state
  .setIn([identifier, 'pending'], state.getIn([identifier, 'pending']) - 1)

export default pendingActionsPageReducer
