import { AUTH_GOOGLE_REQUEST, AUTH_GOOGLE_SUCCESS, GET_INTEGRATIONS, GET_INTEGRATIONS_SUCCESS } from './constants';
import initialState from './initialState';

function appReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_GOOGLE_REQUEST:
    case GET_INTEGRATIONS:
      return {
        ...state, isFetching: true
      }
    case GET_INTEGRATIONS_SUCCESS:
      return {
        integrations: action.integrations,
        isFetching: false
      };
    case AUTH_GOOGLE_SUCCESS:
      window.location = action.url;
      return {
        isFetching: false
      }
    default:
    return state;
  }
}

export default appReducer;
