import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Grid,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  CircularProgress,
} from '@material-ui/core';
import { injectIntl } from 'react-intl';
import PrimaryButton from '../../../../../v2/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../v2/components/buttons/SecondaryButton';
import messages from '../../../messages';
import { validateCandidateInStep } from '../../../../../utils/connector';

const ApproveCandidateDialog = ({ open, onClose, candidate, onConfirm, intl: { formatMessage }, stepViewConfiguration }) => {
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const candidateName = candidate.name || candidate.nickname;

  const phoneField = useMemo(() => stepViewConfiguration.find((config) => config.id === 'telephone' && config.show), [stepViewConfiguration]);
  const emailField = useMemo(() => stepViewConfiguration.find((config) => config.id === 'email' && config.show), [stepViewConfiguration]);
  const nameField = useMemo(() => stepViewConfiguration.find((config) => config.id === 'name_logic' && config.show), [stepViewConfiguration]);

  useEffect(() => {
    if (open) {
      const validateCandidate = async () => {
        setLoading(true)
        try {
          const { isValid, errors } = await validateCandidateInStep(candidate.id, candidate.listingId, candidate.stepId)
          setValid(isValid)
          setValidationErrors(errors)
        } catch (err) {
          let isValid = false
          //Maybe moving this error to a utils space so we can reuseit in some other components for unknown errors?
          let errors = ['REQUEST_UNKNOWN_ERROR']

          if (err && err.response) {
            isValid = err.response.isValid
            errors = err.response.errors
          }

          setValid(isValid)
          setValidationErrors(errors)
        }
        setLoading(false)
      }

      validateCandidate()
    }
  }, [open])

  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth='md'
    fullWidth
  >
    <DialogTitle>{formatMessage(messages.descartes.approveDialog.title)}</DialogTitle>
    {
      loading
        ? <Grid item container justifyContent="center"><CircularProgress /></Grid>
        : <React.Fragment>
          <DialogContent>
            {
              !valid
                ? <React.Fragment>
                  <span>{formatMessage(messages.descartes.approveDialog.errors.title)}</span>
                  <ul>
                    {validationErrors.map((error) => (
                      <li key={error}>
                        {formatMessage(messages.descartes.approveDialog.errors[error])}
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
                : <React.Fragment>
                  <Typography variant="body1" style={{ marginBottom: '10px' }}>
                    {formatMessage(messages.descartes.approveDialog.confirmationWarning)}
                  </Typography>

                  <Box>
                    <Grid container>
                      <Grid md={4}>
                        <Box>
                          <Typography variant="caption">{nameField ? nameField.label : formatMessage(messages.descartes.approveDialog.nickname)}</Typography>
                          <Typography>{candidateName}</Typography>
                        </Box>
                      </Grid>

                      {phoneField && <Grid md={4}>
                        <Box>
                          <Typography variant="caption">{phoneField.label}</Typography>
                          <Typography>{candidate.phone}</Typography>
                        </Box>
                      </Grid>}

                      {emailField && <Grid md={4}>
                        <Box>
                          <Typography variant="caption">{emailField.label}</Typography>
                          <Typography>{candidate.email}</Typography>
                        </Box>
                      </Grid>}
                    </Grid>
                  </Box>

                  <Box sx={{ marginBottom: '10px', marginTop: '12px' }}>
                    <Typography variant="button">
                      {formatMessage(messages.descartes.approveDialog.confirmationMessage)}
                    </Typography>
                  </Box>
                </React.Fragment>
            }
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              type="text"
              onClick={onClose}
            >
              {formatMessage(messages.button.cancel)}
            </SecondaryButton>
            <PrimaryButton
              onClick={onConfirm}
              disabled={!valid}
            >
              {formatMessage(messages.button.confirm)}
            </PrimaryButton>
          </DialogActions>
        </React.Fragment>
    }
  </Dialog>
}

export default injectIntl(ApproveCandidateDialog)