import { useEffect } from "react"
import useLoaderContext from "./useLoaderContext"
import { getFeatureConfigInstance } from "../api"

const STRATEGY = {
  DEFAULT: 'default',
  SKIP: 'skip'
}

export default (strategy = STRATEGY.DEFAULT) => {
  const { dispatch, state: { name, instance, state, error } } = useLoaderContext()

  useEffect(() => {
    async function exec() {
      dispatch({ type: 'set_loading' })
      const result = await getFeatureConfigInstance(name)
      if (result.ok) {
        dispatch({ type: 'set_instance', instance: result.content })
      } else {
        const error = result.error.response && result.error.response.error ? result.error.response.error : { code: null, message: result.error.message }
        dispatch({ type: 'set_error', error })
      }
    }
    async function execSkip() {
      dispatch({ type: 'set_error', error: { code: null, message: 'Skipped' }})
    }

    switch (strategy) {
      case STRATEGY.SKIP:
        execSkip();
        break;
      default:
        exec();
        break;
    }
  }, [name])

  return {
    state,
    instance,
    error
  }
}