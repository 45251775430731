/*
 *
 * SuggestionPage constants
 *
 */

export const LOADING = 'app/SuggestionPage/LOADING'
export const GET_SUGGESTIONS = 'app/SuggestionPage/GET_SUGGESTIONS'
export const GET_SUGGESTIONS_SUCCESS = 'app/SuggestionPage/GET_SUGGESTIONS_SUCCESS'
export const GET_SUGGESTIONS_ERROR = 'app/SuggestionPage/GET_SUGGESTIONS_ERROR'

export const CREATE_SUGGESTION = 'app/SuggestionPage/CREATE_SUGGESTION'
export const CREATE_SUGGESTION_SUCCESS = 'app/SuggestionPage/CREATE_SUGGESTION_SUCCESS'
export const CREATE_SUGGESTION_ERROR = 'app/SuggestionPage/CREATE_SUGGESTION_ERROR'

export const CLEAR_MESSAGE = 'app/SuggestionPage/CLEAR_MESSAGE'
