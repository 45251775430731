/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'babel-polyfill';
// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Router from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import FontFaceObserver from 'fontfaceobserver';
import { useScroll } from 'react-router-scroll';
import 'sanitize.css/sanitize.css';
import 'flexboxgrid/css/flexboxgrid.css';

import 'react-datasheet/lib/react-datasheet.css';

// Import root app
import App, { AuthenticatedApp, AuthenticatedWMApp, GuestApp } from 'containers/App';
import { getUserContext, isUserOfSubsidiary } from './appContext';

// Import Sentry
import * as Sentry from "@sentry/react";

// Import selector for `syncHistoryWithStore`
import { makeSelectLocationState } from 'containers/App/selectors';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon file
/* eslint-disable import/no-webpack-loader-syntax */
import '!file-loader?name=[name].[ext]!./favicon.ico';
/* eslint-enable import/no-webpack-loader-syntax */

import configureStore
  from './store';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import { EmiGlobalStyle } from './global-styles';

// Import routes
import createRoutes, { filterRoutes } from './routes';

// Import app saga
import sagas from './containers/App/sagas';
import subsidiarySagas from './containers/Subsidiary/sagas';
import companySagas from './containers/Company/sagas';
import platformSagas from './containers/Platform/sagas';
import platformListingSagas from './containers/PlatformListings/sagas';
import flowSagas from './containers/Flow/sagas';
import listingSagas from './containers/Listings/sagas';
import suggestionSagas from './containers/Suggestions/sagas';
import stepViewsSagas from './containers/StepViews/sagas';
import candidatesSagas from './containers/Candidate/sagas';
import emiAnalyticsSagas from './containers/EmiAnalytics/sagas';
import userSagas from './containers/User/sagas';
import integrationsSagas from './containers/Integrations/sagas';
import pendingActionsSagas from './containers/PendingActions/sagas'
import documentationSagas from './containers/Documentation/Notion/sagas'
import languageProviderSagas from './containers/LanguageProvider/sagas'
import candidateBagSagas from './containers/CandidateBag/sagas'
import interviewSagas from './containers/Interview/sagas'
import candidateActionsSagas from './store/candidateActions/sagas'
import featuresSagas from './store/features/sagas'

if (isProdEnv() || isStagingEnv()) {
  // Disable console in production
  const emptyFunction = () => { }
  console.log = emptyFunction
  console.debug = emptyFunction
  console.warn = emptyFunction
  console.error = emptyFunction
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
}, () => {
  document.body.classList.remove('fontLoaded');
});

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();

const store = configureStore({}, Router.browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(Router.browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

// store.subscribe(() =>{
//     saveState(store.getState())
//   })
//

const userContext = getUserContext();

const createRootRoute = (store, userContext) => {
  try {
    const routes = filterRoutes(createRoutes(store), userContext);
    switch (userContext.user) {
      case 'authenticated': {
        return {
          component: userContext.extra && userContext.extra.subsidiaryId === 51 ? AuthenticatedWMApp : App,
          childRoutes: routes
        }
      }
      case 'guest': {
        return {
          component: GuestApp,
          childRoutes: routes
        }
      }
    }
    throw Error('Unknown route type');
  } catch (e) {
    return {
      component: App,
      childRoutes: createRoutes(store),
    };
  }
}

// Set up the router, wrapping all Routes in the App component
// Ref: https://github.com/remix-run/react-router/blob/v3.2.6/docs/guides/RouteConfiguration.md#configuration-with-plain-routes
const rootRoute = createRootRoute(store, userContext)

// Inject default app sagas
sagas.map(store.runSaga);
subsidiarySagas.map(store.runSaga);
companySagas.map(store.runSaga);
platformSagas.map(store.runSaga);
platformListingSagas.map(store.runSaga);
flowSagas.map(store.runSaga);
listingSagas.map(store.runSaga);
suggestionSagas.map(store.runSaga);
stepViewsSagas.map(store.runSaga);
candidatesSagas.map(store.runSaga);
emiAnalyticsSagas.map(store.runSaga);
userSagas.map(store.runSaga);
integrationsSagas.map(store.runSaga);
pendingActionsSagas.map(store.runSaga)
documentationSagas.map(store.runSaga)
languageProviderSagas.map(store.runSaga)
candidateBagSagas.map(store.runSaga)
interviewSagas.map(store.runSaga)
candidateActionsSagas.map(store.runSaga)
featuresSagas.map(store.runSaga)

// Hot reloadable translation json files
const MOUNT_NODE = document.getElementById('app');

// var DEBUG = false;
// if(!DEBUG){
//     if(!window.console) window.console = {};
//     var methods = ["log", "debug", "warn", "info"];
//     for(var i=0;i<methods.length;i++){
//         console[methods[i]] = function(){};
//     }
// }

import { useUser } from './utils/hooks';
import { ToastProvider } from './components/Toasts';

import { getClientEnvExtended, getClientReleaseName, getVersionDetail, isProdEnv, isStagingEnv } from '../shared/appContext';

const Render = ({ messages, routes, store, history }) => {
  return (
    <ToastProvider>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <EmiGlobalStyle>
            <Router.Router
              history={history}
              routes={routes}
              render={
                // Scroll to top when going to a new page, imitating default browser
                // behaviour
                Router.applyRouterMiddleware(useScroll())
              }
            />
          </EmiGlobalStyle>
        </LanguageProvider>
      </Provider>
    </ToastProvider>
  )
}

const render = (messages, routes, store, history) => {
  ReactDOM.render(
    <Render messages={messages} routes={routes} store={store} history={history} />,
    MOUNT_NODE
  );
};


if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages, rootRoute, store, history);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  (new Promise((resolve) => {
    resolve(import('intl'));
  }))
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/es.js'),
      import('intl/locale-data/jsonp/de.js'),
    ]))
    .then(() => render(translationMessages, rootRoute, store, history))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages, rootRoute, store, history);
}

const compareObjects = (a, b) => {
  for (const key in b) {
    if (b.hasOwnProperty(key)) {
      if (!a.hasOwnProperty(key) || a[key] !== b[key]) {
        return false;
      }
    }
  }
  return true;
}

const matchesFilter = (event) => {
  const filteredErrors = [
    {
      type: "OTLPExporterError"
    },
    {
      value: "Error test"
    },
  ];

  return event && event.exception && event.exception.values && event.exception.values.find((item) => filteredErrors.find((filter) => compareObjects(item, filter)))
}

const crossPlatformErrors = [
  {
    type: "LoadChunkError"
  },
  {
    value: "Cannot read properties of undefined (reading '$socket')",
  },
];

const isCrossPlatformError = (event) => {
  // Check if current event has any of the cross-platform errors
  return !!(event && event.exception && event.exception.values && 
    event.exception.values.find((item) => crossPlatformErrors.find((filter) => compareObjects(item, filter))))
}
Sentry.init({
  dsn: 'https://b66965647e3c49fbb944ba18a96034a4@sentry.emilabs.ai/14',
  tunnel: '/api/sentry',
  beforeSend: function (event, hint) {
    if (matchesFilter(event)) return null
    if (isCrossPlatformError(event)) {
      event.tags = ({
        ...(event.tags || {}),
        transaction: 'cross-platform'
      })
    }
    return event
  },
  integrations: [
    // Sentry.browserTracingIntegration(),
    Sentry.reactRouterV3BrowserTracingIntegration({
      history: Router.browserHistory,
      // Must be Plain Routes.
      routes: [rootRoute],
      match: Router.match,
    }),
  ],
  release: getClientReleaseName(),
  environment: getClientEnvExtended(),
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: isProdEnv() ? 0.5 : 1.0,
});
Sentry.addEventProcessor(function (event, hint) {
  try {
    event.tags = ({
      ...(event.tags || {}),
      ...getVersionDetail()
    })
  } catch (e) {
  }
  return event
})
Sentry.addEventProcessor(function (event, hint) {
  try {
    const user = useUser();
    if (user) {
      event.user = user
      event.tags = ({
        ...(event.tags || {}),
        userId: user.id,
        subsidiaryId: user.subsidiaryId,
      })
    }
  } catch (e) {
  }
  return event
})

navigator.serviceWorker && navigator.serviceWorker.getRegistrations().then(
  function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  });
