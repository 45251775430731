import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const WAIT_INTERVAL = 3000;
const ENTER_KEY = 13;

const styles = theme => ({
  bootstrapRoot: {
    width: '100%',

    fontSize: 12,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  nonEmptyInput:{
    backgroundColor: 'antiquewhite !important',
    padding: '5px',
  },
  bootstrapInput: {
    backgroundColor: "inherit",
    fontSize: 12,
    padding: '5px',
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
})

class Filter extends Component {
    constructor(props) {
        super();

        this.state = {
            value: props.value
        };
    }

    componentWillReceiveProps(newProps){
      if (newProps.value != this.state.value){
        this.setState({value: newProps.value})
      }
    }

    componentWillMount() {
        this.timer = null;
    }

    handleChange(e) {
        clearTimeout(this.timer);

        this.setState({ value: e.target.value });

        this.timer = setTimeout(::this.triggerChange, WAIT_INTERVAL);
    }

    handleKeyDown(e) {
      if (e.keyCode === ENTER_KEY) {
           clearTimeout(this.timer);
          this.triggerChange();
      }
    }

    triggerChange() {
        const { value } = this.state;

        this.props.onChange(value);
    }

    render() {
        const { classes } = this.props;

        return (
            <TextField
                className={classes.bootstrapRoot}
                value={this.state.value}
                onChange={::this.handleChange}
                onKeyDown={::this.handleKeyDown}
                variant='outlined'
                size='small'
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.bootstrapRoot,
                    input: this.state.value?classes.nonEmptyInput:classes.bootstrapInput,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.bootstrapFormLabel,
                }}
            />
        );
    }
}
export default withStyles(styles)(Filter)
// export default (Filter)
