import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedRelative} from 'react-intl';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    padding: "20px"
    // marginTop: theme.spacing.unit * 3,
  },
  container: {
    // minWidth: '600px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  margin: {
    margin: theme.spacing.unit,
  },
  cssLabel: {
    '&$cssFocused': {
      color: purple[500],
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: purple[500],
    },
  },
  bootstrapRoot: {
    width: '75%',
    marginRight: "20px",
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapRootFullWidth: {
    width: '100%',
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    // width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bootstrapFormLabel: {
    fontSize: 16,
    color: '#000000',
    fontWeight: 400
  },
});

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

class CustomizedInputs extends React.Component {
  state = {
    value: ''
  }
  onChange = (event) => {
    const value = this.filter(event.target.value);
    if (this.props.onChange){
      this.props.onChange(value)
    };
    this.setState({value})
  }

  filter = (value) => {
    if (this.props.filter) {
      return this.props.filter(value)
    }
    return value
  }

  onClick = (e) => {
    if (this.props.onAdd){
      this.props.onAdd(this.state.value);
      this.setState({value: ''})
      if (this.props.onChange){
        this.props.onChange('')
      };
    }
  }

  render () {
    const { classes } = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <TextField
        className={classes.bootstrapRootFullWidth}
        onChange={this.onChange}
        value={this.state.value}
        placeholder={this.props.placeholder || ''}
        id="bootstrap-input"
        type={this.props.type || 'text'}
        InputProps={{
          disableUnderline: true,
          classes: {
            // root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
          },
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.bootstrapFormLabel,
        }}
        label={this.props.label}
      />

    );
  }

}

CustomizedInputs.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  filter: PropTypes.func,
  
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(CustomizedInputs));
