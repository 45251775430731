import { createSelector } from 'reselect';

const buildLocationsId = (listingId, options = {}) => {
  let id = `listingId=${listingId}`;
  const { nameFormat } = options;
  if (nameFormat) {
    id = `${id}-nameFormat=${nameFormat}`;
  }
  return id;
}

/**
 * The global state selectors
 */

const selectStepViews = () => (state) => state.get('stepViews');

const makeSelectStepViews = () => createSelector(
  selectStepViews(),
  (substate) => substate.toJS()
);

const getLocationsById = (id) => createSelector(
  selectStepViews(),
  (substate) => {
    const locations = substate.get('locations').toJS()
    return locations[id]
  }
)

const getPositionsById = (id) => createSelector(
  selectStepViews(),
  (substate) => {
    const positions = substate.get('positions').toJS()
    return positions[id]
  }
)

const getVacanciesBySubsidiaryIdAndListingId = (subsidiaryId, listingId) => createSelector(
  selectStepViews(),
  (substate) => {
    const vacancies = substate.get('vacancies')
    return vacancies[subsidiaryId] && vacancies[subsidiaryId][listingId]
  }
)

export {
  selectStepViews,
  makeSelectStepViews,
  getLocationsById,
  getPositionsById,
  getVacanciesBySubsidiaryIdAndListingId,
  buildLocationsId
};
