/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOADING,
  GET_LISTING_SUCCESS,
  GET_LISTING_ERROR,
  GET_LISTINGS_SUCCESS,
  GET_LISTINGS_ERROR,
  CLEAR_LISTING_STEPS,
  GET_LISTING_STEPS_SUCCESS,
  GET_LISTING_STEPS_ERROR,
  CREATE_LISTING_SUCCESS,
  CREATE_LISTING_ERROR,
  REMOVE_LISTING_SUCCESS,
  REMOVE_LISTING_ERROR,
  EDIT_LISTING_SUCCESS,
  EDIT_LISTING_ERROR,
  CLEAR_MESSAGE,
  UPDATE_LISTING_PLATFORM_LISTINGS,
  REMOVE_LISTING_PLATFORM_LISTINGS,
  RESET_LISTING_SUCCESS,
  RESET_LISTING_ERROR,
  INVITE_INTERVIEW_SUCCESS,
  INVITE_INTERVIEW_ERROR,
  DOWNLOAD_LISTING_DATA,
  DOWNLOAD_LISTING_DATA_SUCCESS,
  DOWNLOAD_LISTING_DATA_ERROR,
  DOWNLOAD_STEP_DATA,
  DOWNLOAD_STEP_DATA_SUCCESS,
  DOWNLOAD_STEP_DATA_ERROR,
  UPDATE_LISTING_STEP, DELETE_LISTING_STEP,
  RECOVER_LISTING_STEP
} from './constants';

const initialState = fromJS({
  listings: [],
  listingSteps:[],
  loading: false,
  message: '',
});

const reEnumerateSteps = (steps) => {
  let stepPosition = 1
  return steps.map((s) => {
    s.step = stepPosition
    if (!s.deleted) stepPosition+=1
    return s
  })
}

function listingPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LISTING_SUCCESS: {
      console.log('GET_LISTING_SUCCESS', action.listing.listing)

      const listing = action.listing.listing;
      let listings = Object.assign([], state.get('listings'));

      const listingIndex = listings.findIndex(x => x.id == listing.id)

      if (listingIndex > -1){
        console.log(' existia', listingIndex)
        listings[listingIndex] = listing
      }else{
        console.log('no existia', listing)
        listings.push(listing)
      }
      console.log('listings', listings)
      return state.set('listings', listings);
    }
    case GET_LISTING_ERROR: {
      console.log("GET_LISTING_ERROR", action.error)

      return state;
    }
    case GET_LISTINGS_SUCCESS: {
      console.log('GET_LISTINGS_SUCCESS', action.listings)
      const listings = Object.assign([], action.listings.listings);
      return state.set('listings', listings);
    }
    case GET_LISTINGS_ERROR: {
      console.log("GET_LISTINGS_ERROR", action.error)

      return state;
    }
    case GET_LISTING_STEPS_SUCCESS: {
      console.log('GET_LISTING_STEPS_SUCCESS', action.listingSteps)
      const listingSteps = Object.assign([], action.listingSteps);

      return state.set('listingSteps', listingSteps).set("listingStepsRemoved", []);
    }
    case GET_LISTING_STEPS_ERROR: {
      console.log("GET_LISTING_STEPS_ERROR", action.error)

      // return state.set("errorMessage", "error.edit");
      return state;
    }
    case CLEAR_LISTING_STEPS: {
      console.log("CLEAR_LISTING_STEPS")

      return state.set('listingSteps', []);
    }
    case REMOVE_LISTING_SUCCESS:{
      console.log('REMOVE_LISTING_SUCCESS', action.listing)
      let originalProfiles = Object.assign([], state.get('listings'));
      const listings = originalProfiles.filter(listing => listing.id != action.listing.id)

      return state.set('listings', listings);
    }
    case REMOVE_LISTING_ERROR: {
      console.log("REMOVE_LISTING_ERROR", action.error)

      return state;
    }
    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case CREATE_LISTING_SUCCESS:
    {
      const listings = Object.assign([], state.get('listings'));
      if (action.listing){
        listings.unshift(action.listing);
      }
      console.log("CREATE_LISTING_SUCCESS", action.listing, listings)
      return state.set('listings', listings)
      .set('listingAdded', true);
    }

    case CREATE_LISTING_ERROR: {
      console.log("CREATE_LISTING_ERROR", action.error)

      return state;
    }

    case EDIT_LISTING_SUCCESS:{
      console.log('EDIT_LISTING_SUCCESS', action.listing)
      const listings = Object.assign([], state.get('listings'));
      console.log('before:',listings)
      const listingIndex = listings.findIndex(x => x.id == action.listing.id);
      for (let p in action.listing){
        listings[listingIndex][p] = action.listing[p]
      }
      return state.set('listings', listings).set("listingSteps", []).set("listingStepsRemoved", []);
    }
    case EDIT_LISTING_ERROR: {
      console.log("EDIT_LISTING_ERROR", action.error)

      return state;
    }
    case UPDATE_LISTING_PLATFORM_LISTINGS:{
      const listings = Object.assign([], state.get('listings'));
      const listingIndex = listings.findIndex(x => x.id == action.listing.id);

      listings[listingIndex].platformListings = listings[listingIndex].platformListings + action.platformListings.length;
      return state.set('listings', listings);
    }
    case REMOVE_LISTING_PLATFORM_LISTINGS:{
      const listings = Object.assign([], state.get('listings'));
      const listingIndex = listings.findIndex(x => x.id == action.listing.id);

      listings[listingIndex].platformListings = listings[listingIndex].platformListings - 1;
      return state.set('listings', listings);
    }

    case RESET_LISTING_SUCCESS:{
      console.log('RESET_LISTING_SUCCESS', action.listingId)
      window.location.reload();
      return state;
    }
    case RESET_LISTING_ERROR: {
      console.log("RESET_LISTING_ERROR", action.error)

      return state;
    }
    case INVITE_INTERVIEW_SUCCESS:{
      console.log('INVITE_INTERVIEW_SUCCESS', action)
      return state;
    }
    case INVITE_INTERVIEW_ERROR: {
      console.log("INVITE_INTERVIEW_ERROR", action.error)

      return state;
    }
    case DOWNLOAD_LISTING_DATA: {
      console.log("DOWNLOAD_LISTING_DATA", action)
      return state.set('downloading', true);
    }
    case DOWNLOAD_LISTING_DATA_SUCCESS:{
      // We should update this to use downloadBlobFile util to standarize how we download files
      console.log('DOWNLOAD_LISTING_DATA_SUCCESS', action)
      const data = action.result
      const result = data.result
      if(result && result.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        const link = document.createElement('a');
        const url = URL.createObjectURL(result);
        link.href = url;
        link.download = "Emi-"+data.stepName.split(' ').join('')+"-"+data.listingId+".xlsx";
        link.click();
      }
      return state.set('downloading', false);
    }
    case DOWNLOAD_LISTING_DATA_ERROR: {
      console.log("DOWNLOAD_LISTING_DATA_ERROR", action.error)
      return state;
    }
    case DOWNLOAD_STEP_DATA: {
      console.log("DOWNLOAD_STEP_DATA", action)
      return state.set('downloading', true)
    }
    case DOWNLOAD_STEP_DATA_SUCCESS:{
      // We should update this to use downloadBlobFile util to standarize how we download files
      console.log('DOWNLOAD_STEP_DATA_SUCCESS', action)
      const data = action.result
      const result = data.result
      if(result && result.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        const link = document.createElement('a');
        const url = URL.createObjectURL(result);
        link.href = url;
        link.download = "Emi-"+data.stepName.split(' ').join('')+"-"+data.listingId+".xlsx";
        link.click();
      }

      return state.set('downloading', false)
    }
    case DOWNLOAD_STEP_DATA_ERROR: {
      console.log("DOWNLOAD_STEP_DATA_ERROR", action.error)
      return state;
    }
    case UPDATE_LISTING_STEP: {
      console.log("UPDATE_LISTING_STEP", action.steps)
      
      return state.set('listingSteps', action.steps);
    }
    case DELETE_LISTING_STEP: {
      const listingSteps = state.get("listingSteps");
      let newListingSteps = listingSteps
      if (action.step.id < 0){
        newListingSteps = listingSteps.filter(s => s.id !== action.step.id);
      } else {
        newListingSteps = newListingSteps.map(s => {
          if (s.id === action.step.id){
            s.deleted = true
          }
          return s
        })
      }
      newListingSteps = reEnumerateSteps(newListingSteps)
      return state.set('listingSteps', newListingSteps);
    }
    case RECOVER_LISTING_STEP: {
      const listingSteps = state.get("listingSteps");
      let newListingSteps = listingSteps.map(s => {
          if (s.id === action.step.id){
            s.deleted = false
          }
          return s
        })
      newListingSteps = reEnumerateSteps(newListingSteps)
      return state.set('listingSteps', newListingSteps);
    }
    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    default:
      return state;
  }
}

export default listingPageReducer;
