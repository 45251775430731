/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOADING,
  GET_SUGGESTIONS_SUCCESS,
  GET_SUGGESTIONS_ERROR,
  CREATE_SUGGESTION_SUCCESS,
  CREATE_SUGGESTION_ERROR,
  CLEAR_MESSAGE,
} from './constants';

const initialState = fromJS({
  suggestions: [],
  loading: false,
  message: '',
});

function suggestionPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUGGESTIONS_SUCCESS: {
      console.log('GET_SUGGESTIONS_SUCCESS', action.suggestions)
      const suggestions = Object.assign([], action.suggestions);

      return state.set('suggestions', suggestions);
    }
    case GET_SUGGESTIONS_ERROR: {
      console.log("GET_SUGGESTIONS_ERROR", action.error)

      return state;
    }
    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case CREATE_SUGGESTION_SUCCESS:
    {
      const suggestions = Object.assign([], state.get('suggestions'));
      if (action.suggestion){
        suggestions.unshift(action.suggestion);
      }
      console.log("CREATE_SUGGESTION_SUCCESS", action.suggestion, suggestions)
      return state.set('suggestions', suggestions)
    }

    case CREATE_SUGGESTION_ERROR: {
      console.log("CREATE_SUGGESTION_ERROR", action.error)

      return state;
    }


    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    default:
      return state;
  }
}

export default suggestionPageReducer;
