import React from "react";
import { useIntl } from 'react-intl';
import Box from "@mui/material/Box";
import messages from '../messages';
import Icon from "../../../v2/components/icons/Icon";

const CustomIcon = ({ name }) => (
  <Box display="inline-block">
    <Icon name={name} height={16} width={16} />
  </Box>
)

const AwesomeIcon = () => <CustomIcon name="awesome" />

const ScienceIcon = () => <CustomIcon name="science" />

const Icons = () => (
  <Box mr={1} display="inline-block">
    <ScienceIcon />
    <AwesomeIcon />
  </Box>
)

const Caret = () => (
  <Box
    color="rgba(0, 0, 0, 0.6)"
    fontSize={16}
    fontFamily="Roboto"
    fontWeight={400}
    ml={1}
    display="inline-block"
  >›</Box>
)

export default () => {
  const { formatMessage } = useIntl()
  return (
    <Box
      color="#168E55"
      padding={1}
      borderRadius={(theme) => theme.spacing(1)}
      fontSize={14}
      fontWeight={600}
      fontFamily="Lato"
    >
      <Icons />
      {formatMessage(messages.testEnvironment)}
      <Caret />
    </Box>
  )
}