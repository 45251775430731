import React from 'react'
import { makeStyles } from "@material-ui/core"
import CustomButton from './CustomButton'

const useStyles = makeStyles((theme) => ({
  ...theme.styles.buttons.error
}))

const ErrorButton = (props) => {
  const styles = useStyles()
  return <CustomButton
    {...props}
    styles={styles}
  />
}

export default ErrorButton