
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EmiIcon from '../icons/EmiIcon';

const useStyles = makeStyles((theme) => ({
  emiLogo: theme.styles.buttons.emiLogo,
  icon: theme.styles.buttons.emiLogo.icon
}))

const EmiLogoButton = (props) => {
  const classes = useStyles()
  return (
    <IconButton {...props} className={classes.emiLogo}>
      <EmiIcon className={classes.icon} />
    </IconButton>
  )
}

export default EmiLogoButton