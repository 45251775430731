/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOADING,
  GET_PLATFORM_LISTINGS_SUCCESS,
  GET_PLATFORM_LISTINGS_ERROR,
  CREATE_PLATFORM_LISTING_SUCCESS,
  CREATE_PLATFORM_LISTING_ERROR,
  RESET_CREATE_PLATFORM_LISTING,
  REMOVE_PLATFORM_LISTING_SUCCESS,
  REMOVE_PLATFORM_LISTING_ERROR,
  EDIT_PLATFORM_LISTING_SUCCESS,
  EDIT_PLATFORM_LISTING_ERROR,
  CLEAR_MESSAGE,
} from './constants';

const initialState = fromJS({
  platformListings: [],
  loading: false,
  message: '',
  platformListingAdded: false
});

function platformListingPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PLATFORM_LISTINGS_SUCCESS: {
      console.log('GET_PLATFORM_LISTINGS_SUCCESS', action.platformListings)
      const platformListings = Object.assign([], action.platformListings);

      return state.set('platformListings', platformListings);
    }
    case GET_PLATFORM_LISTINGS_ERROR: {
      console.log("GET_PLATFORM_LISTINGS_ERROR", action.error)

      return state;
    }
    case REMOVE_PLATFORM_LISTING_SUCCESS:{
      console.log('REMOVE_PLATFORM_LISTING_SUCCESS', action.platformListing)
      let originalPlatformListings = Object.assign([], state.get('platformListings'));
      const platformListings = originalPlatformListings.filter(platformListing => platformListing.id != action.platformListing.id)

      return state.set('platformListings', platformListings);
    }
    case REMOVE_PLATFORM_LISTING_ERROR: {
      console.log("REMOVE_PLATFORM_LISTING_ERROR", action.error)

      return state;
    }
    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case CREATE_PLATFORM_LISTING_SUCCESS:
    {
      const platformListings = Object.assign([], state.get('platformListings'));
      if (action.platformListing){
        platformListings.unshift(action.platformListing);
      }
      console.log("CREATE_PLATFORM_LISTING_SUCCESS", action.platformListing, platformListings)
      return state.set('platformListings', platformListings)
      .set('platformListingAdded', true);
    }

    case CREATE_PLATFORM_LISTING_ERROR: {
      console.log("CREATE_PLATFORM_LISTING_ERROR", action.error)

      return state;
    }
    case RESET_CREATE_PLATFORM_LISTING:{
      return state.set('platformListingAdded', false);
    }
    case EDIT_PLATFORM_LISTING_SUCCESS:{
      console.log('EDIT_PLATFORM_LISTING_SUCCESS', action.platformListing)
      const platformListings = Object.assign([], state.get('platformListings'));
      const platformListingIndex = platformListings.findIndex(x => x.id == action.platformListing.id);
      platformListings[platformListingIndex] = action.platformListing;
      return state.set('platformListings', platformListings);
    }
    case EDIT_PLATFORM_LISTING_ERROR: {
      console.log("EDIT_PLATFORM_LISTING_ERROR", action.error)

      return state;
    }
    case CLEAR_MESSAGE:{
      console.log('platformListings CLEAR_MESSAGE')
      return state.set('message', '');
    }

    default:
      return state;
  }
}

export default platformListingPageReducer;
