import { SIGN_OUT, SIGN_IN } from './constants';

export function loginAction() {
  return {
    type: SIGN_IN
  };
}

export function logoutAction() {
  return {
    type: SIGN_OUT
  };
}

