/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  GET_LISTING,
  GET_LISTINGS,
  GET_LISTING_STEPS,
  CREATE_LISTING,
  REMOVE_LISTING,
  EDIT_LISTING,
  RESET_LISTING,
  INVITE_INTERVIEW,
  DOWNLOAD_LISTING_DATA,
  DOWNLOAD_STEP_DATA,
  DEMO_SEND_REMINDER,
  DEMO_SEND_FEEDBACK,
} from './constants';

import {
  getListingSuccess,
  getListingError,
  getListingsSuccess,
  getListingsError,
  getListingStepsSuccess,
  getListingStepsError,
  removeListingSuccess,
  removeListingError,
  createListingSuccess,
  createListingError,
  editListingSuccess,
  editListingError,
  resetListingError,
  inviteToInterviewSuccess,
  downloadListingDataSuccess,
  downloadStepDataSuccess,
  demoSendReminderSuccess,
  demoSendReminderError,
  demoSendFeedbackError,
  demoSendFeedbackSuccess,
  setLoading
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sListing(action) {
  const requestURL = `${root}/listings/`+action.listingId;
  yield put(setLoading(true));
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getListingSuccess(result));

    yield put(setLoading(false));

  } catch (err) {
    yield put(getListingError(err));
  }
}

export function* getListingApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_LISTING, sListing);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sListings() {
  const requestURL = `${root}/listings`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getListingsSuccess(result));

  } catch (err) {
    yield put(getListingsError(err));
  }
}

export function* getListingsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_LISTINGS, sListings);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


export function* sListingSteps(action) {
  const requestURL = `${root}/listings/steps/${action.listing.id}/full?showDeleted=true`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getListingStepsSuccess(result));

  } catch (err) {
    yield put(getListingStepsError(err));
  }
}

export function* getListingStepsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_LISTING_STEPS, sListingSteps);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


export function* sRemoveListing(action) {
  const requestURL = `${root}/listings/`+action.listing.id;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log(result)
    yield put(removeListingSuccess(action.listing));

  } catch (err) {
    yield put(removeListingError(err));
  }
}

export function* removeListingApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(REMOVE_LISTING, sRemoveListing);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCreateListing(action) {
  const requestURL = `${root}/listings`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.listing),
    });
    yield put(createListingSuccess(result));
    yield put(push('/listings'));
  } catch (err) {
    yield put(createListingError(err));
  }
}

export function* createListingApi() {
  const watcher = yield takeLatest(CREATE_LISTING, sCreateListing);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sEditListing(action) {
  try {
    console.log('sEditListing', action)
    const requestURL = `${root}/listings/`+action.listing.id;
    console.log(requestURL, action.listing)
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.listing),
    });
    yield put(editListingSuccess(result));

    yield put(push('/listings'));
  } catch (err) {
    yield put(editListingError(err));
  }
}

export function* editListingApi() {
  const watcher = yield takeLatest(EDIT_LISTING, sEditListing);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


export function* sResetListing(action) {
  try {
    console.log('sResetListing', action)
    const requestURL = `${root}/listings/reset/`+action.listingId;
    console.log(requestURL)

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    window.location.reload();
    // yield put(push('/listing/dashboard/'+action.listingId));
  } catch (err) {
    yield put(resetListingError(err));
  }
}

export function* resetListingApi() {
  const watcher = yield takeLatest(RESET_LISTING, sResetListing);

}

export function* sInviteToInterview(action) {
  try {
    console.log('sInviteToInterview', action)
    const requestURL = `${root}/interviews/invite`;
    console.log(requestURL)

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({listingId: action.listingId, stepId: action.stepId}),
    });

    yield put(inviteToInterviewSuccess(result))
  } catch (err) {
    yield put(inviteToInterviewSuccess(err));
  }
}

export function* inviteToInterviewApi() {
  const watcher = yield takeLatest(INVITE_INTERVIEW, sInviteToInterview);

}

export function* sDemoSendReminder(action) {
  console.log('345')
  try {

    const requestURL = `${root}/demo-send-reminders`;

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({listingId: action.listingId}),
    });

    yield put(demoSendReminderSuccess(result))
  } catch (err) {
    yield put(demoSendReminderError(err));
  }
}

export function* demoSendReminderApi() {
  console.log('3456')
  const watcher = yield takeLatest(DEMO_SEND_REMINDER, sDemoSendReminder);
}

export function* sDemoSendFeedback(action) {
  try {

    const requestURL = `${root}/demo-send-feedback`;

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({listingId: action.listingId}),
    });

    yield put(demoSendFeedbackSuccess(result))
  } catch (err) {
    yield put(demoSendFeedbackError(err));
  }
}

export function* demoSendFeedbackApi() {
  const watcher = yield takeLatest(DEMO_SEND_FEEDBACK, sDemoSendFeedback);
}

export function* sDownloadListingData(action) {
  console.log('sDownloadListingData', action)
  const requestURL = new URL(`${root}/views/rawData`);
  let params = {
    listingId: action.listingId,
    stepId: action.stepId,
    fromDate: action.fromDate,
    toDate: action.toDate
  }
  Object.keys(params).forEach(key => requestURL.searchParams.append(key, params[key]))
  try {
    // Call our request helper (see 'utils/request')

    const result = yield call(request, requestURL.href, {
      headers: {
        'Content-Disposition': 'attachment;'
      },
    });

    yield put(downloadListingDataSuccess(Object.assign({result}, action)))
  } catch (err) {
    yield put(downloadListingDataSuccess(err));
  }
}

export function* downloadListingDataApi() {
  const watcher = yield takeLatest(DOWNLOAD_LISTING_DATA, sDownloadListingData);

}


export function* sDownloadStepData(action) {
  console.log('sDownloadStepData', action)
  const requestURL = new URL(`${root}/views/downloadStepData`);
  let params = {
    listingId: action.listingId,
    stepId: action.stepId,
    slotId: action.slotId,
    stepName: action.stepName
  }
  Object.keys(params).forEach(key => requestURL.searchParams.append(key, params[key]))
  try {
    // Call our request helper (see 'utils/request')

    const result = yield call(request, requestURL.href, {
      headers: {
        'Content-Disposition': 'attachment;'
      },
    });

    yield put(downloadStepDataSuccess(Object.assign({result}, action)))
  } catch (err) {
    yield put(downloadStepDataSuccess(err));
  }
}

export function* downloadStepDataApi() {
  const watcher = yield takeLatest(DOWNLOAD_STEP_DATA, sDownloadStepData);

}


// All sagas to be loaded
export default [
  getListingApi,
  getListingsApi,
  getListingStepsApi,
  removeListingApi,
  createListingApi,
  editListingApi,
  resetListingApi,
  inviteToInterviewApi,
  downloadListingDataApi,
  downloadStepDataApi,
  demoSendReminderApi,
  demoSendFeedbackApi
];
