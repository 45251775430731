/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  GET_LISTING,
  GET_LISTING_SUCCESS,
  GET_LISTING_ERROR,
  GET_LISTINGS,
  GET_LISTINGS_SUCCESS,
  GET_LISTINGS_ERROR,
  CLEAR_LISTING_STEPS,
  GET_LISTING_STEPS,
  GET_LISTING_STEPS_SUCCESS,
  GET_LISTING_STEPS_ERROR,
  CREATE_LISTING,
  CREATE_LISTING_SUCCESS,
  CREATE_LISTING_ERROR,
  REMOVE_LISTING,
  REMOVE_LISTING_SUCCESS,
  REMOVE_LISTING_ERROR,
  EDIT_LISTING,
  EDIT_LISTING_SUCCESS,
  EDIT_LISTING_ERROR,
  CLEAR_MESSAGE,
  UPDATE_LISTING_PLATFORM_LISTINGS,
  REMOVE_LISTING_PLATFORM_LISTINGS,
  RESET_LISTING,
  RESET_LISTING_SUCCESS,
  RESET_LISTING_ERROR,
  INVITE_INTERVIEW,
  INVITE_INTERVIEW_SUCCESS,
  INVITE_INTERVIEW_ERROR,

  DEMO_SEND_REMINDER,
  DEMO_SEND_REMINDER_SUCCESS,
  DEMO_SEND_REMINDER_ERROR,
  DEMO_SEND_FEEDBACK,
  DEMO_SEND_FEEDBACK_SUCCESS,
  DEMO_SEND_FEEDBACK_ERROR,

  DOWNLOAD_LISTING_DATA,
  DOWNLOAD_LISTING_DATA_SUCCESS,
  DOWNLOAD_LISTING_DATA_ERROR,
  DOWNLOAD_STEP_DATA,
  DOWNLOAD_STEP_DATA_SUCCESS,
  DOWNLOAD_STEP_DATA_ERROR,
  UPDATE_LISTING_STEP, DELETE_LISTING_STEP,
  RECOVER_LISTING_STEP
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}

export function getListing(listingId) {
  console.log("GET_LISTING")
  return {
    type: GET_LISTING,
    listingId
  };
}

export function getListingSuccess(listing) {
  return {
    type: GET_LISTING_SUCCESS,
    listing
  };
}

export function getListingError(error) {
  return {
    type: GET_LISTING_ERROR,
    error
  };
}
export function getListings() {
  console.log("GET_LISTINGS")
  return {
    type: GET_LISTINGS
  };
}

export function getListingsSuccess(listings) {
  return {
    type: GET_LISTINGS_SUCCESS,
    listings
  };
}

export function getListingsError(error) {
  return {
    type: GET_LISTINGS_ERROR,
    error
  };
}

export function clearListingSteps() {
  console.log("CLEAR_LISTING_STEPS")
  return {
    type: CLEAR_LISTING_STEPS
  };
}

export function getListingSteps(listing) {
  console.log("GET_LISTING_STEPS")
  return {
    type: GET_LISTING_STEPS,
    listing
  };
}

export function getListingStepsSuccess(listingSteps) {
  return {
    type: GET_LISTING_STEPS_SUCCESS,
    listingSteps
  };
}

export function getListingStepsError(error) {
  return {
    type: GET_LISTING_STEPS_ERROR,
    error
  };
}

export function createListing(listing){
  console.log("CREATE_LISTING")
  return {
    type: CREATE_LISTING,
    listing
  }
}


export function createListingSuccess(listing){
  return {
    type: CREATE_LISTING_SUCCESS,
    listing
  }
}


export function createListingError(error){
  return {
    type: CREATE_LISTING_ERROR,
    error
  }
}

export function removeListing(listing){
  return {
    type: REMOVE_LISTING,
    listing
  }
}


export function removeListingSuccess(listing){
  return {
    type: REMOVE_LISTING_SUCCESS,
    listing
  }
}


export function removeListingError(error){
  return {
    type: REMOVE_LISTING_ERROR,
    error
  }
}

export function editListing(listing){
  console.log("EDIT_LISTING")
  return {
    type: EDIT_LISTING,
    listing
  }
}


export function editListingSuccess(listing){
  return {
    type: EDIT_LISTING_SUCCESS,
    listing
  }
}


export function editListingError(error){
  return {
    type: EDIT_LISTING_ERROR,
    error
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function updateListingPlatformListings(listing, platformListings){
  return{
    type: UPDATE_LISTING_PLATFORM_LISTINGS,
    listing,
    platformListings
  }
}

export function removeListingPlatformListings(listing){
  return{
    type: REMOVE_LISTING_PLATFORM_LISTINGS,
    listing,
  }
}

export function resetListing(listingId) {
  console.log("RESET_LISTING")
  return {
    type: RESET_LISTING,
    listingId
  };
}

export function resetListingSuccess(listingId) {
  return {
    type: RESET_LISTING_SUCCESS,
    listingId
  };
}

export function resetListingError(error) {
  return {
    type: RESET_LISTING_ERROR,
    error
  };
}

export function inviteToInterview(listingId, stepId) {
  console.log("INVITE_LISTING")
  return {
    type: INVITE_INTERVIEW,
    listingId, stepId
  };
}

export function inviteToInterviewSuccess(result) {
  return {
    type: INVITE_INTERVIEW_SUCCESS,
    result
  };
}

export function inviteToInterviewError(error) {
  return {
    type: INVITE_INTERVIEW_ERROR,
    error
  };
}

export function demoSendReminder(listingId) {
  console.log('34')
  return {
    type: DEMO_SEND_REMINDER,
    listingId
  };
}

export function demoSendReminderSuccess(result) {
  return {
    type: DEMO_SEND_REMINDER_SUCCESS,
    result
  };
}

export function demoSendReminderError(error) {
  return {
    type: DEMO_SEND_REMINDER_ERROR,
    error
  };
}

export function demoSendFeedback(listingId) {
  return {
    type: DEMO_SEND_FEEDBACK,
    listingId
  };
}

export function demoSendFeedbackSuccess(result) {
  return {
    type: DEMO_SEND_FEEDBACK_SUCCESS,
    result
  };
}

export function demoSendFeedbackError(error) {
  return {
    type: DEMO_SEND_FEEDBACK_ERROR,
    error
  };
}

export function downloadListingData(listingId, stepId, stepName, fromDate, toDate) {
  console.log("DOWNLOAD_LISTING_DATA")
  return {
    type: DOWNLOAD_LISTING_DATA,
    listingId, stepId, stepName, fromDate, toDate
  };
}

export function downloadListingDataSuccess(result) {
  return {
    type: DOWNLOAD_LISTING_DATA_SUCCESS,
    result
  };
}

export function downloadListingDataError(error) {
  return {
    type: DOWNLOAD_LISTING_DATA_ERROR,
    error
  };
}

export function downloadStepData(listingId, stepId, slotId, stepName) {
  console.log("DOWNLOAD_STEP_DATA")
  return {
    type: DOWNLOAD_STEP_DATA,
    listingId, stepId, slotId, stepName
  };
}

export function downloadStepDataSuccess(result) {
  return {
    type: DOWNLOAD_STEP_DATA_SUCCESS,
    result
  };
}

export function downloadStepDataError(error) {
  return {
    type: DOWNLOAD_STEP_DATA_ERROR,
    error
  };
}

//
// export function getInterviewConfig(listingId, stepId) {
//   console.log("GET_INTERVIEW_CONFIG", listingId, stepId)
//   return {
//     type: GET_INTERVIEW_CONFIG,
//     listingId, stepId,
//   };
// }
//
// export function getInterviewConfigSuccess(result) {
//   return {
//     type: GET_INTERVIEW_CONFIG_SUCCESS,
//     result
//   };
// }
//
// export function getInterviewConfigError(error) {
//   return {
//     type: GET_INTERVIEW_CONFIG_ERROR,
//     error
//   };
// }

export function updateListingStep(steps) {
  return {
    type: UPDATE_LISTING_STEP,
    steps
  };
}

export function deleteListingStep(step) {
  return {
    type: DELETE_LISTING_STEP,
    step
  };
}

export function recoverListingStep(step) {
  return {
    type: RECOVER_LISTING_STEP,
    step
  }
}
