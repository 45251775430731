import request from '../utils/request'
const config = require('../config');

/**
 * Tracks event in CBO
 * @param event
 */
export default async function track(event){
  if(!config.params.TRACKING_ENABLED) {
    console.log(`tracking ${JSON.stringify(event)}, but is not enabled`)
    return Promise.resolve()
  }
  return await request(`${config.params.API_SERVER}/track`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...event,
      location: window.location.pathname,
      query: window.location.search.slice(1).split('&').reduce((acc,next) => ({...acc, [next.split('=')[0]]:next.split('=')[1]}), {})
    })
  })
}
