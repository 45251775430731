import React, { useEffect, useState, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import NewInterview from '../../../containers/StepViews/Step/Interview/newInterview'
import { getActiveInterview } from '../../../utils/connector'
import { ErrorDialog } from '../../../v2/components/dialogs/ErrorDialog'
import { buildLayoutConfig, getErrorMessage } from './utils'
import messages from '../messages'

const InviteCandidateToInterview = (props) => {
  const {
    candidateId,
    candidateName,
    stepId,
    withExperimentalLayout,
    withDecentralizedLayout,
    listingId,
    onClose,
    onSucceed,
    experimental_config
  } = props

  const [errorMessage, setErrorMessage] = useState(null)
  const layout = buildLayoutConfig(withExperimentalLayout, withDecentralizedLayout)

  useEffect(() => {
    const getInterviewData = async () => {
      if (candidateId && stepId) {
        const interviewData = await getActiveInterview(candidateId, stepId)
        if (interviewData) {
          const error = getErrorMessage({ errorCode: 'active_interview' })
          setErrorMessage(error)
        }
      }
    }

    getInterviewData()
  }, [candidateId, stepId])

  const handleError = useCallback((error) => {
    const message = getErrorMessage(error.response)
    setErrorMessage(message)
  }, [])

  return errorMessage && errorMessage.code ? (
    <ErrorDialog
      onClose={onClose}
      title={<FormattedMessage {...messages.actions.inviteToInterview} />}
      errorTitle={<FormattedMessage {...messages.inviteToInterview.error.errorTitle} />}
      errorMessage={<FormattedMessage {...errorMessage.message} />}
      errorCode={errorMessage.code}
    />
  ) : (
    <NewInterview
      stepId={stepId}
      listingId={listingId}
      candidateId={candidateId}
      candidateName={candidateName}
      onClose={onClose}
      onCreate={onSucceed}
      onError={handleError}
      layout={layout}
      experimental_config={experimental_config}
    />
  )
}

export default InviteCandidateToInterview
