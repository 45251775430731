import {
  FEATURE_LINK_BUILDER,
  FEATURE_MANUALLY_ADD_CANDIDATE,
  REQUEST_FEATURE_CONFIG,
  REQUEST_FEATURE_CONFIG_SUCCEED,
} from './constants';

export const requestFeatureConfig = (featureName, params) => ({
  type: REQUEST_FEATURE_CONFIG,
  featureName,
  params,
});

export const requestFeatureConfigSucceed = (request, data) => ({
  type: REQUEST_FEATURE_CONFIG_SUCCEED,
  request,
  data,
});

export const requestManuallyAddCandidateConfig = stepId =>
  requestFeatureConfig(FEATURE_MANUALLY_ADD_CANDIDATE, { stepId });

export const requestLinkBuilderConfig = subsidiaryId =>
  requestFeatureConfig(FEATURE_LINK_BUILDER, { subsidiaryId, stepId: subsidiaryId });//TODO remove "stepId: subsidiaryId"
