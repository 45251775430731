import moment from 'moment'

class Cache {
	constructor(store, ttl) {
		this.ttlUnit = ttl.unit
		this.ttlValue = ttl.value
		this.store = store
	}

	async get(url) {
		try {
			const item = await this.store.get(url)
			if (!item) {
				return null
			}
			const { value, expiration } = JSON.parse(item)
			if (moment().isAfter(moment(expiration))) {
				await this.store.delete(url)
				return null
			}
			return value
		} catch (e) {
			return null
		}
	}

	async put(url, value) {
		try {
			await this.store.set(url, JSON.stringify({ value, expiration: moment().add(this.ttlValue, this.ttlUnit).toISOString(), url }))
			return true
		} catch (e) {
			return false
		}
	}
}

export default Cache