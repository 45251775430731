/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SEARCH_CANDIDATE_SUCCESS,
  SEARCH_CANDIDATE_ERROR,
} from './constants';

const initialState = fromJS({
  search: {},
  loading: false,
  message: '',
  log: {
    show: false,
    data: null
  }
});

function candidateReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_CANDIDATE_SUCCESS:{
      console.log("SEARCH_CANDIDATE_SUCCESS", action)
      const candidatesObject = action.candidates;
      const search = Object.assign({}, state.get('search'))
      const results = candidatesObject.results;
      const tableIndex = parseInt(candidatesObject.paging.offset)
      const limit = parseInt(candidatesObject.paging.limit)

      search.query = candidatesObject;
      delete search.query.results

      // create the tables if needed
      if (!search.results){
        search.results = {}
      }
      for (let di in results){
        search.results[tableIndex+parseInt(di)] = results[di]
      }
      for (let i = results.length; i < limit; i++) {
        delete search.results[i]
      }

      return state.set('search', search);
    } case SEARCH_CANDIDATE_ERROR:{
      console.log("SEARCH_CANDIDATE_ERROR", action.error)
      return state;
    } default: {
      return state;
    }
  }
}

export default candidateReducer;
