import React, { useContext, useEffect } from 'react'
import Menu from "./Menu"
import MenuV2 from "./MenuV2"
import { injectIntl } from 'react-intl';
import messages from '../messages';
import { connect } from 'react-redux';
import {
  requestAdvance,
  requestCancelInterview,
  requestGoToCandidate,
  requestHire,
  requestInviteToInterview,
  requestLog,
  requestReject,
  requestRescheduleInterview,
  requestResume,
  requestStandBy,
  showChangeVacancy,
  requestEditCandidateData
} from '../../../store/candidateActions/actions';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { ActionsContext } from './ActionsContext';
import { useCandidateActionsConfig } from '../../../containers/ConfigProvider';

const getGeneralActions = ({ candidateId, listingId, stepId, onRequestLog, onRequestResume, name, channelUserId, onRequestGoToCandidate, step, state, subsidiaryId }, { formatMessage }) => {
  const actions = [
    {
      label: formatMessage(messages.actions.resume),
      onClick: () => onRequestResume(candidateId, listingId, stepId)
    },
    {
      label: formatMessage(messages.actions.record),
      onClick: () => onRequestLog(candidateId, listingId, name, channelUserId)
    },
  ];

  if (
    step.type === 'INTERVIEW' &&
    state === 'PENDING_VEREDICT' &&
    subsidiaryId !== 80 // JIRA: HIR-230
  ) {
    actions.push({
      label: formatMessage(messages.actions.goToCandidate),
      onClick: () => onRequestGoToCandidate(candidateId, listingId, stepId)
    });
  }

  return actions;
}

const getInterviewActions = ({ candidateId, listingId, stepId, step, state, name, onRequestCancelInterview, onRequestInviteToInterview, onRequestRescheduleInterview, assignedVacancyId }, { formatMessage }, { isActionEnabled }) => {
  const actions = []

  const isBoolean = (value) => value === true || value === false
  const withExperimentalLayout = step && step.config
    && isBoolean(step.config.hasInterviewScheduling)
    && !step.config.hasInterviewScheduling
    && step.config.interviewType === 'TELEPHONE'

  const withDecentralizedLayout = step 
    && step.config
    && step.config
    && isBoolean(step.config.manualSlotCreation)
    && !step.config.manualSlotCreation
  if (isActionEnabled('cancel', stepId, state)) {
    actions.push({
      label: formatMessage(messages.actions.cancelInterview),
      onClick: () => onRequestCancelInterview(candidateId, stepId)
    })
  }
  if (isActionEnabled('invite', stepId, state) && !assignedVacancyId) {
    actions.push({
      label: formatMessage(withExperimentalLayout ? messages.actions.addToApplication : messages.actions.inviteToInterview),
      onClick: () => onRequestInviteToInterview(candidateId, name, listingId, stepId, withExperimentalLayout, withDecentralizedLayout, step.config)
    })
  }
  if (isActionEnabled('reschedule', stepId, state)) {
    actions.push({
      label: formatMessage(messages.actions.rescheduleInterview),
      onClick: () => onRequestRescheduleInterview(candidateId, listingId, stepId)
    })
  }
  return actions
}

const getAvailableEditCandidateDataActions = (config, state) => {
  const actions = []
  if (config) {
    const stepActions = Array.isArray(config) 
      ? config
      : [config]
    for (const stepAction of stepActions) {
      const { states } = stepAction
      if (!states || (Array.isArray(states) && states.includes(state))) {
        actions.push(stepAction)
      }
    }
  }
  return actions
}

const isEditCandidateFilesActionAvailable = (config, state) => {
  if (config) {
    const { states } = config
    if (!states) {
      return true
    }
    if (Array.isArray(states) && states.includes(state)) {
      return true
    }
  }
  return false
}

const getOtherActions = ({ subsidiaryId, candidateId, listingId, stepId, step, state, name, channelUserId, onRequestHire, onRequestAdvance, onRequestReject, onRequestStandBy, onRequestShowChangeVacancy, onRequestEditCandidateData, inheritedActions, assignedVacancyId }, { formatMessage }, actionsContext, { isActionEnabled, getActionConfig }) => {
  const actions = []

  if (isActionEnabled('advance', stepId, state)) {
    actions.push({
      label: formatMessage(messages.actions.advance),
      onClick: () => onRequestAdvance(candidateId, listingId, subsidiaryId, step.id),
      icon: CheckIcon,
      color: "#0F5E38"
    })
  }

  if (isActionEnabled('hire', stepId, state)) {
    actions.push({
      label: formatMessage(messages.actions.hire),
      onClick: () => onRequestHire(candidateId, name, listingId, channelUserId)
    })
  }
  if(isActionEnabled('reject', stepId, state)){
    actions.push({
      label: formatMessage(messages.actions.reject),
      onClick: () => onRequestReject(candidateId, listingId, step.id, step.type, name, state),
      icon: CloseIcon,
      color: "#B13D3D"
    })
  }

  if(isActionEnabled('standBy', stepId, state)) {
    actions.push({
      label: formatMessage(messages.actions.standBy),
      onClick: () => onRequestStandBy(candidateId, listingId, step.id, subsidiaryId, step.type, name, state),
      icon: PauseIcon,
      color: "#383838"
    })
  }

  if(isActionEnabled('changeVacancy', stepId, state) && !!assignedVacancyId) {
    actions.push({
      label: formatMessage(messages.actions.changeVacancy),
      onClick: () => {
        actionsContext.setState({ candidateId, listingId, stepId, subsidiaryId });
        onRequestShowChangeVacancy();
      }
    })
  }

  const editCandidateDataActions = getAvailableEditCandidateDataActions(getActionConfig('editCandidateData', stepId), state)
  if (editCandidateDataActions.length > 0) {
    for (const editCandidateDataAction of editCandidateDataActions) {
      const action = inheritedActions ? inheritedActions.find((a) => a.type === 'editCandidateData' && !!a.onClick) : null
      actions.push({
        label: editCandidateDataAction.label || formatMessage(messages.actions.editCandidateData),
        onClick: action ? action.onClick : () => onRequestEditCandidateData(candidateId, subsidiaryId, listingId, stepId, editCandidateDataAction),
        icon: EditOutlinedIcon
      })  
    }
  }

  const editCandidateFiles = getActionConfig('editCandidateFiles', stepId)
  if (isEditCandidateFilesActionAvailable(editCandidateFiles, state)) {
    actions.push({
      label: formatMessage(messages.actions.editCandidateFiles),
      onClick: () => onRequestEditCandidateData(candidateId, subsidiaryId, listingId, stepId, editCandidateFiles)
    })
  }

  return actions.sort((aAction, anotherAction) => (aAction.label < anotherAction.label) ? -1 : (aAction.label < anotherAction.label) ? 1 : 0)
}

const getIndividualActions = ({ subsidiaryId, candidateId, listingId, stepId, step, state, name, channelUserId, inheritedActions, validations, handleShowConfirmationDialog, slotId, slotState }, { formatMessage }) => {
  const actions = []
  const candidate = {
    subsidiary_id: subsidiaryId, 
    id: candidateId, 
    candidate_id: candidateId, 
    listing_id: listingId, 
    step_id: stepId, 
    step, 
    state, 
    name, 
    channel_user_id: channelUserId,
    slot_id: slotId,
    slot_state: slotState,
  }

  if (step.type === "INTERVIEW" && validations && validations.shouldAllowFeedback && validations.shouldAllowFeedback(candidate)) {
    actions.push({
      label: formatMessage(messages.ray.approve),
      onClick: () => handleShowConfirmationDialog(candidate, 'approved'),
      icon: CheckIcon,
      color: "#0F5E38"
    }, {
      label: formatMessage(messages.ray.reject),
      onClick: () => handleShowConfirmationDialog(candidate, 'rejected'),
      icon: CloseIcon,
      color: "#B13D3D"
    }, {
      label: formatMessage(messages.ray.absent),
      onClick: () => handleShowConfirmationDialog(candidate, 'not_attended'),
      icon: HorizontalRuleOutlinedIcon,
      color: "#383838"
    })
  }
  
  if (step.type !== "INTERVIEW" && validations && validations.shouldAllowApproveAction && validations.shouldAllowApproveAction()) {
    const action = inheritedActions ? inheritedActions.find((a) => a.type === 'approve-candidate' && !!a.onClick) : null
    if(action) {
      actions.push({
        label: formatMessage(messages.actions.approveCandidate),
        onClick: () => action.onClick(candidate),
        icon: CheckIcon,
        color: "#0F5E38"
      })
    }
  }

  if (step.type !== "INTERVIEW" && validations && validations.shouldAllowRejectAction && validations.shouldAllowRejectAction()) {
    const action = inheritedActions ? inheritedActions.find((a) => a.type === 'reject-candidate' && !!a.onClick) : null
    if(action) {
      actions.push({
        label: formatMessage(messages.actions.rejectCandidate),
        onClick: () => action.onClick(candidate),
        icon: CloseIcon,
        color: "#B13D3D"
      })
    }
  }

  if (step.type !== "INTERVIEW") {
    const action = inheritedActions ? inheritedActions.find((a) => a.type === 'standBy-candidate' && !!a.onClick) : null
    if(action) {
      actions.push({
        label: formatMessage(messages.actions.standByCandidate),
        onClick: () => action.onClick(candidate),
        icon: PauseIcon,
        color: "#383838"
      })
    }
  }

  return actions.sort((aAction, anotherAction) => (aAction.label < anotherAction.label) ? -1 : (aAction.label < anotherAction.label) ? 1 : 0)
}

const CandidateActions = (props) => {
  const {
    candidateId,
    listingId,
    stepId,
    step,
    name,
    state,
    channelUserId,
    intl,
    onRequestLog,
    onRequestResume,
    onRequestCancelInterview,
    onRequestGoToCandidate,
    onRequestInviteToInterview,
    onRequestRescheduleInterview,
    onRequestHire,
    onRequestAdvance,
    onRequestReject,
    onRequestStandBy,
    onRequestShowChangeVacancy,
    onRequestEditCandidateData,
    subsidiaryId,
    actions,
    validations,
    type,
    handleShowConfirmationDialog,
    slotId,
    slotState,
    assignedVacancyId
  } = props;

  const actionsContext = useContext(ActionsContext);
  const config = useCandidateActionsConfig()

  const generalActions = getGeneralActions({
    candidateId,
    listingId,
    stepId,
    onRequestLog,
    onRequestResume,
    name,
    channelUserId,
    onRequestGoToCandidate,
    step,
    state,
    subsidiaryId
  }, intl)

  let interviewActions = []
  let otherActions = []
  let individualActions = []
  // Check if the candidate in the same step that the user is viewing 
  if (stepId == step.id) {
    interviewActions = getInterviewActions({
      candidateId,
      listingId,
      stepId,
      step,
      state,
      name,
      assignedVacancyId,
      onRequestCancelInterview,
      onRequestInviteToInterview,
      onRequestRescheduleInterview
    }, intl, config)
    otherActions = getOtherActions({
      subsidiaryId,
      candidateId,
      listingId,
      stepId,
      step,
      state,
      name,
      channelUserId,
      onRequestHire,
      onRequestAdvance,
      onRequestReject,
      onRequestStandBy,
      onRequestShowChangeVacancy,
      onRequestEditCandidateData,
      inheritedActions: actions,
      assignedVacancyId
    }, intl, actionsContext, config)
    individualActions = getIndividualActions({
      subsidiaryId,
      candidateId,
      listingId,
      stepId,
      step,
      state,
      name,
      channelUserId,
      onRequestHire,
      onRequestAdvance,
      onRequestReject,
      onRequestStandBy,
      onRequestShowChangeVacancy,
      onRequestEditCandidateData,
      inheritedActions: actions,
      validations,
      handleShowConfirmationDialog,
      slotId,
      slotState
    }, intl, actionsContext, config)
  }

  if(type === 'v2') return <MenuV2
    candidateId={candidateId}
    actions={[
      individualActions,
      interviewActions,
      otherActions
    ]}
    disabled={individualActions.length + otherActions.length === 0}
  />

  return <Menu
    identifier={`action-menu-${candidateId}-${listingId}-${stepId}`}
    actions={[
      generalActions,
      interviewActions,
      otherActions
    ]}
  />
}

const mapStateToProps = (state) => {
  return {
    user: state.getIn(["global", "user"])
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestLog: (candidateId, listingId, name, channelUserId) => dispatch(requestLog(candidateId, listingId, name, channelUserId)),
    onRequestResume: (candidateId, listingId, stepId) => dispatch(requestResume(candidateId, listingId, stepId)),
    onRequestCancelInterview: (candidateId, stepId) => dispatch(requestCancelInterview(candidateId, stepId)),
    onRequestGoToCandidate: (candidateId, listingId, stepId) => dispatch(requestGoToCandidate(candidateId, listingId, stepId)),
    onRequestInviteToInterview: (candidateId, candidateName, listingId, stepId, withExperimentalLayout, withDecentralizedLayout, config) => dispatch(requestInviteToInterview(candidateId, candidateName, listingId, stepId, withExperimentalLayout, withDecentralizedLayout, config)),
    onRequestRescheduleInterview: (candidateId, listingId, stepId) => dispatch(requestRescheduleInterview(candidateId, listingId, stepId)),
    onRequestHire: (candidateId, candidateName, listingId, channelUserId) => dispatch(requestHire(candidateId, candidateName, listingId, channelUserId)),
    onRequestAdvance: (candidateId, listingId, subsidiaryId, stepId) => dispatch(requestAdvance(candidateId, listingId, subsidiaryId, stepId)),
    onRequestReject: (candidateId, listingId, stepId, stepType, name, state) => dispatch(requestReject(candidateId, listingId, stepId, stepType, name, state)),
    onRequestStandBy: (candidateId, listingId, stepId, subsidiaryId, stepType, name, state) => dispatch(requestStandBy(candidateId, listingId, stepId, subsidiaryId, stepType, name, state)),
    onRequestShowChangeVacancy: () => dispatch(showChangeVacancy()),
    onRequestEditCandidateData: (candidateId, subsidiaryId, listingId, stepId, config) => dispatch(requestEditCandidateData(candidateId, subsidiaryId, listingId, stepId, config))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CandidateActions))
