import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

import { Image, Text, View, Document, Page, StyleSheet } from '@react-pdf/renderer'


import EmiLogo from '../../assets/images/emi_logotipo2.jpg'
import CandidateSectionPdf from './sectionPdf'
import EmiIsotipo from '../../assets/images/emi_isotipo2.jpg'
import { injectIntl } from 'react-intl'

const styles = theme => ({
  root: {
    padding: "10px",
    // marginTop: "20px"
  },
});


const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

class PagePdf extends React.Component {
  state = {}

  constructor(props) {
    super(props);
  }

  render () {

    const styles = StyleSheet.create({
      header: {
        position: 'relative',
        width: '100%',
        marginBottom: '50px',
      },
      headerImage: {
        position: 'absolute',
        right: '10px',
        top: '15px',
        maxWidth: '15%',
        height: '30px'
      },
      headerBrandImage: {
        position: 'absolute',
        left: '10px',
        top: '15px',
        maxWidth: '15%',
        height: '30px'
      },
      footerImage: {
        position: 'absolute',
        left: '10px',
        bottom: '24px',
        width: '8%',
      },
      footer: {
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        fontSize: 9,
        color: 'grey',
        textAlign: 'left'

      },
      footerText: {
        fontFamily: 'Helvetica-Oblique'
      }
    });

    const phrases = [
      "Lo que no se mide, no se mejora.", 
      "Chau CV, Hola Emi 👋", 
      "Innova tu forma de reclutar, usa Emi.",  
      "El trabajo en equipo siempre es mejor.", 
      "Animate a cambiar el mundo, sumemos candidatos.", 
      "Las oportunidades no aparecen. ¡Tu las creas!", 
      "La pasión es el inicio del éxito.", 
      "Juntos somos mejores.", 
      "Seamos curiosos.", 
      "Que nos mueva la empatía.", 
      "Nuestro hacer es relevante.", 
      "Procesos de selección + humanos, + eficientes.", 
      "Valoremos a todos, sin juzgar.", 
      "Dar una oportunidad todo los días.", 
      "El que no tiene objetivos, no puede alcanzarlos.", 
      "Cuando hay ganas todo se puede.", 
      "El que se queda quieto se oxida.", 
      "La actitud correcta, te llevará al lugar correcto.", 
      "Trabaja duro en silencio y deja que tu éxito haga ruido.", 
      "Mas esencia, menos apariencia.", 
      "Sonríe para la vida, no solo para la foto."
    ]
    const phrase = phrases[Math.floor(Math.random() * phrases.length)]

    return (
      <Page size="A4" >
        <View style={styles.header}>
          <Image style={styles.headerImage}
                 src={EmiLogo}
          />
          {this.props.user?(
            <Image style={styles.headerBrandImage}
                   src={ this.props.user.subsidiary.logo || this.props.user.subsidiary.Company.logo + `?noCache=123` }
                   source={{
                     header: {
                       'Access-Control-Allow-Origin': '*'
                     }
                   }}
            />
          ):null}

        </View>
        {
          this.props.sections.map(section => <CandidateSectionPdf
            title={section.title}
            data={section.data}
            fields={section.fields}
          />)
        }
        <View style={styles.footer}>
          <Text style={styles.footerText}>"{phrase}"</Text>
        </View>
        <View style={styles.footerImage}>
          <Image
            src={EmiIsotipo}
          />

        </View>
      </Page>
    )
  }

}

PagePdf.propTypes = {
  user: PropTypes.object,
  sections: PropTypes.object

};
export default (PagePdf);
