import { useState, useEffect } from 'react';

import { getCandidate } from '../../utils/connector';

export const useFetchCandidate = (candidateId, listingId) => {
  const [candidate, setCandidate] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCandidate(candidateId, listingId);
      setCandidate(response.results[0]);
    };
    if (candidateId) {
      fetchData();
    }
  }, [candidateId]);
  return candidate;
}
