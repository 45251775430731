import { createMuiTheme } from '@material-ui/core/styles';

const themeEmi = createMuiTheme({
  palette: {
    primary: { main: '#1E88E5' },
    // primary: { main: '#2b3643' },
  },

});
export default themeEmi;
