/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  CLEAN_LOCAL_DATA,
  SEARCH_CANDIDATE,
  SEARCH_CANDIDATE_SUCCESS,
  SEARCH_CANDIDATE_ERROR,
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}


export function cleanLocalData(listingId, stepId, tableClass, rejected) {
  console.log("CLEAN_LOCAL_DATA", listingId, stepId, tableClass, rejected)
  return {
    type: CLEAN_LOCAL_DATA,
    listingId, stepId, tableClass, rejected
  };
}


export function searchCandidate(offset, limit, filters){
  console.log('SEARCH_CANDIDATE', offset, limit, filters)
  return {
    type: SEARCH_CANDIDATE,
    offset, limit, filters
  }
}

export function searchCandidateSuccess(candidates){
  return {
    type: SEARCH_CANDIDATE_SUCCESS,
    candidates
  }
}


export function searchCandidateError(error){
  console.log('SEARCH_CANDIDATE_ERROR', error)
  return {
    type: SEARCH_CANDIDATE_ERROR,
    error
  }
}
