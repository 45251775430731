/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import _ from 'lodash';
import {
  CLEAR_MESSAGE,
  LOADING,

  GET_USERS_SUCCESS,
  GET_USERS_ERROR,

  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,

  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
} from './constants';


const initialState = fromJS({
  usersMap: {},
  users: [],
  loading: false,
  message: '',
});


function userPageReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING: {
      return state.set('loading', action.loading);
    }
    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    case GET_USERS_SUCCESS: {
      console.log('GET_USERS_SUCCESS', action.users)
      const users = Object.assign([], action.users);
      return state
        .set('users', users)
        .set('usersMap', _.keyBy(action.users, 'id'));
    }
    case GET_USERS_ERROR: {
      console.log("GET_USERS_ERROR", action.error)
      return state;
    }
    case CREATE_USER_SUCCESS:
    {
      const users = Object.assign([], state.get('users'));
      if (action.user){
        users.unshift(action.user);
      }
      console.log("CREATE_USER_SUCCESS", action.user, users);
      return state
        .set('users', users)
        .set('usersMap', _.keyBy(action.users, 'id'))
        .set('userAdded', true);
    }
    case CREATE_USER_ERROR: {
      console.log("CREATE_USER_ERROR", action.error);
      return state;
    }
    case EDIT_USER_SUCCESS:{
      console.log('EDIT_USER_SUCCESS', action.user)
      const users = Object.assign([], state.get('users'));
      const userIndex = users.findIndex(x => x.id == action.user.id);
      users[userIndex] = action.user;
      return state.set('users', users)
    }
    case EDIT_USER_ERROR: {
      console.log("EDIT_USER_ERROR", action.error)
      return state;
    }

    default:
      return state;
  }
}

export default userPageReducer;
