import React from 'react'

const CheckboxIcon = (props) => {
  const src = props.checked 
    ? require('../../assets/checkbox-checked.svg')
    : require('../../assets/checkbox-unchecked.svg')
  return <div {...props}>
    <img src={src} />
  </div>
}

export default CheckboxIcon