/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { call, put, take, takeLatest } from 'redux-saga/effects';
import { GET_DOCUMENTATION } from './constants';
const camelCaseKeys = require('camelcase-keys')

import request from '../../../utils/request';
import { getDocumentationError, getDocumentationSuccess } from './actions';

const config = require('../../../config');
const root = config.params.API_SERVER;

export function* sDocumentation() {
  try {
    const requestURL = `${root}/documentation`;
    const results = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    yield put(getDocumentationSuccess(results));
  } catch (error) {
    yield put(getDocumentationError(error));
  }
}

export function* getDocumentationApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_DOCUMENTATION, sDocumentation);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

// All sagas to be loaded
export default [
  getDocumentationApi,
];
