/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CLEAR_MESSAGE,
  LOADING,
  GET_SUBSIDIARY_FLOWS_SUCCESS,
  GET_SUBSIDIARY_FLOWS_ERROR,
  GET_FLOWS_SUCCESS,
  GET_FLOWS_ERROR,
  REMOVE_FLOW_SUCCESS,
  REMOVE_FLOW_ERROR,
  CREATE_FLOW_SUCCESS,
  CREATE_FLOW_ERROR,
  RESET_CREATE_FLOW,
  GET_SUBSIDIARY_REJECTION_FLOWS,
  GET_SUBSIDIARY_REJECTION_FLOWS_SUCCESS,
  GET_SUBSIDIARY_REJECTION_FLOWS_ERROR
} from './constants';

const initialState = fromJS({
  subsidiaries: [],
  subsidiaryFlows: [],
  subsidiaryRejectionFlows: [],
  flows: [],
  loading: false,
  message: '',
});

function flowPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSIDIARY_FLOWS_SUCCESS: {
      const subsidiaryFlows = Object.assign([], action.flows);
      console.log('GET_SUBSIDIARY_FLOWS_SUCCESS', action.flows, subsidiaryFlows)

      return state.set('subsidiaryFlows', subsidiaryFlows);
    }
    case GET_SUBSIDIARY_FLOWS_ERROR: {
      console.log("GET_SUBSIDIARY_FLOWS_ERROR", action.error)

      return state;
    }
    case GET_FLOWS_SUCCESS: {
      console.log('GET_FLOWS_SUCCESS', action.flows)
      const flows = Object.assign([], action.flows);

      return state.set('flows', flows);
    }
    case GET_FLOWS_ERROR: {
      console.log("GET_FLOWS_ERROR", action.error)

      return state;
    }
    case REMOVE_FLOW_SUCCESS:{
      console.log('REMOVE_FLOW_SUCCESS', action.flow)
      let originalProfiles = Object.assign([], state.get('flows'));
      const flows = originalProfiles.filter(flow => flow.id != action.flow.id)

      return state.set('flows', flows);
    }
    case REMOVE_FLOW_ERROR: {
      console.log("REMOVE_FLOW_ERROR", action.error)

      return state;
    }
    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case CREATE_FLOW_SUCCESS:
    {
      const flows = Object.assign([], state.get('flows'));
      if (action.flow){
        flows.unshift(action.flow);
      }
      console.log("CREATE_FLOW_SUCCESS", action.flow, flows)
      return state.set('flows', flows)
      .set('flowAdded', true);
    }
    case RESET_CREATE_FLOW:{
      return state.set('flowAdded', false);
    }

    case CREATE_FLOW_ERROR: {
      console.log("CREATE_FLOW_ERROR", action.error)

      return state;
    }
    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    case GET_SUBSIDIARY_REJECTION_FLOWS: {
      const subsidiaryFlows = Object.assign([], action.flows);
      console.log('GET_SUBSIDIARY_REJECTION_FLOWS', action.flows, subsidiaryFlows);

      return state.set('subsidiaryRejectionFlows', subsidiaryFlows);
    }
    case GET_SUBSIDIARY_REJECTION_FLOWS_ERROR: {
      console.log("GET_SUBSIDIARY_REJECTION_FLOWS_ERROR", action.error)

      return state;
    }
    case GET_SUBSIDIARY_REJECTION_FLOWS_SUCCESS: {
      console.log('GET_SUBSIDIARY_REJECTION_SUCCESS', action.flows)
      const flows = Object.assign([], action.flows);

      return state.set('subsidiaryRejectionFlows', flows);
    }
    default:
      return state;
  }
}

export default flowPageReducer;
