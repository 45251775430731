/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable'
import {
  GET_DOCUMENTATION_SUCCESS,
  GET_DOCUMENTATION_ERROR,
  CLEAR_MESSAGE
} from './constants'

const initialState = fromJS({
  documentation: undefined,
  loading: true
})

function documentationPageReducer (state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTATION_SUCCESS: {
      return state
        .set('documentation', action.payload)
        .set('loading', false)
    }
    case GET_DOCUMENTATION_ERROR: {
      console.log('GET_DOCUMENTATION_ERROR', action.payload.error)
      return state
        .set('loading', false)
        .set('errorMessage', action.payload.error)
    }
    case CLEAR_MESSAGE: {
      return state.set('errorMessage', '')
    }
    default:
      return state
  }
}

export default documentationPageReducer
