import React, { useState } from 'react';
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types';
import messages from '../../../../messages'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SecondaryButton from '../../../../../../v2/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../../v2/components/buttons/PrimaryButton';

const LocationReassignDialog = ({ options = [], onClose, open, onConfirm, intl: { formatMessage } }) => {
  const [values, setValues] = useState([]);

  const handleValueChange = newValues => {
    setValues(newValues.map(({ id }) => id));
  };

  const handleConfirmation = () => {
    onConfirm(values);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{formatMessage(messages.descartes.locationReassignDialog.title)}</DialogTitle>
      <DialogContent>
        {options && (
          <Autocomplete
            getOptionLabel={({ name }) => name}
            options={options}
            // onChange={(v) => setValues(prevValues => [...prevValues, store])}
            onChange={(_, newValues) => handleValueChange(newValues)}
            multiple
            renderInput={params => (
              <TextField {...params} label={formatMessage(messages.descartes.locationReassignDialog.location)} variant="outlined" />
            )}
            size="small"
            style={{ width: 300 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton type='outlined' onClick={onClose}>
          {formatMessage(messages.button.cancel)}
        </SecondaryButton>
        <PrimaryButton
          disabled={!values.length}
          onClick={handleConfirmation}
        >
          {formatMessage(messages.button.confirm)}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

LocationReassignDialog.propTypes = {
  options: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
};

export default injectIntl(LocationReassignDialog)
