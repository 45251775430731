

export const saveData = (key, payload) => {
  console.log("saveData", key, payload)
  localStorage.setItem(key, JSON.stringify(payload));
  // localStorage.setItem(key, JSON.stringify(payload));
}

export const getData = (key) => {
  const retrievedObject = localStorage.getItem(key) || null;
  if (key === 'user' && !!retrievedObject) {
    try {
      const user = JSON.parse(retrievedObject)
      if (!!user && user.authorizedSections && Array.isArray(user.authorizedSections) && user.authorizedSections.includes('*')) {
        const newAuthorizedSections = user.authorizedSections.filter(x => x !== '*')
        user.authorizedSections = [...newAuthorizedSections, 'home', 'listings', 'analytics', 'jobBoards', 'searchCandidate', 'wish', 'support']
      }
      return user
    } catch (e) {}
  }
  return JSON.parse(retrievedObject)
}
