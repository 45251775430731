const request = require('../../utils/request')
const config = require('../../config')
const root = config.params.API_SERVER

const fixUrl = (url) => {
  if (url.endsWith('/v1')) {
    return `${url.slice(0, -1)}2`
  }
  return url
}

const ok = (content) => ({ ok: true, content, error: null })
const error = (error) => ({ ok: false, content: null, error })

const getFeatureConfigInstance = async (name) => {
  const requestURL = new URL(`${fixUrl(root)}/features/${name}/instance`);

  try {
    const result = await request.default(requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return ok(result)
  } catch (e) {
    return error(e)
  }
}

export {
  getFeatureConfigInstance,
}