import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as listingActions from '../../../containers/Listings/actions';
import * as stepViewActions from '../../../containers/StepViews/actions';

import InviteToInterviewDialog from './InviteToInterviewDialog';

const InviteToInterviewContainer = (props) => {
  
  const { listingId, stepId, actions } = props;

  useEffect(() => {
    if (listingId && stepId) {
      actions.getListing(listingId);
      actions.getViewConfig(listingId, stepId, 'INTERVIEW');
    }
  }, [listingId, stepId]);

  const { actions: unused, ...args} = props;

  return <InviteToInterviewDialog {...args} />;
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators({ ...listingActions, ...stepViewActions }, dispatch);
  return {
    actions
  };
}

export default connect(null, mapDispatchToProps)(InviteToInterviewContainer);
