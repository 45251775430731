import React, { useState } from 'react'
import { connect } from 'react-redux'
import { candidateChanged, hideStandBy } from '../../../store/candidateActions/actions'
import { standByCandidate } from '../../../utils/connector'
import StandByDialog from '../StandByDialog'

const StandByCandidateAction = ({ store: { show, loading, data }, close, notify }) => {
  const { candidateId, stepId, listingId, name, reasons } = data || {}
  const [processing, setProcessing] = useState(false);

  const onConfirm = async (reason) => {
    setProcessing(true)
    try{
      await standByCandidate(candidateId, listingId, stepId, reason.value)
      notify()
    }
    catch(err){
      console.log('Something failed while rejecting a candidate', err)
    }
    setProcessing(false)
    close()
  }

  return <StandByDialog
    name={name}
    reasons={reasons}
    open={show}
    onClose={close}
    onConfirm={onConfirm}
    loading={loading || processing}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').standBy
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideStandBy()),
    notify: () => dispatch(candidateChanged()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StandByCandidateAction)
