/*
 *
 * PlatformPage actions
 *
 */

import {
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_ERROR,
  GET_ANALYTICS_MAP_CANDIDATES,
  GET_ANALYTICS_MAP_CANDIDATES_SUCCESS,
  GET_ANALYTICS_MAP_CANDIDATES_ERROR,
  GET_CANDIDATES_LISTING_DATA_AGGREGATION,
  GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS,
  GET_CANDIDATES_LISTING_DATA_AGGREGATION_ERROR,
  GET_ANALYTICS_DASHBOARDS,
  GET_ANALYTICS_DASHBOARDS_SUCCESS,
  GET_ANALYTICS_DASHBOARDS_ERROR
} from './constants';


export function getAnalytics(listingId, stepId, dashboard, dashboardFields){
  return {
    type: GET_ANALYTICS,
    listingId, stepId, dashboard, dashboardFields
  }
}
export function getAnalyticsSuccess(data){
  return {
    type: GET_ANALYTICS_SUCCESS,
    data
  }
}
export function getAnalyticsError(error){
  return {
    type: GET_ANALYTICS_ERROR,
    error
  }
}
export function getAnalyticsDashboards(listingId){
  return {
    type: GET_ANALYTICS_DASHBOARDS,
    listingId
  }
}
export function getAnalyticsDashboardsSuccess(data){
  return {
    type: GET_ANALYTICS_DASHBOARDS_SUCCESS,
    data
  }
}
export function getAnalyticsDashboardsError(error){
  return {
    type: GET_ANALYTICS_DASHBOARDS_ERROR,
    error
  }
}

export function getAnalyticsMapCandidates(listingId, stepId){
  return {
    type: GET_ANALYTICS_MAP_CANDIDATES,
    listingId, stepId
  }
}
export function getAnalyticsMapCandidatesSuccess(data){
  return {
    type: GET_ANALYTICS_MAP_CANDIDATES_SUCCESS,
    data
  }
}
export function getAnalyticsMapCandidatesError(error){
  return {
    type: GET_ANALYTICS_MAP_CANDIDATES_ERROR,
    error
  }
}
export function getCandidatesListingDataAggregation(listingId, stepId, state, aggregate){
  return {
    type: GET_CANDIDATES_LISTING_DATA_AGGREGATION,
    listingId, stepId, state, aggregate
  }
}
export function getCandidatesListingDataAggregationSuccess(data){
  return {
    type: GET_CANDIDATES_LISTING_DATA_AGGREGATION_SUCCESS,
    data
  }
}
export function getCandidatesListingDataAggregationError(error){
  return {
    type: GET_CANDIDATES_LISTING_DATA_AGGREGATION_ERROR,
    error
  }
}
