/*
 *
 * StepViews constants
 *
 */

export const LOADING = 'app/StepViews/LOADING'

export const CLEAN_LOCAL_DATA = 'app/StepViews/CLEAN_LOCAL_DATA'

export const SET_VIEW_CONFIG = 'app/StepViews/SET_VIEW_CONFIG'

export const GET_VIEW_DATA = 'app/StepViews/GET_VIEW_DATA'
export const GET_VIEW_DATA_SUCCESS = 'app/StepViews/GET_VIEW_DATA_SUCCESS'
export const GET_VIEW_DATA_ERROR = 'app/StepViews/GET_VIEW_DATA_ERROR'

export const GET_STATE_DATA = 'app/StepViews/GET_STATE_DATA'

export const GET_PENDING_APPROVAL_LISTINGS = 'app/StepViews/GET_PENDING_APPROVAL_LISTINGS'
export const GET_PENDING_APPROVAL_LISTINGS_SUCCESS = 'app/StepViews/GET_PENDING_APPROVAL_LISTINGS_SUCCESS'
export const GET_PENDING_APPROVAL_LISTINGS_ERROR = 'app/StepViews/GET_PENDING_APPROVAL_LISTINGS_ERROR'

export const GET_REASONS_REJECTION = 'app/StepViews/GET_REASONS_REJECTION'
export const GET_REASONS_REJECTION_SUCCESS = 'app/StepViews/GET_REASONS_REJECTION_SUCCESS'
export const GET_REASONS_REJECTION_ERROR = 'app/StepViews/GET_REASONS_REJECTION_ERROR'

export const ADD_REASONS_REJECTION = 'app/StepViews/ADD_REASONS_REJECTION'
export const ADD_REASONS_REJECTION_WAITING = 'app/StepViews/ADD_REASONS_REJECTION_WAITING'
export const ADD_REASONS_REJECTION_SUCCESS = 'app/StepViews/ADD_REASONS_REJECTION_SUCCESS'
export const ADD_REASONS_REJECTION_ERROR = 'app/StepViews/ADD_REASONS_REJECTION_ERROR'

export const REMOVE_REASONS_REJECTION = 'app/StepViews/REMOVE_REASONS_REJECTION'
export const REMOVE_REASONS_REJECTION_WAITING = 'app/StepViews/REMOVE_REASONS_REJECTION_WAITING'
export const REMOVE_REASONS_REJECTION_SUCCESS = 'app/StepViews/REMOVE_REASONS_REJECTION_SUCCESS'
export const REMOVE_REASONS_REJECTION_ERROR = 'app/StepViews/REMOVE_REASONS_REJECTION_ERROR'

export const GET_STEP_VIEWS = 'app/StepViews/GET_STEP_VIEWS'
export const GET_STEP_VIEWS_SUCCESS = 'app/StepViews/GET_STEP_VIEWS_SUCCESS'
export const GET_STEP_VIEWS_ERROR = 'app/StepViews/GET_STEP_VIEWS_ERROR'

export const GET_VIEW_CONFIG = 'app/StepViews/GET_VIEW_CONFIG'
export const GET_VIEW_CONFIG_SUCCESS = 'app/StepViews/GET_VIEW_CONFIG_SUCCESS'
export const GET_VIEW_CONFIG_ERROR = 'app/StepViews/GET_VIEW_CONFIG_ERROR'

export const UPDATE_ANSWER = 'app/StepViews/UPDATE_ANSWER'
export const UPDATE_ANSWER_SUCCESS = 'app/StepViews/UPDATE_ANSWER_SUCCESS'
export const UPDATE_ANSWER_ERROR = 'app/StepViews/UPDATE_ANSWER_ERROR'

export const APPROVE_CANDIDATES = 'app/StepViews/APPROVE_CANDIDATES'
export const APPROVE_CANDIDATES_SUCCESS = 'app/StepViews/APPROVE_CANDIDATES_SUCCESS'
export const APPROVE_CANDIDATES_ERROR = 'app/StepViews/APPROVE_CANDIDATES_ERROR'

export const CANDIDATES_TO_NEXT_STEP = 'app/StepViews/CANDIDATES_TO_NEXT_STEP'
export const CANDIDATES_TO_NEXT_STEP_SUCCESS = 'app/StepViews/CANDIDATES_TO_NEXT_STEP_SUCCESS'
export const CANDIDATES_TO_NEXT_STEP_ERROR = 'app/StepViews/CANDIDATES_TO_NEXT_STEP_ERROR'


export const GET_INTERVIEW_CONFIG = 'app/StepViews/GET_INTERVIEW_CONFIG'
export const GET_INTERVIEW_CONFIG_SUCCESS = 'app/StepViews/GET_INTERVIEW_CONFIG_SUCCESS'
export const GET_INTERVIEW_CONFIG_ERROR = 'app/StepViews/GET_INTERVIEW_CONFIG_ERROR'

export const SAVE_INTERVIEW_SLOT = 'app/StepViews/SAVE_INTERVIEW_SLOT'
export const SAVE_INTERVIEW_SLOT_SUCCESS = 'app/StepViews/SAVE_INTERVIEW_SLOT_SUCCESS'
export const SAVE_INTERVIEW_SLOT_ERROR = 'app/StepViews/SAVE_INTERVIEW_SLOT_ERROR'

export const RESET_SLOT_AVAILABILITY = 'app/StepViews/RESET_SLOT_AVAILABILITY'
export const GET_SLOT_AVAILABILITY = 'app/StepViews/GET_SLOT_AVAILABILITY'
export const GET_SLOT_AVAILABILITY_SUCCESS = 'app/StepViews/GET_SLOT_AVAILABILITY_SUCCESS'
export const GET_SLOT_AVAILABILITY_ERROR = 'app/StepViews/GET_SLOT_AVAILABILITY_ERROR'

export const GET_HIRING_MANAGERS = 'app/StepViews/GET_HIRING_MANAGERS'
export const GET_HIRING_MANAGERS_SUCCESS = 'app/StepViews/GET_HIRING_MANAGERS_SUCCESS'
export const GET_HIRING_MANAGERS_ERROR = 'app/StepViews/GET_HIRING_MANAGERS_ERROR'

export const GET_INTERVIEW_SLOTS = 'app/StepViews/GET_INTERVIEW_SLOTS'
export const GET_INTERVIEW_SLOTS_SUCCESS = 'app/StepViews/GET_INTERVIEW_SLOTS_SUCCESS'
export const GET_INTERVIEW_SLOTS_ERROR = 'app/StepViews/GET_INTERVIEW_SLOTS_ERROR'

export const GET_CANDIDATES_IN_INTERVIEW = 'app/StepViews/GET_CANDIDATES_IN_INTERVIEW'
export const GET_CANDIDATES_IN_INTERVIEW_SUCCESS = 'app/StepViews/GET_CANDIDATES_IN_INTERVIEW_SUCCESS'
export const GET_CANDIDATES_IN_INTERVIEW_ERROR = 'app/StepViews/GET_CANDIDATES_IN_INTERVIEW_ERROR'

export const SET_CANDIDATE_ASSISTANCE_STEP = 'app/StepViews/SET_CANDIDATE_ASSISTANCE_STEP'
export const SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS = 'app/StepViews/SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS'
export const SET_CANDIDATE_ASSISTANCE_STEP_ERROR = 'app/StepViews/SET_CANDIDATE_ASSISTANCE_STEP_ERROR'

export const CANCEL_INTERVIEW = 'app/StepViews/CANCEL_INTERVIEW'
export const CANCEL_INTERVIEW_SUCCESS = 'app/StepViews/CANCEL_INTERVIEW_SUCCESS'
export const CANCEL_INTERVIEW_ERROR = 'app/StepViews/CANCEL_INTERVIEW_ERROR'

export const CLOSE_INTERVIEW = 'app/StepViews/CLOSE_INTERVIEW'
export const CLOSE_INTERVIEW_SUCCESS = 'app/StepViews/CLOSE_INTERVIEW_SUCCESS'
export const CLOSE_INTERVIEW_ERROR = 'app/StepViews/CLOSE_INTERVIEW_ERROR'

export const CLEAR_MESSAGE = 'app/StepViews/CLEAR_MESSAGE'

export const GET_LOCATIONS = 'app/StepViews/GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'app/StepViews/GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_ERROR = 'app/StepViews/GET_LOCATIONS_ERROR'

export const GET_POSITIONS = 'app/StepViews/GET_POSITIONS'
export const GET_POSITIONS_SUCCESS = 'app/StepViews/GET_POSITIONS_SUCCESS'
export const GET_POSITIONS_ERROR = 'app/StepViews/GET_POSITIONS_ERROR'

export const GET_VACANCIES = 'app/StepViews/GET_VACANCIES'
export const GET_VACANCIES_SUCCESS = 'app/StepViews/GET_VACANCIES_SUCCESS'
export const GET_VACANCIES_ERROR = 'app/StepViews/GET_VACANCIES_ERROR'

export const NEW_REQUISITION_CREATED = 'app/StepViews/NEW_REQUISITION_CREATED'
