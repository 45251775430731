import React from 'react'
import useFeature from '../hooks/useFeature'

export const HasAccess = ({
  permission,
  fallback,
  children
}) => {
  const { hasAccess } = useFeature()
  if (hasAccess(permission)) {
    return children
  } else {
    return fallback || null
  }
}