export const defaultActionState = {
  show: false,
  loading: false,
  data: null,
  error: null
}

const initialState = {
  resume: defaultActionState,
  log: defaultActionState,
  hire: defaultActionState,
  interview: {
    cancel: defaultActionState,
    invite: defaultActionState,
    reschedule: defaultActionState
  },
  advance: defaultActionState,
  reject: defaultActionState,
  standBy: defaultActionState,
  others: {
    specialNeeds: defaultActionState,
    editCandidateData: defaultActionState,
    helpAndSupport: defaultActionState
  },
  changeVacancy: {
    show: false
  },
  goToCandidate: defaultActionState,
  lastCandidateChange: null
}

export default initialState;