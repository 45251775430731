/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
 import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  GET_COMPANIES,
  CREATE_COMPANY,
  REMOVE_COMPANY,
  EDIT_COMPANY,
} from './constants';

import {
  getCompaniesSuccess,
  getCompaniesError,
  removeCompanySuccess,
  removeCompanyError,
  createCompanySuccess,
  createCompanyError,
  editCompanySuccess,
  editCompanyError
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sCompanies() {
  const requestURL = `${root}/companies`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log("sCompanies", result)
    yield put(getCompaniesSuccess(result));

  } catch (err) {
    yield put(getCompaniesError(err));
  }
}

export function* getCompaniesApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_COMPANIES, sCompanies);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


export function* sRemoveCompany(action) {
  const requestURL = `${root}/companies/`+action.company.id;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log(result)
    yield put(removeCompanySuccess(action.company));

  } catch (err) {
    yield put(removeCompanyError(err));
  }
}

export function* removeCompanyApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(REMOVE_COMPANY, sRemoveCompany);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCreateCompany(action) {
  const requestURL = `${root}/companies`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.company),
    });
    yield put(createCompanySuccess(result));
    yield put(push('/companies'));
  } catch (err) {
    yield put(createCompanyError(err));
  }
}

export function* createCompanyApi() {
  const watcher = yield takeLatest(CREATE_COMPANY, sCreateCompany);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sEditCompany(action) {
  try {
    const requestURL = `${root}/companies/`+action.company.id;
    console.log(requestURL)

    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.company),
    });
    yield put(editCompanySuccess(result));
    yield put(push('/companies'));
  } catch (err) {
    yield put(editCompanyError(err));
  }
}

export function* editCompanyApi() {
  const watcher = yield takeLatest(EDIT_COMPANY, sEditCompany);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}



// All sagas to be loaded
export default [
  getCompaniesApi,
  removeCompanyApi,
  createCompanyApi,
  editCompanyApi
];
