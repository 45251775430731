/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { put, takeLatest } from 'redux-saga/effects'
import { LOAD_USER_SUCCESS } from '../App/constants'
import { changeLocaleByUser } from './actions'

function * sOnUserLoaded (action) {
  yield put(changeLocaleByUser(action.user))
}

export function* getUserLocale () {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(LOAD_USER_SUCCESS, sOnUserLoaded)

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

// All sagas to be loaded
export default [
  getUserLocale
]
