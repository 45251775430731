import React from "react"
import AdvanceCandidateAction from "./AdvanceCandidateAction";
import CancelInterviewAction from "./CancelInterviewAction";
import CandidateActions from "./CandidateActions";
import HireAction from "./HireAction";
import InviteToInterviewAction from "./InviteToInterviewAction";
import LogAction from "./LogAction"
import RejectCandidateAction from "./RejectCandidateAction";
import RescheduleInterviewAction from "./RescheduleInterviewAction";
import ResumeAction from "./ResumeAction";
import SpecialNeedsAction from "./SpecialNeedsAction";
import StandByCandidateAction from "./StandByCandidateAction";
import ChangeVacancyAction from "./ChangeVacancy";
import EditCandidateDataAction from "./EditCandidateDataAction";
import HelpAndSupportAction from "./HelpAndSupportAction";
import GoToCandidateAction from "./GoToCandidateAction";
import { ActionsContext, ActionsProvider } from './ActionsContext';

//TODO: Add my action here that will show the reject dialog (try to reuse existing and see if we can bind the actions there. If not possible, think about this with Gabi to see the best option)
const AllActions = () => (
  <React.Fragment>
    <LogAction />
    <ResumeAction />
    <CancelInterviewAction />
    <InviteToInterviewAction />
    <RescheduleInterviewAction />
    <HireAction />
    <AdvanceCandidateAction />
    <RejectCandidateAction />
    <StandByCandidateAction />
    <ChangeVacancyAction />
    <EditCandidateDataAction />
    <GoToCandidateAction />

    {/* TODO: this is not an action, so it should not be here */}
    <SpecialNeedsAction />
    <HelpAndSupportAction />
  </React.Fragment>
)

export { AllActions, ActionsContext, ActionsProvider }
export default CandidateActions