import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrimaryButton from '../../../v2/components/buttons/PrimaryButton';
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton';
import messages from '../messages';
import emiMessages from '../../Emi/messages';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { captureMessage } from '../../../utils/sentry';
import { getInformation } from '../../../utils/tawkToUtils';
import { getNetworkConnectionInfo } from '../../../utils/networkUtils';

const HelpAndSupportActionDialog = ({
  open,
  onClose,
  intl: { formatMessage },
}) => {
  const [executionId, setExecutionId] = useState(null);

  const handleClose = useCallback(() => {
    onClose()
    setExecutionId(null)
  })
  const runAnalysis = useCallback( async () => {
    const tawkToInformation = await getInformation();
    const network = getNetworkConnectionInfo();
    const { trackId } = captureMessage('User analysis', {
      feature: 'USER_ANALYSIS',
    }, {
      tawkTo: tawkToInformation,
      react: {
        version: React.version
      },
      network
    });
    setExecutionId(trackId.slice(-12));
  });

  useEffect(() => {}, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth disableBackdropClick>
      <DialogTitle>{formatMessage(messages.helpAndSupport.title)}</DialogTitle>
      <DialogContent>
        {!!executionId && (
          <Grid container justify="space-between">
            <Grid item xs={12}>
              <div>
                <Alert severity='success'>{formatMessage(messages.helpAndSupport.analysis.success, { code: executionId })}</Alert>
              </div>
            </Grid>
          </Grid>
        )}
        {!executionId && (
          <Grid container justify="space-between">
            <Grid item xs={9}>
              {formatMessage(messages.helpAndSupport.analysis.text)}
            </Grid>
            <Grid item xs={3}>
              <PrimaryButton fullWidth onClick={runAnalysis}>{formatMessage(messages.helpAndSupport.analysis.button)}</PrimaryButton>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton type="text" onClick={handleClose}>
          {formatMessage(emiMessages.button.close)}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(HelpAndSupportActionDialog);
