import React from 'react';

import awesomeIcon from '../../assets/awesome.svg';
import bookOpenIcon from '../../assets/book-open.svg';
import briefcaseIcon from '../../assets/briefcase.svg';
import calendarIcon from '../../assets/calendar.svg';
import checkboxCheckedIcon from '../../assets/checkbox-checked.svg';
import checkboxUncheckedIcon from '../../assets/checkbox-unchecked.svg';
import chevronDownIcon from '../../assets/chevron-down.svg';
import chevronUpIcon from '../../assets/chevron-up.svg';
import chevronRightBlueIcon from '../../assets/chevron-right-blue.svg';
import chevronRightGreyIcon from '../../assets/chevron-right-grey.svg';
import clockTimeIcon from '../../assets/clock-time-svgrepo-com.svg';
import clockIcon from '../../assets/clock.svg';
import downloadIcon from '../../assets/download.svg';
import editIcon from '../../assets/edit.svg';
import emiIcon from '../../assets/emi.svg';
import emiColorIcon from '../../assets/emi-color.svg';
import errorFaceIcon from '../../assets/error-face.svg';
import homeIcon from '../../assets/home.svg';
import magicWandIcon from '../../assets/magic-wand.svg';
import messageSquareIcon from '../../assets/message-square.svg';
import occIcon from '../../assets/occ-logo.svg';
import scienceIcon from '../../assets/science.svg';
import searchIcon from '../../assets/search.svg';
import thunderIcon from '../../assets/thunder.svg';
import userIcon from '../../assets/user.svg';


const ICONS = {
  awesome: awesomeIcon,
  bookOpen: bookOpenIcon,
  briefcase: briefcaseIcon,
  calendar: calendarIcon,
  checkboxChecked: checkboxCheckedIcon,
  checkboxUnchecked: checkboxUncheckedIcon,
  chevronDown: chevronDownIcon,
  chevronUp: chevronUpIcon,
  chevronRightBlue: chevronRightBlueIcon,
  chevronRightGrey: chevronRightGreyIcon,
  clockTime: clockTimeIcon,
  clock: clockIcon,
  download: downloadIcon,
  edit: editIcon,
  emi: emiIcon,
  emiColor: emiColorIcon,
  errorFace: errorFaceIcon,
  home: homeIcon,
  magicWand: magicWandIcon,
  messageSquare: messageSquareIcon,
  occ: occIcon,
  science: scienceIcon,
  search: searchIcon,
  thunder: thunderIcon,
  user: userIcon
}

const Icon = ({ name, height, width }) => {
  return <img src={ICONS[name]} height={height} width={width} />;
}

export default Icon;