import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: theme.styles.tabs.appBar.item,
  itemSelected: theme.styles.tabs.appBar.itemSelected,
}));

const AppBarTabItem = ({ selected, label, icon, endIcon, onClick }) => {
  const classes = useStyles();
  return (
    <Button
      className={selected ? classes.itemSelected : classes.item}
      startIcon={icon}
      endIcon={endIcon}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

AppBarTabItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AppBarTabItem;
