/*
 *
 * PendingActionsPage constants
 *
 */

export const LOADING = 'app/PendingActionsPage/LOADING'

export const GET_PENDING_ACTIONS = 'app/PendingActionsPage/GET_PENDING_ACTIONS'
export const GET_PENDING_ACTIONS_SUCCESS = 'app/PendingActionsPage/GET_PENDING_ACTIONSS_SUCCESS'
export const GET_PENDING_ACTIONS_ERROR = 'app/PendingActionsPage/GET_PENDING_ACTIONS_ERROR'

export const GET_CATEGORY_INFO_START = 'app/PendingActionsPage/GET_CATEGORY_INFO_START'
export const GET_CATEGORY_INFO_SUCCESS = 'app/PendingActionsPage/GET_CATEGORY_INFO_SUCCESS'
export const GET_CATEGORY_INFO_ERROR = 'app/PendingActionsPage/GET_CATEGORY_INFO_ERROR'

export const ACTIONS = 'actionsInfo'
export const INTERVIEW = 'inteterviewInfo'
export const STANDBY = 'standByInfo'