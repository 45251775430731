/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl'
import emiMessages from '../Emi/messages'

export default defineMessages({
  ray: {
    approve: {
      id: 'boilerplate.components.InterviewSlots.ray.approve',
      defaultMessage: 'Approve'
    },
    reject: {
      id: 'boilerplate.components.InterviewSlots.ray.reject',
      defaultMessage: 'Reject'
    },
    absent: {
      id: 'boilerplate.components.InterviewSlots.ray.absent',
      defaultMessage: 'Absent'
    },
  },
  placeholder: {
    name: {
      id: 'boilerplate.components.Candidate.search.placeholder.name',
      defaultMessage: 'Name'
    },
    email: {
      id: 'boilerplate.components.Candidate.search.placeholder.email',
      defaultMessage: 'Email'
    },
    document: {
      id: 'boilerplate.components.Candidate.search.placeholder.document',
      defaultMessage: 'Document'
    },
    listing: {
      id: 'boilerplate.components.Candidate.search.placeholder.listing',
      defaultMessage: 'Listing, eg: 1086'
    },
    date:{
      id: 'boilerplate.components.Candidate.search.placeholder.hireDate',
      defaultMessage: 'Hire date',
    },
    telephone:{
      id: 'boilerplate.components.Candidate.search.placeholder.telephone',
      defaultMessage: 'Telephone',
    },
  },
  label: {
    name: {
      id: 'boilerplate.components.Candidate.search.label.name',
      defaultMessage: 'Name'
    },
    email: {
      id: 'boilerplate.components.Candidate.search.label.email',
      defaultMessage: 'Email'
    },
    document: {
      id: 'boilerplate.components.Candidate.search.label.document',
      defaultMessage: 'Document'
    },
    listing: {
      id: 'boilerplate.components.Candidate.search.label.listing',
      defaultMessage: 'Listing, eg: 1086'
    },
    date:{
      id: 'boilerplate.components.Candidate.search.label.hireDate',
      defaultMessage: 'Hire date',
    },
    telephone:{
      id: 'boilerplate.components.Candidate.search.label.telephone',
      defaultMessage: 'Telephone',
    },
  },
  error: {
    id: 'boilerplate.components.Candidate.search.error',
    defaultMessage: 'You need to fill at least one fied'
  },
  button: {
    search: {
      id: 'boilerplate.components.Candidate.search.button.search',
      defaultMessage: 'Search'
    },

    ok: {
      id: 'boilerplate.components.Candidate.search.button.ok',
      defaultMessage: 'Ok'
    },
    view: {
      id: 'boilerplate.components.Candidate.search.button.view',
      defaultMessage: 'View'
    },
    cancel: {
      id: 'boilerplate.components.Candidate.search.button.cancel',
      defaultMessage: 'Cancel'
    },
    back: {
      id: 'boilerplate.components.Candidate.search.button.back',
      defaultMessage: 'Back'
    },
    next: {
      id: 'boilerplate.components.Candidate.search.button.next',
      defaultMessage: 'Next'
    },
    nextPage: {
      id: 'boilerplate.components.Candidate.search.button.nextPage',
      defaultMessage: 'Next Page'
    },
    previousPage: {
      id: 'boilerplate.components.Candidate.search.button.previousPage',
      defaultMessage: 'Previous Page'
    },
    modify: {
      id: 'boilerplate.components.Candidate.search.button.modify',
      defaultMessage: 'Modify'
    },
    save: {
      id: 'boilerplate.components.Candidate.search.button.save',
      defaultMessage: 'Save'
    }
  },
  noData: {
    id: 'boilerplate.components.Candidate.search.noData',
    defaultMessage: 'No rows found'
  },
  numberOfResults: {
    id: 'boilerplate.components.Emi.table.numberOfResults',
    defaultMessage: 'of {total} results'
  },
  search: {
    header: {
      name: {
        id: 'boilerplate.components.Candidate.search.header.name',
        defaultMessage: 'Name'
      },
      email: {
        id: 'boilerplate.components.Candidate.search.header.email',
        defaultMessage: 'Email'
      },
      document: {
        id: 'boilerplate.components.Candidate.search.header.document',
        defaultMessage: 'Document'
      },
      telephone: {
        id: 'boilerplate.components.Candidate.search.header.telephone',
        defaultMessage: 'Telephone'
      },
      listingId: {
        id: 'boilerplate.components.Candidate.search.header.listingId',
        defaultMessage: 'Listing'
      },
      listingName: {
        id: 'boilerplate.components.Candidate.search.header.listingName',
        defaultMessage: 'Listing Name'
      },
      stepName: {
        id: 'boilerplate.components.Candidate.search.header.stepName',
        defaultMessage: 'Step'
      },
      workingPlace: {
        id: 'boilerplate.components.Candidate.search.header.workingPlace',
        defaultMessage: 'Assigned places'
      },
      channelUserId: {
        id: 'boilerplate.components.Candidate.search.header.channelUserId',
        defaultMessage: 'Emi Id'
      },
      state: {
        id: 'boilerplate.components.Candidate.search.header.state',
        defaultMessage: 'State'
      },
      date: {
        id: 'boilerplate.components.Candidate.search.header.date',
        defaultMessage: 'Date'
      },
      reason: {
        id: 'boilerplate.components.Candidate.search.header.reason',
        defaultMessage: 'Reason'
      }
    },
    results: {
      state: {
        dismissed: {
          rejected: {
            id: 'boilerplate.components.Candidate.search.results.state.dismissed.rejected'
          }
        },
        descartes: {
          dismissed: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.dismissed',
            defaultMessage: 'Dismissed'
          },
          approved: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.approved',
            defaultMessage: 'Pending action'
          },
          finished: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.finished'
          },
          not_interested: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.not_interested',
            defaultMessage: 'Not Interested'
          },
          pending_approval: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.pending_approval'
          },
          pending_rejection: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.pending_rejection'
          },
          pending_veredict: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.pending_veredict'
          },
          pending_ready_for_next_step: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.ready_for_next_step'
          },
          rejected: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.rejected',
            defaultMessage: 'Rejected'
          },
          started: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.started'
          },
          stand_by: {
            id: 'boilerplate.components.Candidate.search.results.state.descartes.stand_by',
            defaultMessage: 'Standby'
          }
        },
        interview: {
          abandoned: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.abandoned'
          },
          finished: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.finished'
          },
          not_interested: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.not_interested'
          },
          pending_approval: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.pending_approval'
          },
          pending_veredict: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.pending_veredict'
          },
          ready_for_next_step: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.ready_for_next_step'
          },
          rejected: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.rejected'
          },
          started: {
            id: 'boilerplate.components.Candidate.search.results.state.interview.started'
          }
        },
        hired: {
          approved: {
            id: 'boilerplate.components.Candidate.search.results.state.hired.approved',
            defaultMessage: 'Hired'
          }
        },
        manual: {
          approved: {
            id: 'boilerplate.components.Candidate.search.results.state.manual.approved',
            defaultMessage: 'Pending action'
          },
          not_interested: {
            id: 'boilerplate.components.Candidate.search.results.state.manual.not_interested',
            defaultMessage: 'Not Interested'
          },
          rejected: {
            id: 'boilerplate.components.Candidate.search.results.state.manual.rejected',
            defaultMessage: 'Rejected'
          },
          started: {
            id: 'boilerplate.components.Candidate.search.results.state.manual.started',
            defaultMessage: 'Processing'
          },
          stand_by: {
            id: 'boilerplate.components.Candidate.search.results.state.manual.stand_by',
            defaultMessage: 'Standby'
          },
          dismissed: {
            id: 'boilerplate.components.Candidate.search.results.state.manual.dismissed',
            defaultMessage: 'Discarded'
          }
        },
        requisition: {
          started: {
            id: 'boilerplate.components.Candidate.search.results.state.requisition.started',
            defaultMessage: '-'
          }
        }
      }
    }

  },
  log: {
    title: {
      id: 'boilerplate.components.Candidate.log.title',
      defaultMessage: 'Record'
    },
    type: {
      manuallyAddCandidate: {
        id: 'boilerplate.components.Candidate.log.manuallyAddCandidate',
        defaultMessage: 'Manually Added Candidate'
      },
      APPLICATION: {
        id: 'boilerplate.components.Candidate.log.type.APPLICATION',
        defaultMessage: 'Applied through {{reference}}'
      },
      'DESCARTES.STARTED': {
        id: 'boilerplate.components.Candidate.log.type.DESCARTES.STARTED',
        defaultMessage: '{{stepName}}: Started chatting with Emi'
      },
      'DESCARTES.APPROVED': {
        id: 'boilerplate.components.Candidate.log.type.DESCARTES.APPROVED',
        defaultMessage: '{{stepName}}: Pre-selected'
      },
      'DESCARTES.REJECTED': {
        id: 'boilerplate.components.Candidate.log.type.DESCARTES.REJECTED',
        defaultMessage: '{{stepName}}: Discarded'
      },
      'DESCARTES.READY_FOR_NEXT_STEP.MANUAL': {
        id: 'boilerplate.components.Candidate.log.type.DESCARTES.READ_FOR_NEXT_STEP.MANUAL',
        defaultMessage: '{{stepName}}: Advanced manually '
      },
      'DESCARTES.READY_FOR_NEXT_STEP.MANUAL_BY_USER': {
        id: 'boilerplate.components.Candidate.log.type.DESCARTES.READ_FOR_NEXT_STEP.MANUAL_BY_USER',
        defaultMessage: '{{stepName}}: Advanced manually by {{email}}'
      },
      'HIRED.APPROVED': {
        id: 'boilerplate.components.Candidate.log.type.HIRED.APPROVED',
        defaultMessage: 'Hired'
      },
      'MANUAL.READY_FOR_NEXT_STEP': {
        id: 'boilerplate.components.Candidate.log.type.MANUAL.READY_FOR_NEXT_STEP',
        defaultMessage: 'Approved in {{stepName}} by {{email}}'
      },
      'MANUAL.READY_FOR_NEXT_STEP.NO_USER': {
        id: 'boilerplate.components.Candidate.log.type.MANUAL.READY_FOR_NEXT_STEP.NO_USER',
        defaultMessage: 'Automatically approved in {{stepName}}'
      },
      'MANUAL.REJECTED': {
        id: 'boilerplate.components.Candidate.log.type.MANUAL.REJECTED',
        defaultMessage: 'Rejected in {{stepName}} by {{email}}'
      },
      'INTERVIEW.APPROVED': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.APPROVED',
        defaultMessage: 'Approved in {{locationName}} in an interview on {{timeFrom}}'
      },
      'INTERVIEW.REJECTED': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.REJECTED',
        defaultMessage: 'Rejected in {{locationName}} in an interview on {{timeFrom}}'
      },
      'INTERVIEW.APPROVED_TELEPHONE': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.APPROVED_TELEPHONE',
        defaultMessage: 'Approved in telephone interview by {{email}}'
      },
      'INTERVIEW.APPROVED_TELEPHONE_NO_EMAIL': {
        id: 'boilerplate.components.Candidate.log.type.APPROVED_TELEPHONE_NO_EMAIL',
        defaultMessage: 'Approved in telephone interview'
      },
      'INTERVIEW.APPROVED_WITHOUT_TIME': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.APPROVED_WITHOUT_TIME',
        defaultMessage: 'Approved in {{locationName}} in an interview'
      },
      'INTERVIEW.REJECTED_TELEPHONE': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.REJECTED_TELEPHONE',
        defaultMessage: 'Rejected in telephone interview by {{email}}'
      },
      'INTERVIEW.REJECTED_TELEPHONE_NO_EMAIL': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.REJECTED_TELEPHONE_NO_EMAIL',
        defaultMessage: 'Rejected in telephone interview'
      },
      'INTERVIEW.REJECTED_WITHOUT_TIME': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.REJECTED_WITHOUT_TIME',
        defaultMessage: 'Rejected in {{locationName}} in an interview'
      },
      'INTERVIEW.REJECTED_WITHOUT_TIME_AND_LOCATION': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.REJECTED_WITHOUT_TIME_AND_LOCATION',
        defaultMessage: 'Rejected in an interview'
      },
      'INTERVIEW.UNREACHABLE_AVAILABILITY': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.UNREACHABLE_AVAILABILITY',
        defaultMessage: 'HM of {{locationName}} did not answer availability'
      },
      'INTERVIEW.ABSENT': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.ABSENT',
        defaultMessage: 'Candidate was absent in an interview in {{locationName}}'
      },
      'INTERVIEW.INTERVIEW_CANCELED_FOR_INTERVIEWEE': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.INTERVIEW_CANCELED_FOR_INTERVIEWEE',
        defaultMessage: 'Interview in {{locationName}} on {{timeFrom}} was canceled'
      },
      'INTERVIEW.NO_AVAILABLE_SLOT': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.NO_AVAILABLE_SLOT',
        defaultMessage: 'HM of {{locationName}} did not give availability for an interview'
      },
      'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_SLOT_DATE': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_SLOT_DATE',
        defaultMessage: 'Candidate rejected invitation in {{locationName}} for {{timeFrom}} due to incovenient date'
      },
      'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_SLOT_DATETIME': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_SLOT_DATETIME',
        defaultMessage: 'Candidate rejected invitation in {{locationName}} for {{timeFrom}} due to incovenient schedule'
      },
      'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_LISTING': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_LISTING',
        defaultMessage: 'Candidate rejected invitation in {{locationName}} due to lack of interest in the search'
      },
      'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_LOCATION': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_LOCATION',
        defaultMessage: 'Candidate rejected invitation in {{locationName}} due to inconvenient location'
      },
      'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_POSITION': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_POSITION',
        defaultMessage: 'Candidate rejected invitation in {{locationName}} due to position'
      },
      'INTERVIEW.NO_CONFIRMATION_FROM_INTERVIEWEE': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.NO_CONFIRMATION_FROM_INTERVIEWEE',
        defaultMessage: 'Candidate did not respond to invitation in {{locationName}} for {{timeFrom}}'
      },
      'INTERVIEW.NO_CONFIRMATION_FROM_HIRING_MANAGER': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.NO_CONFIRMATION_FROM_HIRING_MANAGER',
        defaultMessage: 'HM of {{locationName}} did not respond to the interview confirmation'
      },
      'INTERVIEW.INTERVIEW_CANCELED_BY_HIRING_MANAGER': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.INTERVIEW_CANCELED_BY_HIRING_MANAGER',
        defaultMessage: 'Interview in {{locationName}} canceled by HM'
      },
      'INTERVIEW.INTERVIEW_CANCELED_BY_INTERVIEWEE': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.INTERVIEW_CANCELED_BY_INTERVIEWEE',
        defaultMessage: 'Interview in {{locationName}} canceled by candidate'
      },
      'INTERVIEW.WAITING_FOR_SLOT_CONFIRMATION': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.WAITING_FOR_SLOT_CONFIRMATION',
        defaultMessage: 'Waiting for response to invitation in {{locationName}} for {{timeFrom}}'
      },
      'INTERVIEW.WAITING_FOR_SLOT_AVAILABLE': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.WAITING_FOR_SLOT_AVAILABLE',
        defaultMessage: 'Waiting for HM availability from {{locationName}}'
      },
      'INTERVIEW.WAITING_FOR_HM_INTERVIEW_CONFIRMATION': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.WAITING_FOR_HM_INTERVIEW_CONFIRMATION',
        defaultMessage: 'Waiting for HM confirmation from {{locationName}}'
      },
      'INTERVIEW.WAITING_FOR_INTERVIEW': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.WAITING_FOR_INTERVIEW',
        defaultMessage: 'Waiting for an interview in {{locationName}}'
      },
      'INTERVIEW.WAITING_FOR_FEEDBACK': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.WAITING_FOR_FEEDBACK',
        defaultMessage: 'Waiting for feedback from HM from {{locationName}}'
      },
      'INTERVIEW.WAITING_INTEREST': {
        id: 'boilerplate.components.Candidate.log.type.INTERVIEW.WAITING_INTEREST',
        defaultMessage: 'Validating interest'
      },
      'INTEGRATION.STARTED': {
        id: 'boilerplate.components.Candidate.log.type.INTEGRATION.STARTED',
        defaultMessage: '{{stepName}}: In process'
      },
      'INTEGRATION.REJECTED': {
        id: 'boilerplate.components.Candidate.log.type.INTEGRATION.REJECTED',
        defaultMessage: '{{stepName}}: Rejected'
      },
      'INTEGRATION.READY_FOR_NEXT_STEP': {
        id: 'boilerplate.components.Candidate.log.type.INTEGRATION.READY_FOR_NEXT_STEP',
        defaultMessage: '{{stepName}}: Finished'
      },
    }
  },
  cancelInterview: {
    listing: {
      defaultMessage: 'Listing',
      id: 'boilerplate.components.Candidate.cancelInterview.listing'
    },
    candidate: {
      defaultMessage: 'Candidate',
      id: 'boilerplate.components.Candidate.cancelInterview.candidate'
    },
    date: {
      defaultMessage: 'Date',
      id: 'boilerplate.components.Candidate.cancelInterview.date'
    },
    hiringManager: {
      defaultMessage: 'Hiring Manager',
      id: 'boilerplate.components.Candidate.cancelInterview.hiringManager'
    },
    title: {
      defaultMessage: 'Cancel Candidate Interview',
      id: 'boilerplate.components.Candidate.cancelInterview.title'
    },
    question: {
      defaultMessage: 'Are you sure you want to cancel the interview with the following candidate?',
      id: 'boilerplate.components.Candidate.cancelInterview.question'
    },
    doNotCancelInterview: {
      defaultMessage: 'Do not cancel',
      id: 'boilerplate.components.Candidate.cancelInterview.doNotCancelInterview'
    },
    cancelInterview: {
      defaultMessage: 'Cancel interview',
      id: 'boilerplate.components.Candidate.cancelInterview.cancelInterview'
    },
    error: {
      defaultMessage: 'It is not possible to cancel this interview',
      id: 'boilerplate.components.Candidate.cancelInterview.error'
    },
  },
  rescheduleInterview: {
    title: {
      defaultMessage: 'Reschedule Interview',
      id: 'boilerplate.components.Candidate.rescheduleInterview.title'
    },
    question: {
      defaultMessage: 'Are you sure you want to reschedule?',
      id: 'boilerplate.components.Candidate.rescheduleInterview.question'
    },
    reschedule: {
      defaultMessage: 'Reschedule',
      id: 'boilerplate.components.Candidate.rescheduleInterview.reschedule'
    },
    cancel: {
      defaultMessage: 'Cancel',
      id: 'boilerplate.components.Candidate.rescheduleInterview.cancel'
    },
    error: {
      defaultMessage: 'This interview cannot be rescheduled',
      id: 'boilerplate.components.Candidate.rescheduleInterview.error'
    }
  },
  inviteToInterview: {
    error: {
      alreadyInvited: {
        defaultMessage: 'This candidate has already been invited for an interview, so they cannot be invited again',
        id: 'boilerplate.components.Candidate.inviteToInterview.error.alreadyInvited'
      },
      noOpenRequisition: {
        defaultMessage: 'There is no open requisition for this store - position',
        id: 'boilerplate.components.Candidate.inviteToInterview.error.noOpenRequisition'
      },
      dataRequired: {
        defaultMessage: "The attempt to associate the candidate with the interview space failed. Please verify that the candidate's information is complete and try scheduling the interview again.",
        id: 'boilerplate.components.Candidate.inviteToInterview.error.dataRequired'
      },
      invalidInterviewee: {
        defaultMessage: "The attempt to associate the candidate with the interview slot failed. Please verify that the candidate's information is complete and try to schedule the interview again.",
        id: 'boilerplate.components.Candidate.inviteToInterview.error.invalidInterviewee'
      },
      candidateAssigned: {
        defaultMessage: 'This candidate is already assigned to an interview slot.',
        id: 'boilerplate.components.Candidate.inviteToInterview.error.candidateAssigned'
      },
      automaticSlot: {
        defaultMessage: 'We were unable to create the interview slot because interview slot generation is automated and cannot be manually scheduled. If you wish to change this setting and make an exception for this request, please contact Support.',
        id: 'boilerplate.components.Candidate.inviteToInterview.error.automaticSlot'
      },
      incompleteLocation: {
        defaultMessage: 'The interview invitation failed. Please contact support for more information.',
        id: 'boilerplate.components.Candidate.inviteToInterview.error.incompleteLocation'
      },
      onSiteRequisition: {
        defaultMessage: "We couldn't process the request because the interview space does not have an associated address. Please verify that the data is correct and try scheduling the interview again. If the error persists, please contact support for assistance.",
        id: 'boilerplate.components.Candidate.inviteToInterview.error.onSiteRequisition'
      },
      missingTimezone: {
        defaultMessage: "We couldn't process the request because the interview space doesn't have a valid time zone for scheduling the interview. Please contact support so they can assist you in validating the time zone data for the location.",
        id: 'boilerplate.components.Candidate.inviteToInterview.error.missingTimezone'
      },
      missingSlotLocationAndDate: {
        defaultMessage: "We couldn't process the request because the interview space doesn't have a valid time zone to schedule the interview. Please contact support for assistance in validating the time zone data for the location.",
        id: 'boilerplate.components.Candidate.inviteToInterview.error.missingSlotLocationAndDate'
      },
      closedRequisition: {
        defaultMessage: "We couldn't process the request because interviews cannot be scheduled with such a short notice between the interview request and the interview time. Interview invitations must be scheduled at least 3 hours in advance. Please choose a different time and try again. If the error persists, you can contact support for further assistance.",
        id: 'boilerplate.components.Candidate.inviteToInterview.error.closedRequisition'
      },
      serverError: {
        defaultMessage: 'We could not process the request. Please try scheduling the interview again. If the error persists, contact support for assistance.',
        id: 'boilerplate.components.Candidate.inviteToInterview.error.serverError'
      },
      errorTitle: {
        defaultMessage: 'An error occurred while trying to process the request',
        id: 'boilerplate.components.Candidate.inviteToInterview.error.errorTitle'
      }
    }
  },
  hireCandidate: {
    title: {
      defaultMessage: 'Move to hire',
      id: 'boilerplate.components.Candidate.hireCandidate.title'
    },
    question: {
      defaultMessage: 'Are you sure you want to move this candidate to hired?',
      id: 'boilerplate.components.Candidate.hireCandidate.question'
    },
    cancel: {
      defaultMessage: 'Cancel',
      id: 'boilerplate.components.Candidate.rescheduleInterview.cancel'
    },
    hire: {
      defaultMessage: 'Move to hire!',
      id: 'boilerplate.components.Candidate.hireCandidate.hire'
    },

  },
  advance: {
    title: {
      id: 'boilerplate.components.Candidate.advance.title',
      defaultMessage: 'Advance candidate'
    },
    workingPlace: {
      id: 'boilerplate.components.Candidate.advance.workingPlace',
      defaultMessage: 'Working place'
    },
  },
  standBy: {
    title: {
      id: 'boilerplate.components.Candidate.standBy.title',
      defaultMessage: 'Move candidate to standby'
    }
  },
  actions: {
    cancelInterview: {
      defaultMessage: 'Cancel interview',
      id: 'boilerplate.components.Candidate.actions.cancelInterview'
    },
    goToCandidate: {
      defaultMessage: 'Go to candidate',
      id: 'boilerplate.components.Candidate.actions.goToCandidate'
    },
    inviteToInterview: {
      defaultMessage: 'Invite to interview',
      id: 'boilerplate.components.Candidate.actions.inviteToInterview'
    },
    addToApplication: {
      defaultMessage: 'Add to application',
      id: 'boilerplate.components.Candidate.actions.addToApplication'
    },
    rescheduleInterview: {
      defaultMessage: 'Reschedule interview',
      id: 'boilerplate.components.Candidate.actions.rescheduleInterview'
    },
    hire: {
      defaultMessage: 'Hire candidate',
      id: 'boilerplate.components.Candidate.actions.hire'
    },
    resume: {
      defaultMessage: 'Show resume',
      id: 'boilerplate.components.Candidate.actions.resume'
    },
    record: {
      defaultMessage: 'Show history',
      id: 'boilerplate.components.Candidate.actions.record'
    },
    title: {
      defaultMessage: 'Actions',
      id: 'boilerplate.components.Candidate.actions.title'
    },
    advance: {
      defaultMessage: 'Advance candidate',
      id: 'boilerplate.components.Candidate.actions.advance'
    },
    reject: {
      defaultMessage: 'Reject candidate',
      id: 'boilerplate.components.Candidate.actions.reject'
    },
    standBy: {
      defaultMessage: 'Standby',
      id: 'boilerplate.components.Candidate.actions.standBy'
    },
    approveCandidate: {
      defaultMessage: 'Advance',
      id: 'boilerplate.components.Candidate.actions.approveCandidate'
    },
    rejectCandidate: {
      defaultMessage: 'Reject candidate',
      id: 'boilerplate.components.Candidate.actions.rejectCandidate'
    },
    standByCandidate: {
      defaultMessage: 'Standby',
      id: 'boilerplate.components.Candidate.actions.standByCandidate'
    },
    changeVacancy: {
      defaultMessage: 'Change vacancy',
      id: 'boilerplate.components.Candidate.actions.changeVacancy'
    },
    editCandidateData: {
      defaultMessage: 'Edit data',
      id: 'boilerplate.components.Candidate.actions.editCandidateData'
    },
    editCandidateFiles: {
      defaultMessage: 'Edit and validate documents',
      id: 'boilerplate.components.Candidate.actions.editCandidateFiles'
    },
    goBack: {
      defaultMessage: 'Go back',
      id: 'boilerplate.components.Candidate.actions.goBack'
    }
  },
  specialNeeds: {
    title: {
      defaultMessage: 'Special Needs',
      id: 'boilerplate.components.Candidate.specialNeeds.title'
    }
  },
  helpAndSupport: {
    title: {
      defaultMessage: 'Performance Review',
      id: 'boilerplate.components.Candidate.helpAndSupport.title'
    },
    analysis: {
      text: {
        defaultMessage: 'Analyze current user status',
        id: 'boilerplate.components.Candidate.helpAndSupport.analysis.text'
      },
      button: {
        defaultMessage: 'Execute analysis',
        id: 'boilerplate.components.Candidate.helpAndSupport.analysis.button'
      },
      success: {
        defaultMessage: 'Analysis executed successfully. Code: {code}',
        id: 'boilerplate.components.Candidate.helpAndSupport.analysis.success'
      }
    }
  },
  changeVacancy: {
    title: {
      id: 'boilerplate.components.Candidate.changeVacancy.title',
      defaultMessage: 'Change vacancy'
    },
    vacancy: {
      id: 'boilerplate.components.Candidate.changeVacancy.vacancy',
      defaultMessage: 'Vacancy'
    },
    noMatch: {
      id: 'boilerplate.components.Candidate.changeVacancy.noMatch',
      defaultMessage: 'no matching results'
    }
  },
  editCandidateData: {
    title: {
      defaultMessage: 'Edit data',
      id: 'boilerplate.components.Candidate.editCandidateData.title'
    }
  },
  goToCandidate: {
    error: {
      defaultMessage: "We're sorry. We were unable to complete the operation.",
      id: 'boilerplate.components.Candidate.goToCandidate.error'
    }
  },
  format: emiMessages.format,
  emi: {
    button: emiMessages.button
  },
  validation: {
    numberOfCharacters: {
      defaultMessage: 'Number of characters required: {maxLength}',
      id: 'boilerplate.components.Candidate.validation.numberOfCharacters'
    },
    minimumNumberOfCharacters: {
      defaultMessage: 'Minimum number of characters required: {minLength}',
      id: 'boilerplate.components.Candidate.validation.minimumNumberOfCharacters'
    },
    maximumNumberOfCharacters: {
      defaultMessage: 'Maximum number of characters allowed: {maxLength}',
      id: 'boilerplate.components.Candidate.validation.minimumNumberOfCharacters'
    },
    requiredField: {
      defaultMessage: 'Required field',
      id: 'boilerplate.components.Candidate.validation.requiredField'
    }
  }
})
