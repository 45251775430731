import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiToolbar from "@mui/material/Toolbar";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: "white",
  height: theme.custom.navigation.appBar.height,
  minHeight: theme.custom.navigation.appBar.height,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("sm")]: {
    height: theme.custom.navigation.appBar.height,
    minHeight: theme.custom.navigation.appBar.height,
  }
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  height: theme.custom.navigation.appBar.height,
  minHeight: theme.custom.navigation.appBar.height,
  padding: theme.spacing(1, 6, 1, 3),
  [theme.breakpoints.up("sm")]: {
    height: theme.custom.navigation.appBar.height,
    minHeight: theme.custom.navigation.appBar.height,
  },
}));

export default ({ children }) => (
  <AppBar position="fixed">
    <Toolbar>
      {children}
    </Toolbar>
  </AppBar>
)