import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers'
import moment from 'moment'

const TimeCondition = ({ label, value, onChange, metadata }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardTimePicker
      fullWidth
      variant="inline"
      inputVariant="outlined"
      id="time-picker"
      label={label}
      value={value ? moment(value, 'HH:mm') : value}
      onChange={(newValue) => onChange(moment(newValue).format("HH:mm"))}
      KeyboardButtonProps={{
        'aria-label': 'change time',
      }}
      size='small'
      error={metadata && metadata.error}
      helperText={metadata && metadata.error ? metadata.errorMessage : null}
    />
  </MuiPickersUtilsProvider>
)

export default TimeCondition