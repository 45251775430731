/**
 *
 * LoginPage
 *
 */

import React from 'react';
import { params } from 'config';
import { createStructuredSelector } from 'reselect'
import { makeSelectIsAuthenticated } from 'store/session/selectors'
import { loginAction, logoutAction } from 'store/session/actions'
import { connect } from 'react-redux'

class LockerAuth extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  componentDidMount () {
    const { isAuthenticated, login } = this.props;
    if (!isAuthenticated) {
      const loginUrl = `${params.COGNITO_LOGIN_URL}/?callbackUrl=${encodeURIComponent(window.location.href)}`;
      window.location = loginUrl;
    } else {
      login();
    }
  }

  logout() {
    const { isAuthenticated, logout } = this.props
    if (isAuthenticated) {
      // logout
      logout()
    }
  }

  render() {
    return (null);
  }
}

LockerAuth.propTypes = {};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated()
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
  login: () => dispatch(loginAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(LockerAuth);
