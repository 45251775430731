import { SIGN_IN, SIGN_OUT} from './constants';
import initialState from './initialState';
import { signOut } from 'utils/signOut'

function appReducer(state = initialState, action) {
  console.log("appReducer session", action.type)
  switch (action.type) {
    case SIGN_IN:
      return state
    case SIGN_OUT:
      signOut()
      return {
        cognitoUser: null,
        session: null
      };
    default:
    return state;
  }

}

export default appReducer;
