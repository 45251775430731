import Cache from './cache';
import KeyValueStore from './keyValueStore';
import { signOut } from './signOut';
import { useUserOrEmpty } from './hooks';

/**
 * Parses the JSON returned by a network request
 * @param  {object} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response && response.status === 204) {
    return {};
  }
  if (response.headers.get("content-type") && response.headers.get("content-type").includes('json')) {
    return response.json();
  }
  const blob = response.clone().blob();
  return blob.then(blob => blob);
}

class ResponseError extends Error {
  constructor(message = '', status, response, ...args) {
    super(message, ...args);
    this.message = message;
    this.status = status;
    this.response = response;
  }
}

export const SERVICE_UNAVAILABLE_EVENT = 'serviceUnavailable';
export const SERVICE_OK_EVENT = 'serviceOk';
const serviceUnavailableEvent = new Event(SERVICE_UNAVAILABLE_EVENT);
const serviceOkEvent = new Event(SERVICE_OK_EVENT);

/**
 * Checks if a network request came back fine, and throws an error if not
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {

  if (response.status >= 200 && response.status < 300) {
    document.dispatchEvent(serviceOkEvent);
    return response;
  }

  if (response.status === 401) {
    window.localStorage.clear('user');
    window.location = '/';
  }

  const result = await parseJSON(response);
  if (response.status === 403) {
    if (result.signout) {
      signOut();
    }
  }

  if (response.status === 503) {
    document.dispatchEvent(serviceUnavailableEvent);
  }

  const error = new ResponseError(response.statusText, response.status, result);
  throw error;
}

function resolveRequest(url, options) {
  // include cookies
  options.credentials = 'include';

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);
}

function hasCacheEnabled(user) {
  return !(user.subsidiaryId === 1 || user.isFake)
}

function shouldBeCached (url, options) {
  if (options.method !== "GET") return false
  
  // Cache specific URLs
  const cacheableUrls = [
    '/api/v1/views/candidates/filters/positions',
    '/api/v1/views/candidates/filters/locations',
    '/api/v1/views/candidates/filters/recruiters',
    '/api/v1/views/candidates/filters/steps',
    '/api/v1/vacancies/jobs/filters',
    '/api/v1/subsidiaries/:id/locations/short',
    '/api/v1/interviews/config',
    '/api/v1/subsidiaries/:id/hiring-managers',
    '/api/v1/features/MANUALLY_ADD_CANDIDATE/instance',
    '/api/v1/views/config',
    '/api/v1/listings/reasons',
    '/api/v1/listings/:id/positions',
    '/api/v1/subsidiaries/:id/users'
  ];

  return cacheableUrls.some(cacheableUrl => {
    if (cacheableUrl.includes(':id')) {
      const regex = new RegExp(cacheableUrl.replace(/:id/g, '(\\d+)'));
      return regex.test(url);
    }
    return url.includes(cacheableUrl);
  });
}

async function resolveRequestWithCache (url, options) {
  const cache = new Cache(new KeyValueStore(), { unit: 'minutes', value: '15' })
  const value = await cache.get(url)
  if (value) {
    return value
  }
  const result = await resolveRequest(url, options)
  cache.put(url, result)
  return result
}

/**
 * Requests a URL, returning a promise
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           The response data
 */
export default function request(url, options) {
  const user = useUserOrEmpty()
  const resolver = hasCacheEnabled(user) && shouldBeCached(url, options)
    ? resolveRequestWithCache
    : resolveRequest
  return resolver(url, options)
}
