import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { injectIntl } from 'react-intl';
import PrimaryButton from '../buttons/PrimaryButton';
import ToolbarApproved from './toolbarApproved';

const toolbarStyles = theme => ({
  highlight:
    theme.palette.type === 'light'
      ? {
        color: '#7A7A7A',
        backgroundColor: '#F5F5F5',
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  spacer: {
    flex: '1 1 100%',
    textAlign: 'center',
    color: 'red'
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    flex: '0 0 auto',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  hiddenTitle: {
    display: 'none'
  },
  infoTitle: {
    color: '#7A7A7A',
    textAlign: "center",
    fontSize: 13
  }
});

let ToolbarRejected = props => {
  const { numSelected, numSelectedApproved, numSelectedRejected, isRequiredFieldsError, classes, messages } = props;
  const { formatMessage } = props.intl;
  // <ToolbarApproved

  return (
    <div>
      <ToolbarApproved {...props} />
      <div
        className={classNames({
          [classes.highlight]: numSelected > 0,
          [classes.hiddenTitle]: numSelected == 0
        },
          classes.infoTitle)}
      >
        {formatMessage(messages.table.toolbar.explanation.rejected)}
      </div>
    </div>

  );
};

ToolbarRejected.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  numSelectedApproved: PropTypes.number,
  numSelectedRejected: PropTypes.number,
  changeState: PropTypes.func,
  isRequiredFieldsError: PropTypes.bool,

  
  messages: PropTypes.any.isRequired
};

export default injectIntl(withStyles(toolbarStyles)(ToolbarRejected));
