import React, { useCallback, useMemo, useState } from 'react'
import ProductFruitsContext from './ProductFruitsContext'
import { getProductFruitsInfo } from '../../utils/connector'

export default ({ children }) => {
  const [info, setInfo] = useState(null)

  const fetchInfo = useCallback(async () => {
    try {
      const result = await getProductFruitsInfo()
      setInfo(result)
    } catch (e) {
      console.error('Cannot fetch product-fruits info', e)
    }
  })

  const contextValue = useMemo(() => {
    return {
      info,
      fetchInfo
    }
  }, [info])

  return <ProductFruitsContext.Provider value={contextValue}>
    {children}
  </ProductFruitsContext.Provider>
}