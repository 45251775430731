/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */

import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  GET_PLATFORMS,
  GET_BOARD_LISTINGS,
  GET_USER_PLATFORMS,
  PLATFORM_ADD,
  PLATFORM_REMOVE,
  JOB_LINK,
  JOB_READ } from './constants';

import {
  getBoardListingsSuccess,
  getBoardListingsError,
  getPlatformsSuccess,
  getPlatformsError,
  getUserPlatformsSuccess,
  getUserPlatformsError,
  addPlatformSuccess,
  addPlatformError,
  removePlatformSuccess,
  removePlatformError } from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sUserPlatforms() {
  const requestURL = `${root}/subsidiaryPlatforms`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log("sUserPlatforms", result)
    yield put(getUserPlatformsSuccess(result));

  } catch (err) {
    yield put(getUserPlatformsError(err));
  }
}

export function* getUserPlatformsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_USER_PLATFORMS, sUserPlatforms);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


export function* sPlatforms() {
  const requestURL = `${root}/countryPlatforms`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log(result)
    yield put(getPlatformsSuccess(result));

  } catch (err) {
    yield put(getPlatformsError(err));
  }
}

export function* getPlatformsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_PLATFORMS, sPlatforms);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


export function* sRemovePlatform(action) {
  const requestURL = `${root}/subsidiaryPlatforms/`+action.platform.id;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log(result)
    yield put(removePlatformSuccess(action.platform));

  } catch (err) {
    yield put(removePlatformError(err));
  }
}

export function* removePlatformApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(PLATFORM_REMOVE, sRemovePlatform);

  // Suspend execution until location changes
//    yield take(LOCATION_CHANGE);
//    yield cancel(watcher);
}


export function* sPlatformAdd(action) {
  const requestURL = `${root}/subsidiaryPlatforms`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.platform),
    });
    yield put(addPlatformSuccess(result));

  } catch (err) {
    yield put(addPlatformError(err));
  }
}

export function* addPlatformApi() {
  const watcher = yield takeLatest(PLATFORM_ADD, sPlatformAdd);

//  yield take(LOCATION_CHANGE);
//  yield cancel(watcher);
}


export function* sBoardListings(action) {
  let requestURL = `${root}/subsidiaryPlatforms/listings/`+action.subsidiaryPlatform.id;
  if(action.listingId){
    requestURL = requestURL+"?listingId="+action.listingId
  }
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      cache: 'no-cache'
    });
    let positions = []
    if (action.listingId) {
      positions = yield call(request, `${root}/listings/${action.listingId}/positions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
    }
    yield put(getBoardListingsSuccess(result, positions));

  } catch (err) {
    yield put(getBoardListingsError(err));
  }
}

export function* getBoardListingsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_BOARD_LISTINGS, sBoardListings);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


// All sagas to be loaded
export default [
  getBoardListingsApi,
  getPlatformsApi,
  getUserPlatformsApi,
  removePlatformApi,
  addPlatformApi
];
