import React from 'react';
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  icon: theme.styles.tabs.appBar.item.icon,

}))

const SearchIcon = (props) => {
  const classes = useStyles()
  return <div {...props}>
    <img src={require('../../assets/search.svg')} className={classes.icon}/>
  </div>
}

export default SearchIcon
