
const initialState = {
  integrations: {
    google: false
  },
  isFetching: false
};

export default initialState;


