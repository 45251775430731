import React from 'react'
import { connect } from 'react-redux'
import { candidateChanged, hideHire } from '../../../store/candidateActions/actions'
import HireCandidateDialog from '../HireCandidateDialog'

const HireAction = ({ store: { show, data }, close, notify }) => {
  const { candidateId, candidateName, listingId, channelUserId } = data || {}
  const onSucceed = () => {
    notify()
    close()
  }
  return <HireCandidateDialog
    show={show}
    candidateId={candidateId}
    candidateName={candidateName}
    listingId={listingId}
    channelUserId={channelUserId}
    onClose={close}
    onSucceed={onSucceed}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').hire
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideHire()),
    notify: () => dispatch(candidateChanged())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HireAction)
