/*
 *
 * DocumentationPage constants
 *
 */

export const GET_DOCUMENTATION = 'app/DocumentationPage/GET_DOCUMENTATION'
export const GET_DOCUMENTATION_SUCCESS = 'app/DocumentationPage/GET_DOCUMENTATION_SUCCESS'
export const GET_DOCUMENTATION_ERROR = 'app/DocumentationPage/GET_DOCUMENTATION_ERROR'
export const CLEAR_MESSAGE = 'app/DocumentationPage/CLEAR_MESSAGE'
