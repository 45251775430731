import React, { useCallback, useEffect, useRef, useState } from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import track from '../../utils/track';
import { useUser } from '../../utils/hooks';

const TAWKTO_INSTANCE = Object.freeze({
  AR: '1h7iipjog',
  MX: 'default',
  US: '1f12ouind'
})

const parseLocaleTag = (tag) => {
  if (typeof tag !== 'string' || tag.length < 2) {
    return null
  }
  const [language, country] = tag.split('-')
  return { language, country }
}

const extractInstanceFromCountry = (country) => {
  if (country) {
    return TAWKTO_INSTANCE[country]
  }
  return null
}

const extractInstanceFromLocale = (locale) => {
  if (locale) {
    const parsed = parseLocaleTag(locale)
    if (parsed) {
      return extractInstanceFromCountry(parsed.country)
    }
  }
  return null
}

const resolveWidgetId = (user) => {
  // TODO: this is just for testing purposes
  // if (user && user.id === 5383) {
  //   return '1h69289t2'
  // }
  if (user && user.roles && user.roles.includes('sys_admin') && user.locale) {
    const instance = extractInstanceFromLocale(user.locale)
    if (instance) {
      return instance
    }
  }

  if (user && user.subsidiary && user.subsidiary.country && user.subsidiary.country.code) {
    const instance = extractInstanceFromCountry(user.subsidiary.country.code)
    if (instance) {
      return instance
    }
  }

  return TAWKTO_INSTANCE.MX
}

const SUPPORT_SECTIONS = ['support']
const isChatEnabled = (user) => {
  return user && user.authorizedSections && user.authorizedSections.some(section => SUPPORT_SECTIONS.includes(section))
}

const EVENT = Object.freeze({
  LOAD: 'TAWKTO_ON_LOAD',
  STATUS_CHANGE: 'TAWKTO_ON_STATUS_CHANGE',
  BEFORE_LOAD: 'TAWKTO_ON_BEFORE_LOAD',
})

const trackEvent = (eventType, tawkToRef) => {
  try {
    track({
      type: eventType,
      status: tawkToRef.getStatus(),
      isChatHidden: tawkToRef.isChatHidden(),
      widgetPosition: tawkToRef.widgetPosition(),
      windowType: tawkToRef.getWindowType()
    })
  } catch (e) {
    // Igore error
  }
}

const SupportChat = () => {
  
  const user = useUser()
  const tawkMessengerRef = useRef();
  const [isEnabled, setIsEnabled] = useState(false)
  const [widgetId, setWidgetId] = useState(null)

  useEffect(() => { 
    setIsEnabled(isChatEnabled(user))
    setWidgetId(resolveWidgetId(user))
  }, [user])

  const handleLoad = useCallback(() => {

    trackEvent(EVENT.LOAD, tawkMessengerRef.current)

    tawkMessengerRef.current.visitor({
      name: `${user.firstName} ${user.lastName}`,
      email: user.email
    }, function(err) {
      console.debug('### chat - visitor', { err })
    })
    tawkMessengerRef.current.addTags(
      [
        `User id: ${user.id}`,
        `Subsidiary: ${user.subsidiary.name} (#${user.subsidiary.id})`,
        `Country: ${user.subsidiary.country.name}`,
      ],
      function(err) {
        console.debug('### chat - addTags', { err })
      }
  );
  }, [user, tawkMessengerRef])

  const handleStatusChange = useCallback(() => {
    trackEvent(EVENT.STATUS_CHANGE, tawkMessengerRef.current)
  }, [tawkMessengerRef])

  const handleBeforeLoad = useCallback(() => {
    trackEvent(EVENT.BEFORE_LOAD, tawkMessengerRef.current)
  }, [tawkMessengerRef])

  if (!user) {
    console.debug('Support chat is only available for authenticated users')
    return null
  }

  if (!isEnabled) {
    console.debug('Support chat is not enabled')
    return null
  }

  if (!widgetId) {
    console.debug('Support chat requires widgetId')
    return null
  }

  return <TawkMessengerReact
    ref={tawkMessengerRef}
    propertyId="5ed55f858ee2956d73a693ed"
    widgetId={widgetId}
    onLoad={handleLoad}
    onBeforeLoad={handleBeforeLoad}
    onStatusChange={handleStatusChange}
  />
}

export {
  SupportChat
}