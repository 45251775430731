import { every, toString } from "lodash"
import { getRequest } from "../../../../utils/connector"

const sleep = ms => new Promise((resolve, _) => {
    setTimeout(resolve, ms)
})

/**
 * 
 * creates a resolver function for the specified field resolver definition
 * a resolver function must be used to trigger a specific field's resolution.
 * A resolver function receives an arguments object, and returns a Promise<[resource]>
 * The following properties are provided in the arguments object: subsidiaryId, listingId, stepId, candidateId.
 * In addition, custom dependency fields can be provided into this arguments object
 * Each resolver function should return a list of resources. A resource is an object with the "id" and "name" properties
 * 
 * @param {Object} resolverDefinition 
 * @param {String} resolverDefinition.name - states the name for the resolver builder, as stated in the below dictionary
 * @param {Array} resolverDefinition.args - arguments to be passed in to the resolver builder
 * @returns {Function}
 */
export const buildResolver = resolverDefinition => {
    const { name, args } = resolverDefinition
    const builder = resolverBuilders[ name ]
    return builder(...args)
}

/**
 * this dictionary holds the definition for each resolver builder.
 * A resolver builder should be a function that returns the resolver function to be used.
 * Each resolver builder can receive a variable and flexible array of arguments, as specified in each
 * field definition/configuration.-
 */
const resolverBuilders = {
    'FAKE': (type, compareFields) => {
        const fakeEntitiesByType = {
            'EMPRESA': [
                { id: "1", name: 'Empresa 1', plaza_id: "184" },
                { id: "2", name: 'Empresa 2', plaza_id: "184" },
                { id: "3", name: 'Empresa 3', plaza_id: "184" },
                { id: "4", name: 'Empresa 4', plaza_id: "185" },
                { id: "5", name: 'Empresa 5', plaza_id: "185" }
            ],
            'REPRESENTANTE': [
                { id: "1", name: 'Representante 1', plaza_id: "184", empresa_id: "2" },
                { id: "2", name: 'Representante 2', plaza_id: "184", empresa_id: "2" },
                { id: "3", name: 'Representante 3', plaza_id: "184", empresa_id: "1" },
                { id: "4", name: 'Representante 4', plaza_id: "185", empresa_id: "2" },
                { id: "5", name: 'Representante 5', plaza_id: "184", empresa_id: "1" }
            ]
        }

        const entities = fakeEntitiesByType[ type ]

        return async resolvingArgs => {
            await sleep(2000) // simulate API call

            return entities
                .filter(entity => 
                    every(compareFields, field => resolvingArgs[ field.source ] === entity[ field.mapTo ])
                )
        }

    },
    'API_GET': (rawUrl, stringifyIds) => {
        return async (resolvingArgs) => {
            const keys = Object.keys(resolvingArgs)

            const url = keys.reduce((prev, currentKey) => {
                // replace placeholders with stringified values e.g.: /{listingId}/positions => /1530/positions
                return prev.replace(`{${currentKey}}`, resolvingArgs[ currentKey ] + '')
            }, rawUrl)

            const resources = await getRequest(url)

            if (stringifyIds) {
                resources.forEach(r => {
                    r.id = toString(r.id)
                })
            }
    
            return resources
        }
    },
    'DOC2SIGN': (entity, dependencies = null) => {
      const transform = ({ ID, Nombre }) => ({ id: String(ID), name: Nombre.trim() })
      const hasDependencies = !!dependencies && !!Object.keys(dependencies).length
      const resolveDependencies = (resolvingArgs, deps) => {
        const dependencyArray = Object.entries(deps).map(([key, value]) => [key, resolvingArgs[value]])

        return Object.fromEntries(dependencyArray)
      }

      return async (resolvingArgs) => {
        const requestArgs = hasDependencies ? resolveDependencies(resolvingArgs, dependencies) : null
        const resources = await getRequest(`/doc2sign/catalog/${entity}`, requestArgs)

        return resources.map(transform)
      }
    }
}