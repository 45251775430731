/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  RESET_CREATE_FLOW,
  CREATE_FLOW,
  CREATE_FLOW_SUCCESS,
  CREATE_FLOW_ERROR,
  GET_FLOWS,
  GET_FLOWS_SUCCESS,
  GET_FLOWS_ERROR,
  GET_SUBSIDIARY_FLOWS,
  GET_SUBSIDIARY_FLOWS_SUCCESS,
  GET_SUBSIDIARY_FLOWS_ERROR,
  GET_SUBSIDIARY_REJECTION_FLOWS,
  GET_SUBSIDIARY_REJECTION_FLOWS_SUCCESS,
  GET_SUBSIDIARY_REJECTION_FLOWS_ERROR,
  REMOVE_FLOW,
  REMOVE_FLOW_SUCCESS,
  REMOVE_FLOW_ERROR,
  CLEAR_MESSAGE,
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}

export function getFlows() {
  console.log('action GET_FLOWS')
  return {
    type: GET_FLOWS
  };
}

export function getFlowsSuccess(flows) {
  return {
    type: GET_FLOWS_SUCCESS,
    flows
  };
}

export function getFlowsError(error) {
  return {
    type: GET_FLOWS_ERROR,
    error
  };
}


export function getSubsidiaryFlows(subsidiaryId) {
  console.log('action GET_SUBSIDIARY_FLOWS')
  return {
    type: GET_SUBSIDIARY_FLOWS,
    subsidiaryId
  };
}

export function getSubsidiaryFlowsSuccess(flows) {
  return {
    type: GET_SUBSIDIARY_FLOWS_SUCCESS,
    flows
  };
}

export function getSubsidiaryFlowsError(error) {
  return {
    type: GET_SUBSIDIARY_FLOWS_ERROR,
    error
  };
}

export function createFlow(flow){
  return {
    type: CREATE_FLOW,
    flow
  }
}


export function createFlowSuccess(flow){
  return {
    type: CREATE_FLOW_SUCCESS,
    flow
  }
}


export function createFlowError(error){
  return {
    type: CREATE_FLOW_ERROR,
    error
  }
}

export function removeFlow(flow){
  return {
    type: REMOVE_FLOW,
    flow
  }
}


export function removeFlowSuccess(flow){
  return {
    type: REMOVE_FLOW_SUCCESS,
    flow
  }
}


export function removeFlowError(error){
  return {
    type: REMOVE_FLOW_ERROR,
    error
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function resetCreateFlow() {
  return {
    type: RESET_CREATE_FLOW,
  };
}


export function getSubsidiaryRejectionFlows(subsidiaryId) {
  console.log('action GET_SUBSIDIARY_FLOWS')
  return {
    type: GET_SUBSIDIARY_REJECTION_FLOWS,
    subsidiaryId
  };
}

export function getSubsidiaryRejectionFlowsSuccess(flows) {
  return {
    type: GET_SUBSIDIARY_REJECTION_FLOWS_SUCCESS,
    flows
  };
}

export function getSubsidiaryRejectionFlowsError(error) {
  return {
    type: GET_SUBSIDIARY_REJECTION_FLOWS_ERROR,
    error
  };
}
