const moment = require('moment');

const parseDate = value => moment(value, 'YYYY-MM-DD');

const isValidDate = value =>
  !!value &&
  (!!value.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) ||
   !!value.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d/)) &&
  parseDate(value).isValid();

const getCurrentDate = () => moment().startOf('day');

const extractDateOrNull = value => {
  if (['yesterday', 'today', 'tomorrow'].includes(value)) {
    if (value === 'yesterday') {
      return getCurrentDate().add(-1, 'day').format('YYYY-MM-DD');
    }
    if (value === 'today') {
      return getCurrentDate().format('YYYY-MM-DD');
    }
    if (value === 'tomorrow') {
      return getCurrentDate().add(1, 'day').format('YYYY-MM-DD');
    }
    return null;
  }
  if (value && !!value.match(/[+|-][0-9]+[y|M|w|d]/g)) {
    const unit = value.slice(-1)
    const amount = +value.substring(0, value.length - 1)
    return getCurrentDate().add(amount, unit).format('YYYY-MM-DD')
  }
  if (isValidDate(value)) {
    return value;
  }
  return null;
};

const extractDateTimeOrNull = value => {
  if (['yesterday', 'today', 'tomorrow'].includes(value)) {
    if (value === 'yesterday') {
      return getCurrentDate().add(-1, 'day').format('YYYY-MM-DDTHH:MM');
    }
    if (value === 'today') {
      return getCurrentDate().format('YYYY-MM-DDTHH:MM');
    }
    if (value === 'tomorrow') {
      return getCurrentDate().add(1, 'day').format('YYYY-MM-DDTHH:MM');
    }
    return null;
  }
  if (value && !!value.match(/[+|-][0-9]+[y|M|w|d]/g)) {
    const unit = value.slice(-1)
    const amount = +value.substring(0, value.length - 1)
    return getCurrentDate().add(amount, unit).format('YYYY-MM-DDTHH:MM')
  }
  if (isValidDate(value)) {
    return value;
  }
  return null;
};

// TODO: we should remove this from here
const HOLIDAYS = ['2024-01-01', '2024-02-05', '2024-03-18', '2024-05-01', '2024-09-16', '2024-11-18', '2024-12-25'];
const SPECIFIC_DAYS = [];

const isHoliday = value => HOLIDAYS.includes(value.format('YYYY-MM-DD'));
const isSpecificDay = value => SPECIFIC_DAYS.includes(value.format('YYYY-MM-DD'));

module.exports = {
  extractDateOrNull,
  extractDateTimeOrNull,
  parseDate,
  isValidDate,
  getCurrentDate,
  isHoliday,
  isSpecificDay
};
