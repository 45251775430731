import React from 'react'
import { connect } from 'react-redux'
import { candidateChanged, hideCancelInterview } from '../../../store/candidateActions/actions'
import CancelInterviewDialog from '../CancelInterviewDialog'

const CancelInterviewAction = ({ store: { show, data }, close, notify }) => {
  const { candidateId, stepId } = data || {}
  const onSucceed = () => {
    notify()
    close()
  }
  return <CancelInterviewDialog
    show={show}
    candidateId={candidateId}
    stepId={stepId}
    onClose={close}
    onSucceed={onSucceed}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').interview.cancel
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideCancelInterview()),
    notify: () => dispatch(candidateChanged())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelInterviewAction)
