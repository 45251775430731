import { useEffect, useRef } from 'react';

export const useTimeout = (callback, delay) => {
    let savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay === null) return;

        const timeout = setTimeout(() => savedCallback.current(), delay);

        return () => clearTimeout(timeout);
    }, [delay]);
};
