import React from 'react'
import { injectIntl } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PrimaryButton from '../../../../../v2/components/buttons/PrimaryButton'
import SecondaryButton from '../../../../../v2/components/buttons/SecondaryButton'
import messages from '../../../messages'

const RejectCandidateForReasonDialog = ({ open, onClose, name, reason, onConfirm, intl: { formatMessage } }) => {
  const doConfirm = () => onConfirm(reason)

  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth='md'
    fullWidth
  >
    <DialogTitle>{formatMessage(messages.descartes.rejectDialog.title)}: {name}</DialogTitle>
    <DialogContent>
      {formatMessage(messages.descartes.rejectDialog.reason)}: {reason}
    </DialogContent>
    <DialogActions>
      <PrimaryButton
        onClick={doConfirm}
      >
        {formatMessage(messages.button.confirm)}
      </PrimaryButton>
      <SecondaryButton
        type="text"
        onClick={onClose}
      >
        {formatMessage(messages.button.cancel)}
      </SecondaryButton>
    </DialogActions>
  </Dialog>
}

export default injectIntl(RejectCandidateForReasonDialog)