import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types'
import AppBarTabItem from './AppBarTabItem';

const useStyles = makeStyles((theme) => ({
  main: theme.styles.tabs.appBar.main,
}));

const AppBarTabs = ({ items }) => {
  const classes = useStyles()
  return <Box className={classes.main}>
    {items.map((item, index) => <AppBarTabItem key={index} {...item} />)}
  </Box>
}

AppBarTabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    selected: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired
  })),
};

export default AppBarTabs
