import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const NewInterviewSummary = ({ title, conditions, message, submitButton, cancelButton }) => {
  return (
    <React.Fragment>
      <Box marginBottom='15px'>
        <Grid container>
          <Grid item xs={12}>
            <Box
              fontFamily='Lato'
              fontStyle='normal'
              fontWeight='bold'
              fontSize='24px'w
              lineHeight='31px'
            >{title}</Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box textAlign='left' padding='20px' borderBottom='1px solid black' marginBottom='25px'>
            {
              conditions.map(({ label, value }, index) => <Grid item xs={12} key={index}><b>{label}:</b> {value}</Grid>)
            }
          </Box>
        </Grid>
        <Grid item xs={12}>{message}</Grid>
      </Grid>
      <Box marginTop='15px'>
        <Grid container justify='flex-end'>
          <Grid item>
            {cancelButton}
          </Grid>
          <Grid item>
            {submitButton}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}

NewInterviewSummary.propTypes = {
  title: PropTypes.string.isRequired,
  conditions: PropTypes.array.isRequired,
  message: PropTypes.string.isRequired,
  submitButton: PropTypes.element.isRequired,
  cancelButton: PropTypes.element.isRequired,
}

export default NewInterviewSummary
