export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE'
export const LISTING_STATES = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  ENDED: 'ENDED',
  DELETED: 'DELETED',
  HIDDEN: 'HIDDEN'
}

export const DESCARTES = 'DESCARTES';
export const INTERVIEW = 'INTERVIEW';
export const SELECTED = 'SELECTED';
export const HIRED = 'HIRED';
export const MANUAL = 'MANUAL';
export const REQUISITION = 'REQUISITION';
export const INTEGRATION = 'INTEGRATION';
export const HIRING_MANAGER = 'HIRING_MANAGER'

export const STEP_TYPES = [
  DESCARTES,
  INTERVIEW,
  SELECTED,
  HIRED,
  MANUAL,
  REQUISITION,
  INTEGRATION,
  HIRING_MANAGER
]

export const INTERVIEW_CONDITION_TYPE_OPERATOR = 'operator';
export const INTERVIEW_CONDITION_TYPE_WP = 'working_place';
export const INTERVIEW_CONDITION_TYPE_JOB_POSITION = 'job_position';

export const CONTAINS = 'contains';
export const BETWEEN = 'between';

export const INTERVIEW_WP_CONDITIONS = [
  {questionKey: 'working_place_id', label: 'Sucursales', type: INTERVIEW_CONDITION_TYPE_WP, cboSlField: 'name'}
]

export const INTERVIEW_JOB_POSITION_CONDITIONS = [
  {questionKey: 'job_position_id', label: 'Posiciones', type: INTERVIEW_CONDITION_TYPE_JOB_POSITION},
]

export const INTERVIEW_STATS_INVITED = "invited";
export const INTERVIEW_STATS_CONFIRMED = "confirmed";
export const INTERVIEW_STATS_RESCHEDULED = "rescheduled";
export const INTERVIEW_STATS_ABSENT = "absent";
