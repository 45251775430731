import React, { createContext, useReducer } from 'react'

const initialState = (name) => ({
  name,
  instance: null,
  error: null,
  state: 'LOADING'
})

const contextReducer = (state, action) => {
  switch (action.type) {
    case 'set_loading': {
      return { ...state, state: 'LOADING', instance: null, error: null }
    }
    case 'set_instance': {
      return { ...state, state: 'OK', instance: action.instance, error: null }
    }
    case 'set_error': {
      return { ...state, state: 'ERROR', error: null, error: action.error }
    }
  }
  return state
}

export const LoaderContext = createContext(null);

export const LoaderContextProvider = ({ name, children }) => {
  const [state, dispatch] = useReducer(contextReducer, initialState(name));
  return <LoaderContext.Provider value={{ state, dispatch }}>
    {children}
  </LoaderContext.Provider>
}

export const FeatureContext = createContext(null);

export const FeatureContextProvider = ({ instance, children }) => {
  return <FeatureContext.Provider value={instance}>
    {children}
  </FeatureContext.Provider>
}