/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl'
export default defineMessages({
  title: {
    id: 'boilerplate.components.SearchCandidates.title',
    defaultMessage: 'Search candidates'
  },
  description: {
    id: 'boilerplate.components.SearchCandidates.description',
    defaultMessage: 'One or more fields are required to perform a search, fields are optional'
  },
  action: {
    downloadData: {
      id: 'boilerplate.components.Candidate.show.action.downloadData',
      defaultMessage: 'Download data'
    },
    buildingPDF: {
      id: 'boilerplate.components.Candidate.show.action.buildingPDF',
      defaultMessage: 'Building PDF'
    }
  },
  section: {
    personalData: {
      id: 'boilerplate.components.Candidate.show.section.personalData',
      defaultMessage: 'Personal Data'
    },
    education: {
      id: 'boilerplate.components.Candidate.show.section.education',
      defaultMessage: 'Education'
    },
    experience: {
      id: 'boilerplate.components.Candidate.show.section.experience',
      defaultMessage: 'Experience'
    },
    generalData: {
      id: 'boilerplate.components.Candidate.show.section.generalData',
      defaultMessage: 'General Data'
    },
    timeAvailability: {
      id: 'boilerplate.components.Candidate.show.section.timeAvailability',
      defaultMessage: 'Time Availability'
    },
    testAnswers: {
      id: 'boilerplate.components.Candidate.show.section.testAnswers',
      defaultMessage: 'Test answers'
    },
    interviewData: {
      id: 'boilerplate.components.Candidate.show.section.interviewData',
      defaultMessage: 'Interview Data'
    }
  }
})
