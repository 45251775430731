import React from 'react'
import { Loader } from './components/Loader'
import { LoaderContextProvider } from './context'
import PageFallback from './components/PageFallback'
export { default as useFeature} from './hooks/useFeature'
export { HasAccess } from './components/HasAccess'

export const PageFeature = ({
  name,
  children
}) => (
  <Feature
    name={name}
    fallback={<PageFallback />}
  >
    {children}
  </Feature>
)

const Feature = ({
  name,
  fallback,
  loading,
  children,
  loadStrategy
}) => (
  <LoaderContextProvider name={name}>
    <Loader
      loading={loading}
      fallback={fallback}
      strategy={loadStrategy}
    >
      {children}
    </Loader>
  </LoaderContextProvider>
)

export default Feature
