/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';

import emiMessages from '../../../../app/components/Emi/messages'

export default defineMessages({
  listing: emiMessages.listing,
  testingBanner: {
    id: 'boilerplate.components.v2.other.testingBanner',
    defaultMessage: 'Testing environment'
  }
});
