/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
 import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  CREATE_FLOW,
  GET_SUBSIDIARY_FLOWS,
  GET_SUBSIDIARY_REJECTION_FLOWS,
  GET_FLOWS,
  REMOVE_FLOW,
} from './constants';

import {
  getSubsidiaryFlowsSuccess,
  getSubsidiaryFlowsError,
  getFlowsSuccess,
  getFlowsError,
  removeFlowSuccess,
  removeFlowError,
  createFlowSuccess,
  createFlowError,
  getSubsidiaryRejectionSuccess,
  getSubsidiaryRejectionError,
  getSubsidiaryRejectionFlowsSuccess,
  getSubsidiaryRejectionFlowsError
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sSubsidiaryFlows(action) {
  const requestURL = `${root}/flows/subsidiary/` + action.subsidiaryId;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log("sSubsidiaryFlows", result)
    yield put(getSubsidiaryFlowsSuccess(result));

  } catch (err) {
    yield put(getSubsidiaryFlowsError(err));
  }
}

export function* getSubsidiaryFlowsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_SUBSIDIARY_FLOWS, sSubsidiaryFlows);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sFlows() {
  const requestURL = `${root}/flows`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    yield put(getFlowsSuccess(result));

  } catch (err) {
    yield put(getFlowsError(err));
  }
}

export function* getFlowsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_FLOWS, sFlows);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sRemoveFlow(action) {
  const requestURL = `${root}/flows/`+action.flow.id;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log(result)
    yield put(removeFlowSuccess(action.flow));

  } catch (err) {
    yield put(removeFlowError(err));
  }
}

export function* removeFlowApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(REMOVE_FLOW, sRemoveFlow);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}

export function* sCreateFlow(action) {
  const requestURL = `${root}/flows`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.flow),
    });
    yield put(createFlowSuccess(result));
    yield put(push('/flows'));
  } catch (err) {
    yield put(createFlowError(err));
  }
}

export function* createFlowApi() {
  const watcher = yield takeLatest(CREATE_FLOW, sCreateFlow);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}



function* sRejectionFlows(action) {
  const requestURL = `${root}/flows/subsidiary/${action.subsidiaryId}/rejection`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log("sRejectionFlows", result)
    yield put(getSubsidiaryRejectionFlowsSuccess(result));

  } catch (err) {
    yield put(getSubsidiaryRejectionFlowsError(err));
  }
}

export function* getRejectionFlowsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_SUBSIDIARY_REJECTION_FLOWS, sRejectionFlows);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}





// All sagas to be loaded
export default [
  getRejectionFlowsApi,
  getSubsidiaryFlowsApi,
  getFlowsApi,
  removeFlowApi,
  createFlowApi
];
