import { GET_INTEGRATIONS, GET_INTEGRATIONS_SUCCESS, AUTH_GOOGLE_REQUEST, AUTH_GOOGLE_SUCCESS } from './constants';

export function getIntegrations() {
  return {
    type: GET_INTEGRATIONS
  };
}

export function getIntegrationsSuccess(integrations) {
  return {
    type: GET_INTEGRATIONS_SUCCESS,
    integrations
  };
}

export function authGoogle() {
  return {
    type: AUTH_GOOGLE_REQUEST
  };
}

export function authGoogleSuccess(url) {
  return {
    type: AUTH_GOOGLE_SUCCESS,
    url
  };
}
