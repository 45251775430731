import {
  GET_CANDIDATES,
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_ERROR,
  CLEAR_MESSAGE
} from './constants'

export function getCandidates () {
  return {
    type: GET_CANDIDATES
  }
}

export function getCandidatesSuccess (candidates) {
  return {
    type: GET_CANDIDATES_SUCCESS,
    payload: candidates
  }
}

export function getCandidatesError (type, error) {
  return {
    type: GET_CANDIDATES_ERROR,
    payload: {
      type,
      error
    }
  }
}

export function clearMessage () {
  return {
    type: CLEAR_MESSAGE
  }
}
