/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

import appReducer from 'containers/App/reducer';
import subsidiaryReducer from 'containers/Subsidiary/reducer';
import companyReducer from 'containers/Company/reducer';
import platformReducer from 'containers/Platform/reducer';
import platformListingReducer from 'containers/PlatformListings/reducer';
import listingReducer from 'containers/Listings/reducer';
import flowReducer from 'containers/Flow/reducer';
import suggestionsReducer from 'containers/Suggestions/reducer';
import stepViewsReducer from 'containers/StepViews/reducer';
import candidateReducer from 'containers/Candidate/reducer';
import emiAnalyticsReducer from 'containers/EmiAnalytics/reducer';
import sessionReducer from 'store/session/reducer';
import userReducer from 'containers/User/reducer';
import integrationsReducer from 'store/integrations/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import pendingActionsReducer from 'containers/PendingActions/reducer';
import candidateBag from 'containers/CandidateBag/reducer'
import documentationReducer from 'containers/Documentation/Notion/reducer'
import interviewReducer from 'containers/Interview/reducer'
import candidateActionsReducer from 'store/candidateActions/reducer';
import featuresReducer from 'store/features/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    route: routeReducer,
    global: appReducer,
    subsidiaries: subsidiaryReducer,
    companies: companyReducer,
    platforms: platformReducer,
    platformListings: platformListingReducer,
    listings: listingReducer,
    flows: flowReducer,
    language: languageProviderReducer,
    suggestions: suggestionsReducer,
    stepViews: stepViewsReducer,
    candidates: candidateReducer,
    emiAnalytics: emiAnalyticsReducer,
    session: sessionReducer,
    users: userReducer,
    integrations: integrationsReducer,
    pendingActions: pendingActionsReducer,
    documentation: documentationReducer,
    interview: interviewReducer,
    candidateBag: candidateBag,
    candidateActions: candidateActionsReducer,
    features: featuresReducer,
    ...asyncReducers,
  });
}
