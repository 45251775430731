/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOADING,
  CLEAR_MESSAGE,
  SET_MESSAGE,
  GET_BOARD_LISTINGS_SUCCESS,
  GET_BOARD_LISTINGS_ERROR,
  LOAD_PLATFORMS_SUCCESS,
  LOAD_PLATFORMS_ERROR,
  LOAD_USER_PLATFORMS_SUCCESS,
  LOAD_USER_PLATFORMS_ERROR,
  RESET_PLATFORM_ADD,
  PLATFORM_ADD,
  PLATFORM_ADD_SUCCESS,
  PLATFORM_ADD_ERROR,
  PLATFORM_REMOVE_SUCCESS,
  PLATFORM_REMOVE_ERROR,
  JOB_LINK_SUCCESS,
  JOB_LINK_ERROR,
  JOB_READ_SUCCESS,
  JOB_READ_ERROR,
  CLEAR_CREDENTIALS_MESSAGE
} from './constants';

const initialState = fromJS({
  userPlatforms: [],
  platforms: [],
  boardListings:[],
  message: '',
  waitingServer: false,
});

function platformPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOARD_LISTINGS_SUCCESS: {
      console.log('GET_BOARD_LISTINGS_SUCCESS', action.boardListings)
      const boardListings = Object.assign([], action.boardListings);
      const positions = Object.assign([], action.positions);

      return state.set('boardListings', boardListings)
      .set('positions', positions)
      .set('waitingServer', false)
      .set('message', '');
    }
    case GET_BOARD_LISTINGS_ERROR: {
      console.log("GET_BOARD_LISTINGS_ERROR", action.error)

      return state.set('waitingServer', false)
      .set('message', 'server.error')
      .set('boardListings', []);
    }
    case LOAD_USER_PLATFORMS_SUCCESS: {
      console.log('LOAD_USER_PLATFORMS_SUCCESS', action.platforms)
      const userPlatforms = Object.assign([], action.platforms);

      return state.set('userPlatforms', userPlatforms)
      .set('message', '');
    }
    case LOAD_USER_PLATFORMS_ERROR: {
      console.log("LOAD_USER_PLATFORMS_ERROR", action.error)

      return state;
    }
    case PLATFORM_REMOVE_SUCCESS: {
      console.log('PLATFORM_REMOVE_SUCCESS', action.platform)
      let originalUserPlatforms = Object.assign([], state.get('userPlatforms'));
      const userPlatforms = originalUserPlatforms.filter(platform => platform.id != action.platform.id)

      return state.set('userPlatforms', userPlatforms);
    }
    case PLATFORM_REMOVE_ERROR: {
      console.log("PLATFORM_REMOVE_ERROR", action.error)

      return state;
    }
    case LOAD_PLATFORMS_SUCCESS: {
      console.log('LOAD_PLATFORMS_SUCCESS', action.platforms)
      const platforms = Object.assign([], action.platforms);

      return state.set('platforms', platforms);
    }
    case LOAD_PLATFORMS_ERROR: {
      console.log("LOAD_PLATFORMS_ERROR", action.error)

      return state;
    }
    case CLEAR_CREDENTIALS_MESSAGE:
    {
      return state.set('credentialsErrorMessage', '');
    }
    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case PLATFORM_ADD_SUCCESS:
    {
      const userPlatforms = Object.assign([], state.get('userPlatforms'));
      if (action.platform && action.platform[0]){
        userPlatforms.unshift(action.platform[0]);
      }
      console.log("PLATFORM_ADD_SUCCESS", action.platform)
      return state.set('userPlatforms', userPlatforms)
      .set('platformAdded', true);
    }
    case RESET_PLATFORM_ADD:
    {
      return state.set('platformAdded', false).
      set('loading', false);
    }
    case PLATFORM_ADD_ERROR:
    {
      return state.set('credentialsErrorMessage', 'error.login').
      set("loading", false);
    }
    case CLEAR_MESSAGE:
    {
      console.log('platform reducer CLEAR_MESSAGE', state.get('message'))
      return state.set('message', '');
    }
    case SET_MESSAGE:
    {
      return state.set('message', action.message);
    }
    default:
      return state;
  }
}

export default platformPageReducer;
