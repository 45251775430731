import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { getCandidate, getViewConfig } from '../../../utils/connector'
import CircularProgress from '@material-ui/core/CircularProgress'
import CandidateDrawer from '../../../components/Candidate/View'
import CandidateView from '../../../containers/Candidate/Show'
import { connect } from 'react-redux'
import { hideResume } from '../../../store/candidateActions/actions'
import moment from 'moment'
import messages from '../../Emi/messages'

// TODO: this should not be here.
// Copy&paste from: app/components/StepView/Table/Descartes/index.js#formatTimestamps
const formatTimestamp = (stringDate, format, defaultFn) => { 
  try {
    const timezoneOffset = new Date().getTimezoneOffset()
    const momentValue = moment(new Date(stringDate)).add(timezoneOffset * -1, 'minutes')
    return momentValue.format(format)
  } catch (e) {
    return defaultFn(format)
  }  
};

const CandidateCV = injectIntl(({ show, candidateId, listingId, stepId, onClose, intl: { formatMessage } }) => {
  const [candidate, setCandidate] = useState(null)
  const [fields, setFields] = useState(null)

  useEffect(() => {
    let isCancelled = false;
    const fetchViewConfig = async () => {
      let fields = null
      if (listingId && stepId) {
        const config = await getViewConfig(listingId, stepId)
        fields = config && config.length > 0 && config[0].results && Array.isArray(config[0].results)
          ? config[0].results
          : []
      }
      if (!isCancelled) {
        setFields(fields)
      }
    }
    fetchViewConfig()
    return () => {
      isCancelled = true;
    }
  }, [listingId, stepId])

  useEffect(() => {
    let isCancelled = false;
    const fetchCandidate = async () => {
      let candidate = null
      if (candidateId && listingId) {
        const result = await getCandidate(candidateId, listingId)
        candidate = result.paging.total === 1
          ? result.results[0]
          : null
        if (candidate.applied_at) {
          candidate.applied_at = formatTimestamp(candidate.applied_at, formatMessage(messages.format.datetime), (format) => new Date(Date.parse(candidate.applied_at + ' utc')).toString())
        }
      }
      if (!isCancelled) {
        setCandidate(candidate)
      }
    }
    fetchCandidate()
    return () => {
      isCancelled = true;
    }
  }, [candidateId, listingId])

  const content = show && (!candidate || !fields)
    ? <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh" }}>
        <CircularProgress />
      </div>
    : <CandidateView
        candidate={candidate}
        fields={fields}
      />

  return (
    <CandidateDrawer onHideCV={onClose} show={show}>
      { content }
    </CandidateDrawer>
  )
})

const ResumeAction = ({ store: { show, data }, close }) => {
  const { candidateId, listingId, stepId } = data || {}
  return <CandidateCV
    show={show}
    onClose={close}
    candidateId={candidateId}
    listingId={listingId}
    stepId={stepId}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').resume
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideResume())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeAction)
