import { getData } from '../containers/App/localStorageManager';

const useUser = () => getData('user');

const useUserOrEmpty = () => useUser() || {}

const useLocale = () => {
  const { locale } = useUserOrEmpty()
  return { locale, isEnglish: locale === "en-US"}
}

export { useUser, useUserOrEmpty, useLocale };
