import React from 'react';

export default () => (
  // Trying to simulate general BO loading spinner
  // Ref: app/index.html
  <div className="spinner-container">
    <div className="spinner">
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  </div>
);

