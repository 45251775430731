/*
 *
 * PlatformPage actions
 *
 */

import {
  CONTACT_CANDIDATE,
  CONTACT_CANDIDATE_SUCCESS,
  CONTACT_CANDIDATE_ERROR,
  LOADING,
  CLEAR_MESSAGE,
} from './constants';



export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}
export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function contactCandidate(payload) {
  console.log('action CONTACT_CANDIDATE')
  return {
    type: CONTACT_CANDIDATE, payload
  };
}

export function contactCandidateSuccess() {
  return {
    type: CONTACT_CANDIDATE_SUCCESS

  };
}

export function contactCandidateError(error) {
  return {
    type: CONTACT_CANDIDATE_ERROR,
    error
  };
}
