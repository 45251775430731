/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { call, put, take, fork } from 'redux-saga/effects';
import {
  SEARCH_CANDIDATE
} from './constants';

import {
  searchCandidateSuccess,
  searchCandidateError,
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

const takeLeading = (patternOrChannel, saga, ...args) => fork(function*() {
  while (true) {
    const action = yield take(patternOrChannel);
    yield call(saga, ...args.concat(action));
  }
})

export function* sSearchCandidate(action) {
  const requestURL = new URL(`${root}/listings/candidates/search/?offset=${action.offset}&limit=${action.limit}`);
  for (let key in action.filters) {
    if (action.filters[key]) {
      if (key != 'listingId') {
        requestURL.searchParams.append(key + "_contains", action.filters[key])
      } else {
        requestURL.searchParams.append(key, action.filters[key])
      }

    }
  }

  console.log('in sagas SEARCH_CANDIDATE', action, requestURL.href)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    yield put(searchCandidateSuccess(result));
  } catch (err) {
    console.error('in view data error')
    yield put(searchCandidateError(err));
  }
}

export function* apiSearchCandidate() {
  const watcher = yield takeLeading(SEARCH_CANDIDATE, sSearchCandidate);
}

// All sagas to be loaded
export default [
  apiSearchCandidate
];
