import React, { useEffect } from 'react';
import { ProductFruits } from 'react-product-fruits';
import useProductFruits from './useProductFruits';

export const ProductFruitsWrapper = ({ user }) => {
  const { info, fetchInfo } = useProductFruits()

  useEffect(() => {
    if (user && user.id && !info) {
      fetchInfo()
    }
  }, [user, info])

  if (!info) {
    return null
  }
  const { workspaceCode, language, userInfo } = info
  return <ProductFruits workspaceCode={workspaceCode} language={language} user={userInfo} debug />;
};
