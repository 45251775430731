import { createSelector } from 'reselect';
import { get } from 'lodash'
/**
 * The global state selectors
 */

const selectSession = () => (state) => state.get('session');

export const makeSelectSession = () => createSelector(
  selectSession(),
  (session) => get(session, 'session')
);

export const makeSelectCognitoUser = () => createSelector(
  selectSession(),
  (session) => get(session, 'cognitoUser')
);

export const makeSelectIsAuthenticated = () => createSelector(
  selectSession(),
  (session) => get(session, 'session') !== null
);
