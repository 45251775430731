import React from "react";
import { styled } from "@mui/material/styles";

export default styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  marginLeft: 0,
  width: "100vw",
  [theme.breakpoints.up("sm")]: {
    marginLeft: `calc(${theme.spacing(8)} + 1px)`,
    width: `calc(100vh - ${theme.spacing(8)} - 1px)`,
  },
  marginTop: theme.custom.navigation.appBar.height,
  height: `calc(100vh - ${theme.custom.navigation.appBar.height})`,
  overflowY: 'scroll'
}));
