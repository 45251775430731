/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOADING,
  GET_SUBSIDIARIES_SUCCESS,
  GET_SUBSIDIARIES_ERROR,
  GET_SUBSIDIARY_LOCATIONS,
  GET_SUBSIDIARY_LOCATIONS_SUCCESS,
  GET_SUBSIDIARY_LOCATIONS_ERROR,
  CREATE_SUBSIDIARY_SUCCESS,
  CREATE_SUBSIDIARY_ERROR,
  REMOVE_SUBSIDIARY_SUCCESS,
  REMOVE_SUBSIDIARY_ERROR,
  EDIT_SUBSIDIARY_SUCCESS,
  EDIT_SUBSIDIARY_ERROR,
  CLEAR_MESSAGE,
} from './constants';

const initialState = fromJS({
  subsidiaries: [],
  currentSubsidiaryLocations: null,
  loading: false,
  message: '',
});

function subsidiaryPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSIDIARIES_SUCCESS: {
      console.log('GET_SUBSIDIARIES_SUCCESS', action.subsidiaries)
      const subsidiaries = Object.assign([], action.subsidiaries);

      return state.set('subsidiaries', subsidiaries);
    }
    case GET_SUBSIDIARY_LOCATIONS_SUCCESS: {
      console.log('✅ SUBSIDIARY_SUCCESS', action.locations)
      return state.set('currentSubsidiaryLocations', action.locations);
    }
    case GET_SUBSIDIARIES_ERROR: {
      console.log("GET_SUBSIDIARIES_ERROR", action.error)

      return state;
    }
    case REMOVE_SUBSIDIARY_SUCCESS:{
      console.log('REMOVE_SUBSIDIARY_SUCCESS', action.flow)
      let originalProfiles = Object.assign([], state.get('flows'));
      const flows = originalProfiles.filter(flow => flow.id != action.flow.id)

      return state.set('flows', flows);
    }
    case REMOVE_SUBSIDIARY_ERROR: {
      console.log("REMOVE_SUBSIDIARY_ERROR", action.error)

      return state;
    }
    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case CREATE_SUBSIDIARY_SUCCESS:
    {
      const subsidiaries = Object.assign([], state.get('subsidiaries'));
      if (action.subsidiary){
        subsidiaries.unshift(action.subsidiary);
      }
      console.log("CREATE_SUBSIDIARY_SUCCESS", action.subsidiary, subsidiaries)
      return state.set('subsidiaries', subsidiaries)
      .set('subsidiaryAdded', true);
    }

    case CREATE_SUBSIDIARY_ERROR: {
      console.log("CREATE_SUBSIDIARY_ERROR", action.error)

      return state;
    }

    case EDIT_SUBSIDIARY_SUCCESS:{
      console.log('EDIT_SUBSIDIARY_SUCCESS', action.subsidiary)
      const subsidiaries = Object.assign([], state.get('subsidiaries'));
      const subsidiaryIndex = subsidiaries.findIndex(x => x.id == action.subsidiary.id);
      subsidiaries[subsidiaryIndex] = action.subsidiary;
      return state.set('subsidiaries', subsidiaries);
    }
    case EDIT_SUBSIDIARY_ERROR: {
      console.log("EDIT_SUBSIDIARY_ERROR", action.error)

      return state;
    }


    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    default:
      return state;
  }
}

export default subsidiaryPageReducer;
