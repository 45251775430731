/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOADING,
  CLEAR_MESSAGE,
  CONTACT_CANDIDATE_ERROR,
  CONTACT_CANDIDATE_SUCCESS,
} from '../CandidateBag/constants'



const initialState = fromJS({
  usersMap: {},
  candidateBag: [],
  loading: false,
  message: '',
});


function candidateBagPageReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    case CONTACT_CANDIDATE_ERROR:{
      console.log(action.error)
      return state.set('errorMessage', '');
    }
    default:
      return state;
  }
}

export default candidateBagPageReducer;
