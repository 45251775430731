const request = require('./request')
const config = require('../config')
const snakeCaseKeys = require('snakecase-keys')
const root = config.params.API_SERVER
const moment = require('moment');

const isNullOrUndefined = value => value === null || value === undefined

const getGroupsBySubsidiaryId = async (subsidiaryId) => {
  try {
    const result = await request.default(`${root}/groups/subsidiary/${subsidiaryId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.debug('Get groups by subsidiary id', subsidiaryId, result)
    return result
  } catch (e) {
    console.error('Get groups by subsidiary id error', subsidiaryId, e)
    return []
  }
}

const getGroupsByUserId = async (userId) => {
  try {
    const result = await request.default(`${root}/groups/user/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.debug('Get groups by user id', userId, result)
    return result
  } catch (e) {
    console.error('Get groups by user id error', userId, e)
    return []
  }
}

const getSubsidiaryLocationById = async (locationId) => {
  try {
    const result = await request.default(`${root}/subsidiaryLocations/${locationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return result
  } catch (e) {
    return null
  }

}

const getUserTagsBySubsidiary = async (subsidiaryId) => {
  try {
    const result = await request.default(`${root}/subsidiaries/${subsidiaryId}/tags/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.debug('Get user tags by subsidiary id', subsidiaryId, result)
    return result
  } catch (e) {
    console.error('Get user tags by subsidiary id', subsidiaryId, e)
    return []
  }

}

const getUserTagsValuesBySubsidiary = async (subsidiaryId) => {
  try {
    const result = await request.default(`${root}/subsidiaries/${subsidiaryId}/tags/users/values`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.debug('Get user tags values by subsidiary id', subsidiaryId, result)
    return result
  } catch (e) {
    console.error('Get user tags values by subsidiary id', subsidiaryId, e)
    return []
  }
}

const getUserTagsValuesBySubsidiaryAndUser = async (subsidiaryId, userId) => {
  try {
    const result = await request.default(`${root}/subsidiaries/${subsidiaryId}/tags/users/${userId}/values`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.debug('Get user tags values by subsidiary id and user id', subsidiaryId, userId, result)
    return result
  } catch (e) {
    console.error('Get user tags values by subsidiary id and user id', subsidiaryId, userId, e)
    return []
  }
}

const getViewConfig = async (listingId, stepId) => {
  try {
    const result = await request.default(`${root}/views/config?listingId=${listingId}&stepId=${stepId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.debug('Get view config by listing id and step id', listingId, stepId, result)
    return result
  } catch (e) {
    console.error('Get view config by listing id and step id', listingId, stepId, e)
    return []
  }
}

const getCandidate = async (candidateId, listingId) => {
  try {
    const result = await request.default(`${root}/views/data?listing_id=${listingId}&filter=candidate_id_in=${candidateId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.debug('Get candidate by candidate id and listing  id', candidateId, listingId, result)
    return result
  } catch (e) {
    console.error('Get candidate by candidate id and listing id', candidateId, listingId, e)
    return []
  }
}

const getActiveInterview = async (candidateId, stepId) => {
  try {
    const result = await request.default(`${root}/listings/candidates/search/assignment?candidateId=${candidateId}&stepId=${stepId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.info('Get active interview by candidate id', candidateId, result)
    return result
  } catch (e) {
    console.error('Something failed while retrieving active interview for candidate', candidateId, e)
    return null
  }
}

const cancelInterview = async (assignmentId) => {
  try {
    const result = await request.default(`${root}/interviews/slots/cancel/candidate?assignmentId=${assignmentId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return result
  } catch (e) {
    return []
  }
}

const getSlot = async (slotId) => {
  try {
    const result = await request.default(`${root}/interviews/slots/${slotId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return result
  } catch (e) {
    return null
  }
}

const moveCandidateToHire = async (data) => {
  try {
    const result = await request.default(`${root}/candidates/hire_candidate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return result
  } catch (e) {
  }
}

const setQuestionIndex = async (candidateId, listingId, stepId, questionIndex, value) => {
  return await request.default(`${root}/users/answer`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      candidateId,
      listingId,
      stepId,
      questionIndex,
      value: isNullOrUndefined(value) ? null : typeof(value) !== 'object' ? `${value}` : value
    })
  });
}

const updateAssignedVacancy = async (candidateId, listingId, vacancyId, augmentedData = false) => {
  return await request.default(`${root}/candidates/${candidateId}/vacancy`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ listingId, vacancyId, augmentedData })
  })
}

const updateVacancy = async (vacancyId, vacancy) => {
  return await request.default(`${root}/vacancies/${vacancyId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vacancy)
  })
}

const approveCandidate = async (candidateId, listingId, stepId) => {
  return await request.default(`${root}/stateMachine/candidate/nextStep`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      approvedIds: [candidateId],
      listingId: listingId,
      stepId: stepId,
    })
  });
}

const changeSlotState = async (slotId, body) => {
  return await request.default(`${root}/interviews/slots/${slotId}/state`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

const approveCandidateBulk = async (candidateIds, listingId, stepId) => {
  return await request.default(`${root}/stateMachine/candidate/nextStep/bulk`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      approvedIds: candidateIds,
      listingId: listingId,
      stepId: stepId,
    })
  });
}

const approveCandidateWithLocation = async (candidateId, listingId, stepId, locationId) => {
  await setQuestionIndex(candidateId, listingId, stepId, 'assigned_working_place_id', locationId)
  await approveCandidate(candidateId, listingId, stepId)
}

const moveCandidateToNextStep = async (candidateId, body) => {
  return await request.default(`${root}/candidates/${candidateId}/push`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

const moveCandidateAtInterviewToNextStep = async (candidateId, listingId, stepId, locationId, data) => {
  return await moveCandidateToNextStep(candidateId, { stepId, listingId, locationId, data })
}

const getPositionsByListingId = async (listingId) => {
  return await request.default(`${root}/listings/${listingId}/positions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const getPositionByListingId = async (listingId, positionId) => {
  return await request.default(`${root}/listings/${listingId}/positions/${positionId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const getPositionsBySubsidiaryId = async (subsidiaryId) => {
  return await request.default(`${root}/subsidiaries/${subsidiaryId}/listings/positions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const getBrandsPositionsBySubsidiaryId = async (subsidiaryId) => {
  return await request.default(`${root}/subsidiaries/${subsidiaryId}/brands/positions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const customButtonRequest = async (path, candidateId, listingId, channelUserId) => {

  await request.default(`${root}/${path}?candidate_id=${candidateId}&listing_id=${listingId}&channel_user_id=${channelUserId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });

}

const rejectCandidate = async (candidateId, listingId, stepId, rejectionReasons) => {
  const body = {
    candidateId,
    listingId,
    stepId,
    rejectionReasons
  }

  return await request.default(`${root}/candidates/reject`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

const rejectCandidateBulk = async (candidates, listingId, stepId, rejectionReasons) => {
  const body = {
    candidates,
    listingId,
    stepId,
    rejectionReasons
  }

  return await request.default(`${root}/candidates/reject/bulk`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

const postAnalogCandidate = async (listingId, data) => {
  const result = await request.default(`${root}/candidates/analog?listing_id=${listingId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return result;
};

const postManuallyAddedCandidate = async (listingId, stepId, data) => {
  const result = await request.default(`${root}/candidates/manually_add_candidate?listing_id=${listingId}&step_id=${stepId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return result;
}

const getOutreaches = async (subsidiaryId) => {
  const result = await request.default(`${root}/outreaches?subsidiaryId=${subsidiaryId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });
  return result;
};

const postOutreaches = async (subsidiaryId, data) => {
  const result = await request.default(`${root}/outreaches?subsidiaryId=${subsidiaryId}`, {
    method: 'POST',
    body: data
  });
  return result
}

const standByCandidate = async (candidateId, listingId, stepId, standByReason) => {
  const body = {
    candidateId,
    listingId,
    stepId,
    standByReason
  }

  return await request.default(`${root}/candidates/stand_by`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

const standByCandidateBulk = async (candidates, listingId, stepId, standByReason) => {
  const body = {
    candidates,
    listingId,
    stepId,
    standByReason
  }

  return await request.default(`${root}/candidates/stand_by/bulk`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}

const getVacancies = async (offset, limit, filters, sorting) => {
  let url = `${root}/vacancies/search?offset=${offset}&limit=${limit}`

  let filter = [];
  for (let key in filters) {
    if (filters[key].value) {
      filter.push(`${key}_${filters[key].type}=${filters[key].value}`);
    }
  }
  filter = filter.join(',');

  if (filter) url += `&filter=${filter}`

  if (sorting) url += `&sort=${sorting}`

  return await request.default(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const getVacanciesPositions = async (determinant, department) => {
  return await request.default(`${root}/vacancies/jobs?determinant=${determinant}&department=${department}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const postVacancy = async (vacancy) => {
  return await request.default(`${root}/vacancies`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vacancy)
  });
}

const postVacancyConfirmationAction = async (type, { id }, bodyData) => {
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  if(bodyData) options.body = JSON.stringify(bodyData)
  return await request.default(`${root}/vacancies/${id}/${type}`, options)
}

const approveVacancy = async (flowId) => {
  return await request.default(`${root}/vacancies/approve`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ flowId })
  });
}

const rejectVacancy = async (flowId, reason) => {
  return await request.default(`${root}/vacancies/reject`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ flowId, reason })
  })
}

const getVacancyRecruiters = async (subsidiaryId) => {
  return await request.default(`${root}/vacancies/recruiters?subsidiary_id=${subsidiaryId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const updateVacancyRecruiter = async (vacancyId, recruiterUserId) => {
  return await request.default(`${root}/vacancies/${vacancyId}/recruiter`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ recruiterUserId })
  })
}

const getCandidates = async (params) => {
  /**
   * params structure:
   * {
   *    param1: value1,
   *    param2: value2,
   *    ...,
   *    paramN: valueN
   * }
   */
  const urlParams = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
  return await request.default(
    `${root}/listings/candidates/search?${urlParams}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const getVacancy = async (vacancyId) => {
  return await request.default(`${root}/vacancies/${vacancyId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const getListingByVacancyId = async (vacancyId) => {
  return await request.default(`${root}/vacancies/${vacancyId}/listing`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const getPositionsFilters = async () => {
  return await request.default(`${root}/vacancies/jobs/filters`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const updateCandidateData = async (candidateId, listingId, stepId, candidateData) => {
  console.log('#### updateCanddiateData', { candidateId, listingId, stepId, candidateData })
  for (const [key, value] of Object.entries(candidateData)) {
    await setQuestionIndex(candidateId, listingId, stepId, key, value)
  }
}

const getCurrentUser = async () => {
  return await request.default(`${root}/users/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const logError = async (event) => {
  await request.default(`${root}/error`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(event)
  });
}

const logExternalTrack = async (event) => {
  await request.default(`${root}/external-track?source=${event.source}&type=${event.type}&userId=11322`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });
}

const changeCandidateState = async (data) => {
  await request.default(`${root}/candidates/actions/change_state`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

const getOpenVacancies = async (subsidiaryId, listingId) => {
  const response = await request.default(`${root}/vacancies?subsidiaryId=${subsidiaryId}&listingId=${listingId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return response && response.results && response.results.length ? response.results : []
}

const getVacanciesfilteredByText = async (subsidiaryId, listingId, parcial) => {
  const requestURL = `${root}/vacancies?subsidiaryId=${subsidiaryId}&listingId=${listingId}&search=${parcial}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const response = await request.default(requestURL, options);
  return response && response.results;
}

const postCandidateFiles = async (candidateId, listingId, files) => {
  const formData = new FormData();
  files.forEach(({ name, file }) => {
    if (file) {
      formData.append('file', file);
      formData.append(name, file.name);
    } else {
      formData.append(name, '');
    }
  });
  const result = await request.default(
    `${root}/candidate-files?candidate_id=${candidateId}&listing_id=${listingId}`,
    {
      method: 'POST',
      body: formData,
    },
  );
  return result;
}

const getCandidateFile = async (candidateId, listingId, key) => {
  return await request.default(
    `${root}/candidate-files?candidate_id=${candidateId}&listing_id=${listingId}&question_index=${key}`,
    {
      method: 'GET'
    },
  );
}

const getCandidateFilesBulk = async (candidateId, listingId, questionIndex) => {
  return await request.default(
    `${root}/candidate-files?candidate_id=${candidateId}&listing_id=${listingId}&${questionIndex}`,
    {
      method: 'GET'
    },
  );
}

const validateCandidateInStep = async (candidateId, listingId, stepId) => {
  const requestURL = `${root}/candidates/validate?candidateId=${candidateId}&listingId=${listingId}&stepId=${stepId}`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  return await request.default(requestURL, options);
}

const getInterviewActiveSlots = async (candidateId, listingId) => {
  return await request.default(
    `${root}/candidate/active-slots?candidate_id=${candidateId}&listing_id=${listingId}`,
    {
      method: 'GET'
    },
  );
}

const getLinkCode = async (listingId, eref2) => {
  return await request.default(`${root}/listings/${listingId}/link-code`,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ eref2 })
    },
  );
}

const updatePlatforms = async (posting) => {
  return await request.default(`${root}/jobPostings/${posting.id}/update`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(posting)
  });
};

const getFeatureConfiguration = async (featureName, params) => {
  const urlParams = new URLSearchParams(snakeCaseKeys(params))
  return await request.default(
    `${root}/features/${featureName}/instance?${urlParams}`,
    {
      method: 'GET',
    },
  );
};

const getCandidateByCode = async (code, listingId) => {
  return await request.default(
    `${root}/form/interview/candidates/${code}?listing_id=${listingId}`,
    {
      method: 'GET',
    })
}

const getCandidateByCodePublic = async (code, listingId) => {
  return await request.default(
    `${root}/public/form/interview/candidates/${code}?listing_id=${listingId}`,
    {
      method: 'GET',
    })
}

const postBuildLink = async (form) => {
  return await request.default(`${root}/recruitment-links/build`,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ form })
    },
  );
}

const submitFormInterviewFeedback = async (feedback, slotId) => {
  return await request.default(`${root}/form/interview/feedback${slotId ? `?slotId=${slotId}` : ''}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(feedback)
  });
}

const submitFormInterviewFeedbackPublic = async (feedback) => {
  return await request.default(`${root}/public/form/interview/feedback`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(feedback)
  });
}

const submitFormInterviewFeedbackFile = async (candidateId, fileKey, file) => {
  return await request.default(`${root}/form/interview/feedback/file?candidateId=${candidateId}&fileKey=${fileKey}`, {
    method: 'POST',
    body: file
  });
}

const submitFormInterviewFeedbackFilePublic = async (candidateId, fileKey, file) => {
  return await request.default(`${root}/public/form/interview/feedback/file?candidateId=${candidateId}&fileKey=${fileKey}`, {
    method: 'POST',
    body: file
  });
}

const submitCandidateFromForm = async (featureName, listingId, stepId, userId, data) => {
  return await request.default(
    `${root}/candidates/add_from_private_form`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      listingId,
      stepId,
      form: featureName,
      data
    })
  }
  )
}

const submitCandidateFromPublicForm = async (data, refCode) => {
  return await request.default(
    `${root}/candidates/add_from_public_form`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      refCode,
      data
    })
  }
  )
}

const getPublicFormConfig = async (refCode) => {
  return await request.default(
    `${root}/add_from_public_form/config?refCode=${refCode}`, {
    method: 'GET'
  }
  )
}

const getRequest = async (url, queryStringParams = null) => {
  const queryString = queryStringParams != null
    ? `?${new URLSearchParams(queryStringParams).toString()}`
    : ''
  return await request.default(
    `${root}${url}${queryString}`,
    {
      method: 'GET'
    }
  )
}

const applyCandidateTransformations = async ({ candidateId, listingId, transformations }) => {
  return await request.default(`${root}/candidates/actions/apply_transformations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ candidateId, listingId, transformations })
  })
}

const getInterviews = async ({ listingId, locationId }) => {
  return await request.default(`${root}/interviews/candidates?listingId=${listingId}&today=true&locationId=${locationId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
}

const getLastInterviews = async ({ listingId, locationId }) => {
    const date_from = moment().subtract(2, 'days').format('YYYY-MM-DD')
    const date_to = moment().subtract(1, 'days').format('YYYY-MM-DD')
  return await request.default(`${root}/interviews/candidates?listingId=${listingId}&date_from=${date_from}&date_to=${date_to}&locationId=${locationId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
}

const getLinks = async (params) => {
  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== null) queryParams.set(key, value);
  }

  const response = await request.default(
    `${root}/recruitment-links?${queryParams.toString()}`, {
    method: 'GET'
  });

  return response;
}

const getRejectionReasonsByListingIdAndStepId = async (listingId, stepId) => {
  try {
    const result = await request.default(`${root}/listings/reasons?listingId=${listingId ||
      ''}&stepId=${stepId || ''}&viewId=`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return result
  } catch (e) {
    return null
  }
}

const getStandByReasonsBySubsidiary = async (subsidiaryId) => {
  try {
    const result = await request.default(`${root}/subsidiaries/${subsidiaryId}/standby_reasons`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return result
  } catch (e) {
    return null
  }
}

const getConfig = async () => {
  return await request.default(`${root}/config`, {
    method: 'GET'
  })
}

const getActionsConfigByListingId = async (listingId) => {
  return await request.default(`${root}/config/actions?listing_id=${listingId}`, {
    method: 'GET'
  })
}

const getPlacesAutocompleteOptions = async (text, countryCode, sessionId) => {
  const params = new URLSearchParams()
  params.append('text', text)
  params.append('featurePage', window.location.pathname)
  if (countryCode) {
    params.append('countryCode', countryCode)
  }
  if (sessionId) {
    params.append('sessionId', sessionId)
  }
  return await request.default(`${root}/public/places/autocomplete?${params}`, {
    method: 'GET'
  })
}

const manuallyAssignCandidateToRequisition = async (candidateData, { candidateId, listingId, stepId }) => {
  try {
    const response = await request.default(`${root}/manually-assign-candidate-to-requisition`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        vacancyRef: candidateData._requisition_ref,
        candidateId,
        listingId,
        stepId,
      })
    })
    return response
  } catch (error) {
    return {
      message: `Request failed with error: ${error.message}`
    }
  }
}

const getProductFruitsInfo = async () => {
  return await request.default(`${root}/users/me/product-fruits`, {
    method: 'GET'
  })
}

const getLocationsBySubsidiaryId = async (subsidiaryId) => {
  try {
    const result = await request.default(`${root}/subsidiaries/${subsidiaryId}/locations`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return result
  } catch (e) {
    return null
  }
}

const getOxxoPrivateConfig = async () => {
  return await request.default(`${root}/config/oxxo`, {
    method: 'GET'
  })
}

const getOxxoPublicConfig = async () => {
  return await request.default(`${root}/config/oxxo/public`, {
    method: 'GET'
  })
}

export {
  getConfig,
  getActionsConfigByListingId,
  getGroupsBySubsidiaryId,
  getGroupsByUserId,
  getSubsidiaryLocationById,
  getUserTagsBySubsidiary,
  getUserTagsValuesBySubsidiary,
  getUserTagsValuesBySubsidiaryAndUser,
  getViewConfig,
  getCandidate,
  getActiveInterview,
  cancelInterview,
  moveCandidateToHire,
  approveCandidate,
  approveCandidateWithLocation,
  moveCandidateAtInterviewToNextStep,
  getSlot,
  getPositionsByListingId,
  getPositionByListingId,
  customButtonRequest,
  rejectCandidate,
  postAnalogCandidate,
  postManuallyAddedCandidate,
  getOutreaches,
  postOutreaches,
  standByCandidate,
  getVacancies,
  getVacanciesPositions,
  postVacancy,
  approveVacancy,
  rejectVacancy,
  postVacancyConfirmationAction,
  getVacancyRecruiters,
  updateVacancyRecruiter,
  getCandidates,
  getVacancy,
  getListingByVacancyId,
  getPositionsFilters,
  updateAssignedVacancy,
  updateCandidateData,
  getCurrentUser,
  logError,
  logExternalTrack,
  changeCandidateState,
  getOpenVacancies,
  getVacanciesfilteredByText,
  postCandidateFiles,
  getCandidateFile,
  getCandidateFilesBulk,
  validateCandidateInStep,
  getInterviewActiveSlots,
  getLinkCode,
  updatePlatforms,
  getFeatureConfiguration,
  getCandidateByCode,
  submitFormInterviewFeedback,
  postBuildLink,
  submitCandidateFromForm,
  submitCandidateFromPublicForm,
  getRequest,
  getPositionsBySubsidiaryId,
  submitFormInterviewFeedbackFile,
  getPublicFormConfig,
  applyCandidateTransformations,
  getInterviews,
  getLastInterviews,
  getLinks,
  getCandidateByCodePublic,
  submitFormInterviewFeedbackPublic,
  submitFormInterviewFeedbackFilePublic,
  getPlacesAutocompleteOptions,
  getRejectionReasonsByListingIdAndStepId,
  approveCandidateBulk,
  rejectCandidateBulk,
  standByCandidateBulk,
  getStandByReasonsBySubsidiary,
  changeSlotState,
  updateVacancy,
  getBrandsPositionsBySubsidiaryId,
  manuallyAssignCandidateToRequisition,
  getProductFruitsInfo,
  getLocationsBySubsidiaryId,
  getOxxoPublicConfig,
  getOxxoPrivateConfig
};
