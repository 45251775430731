/*
 *
 * StepViews constants
 *
 */

export const LOADING = 'app/Candidates/LOADING'

export const CLEAN_LOCAL_DATA = 'app/Candidates/CLEAN_LOCAL_DATA'

export const SEARCH_CANDIDATE = 'app/Candidates/SEARCH_CANDIDATE'
export const SEARCH_CANDIDATE_SUCCESS = 'app/Candidates/SEARCH_CANDIDATE_SUCCESS'
export const SEARCH_CANDIDATE_ERROR = 'app/Candidates/SEARCH_CANDIDATE_ERROR'

export const CANDIDATE_LOG = 'app/Candidates/CANDIDATE_LOG'
export const CANDIDATE_LOG_SUCCESS = 'app/Candidates/CANDIDATE_LOG_SUCCESS'
export const CANDIDATE_LOG_ERROR = 'app/Candidates/CANDIDATE_LOG_ERROR'
export const CLEAN_CANDIDATE_LOG = 'app/Candidates/CLEAN_CANDIDATE_LOG'
