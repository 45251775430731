import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { getConfig, getActionsConfigByListingId } from '../../utils/connector'
import { useUser } from "../../utils/hooks"

const ConfigContext = createContext(null)

const initialConfig = {
  actions: {},
  analytics: {},
  home: {},
  privacyPolicy: {},
}

export const ConfigProvider = ({ children }) => {
  const [state, setState] = useState(initialConfig)
  const [initialiazing, setInitializing] = useState(true)

  useEffect(() => {
    async function process() {
      getConfig()
        .then((config) => {
          setState(config)
          setInitializing(false)
        });
    }
    process()
  }, [])

  if (initialiazing) {
    return null
  }

  return <ConfigContext.Provider value={state}>
    {children}
  </ConfigContext.Provider>
}

export const ActionsConfigProvider = ({ listingId, children }) => {
  const [state, setState] = useState(initialConfig)
  const [initialiazing, setInitializing] = useState(true)
  const user = useUser()
  const isEmployee = useMemo(() => user && user.subsidiaryId && user.subsidiaryId === 1, [user])

  useEffect(() => {
    async function process() {
      getActionsConfigByListingId(listingId)
        .then((actionsConfig) => {
          setState({ ...initialConfig, actions: actionsConfig })
          setInitializing(false)
        });
    }
    if (isEmployee) {
      process()
    } else {
      setInitializing(false)
    }
  }, [])

  if (initialiazing) {
    return null
  }

  if (!isEmployee) {
    return children
  }

  return <ConfigContext.Provider value={state}>
    {children}
  </ConfigContext.Provider>
}

export const useCandidateActionsConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('ConfigProvider required')
  }

  const isStandByActionEnable = useCallback((stepId) => {
    return !!(context && context.actions && context.actions.steps && context.actions.steps[stepId] && context.actions.steps[stepId]['standBy'])
  }, [context])

  const isActionEnabled = useCallback((action, stepId, state) => {
    if (context && context.actions && context.actions.steps && context.actions.steps[stepId] && context.actions.steps[stepId][action]) {
      const { states, notStates} = context.actions.steps[stepId][action]
      if (states && Array.isArray(states) && states.includes(state)) {
        return true
      }
      if (notStates && Array.isArray(notStates) && !notStates.includes(state)) {
        return true
      }
    }
    return false
  }, [context])


  const getActionConfig = useCallback((action, stepId) => {
    if (context && context.actions && context.actions.steps && context.actions.steps[stepId] && context.actions.steps[stepId][action]) {
      return context.actions.steps[stepId][action]
    }
    return null
  }, [context])

  return { isStandByActionEnable, isActionEnabled, getActionConfig }
}

export const usePrivacyPolicyConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('ConfigProvider required')
  }
  const getPrivacyPolicyConfig = useCallback((subsidiaryId) => {
    const defaultConfig = context.privacyPolicy.default || {}
    const subsidiaryConfig = context.privacyPolicy[subsidiaryId] || {}
    // Combine default with custom settings to ensure all default fields are provided
    // This type of merge is valid because this configuration only has one level
    return {
      ...defaultConfig,
      ...subsidiaryConfig
    }
  })

  return { getPrivacyPolicyConfig }
}

export const useHomeConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('ConfigProvider required')
  }
  const getHomeConfig = useCallback((userId, subsidiaryId) => {
    const defaultConfig = context.home.subsidiariesConfig.default || {}
    const subsidiaryConfig = context.home.subsidiariesConfig[subsidiaryId] || {}
    // Combine default with custom settings to ensure all default fields are provided
    // This type of merge is valid because this configuration only has one level
    const config = {
      ...defaultConfig,
      ...subsidiaryConfig
    }

    return {
      ...config,
      show: context.home.authorizedSubsidiaries.includes(parseInt(subsidiaryId)) || context.home.authorizedUsers.includes(userId)
    }
  })

  return { getHomeConfig }
}

export const useAnalyticsConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('ConfigProvider required')
  }
  const getAnalyticsConfig = useCallback((subsidiaryId) => {
    return context.analytics[subsidiaryId] || context.analytics.default
  })
  return { getAnalyticsConfig }
}