import { fromJS } from 'immutable'
import {
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATES_ERROR,
  CLEAR_MESSAGE
} from './constants'

const initialState = fromJS({
  candidates: [],
  loading: true
})

function interviewPageReducer (state = initialState, action) {
  switch (action.type) {
    case GET_CANDIDATES_SUCCESS: {
      return state
        .set('candidates', action.payload)
        .set('loading', false)
    }
    case GET_CANDIDATES_ERROR: {
      console.log('GET_CANDIDATES_ERROR', action.payload.error)
      return state
        .set('loading', false)
        .set('errorMessage', action.payload.error)
    }
    case CLEAR_MESSAGE: {
      return state.set('errorMessage', '')
    }
    default:
      return state
  }
}

export default interviewPageReducer
