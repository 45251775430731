import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { getActiveInterview, cancelInterview } from '../../../utils/connector';
import PrimaryButton from '../../../v2/components/buttons/PrimaryButton';
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton';
import messages from '../messages'

const formatTimestamp = (stringDate, timezone, { formatMessage }) => {
  return moment.utc(stringDate).tz(timezone).format(formatMessage(messages.format.datetime));
}

const CancelInterviewDialog = ({ show, candidateId, stepId, onClose, onSucceed, intl }) => {
  const [interviewData, setInterviewData] = useState({});
  const [showError, setShowError] = useState(false);

  const { formatMessage } = intl;

  const onCancelInterview = async () => {
    await cancelInterview(interviewData.assignment_id);
    setInterviewData({});
    onSucceed();
  }

  useEffect(() => {
    async function getInterviewData() {
      if (candidateId && stepId) {
        try {
          const interviewData = await getActiveInterview(candidateId, stepId);
          if(interviewData) {
            setInterviewData(interviewData);
          } else {
            setShowError(true);
          }
        } catch (e) {
          setShowError(true);
        }
      }
    }
    getInterviewData();
  }, [candidateId, stepId]);

  return <Dialog open={show} onClose={onClose}>
    <DialogTitle>
      {formatMessage(messages.cancelInterview.title)}
    </DialogTitle>
    <DialogContent>
      <Grid container style={{ justifyContent: 'center' }}>
        {interviewData && interviewData.listing_name && [
          <Grid item xs={12} spacing={3}>
            {formatMessage(messages.cancelInterview.question)}
          </Grid>,
          <Grid item xs={12}><b>{formatMessage(messages.cancelInterview.listing)}:</b> {interviewData.listing_name}</Grid>,
          <Grid item xs={12}><b>{formatMessage(messages.cancelInterview.candidate)}:</b> {interviewData.interviewee_name}</Grid>,
          interviewData.utc_interview_date_time_str &&
          <Grid item xs={12}><b>{formatMessage(messages.cancelInterview.date)}:</b> {formatTimestamp(interviewData.utc_interview_date_time_str, interviewData.timezone, intl)}</Grid>,
          interviewData.hiring_manager_name &&
          <Grid item xs={12}><b>{formatMessage(messages.cancelInterview.hiringManager)}:</b> {interviewData.hiring_manager_name}</Grid>,
        ]}
        {!interviewData && !showError &&
          <Grid item><CircularProgress /></Grid>
        }
        {showError && <Grid item>
          <h3>{formatMessage(messages.cancelInterview.error)}</h3>
        </Grid>
        }
      </Grid>
    </DialogContent>
    {interviewData && interviewData.listing_name && <DialogActions>
      <PrimaryButton onClick={onCancelInterview}>{formatMessage(messages.cancelInterview.cancelInterview)}</PrimaryButton>
      <SecondaryButton type='text' onClick={onClose} >{formatMessage(messages.cancelInterview.doNotCancelInterview)}</SecondaryButton>
    </DialogActions>
    }
  </Dialog>;
};

export default injectIntl(CancelInterviewDialog);
