/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { DEFAULT_LOCALE } from '../app/containers/App/constants';

import esTranslationMessages from './translations/es.json';
import deTranslationMessages from './translations/de.json';

require('@formatjs/intl-pluralrules/polyfill')
require('@formatjs/intl-pluralrules/locale-data/de')
require('@formatjs/intl-pluralrules/locale-data/es')

require('@formatjs/intl-relativetimeformat/polyfill')
require('@formatjs/intl-relativetimeformat/locale-data/de')
require('@formatjs/intl-relativetimeformat/locale-data/es')

export const appLocales = [
  'es',
  'de',
];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, esTranslationMessages)
    : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  es: formatTranslationMessages('es', esTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
};
