/*
 * Messages
 *
 * This contains all the text for the copy to clipboard component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
    clipboard: {
        success: {
            defaultMessage: 'Field copied with success',
            id: "app.v2.components.CopyToClipboard.success"
        },
        error: {
            defaultMessage: 'Error copying this field',
            id: "app.v2.components.CopyToClipboard.error"
        },
    },
});