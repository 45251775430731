/*
 *
 * CompanyPage constants
 *
 */

export const LOADING = 'app/CompanyPage/LOADING'
export const CLEAR_MESSAGE = 'app/CompanyPage/CLEAR_MESSAGE'

export const GET_COMPANIES = 'app/CompanyPage/GET_COMPANIES'
export const GET_COMPANIES_SUCCESS = 'app/CompanyPage/GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_ERROR = 'app/CompanyPage/GET_COMPANIES_ERROR'
export const CREATE_COMPANY = 'app/CompanyPage/CREATE_COMPANY'
export const CREATE_COMPANY_SUCCESS = 'app/CompanyPage/CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_ERROR = 'app/CompanyPage/CREATE_COMPANY_ERROR'
export const REMOVE_COMPANY = 'app/CompanyPage/REMOVE_COMPANY'
export const REMOVE_COMPANY_SUCCESS = 'app/CompanyPage/REMOVE_COMPANY_SUCCESS'
export const REMOVE_COMPANY_ERROR = 'app/CompanyPage/REMOVE_COMPANY_ERROR'
export const EDIT_COMPANY = 'app/CompanyPage/EDIT_COMPANY'
export const EDIT_COMPANY_SUCCESS = 'app/CompanyPage/EDIT_COMPANY_SUCCESS'
export const EDIT_COMPANY_ERROR = 'app/CompanyPage/EDIT_COMPANY_ERROR'
