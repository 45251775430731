import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

export default ({ onClick }) => (
  <IconButton
    color="inherit"
    aria-label="open drawer"
    onClick={onClick}
    edge="start"
    sx={{
      marginRight: (theme) => theme.spacing(1),
    }}
  >
    <MenuIcon
      sx={{ color: (theme) => theme.custom.navigation.appBar.toggleIcon.color }}
    />
  </IconButton>
)