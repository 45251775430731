import { isNil } from "lodash"
import { getSubsidiaryLocationById } from "../../../../utils/connector"

/**
 * This dictionary holds the available custom extractors to be applied to a specific resource field.
 * An extractor is a function that receives the field definition and a resource, and returns an object containing 
 * the updated candidate's data fields. This can be an async function.
 * 
 * fieldDefinition -> holds the field definition from the form configuration file
 * 
 * resourceInstance -> holds a resource object. It's an object that contains the "id" and "name" properties, but it 
 * can optionally contain other relevant properties, too.
 */
const extractors = {
    'default': (fieldDefinition, resourceInstance) => {
        return {
            [fieldDefinition.name]: resourceInstance === null
                ? null
                : resourceInstance.id
        }
    },
    'getLocationOperation': async (fieldDefinition, resourceInstance) => {
        if (!resourceInstance) return null
        
        const response = { [fieldDefinition.name]: resourceInstance.id }
        const location = await getSubsidiaryLocationById(resourceInstance.id)
        if (!location) return response
        
        const tag = location.tags.find(tag => tag.label === 'Característica_Operación')
        return {
            ...response,
            'location_operation': tag ? tag.value : null
        }
    }
}

/**
 * 
 * @param {Object} fieldDefinition 
 * @param {Object?} fieldDefinition.extractor - contains the extractor's configs
 * @param {String} fieldDefinition.extractor.name - the name of the specific custom extractor
 * @returns {Function} - the extractor function
 */
export const buildExtractor = fieldDefinition => {
    const extractorName = isNil(fieldDefinition.extractor)
        ? 'default'
        : fieldDefinition.extractor.name
    return extractors[ extractorName ]
}