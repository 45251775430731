import React, { useState } from 'react'
import { connect } from 'react-redux'
import { candidateChanged, hideReject } from '../../../store/candidateActions/actions'
import { rejectCandidate } from '../../../utils/connector'
import RejectCandidateDialog from '../../StepView/Table/Descartes/dialog/RejectCandidateDialog'
import track from '../../../utils/track'

// We need this to handle WM Talent Pool candidates rejections. It is not the best way but it works.
// TODO: see how we can improve this
const WM_TALENT_POOL_HARD_REASONS = [
  "Aceptó otra oferta laboral",
  "Candidato \“No recontratable\”",
  "No acepta rolar turnos",
  "No tiene fit cultural",
  "No tiene interés en la empresa",
  "Candidato interno - No cumple con validación de RRHH"
]
const WM_LISTING_IDS = [1530, 1541, 1556, 1605]
const WM_TALENT_POOL_REASONS_BY_STEP = {
  '2977': [
    "Aceptó otra oferta laboral", 
    "No acepta rolar turnos", 
    "No tiene fit cultural", 
    "No tiene interés en la empresa", 
    "No tiene disponibilidad en este momento", 
    "No entregó la documentación correcta", 
    "Otro Candidato era más indicado para la vacante", 
    "No acepta la posición ofrecida", 
    "No tiene más interés en la posición"
  ]
}

const getWMReasonLabel = (reason) => `${reason} *`

const getWalmartHardReasons = (reasons, stepId) => {
  const enabledReasons = `${stepId}` in WM_TALENT_POOL_REASONS_BY_STEP
    ? WM_TALENT_POOL_REASONS_BY_STEP[stepId]
    : WM_TALENT_POOL_HARD_REASONS
  return reasons.filter(({ reason }) => enabledReasons.includes(reason))
}

const isInterviewStep = (stepType) => stepType === 'INTERVIEW'

const isCandidateInWMTalentPool = ({ stepType, state, listingId }) => {
  return WM_LISTING_IDS.includes(listingId) && isInterviewStep(stepType) && ['STARTED', 'FINISHED'].includes(state)
}

const RejectCandidateAction = ({ store: { show, loading, data }, close, notify }) => {
  const { candidateId, stepId, listingId, name, reasons, stepType, state } = data || {}
  const [processing, setProcessing] = useState(false);
  const onConfirm = async (reasons) => {
    setProcessing(true)
    try{
      await rejectCandidate(candidateId, listingId, stepId, reasons);
      track({ type: 'CANDIDATE_REJECT_MODAL_CONFIRM', candidateId: [candidateId], candidateCount: 1, listingId, stepId, state });
      notify()
    }
    catch(err){
      console.log('Something failed while rejecting a candidate', err)
    }
    setProcessing(false)
    close()
  }
  const isInterview = isInterviewStep(stepType)
  const isInWMTalenPool = isCandidateInWMTalentPool({ stepType, state, listingId }) 
  return <RejectCandidateDialog
    name={name}
    reasons={isInWMTalenPool ? getWalmartHardReasons(reasons, stepId) : reasons }
    currentReasons={[]}
    open={show}
    onClose={close}
    onConfirm={onConfirm}
    loading={loading || processing}
    oneSelectionEnabled = {isInterview}
    labelResolver={isInWMTalenPool ? getWMReasonLabel : null}
    references={isInWMTalenPool ? ["* Razón de rechazo dura"] : null}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').reject
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideReject()),
    notify: () => dispatch(candidateChanged())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectCandidateAction)
