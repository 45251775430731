import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import * as stepViewActions from '../../../containers/StepViews/actions';

import { connect } from 'react-redux';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import messages from '../messages';
import Grid from '@material-ui/core/Grid';
import { makeSelectStepViews } from '../../../containers/StepViews/selectors';
import * as listingActions from '../../../containers/Listings/actions';
import { makeSelectListingPage } from '../../../containers/Listings/selectors';
import AutoCompleteCondition from '../../../v2/components/others/AutoCompleteCondition';
import PrimaryButton from '../../../v2/components/buttons/PrimaryButton';
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton';
import { moveCandidateToHire } from '../../../utils/connector';
import DateCondition from '../../../v2/components/others/DateCondition';

const HireCandidateDialog = ({
                               show,
                               candidateId,
                               candidateName,
                               listingId,
                               channelUserId,
                               onClose,
                               onSucceed,
                               actions,
                               intl,
                               stepViewsStore,
                               listingStore,
                             }) => {

  const { formatMessage } = intl;

  const [loading, setLoading] = useState(true);

  const [interviewStep, setInterviewStep] = useState();

  const [listing, setListing] = useState();

  const [workingPlace, setWorkingPlace] = useState();
  const [jobPosition, setJobPosition] = useState();

  const [hireDate, setHireDate] = useState();

  const [workingPlaceCondition, setWorkingPlaceCondition] = useState();
  const [jobPositionCondition, setJobPositionCondition] = useState();

  useEffect(() => {
    if (listingId) {
      actions.getListing(listingId);
    }
  }, [listingId]);

  useEffect(() => {
    if (!!stepViewsStore.loading) {
      setLoading(true);
    }
  }, [stepViewsStore.loading]);

  useEffect(() => {
    const listing = listingStore.listings.find(({ id }) => id === listingId);
    if (listing) {
      setListing(listing);
      const interviewStep = Object.values(listing.steps).find(({ type }) => type === 'INTERVIEW');
      if (interviewStep) {
        setInterviewStep(interviewStep);
        if (!stepViewsStore.stepViews[listingId] || !stepViewsStore.stepViews[listingId][interviewStep.id]) {
          actions.getInterviewConfig(listingId, interviewStep.id);
        }
      } else {
        setLoading(false)
      }
    }else{
      setLoading(true)
    }
  }, [listingStore.listings]);


  useEffect(() => {
    if (interviewStep && stepViewsStore.stepViews[listingId] && stepViewsStore.stepViews[listingId][interviewStep.id]) {
      const config = stepViewsStore.stepViews[listingId][interviewStep.id];
      const conditions = config.interviewConfig.conditions.conditions;
      const workingPlaceCondition = conditions.find(({ question_key }) => question_key === 'working_place_id');
      setWorkingPlaceCondition(workingPlaceCondition);
      const jobPositionCondition = conditions.find(({ question_key }) => question_key === 'job_position_id');
      setJobPositionCondition(jobPositionCondition);
      setLoading(false)
    }
  }, [interviewStep, interviewStep && stepViewsStore.stepViews[listingId] ? stepViewsStore.stepViews[listingId][interviewStep.id] : null]);

  const onWorkingPlaceChange = (workingPlace) => {
    setWorkingPlace(workingPlace);
  };

  const handleJobPositionChange = useCallback((x) => {
    setJobPosition(x);
  });

  const onHire = async () => {
    const data = {
      channelUserId,
      candidateId,
      listingId,
      locationId: workingPlace.id,
      jobPositionId: jobPosition && jobPosition.id,
      hireDate,
    };
    setLoading(true);
    await moveCandidateToHire(data);
    onSucceed();
  };

  const ready = useMemo(() => {
    if (loading) {
      return false;
    }
    if (!interviewStep) {
      return true;
    }
    return workingPlaceCondition && workingPlaceCondition.value;
  });
  const isDisabled = useMemo(() => {
    if (!interviewStep) {
      return false;
    }
    if (!workingPlace) {
      return true;
    }
    if (jobPositionCondition && !jobPosition) {
      return true;
    }
    return false;
  }, [interviewStep, workingPlace, jobPositionCondition, jobPosition])
  return <Dialog open={show} onClose={onClose}>
    <DialogTitle>
      {formatMessage(messages.hireCandidate.title)}
    </DialogTitle>
    <DialogContent style ={{ overflow: 'hidden' }}>
      <Grid container style={{ justifyContent: 'center' }} spacing={3}>
        {!ready && <Grid item><CircularProgress /></Grid>}
        {ready && [
          <Grid item xs={12} spacing={3}>
            {formatMessage(messages.hireCandidate.question)}
          </Grid>,
          <Grid item xs={12}><b>{formatMessage(messages.cancelInterview.listing)}:</b> {listing && listing.name}</Grid>,
          <Grid item xs={12}><b>{formatMessage(messages.cancelInterview.candidate)}:</b> {candidateName}</Grid>,
          workingPlaceCondition &&
          <Grid item xs={12} spacing={3}>
            <AutoCompleteCondition
              label={workingPlaceCondition.title}
              metadata={{ options: workingPlaceCondition.value, getOptionLabel: (option) => option.value }}
              onChange={onWorkingPlaceChange}
            />
          </Grid>,
          jobPositionCondition &&
          <Grid item xs={12} spacing={3}>
            <AutoCompleteCondition
              label={jobPositionCondition.title}
              metadata={{ options: jobPositionCondition.value, getOptionLabel: (option) => option.value }}
              onChange={handleJobPositionChange}
            />
          </Grid>,

          <Grid item xs={12}>
            <DateCondition
              label={formatMessage(messages.placeholder.date)}
              onChange={setHireDate}
              value={hireDate}
              metadata={{maxValue: new Date()}}
            />
          </Grid>,
        ]}
      </Grid>
    </DialogContent>
    {ready && <DialogActions>
      <PrimaryButton disabled={isDisabled}
                     onClick={onHire}>{formatMessage(messages.hireCandidate.hire)}</PrimaryButton>
      <SecondaryButton type='text' onClick={onClose}>{formatMessage(messages.hireCandidate.cancel)}</SecondaryButton>
    </DialogActions>
    }
  </Dialog>;
};

const mapStateToProps = createStructuredSelector({
  stepViewsStore: makeSelectStepViews(),
  listingStore: makeSelectListingPage(),
});


function mapDispatchToProps(dispatch) {
  // You can use actions from another container, for example appActions, like this:
  // const actions = bindActionCreators({ ...appActions, ...apidemoActions }, dispatch);

  const actions = bindActionCreators({ ...stepViewActions, ...listingActions }, dispatch);
  return {
    actions,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HireCandidateDialog));
