import { createMuiTheme } from '@material-ui/core/styles';

const themeBlue = createMuiTheme({
  palette: {
  },
  appBar: {
    height: 57,
    color: '#2d5f8b',
  },
  drawer: {
    width: 230,
    color: '#4276a4',
  },
  raisedButton: {
    primaryColor: '#1E88E5',
  },
  tabs: {
    backgroundColor: '#2d5f8b',
    textColor: '#c0dff5',
    selectedTextColor: '#ffffff',
  },
  inkBar: {
    backgroundColor: '#d64635',
  },
});


export default themeBlue;
