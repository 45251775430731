import { useCallback } from "react"
import useFeatureContext from "./useFeatureContext"

export default () => {
  const { config, authorized } = useFeatureContext()
  const hasAccess = useCallback((permission) => authorized.includes(permission), [authorized])
  return {
    config,
    hasAccess
  }
}