import React, { createContext, useState } from 'react';

export const ActionsContext = createContext({});

export const ActionsProvider = (props) => {
  const [state, setState] = useState({});
  return (
    <ActionsContext.Provider value={{ state, setState }}>{props.children}</ActionsContext.Provider>
  );
}
