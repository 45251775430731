import React from 'react'
import { connect } from 'react-redux'
import { hideSpecialNeeds } from '../../../store/candidateActions/actions'
import SpecialNeedsDialog from '../SpecialNeedsDialog'

const SpecialNeedsAction = ({ store: { show, data, loading }, close }) => {
  const { response: { locations }, viewConfig } = data || { response: {}, viewConfig: {}}
  return <SpecialNeedsDialog
    open={show}
    loading={loading}
    locations={locations}
    viewConfig={viewConfig}
    onClose={close}
  />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').others.specialNeeds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideSpecialNeeds())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialNeedsAction)
