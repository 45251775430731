import React from 'react';
import { Button, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  offTable: theme.styles.buttons.offTable,
  ctaOffTable: theme.styles.buttons.ctaOffTable
}))

const OffTableButton = ({ cta, ...props }) => {
  const styles = useStyles()
  return <Button
    {...props}
    className={cta ? styles.ctaOffTable : styles.offTable}
  />
}

export default OffTableButton