/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  GET_PLATFORM_LISTINGS,
  GET_PLATFORM_LISTINGS_SUCCESS,
  GET_PLATFORM_LISTINGS_ERROR,
  CREATE_PLATFORM_LISTING,
  CREATE_PLATFORM_LISTING_SUCCESS,
  CREATE_PLATFORM_LISTING_ERROR,
  RESET_CREATE_PLATFORM_LISTING,
  REMOVE_PLATFORM_LISTING,
  REMOVE_PLATFORM_LISTING_SUCCESS,
  REMOVE_PLATFORM_LISTING_ERROR,
  EDIT_PLATFORM_LISTING,
  EDIT_PLATFORM_LISTING_SUCCESS,
  EDIT_PLATFORM_LISTING_ERROR,
  CLEAR_MESSAGE,
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}

export function getPlatformListings(listing) {
  console.log("GET_PLATFORM_LISTINGS")
  return {
    type: GET_PLATFORM_LISTINGS,
    listing
  };
}

export function getPlatformListingsSuccess(platformListings) {
  return {
    type: GET_PLATFORM_LISTINGS_SUCCESS,
    platformListings
  };
}

export function getPlatformListingsError(error) {
  return {
    type: GET_PLATFORM_LISTINGS_ERROR,
    error
  };
}


export function createPlatformListing(platformListing){
  console.log("CREATE_PLATFORM_LISTING")
  return {
    type: CREATE_PLATFORM_LISTING,
    platformListing
  }
}


export function createPlatformListingSuccess(platformListing){
  return {
    type: CREATE_PLATFORM_LISTING_SUCCESS,
    platformListing
  }
}


export function createPlatformListingError(error){
  return {
    type: CREATE_PLATFORM_LISTING_ERROR,
    error
  }
}

export function resetCreatePlatformListing(){
  return{
    type: RESET_CREATE_PLATFORM_LISTING
  }
}

export function removePlatformListing(platformListing){
  return {
    type: REMOVE_PLATFORM_LISTING,
    platformListing
  }
}


export function removePlatformListingSuccess(platformListing){
  return {
    type: REMOVE_PLATFORM_LISTING_SUCCESS,
    platformListing
  }
}


export function removePlatformListingError(error){
  return {
    type: REMOVE_PLATFORM_LISTING_ERROR,
    error
  }
}

export function editPlatformListing(platformListing){
  console.log("EDIT_PLATFORM_LISTING")
  return {
    type: EDIT_PLATFORM_LISTING,
    platformListing
  }
}


export function editPlatformListingSuccess(platformListing){
  return {
    type: EDIT_PLATFORM_LISTING_SUCCESS,
    platformListing
  }
}


export function editPlatformListingError(error){
  return {
    type: EDIT_PLATFORM_LISTING_ERROR,
    error
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}
