import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

import Button from '@mui/material/Button';

import underMaintenanceImg from '../../assets/under-maintenance.svg';

const StyledButton = styled(Button)`
  && {
    color: #FFFFFF;
    font-size: 14px;
    font-family: Lato;
    font-weight: 600;

    background-color: #3179FF;
    padding: 16px 24px;
    border-radius: 8px;
    text-transform: none;
  }
`

const StyledContainer = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
`;

const StyledImg = styled.img`
  height: 150px;
  margin-bottom: 16px;
`;

const StyledTitle = styled.div`
  font-family: Lato;
  font-weight: 700;
  font-size: 20px;
  color: #1049BC;
`;

const StyledSubTitle = styled.div`
  margin-top: 16px;
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  color: #000000;
`;

const StyledTextContainer = styled.div`
  width: 500px;
  text-align: center;
  margin-bottom: 32px;
`;

const StyledCard = styled.div`
  box-shadow: 0px 4px 4px rgba(29.50, 61.81, 126.44, 0.19);
  background: white;
  border-radius: 16px;
`;

const StyledCardContent = styled.div`
  width: 50vw;
  padding: 64px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const StyledCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #F8F9FF;
`;

const messages = defineMessages({
  title: {
    id: "underMaintenance.title",
    defaultMessage: "We're facing some technical issues"
  },
  subtitle1: {
    id: "underMaintenance.subtitle1",
    defaultMessage: "Our team is working hard to fix them and get everything back up soon."
  },
  subtitle2: {
    id: "underMaintenance.subtitle2",
    defaultMessage: "Need help right away? Our Support Team is here for you, ready to assist and find the best solution."
  },
  button: {
    id: "underMaintenance.button",
    defaultMessage: "Contact Support"
  },
})

export const UnderMaintenancePage = injectIntl(({ intl: { formatMessage }}) => (
  <StyledContainer>
    <StyledCardContainer>
      <StyledCard>
        <StyledCardContent>
          <StyledImg src={underMaintenanceImg} />
          <StyledTextContainer>
            <StyledTitle>{formatMessage(messages.title)}</StyledTitle>
            <StyledSubTitle>{formatMessage(messages.subtitle1)}</StyledSubTitle>
            <StyledSubTitle>{formatMessage(messages.subtitle2)}</StyledSubTitle>
          </StyledTextContainer>
          <StyledButton variant='contained' href="mailto:support@emilabs.ai">{formatMessage(messages.button)}</StyledButton>
        </StyledCardContent>
      </StyledCard>
    </StyledCardContainer>
  </StyledContainer>
));
