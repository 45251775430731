/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  LOADING,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
  REMOVE_COMPANY_SUCCESS,
  REMOVE_COMPANY_ERROR,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_ERROR,
  CLEAR_MESSAGE,
} from './constants';

const initialState = fromJS({
  companies: [],
  loading: false,
  message: '',
});

function companyPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS: {
      console.log('GET_COMPANIES_SUCCESS', action.companies)
      const companies = Object.assign([], action.companies);

      return state.set('companies', companies);
    }
    case GET_COMPANIES_ERROR: {
      console.log("GET_COMPANIES_ERROR", action.error)

      return state;
    }
    case REMOVE_COMPANY_SUCCESS:{
      console.log('REMOVE_COMPANY_SUCCESS', action.company)
      let originalCompanies = Object.assign([], state.get('companies'));
      const companies = originalCompanies.filter(company => company.id != action.company.id)

      return state.set('companies', companies);
    }
    case REMOVE_COMPANY_ERROR: {
      console.log("REMOVE_COMPANY_ERROR", action.error)

      return state;
    }
    case EDIT_COMPANY_SUCCESS:{
      console.log('EDIT_COMPANY_SUCCESS', action.company)
      const companies = Object.assign([], state.get('companies'));
      const companyIndex = companies.findIndex(x => x.id == action.company.id);
      companies[companyIndex] = action.company;
      return state.set('companies', companies);
    }
    case EDIT_COMPANY_ERROR: {
      console.log("EDIT_COMPANY_ERROR", action.error)

      return state;
    }


    case LOADING:
    {
      return state.set('loading', action.loading);
    }
    case CREATE_COMPANY_SUCCESS:
    {
      const companies = Object.assign([], state.get('companies'));
      if (action.company){
        companies.unshift(action.company);
      }
      console.log("CREATE_COMPANY_SUCCESS", action.company, companies)
      return state.set('companies', companies)
      .set('companyAdded', true);
    }
    case CREATE_COMPANY_ERROR: {
      console.log("CREATE_COMPANY_ERROR", action.error)

      return state;
    }
    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    default:
      return state;
  }
}

export default companyPageReducer;
