import { call, put, takeLatest } from 'redux-saga/effects';
import { REQUEST_FEATURE_CONFIG } from './constants';

import { requestFeatureConfigSucceed } from './actions';

import { getFeatureConfiguration } from '../../utils/connector';

function* sRequestFeatureConfig(request) {
  const { featureName, params } = request;
  try {
    const result = yield call(getFeatureConfiguration, featureName, params);
    yield put(requestFeatureConfigSucceed(request, result));
  } catch (err) {
    console.error('sRequestFeatureConfig', err);
  }
}

function* requestFeatureConfig() {
  yield takeLatest(REQUEST_FEATURE_CONFIG, sRequestFeatureConfig);
}

// All sagas to be loaded
export default [requestFeatureConfig];
