function getNetworkConnection() {
  return (
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    null
  );
}

export const getNetworkConnectionInfo = () => {
  const connection = getNetworkConnection();
  if (!connection) {
    return {};
  }
  return { 
    type: connection.type,
    effectiveType: connection.effectiveType,
    downlinkMax: connection.downlinkMax,
    downlink: connection.downlink,
    rtt: connection.rtt
  }
}
