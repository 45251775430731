/*
 *
 * PlatformListingPage constants
 *
 */

export const LOADING = 'app/PlatformListingPage/LOADING'
export const GET_PLATFORM_LISTINGS = 'app/PlatformListingPage/GET_PLATFORM_LISTINGS'
export const GET_PLATFORM_LISTINGS_SUCCESS = 'app/PlatformListingPage/GET_PLATFORM_LISTINGS_SUCCESS'
export const GET_PLATFORM_LISTINGS_ERROR = 'app/PlatformListingPage/GET_PLATFORM_LISTINGS_ERROR'

export const CREATE_PLATFORM_LISTING = 'app/PlatformListingPage/CREATE_PLATFORM_LISTING'
export const CREATE_PLATFORM_LISTING_SUCCESS = 'app/PlatformListingPage/CREATE_PLATFORM_LISTING_SUCCESS'
export const CREATE_PLATFORM_LISTING_ERROR = 'app/PlatformListingPage/CREATE_PLATFORM_LISTING_ERROR'
export const RESET_CREATE_PLATFORM_LISTING = 'app/PlatformListingPage/RESET_CREATE_PLATFORM_LISTING'


export const REMOVE_PLATFORM_LISTING = 'app/PlatformListingPage/REMOVE_PLATFORM_LISTING'
export const REMOVE_PLATFORM_LISTING_SUCCESS = 'app/PlatformListingPage/REMOVE_PLATFORM_LISTING_SUCCESS'
export const REMOVE_PLATFORM_LISTING_ERROR = 'app/PlatformListingPage/REMOVE_PLATFORM_LISTING_ERROR'

export const EDIT_PLATFORM_LISTING = 'app/PlatformListingPage/EDIT_PLATFORM_LISTING'
export const EDIT_PLATFORM_LISTING_SUCCESS = 'app/PlatformListingPage/EDIT_PLATFORM_LISTING_SUCCESS'
export const EDIT_PLATFORM_LISTING_ERROR = 'app/PlatformListingPage/EDIT_PLATFORM_LISTING_ERROR'

export const CLEAR_MESSAGE = 'app/PlatformListingPage/CLEAR_MESSAGE'
