import track from '../../../utils/track'

const actionsType = {
  approve: "CANDIDATE_APPROVE_MODAL_CONFIRM",
  detailsButton: "CANDIDATE_VIEW_DETAILS", 
  documentsTab: "CANDIDATE_DOCUMENTS_TAB", 
  downloadDocuments: "CANDIDATE_VIEW_DOWNLOAD_DOCUMENTS", 
  downloadHistory: "CANDIDATE_VIEW_DOWNLOAD_HISTORY", 
  export: "CANDIDATE_VIEW_DOWNLOAD_INFO",
  historyTab: "CANDIDATE_HISTORY_TAB",
  reject: "CANDIDATE_REJECT_MODAL_CONFIRM",
  search: "CANDIDATE_VIEW_FILTER",
  sort: "CANDIDATE_VIEW_SORT", 
  showDocument: "CANDIDATE_VIEW_DOCUMENTS",
  standBy: "CANDIDATE_VIEW_STANDBY",
  view: "CANDIDATE_VIEW_OPEN_TAB",
  actionButtonClick: "CANDIDATE_VIEW_ACTION_BUTTON_CLICK",
  openInvitation: "INVITE_CANDIDATE_TO_INTERVIEW_MODAL_OPEN",
  confirmInvitation: "INVITE_CANDIDATE_TO_INTERVIEW_MODAL_CONFIRM",
}

const trackEvent = ({ type, data }) => {
  if(type) {
    let event = { ...data }
    event.type = type
    track(event)
  }
}

const buildFilterEvent = (event) => {
  const filterTypeMap = {
    manually_assigned_job_position_id_in: "POSITION",
    position_id_in: "POSITION",
    assigned_working_place_id_in: "LOCATION",
    working_place_id_in: "LOCATION",
    step_id: "STEP",
    state: "STATE",
    emi_user_id_in: "RECRUITER",
    applied_at_min: "DATE",
    applied_at_max: "DATE",
  };

  const filterTypes = event.filters.map((filter) => filterTypeMap[filter.field]);
  const uniqueFilterTypes = [...new Set(filterTypes)];

  const filterEvent = {
    search: event.search,
    filter_type: uniqueFilterTypes.join(", "),
  };

  return filterEvent;
}

export {
  trackEvent,
  buildFilterEvent,
  actionsType
}