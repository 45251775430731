import React, { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ValidableTextField from './ValidableTextField';
import { getPlacesAutocompleteOptions } from '../../../utils/connector';

const INPUT_VALUE_MIN_LENGTH = 3
const DEBOUNCE_TIME = 500

export const AddressField = ({ state: { value: stateValue, required, disabled, validation }, definition: { label, placeholder, fontSize }, setFormData, name, countryRestrictions = ["mx"] }) => {
  const [value, setValue] = React.useState(() => {
    if (!stateValue) {
      return ''
    }
    if (typeof(stateValue) === 'string') {
      return stateValue
    }
    if (typeof(stateValue) === 'object' && !!stateValue.formatted_address) {
      return stateValue.formatted_address
    }
    return ''
  });
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const sessionId = useMemo(() => uuidv4(), [])

  useEffect(() => {
    let active = true;

    async function process() {
      const { results } = await getPlacesAutocompleteOptions(inputValue, countryRestrictions, sessionId)
      if (active) {
        setOptions(results)
      }
    }

    if (!!inputValue && inputValue.length > INPUT_VALUE_MIN_LENGTH && active) {
      process()
    } else {
      if (active) {
        setOptions([])
      }
    }

    return () => {
      active = false;
    };
  }, [inputValue]);

  const handleChange = useCallback((_, newValue) => {
    if (newValue) {
      const { value } = newValue
      setValue(value.formattedAddress);
      setFormData((formData) => ({ ...formData, [name]: value }));
    } else {
      setValue(null);
      setFormData((formData) => ({ ...formData, [name]: null }));
    }
  }, [setFormData]);

  const debouncedOnInputChange = useCallback(debounce(setInputValue, DEBOUNCE_TIME), []);

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      renderInput={props => (
        <ValidableTextField
          {...props}
          size="small"
          variant="outlined"
          required={required}
          label={label}
          validation={validation}
          disabled={disabled}
          placeholder={placeholder}
          fontSize={fontSize}
        />
      )}
      renderOption={option => {
        return (
          <li>
            <Grid container alignItems="center">
              <Grid item xs>
                {option.mainDescription}
                <Typography variant="body2" color="text.secondary">
                  {option.secondaryDescription}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        if (!!event) { // Check to prevent extra api call when option selected by user
          debouncedOnInputChange(newInputValue)
        }
      }}
      value={!disabled ? value : null}
      noOptionsText={placeholder}
      disabled={disabled}
      filterOptions={(options) => options}
    />
  );
};
