import React from 'react'
import { connect } from 'react-redux'
import { hideLog } from '../../../store/candidateActions/actions'
import Log from '../Log'
import LogV2 from '../Log/v2'

// TODO: remove this listing check one we have v2 version ready for all clients
const LogAction = ({ store: { show, loading, data }, close }) => {
  const { logs, name, channelUserId, listingId } = data || {}
  return listingId == 1593
  ? <LogV2
      show={show}
      loading={loading}
      logs={logs}
      name={name}
      channelUserId={channelUserId}
      onClose={close}
    />
  : <Log
      show={show}
      loading={loading}
      logs={logs}
      name={name}
      channelUserId={channelUserId}
      onClose={close}
    />
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').log
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideLog())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogAction)
