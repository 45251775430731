/*
 *
 * UserPage constants
 *
 */

export const LOADING = 'app/UserPage/LOADING'
export const GET_USERS = 'app/UserPage/GET_USERS'
export const GET_USERS_SUCCESS = 'app/UserPage/GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'app/UserPage/GET_USERS_ERROR'

export const CREATE_USER = 'app/UserPage/CREATE_USER'
export const CREATE_USER_SUCCESS = 'app/UserPage/CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'app/UserPage/CREATE_USER_ERROR'

export const EDIT_USER = 'app/UserPage/EDIT_USER'
export const EDIT_USER_SUCCESS = 'app/UserPage/EDIT_USER_SUCCESS'
export const EDIT_USER_ERROR = 'app/UserPage/EDIT_USER_ERROR'

export const CLEAR_MESSAGE = 'app/UserPage/CLEAR_MESSAGE'
