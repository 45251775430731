import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PrimaryButton from '../buttons/PrimaryButton';

const toolbarStyles = theme => ({
  highlight:
    theme.palette.type === 'light'
      ? {
        color: '#7A7A7A',
        backgroundColor: '#F5F5F5',
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  hiddenTitle: {
    display: 'none'
  }
});

let ToolbarApproved = props => {
  const { numSelected, numSelectedApproved, numSelectedRejected, classes, isRequiredFieldsError, messages } = props;
  const { formatMessage } = props.intl;


  return (
    <Toolbar
      className={classNames({
        [classes.highlight]: numSelected > 0,
        [classes.hiddenTitle]: numSelected == 0,
      })}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          {numSelected > 1 ? (
            <Typography color="inherit" variant="subtitle2">
              {formatMessage(messages.table.toolbar.selectedMany, { count: numSelected })}
            </Typography>
          ) : numSelected === 1 ? (
            <Typography color="inherit" variant="subtitle2">
              {formatMessage(messages.table.toolbar.selectedOne)}
            </Typography>
          ) : (
            <Typography id="tableTitle" className={classes.hiddenTitle}>
              {props.title || ''}
            </Typography>
          )}
        </Grid>
        {
          isRequiredFieldsError && (
            <Grid item>
              <Typography color="error" variant="subtitle2">
                {formatMessage(messages.table.toolbar.error.required)}
              </Typography>
            </Grid>
          )
        }
        <Grid item>
          {
            numSelected > 0 && (
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Grid
                  item
                >
                  <Grid
                    container
                    direction="column"
                  >
                    {
                      numSelectedApproved > 0 && (
                        <Grid item xs>
                          <Typography color="inherit" variant="subtitle2">
                            {numSelectedApproved} {formatMessage(messages.header.approved)}
                          </Typography>
                        </Grid>
                      )
                    }
                    {
                      numSelectedRejected > 0 && (
                        <Grid item xs>
                          <Typography color="inherit" variant="subtitle2">
                            {numSelectedRejected} {formatMessage(messages.header.rejected)}
                          </Typography>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    disabled={isRequiredFieldsError}
                    onClick={() => {
                      if (props.changeState) {
                        props.changeState()
                      }
                    }}
                  >
                    {formatMessage(messages.table.toolbar.button.confirm)}
                  </PrimaryButton>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Toolbar>
  );
};

ToolbarApproved.propTypes = {
  classes: PropTypes.object.isRequired,
  

  title: PropTypes.string,
  numSelected: PropTypes.number.isRequired,
  numSelectedApproved: PropTypes.number,
  numSelectedRejected: PropTypes.number,

  isRequiredFieldsError: PropTypes.bool,

  changeState: PropTypes.func,
  messages: PropTypes.any.isRequired
};

export default injectIntl(withStyles(toolbarStyles)(ToolbarApproved));
