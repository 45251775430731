import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import messages from '../../messages';
import { injectIntl } from 'react-intl';
import DownloadIcon from '../../../../v2/components/icons/DownloadIcon';
import {
  Image,
  PDFDownloadLink,
  Page,
  View,
  Text,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import EmiLogo from '../../../assets/images/emi_logotipo2.jpg';
import EmiIsotipo from '../../../assets/images/emi_isotipo2.jpg';
import OffTableButton from '../../../../v2/components/buttons/OffTableButton';
import { makeStyles } from '@material-ui/core/styles';

// Create PDF styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    position: 'relative',
    width: '100%',
    marginBottom: '50px',
  },
  headerImage: {
    position: 'absolute',
    right: '10px',
    top: '15px',
    maxWidth: '15%',
    height: '30px',
  },
  footerImage: {
    position: 'absolute',
    left: '10px',
    bottom: '10px',
    width: '8%',
  },
  title: {
    padding: '10px',
    fontSize: 12,
    color: 'blue',
  },
  key: {
    fontSize: 10,
    color: 'black',
    fontWeight: 'bolder'
    // fontFamily: 'Times-Bold'
  },
  value: {
    fontSize: 9,
    color: 'black',
  },
  gridContainer: {
    margin: '20px',
    marginTop: '5',
    marginBottom: '5',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderStyle: "solid",
    borderBottomColor: 'gainsboro',
    borderBottomWidth: 1,
  },
  gridItem: {
    marginBottom: '10px',
    textAlign: 'left',
    width: '98%',

  },
});

// Create custom component styles
const useStyles = makeStyles({
  downloadIcon: {
    color: '#3179FF'
  },
  downloadAnchor: {
    color: '#3179FF',
    '&:hover': {
     textDecoration: 'none'
    }
  }
})
const CandidateLogPdf = ({ title, records }) => (
  <Document>
    <Page size="A4">
      <View style={styles.header}>
        <Image style={styles.headerImage} src={EmiLogo} />
      </View>
      <View>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>
        <View style={styles.gridContainer}>
          {records.map(({ message: { text }, timestamp }, index) => (
            <Text style={styles.gridItem} key={index}>
              <Text style={styles.key}>{timestamp} </Text>{' '}
              <Text style={styles.value}>{text}</Text>
            </Text>
          ))}
        </View>
      </View>
      <View style={styles.footerImage}>
        <Image src={EmiIsotipo} />
      </View>
    </Page>
  </Document>
);

const formatTimestamp = (timestamp, { formatMessage }, timezone) => {
  const time = timezone ? moment(timestamp).tz(timezone) : moment(timestamp)
  return time.format(formatMessage(messages.format.datetime))
};

const createMessage = (text, icon) => ({ text, icon });

const getFormat = (type, { formatMessage }) => {
  const intlData = messages.log.type[type];
  if (!intlData) {
    return null;
  }
  const messageFormat = formatMessage(intlData);
  if (!messageFormat) {
    return null;
  }
  return messageFormat;
};

const getMessage = (type, metadata, intl) => {
  const messageFormat = getFormat(type, intl);
  if (!messageFormat) {
    return null;
  }
  switch (type) {
    case 'APPLICATION':
      return createMessage(
        messageFormat.replace('{{reference}}', metadata.reference),
      );
    case 'DESCARTES.STARTED':
    case 'DESCARTES.APPROVED':
    case 'DESCARTES.REJECTED':
    case 'DESCARTES.READY_FOR_NEXT_STEP.MANUAL':
    case 'INTEGRATION.STARTED':
    case 'INTEGRATION.REJECTED':
    case 'INTEGRATION.READY_FOR_NEXT_STEP':
      return createMessage(
        messageFormat.replace('{{stepName}}', metadata.step.name),
      );
    case 'DESCARTES.READY_FOR_NEXT_STEP.MANUAL_BY_USER':
    case 'MANUAL.REJECTED':
    case 'MANUAL.READY_FOR_NEXT_STEP':
      return createMessage(
        messageFormat
          .replace('{{stepName}}', metadata.step.name)
          .replace('{{email}}', metadata.user.email),
      );
    case 'INTERVIEW.APPROVED':
    case 'INTERVIEW.REJECTED': {
      if (metadata.requisitionType === 'TELEPHONE') {
        if (!metadata.user.email) {
          const telephoneMessageFormat = getFormat(`${type}_TELEPHONE_NO_EMAIL`, intl);
          return createMessage(telephoneMessageFormat);
        }
        const telephoneMessageFormat = getFormat(`${type}_TELEPHONE`, intl);
        return createMessage(telephoneMessageFormat.replace('{{email}}', metadata.user.email));
      }
      if (!metadata.timeFrom && !metadata.locationName) {
        const withoutTimeAndLocationFormat = getFormat(`${type}_WITHOUT_TIME_AND_LOCATION`, intl);
        return createMessage(withoutTimeAndLocationFormat);
      }
      if (!metadata.timeFrom) {
        const withoutTimeFormat = getFormat(`${type}_WITHOUT_TIME`, intl);
        return createMessage(withoutTimeFormat.replace('{{locationName}}', metadata.locationName));
      }
      const timeFrom = formatTimestamp(metadata.timeFrom, intl, metadata.requisitionTimezone);
      return createMessage(
        messageFormat
          .replace('{{locationName}}', metadata.locationName)
          .replace('{{timeFrom}}', timeFrom),
      );
    }
    case 'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_SLOT_DATE':
    case 'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_SLOT_DATETIME':
    case 'INTERVIEW.NO_CONFIRMATION_FROM_INTERVIEWEE':
    case 'INTERVIEW.WAITING_FOR_SLOT_CONFIRMATION': {
      const timeFrom = formatTimestamp(metadata.timeFrom, intl, metadata.requisitionTimezone);
      return createMessage(
        messageFormat
          .replace('{{locationName}}', metadata.locationName)
          .replace('{{timeFrom}}', timeFrom),
      );
    }
    case 'INTERVIEW.UNREACHABLE_AVAILABILITY':
    case 'INTERVIEW.NO_AVAILABLE_SLOT':
    case 'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_LISTING':
    case 'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_LOCATION':
    case 'INTERVIEW.SLOT_REJECTED_BY_INTERVIEWEE_POSITION':
    case 'INTERVIEW.NO_CONFIRMATION_FROM_HIRING_MANAGER':
    case 'INTERVIEW.INTERVIEW_CANCELED_BY_HIRING_MANAGER':
    case 'INTERVIEW.INTERVIEW_CANCELED_BY_INTERVIEWEE':
    case 'INTERVIEW.WAITING_FOR_SLOT_AVAILABLE':
    case 'INTERVIEW.WAITING_FOR_HM_INTERVIEW_CONFIRMATION':
    case 'INTERVIEW.WAITING_FOR_INTERVIEW':
    case 'INTERVIEW.INTERVIEW_CANCELED_FOR_INTERVIEWEE': {
      const timeFrom = formatTimestamp(metadata.timeFrom, intl, metadata.requisitionTimezone);
      return createMessage(
        messageFormat
          .replace('{{locationName}}', metadata.locationName)
          .replace('{{timeFrom}}', timeFrom),
      );
    }
    case 'INTERVIEW.ABSENT':{
      const timeFrom = formatTimestamp(metadata.timeFrom, intl, metadata.requisitionTimezone);
      return createMessage(
        messageFormat
          .replace('{{locationName}}', metadata.locationName)
          .replace('{{timeFrom}}', timeFrom),
      );
    }
    case 'INTERVIEW.WAITING_FOR_FEEDBACK':
      return createMessage(
        messageFormat.replace('{{locationName}}', metadata.locationName),
      );
    default:
      return createMessage(messageFormat);
  }
};

const CandidateLog = ({
  show,
  loading,
  logs,
  name,
  channelUserId,
  onClose,
  intl,
}) => {
  const title =
    name && channelUserId
      ? `${name} (#${channelUserId})`
      : name
      ? name
      : intl.formatMessage(messages.log.title);
  const logStyles = useStyles()
  let content = null;
  let pdf = null;
  if (loading) {
    content = (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100px',
        }}
      >
        <CircularProgress />
      </div>
    );
  } else if (!!logs) {
    const records = logs
      .map((log) => {
        const { type, metadata } = log;
        const message = getMessage(type, metadata, intl);
        return { ...log, message };
      })
      .filter((item) => !!item.message)
      .map((item) => ({
        ...item,
        timestamp: formatTimestamp(item.timestamp, intl, item.metadata.requisitionTimezone),
      }));

    content = (
      <DialogContent>
        {records.map(({ timestamp, message }, index) => {
          return (
            <React.Fragment key={index}>
              <Typography variant="body2">
                <Typography variant="caption">{timestamp}</Typography>{' '}
                {message.text} {message.icon}
              </Typography>
            </React.Fragment>
          );
        })}
      </DialogContent>
    );
    pdf = <CandidateLogPdf title={title} records={records} />;
  }

  return (
    <Dialog open={show} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Grid container justify="space-between">
          <Grid item xs={12}><Typography variant='h5'>Historial del candidato</Typography></Grid>
          <Grid item xs={6}><Typography variant='subtitle1'>Nombre del candidato</Typography></Grid>
          <Grid item xs={6}><Typography variant='subtitle1'>ID</Typography></Grid>
          <Grid item xs={6}><Typography variant='body1'>{name}</Typography></Grid>
          <Grid item xs={6}><Typography variant='subtitle1'>#{channelUserId}</Typography></Grid>
          {/* <Grid item>{title}</Grid> */}
          <Grid item xs={6}><Typography>Actividad Registrada</Typography></Grid>
          <Grid item xs={6}>
            {!loading && pdf && (
              <PDFDownloadLink
                // style={{float: 'right', 'margin': '10px', 'marginRight': '20px'}}
                document={pdf}
                fileName={`${title}.pdf`}
                className={logStyles.downloadAnchor}
              >
                {({ blob, url, loading, error }) => {
                  return loading
                    ? null
                    : <OffTableButton>Descargar Historial<DownloadIcon className={logStyles.downloadIcon}/></OffTableButton>;
                }}
              </PDFDownloadLink>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      {content}
    </Dialog>
  );
};

export default injectIntl(CandidateLog);