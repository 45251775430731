import { createMuiTheme } from '@material-ui/core/styles';
import { fade, darken } from "@material-ui/core/styles/colorManipulator";

const buttonBuilder = (color, theme) => {
  return {
    /**
    * Color styles for text (aka flat) buttons
    * NOTE: these styles are also applied to the outlined varaint.
    * @see https://github.com/mui-org/material-ui/blob/8995f085904eb55bcb5861fb6d8a32fbd38d72eb/packages/material-ui/src/Button/Button.js#L50-L59
    */
    text: {
      color: color,
      "&:hover": {
        backgroundColor: fade(color, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent"
        }
      }
    },

    /**
     * Color styles for outlined buttons.
     * Note: These styles are combined with the text button styles (.textPurple)
     * @see https://github.com/mui-org/material-ui/blob/8995f085904eb55bcb5861fb6d8a32fbd38d72eb/packages/material-ui/src/Button/Button.js#L84-L92
     */
    outlined: {
      border: `1px solid ${fade(color, 0.5)}`,
      "&:hover": {
        border: `1px solid ${color}`
      },
      // Disabled styles for outlined button...
      // NOTE: You need to pass `classes={{disabled: classes.diabled}}` to
      // the Button component for these styles to work. You also need have
      // a .disabled class in your style rules.
      "&$disabled": {
        border: `1px solid ${theme.palette.action.disabled}`
      }
    },

    /**
     * Color styles for contained (aka raised) buttons
     * @see https://github.com/mui-org/material-ui/blob/8995f085904eb55bcb5861fb6d8a32fbd38d72eb/packages/material-ui/src/Button/Button.js#L131-L141
     */
    contained: {
      color: theme.palette.getContrastText(color),
      backgroundColor: color,
      "&:hover": {
        backgroundColor: darken(color, 0.5),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: color
        }
      }
    },

    // This is required for the '&$disabled' selector to work
    disabled: {}
  }
}

const theme = createMuiTheme()

const fontFamily = 'Lato'
const colors = {
  primary: '#3D7BF6',
  secondary: '#A1A1A1',
  success: '#1BC47D',
  error: '#CC3300'
}

const defaultTabItem = {
  fontFamily,
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '21px',
  marginRight: '50px',
  color: '#3D7BF6',
  borderRadius: '0px'
}

const defaultAppBarItem = {
  fontFamily,
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  color: '#FFFFFF',
  borderRadius: '0px',
  height: '100%',
  verticalAlign: 'middle',
  padding: '6px 14px'
}

const defaultFilterButton = {
  fontFamily,
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  height: '31px',
  color: '#A2A2A2'
}

const defaultListingCard = {
  main: {
    border: '2px solid #E0E1E4',
    borderRadius: '5px',
    width: 'auto',
    padding: '25px',
    marginRight: '25px',
    marginBottom: '25px'
  },
  title: {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '31px',
    color: '#000000'
  },
  subtitle: {
    fontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#7A7A7A',
    marginTop: '5px',
    paddingBottom: '15px',
  },
  button: {
    marginRight: '5px',
    marginBottom: '5px'
  }
}

const customTheme = {
  palette: {
    primary: {
      main: colors.primary
    }
  },
  typography: {
    fontFamily: fontFamily,
    button: {
      textTransform: 'none',
      fontWeight: 400
    }
  },
  styles: {
    buttons: {
      primary: buttonBuilder(colors.primary, theme),
      secondary: buttonBuilder(colors.secondary, theme),
      success: buttonBuilder(colors.success, theme),
      error: buttonBuilder(colors.error, theme),
      emiLogo: {
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingRight: '0px',
        paddingBottom: '0px',
        width: '50px',
        height: '50px',
        marginRight: '10px',
        icon: {
          width: '50px'
        }
      },
      offTable: {
        fontFamily,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#3179FF',
        paddingRight: '8px',
        paddingLeft: '8px',
        background: '#E6EEFF',
        border: '1px solid #E6EEFF'
      },
      ctaOffTable: {
        fontFamily,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#FFFFFF',
        paddingRight: '8px',
        paddingLeft: '8px',
        background: '#3179FF',
        border: '1px solid #3179FF',
        "&:hover": {
          background: '#FFFFFF',
          color: '#3179FF',
        },
      },
      offTablePagination: {
        fontFamily,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#3D7BF6',
        paddingRight: '5px'
      },
      title: {
        background: '#FFFFFF',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: '5px',
        marginLeft: '5px'
      }
    },
    others: {
      selectListingDropdown:{
        minWidth: '256px'
      },
      appBar: {
        main: {
          paddingLeft: '30px',
          paddingRight: '30px',
          backgroundColor: '#20242C',
          height: '55px'
        },
        toolBar: {
          paddingLeft: '0px',
          paddingRight: '0px',
          height: '100%',
          minHeight: 'auto'
        },
      },
      listingCard: defaultListingCard,
      newListingCard: defaultListingCard,
      newJobBoardCard: defaultListingCard,
      pageTitle: {
        main: {
          marginBottom: '20px'
        },
        title: {
          fontFamily,
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '31px'
        },
        subtitle: {
          fontFamily,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '21px',
          color: '#7A7A7A',
          paddingTop: '10px'
        },
        description: {
          fontFamily,
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#000000',
          paddingTop: '12px'
        }
      }
    },
    pages: {
      base: {
        top: {
          padding: '30px',
          paddingBottom: '10px'
        },
        bottom: {
          padding: '30px',
          paddingTop: '0px'
        }
      },
      homeBase: {
        header: {
          background: '#F5F5F5',
          marginBottom: '10px'
        },
        curved: {
          height: '30px',
          borderRadius: '150%/100px 100px 0 0',
          backgroundColor: '#ffffff',
        }
      },
      analytics: {
        dropdownContainer: {
          marginTop: '20px',
          marginBottom: '40px',
          padding: '40px',
          background: '#FFFFFF',
          border: '2px solid #E0E1E4',
          boxSizing: 'border-box',
          borderRadius: '5px'
        }
      },
      home: {
        sectionTitle: {
          paddingBottom: '20px'
        }
      }
    },
    tabs: {
      default: {
        main: {
          marginTop: '30px',
          marginBottom: '30px'
        },
        item: defaultTabItem,
        itemSelected: {
          ...defaultTabItem,
          color: '#404040',
          borderBottom: '2px solid #404040'
        },
      },
      appBar: {
        main: {
          height: '100%'
        },
        item: {
          ...defaultAppBarItem,
          icon: {
            paddingBottom: '5px'
          }
        },
        itemSelected: {
          ...defaultAppBarItem,
          background: '#1E3E7E',
        }
      },
      filterTable: {
        main: {
          marginLeft: '5px'
        },
        item: defaultFilterButton,
        itemSelected: {
          ...defaultFilterButton,
          background: null,
          borderRadius: '10px',
          color: '#3D7BF6',
          backgroundColor: '#F7F9FF'
        }
      }
    },
    tables: {
      default: {
        bar: {
          paddingTop: '20px',
          paddingBottom: '20px',
          paddingRight: '0px',
          paddingLeft: '0px'
        },
        main: {
          border: '2px solid #E0E1E4',
          borderRadius: '5px',
          padding: '10px',
          width: 'auto'
        },
        title: {
          fontFamily,
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '31px'
        }
      },
      home: {
        select: {
          width: '257px',
          height: '48px',
          background: '#FFFFFF',
          border: '1px solid #E0E1E4',
          boxSizing: 'border-box',
          borderRadius: '5px',
          fontFamily,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '21px',
          color: '#000000',
          padding: '5px'
        },
        option: {
          fontFamily,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '21px',
          color: '#000000'
        }
      }
    }
  }
};

export default createMuiTheme(customTheme)
