/*
 *
 * SubsidiaryPage constants
 *
 */

export const LOADING = 'app/SubsidiaryPage/LOADING'
export const GET_SUBSIDIARIES = 'app/SubsidiaryPage/GET_SUBSIDIARIES'
export const GET_SUBSIDIARIES_SUCCESS = 'app/SubsidiaryPage/GET_SUBSIDIARIES_SUCCESS'
export const GET_SUBSIDIARIES_ERROR = 'app/SubsidiaryPage/GET_SUBSIDIARIES_ERROR'

export const CREATE_SUBSIDIARY = 'app/SubsidiaryPage/CREATE_SUBSIDIARY'
export const CREATE_SUBSIDIARY_SUCCESS = 'app/SubsidiaryPage/CREATE_SUBSIDIARY_SUCCESS'
export const CREATE_SUBSIDIARY_ERROR = 'app/SubsidiaryPage/CREATE_SUBSIDIARY_ERROR'

export const REMOVE_SUBSIDIARY = 'app/SubsidiaryPage/REMOVE_SUBSIDIARY'
export const REMOVE_SUBSIDIARY_SUCCESS = 'app/SubsidiaryPage/REMOVE_SUBSIDIARY_SUCCESS'
export const REMOVE_SUBSIDIARY_ERROR = 'app/SubsidiaryPage/REMOVE_SUBSIDIARY_ERROR'

export const EDIT_SUBSIDIARY = 'app/SubsidiaryPage/EDIT_SUBSIDIARY'
export const EDIT_SUBSIDIARY_SUCCESS = 'app/SubsidiaryPage/EDIT_SUBSIDIARY_SUCCESS'
export const EDIT_SUBSIDIARY_ERROR = 'app/SubsidiaryPage/EDIT_SUBSIDIARY_ERROR'

export const GET_SUBSIDIARY_LOCATIONS = 'app/SubsidiaryPage/GET_SUBSIDIARY_LOCATIONS,'
export const GET_SUBSIDIARY_LOCATIONS_SUCCESS = 'app/SubsidiaryPage/GET_SUBSIDIARY_LOCATIONS_SUCCESS,'
export const GET_SUBSIDIARY_LOCATIONS_ERROR = 'app/SubsidiaryPage/GET_SUBSIDIARY_LOCATIONS_ERROR,'


export const CLEAR_MESSAGE = 'app/SubsidiaryPage/CLEAR_MESSAGE'

// export const GET_LISTINGS = 'app/SubsidiaryPage/GET_LISTINGS'
// export const GET_LISTINGS_SUCCESS = 'app/SubsidiaryPage/GET_LISTINGS_SUCCESS'
// export const GET_LISTINGS_ERROR = 'app/SubsidiaryPage/GET_LISTINGS_ERROR'
