import React from 'react';
import { TextField } from '@material-ui/core';

const CustomTextField = ({ label, required, fontSize, ...rest }) => (
  <TextField
    required={required}
    {...rest}
  />
);

export default CustomTextField;
