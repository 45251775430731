import { isNil, get } from "lodash";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { buildExtractor } from "./resourceExtractors";
import { buildResolver } from "./resourceResolvers";
import { mapKeys } from 'lodash'

/**
 * basic context interface and capabilities
 */
export const ApiResourcesContext = createContext({
    isInitializing: false,
    listResourcesForField: field => [],
    resourcesForFieldAreReady: field => false,
    extractResourceFields: (field, resource) => ({}),
    handleResourceFieldUpdated: (field, data) => {}
})

/**
 * - helper function -
 * 
 * checks if a specific resource field has a dependency with
 * any other field
 * 
 * @param {Object} field 
 * @returns {boolean}
 */
function isDependingField(field) {
    return !isNil(field.dependsOn)
}

/**
 * - helper function -
 * 
 * returns a function that resolves the passed in dependent resource field, or false
 * if the field can't still be resolved. A resource can only be resolved if all of its
 * dependencies have been satisfied in the form data. The resulting function calls
 * the specified resolver for this resource, adding the dependencies as additional arguments.
 * Note that the returned functions will be promises.
 * 
 * @param {Object} field
 * @param {Object} formData
 * @returns {Function|boolean}
 */
function buildResolverForDependingField(field, formData) {
    const { dependsOn, resolver: resolverDefinition } = field
    const dependenciesFieldNames = dependsOn.resources.map(resourceDef => resourceDef.field)
    const emptyResolver = () => false

    const additionalArguments = {}
    for (const fieldName of dependenciesFieldNames) {
        if (!formData[ fieldName ]) {
            return emptyResolver
        }

        additionalArguments[ fieldName ] = formData[ fieldName ]
    }

    const resolver = buildResolver(resolverDefinition)

    return args => resolver({ ...args, ...additionalArguments })
}


/**
 * custom context provider definition
 * 
 * @param {Object} props - passed in props from parent component
 * @param {Array<Object>} props.resourceFields - array of fields of type 'api-resource'
 * @param {Object} props.commonArgs - an object holding the common arguments this context will operate with.
 * common arguments include: subsidiaryId, listingId, stepId, candidateId
 * @param {Object} props.initFormData - an object containing the initial candidate data fields. This is useful for
 * initializing the context by loading the in-use resources
 * @param {Object} props.children - default React children prop passed in automatically
 */
export const ApiResourcesContextProvider = ({ resourceFields = [], commonArgs = {}, initFormData = {}, children }) => {
    const [isLoadingInitialResources, setIsLoadingInitialResources] = useState(false)

    const [resourcesPool, setResourcesPool] = useState({})
    
    const { subsidiaryId, listingId, stepId, candidateId } = commonArgs
    
    useEffect(() => {
        setIsLoadingInitialResources(true)

        const shouldFetchAtLeastOneResource = resourceFields.length > 0 && !isNil(stepId)
        
        // load resources only if there's at least one resource field and valid arguments were provided
        if (!shouldFetchAtLeastOneResource) {
            setIsLoadingInitialResources(false)
            return
        }

        // build all resource resolving functions for an initial resource load
        const resourceResolvers = resourceFields
            .map(field => {
                return isDependingField(field)
                    ? buildResolverForDependingField(field, initFormData)
                    : buildResolver(field.resolver)
            })
        
        // call each resolver. A resolver function returns a Promise that resolves to a resource list (Array<Object>)
        const resolvingCalls = resourceResolvers.map(resolver => resolver(commonArgs))

        Promise.all(resolvingCalls)
            .then(results => {

                // build a dictionary. Use resourceId as the key and the returned resource list as the value
                const initialResourcesPool = results.reduce((all, resourceList, index) => {

                    // if resource list resolved to false, it means it couldn't be resolved
                    // due to missing dependencies
                    if (resourceList === false) {
                        return all
                    }

                    const { resourceId, transformation } = resourceFields[ index ]
                    
                    if (transformation) {
                        const newTransformation = mapKeys(transformation, (value, key) => {
                            const map = {
                                label: 'id',
                                value: 'name',
                            }
                         
                            return map[key] || key
                        })

                        resourceList = resourceList.map((item) => {
                            return Object.entries(newTransformation).reduce(
                                (newItem, [key, value]) => { 
                                    newItem[key] = item[value]
                                    return newItem
                                },
                                {}
                            )
                        })
                    }
                    
                    return {
                        ...all,
                        [resourceId]: resourceList
                    }
                }, {})

                // initial loading finished
                setResourcesPool(initialResourcesPool)
                setIsLoadingInitialResources(false)
            })

    }, [subsidiaryId, listingId, stepId, candidateId, initFormData])


    /**
     * - context property -
     * 
     * retrieves the current resource list for a given resource type field.
     * [] is returned is the field couldn't been yet resolved
     * 
     * @param {Object} field - the field for which we want the resources
     * @param {string} field.resourceId
     * @returns {Array<Object>}
     */
    const listResourcesForField = useCallback(({ resourceId }) => {
        return get(resourcesPool, resourceId, [])
    }, [resourcesPool])


    /**
     * - context property -
     * 
     * when a resource type field is selected from the dropdown, this function executes to retrieve
     * the new form data values that should be set, coming from the selected resource instance. The resource 
     * instance mapping to data values should be resolved by a specific extractor.
     * 
     * In addition, if a resource field that is being observed by other depending resources changes, this
     * function will set the depending resource values to null, thus forcing them to be re-filled. This avoids
     * leaving depending fields filled in with old inconsistent values
     * 
     * @param {Object} fieldDefinition - the field for which a new resource has been picked
     * @param {Object} resourceInstance - the new resource that has been selected
     * @returns {Object} - the resulting form data that should be set for this field
     */
    const extractResourceFields = useCallback(async (fieldDefinition, resourceInstance) => {
        const extractor = buildExtractor(fieldDefinition)
        const updatedFields = await extractor(fieldDefinition, resourceInstance)

        // find fields that depend on the updated one
        const dependingFields = resourceFields
            .filter(isDependingField)
            .filter(field => field.dependsOn.resources.some(r => r.field === fieldDefinition.name))
            
        // set depending fields to null when the main field has changed
        // this avoids having old inconsistent depending values
        // every time the main field changes, depending fields will have to be re-filled
        const cleanUpFields = dependingFields
            .map(field => field.name)
            .reduce((fieldsToClean, fieldName) => {
                return { ...fieldsToClean, [ fieldName ]: null }
            }, {})

        return { ...cleanUpFields, ...updatedFields }
    }, [resourceFields, resourcesPool])


    /**
     * - context property -
     * 
     * use this function to check if the resource list for a resource type field has
     * already been resolved (e.g. fetched from an API and ready to be used). A resource that hasn't
     * yet been resolved, might be in progress of resolving, or in need of a missing dependency
     * 
     * @param {Object} field - the field for which we want to know if the pool has resources
     * @returns {boolean} - true if the resource field has been resolved and a resource list is available
     */
    const resourcesForFieldAreReady = useCallback(({ resourceId }) => {
        return !isNil(resourcesPool[ resourceId ])
    }, [resourcesPool])

    
    /**
     * - context property -
     * 
     * use this function to notify this context about a new change made over a resource type field.
     * Usually, this should be provided as a side effect to be run once a resource type field has changed its value.
     * 
     * A resource field can be depended on by other resource type fields. So, when this field's value changes,
     * their depending fields should be updated as well: the resource list for each of them should be re-computed
     * according to the new entered value. This function performs such an update to the depending fields to
     * ensure that only the corresponding values for each depending field are being showed at any time, or that no values
     * at all are shown.-
     * 
     * @param {Object} updatedField - the resource field that has been changed
     * @param {Object} formData - the updated form data values, including the updated field's value
     * @returns {void}
     */
    const handleResourceFieldUpdated = useCallback((updatedField, formData) => {
        // find fields that depend on the updated one
        const dependingFields = resourceFields
            .filter(isDependingField)
            .filter(field => field.dependsOn.resources.some(resource => resource.field === updatedField.name ))
        
        if (dependingFields.length === 0) {
            return
        }
        
        const resolvingCalls = dependingFields
            .map(field => buildResolverForDependingField(field, formData))
            .map(resolver => resolver(commonArgs))

        Promise.all(resolvingCalls)
            .then(results => {

                // for each depending field, update their resource list accordingly:
                // if all the dependencies are fulfilled, then resolve the list using the resolver
                // if any dependency is missing, then remove the current entry from the pool until they're provided
                const updatedResourcesPool = results.reduce((pool, resourceList, index) => {
                    const { resourceId } = dependingFields[ index ]

                    // if the list resolved to false, then there's a missing dependency for this depending field
                    if (resourceList === false) {
                        delete pool[ resourceId ]
                        return pool
                    }

                    return {
                        ...pool,
                        [resourceId]: resourceList
                    }

                }, { ...resourcesPool })

                // update the pool with the new resources lists (if resolved)
                setResourcesPool(updatedResourcesPool)
            })

    }, [commonArgs, resourceFields, resourcesPool])


    return (
        <ApiResourcesContext.Provider 
            value={{ 
                isInitializing: isLoadingInitialResources, 
                listResourcesForField,
                resourcesForFieldAreReady,
                extractResourceFields,
                handleResourceFieldUpdated
            }}
        >
                {children}
        </ApiResourcesContext.Provider>
    )
}