import React from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton'
import messages from '../messages';
import emiMessages from '../../Emi/messages';
import { Grid } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const ConditionText = ({ id, value, viewElement }) => {
  const label = viewElement && viewElement.label ? viewElement.label : id
  return <React.Fragment>{label}: {value}. </React.Fragment>
}

const SpecialNeedText = ({ code, conditions, viewConfig }) => {
  const content = Object.entries(conditions).map(([id, value], index) => <ConditionText key={index} id={id} value={value} viewElement={viewConfig.find(x => x.id === id)}/>)
  return <Typography>- {code}. {content}</Typography>
}

const SpecialNeedsDialog = ({
  open,
  onClose,
  locations,
  viewConfig,
  intl: { formatMessage },
  loading,
}) => {
  const content = loading ? (
    <Grid item container justify="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container spacing={2}>
      {/* TODO: translate labels */}
      {
        locations && locations.map((location, index) => {
          const { name, hiringManager, address, otherAddresses, requisitions, features, specialNeeds } = location
          return <Grid key={index} item>
            <Typography>{index + 1}. {name}</Typography>
            {hiringManager && (<Typography>LDV: {hiringManager.name} ({hiringManager.phoneNumber})</Typography>)}
            <Typography>Dirección: {address}</Typography>
            <Typography>Extra direcciones: {otherAddresses.join(', ')}</Typography>
            <Typography>Requisiciones: (Full time: {requisitions.fullTime}, Part time: {requisitions.partTime})</Typography>
            <Typography>Características tienda: {features.length === 0 ? 'NA' : features.join(', ')}</Typography>
            <Typography>Nec. Especiales: {!specialNeeds || specialNeeds.length === 0 ? 'NA' : ''}</Typography>
            {specialNeeds && specialNeeds.length > 0 && specialNeeds.map((specialNeed, index) => <SpecialNeedText key={index} viewConfig={viewConfig} {...specialNeed} />)}
          </Grid>
        })
      }
      
    </Grid>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{formatMessage(messages.specialNeeds.title)}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <SecondaryButton type="text" onClick={onClose}>
          {formatMessage(emiMessages.button.close)}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(SpecialNeedsDialog);
