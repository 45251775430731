import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import EmiLogoButton from '../buttons/EmiLogoButton';
import AppBarTabs from '../tabs/AppBarTabs';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import { useUser } from '../../../utils/hooks';
import messages from './messages';
import { injectIntl } from 'react-intl';

const NAVBAR_MAIN_MAX_SIZE = 4;

const useStyles = (isFakeUser) => makeStyles((theme) => {
  const main = isFakeUser ? {
    ...theme.styles.others.appBar.main,
    borderTop: '3px solid yellow'
  } : theme.styles.others.appBar.main
  return ({
    grow: {
      flexGrow: 1,
    },
    main,
    toolBar: theme.styles.others.appBar.toolBar
  })
})();

const AppBar = ({ onLogoClick, mainTab, secondaryTab, moreOptionsMenu, profileMenu, isFakeUser, intl }) => {
  const { formatMessage } = intl;
  const { isTestUser } = useUser();
  const classes = useStyles(isFakeUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSubMenuEl, setAnchorSubMenuEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSubMenuOpen = (event) => {
    setAnchorSubMenuEl(event.currentTarget);
  };
  const handleSubMenuClose = () => {
    setAnchorSubMenuEl(null);
  };

  const [mainTabItems, moreOptionsItems] = useMemo(() => {
    const [mainTabItems, moreOptionsItems] = (() => {
      if (mainTab.length < NAVBAR_MAIN_MAX_SIZE && moreOptionsMenu.items.length) {
        const sizeToFill = NAVBAR_MAIN_MAX_SIZE - mainTab.length;
        const filledMainTab = [...mainTab, ...moreOptionsMenu.items.slice(0, sizeToFill)];
        return [
          filledMainTab,
          moreOptionsMenu.items.slice(sizeToFill)
        ];
      }
      return [
        mainTab,
        moreOptionsMenu.items
      ];
    })();
    if (moreOptionsItems.length) {
      const moreOptions = {
        selected: Boolean(anchorSubMenuEl),
        label: moreOptionsMenu.label,
        endIcon: <ChevronDownIcon />,
        onClick: handleSubMenuOpen
      };
      const mainTabItemsPlusMoreOptions = [...mainTabItems, moreOptions];
      return [mainTabItemsPlusMoreOptions, moreOptionsItems];
    }
    return [mainTabItems, moreOptionsItems];
  }, [mainTab, moreOptionsMenu, anchorSubMenuEl]);
  
  return (
    <div className={classes.grow}>
      {isTestUser && <TestingEnvionmentNotification>
        {formatMessage(messages.testingBanner)}
      </TestingEnvionmentNotification>}
      <MuiAppBar position="static" elevation={0} className={classes.main}>
        <Toolbar className={classes.toolBar}>
          <EmiLogoButton onClick={onLogoClick} />
          <AppBarTabs
            items={mainTabItems}
          />
          <div className={classes.grow} />
          <AppBarTabs
            items={[
              ...secondaryTab,
              {
                selected: Boolean(anchorEl),
                label: profileMenu.label,
                icon: <PersonOutlineOutlinedIcon />,
                onClick: handleProfileMenuOpen
              }
            ]}
          />
        </Toolbar>
      </MuiAppBar>
      <StyledMenu
        anchorEl={anchorSubMenuEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id='sub-menu'
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={anchorSubMenuEl}
        onClose={handleSubMenuClose}
      >
        {moreOptionsItems.map(({ label, icon, onClick }, index) => (
          <StyledMenuItem key={index} onClick={() => { onClick(); handleSubMenuClose(); }}>
            <StyledIconContainer>{icon}</StyledIconContainer>
            <div>{label}</div>
          </StyledMenuItem>
        ))}
      </StyledMenu>
      <StyledMenu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id='user-actions-menu'
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {profileMenu.items.map(({ label, onClick }, index) => (
          <StyledMenuItem key={index} onClick={() => { onClick(); handleMenuClose(); }}>
            {label}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

const TestingEnvionmentNotification = styled(Box)`
  padding: 2px 10px;
  background-color: #5E33C5;
  font-family: Lato;
  color: #fff;
`;

const StyledIconContainer = styled.div`
  margin-right: 10px;
`;

const StyledMenu = withStyles(() => ({
  list: {
    padding: 0,
    margin: '0 10px',
    color: '#20242C',
  },
  paper: {
    marginTop: '40px',
    background: '#F2F2F2',
    boxShadow: '0px 4px 2px rgba(37, 35, 35, 0.25)'
  }
}))(Menu);

const StyledMenuItem = withStyles(() => ({
  root: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    padding: '21px 25px',
    borderBottom: '1px solid #A7A7A7',
    '&:last-of-type': {
      borderBottom: '0px'
    }
  }
}))(MenuItem);

AppBar.propTypes = {
  onLogoClick: PropTypes.func.isRequired,
  mainTab: PropTypes.array.isRequired,
  secondaryTab: PropTypes.array.isRequired,
  profileMenu: PropTypes.object.isRequired,
  isFakeUser: PropTypes.boolean
};

export default injectIntl(AppBar);
