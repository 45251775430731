import {
  CANDIDATE_CHANGED,
  HIDE_ADVANCE,
  HIDE_GO_TO_CANDIDATE,
  HIDE_HIRE,
  HIDE_INTERVIEW_CANCEL,
  HIDE_INTERVIEW_INVITE,
  HIDE_INTERVIEW_RESCHEDULE,
  HIDE_LOG,
  HIDE_REJECT,
  HIDE_RESUME,
  HIDE_SPECIAL_NEEDS,
  HIDE_STAND_BY,
  REQUEST_ADVANCE,
  REQUEST_ADVANCE_FAIL,
  REQUEST_ADVANCE_SUCCEED,
  REQUEST_HIRE,
  REQUEST_INTERVIEW_CANCEL,
  REQUEST_GO_TO_CANDIDATE,
  REQUEST_INTERVIEW_INVITE,
  REQUEST_INTERVIEW_RESCHEDULE,
  REQUEST_LOG,
  REQUEST_LOG_FAIL,
  REQUEST_LOG_SUCCEED,
  REQUEST_REJECT,
  REQUEST_REJECT_FAIL,
  REQUEST_REJECT_SUCCEED,
  REQUEST_STAND_BY,
  REQUEST_STAND_BY_FAIL,
  REQUEST_STAND_BY_SUCCEED,
  REQUEST_RESUME,
  SHOW_ADVANCE,
  SHOW_LOG,
  SHOW_REJECT,
  SHOW_SPECIAL_NEEDS,
  SHOW_STAND_BY,
  REQUEST_SPECIAL_NEEDS,
  REQUEST_SPECIAL_NEEDS_SUCCEED,
  REQUEST_SPECIAL_NEEDS_FAIL,
  SHOW_CHANGE_VACANCY,
  HIDE_CHANGE_VACANCY,
  SHOW_EDIT_CANDIDATE_DATA,
  HIDE_EDIT_CANDIDATE_DATA,
  REQUEST_EDIT_CANDIDATE_DATA,
  REQUEST_EDIT_CANDIDATE_DATA_SUCCEED,
  REQUEST_EDIT_CANDIDATE_DATA_FAIL,
  SHOW_HELP_AND_SUPPORT,
  HIDE_HELP_AND_SUPPORT
} from "./constants";

export const showLog = () => ({
  type: SHOW_LOG
})

export const requestLog = (candidateId, listingId, name, channelUserId) => ({
  type: REQUEST_LOG,
  candidateId,
  listingId,
  name,
  channelUserId
})

export const requestLogSucceed = (data) => ({
  type: REQUEST_LOG_SUCCEED,
  data
})

export const requestLogFail = (error) => ({
  type: REQUEST_LOG_FAIL,
  error
})

export const hideLog = () => ({
  type: HIDE_LOG
})

export const hideResume = () => ({
  type: HIDE_RESUME
})

export const requestResume = (candidateId, listingId, stepId) => ({
  type: REQUEST_RESUME,
  data: {
    candidateId,
    listingId,
    stepId
  }
})

export const hideCancelInterview = () => ({
  type: HIDE_INTERVIEW_CANCEL
})

export const requestCancelInterview = (candidateId, stepId) => ({
  type: REQUEST_INTERVIEW_CANCEL,
  data: {
    candidateId,
    stepId
  }
})

export const requestGoToCandidate = (candidateId, listingId, stepId) => ({
  type: REQUEST_GO_TO_CANDIDATE,
  data: {
    candidateId,
    listingId,
    stepId
  }
})

export const hideGoToCandidate = () => ({
  type: HIDE_GO_TO_CANDIDATE
})

export const hideInviteToInterview = () => ({
  type: HIDE_INTERVIEW_INVITE
})

export const requestInviteToInterview = (candidateId, candidateName, listingId, stepId, withExperimentalLayout, withDecentralizedLayout, config) => ({
  type: REQUEST_INTERVIEW_INVITE,
  data: {
    candidateId,
    candidateName,
    listingId,
    stepId,
    withExperimentalLayout,
    withDecentralizedLayout,
    config
  }
})

export const hideRescheduleInterview = () => ({
  type: HIDE_INTERVIEW_RESCHEDULE
})

export const requestRescheduleInterview = (candidateId, listingId, stepId) => ({
  type: REQUEST_INTERVIEW_RESCHEDULE,
  data: {
    candidateId,
    listingId,
    stepId
  }
})

export const hideHire = () => ({
  type: HIDE_HIRE
})

export const requestHire = (candidateId, candidateName, listingId, channelUserId) => ({
  type: REQUEST_HIRE,
  data: {
    candidateId,
    candidateName,
    listingId,
    channelUserId
  }
})

export const showAdvance = () => ({
  type: SHOW_ADVANCE
})

export const hideAdvance = () => ({
  type: HIDE_ADVANCE
})

export const requestAdvance = (candidateId, listingId, subsidiaryId, stepId) => ({
  type: REQUEST_ADVANCE,
  candidateId,
  listingId,
  subsidiaryId,
  stepId
})

export const requestAdvanceSucceed = (data) => ({
  type: REQUEST_ADVANCE_SUCCEED,
  data
})

export const requestAdvanceFail = (error) => ({
  type: REQUEST_ADVANCE_FAIL,
  error
})

export const showReject = (data) => ({
  type: SHOW_REJECT,
  data
})

export const hideReject = () => ({
  type: HIDE_REJECT
})

export const requestReject = (candidateId, listingId, stepId, stepType, name, state) => ({
  type: REQUEST_REJECT,
  candidateId,
  listingId,
  stepId,
  stepType,
  name,
  state
})

export const requestRejectSucceed = (data) => ({
  type: REQUEST_REJECT_SUCCEED,
  data
})

export const requestRejectFail = (error) => ({
  type: REQUEST_REJECT_FAIL,
  error
})

export const showStandBy = (data) => ({
  type: SHOW_STAND_BY,
  data
})

export const hideStandBy = () => ({
  type: HIDE_STAND_BY
})

export const requestStandBy = (candidateId, listingId, stepId, subsidiaryId, stepType, name, state) => ({
  type: REQUEST_STAND_BY,
  candidateId,
  listingId,
  stepId,
  subsidiaryId,
  stepType,
  name,
  state
})

export const requestStandBySucceed = (data) => ({
  type: REQUEST_STAND_BY_SUCCEED,
  data
})

export const requestStandByFail = (error) => ({
  type: REQUEST_STAND_BY_FAIL,
  error
})

export const candidateChanged = () => ({
  type: CANDIDATE_CHANGED
})

export const showSpecialNeeds = () => ({
  type: SHOW_SPECIAL_NEEDS
})

export const requestSpecialNeeds = (listingId, locations, viewConfig) => ({
  type: REQUEST_SPECIAL_NEEDS,
  listingId,
  locations,
  viewConfig
})

export const requestSpecialNeedsSucceed = (response, viewConfig) => ({
  type: REQUEST_SPECIAL_NEEDS_SUCCEED,
  data: { response, viewConfig }
})

export const requestSpecialNeedsFail = (error) => ({
  type: REQUEST_SPECIAL_NEEDS_FAIL,
  error
})

export const hideSpecialNeeds = () => ({
  type: HIDE_SPECIAL_NEEDS
})

export const showChangeVacancy = () => ({
  type: SHOW_CHANGE_VACANCY
})

export const hideChangeVacancy = () => ({
  type: HIDE_CHANGE_VACANCY
})

export const showEditCandidateData = (data) => ({
  type: SHOW_EDIT_CANDIDATE_DATA,
  data
})

export const requestEditCandidateData = (candidateId, subsidiaryId, listingId, stepId, config) => ({
  type: REQUEST_EDIT_CANDIDATE_DATA,
  candidateId,
  subsidiaryId,
  listingId,
  stepId,
  config
})

export const requestEditCandidateDataSucceed = (data) => ({
  type: REQUEST_EDIT_CANDIDATE_DATA_SUCCEED,
  data
})

export const requestEditCandidateDataFail = (error) => ({
  type: REQUEST_EDIT_CANDIDATE_DATA_FAIL,
  error
})

export const hideEditCandidateData = () => ({
  type: HIDE_EDIT_CANDIDATE_DATA
})

export const showHelpAndSupport = () => ({
  type: SHOW_HELP_AND_SUPPORT
})

export const hideHelpAndSupport = () => ({
  type: HIDE_HELP_AND_SUPPORT
})