import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from "@mui/material/styles";
import { signOut } from '../../containers/App/actions';
import { showHelpAndSupport } from '../../store/candidateActions/actions'
import Layout from "./Layout";
import theme from "./theme"
import { useNavigation } from "./hooks/useNavigation";
import { useUser } from "../../utils/hooks";

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ signOut, showHelpAndSupport }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(function ({ actions: { showHelpAndSupport, signOut }, isFakeUser, children }) {
  const user = useUser()
  const { menu, profileMenu, workspace } = useNavigation({ showHelpAndSupport, signOut })
  return (
    <ThemeProvider theme={theme}>
      <Layout
        menuItems={menu}
        profileMenuItems={profileMenu}
        workspace={workspace}
        userName={`${user.firstName} ${user.lastName}`}
        isImpersonated={user.isFake}
        isTestEnvironment={user.isTestUser}
      >{children}</Layout>
    </ThemeProvider>
  );
})
