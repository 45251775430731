/*
 * Messages
 *
 * This contains all the text for the auth component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  tabnavs:{
    header: {
      listings:{
        id: 'boilerplate.components.Header.tabnavs.header.listings',
        defaultMessage: 'Listings',
      },
      home:{
        id: 'boilerplate.components.Header.tabnavs.header.pendingActions',
        defaultMessage: 'Home',
      },
      docs:{
        id: 'boilerplate.components.Header.tabnavs.header.docs',
        defaultMessage: 'Tutorials',
      },
      jobBoards:{
        id: 'boilerplate.components.Header.tabnavs.header.jobBoards',
        defaultMessage: 'Job Boards',
      },
      searchCandidate:{
        id: 'boilerplate.components.Header.tabnavs.header.searchCandidate',
        defaultMessage: 'Search Candidates',
      },
      candidateView:{
        id: 'boilerplate.components.Header.tabnavs.header.candidateView',
        defaultMessage: 'Candidates',
      },
      analytics:{
        id: 'boilerplate.components.Header.tabnavs.header.analytics',
        defaultMessage: 'Analytics',
      },
      candidateAdd:{
        id: 'boilerplate.components.Header.tabnavs.header.candidateAdd',
        defaultMessage: 'Add Candidate',
      },
      candidateLoad:{
        id: 'boilerplate.components.Header.tabnavs.header.candidateLoad',
        defaultMessage: 'Candidate Load',
      },
      approve:{
        id: 'boilerplate.components.Header.tabnavs.header.approve',
        defaultMessage: 'Approve',
      },
      wish:{
        id: 'boilerplate.components.Header.tabnavs.header.wish',
        defaultMessage: 'Feedback',
      },
      profiles:{
        id: 'boilerplate.components.Header.tabnavs.header.profiles',
        defaultMessage: 'Profiles',
      },
      profile:{
        id: 'boilerplate.components.Header.tabnavs.header.profile',
        defaultMessage: 'Profile',
      },
      moreOptions: {
        id: 'boilerplate.components.Header.tabnavs.header.moreOptions',
        defaultMessage: 'More options',
      },
      signOut:{
        id: 'boilerplate.components.Header.tabnavs.header.signOut',
        defaultMessage: 'Sign out',
      },
      suggestions:{
        id: 'boilerplate.components.Header.tabnavs.header.suggestions',
        defaultMessage: 'Suggestions',
      },
      companies:{
        id: 'boilerplate.components.Header.tabnavs.header.companies',
        defaultMessage: 'Companies',
      },
      subsidiaries:{
        id: 'boilerplate.components.Header.tabnavs.header.subsidiaries',
        defaultMessage: 'Subsidiaries',
      },
      users:{
        id: 'boilerplate.components.Header.tabnavs.header.users',
        defaultMessage: 'Users',
      },
      integrations:{
        id: 'boilerplate.components.Header.tabnavs.header.integrations',
        defaultMessage: 'Integrations',
      },
      status:{
        id: 'boilerplate.components.Header.tabnavs.header.status',
        defaultMessage: 'Status'
      },
      candidateBag:{
        id: 'boilerplate.components.Header.tabnavs.header.candidateBag',
        defaultMessage: 'Candidate Bag'
      },
      attraction:{
        id: 'boilerplate.components.Header.tabnavs.header.attraction',
        defaultMessage: 'Attraction',
      },
      interviewView:{
        id: 'boilerplate.components.Header.tabnavs.header.interviewView',
        defaultMessage: 'Interviews',
      },
      vacancies:{
        id: 'boilerplate.components.Header.tabnavs.header.vacancies',
        defaultMessage: 'Vacancies',
      },
      helpAndSupport:{
        id: 'boilerplate.components.Header.tabnavs.header.helpAndSupport',
        defaultMessage: 'Performance Review',
      },
      oxxo_interview_form_shortcut:{
        id: 'boilerplate.components.Header.tabnavs.header.applicationPage',
        defaultMessage: 'Interview Form',
      },
      campaigns:{
        id: 'boilerplate.components.Header.tabnavs.header.campaigns',
        defaultMessage: 'Candidate Re-engagement',
      },
      requisitions:{
        id: 'boilerplate.components.Header.tabnavs.header.requisitions',
        defaultMessage: 'Requisitions',
      },
    }
  },
});
