import {
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILED,
  GET_COUNTRIES,


  SIGN_IN_FACEBOOK,
  SIGN_IN_GOOGLE,
  REGISTER,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  AUTHENTICATED,
  AUTHENTICATION_FAILED,
  REGISTRATION_FAILED,
} from './constants';

import { SIGN_IN } from '../../store/session/constants'


import { take, call, put, cancel, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  loadMenu,
  signInSuccess,
  authenticated, authenticationFail,
  companyRead,
  loadUserApi, loadUserSuccess,
  getCountriesSuccess, getCountriesError,

  } from './actions';
import { LOCATION_CHANGE } from 'react-router-redux';

import request from '../../utils/request';
import track from '../../utils/track';
import { getInformation } from '../../utils/tawkToUtils';
import { getNetworkConnectionInfo } from '../../utils/networkUtils';
const config = require('../../config');
const root = config.params.API_SERVER

export function* userSignIn(action) {
  const requestURL = `${root}/auth`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    yield put(loadUserApi());
    yield put(authenticated(result));
    const user = result.data.user
    user.roles = result.data.roles
    yield put(loadMenu(user))
    trackUserInfo(user)
  } catch (err) {
    yield put(authenticationFail(err));
  }
}

const trackUserInfo = async (user) => {
  try {
    const tawkTo = await getInformation()
    const network = getNetworkConnectionInfo()
    track({
      type: 'USER_INFORMATION',
      data: {
        subsidiaryId: user.subsidiaryId,
        roles: user.roles,
        authorizedSections: user.authorizedSections,
        tawkTo,
        network
      }
    })
  } catch (e) {
  }
}

export function* signIn() {
  const watcher = yield takeLatest(SIGN_IN, userSignIn);

  //yield take(LOCATION_CHANGE);
  //yield cancel(watcher);
}

export function* fetchUser(action) {
  const requestURL = `${root}/users/me`;
  console.log('fetchUSer')
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify(action.payload || {}),
    });
    console.log("fetchUser", result)
    yield put(loadUserSuccess(result));
  } catch (err) {
    yield put(authenticationFail(err));
  }
}

export function* loadUser() {
  yield takeLatest(LOAD_USER, fetchUser);
}

export function* fetchSignIn(action) {
  try {
    // here you can call your API in order to authenticate the user
    if (action.payload.email === 'demo@test.com' &&
      action.payload.password === 'demo') {
      yield put({ type: AUTHENTICATED,
        user: {
          name: 'John Smith',
          email: action.payload.email,
          imgUrl: 'http://www.material-ui.com/images/ok-128.jpg',
        },
      });
    } else if (action.payload.email === 'demo2@test.com' &&
      action.payload.password === 'demo2') {
      yield put({ type: AUTHENTICATED,
        user: {
          name: 'Jane Doe',
          email: action.payload.email,
          imgUrl: 'http://www.material-ui.com/images/uxceo-128.jpg',
        },
      });
    } else {
      yield put({
        type: AUTHENTICATION_FAILED,
        messageKey: 'error.login',
      });
    }
  } catch (e) {
    yield put({ type: AUTHENTICATION_FAILED, message: e.message });
  }
}

// export function* signIn() {
//   yield takeLatest(SIGN_IN, fetchSignIn);
// }

export function* fetchSignInFacebook(action) {
  try {
    // here you can call your API in order to authenticate the user, for this demo just authenticate an user
    yield put({ type: AUTHENTICATED,
      user: {
        name: 'John Smith',
        email: action.payload.email,
        imgUrl: 'http://www.material-ui.com/images/ok-128.jpg',
      },
    });
  } catch (e) {
    yield put({ type: AUTHENTICATION_FAILED, message: e.message });
  }
}

export function* signInFacebook() {
  yield takeLatest(SIGN_IN_FACEBOOK, fetchSignInFacebook);
}

export function* fetchSignInGoogle(action) {
  try {
    // here you can call your API in order to authenticate the user, for this demo just authenticate an user
    yield put({ type: AUTHENTICATED,
      user: {
        name: 'John Smith',
        email: action.payload.email,
        imgUrl: 'http://www.material-ui.com/images/ok-128.jpg',
      },
    });
  } catch (e) {
    yield put({ type: AUTHENTICATION_FAILED, message: e.message });
  }
}

export function* signInGoogle() {
  yield takeLatest(SIGN_IN_GOOGLE, fetchSignInGoogle);
}

export function* fetchRegister(action) {
  try {
    // here you can call your API in order to register an user, for this demo just authenticate an user
    yield put({ type: AUTHENTICATED,
      user: {
        name: 'John Smith',
        email: action.payload.email,
        imgUrl: 'http://www.material-ui.com/images/ok-128.jpg',
      },
    });
  } catch (e) {
    yield put({ type: REGISTRATION_FAILED, message: e.message });
  }
}

export function* register() {
  yield takeLatest(REGISTER, fetchRegister);
}

export function* fetchResetPassword(action) {
  try {
    // here you can call your API in order to reset the password, for this demo just authenticate an user
    yield put({ type: AUTHENTICATED,
      user: {
        name: 'John Smith',
        email: action.payload.email,
        imgUrl: 'http://www.material-ui.com/images/ok-128.jpg',
      },
    });
  } catch (e) {
    yield put({ type: RESET_PASSWORD_FAILED, message: e.message });
  }
}

export function* resetPassword() {
  yield takeLatest(RESET_PASSWORD, fetchResetPassword);
}

export function* sCountries() {
  const requestURL = `${root}/countries`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log("sCountries", result)
    yield put(getCountriesSuccess(result));

  } catch (err) {
    yield put(getCountriesError(err));
  }
}

export function* getCountriesApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_COUNTRIES, sCountries);

  // Suspend execution until location changes
  //yield take(LOCATION_CHANGE);
  //yield cancel(watcher);
}

// All sagas to be loaded
export default [
  signIn,
  loadUser,
  signInFacebook,
  signInGoogle,
  register,
  resetPassword,
  getCountriesApi
];
