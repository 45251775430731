/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
 import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';

import {
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS,
  AUTH_GOOGLE_SUCCESS,
  AUTH_GOOGLE_REQUEST
} from 'store/integrations/constants';

import {
  getIntegrationsSuccess,
  authGoogleSuccess
} from 'store/integrations/actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

function* sGetIntegrations(action) {
  const requestURL = `${root}/integrations`;
  console.log(requestURL)
  try {
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getIntegrationsSuccess(result));

  } catch (err) {
    console.log('todo error', err)
    // yield put(getSubsidiaryRejectionFlowsError(err));
  }
}

export function* getIntegrations() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_INTEGRATIONS, sGetIntegrations);

}

function* sAuthGoogle(action) {
  const requestURL = `${root}/integrations/google`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    console.log()
    yield put(authGoogleSuccess(result.url));

  } catch (err) {
    console.log('todo error')
    // yield put(getSubsidiaryRejectionFlowsError(err));
  }
}

export function* authGoogle() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(AUTH_GOOGLE_REQUEST, sAuthGoogle);

}

// All sagas to be loaded
export default [
  getIntegrations,
  authGoogle
];
