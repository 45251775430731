import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';

import { candidateChanged, hideAdvance } from '../../../store/candidateActions/actions';
import { moveCandidateAtInterviewToNextStep } from '../../../utils/connector';
import ConfirmationModal from '../../StepView/Interview/ConfirmationModal';
import messages from '../messages';
import track from '../../../utils/track';

const LOCATION_ID = '__locationId';

const AdvanceCandidateDialog = injectIntl(({
  view,
  listingId,
  subsidiaryId,
  candidateId,
  candidate,
  show,
  loading,
  onClose,
  onSucceed,
  intl: { formatMessage }
}) => {
  const [enhancedView, setEnhancedView] = useState(null);
  const [locationFieldId, setLocationFieldId] = useState(LOCATION_ID);

  useEffect(() => {
    if (view) {
      let newView = [...view];
      const locationElements = newView.filter(x => (
        x.required_in_interview === true 
        && (x.field_type === 'locations' || Array.isArray(x.dropdown))
        && ['manually_assigned_working_place_id', 'assigned_working_place_id'].includes(x.id)
      ));
      if (locationElements && locationElements.length > 0) {
        setLocationFieldId(locationElements[0].id);
      } else {
        const locationField = {
          required: true,
          id: LOCATION_ID,
          label: formatMessage(messages.advance.workingPlace),
          field_type: 'locations'
        };
        newView.push(locationField);
      }
      setEnhancedView(newView);
    } else {
      setEnhancedView(null);
    }
  }, [view]);

  const onConfirm = ({ answers }) => {
    let locationId = null;
    const data = answers.reduce((previousValue, currentValue) => {
      const { questionIndex, value } = currentValue;
      if (questionIndex === locationFieldId) {
        locationId = value;
        if (locationFieldId === LOCATION_ID) {
          return previousValue;
        }
      }
      return { ...previousValue, [questionIndex]: value };
    }, {});
    onSucceed(locationId, data);
  }

  if (show === false) {
    return null;
  }

  return (
    <Dialog
      open={show}
      onClose={onClose}
      fullWidth
    >
      {
        (loading || !enhancedView) ? (
          <React.Fragment>
            <DialogTitle>{formatMessage(messages.advance.title)}</DialogTitle>
            <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100px" }}>
              <CircularProgress />
            </div>
          </React.Fragment>
        ) : (
          <ConfirmationModal
            data={{
              id: 'approved',
              rowData: candidate,
              candidateId,
              forcedFeedback: false,
              editedFeedback: false,
              messages: {
                title: formatMessage(messages.advance.title),
                body: null
              }
            }}
            tableHeaders={enhancedView}
            onClose={onClose}
            onConfirm={onConfirm}
            listingId={listingId}
            subsidiaryId={subsidiaryId}
            slotConditions={[]}
            slot={{}}
            user={{}}
          />
        )
      }
    </Dialog>
  );
});

const AdvanceCandidateAction = ({ store: { show, loading, data }, close, notify }) => {
  const { candidate = {}, candidateId, subsidiaryId, listingId, stepId, locations, view } = data || {};
  const [processing, setProcessing] = useState(null);

  const onSucceed = async (locationId, data) => {
    setProcessing(true);
    try {
      await moveCandidateAtInterviewToNextStep(candidateId, listingId, stepId, locationId, data);
      track({ type: 'CANDIDATE_APPROVE_MODAL_CONFIRM', candidateId: [candidateId], candidateCount: 1, listingId, stepId, state: candidate.step_state });
      notify();
    } catch (e) {
      console.error(`Cannot move candidate to the next step ${candidateId}`);
    }
    close();
    setProcessing(null);
  }

  return (
    <AdvanceCandidateDialog
      show={show}
      loading={processing || loading}
      onClose={close}
      onSucceed={onSucceed}
      locations={locations}
      view={view}
      subsidiaryId={subsidiaryId}
      listingId={listingId}
      candidateId={candidateId}
      candidate={candidate}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    store: state.get('candidateActions').advance
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch(hideAdvance()),
    notify: () => dispatch(candidateChanged())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceCandidateAction);
