import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { debounce } from 'lodash';

import MuiDialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import PrimaryButton from '../../../../v2/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../v2/components/buttons/SecondaryButton';
import AutoCompleteCondition from '../../../../v2/components/others/AutoCompleteCondition';
import { getVacancy } from '../../../../utils/connector';

import messages from '../../messages';

export const Dialog = injectIntl(({
  candidate,
  vacancies,
  isLoadingVacancies,
  show,
  loading,
  onClose,
  onInputChange,
  onSucceed,
  intl: { formatMessage }
}) => {
  const [vacancyId, setVacancyId] = useState(candidate['assigned_vacancy_id'] || null);
  const [assignedVacancy, setAssignedVacancy] = useState(null);

  useEffect(() => {
    const getVacancyById = async (vacancyId) => {
      const vacancy = await getVacancy(vacancyId);
      setAssignedVacancy(vacancy);
    }
    const currentVacancy = vacancies.find(vac => vac.id === vacancyId);
    if (vacancyId) {
      if (!currentVacancy) {
        getVacancyById(vacancyId);
      } else {
        setAssignedVacancy(currentVacancy);
      }  
    } else {
      setAssignedVacancy(null);
    }
  }, [vacancyId]);

  const onConfirm = () => {
    onSucceed(vacancyId);
  }

  const debouncedOnInputChange = useCallback(debounce(onInputChange, 500), []);

  const options = useMemo(() => assignedVacancy ? [...vacancies, assignedVacancy] : vacancies, [vacancies, assignedVacancy]);

  return (
    <MuiDialog
      open={show}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>{formatMessage(messages.changeVacancy.title)}</DialogTitle>
      {
        loading ? (
          <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100px" }}>
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            <DialogContent>
              <AutoCompleteCondition
                label={formatMessage(messages.changeVacancy.vacancy)}
                value={assignedVacancy && assignedVacancy.alias}
                onChange={(newValue) => setVacancyId(newValue ? newValue.id : null)}
                onInputChange={(event, newInputValue) => debouncedOnInputChange(newInputValue)}
                isLoading={isLoadingVacancies}
                noOptionsText={formatMessage(messages.changeVacancy.noMatch)}
                metadata={{
                  options,
                  getOptionLabel: (option) => option.alias,
                  getValueItem: (value) => (!options || !value) ? null : options.find(l => l.alias == value)
                }}
              />
            </DialogContent>
            <DialogActions>
              <PrimaryButton
                onClick={onConfirm}
              >{formatMessage(messages.emi.button.confirm)}</PrimaryButton>
              <SecondaryButton
                type="text"
                onClick={onClose}
              >{formatMessage(messages.emi.button.cancel)}</SecondaryButton>
            </DialogActions>
          </React.Fragment>
        )
      }
    </MuiDialog>
  );
});
