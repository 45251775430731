import React from 'react'
import useLoader from '../hooks/useLoader'
import { FeatureContextProvider } from '../context'

export const Loader = ({
  fallback,
  loading,
  strategy,
  children
}) => {

  const { state, instance } = useLoader(strategy)
  switch (state) {
    case 'LOADING':
      return loading || null
    case 'ERROR':
      return fallback || null
    case 'OK':
    default:
      return (
        <FeatureContextProvider instance={instance}>{children}</FeatureContextProvider>
      )
  }
}