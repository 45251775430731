export const API_SERVER =
  process.env.API_SERVER || 'https://cbo-backend-staging.emilabs.io/api/v1';
export const DESCARTES_SERVER =
  process.env.DESCARTES_SERVER || 'https://descartes.holaemi.com';
export const WEBHOOK_SERVER =
  process.env.WEBHOOK_SERVER ||
  'http://emi-motion-staging.us-east-1.elasticbeanstalk.com';
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE';
export const METRICS_METABASE_SITE_URL =
  process.env.METRICS_METABASE_SITE_URL || 'https://metabase.emilabs.ai';
export const GOOGLE_MAPS_API_KEY =
  process.env.GOOGLE_MAPS_API_KEY || 'AIzaSyDmoYGllIsMUFo9EtJP11aIy-dM4-yt3fQ';
export const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  'http://830b00ae2e3140f79e1c5303139d2d85@sentry.holaemi.com/29';
export const COGNITO_LOGIN_URL = 'https://login-staging.emilabs.io';
export const TRACKING_ENABLED = false;
export const OTEL_COLLECTOR_API = 'https://otel-collector-staging.emilabs.io'