/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
import { push } from 'react-router-redux'
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CONTACT_CANDIDATE,

} from './constants';

import {
  contactCandidateSuccess,
  contactCandidateError,
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sContactCandidates(action) {
  const requestURL = `${root}/contact-candidate`;
  console.log("sagas");
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.payload),
    });
    yield put(contactCandidateSuccess(result));

  } catch (err) {
    yield put(contactCandidateError(err));
  }
}

export function* contactCandidatesApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(CONTACT_CANDIDATE, sContactCandidates);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}



// All sagas to be loaded
export default [
  contactCandidatesApi
];
