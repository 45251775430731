/*
 *
 * PlatformPage actions
 *
 */

import {
  LOADING,
  GET_SUBSIDIARIES,
  GET_SUBSIDIARIES_SUCCESS,
  GET_SUBSIDIARIES_ERROR,
  GET_SUBSIDIARY_LOCATIONS,
  GET_SUBSIDIARY_LOCATIONS_SUCCESS,
  GET_SUBSIDIARY_LOCATIONS_ERROR,
  CREATE_SUBSIDIARY,
  CREATE_SUBSIDIARY_SUCCESS,
  CREATE_SUBSIDIARY_ERROR,
  REMOVE_SUBSIDIARY,
  REMOVE_SUBSIDIARY_SUCCESS,
  REMOVE_SUBSIDIARY_ERROR,
  EDIT_SUBSIDIARY,
  EDIT_SUBSIDIARY_SUCCESS,
  EDIT_SUBSIDIARY_ERROR,
  CLEAR_MESSAGE,
} from './constants';


export function setLoading(loading){
  return {
    type: LOADING,
    loading
  }
}

export function getSubsidiaries() {
  console.log("GET_SUBSIDIARIES")
  return {
    type: GET_SUBSIDIARIES
  };
}

export const getSubsidiaryLocations = ({ listingId, subsidiaryId }) => {
  console.log("GET_SUBSIDIARY_LOCATIONS", {listingId, subsidiaryId})
  return {
    type: GET_SUBSIDIARY_LOCATIONS,
    listingId,
    subsidiaryId
  }
}

export const getSubsidiaryLocationsSuccess = ({ locations }) => {
  return {
    type: GET_SUBSIDIARY_LOCATIONS_SUCCESS,
    locations
  }
}

export function getSubsidiariesSuccess(subsidiaries) {
  return {
    type: GET_SUBSIDIARIES_SUCCESS,
    subsidiaries
  };
}

export function getSubsidiariesError(error) {
  return {
    type: GET_SUBSIDIARIES_ERROR,
    error
  };
}

export function createSubsidiary(subsidiary){
  console.log("CREATE_SUBSIDIARY")
  return {
    type: CREATE_SUBSIDIARY,
    subsidiary
  }
}


export function createSubsidiarySuccess(subsidiary){
  return {
    type: CREATE_SUBSIDIARY_SUCCESS,
    subsidiary
  }
}


export function createSubsidiaryError(error){
  return {
    type: CREATE_SUBSIDIARY_ERROR,
    error
  }
}

export function removeSubsidiary(subsidiary){
  return {
    type: REMOVE_SUBSIDIARY,
    subsidiary
  }
}


export function removeSubsidiarySuccess(subsidiary){
  return {
    type: REMOVE_SUBSIDIARY_SUCCESS,
    subsidiary
  }
}


export function removeSubsidiaryError(error){
  return {
    type: REMOVE_SUBSIDIARY_ERROR,
    error
  }
}

export function editSubsidiary(subsidiary){
  console.log("EDIT_SUBSIDIARY")
  return {
    type: EDIT_SUBSIDIARY,
    subsidiary
  }
}


export function editSubsidiarySuccess(subsidiary){
  return {
    type: EDIT_SUBSIDIARY_SUCCESS,
    subsidiary
  }
}


export function editSubsidiaryError(error){
  return {
    type: EDIT_SUBSIDIARY_ERROR,
    error
  }
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}
