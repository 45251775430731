/*
 *
 * ApidemoPage reducer
 *
 */

import { fromJS } from 'immutable';
import _ from 'lodash';
import {
  SET_VIEW_CONFIG,
  CLEAN_LOCAL_DATA,
  GET_VIEW_DATA_SUCCESS,
  GET_VIEW_DATA_ERROR,
  GET_PENDING_APPROVAL_LISTINGS_SUCCESS,
  GET_PENDING_APPROVAL_LISTINGS_ERROR,
  GET_STEP_VIEWS_SUCCESS,
  GET_STEP_VIEWS_ERROR,
  GET_VIEW_CONFIG_SUCCESS,
  GET_VIEW_CONFIG_ERROR,
  GET_REASONS_REJECTION_SUCCESS,
  GET_REASONS_REJECTION_ERROR,
  ADD_REASONS_REJECTION_WAITING,
  ADD_REASONS_REJECTION_SUCCESS,
  ADD_REASONS_REJECTION_ERROR,
  REMOVE_REASONS_REJECTION_WAITING,
  REMOVE_REASONS_REJECTION_SUCCESS,
  REMOVE_REASONS_REJECTION_ERROR,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_ERROR,
  APPROVE_CANDIDATES_SUCCESS,
  APPROVE_CANDIDATES_ERROR,
  CANDIDATES_TO_NEXT_STEP_SUCCESS,
  CANDIDATES_TO_NEXT_STEP_ERROR,
  CLEAR_MESSAGE,
  GET_INTERVIEW_CONFIG_SUCCESS,
  GET_INTERVIEW_CONFIG_ERROR,
  SAVE_INTERVIEW_SLOT_SUCCESS,
  SAVE_INTERVIEW_SLOT_ERROR,
  GET_SLOT_AVAILABILITY_SUCCESS,
  GET_SLOT_AVAILABILITY_ERROR,
  GET_INTERVIEW_SLOTS_SUCCESS,
  GET_INTERVIEW_SLOTS_ERROR,
  RESET_SLOT_AVAILABILITY,
  GET_CANDIDATES_IN_INTERVIEW_SUCCESS,
  GET_CANDIDATES_IN_INTERVIEW_ERROR,
  SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS,
  SET_CANDIDATE_ASSISTANCE_STEP_ERROR,
  CANCEL_INTERVIEW_SUCCESS,
  CANCEL_INTERVIEW_ERROR,
  CLOSE_INTERVIEW_SUCCESS,
  CLOSE_INTERVIEW_ERROR,
  GET_HIRING_MANAGERS_SUCCESS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_ERROR,
  GET_VACANCIES_SUCCESS,
  GET_VACANCIES_ERROR,
  NEW_REQUISITION_CREATED, LOADING,
} from './constants';
import moment from 'moment'


/*
stepViews: {
  listingId:{
      stepId:{
        criteria: {},
        config: {},
        reasons: [],
        ts: null,
        views: {
          descartes:{
            approved: { row: candidate },
            rejected: { row: candidate },
          },
          pendingApproval: {
            approved: { row: candidate },
            rejected: { row: candidate },
          },
          interview: {
            approved: { row: candidate },
          },
          slots: {
            'type': { row: slot },      type: past, etc. (is a param)
            'id': { row: candidate }
          },
        }
      }
  }
}
*/
const initialState = fromJS({
  stepViews: {},
  locations: {},
  positions: {},
  vacancies: {},
  loading: true,
  individualLoadings: {},
  message: '',
  hiringManagers: {}
});

const setIndividualLoading = (state, key, value) => {
  const individualLoadings = state.get('individualLoadings').set(key, value)
  // if an individual loading is true, then loading is true
  const loading = Object.values(individualLoadings.toJSON()).find(x => x)
  return {individualLoadings, loading}
}

const buildInterviewTableName = (type, status) => {
  if (type && status) {
    return `${type}_${status}`
  }
  if (type) {
    return type
  }
  if (status) {
    return status
  }
  return ''
}

const removeCandidatesFromTable = (table, candidateIds) =>{
  console.log('removeCandidatesFromTable', table, candidateIds)
  const candidateKeys = []
  // first get the index of the candidates to remove them from the table
  Object.keys(table).map((tableIndex) => {
    if (candidateIds.indexOf(table[tableIndex].candidate_id) > -1){
      candidateKeys.push(tableIndex)
    }
  });


  // second, create a new table without the candidateKeys
  const filtered = Object.keys(table)
    .filter(key => !candidateKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = table[key];
      return obj;
    },
  {});
  const newTable = {}

  // third decrease the row index of the filtered output
  let candidateKeysIndex = 0;
  let removedKey = candidateKeys[candidateKeysIndex];
  let nextRemovedKey = removedKey;
  if (candidateKeysIndex+1 < candidateKeys.length){
    nextRemovedKey =candidateKeys[candidateKeysIndex+1]
  }
  // sort the keys to make more efficient and only 1 loop
  candidateKeys.sort();
  Object.keys(filtered).sort().map((row, index) => {
    if (row > removedKey && row > nextRemovedKey && removedKey != nextRemovedKey){
      removedKey = nextRemovedKey;
      candidateKeysIndex = candidateKeysIndex + 1;
      if (candidateKeysIndex+1 < candidateKeys.length){
        nextRemovedKey =candidateKeys[candidateKeysIndex+1]
      }
    }
    newTable[row-candidateKeysIndex] = filtered[row];
  });

  return newTable
}

const extractReasonValue = (reason) => {
  if (reason && Object.hasOwn(reason, 'reason')) {
    return reason.reason
  }
  return reason
}

function stepViewsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VIEW_CONFIG_SUCCESS: {
      console.log('GET_VIEW_CONFIG_SUCCESS', action.viewConfig)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.viewConfig.listingId;
      const stepId = action.viewConfig.stepId;

      // data
      const config = action.viewConfig.config[0].results;

      const type = action.viewConfig.type;

      if (!stepViews[listingId]){
        stepViews[listingId] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null }
      }
      if(type && type=='INTERVIEW'){
        stepViews[listingId][stepId].interviewViewConfig = config
      } else {
        stepViews[listingId][stepId].config = config
      }
      stepViews[listingId][stepId].viewConfig = action.viewConfig.config[0].stepViewConfig

      return state.set('stepViews', stepViews)
    }
    case GET_VIEW_CONFIG_ERROR: {
      console.log("GET_VIEW_CONFIG_ERROR", action.error)

      return state;
    }

    case SET_VIEW_CONFIG: {
      console.log('SET_VIEW_CONFIG', action)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;

      // data
      const config = action.config;

      if (!stepViews[listingId]){
        stepViews[listingId] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null}
      }

      stepViews[listingId][stepId].config = config

      return state.set('stepViews', stepViews);
    }
    case GET_REASONS_REJECTION_SUCCESS: {
      console.log('GET_REASONS_REJECTION_SUCCESS', action.reasonsData)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.reasonsData.listingId;
      const stepId = action.reasonsData.stepId;

      // data
      if(!action.reasonsData.reasons || !Array.isArray(action.reasonsData.reasons)){
        return state.set('stepViews', stepViews);
      }
      const reasons = action.reasonsData.reasons;
      if (!stepViews[listingId]){
        stepViews[listingId] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null}
      }

      stepViews[listingId][stepId].reasons = reasons

      return state.set('stepViews', stepViews);
    }
    case GET_REASONS_REJECTION_ERROR: {
      console.log("GET_REASONS_REJECTION_ERROR", action.error)

      return state;
    }
    case LOADING: {
      const { individualLoadings, loading } = setIndividualLoading(state, action.key, action.loading);
      return state.set('individualLoadings', individualLoadings).set('loading', loading)
    }
    case ADD_REASONS_REJECTION_WAITING: {
      console.log('ADD_REASONS_REJECTION_WAITING', action)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;

      // data
      const reasons = action.reasons

      // table
      const tableIndex = action.tableIndex
      const tableClass = action.tableClass
      const tableName = action.rejected? 'rejected': 'approved'

      if (!stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason){
        stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason = []
      }
      for (let r in reasons){
        let reason = {candidate_id: -1, reason: extractReasonValue(reasons[r])}
        stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason.push(reason)
        let reasonText = reasons[r]
        if (!stepViews[listingId][stepId].reasons.filter(r => r.indexOf(reasonText) >-1).length){
          console.log('adding new reason to list')
          stepViews[listingId][stepId].reasons.push(reasonText)
        }
      }

      return state.set('stepViews', stepViews);
    }
    case ADD_REASONS_REJECTION_SUCCESS: {
      console.log('ADD_REASONS_REJECTION_SUCCESS', action)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;

      // data
      const reasons = action.reasons

      // table
      const tableIndex = action.tableIndex
      const tableClass = action.tableClass
      const tableName = action.rejected? 'rejected': 'approved'

      if (!stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason){
        stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason = []
      }
      for (let i in reasons){
        let reasonIndex = stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason.findIndex(r => r.reason == reasons[i].reason)
        if (reasonIndex > -1){
          stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason[reasonIndex] = reasons[i]
        }
      }

      return state.set('stepViews', stepViews);
    }
    case ADD_REASONS_REJECTION_ERROR: {
      console.log("ADD_REASONS_REJECTION_ERROR", action.error)

      return state;
    }
    case REMOVE_REASONS_REJECTION_SUCCESS: {
      console.log('REMOVE_REASONS_REJECTION_SUCCESS', action)
      const stepViews = Object.assign({}, state.get('stepViews'));
      console.log(stepViews)
      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;

      // data
      const reasons = action.reasons

      // table
      const tableIndex = action.tableIndex
      const tableClass = action.tableClass
      const tableName = action.rejected? 'rejected': 'approved'

      for (let r in reasons){
        let reason = reasons[r]
        stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason = stepViews[listingId][stepId].views[tableClass][tableName][tableIndex].reason.filter(ri => ri.id != reason)
      }

      return state.set('stepViews', stepViews);
    }
    case REMOVE_REASONS_REJECTION_ERROR: {
      console.log("REMOVE_REASONS_REJECTION_ERROR", action.error)

      return state;
    }

    case GET_VIEW_DATA_SUCCESS: {
      console.log('GET_VIEW_DATA_SUCCESS', action.viewData)
      const stepViews = Object.assign({}, state.get('stepViews'));
      // structure
      const listingId = action.viewData.listingId;
      const stepId = action.viewData.stepId;

      // table
      const limit = parseInt(action.viewData.data.paging.limit)
      const tableIndex = parseInt(action.viewData.data.paging.offset)
      const tableClass = action.viewData.tableClass
      const tableName = action.viewData.rejected? 'rejected': 'approved'

      let data = Object.assign({}, action.viewData.data)
      console.log(tableClass, tableName, tableIndex, listingId, stepId, data)
      // extract the data and the descartes criteria used

      // create the tables if needed
      if (!stepViews[listingId]){
        stepViews[listingId][stepId] = {views: {}, ts: null}
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = { views: {}, ts: null }
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId].views[tableClass]){
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId].views[tableClass][tableName]){
        stepViews[listingId][stepId].views[tableClass][tableName] = {}
      }

      // store the array as a map with row as index
      // row: tableIndex (page*amount) + array position
      for (let di in data.results){
        stepViews[listingId][stepId].views[tableClass][tableName][tableIndex+parseInt(di)] = data.results[di]
      }
      for (let i = data.results.length; i < limit; i++) {
        delete stepViews[listingId][stepId].views[tableClass][tableName][tableIndex+parseInt(i)]
      }

      delete data.results
      stepViews[listingId][stepId].views[tableClass]["query"+tableName] = data
      console.log('GET_VIEW_DATA_SUCCESS stepViews', stepViews)

      return state.set('stepViews', stepViews);
    }
    case GET_VIEW_DATA_ERROR: {
      console.log("GET_VIEW_DATA_ERROR", action.error)

      return state;
    }

    case CLEAN_LOCAL_DATA: {
      console.log('CLEAN_LOCAL_DATA', action)
      const stepViews = Object.assign({}, state.get('stepViews'));
      console.log('CLEAN_LOCAL_DATA stepViews', stepViews)
      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;

      // table
      const tableClass = action.tableClass
      const tableName = action.rejected? 'rejected': 'approved'

      stepViews[listingId][stepId].views[tableClass][tableName] = {}

      return state.set('stepViews', stepViews);
    }
    case UPDATE_ANSWER_SUCCESS: {
      console.log('UPDATE_ANSWER_SUCCESS', action.updateData)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.updateData.listingId;
      const stepId = action.updateData.stepId;
      const candidateId = action.updateData.candidateId;

      // table
      const tableIndex = action.updateData.tableIndex
      const tableClass = action.updateData.tableClass
      const tableName = action.updateData.rejected? 'rejected': 'approved'

      //data
      const questionIndex = (action.updateData.questionIndex);
      const value = (action.updateData.value);
      console.log('stepViews[listingId][stepId].views[tableClass][tableName]', stepViews[listingId][stepId], tableIndex)
      if (stepViews[listingId][stepId].views[tableClass][tableName]){
        const current = stepViews[listingId][stepId].views[tableClass][tableName][tableIndex]
        stepViews[listingId][stepId].views[tableClass][tableName][tableIndex] = { ...current, ...action.updateData.data }
      } else {
        let stepData = Object.keys(stepViews[listingId][stepId].views[tableClass])
        let data = stepViews[listingId][stepId].views[tableClass]
        for (let sd in stepData){
          console.log("andy")
          console.log(stepViews[listingId][stepId].views[tableClass][stepData[sd]], tableIndex)
          if (Array.isArray(data[stepData[sd]]) && stepViews[listingId][stepId].views[tableClass][stepData[sd]][tableIndex]['candidate_id'] == candidateId){
            console.log('aca gato',stepViews[listingId][stepId].views[tableClass][stepData[sd]][tableIndex])
            stepViews[listingId][stepId].views[tableClass][stepData[sd]][tableIndex][questionIndex] = value
          }
        }
      }
      return state.set('stepViews', stepViews);
    }
    case UPDATE_ANSWER_ERROR: {
      console.log("UPDATE_ANSWER_ERROR", action.error)

      return state;
    }

    case GET_PENDING_APPROVAL_LISTINGS_SUCCESS: {
      console.log('GET_PENDING_APPROVAL_LISTINGS_SUCCESS', action.listings)

      // structure
      const listings = action.listings.listings
      const steps = action.listings.steps

      const pendingApprovalListings = Object.assign([], state.get('pendingApprovalListings'));
      const pendingApprovalSteps = Object.assign([], state.get('pendingApprovalSteps'));
      for (let l in listings){
        pendingApprovalListings.push(listings[l])
      }
      for (let s in steps){
        pendingApprovalSteps.push(steps[s])
      }
      return state.set('pendingApprovalListings', pendingApprovalListings)
      .set('pendingApprovalSteps', pendingApprovalSteps);
    }
    case GET_PENDING_APPROVAL_LISTINGS_ERROR: {
      console.log("GET_PENDING_APPROVAL_LISTINGS_ERROR", action.error)

      return state;
    }

    case APPROVE_CANDIDATES_SUCCESS: {
      console.log('APPROVE_CANDIDATES_SUCCESS', action)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      let listingId = action.listingId
      let stepId = action.stepId

      // table
      const tableClass = action.tableClass

      // data
      const approved = action.approved || []
      const rejected = action.rejected || []

      const approvedTable = stepViews[listingId][stepId].views[tableClass]['approved'] || {}
      const rejectedTable = stepViews[listingId][stepId].views[tableClass]['rejected'] || {}
      console.log("stepViews[listingId][stepId]", stepViews[listingId][stepId])
      stepViews[listingId][stepId].views[tableClass]['approved'] = removeCandidatesFromTable(approvedTable, approved+rejected);
      stepViews[listingId][stepId].views[tableClass]['rejected'] = removeCandidatesFromTable(rejectedTable, approved+rejected);
      console.log("stepViews[listingId][stepId] despues ", stepViews[listingId][stepId])
      return state.set('stepViews', stepViews);

    }
    case APPROVE_CANDIDATES_ERROR: {
      console.log("APPROVE_CANDIDATES_ERROR", action.error)

      return state;
    }


    case CANDIDATES_TO_NEXT_STEP_SUCCESS: {
      console.log('CANDIDATES_TO_NEXT_STEP_SUCCESS', action)
      const stepViews = Object.assign({}, state.get('stepViews'));
      console.log('CANDIDATES_TO_NEXT_STEP_SUCCESS', stepViews)
      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;

      // table
      const tableClass = action.tableClass
      const tableName = action.rejected? 'rejected': 'approved'

      const table = stepViews[listingId][stepId].views[tableClass][tableName]
      console.log("table before", table)
      const candidateIds = tableName == 'rejected'? action.approvedIds: action.approvedIds.concat(action.rejectedIds)
      const candidateKeys = []

      stepViews[listingId][stepId].views[tableClass][tableName] = removeCandidatesFromTable(table, candidateIds);
      console.log("table after", stepViews[listingId][stepId].views[tableClass][tableName])

      return state.set('stepViews', stepViews);
    }
    case CANDIDATES_TO_NEXT_STEP_ERROR: {
      console.log("CANDIDATES_TO_NEXT_STEP_ERROR", action.error)

      return state;
    }

    case CLEAR_MESSAGE:{
      return state.set('errorMessage', '');
    }
    case GET_SLOT_AVAILABILITY_SUCCESS: {
      console.log('GET_SLOT_AVAILABILITY_SUCCESS', action)
      const stepViews = Object.assign({}, state.get('stepViews'));
      stepViews.interviewAvailability = action.availability?action.availability.candidate_count:0;

      return state.set('stepViews', stepViews);
    }
    case GET_SLOT_AVAILABILITY_ERROR: {
      console.log("GET_SLOT_AVAILABILITY_ERROR", action.error)
      return state;
    }
    case GET_INTERVIEW_CONFIG_SUCCESS: {
      console.log('GET_INTERVIEW_CONFIG_SUCCESS', action)

      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;

      // data
      const config = action.interviewConfig;

      if (!stepViews[listingId]){
        stepViews[listingId] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null }
      }

      stepViews[listingId][stepId].interviewConfig = config
      stepViews.interviewAvailability = '-'
      return state.set('stepViews', stepViews);
    }

    case RESET_SLOT_AVAILABILITY: {
      const stepViews = Object.assign({}, state.get('stepViews'));
      stepViews.interviewAvailability = '-'
      return state.set('stepViews', stepViews);
    }
    case GET_INTERVIEW_CONFIG_ERROR: {
      console.log("GET_INTERVIEW_CONFIG_ERROR", action.error)
      return state;
    }
    case SAVE_INTERVIEW_SLOT_SUCCESS: {
      console.log('SAVE_INTERVIEW_SLOT_SUCCESS', action)
      return state;
    }
    case SAVE_INTERVIEW_SLOT_ERROR: {
      console.log("SAVE_INTERVIEW_SLOT_ERROR", action.error)
      return state.set('interivewSlotError', action.error)
    }
    case GET_INTERVIEW_SLOTS_SUCCESS: {
      console.log('GET_INTERVIEW_SLOTS_SUCCESS', action)
      const stepViews = Object.assign({}, state.get('stepViews'));

      // structure
      const listingId = action.slots.listingId;
      const stepId = action.slots.stepId;

      // table
      const tableIndex = parseInt(action.slots.amount || 0)*parseInt(action.slots.page ||0)
      const tableClass = 'slots'
      const tableName = buildInterviewTableName(action.slots.type, action.slots.status)
      console.log(tableClass, tableName, tableIndex)

      // extract the data and the descartes criteria used
      let data = action.slots.data.results
      delete action.slots.data.results
      // data = data.sort(function(a, b){return a.dateTime > b.dateTime});

      // create the tables if needed
      if (!stepViews[listingId]){
        stepViews[listingId] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null}
      }
      if (!stepViews[listingId][stepId].views[tableClass]){
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId].views[tableClass]){
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId].views[tableClass][tableName]){
        stepViews[listingId][stepId].views[tableClass][tableName] = {}
      }
      stepViews[listingId][stepId].views[tableClass]["query"+tableName] = action.slots.data

      // store the array as a map with row as index
      // row: tableIndex (page*amount) + array position
      for (let di in data){
        stepViews[listingId][stepId].views[tableClass][tableName][tableIndex+parseInt(di)] = data[di]
      }
      stepViews[listingId][stepId].ts = null
      console.log("stepViews[listingId][stepId].views[tableClass][tableName]", stepViews)
      return state.set('stepViews', stepViews)
    }
    case GET_INTERVIEW_SLOTS_ERROR: {
      console.log("GET_INTERVIEW_SLOTS_ERROR", action.error)
      return state;
    }
    case GET_CANDIDATES_IN_INTERVIEW_SUCCESS: {
      console.log('GET_CANDIDATES_IN_INTERVIEW_SUCCESS', action)

      const stepViews = Object.assign({}, state.get('stepViews'));
      const interviewData = action.interviewData
      // structure
      const listingId = interviewData.listingId;
      const stepId = interviewData.stepId;
      const slotId = interviewData.slotId;
      const tableClass = 'slots'

      // data
      const config = interviewData.results.config;

      // create the tables if needed
      if (!stepViews[listingId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null}
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = { views: {}, ts: null }
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId].views[tableClass]){
        stepViews[listingId][stepId].views[tableClass] = {}
      }
      if (!stepViews[listingId][stepId].views[tableClass][slotId]){
        stepViews[listingId][stepId].views[tableClass][slotId] = []
      }

      if (!stepViews[listingId]){
        stepViews[listingId] = {}
      }
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null}
      }

      stepViews[listingId][stepId].interviewConfig = config

      // answers
      console.log('int data', interviewData)
      const answers = interviewData.results.results

      // store the array as a map with row as index
      // row: tableIndex (page*amount) + array position
      const tableIndex = parseInt(interviewData.offset)
      Object.keys(answers).map((k, index) => {
        stepViews[listingId][stepId].views[tableClass][slotId][tableIndex+parseInt(index)] = answers[k]
      })
      delete interviewData.results.results
      stepViews[listingId][stepId].views[tableClass].queryCandidates = interviewData.results
      console.log('stepViews[listingId][stepId].views[tableClass][slotId]', stepViews[listingId][stepId].views[tableClass][slotId])
      stepViews[listingId][stepId].ts = null
      return state.set('stepViews', stepViews);
    }
    case GET_CANDIDATES_IN_INTERVIEW_ERROR: {
      console.log("GET_CANDIDATES_IN_INTERVIEW_ERROR", action.error)
      return state;
    }
    case SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS: {
      console.log('SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS', action)

      const stepViews = Object.assign({}, state.get('stepViews'));
      const candidateState = action.candidateState

      // structure
      const answers = candidateState.answers;
      const listingId = candidateState.listingId;
      const stepId = candidateState.stepId;
      const slotId = candidateState.slotId;
      const tableClass = 'slots'

      // data
      const candidates = candidateState.candidates;

      // update the candidates data:

      Object.keys(candidates).map((candidateId) => {
        const candidate = candidates[candidateId]
        const candidateIndex = stepViews[listingId][stepId].views[tableClass][slotId].findIndex(({ candidate_id }) => Number(candidate_id) === Number(candidateId))
        const targetCandidateData = stepViews[listingId][stepId].views[tableClass][slotId][candidateIndex];

        answers && answers.forEach(({ questionIndex, value }) => {
          if (_.has(targetCandidateData, questionIndex)) {
            targetCandidateData[questionIndex] = value
          }
        })

        if (candidateIndex > -1) {
          targetCandidateData.assisted = !!candidate.assisted
          targetCandidateData.selected = !!candidate.selected
        }
        console.log(targetCandidateData)
      })
      console.log('SET_CANDIDATE_ASSISTANCE_STEP_SUCCESS - end', stepViews[listingId][stepId].views[tableClass][slotId])
      return state.set('stepViews', stepViews);

    }
    case SET_CANDIDATE_ASSISTANCE_STEP_ERROR: {
      console.log("SET_CANDIDATE_ASSISTANCE_STEP_ERROR", action.error)
      return state;
    }

    case GET_HIRING_MANAGERS_SUCCESS: {
      console.log('GET_HIRING_MANAGERS_SUCCESS', action)
      const hiringManagers = Object.assign({}, state.get('hiringManagers'));
      hiringManagers[action.subsidiaryId] = action.hiringManagers;
      return state.set('hiringManagers', hiringManagers)
    }

    case CANCEL_INTERVIEW_SUCCESS: {
      console.log('CANCEL_INTERVIEW_SUCCESS', action)

      const stepViews = Object.assign({}, state.get('stepViews'));
      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;
      const slotId = action.slotId;
      const tableClass = 'slots'
      const tableName = 'future'
      // data
      const slots = stepViews[listingId][stepId].views[tableClass][tableName]

      let slotKeys = Object.keys(slots)
      console.log("slots", slots)
      console.log("slotKeys", slotKeys)
      let slotIndex = slotKeys.findIndex(s => slots[s].id == slotId)
      stepViews[listingId][stepId].views[tableClass][tableName][slotKeys[slotIndex]].sendingEnabled = false;
      console.log(stepViews)
      return state.set('stepViews', stepViews);

    }
    case CANCEL_INTERVIEW_ERROR: {
      console.log("CANCEL_INTERVIEW_ERROR", action.error)
      return state;
    }
    case CLOSE_INTERVIEW_SUCCESS: {
      console.log('CLOSE_INTERVIEW_SUCCESS', action)

      const stepViews = Object.assign({}, state.get('stepViews'));
      // structure
      const listingId = action.listingId;
      const stepId = action.stepId;
      const slotId = action.slotId;
      const tableClass = 'slots'
      const tableName = 'close'
      // data
      const slots = stepViews[listingId][stepId].views[tableClass][tableName]

      let slotKeys = Object.keys(slots)
      console.log("slots", slots)
      console.log("slotKeys", slotKeys)
      let slotIndex = slotKeys.findIndex(s => slots[s].id == slotId)
      stepViews[listingId][stepId].views[tableClass][tableName][slotKeys[slotIndex]].sendingEnabled = false;
      console.log(stepViews)
      return state.set('stepViews', stepViews);

    }
    case CLOSE_INTERVIEW_ERROR: {
      console.log("CLOSE_INTERVIEW_ERROR", action.error)
      return state;
    }
    case GET_LOCATIONS_SUCCESS: {
      return state.setIn(['locations', action.id], action.locations)
    }
    case GET_LOCATIONS_ERROR: {
      console.log("GET_LOCATIONS_ERROR", action)
      return state;
    }
    case GET_POSITIONS_SUCCESS: {
      return state.setIn(['positions', action.id], action.positions)
    }
    case GET_POSITIONS_ERROR: {
      console.log("GET_POSITIONS_ERROR", action)
      return state;
    }
    case GET_VACANCIES_SUCCESS: {
      const vacancies = Object.assign({}, state.get('vacancies'));

      const subVacancies = vacancies[action.subsidiaryId]
      
      if(subVacancies) {
        subVacancies[action.listingId] = action.vacancies
      } else {
        vacancies[action.subsidiaryId] = {}
        vacancies[action.subsidiaryId][action.listingId] = action.vacancies
      }
      
      vacancies[action.subsidiaryId][action.listingId] = action.vacancies
      return state.set('vacancies', vacancies);
    }
    case GET_VACANCIES_ERROR: {
      console.log("GET_VACANCIES_ERROR", action)
      return state;
    }
    case NEW_REQUISITION_CREATED: {
      const stepViews = Object.assign({}, state.get('stepViews'));
      const listingId = action.listingId;
      const stepId = action.stepId;
      if (!stepViews[listingId][stepId]){
        stepViews[listingId][stepId] = {config: {}, reasons:[], views: {}, ts: null }
      }
      stepViews[listingId][stepId].ts = moment().toISOString()
      return state.set('stepViews', stepViews);
    }
    default:
      return state;
  }
}













export default stepViewsReducer;
