import * as idb from 'idb-keyval';

class KeyValueStore {

  async get(key) {
    return await idb.get(key)
  }

  async set(key, value) {
    return await idb.set(key, value)
  }

  async delete(key) {
    return await idb.del(key)
  }
}

export default KeyValueStore