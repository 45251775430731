// Emi library



// searches the key in a dict. eg: 'a.b.c' in myObj
// returns myObj.a.b.c
const getFromKey = (dicObject, key) => {
  return key.split('.').reduce((p,c)=>p&&p[c]||null, dicObject)
}

const decodeEscapeSequence = (s) => {
  if(!s) return s
  return s.replace(/\\x([0-9A-Fa-f]{2})/g, function() {
      return String.fromCharCode(parseInt(arguments[1], 16));
  });
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const sleepAndRefresh = async (ms) => {
  await sleep(ms)
  location.reload()
}

export {
  getFromKey,
  decodeEscapeSequence,
  sleep,
  sleepAndRefresh
};
