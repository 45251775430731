import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { getActiveInterview } from '../../../utils/connector';
import NewInterview from '../../../containers/StepViews/Step/Interview/newInterview';
import * as listingActions from '../../../containers/Listings/actions';
import * as stepViewActions from '../../../containers/StepViews/actions';
import stepViewMessages from '../../StepView/messages';
import PrimaryButton from '../../../v2/components/buttons/PrimaryButton';
import SecondaryButton from '../../../v2/components/buttons/SecondaryButton';
import { ErrorDialog } from '../../../v2/components/dialogs/ErrorDialog';
import { getErrorMessage } from '../InviteToInterviewDialog/utils';
import messages from '../messages';

const formatTimestamp = (stringDate, timezone, formatMessage) => (
  moment
    .utc(stringDate)
    .tz(timezone)
    .format(formatMessage(messages.format.datetime))
);

const RescheduleInterviewDialog = ({ show, candidateId, listingId, stepId, onClose, onSucceed, actions, intl }) => {
  const [interviewData, setInterviewData] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showInterviewDialog, setShowInterviewDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleError = useCallback((error) => {
    const message = getErrorMessage(error.response)
    setErrorMessage(message)
  }, [])

  const onRescheduleInterview = () => {
    actions.getListing(listingId);
    actions.getViewConfig(listingId, stepId, 'INTERVIEW');
    setShowInterviewDialog(true);
  };

  const doClose = () => {
    setShowInterviewDialog(false);
    onClose();
  }

  useEffect(() => {
    async function getInterviewData() {
      if (candidateId && stepId) {
        try {
          const interviewData = await getActiveInterview(candidateId, stepId);
          if (interviewData && interviewData.allow_rescheduling) {
            setInterviewData(interviewData);
          } else {
            setShowError(true);
          }
          
        } catch (e) {
          setShowError(true);
        }
      }
    }
    setInterviewData(null);
    getInterviewData();
    setShowError(false);
    setShowInterviewDialog(false);
  }, [candidateId, stepId]);

  if (showInterviewDialog) {
    return (
      <Fragment>
        {!!errorMessage && !!errorMessage.code ? (
          <ErrorDialog
            onClose={doClose}
            title={<FormattedMessage {...stepViewMessages.interview.dialog.title} />}
            errorTitle={<FormattedMessage {...messages.inviteToInterview.error.errorTitle} />}
            errorMessage={<FormattedMessage {...errorMessage.message} />}
            errorCode={errorMessage.code}
          />
        ) : ( 
          <NewInterview
            stepId={stepId}
            listingId={listingId}
            candidateId={candidateId}
            onClose={doClose}
            onError={handleError}
            onCreate={onSucceed}
            assignmentId={interviewData.assignment_id}
            layout={{
              showButton: false,
              titleText: <FormattedMessage {...stepViewMessages.interview.dialog.title} />,
              showDate: true,
              showTime: true,
              showLocations: true,
              showSize: false,
              showConditions: true,
              includeConditions: ['working_place_id', 'working_place_cell', 'job_position_id', 'modality'],
              showSlotAvailability: false,
              showSummaryMessage: false
            }}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage {...messages.rescheduleInterview.title} />
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ justifyContent: 'center' }}>
          {
            !interviewData && !showError && (
              <Grid item>
                <CircularProgress />
              </Grid>
            )
          }
          {
            interviewData && [
              <Grid item xs={12} spacing={3}>
                <FormattedMessage {...messages.rescheduleInterview.question} />
              </Grid>,
              <Grid item xs={12}>
                <b><FormattedMessage {...messages.cancelInterview.listing} />:</b> {interviewData.listing_name}
              </Grid>,
              <Grid item xs={12}>
                <b><FormattedMessage {...messages.cancelInterview.candidate} />:</b> {interviewData.interviewee_name}
              </Grid>,
              interviewData.utc_interview_date_time_str &&
              <Grid item xs={12}>
                <b><FormattedMessage {...messages.cancelInterview.date} />:</b> {formatTimestamp(interviewData.utc_interview_date_time_str, interviewData.timezone, intl.formatMessage)}
              </Grid>,
              interviewData.hiring_manager_name && (
                <Grid item xs={12}>
                  <b><FormattedMessage {...messages.cancelInterview.hiringManager} />:</b> {interviewData.hiring_manager_name}
                </Grid>
              ),
            ]
          }
          {
            showError && (
              <Grid item>
                <h3>
                  <FormattedMessage {...messages.rescheduleInterview.error} />
                </h3>
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      {
        interviewData && (
          <DialogActions>
            <PrimaryButton onClick={onRescheduleInterview}>
              <FormattedMessage {...messages.rescheduleInterview.reschedule} />
            </PrimaryButton>
            <SecondaryButton type='text' onClick={onClose}>
              <FormattedMessage {...messages.rescheduleInterview.cancel} />
            </SecondaryButton>
          </DialogActions>
        )
      }
    </Dialog>
  );
};

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators({ ...listingActions, ...stepViewActions }, dispatch);
  return {
    actions
  };
}

export default connect(null, mapDispatchToProps)(injectIntl(RescheduleInterviewDialog));
