import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const UserNameButton = styled(Button)(({ theme }) => ({
  textTransform: "unset !important",
  fontFamily: "Lato",
  fontSize: 14,
  fontWeight: 600,
  color: theme.custom.navigation.appBar.userName.color,
}));

const ProfileMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.custom.navigation.appBar.profileMenu.backgroundColor,
  },
}));

const ProfileMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: "Lato",
  fontSize: 14,
  fontWeight: 500,
  color: theme.custom.navigation.appBar.profileMenu.labelColor,
}));

export default ({
  menu,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const closeAndFire = (action) => (event) => {
    handleClose(event);
    action();
  };
  return (
    <React.Fragment>
      <UserNameButton
        disableRipple
        onClick={handleClick}
        variant="text"
        endIcon={
          open ? (
            <ExpandLessIcon
              sx={{ color: theme => theme.custom.navigation.appBar.profileIcon.color }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{ color: theme => theme.custom.navigation.appBar.profileIcon.color }}
            />
          )
        }
      >
        {children}
      </UserNameButton>
      <ProfileMenu
        anchorEl={anchorEl}
        id="profile-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClick}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menu.map(({ label, Icon, onClick }) => (
          <ProfileMenuItem key={label} onClick={closeAndFire(onClick)}>
            <Icon
              sx={(theme) => ({
                fontSize: 20,
                color: theme.custom.navigation.appBar.profileMenu.iconColor,
                marginRight: theme.spacing(1),
              })}
            />
            {label}
          </ProfileMenuItem>
        ))}
      </ProfileMenu>
    </React.Fragment>
  );
};