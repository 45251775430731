import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SecondaryButton from '../../../../../../v2/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../../v2/components/buttons/PrimaryButton';
import messages from '../../../../messages'

const CustomButtonConfirmationDialog = ({ candidate, button, show, onConfirm, onClose, intl: { formatMessage } }) => {

  const handleConfirmation = () => {
    onConfirm(button, candidate);
    onClose()
  }

  const { label, confirmationModel } = button

  let content = null
  if (show && confirmationModel) {
    const { showFields } = confirmationModel
    const formatValue = (format, value) => {
      if (!value) {
        return ''
      }
      if (format === 'datetime') {
        return moment(new Date(value)).format(formatMessage(messages.format.datetime))
      }
      return value
    }
    content = showFields.map(({ name, label, format }, index) => {
      const value = formatValue(format, candidate[name])
      return value ? <p key={index}>{label}: <strong>{value}</strong></p> : null
    })
  }

  return (
    <Dialog open={show}>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <SecondaryButton type='text' onClick={onClose}>
          {formatMessage(messages.button.cancel)}
        </SecondaryButton>
        <PrimaryButton
          onClick={handleConfirmation}
        >
          {formatMessage(messages.button.confirm)}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

CustomButtonConfirmationDialog.propTypes = {
  candidate: PropTypes.object,
  button: PropTypes.object,
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  
};

export default injectIntl(CustomButtonConfirmationDialog)
