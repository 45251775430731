import React from 'react';
import { connect } from 'react-redux';

import ChangeVacancy from './ChangeVacancy';

const Container = ({ show }) => {
  return show && <ChangeVacancy />;
}

const mapStateToProps = state => ({
  show: state.get('candidateActions').changeVacancy.show
});

export default connect(mapStateToProps)(Container);

