import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import intlMessages from '../messages';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import AutoCompleteCondition from '../../../v2/components/others/AutoCompleteCondition';
import TimeCondition from '../../../v2/components/others/TimeCondition';
import DateCondition from '../../../v2/components/others/DateCondition';

import { getLocations, getPositions, getVacancies, getHiringManagers } from '../../../containers/StepViews/actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectStepViews, buildLocationsId } from '../../../containers/StepViews/selectors';

import moment from 'moment'
import { has } from 'lodash';

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 6px;
  color: #262626;
`;

const WM_TALENT_POOL_HARD_REASONS = ["Aceptó otra oferta laboral", "Candidato 'No recontratable'", "No acepta rolar turnos", "No tiene fit cultural", "No tiene interés en la empresa", "Candidato interno - No cumple con validación de RRHH"]
const WM_LISTING_IDS = ['1530', '1541', '1556', '1605']

const isCandidateInWMListing = ({ listingId }) => {
  return WM_LISTING_IDS.includes(listingId)
}

const getWMReasonLabel = (reason) => {
  if (typeof reason === 'string') {
    if (WM_TALENT_POOL_HARD_REASONS.includes(reason)) {
      return `${reason} [Dura]`;
    }
    return reason;
  }
  if (WM_TALENT_POOL_HARD_REASONS.includes(reason.label)) {
    return {
      ...reason,
      label: `${reason.label} [Dura]`,
    };
  }
  return reason;
};

const DescartesColumnField = ({ value, field: { id, label, required, dropdown, original: { field_type } }, onChange, hasError, extra: { locations, hiringManagers, positions, candidate, vacancies } }) => {
  if (field_type === 'hiring_managers') {
    if (!hiringManagers) {
      // Prevent wrong render while fetching locations
      return <TextField
        defaultValue={value}
        label={`${label}${required ? '*' : ''}`}
        disabled
        variant="outlined"
        size='small'
        fullWidth
      />
    }
    return <AutoCompleteCondition
      label={`${label}${required ? '*' : ''}`}
      value={value}
      onChange={(newValue) => {
        onChange(id, newValue ? newValue.id : null)
        onChange('manually_assigned_hiring_manager_name', newValue ? newValue.name : null )
      }}
      metadata={{
        options: hiringManagers.sort((a, b) => (a.name > b.name) ? 1 : -1),
        getOptionLabel: (option) => {
          if (option.email) {
            return `${option.name} (${option.email})`;
          } else if (option.phoneNumber) {
            return `${option.name} (${option.phoneNumber})`;
          } else {
            return option.name;
          }
        },
        getValueItem: (value) => hiringManagers.find(({ id }) => id === value),
        error: hasError(id)
      }}
    />
  }
  if (field_type === 'time') {
    return <TimeCondition
      label={`${label}${required ? '*' : ''}`}
      value={value}
      onChange={(newValue) => onChange(id, newValue)}
      metadata={{
        error: hasError(id)
      }}
    />
  }
  if (field_type === 'date') {
    return <DateCondition
      label={`${label}${required ? '*' : ''}`}
      value={value && moment(value).isValid() ? moment(value).toDate() : value}
      onChange={(newValue) => onChange(id, newValue && newValue instanceof Date ? moment(newValue).format('YYYY-MM-DD') : newValue)}
      metadata={{
        error: hasError(id),
        minValue: new Date()
      }}
    />
  }
  if (field_type === 'locations') {
    if (!locations) {
      // Prevent wrong render while fetching locations
      return <TextField
        defaultValue={value}
        label={`${label}${required ? '*' : ''}`}
        disabled
        variant="outlined"
        size='small'
        fullWidth
      />
    }
    return <AutoCompleteCondition
      label={`${label}${required ? '*' : ''}`}
      value={value}
      onChange={(newValue) => onChange(id, newValue ? newValue.id : null)}
      metadata={{
        options: locations,
        getOptionLabel: (option) => option.name,
        getValueItem: (value) => {
          let item = locations.find(l => l.id === value)
          if (item) {
            return item
          }
          // working_place_id starts with location name instead of location id
          item = locations.find(l => l.name === value)
          if (item) {
            onChange(id, item.id)
          }
          return item
        },
        error: hasError(id)
      }}
    />
  }
  if (field_type === 'positions') {
    if (!positions) {
      // Prevent wrong render while fetching locations
      return <TextField
        label={`${label}${required ? '*' : ''}`}
        disabled
        variant="outlined"
        size='small'
        fullWidth
      />
    }
    return <AutoCompleteCondition
      label={`${label}${required ? '*' : ''}`}
      value={value}
      onChange={(newValue) => onChange(id, newValue ? newValue.id : null)}
      metadata={{
        options: positions,
        getOptionLabel: (option) => option.name,
        getValueItem: (value) => {
          let item = positions.find(l => l.id === value)
          if (item) {
            return item
          }
          // positions starts with position name instead of position id
          item = positions.find(l => l.name === value)
          if (item) {
            onChange(id, item.id)
          }
          return item
        },
        error: hasError(id)
      }}
    />
  }
  if (field_type === 'vacancies') {
    if (!vacancies) {
      // Prevent wrong render while fetching locations
      return <TextField
        label={`${label}${required ? '*' : ''}`}
        disabled
        variant="outlined"
        size='small'
        fullWidth
      />
    }
    return <AutoCompleteCondition
      label={`${label}${required ? '*' : ''}`}
      value={value}
      onChange={(newValue) => onChange(id, newValue ? newValue.id : null)}
      metadata={{
        options: vacancies,
        getOptionLabel: (option) => `${option.id}-${option.positionName}-${option.locationName}`,
        getValueItem: (value) => {
          let item = vacancies.find(l => l.id === value)
          
          return item
        },
        error: hasError(id)
      }}
    />
  }
  if (dropdown) {
    let source = dropdown
    if (typeof dropdown === 'string') {
      if (dropdown.startsWith('ref/')) {
        const refData = dropdown.replace('ref/', '').split('.');
        if (refData[0] === 'listing_candidate') {
          source = candidate[refData[1]]
        }
      }
    }

    const options = source.every(item => typeof item === 'string')
        ? source.map(item => ({
          label: item,
          value: item
        }))
        : source
   
    return <AutoCompleteCondition
      label={`${label}${required ? '*' : ''}`}
      value={value}
      onChange={(newValue) => onChange(id, newValue ? newValue.value : null, newValue)}
      metadata={{
        options,
        getOptionLabel: (option) => option.label,
        getValueItem: (value) => options.find(l => l.label === value),
        error: hasError(id)
      }}
    />
  }
  return <TextField
    defaultValue={value}
    error={hasError(id)}
    onChange={(e) => onChange(id, e.target.value)}
    label={`${label}${required ? '*' : ''}`}
    multiline
    rowsMax={4}
    variant="outlined"
    size='small'
    fullWidth
  />
}

// TODO: We need to rethink this in a way we can have visible fields but not required but right now we have this two things coupled
const ConfirmationModal = ({ data, onClose, onConfirm, tableHeaders, listingId, subsidiaryId, slotConditions, slot, intl, stepsStore, fetchLocations, fetchPositions, fetchVacancies, fetchHiringManagers, user }) => {
  const getLocations = (listingId, options) => stepsStore.locations[buildLocationsId(listingId, options)];
  const getPositions = (id) => stepsStore.positions[id]
  const getVacancies = (subsidiaryId, listingId) => stepsStore.vacancies[subsidiaryId] && stepsStore.vacancies[subsidiaryId][listingId]
  const getHiringManagers = (id) => stepsStore.hiringManagers[id]
  const [extraFields, setExtraFields] = useState([]);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState([]);
  const [rejectionReason, setRejectionReason] = useState(null)
  const nonRequiredFields = ['_empty'];
  
  useEffect(() => {
    const fillAnswers = {};
    const shouldShowField = (field) => (field.required_in_interview || field.required || nonRequiredFields.includes(field.id)) && (!field.show_only_to_emi_employees || (user && [1,56].includes(user.subsidiaryId)));
    const filterFields = tableHeaders
      .filter((field) => {
        if (field.id === '_interview_reasons' && data.id === 'rejected') {
          setRejectionReason((field.dropdown.find(x => x.label === data.rowData[field.id]) || {}).value)
          return true;
        }
        if (data.id === 'approved') {
          return shouldShowField(field)
        }
        return field.required || nonRequiredFields.includes(field.id);
      })
      .map((field) => {
        const { id, dropdown, label } = field
        //TODO definir como resolverlo
        // EJ: ref a otro QI
        let value = data.rowData[id]
        if (!value) {
          if (id === 'manually_assigned_working_place_id') {
            const condition = slotConditions.find(sc => sc.questionKey === 'working_place_id')
            if (condition) {
              value = condition.value
            }
          } else if (id === 'manually_assigned_job_position_id') {
            if (slot.positionId) {
              value = slot.positionId
            }
          } else if (id === 'manually_assigned_hiring_manager') {
            value = slot.hiringManagerId
            console.log('manually_assigned_hiring_manager value', value)
          } else if (id === 'manually_assigned_vacancy_id') {
            value = slot.vacancyId
          }
          // TODO acá algo tambien tenemos que hacer para precargar el valor por default
        }
        
        if (id === 'manually_assigned_hiring_manager' && slot.hiringManager) {
          fillAnswers['manually_assigned_hiring_manager_name'] = slot.hiringManager.name
        }

        fillAnswers[id] = value || null;

        // should be mandatory by default
        const isMandatory = !has(field, 'mandatory') || field.mandatory

        return {
          id,
          dropdown,
          label,
          required: !nonRequiredFields.includes(id) && isMandatory,
          value: value,
          original: id === 'manually_assigned_hiring_manager' ? {
            ...field,
            field_type: 'hiring_managers'
          } : field
        };
      });
    setExtraFields(filterFields);
    setAnswers(fillAnswers);
  }, []);

  useEffect(() => {
    const locationFields = extraFields.filter(field => field.original.field_type === 'locations')
    if (locationFields && locationFields.length) {
      const locationFieldsWithNameFormat = locationFields
        .filter(field => field.original.metadata && field.original.metadata.name_format)
      
      if (locationFields.length !== locationFieldsWithNameFormat) {
        fetchLocations(listingId, { })
      }
      locationFieldsWithNameFormat.forEach(field => fetchLocations(listingId, { nameFormat: field.original.metadata.name_format }))
      
    }
    if (extraFields.some(field => field.original.field_type === 'positions')) {
      fetchPositions(listingId)
    }
    if (extraFields.some(field => field.original.field_type === 'vacancies')) {
      fetchVacancies(subsidiaryId, listingId)
    }
    if (extraFields.some(field => field.original.field_type === 'hiring_managers')) {
      fetchHiringManagers(subsidiaryId)
    }
  }, [extraFields])

  const submitAnswer = ({ questionIndex, value }) => {
    console.log('changed 2', {
      [questionIndex]: value,
    })
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: value,
    }));
    setErrors(errors.filter(x => x.id === questionIndex))
  };

  const isMandatoryField = (fields, id) => {
    const field = fields.find(f => f.id === id)
    const attributes = field ? {...field, ...field.original} : {}
    return !has(attributes, 'mandatory') || attributes.mandatory
  }

  const isErroredAnswer = (answer, answerKey) => {
    const fieldIsForcedMandatory = !nonRequiredFields.includes(answerKey)
    const fieldShouldNotBeEmpty = isMandatoryField(extraFields, answerKey)
    const isEmptyAnswer = answer === null || answer === ''
    return isEmptyAnswer && fieldIsForcedMandatory && fieldShouldNotBeEmpty
  }

  const handleConfirmation = ({ candidateId, type, reason, forcedFeedback, editedFeedback }) => {
    setErrors([]);

    const inputErrors = Object.keys(answers).filter(
      (key) => isErroredAnswer(answers[key], key)
    );

    if (inputErrors.length) {
      setErrors(inputErrors);
      return;
    }

    const mappedAnswers = Object.keys(answers)
      .map((key) => ({
        questionIndex: key,
        value: answers[key] === null ? '' : answers[key],//CBO-server does not accept null as answer value
      }))
      .filter(({ value }) => value !== '');
    console.log('change 3', mappedAnswers)
    const status = {
      assisted: [{ id: candidateId, value: type === 'not_attended' ? 0 : 1 }],
      selected: [
        {
          id: candidateId,
          value:
            // eslint-disable-next-line no-nested-ternary
            type === 'approved'
              ? 1
              : type === 'not_attended'
                ? null
                : 0,
        },
      ],
      reason: [{ id: candidateId, value: reason }]
    };

    onConfirm(
      mappedAnswers.length ? { ...status, answers: mappedAnswers } : status,
      forcedFeedback,
      editedFeedback
    );
    onClose();
  };

  const { messages } = data || {};

  console.log('ConfirmationModal', data)
  const getColumnSize = (field) => (data && data.id === 'rejected') || field.id === '_empty' ? 12 : 6
  return (
    <ModalContent>
      {messages && messages.title && (
        <DialogTitle>{messages.title}</DialogTitle>
      )}
      <DialogContent>
        {messages && messages.body && (
          <DialogContentText>{messages.body}</DialogContentText>
        )}

        <Grid container spacing={2}>
          {!!extraFields.length &&
            extraFields.map((field, index) => (
              <Grid key={index} item sm={getColumnSize(field)}>
                <DescartesColumnField
                  value={answers[field.id]}
                  field={field.id === '_interview_reasons' && field.dropdown && isCandidateInWMListing({ listingId }) ? 
                    {
                      ...field,
                      dropdown: field.dropdown.map(item => getWMReasonLabel(item))
                    }
                    : field
                  }
                  onChange={(id, value, raw) => {
                    if (id === '_interview_reasons') {
                      setRejectionReason(raw.value)
                    }
                    submitAnswer({ questionIndex: id, value: value })
                  }}
                  hasError={(id) => errors.includes(id)}
                  extra={{
                    candidate: data && data.rowData,
                    locations: getLocations(listingId, { nameFormat: field.original && field.original.metadata ? field.original.metadata.name_format : null }),
                    hiringManagers: getHiringManagers(subsidiaryId),
                    positions: getPositions(listingId),
                    vacancies: getVacancies(subsidiaryId, listingId)
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{intl.formatMessage(intlMessages.button.cancel)}</Button>
        <Button
          autoFocus
          color="primary"
          onClick={() =>
            handleConfirmation({
              candidateId: data.candidateId,
              type: data.id,
              reason: rejectionReason,
              forcedFeedback: data.forcedFeedback,
              editedFeedback: data.editedFeedback
            })
          }
          variant="contained"
        >
          {intl.formatMessage(intlMessages.button.confirm)}
        </Button>
      </DialogActions>
    </ModalContent>
  );
};

ConfirmationModal.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  tableHeaders: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  stepsStore: makeSelectStepViews()
});

const mapDispatchToProps = dispatch => ({
  fetchLocations: (listingId, options) => dispatch(getLocations(buildLocationsId(listingId, options), listingId, options)),
  fetchPositions: (listingId) => dispatch(getPositions(listingId, listingId)),
  fetchVacancies: (subsidiaryId, listingId) => dispatch(getVacancies(subsidiaryId, listingId)),
  fetchHiringManagers: (subsidiaryId) => dispatch(getHiringManagers(subsidiaryId))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmationModal));
