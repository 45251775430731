/**
 * Gets posts from https://jsonplaceholder.typicode.com/
 */
 import { push } from 'react-router-redux'
import { take, call, put, cancel, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  GET_SUGGESTIONS,
  CREATE_SUGGESTION,
  } from './constants';

import {
  getSuggestionsSuccess,
  getSuggestionsError,
  createSuggestionSuccess,
  createSuggestionError,
} from './actions';

import request from '../../utils/request';
const config = require('../../config');
const root = config.params.API_SERVER

export function* sSuggestions() {
  const requestURL = `${root}/suggestions`;
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    yield put(getSuggestionsSuccess(result));

  } catch (err) {
    yield put(getSuggestionsError(err));
  }
}

export function* getSuggestionsApi() {
  // Watches for GET_POSTS actions and calls getPosts when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  const watcher = yield takeLatest(GET_SUGGESTIONS, sSuggestions);

  // Suspend execution until location changes
  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}



export function* sCreateSuggestion(action) {
  const requestURL = `${root}/suggestions`;
  console.log(requestURL)
  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(action.suggestion),
    });
    yield put(createSuggestionSuccess(result));
  } catch (err) {
    yield put(createSuggestionError(err));
  }
}

export function* createSuggestionApi() {
  const watcher = yield takeLatest(CREATE_SUGGESTION, sCreateSuggestion);

  // yield take(LOCATION_CHANGE);
  // yield cancel(watcher);
}


// All sagas to be loaded
export default [
  getSuggestionsApi,
  createSuggestionApi,
];
